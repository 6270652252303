import { useMutation, useQuery } from "@apollo/client";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "components/Button/Button";
import { GetLogbookDocument, GetLogEntryDocument, GetUserInfoDocument, UpdateLogEntryDocument } from "graphql/generated";
import { useNavigate } from "react-router-dom";
import { formatApiDate } from "utils/formatter";

export default function SignOffModal({setShowSign, id, logEntry} : { setShowSign: (boolean)=>void, id: string, logEntry: any }) {
    const { data: { me } = { } } = useQuery(GetUserInfoDocument);
    const [updateLogEntry] = useMutation(UpdateLogEntryDocument, {
        refetchQueries: [{ query: GetLogbookDocument, variables: { logBookId:logEntry?.logBook?.id } }, { query: GetLogEntryDocument, variables: { logEntryId:id } }],
        onCompleted: () => setShowSign(false),
      });

    const navigate = useNavigate();
    const airmenNumber = me?.airmenLicense?.airmenNumber;
    return (
        <div className="relative">
            <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
                <div onClick={() => {
                    setShowSign(false); }} className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0">
                    <div className="flex flex-col gap-2 items-center justify-center relative  max-w-md w-full h-full">
                        <div className="flex flex-col rounded border border-slate-300 relative hover-shadow bg-white">
                            <XMarkIcon
                                onClick={() => {
                                    setShowSign(false);
                                }}
                                className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
                            />
                            <div className="flex gap-3 items-center p-8">
                                <div>
                                    {airmenNumber ? 
                                    <div className=''>
                                        <h1 className="text-sm mb-3">This aircraft, airframe, engine, propeller, or appliance identified was repaired and/or inspected in accordance with the current regulations of the Federal Aviation Administration and is approved for return to service for the work performed.</h1>
                                        <h2 className="font-bold">License Number: {me?.airmenLicense?.airmenName} - {me?.airmenLicense?.licenseType}</h2>
                                        <h2 className="font-bold">Signed: {me?.airmenLicense?.airmenNumber}</h2>
                                        <h2 className="font-bold">Date: {formatApiDate(new Date())}</h2>
                                        
                                    </div>
                                    : <>
                                        <h1 className="">To sign off on this entry you will first need to set up your license information on your profile.</h1>
                                    </> }
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-t border-slate-300 bg-slate-50">
                                {/* <Button color="white" size="xs" text="Cancel" onClick={()=>{ cancel(false) }}/> */}
                                <span
                                    className="text-sm cursor-pointer font-medium hover:opacity-70 transition"
                                    onClick={() => setShowSign(false)}>
                                    Cancel
                                </span>
                                {airmenNumber ? 
                                    <Button
                                    color="navy"
                                    size="xs"
                                    text="Sign Off"
                                    onClick={() => updateLogEntry({ variables: { input:{
                                        id,
                                        licenseInfo: me.airmenLicense,
                                        signedOffAt: new Date(),
                                        signedOffBy: me.firstName + ' ' + me.lastName,
                                        signedUser: { connect: { id: me.id } }
                                    } } })}
                                    />
                                    :
                                    <Button
                                    color="navy"
                                    size="xs"
                                    text="Set Up Now"
                                    onClick={() => navigate('/account/settings', { state: {settingsTab: 'Profile'}})}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
const ProgressRing = ({ radius, stroke, progress, value, warning } : 
  { radius: number, stroke: number, progress: number, value?:number, warning?:boolean}) => {
  const isWarning = warning || progress <= 30;
  const isPastDue = progress <= 0 || value <= 0;
  progress = Math.min(progress, 100);
  const normalizedRadius = radius - stroke * 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className="relative">
      <svg
        height={radius * 2}
        width={radius * 2}
        className="transform rotate-[270deg]"
      >
        <circle
          className="progress-ring-circle"
          stroke="lightgray"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="progress-ring-circle"
          stroke={isPastDue ? "red" : isWarning ? "#EAB308" : "#1C64F2"}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-lg font-bold">{ value !== null ? value : progress+"%"}</span>
      </div>
    </div>
  );
};

export default ProgressRing;
import { CheckIcon } from 'assets/icons';
import { webFormat } from 'utils/statuses';

const signoffValues = ['INITIAL_SIGNOFF', 'PRIMARY_INSPECTION', 'FUNCTIONAL_TEST', 'LEAK_CHECK'];

export default function SignoffSelector({
  selectedSignoffs,
  handleSignoffSelection,
}: {
  selectedSignoffs: any[];
  handleSignoffSelection: (any) => void;
}) {
  return (
    <div className="rounded bg-slate-50 overflow-y-auto shadow-inner p-2 border border-slate-300 mt-2">
      <div className="bg-white rounded border border-slate-200 shadow-blue p-2">
        {signoffValues?.map((value, index) => (
          <div
            key={index}
            className="flex flex-row-reverse items-center rounded last:mb-0 relative group border-b border-brand-pale border-dashed last:border-b-0">
            <input
              id={value}
              checked={selectedSignoffs.findIndex((e) => e === value || e.title === webFormat(value)) > -1 ? true : false}
              onChange={() => handleSignoffSelection(value)}
              type="checkbox"
              name="profileSelection"
              className="cursor-pointer bg-transparent hidden-check border-0 focus:ring-0 focus:outline-0 absolute right-2 rounded-full"
            />
            <label
              htmlFor={value}
              className="flex grow cursor-pointer items-center p-2 border border-white hover:bg-brand-offwhite hover:border-brand-pale rounded transition my-0.5">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col">
                  <span className="font-bold text-brand-dark">{webFormat(value)}</span>
                </div>
              </div>
            </label>
            <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 hidden pointer-events-none" />
          </div>
        ))}
      </div>
    </div>
  );
}

import { useMemo, useState, useEffect } from 'react';
import {
  GetMroEstimateDocument,
  GetMroEstimateQuery,
  GetMroWorkOrderQuery,
  GetOrganizationRateDocument,
  GetWorkOrderBillingDocument,
  MroInvoiceCreateInput,
  UpdateMroEstimateDocument
} from 'graphql/generated';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { useMutation, useQuery } from '@apollo/client';
import Estimate from '../partials/EstimateView';
import { useSession } from 'contexts';

export default function EditEstimateFlyout({
  workOrder,
  estimate,
  closeFlyout,
}: {
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  estimate: GetMroEstimateQuery['mroEstimate'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
}) {
  const { data : { mroWorkOrder : { billableRate } = {} } = {} } = useQuery(GetOrganizationRateDocument, { variables: { mroWorkOrderId: workOrder.id } });
  const initialVals = useMemo(() => {
    const init = {
      estimateNumber: estimate.estimateNumber,
      date: new Date(estimate.date).toISOString(),
      items: [
        ...workOrder.mroWorkOrderItems
          .filter((item) => item.status !== 'ABORT')
          .map((item) => {
            const partsCost = Number(item.mroWorkOrderParts.reduce((acc, part) => acc + part.unitPrice * part.quantity, 0));
            const laborCost = Number(item.isFlatRate ? item.flatRate : (item.estimatedHours * billableRate));
            return {
              id: item.id,
              title: item.title,
              laborHours: item.totalLaborHours,
              laborCost: laborCost,
              partsCost,
              active: estimate.items.find((estimateItem) => estimateItem.id === item.id),
              severity: estimate.items.find((estimateItem) => estimateItem.id === item.id)?.severity ?? 'DISCRETIONARY',
              desc: estimate.items.find((estimateItem) => estimateItem.id === item.id)?.desc ?? '',
              isFlatRate: item.isFlatRate,
              subTotal: laborCost + partsCost,
              model: item.category?.mroComponent?.model,
              serialNumber: item.category?.mroComponent?.serialNumber,
              parts: item.mroWorkOrderParts
              .map((part) => {
                return {
                  quantity: part.quantity,
                  partNumber: part?.mroPart?.partNumber ?? part?.partNumber,
                  description: part?.mroPart?.description ?? part?.partDescription,
                  unitPrice: part.unitPrice,
                  subtotal: part.unitPrice * part.quantity,
                };
              }),
            };
          }),
        ...estimate.items
          .filter((item) => item.temp)
          .map((item) => {
            return { ...item, legacy: true };
          }),
      ],
      charges: estimate.charges ?? [],
      discounts: estimate.discounts ?? [],
      laborSummary: estimate.laborSummary,
      priceSummary: estimate.priceSummary,
      id: estimate.id,
    };
    return init;
  }, [workOrder, estimate, billableRate]);
  
  const [estimateItems, setEstimateItems] = useState<(typeof initialVals.items[number] & { id?: string | number; notes?: string })[]>(
    initialVals.items
  );

  useEffect(() => {
    setVals({
      ...vals,
      total: initialVals.priceSummary.amountDue,
    });
  }, [initialVals.items]);
  const [taxes, setTaxes] = useState(initialVals.priceSummary.tax);
  const [charges, setCharges] = useState<typeof initialVals.charges>(initialVals.charges);
  const [discounts, setDiscounts] = useState<typeof initialVals.discounts>(initialVals.discounts);

  const [vals, setVals] = useState({
    estimateNumber: initialVals.estimateNumber,
    date: initialVals.date,

    totalShipping: initialVals.priceSummary.totalShipping,
    totalParts: initialVals.priceSummary.totalParts,
    totalLabor: initialVals.priceSummary.totalLabor,
    totalHours: initialVals.laborSummary.totalHours,
    discount: initialVals.priceSummary.discount ?? 0,
    totalCharges: Math.round(initialVals.charges.reduce((acc, charge) => acc + charge.total, 0) * 100) / 100,
    total: initialVals.priceSummary.amountDue,
  });

  const [updateEstimate] = useMutation(UpdateMroEstimateDocument, {
    refetchQueries: [{ query: GetWorkOrderBillingDocument, variables:{ mroWorkOrderId: workOrder.id } }, { query: GetMroEstimateDocument, variables: { id: estimate.id } }],
  });

  const handleSubmit = () => {
    const newVals: typeof initialVals & { total?: number; status?: string; mroWorkOrder?: MroInvoiceCreateInput['mroWorkOrder'] } = {
      ...initialVals,
    };
    const activeItems = estimateItems.filter((item) => item.active);
    const laborFlat = Math.round(activeItems.filter((item) => item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    const laborTM = Math.round(activeItems.filter((item) => !item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;

    newVals.estimateNumber = vals.estimateNumber;
    newVals.date = vals.date;
    newVals.items = activeItems;
    newVals.priceSummary = {
      amountDue: vals.total,
      deposit: initialVals.priceSummary.deposit,
      tax: Math.round((vals.totalLabor + initialVals.priceSummary.totalParts + vals.totalCharges) * taxes * 100) / 100,
      taxRate: taxes,
      totalCharges: vals.totalCharges,
      laborFlat: laborFlat,
      laborTM: laborTM,
      discount: vals.discount,
      totalLabor: vals.totalLabor,
      totalParts: vals.totalParts,
      totalShipping: vals.totalShipping,
    };
    newVals.laborSummary = {
      shopLabor: vals.totalLabor,
      outsideLabor: 0,
      totalHours: vals.totalHours,
    };
    newVals.total = vals.total;
    newVals.mroWorkOrder = { connect: { id: workOrder.id } };
    newVals.charges = charges;
    newVals.discounts = discounts.filter((discount) => discount.active);
    updateEstimate({ variables: { input: newVals } })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  return (
    <div className="flex flex-col p-4 h-full">
      <Estimate
        edit={true}
        estimate={{ ...initialVals, ...vals }}
        location={workOrder?.mroLocation?.code}        
        { ...{ setCharges, charges, vals, setVals, estimateItems, setEstimateItems, taxes, setTaxes, discounts, setDiscounts } }
      />

      <div className="flex gap-2 items-center justify-end mt-2 pb-20">
        <button
          onClick={() => closeFlyout()}
          className="flex items-center font-semibold text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          Save Changes
        </button>
      </div>
    </div>
  );
}

import NewCustomerForm from './Partials/NewCustomerForm';
import { UserIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const NewCustomer = function () {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded-t shadow-blue">
        <div className="flex items-center">
          <UserIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-xl md:text-2xl">Create A Customer</h1>
        </div>
      </div>
      <div className="flex items-center justify-center px-4 py-12 shadow-inner bg-slate-50 border border-t-0 rounded-b border-slate-300">
        <div className="p-8 bg-white rounded shadow-blue border border-slate-200 w-full max-w-lg">
          <NewCustomerForm postSubmit={() => navigate('/mro/customers')} />
        </div>
      </div>
    </>
  );
};

export default NewCustomer;

import { useMutation } from '@apollo/client';
import Button from 'components/Button/Button';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import { Form, Formik } from 'formik';
import { CreateWorkOrderTemplateDocument, MroWorkOrderTemplatesDocument } from 'graphql/generated';

interface Values {
  title: string;
}

export default function NewTemplateFlyout({
  closeFlyout,
  workOrderId,
  titleTemplate,
}: {
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  workOrderId: string;
  titleTemplate?: string;
}) {
  const [createTemplate] = useMutation(CreateWorkOrderTemplateDocument, { refetchQueries: [MroWorkOrderTemplatesDocument] });
  const initialValues = {
    title: titleTemplate ?? '',
  };
  const { user } = useSession();

  const handleSubmit = ({ title }: Values) => {
    createTemplate({
      variables: {
        input: {
          title,
          mroWorkOrderId: workOrderId,
          mroOrganization: { connect: { id: user.mroOrganizationId } },
        },
      },
    })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  return (
    <>
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col w-full border border-slate-200 rounded pt-10 shadow-blue bg-white">
                <div className="flex w-full mb-2 px-10">
                  <FullFormikInput name="title" label="Title" />
                </div>
              </div>
              <div className="flex justify-end items-center mt-4">
                <Button text="Create Template" color="navy" size="sm" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

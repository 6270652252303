import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { PlusIcon } from '../../../../assets/icons';
import { Table } from '../../../../components/Table/Table';
import Flyout from 'components/Flyout/Flyout';
import { useSession } from 'contexts';
import { GetMaintenanceRecordsDocument } from 'graphql/generated';

const View = ({ data }) => {
  return <div className="w-full h-full text-left">TEST</div>;
};

const New = () => {
  return <div className="w-full h-full text-left">TEST</div>;
};

const MaintenanceRecords = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeFlyout, setActiveFlyout] = useState({ title: '', content: <></>, active: 0 });
  const { craftId } = useSession();

  const updateFlyout = function (flyoutData) {
    setIsOpen(true);
    setActiveFlyout(flyoutData);
  };

  const { loading, error, data } = useQuery(GetMaintenanceRecordsDocument, { variables: { craftId } });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const columns = [
    {
      Header: 'Title',
      accessor: 'name',
      className: 'text-left',
      Cell: ({ value, row }: any) => {
        return (
          <div
            className="w-full h-full text-left"
            onClick={() => updateFlyout({ title: 'Maintenance Record', content: <View data={row.original} /> })}>
            <button>{value}</button>
          </div>
        );
      },
    },
    {
      Header: 'Completion Date',
      accessor: 'completionDate',
      className: 'text-center',
      Cell: ({ value, row }: any) => {
        return (
          <div
            className="w-full h-full text-left"
            onClick={() => updateFlyout({ title: 'Maintenance Record', content: <View data={row.original} /> })}>
            <button>{value}</button>
          </div>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      className: 'text-left',
      Cell: ({ value, row }: any) => {
        return (
          <div
            className="w-full h-full text-left"
            onClick={() => updateFlyout({ title: 'Maintenance Record', content: <View data={row.original} /> })}>
            <button>{value}</button>
          </div>
        );
      },
    },
    {
      Header: 'Date Opened',
      accessor: 'createdAt',
      className: 'text-center',
      Cell: ({ value, row }: any) => {
        return (
          <div
            className="w-full h-full text-left"
            onClick={() => updateFlyout({ title: 'Maintenance Record', content: <View data={row.original} /> })}>
            <button>{value}</button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Flyout data={activeFlyout} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="px-4 md:px-8">
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center mb-4 px-4 md:px-0">
            <h2 className="text-brand-dark font-bold text-xl">Maintenance Records</h2>
            <button
              onClick={() => updateFlyout({ title: 'Maintenance Record', content: <New /> })}
              className="border bg-white p-1 px-2 rounded flex items-center text-sm font-semibold">
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              Add Maintenance Record
            </button>
          </div>
        </div>
        {/* Maintenance Records List */}
        <div className="">
          <Table columns={columns} data={data.maintenanceRecords} />
        </div>
      </div>
    </>
  );
};

export default MaintenanceRecords;

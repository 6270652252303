import { ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import IntercomChat from 'components/IntercomChat/IntercomChat';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

export default function AuthLayout({ children }: { children?: ReactNode }) {
  return (
    <>
      <main className="flex md:items-center justify-center min-h-[100vh] bg-brand-gray">
        <Outlet />
      </main>
      <IntercomChat />
    </>
  );
}

import { useQuery } from '@apollo/client';
import { FullFormikSelect } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import { Field, Form, Formik } from 'formik';
import { GetLocationsDocument, GetMroWorkOrderCategoriesDocument, MroWorkOrderTemplatesDocument } from 'graphql/generated';

const fieldClass =
  'rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm';

// STEP 5
const AddInfo = function ({ setStep, createOrder, mroServiceRequest }) {
  const { user } = useSession();
  const { data: { mroWorkOrderTemplates } = {} } = useQuery(MroWorkOrderTemplatesDocument, {
    variables: { mroOrganizationId: user.mroOrganizationId },
  });
  const { data : { mroWorkOrderCategories } = {} } = useQuery(GetMroWorkOrderCategoriesDocument, { variables: { where: {
    mroOrganizationId: { equals: user.mroOrganizationId },
  } } });
  
  // FIRST WE CHECK CODE FROM SERVICE REQUEST FOR CATEGORY AND WORK TYPE, THEN WE DEFAULT TO FIRST CATEGORY
  let categoryId = mroWorkOrderCategories?.find((category) => 
    (category.location === mroServiceRequest?.location && category.workType === mroServiceRequest.description )
  )?.id ?? undefined;
  if(!categoryId) categoryId = mroWorkOrderCategories?.find((category) => (category.location === mroServiceRequest?.location && !category.workType) )?.id  ?? undefined;
  if(!categoryId) categoryId = mroWorkOrderCategories?.[0]?.id ?? undefined;

  const initialValues = {
    mroTitle: mroServiceRequest?.title,
    arrivalDate: '',
    promiseDate: '',
    primaryConcern: mroServiceRequest?.description,
    notes: mroServiceRequest?.notes,
    mroWorkOrderCategoryId: categoryId,
  }
  
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => createOrder(values) }>
      {({ values }) => (
        <Form>
          <div className="flex flex-col">
            <h2 className="text-xl font-bold text-brand border-b border-slate-200 pb-3 -mx-2 pl-2 mb-4">Work Order Details</h2>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Work Order Title</label>
              <Field name="mroTitle" className={fieldClass}></Field>
            </div>
            <div className="flex gap-1">
              <div className="flex flex-col w-full">
                <label className="text-sm font-medium text-brand">Arrival Date</label>
                <Field name="arrivalDate" className={fieldClass} type="date"></Field>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-medium text-brand">Promise Date</label>
                <Field name="promiseDate" className={fieldClass} type="date"></Field>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Primary Concern</label>
              <Field name="primaryConcern" className={fieldClass}></Field>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Notes</label>
              <Field
                rows={4}
                as="textarea"
                name="notes"
                placeholder="Enter notes for the work order."
                className="rounded border border-slate-300 bg-slate-50 mb-3 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm"></Field>
            </div>
            <div className="flex justify-between items-center mt-2">
              <FullFormikSelect name="mroWorkOrderCategoryId" label="Category">
                <option value="">N/A</option>
                {mroWorkOrderCategories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </FullFormikSelect>
            </div>
            <div className="flex justify-between items-center mt-2">
              {/* TODO: Make this a searchable select */}
              <FullFormikSelect name="mroWorkOrderTemplateId" label="Template">
                <option value="">Default</option>
                <option value="blank">Blank</option>
                {mroWorkOrderTemplates?.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.title}
                  </option>
                ))}
              </FullFormikSelect>
            </div>
            <div className="flex justify-between items-center mt-2">
              {!mroServiceRequest ? <button
                type="button"
                onClick={() => {
                  setStep(3);
                }}
                className="underline text-brand font-semibold text-sm">
                Go Back
              </button> : <span></span> }
              <button
                type="submit"
                className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-2 px-4 disabled:opacity-50">
                Create Work Order
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default AddInfo;
import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateInventoryDocument,
  GetMroPartInventoriesDocument,
  GetMroPartWarehouseDocument,
  GetMroPartsDocument,
  PartCondition,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';

const NewInventoryFlyout = function ({ closeFlyout, partId, importData }: { closeFlyout: any; partId: string; importData?: any }) {
  const { user } = useSession();
  const [createInventory] = useMutation(CreateInventoryDocument, {
    refetchQueries: [GetMroPartInventoriesDocument, GetMroPartsDocument],
  });

  const { data: { mroPartWarehouses = [] } = {} } = useQuery(GetMroPartWarehouseDocument, {
    variables: {
      input: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });
  const [initialValues, setInitialValues] = useState({
    condition: 'NEW' as PartCondition,
    quantity: 0,
    unitPrice: 0,
    lotNumber: '',
    warehouse: 'n/a',
    bin: '',
  });

  useEffect(() => {
    if (importData) {
      setInitialValues({
        condition: importData.condition,
        quantity: importData.quantity,
        unitPrice: importData.unitPrice,
        lotNumber: '',
        warehouse: 'n/a',
        bin: '',
      });
    }
  }, [importData]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const warehouseId = values.warehouse === 'n/a' ? null : values.warehouse;
      delete values.warehouse;
      createInventory({
        variables: {
          input: {
            mroOrganization: { connect: { id: user?.mroOrganizationId } },
            mroPart: { connect: { id: partId } },
            ...(warehouseId && { mroPartWarehouse: { connect: { id: warehouseId } } }),
            ...values,
          },
        },
      })
        .then(() => closeFlyout())
        .catch(console.error);
    },
    [createInventory, closeFlyout, user]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikSelect name="condition" label="Condition">
                  <option value="NEW">New</option>
                  <option value="USED">Used</option>
                  <option value="REFURBISHED">Refurbished</option>
                  <option value="SERVICEABLE">Serviceable</option>
                </FullFormikSelect>
                <FullFormikInput type="number" name="unitPrice" label="Unit Price" />
                <FullFormikInput type="number" name="quantity" label="Qty." />
              </div>
              <div className="flex gap-1">
                <FullFormikInput name="lotNumber" label="Lot #" />
                <FullFormikSelect name="warehouse" label="Warehouse">
                  <option value="n/a">N/A</option>
                  {mroPartWarehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </FullFormikSelect>
                <FullFormikInput name="bin" label="Bin" />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              {/* Change text based on context of use */}
              <Button
                text={importData ? 'Create Inventory and Mark as Received' : 'Create Inventory'}
                color="navy"
                size="xs"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewInventoryFlyout;

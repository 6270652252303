import { useMutation, useQuery } from '@apollo/client';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import {
  GetCraftDocument,
  InspectionGroupCreateInput,
  GetInspectionsDocument,
  UpdateInspectionGroupDocument,
  GetInspectionGroupDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';

const Edit = function ( { id, closeFlyout } : {id: string; closeFlyout: () => void } ) {
  const { craftId } = useSession();
  const [updateInspectionGroup] = useMutation(UpdateInspectionGroupDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }] });
  const { data: { inspectionGroup } = { } } = useQuery(GetInspectionGroupDocument, { variables: { id } });
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    position: 0,
  }); 

  useEffect(() => {
    if (inspectionGroup) {
      setInitialValues({
        name: inspectionGroup.name,
        description: inspectionGroup.description,
        position: inspectionGroup.position + 1,
      });
    }
  }, [inspectionGroup]);
  

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const inspectionInput: InspectionGroupCreateInput = {
      ...values,
      position: values.position - 1,
      craft: { connect: { id: craftId } },
      id
    };
    await updateInspectionGroup({ variables: { input: inspectionInput } });
    setSubmitting(false);
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue }) => (
        <Form className='p-2'>
          <Card>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="flex flex-col w-full">
                <FullFormikInput name="name" label="Name" autoComplete="off" />
                <FullFormikInput name="description" label="Description" autoComplete="off" />
                <FullFormikInput name="position" label="Position" autoComplete="off" />
              </div>
            </div>
          </Card>
          <div className="px-4 mt-4 flex items-center justify-end">
            <Button text="Save Group" color="navy" size="xs" type='submit' disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Edit;

import { useQuery } from "@apollo/client";
import { BriefcaseIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Table } from "components/Table/Table";
import UserIcon from "components/UserIcon/UserIcon";
import WorkOrderNumber from "components/WorkOrderNumber/WorkOrderNumber";
import { useSession } from "contexts";
import { GetDueSoonWorkOrdersDocument, GetMroDashboardOrdersDocument } from "graphql/generated";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatApiDate, formatOrderNumber, formatWithZeros } from "utils/formatter";

export function UpcomingWorkOrders() {
    const { user } = useSession();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const { data: { mroWorkOrders } = {} } = useQuery(GetDueSoonWorkOrdersDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
    const filterOrderData = useMemo(() => {
        return mroWorkOrders?.filter((item) => {
          if (
            !item.title?.toUpperCase().includes(search.toUpperCase()) &&
            !item.mroCraft.tailNumber?.toUpperCase().includes(search.toUpperCase())
          ){
            return false;
          }
          return true;
        });
    }, [mroWorkOrders, search]);
    //WORK ORDERS COLUMNS
    const columns = useMemo(
        () => [
          {
            Header: 'Craft',
            accessor: (order) => order.mroCraft, // accessor is the "key" in the data
            className: 'w-3/12 pl-8',
            Cell: ({ value, row }: any) => {
              return (
                <div className="flex items-center gap-2 max-w-[300px]">
                  <BriefcaseIcon className="h-10 w-10 p-2 bg-brand-pale text-brand-electric rounded shrink-0" />
                  <div className="flex flex-col">
                    <span className="text-brand-dark font-bold text-base">{value.tailNumber}</span>
                    <span className="font-medium uppercase text-slate-500 text-sm -mt-1">
                      {value.make} {value.model}
                    </span>
                  </div>
                </div>
              );
            },
          },
          {
            Header: 'Order #',
            accessor: (obj) => {
              return {
                workOrderNum: obj.workOrderNum, 
                mroWorkOrderCategory: obj.mroWorkOrderCategory,
              };
            },
            className: 'w-full max-w-[10rem]',
            Cell: ({ value }) => {
              const color = value.mroWorkOrderCategory?.color ? "text-[" + value.mroWorkOrderCategory?.color + "]" : 'text-slate-500';
              return (
                <div className='flex flex-col justify-center'>
                  <WorkOrderNumber code={value.mroWorkOrderCategory?.code} workOrderNum={value.workOrderNum} />
                  <span className={`text-xs p-0.5 ${ color } font-semibold text-center`}>{value.mroWorkOrderCategory?.name}</span>
                </div>
              );
            },
          },
          {
            Header: 'Description',
            accessor: 'title',
            className: 'w-4/12',
          },
          {
            Header: 'Arrival',
            accessor: 'arrivalDate',
            className: 'border-l justify-center th-white w-[12.5%]',
            Cell: ({ value, row }: any) => {
              return <span className="font-semibold text-sm whitespace-nowrap">{formatApiDate(value)}</span>;
            },
          },
          {
            Header: 'Promise',
            accessor: 'promiseDate',
            className: 'border-r justify-center th-white w-[12.5%]',
            Cell: ({ value, row }: any) => {
              return <span className="font-semibold text-sm whitespace-nowrap">{formatApiDate(value)}</span>;
            },
          },
          {
            Header: 'Assignee(s)',
            accessor: 'assignees',
            className: 'w-2/12 justify-end pr-8',
            Cell: ({ value, row }: any) => {
              if (!Array.isArray(value)) {
                value = [value];
              }
              return (
                <div className="flex -ml-[0.125rem] -my-[0.125rem]">
                  {value.map((item, index) => {
                    if (!item) return null;
                    return (
                      <UserIcon
                        key={index}
                        iconSize="sm"
                        iconText={item.firstName + ' ' + item.lastName}
                        iconId={item.firstName + ' ' + item.lastName}
                        iconColor={item.profileColor}
                        className="border-2 border-slate-50"
                      />
                    );
                  })}
                </div>
              );
            },
          },
        ],
        []
    );
    return (
        <div className=" bg-white shadow-blue">
            <div className="flex flex-col">
                <div className="flex items-center justify-between py-4 px-6">
                    <h1 className="text-brand-dark text-left font-bold text-lg">Upcoming Work Orders</h1>
                    <div className="flex items-center gap-2">
                        <div className="relative flex items-center">
                            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                            <input
                                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </div>
                        {/* <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" /> */}
                    </div>
                </div>
                <div className="flex flex-wrap pt-1 pb-2 overflow-x-auto">
                    <Table
                    columns={columns}
                    data={filterOrderData ?? []}
                    className="sm-head w-full"
                    onRowClick={(row) => navigate('/mro/work-orders/'+row.id)}
                    />
                </div>
            </div>
        </div>
    );
}
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export type Interval = {
    id?: string;
    name: string;
    startValue: number;
    endValue: number;
};

export default function IntervalItem(
{   interval, 
    index, 
    required = false,
    handleItemUpdate,
    error
} : {
    interval: Interval;
    required?: boolean;
    index: number;
    error?: any;
    handleItemUpdate: (id: string, type : string, value: string) => void;
}) {
    return ( <div className="flex w-full gap-2 mb-9">
        <span className="w-1/3 self-center">{interval?.name}{required && '*'}</span>
        <div className="flex flex-col w-1/3 pr-4">
            <div className="h-12"><input onChange={(e)=> handleItemUpdate( interval.id, 'startValue', e.target.value ) } value={interval?.startValue} className="w-full h-12 flex-1 rounded bg-slate-50 border-slate-200 border px-2 text-brand focus:outline-0 placeholder:text-slate-400 placeholder:text-sm"></input></div>
            <span className="text-red-500 text-xs mx-2 mt-1 mb-[-20px]">{error?.startValue ?? '\xa0'}</span>
        </div>
        <div className="flex flex-col w-1/3 pr-4">
            <div className="h-12"><input onChange={(e)=> handleItemUpdate( interval.id, 'endValue', e.target.value ) } value={interval?.endValue} className="w-full h-full flex-1 rounded bg-slate-50 border-slate-200 border px-2 text-brand focus:outline-0 placeholder:text-slate-400 placeholder:text-sm"></input></div>
            <span className=" text-red-500 text-xs mx-2 mt-1 mb-[-20px]">{error?.endValue ?? '\xa0'}</span>
        </div>

    </div> );
    
}
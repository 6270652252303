import { useState } from 'react';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { formatToFixed, formatUSD } from 'utils/formatter';

export default function NewInvoiceItem({ item, index, handleRemoveItem, handleItemUpdate }) {
  const [contents, setContents] = useState({ notes: '', ...item });
  const [checked, setChecked] = useState(contents.active);
  const [isEditable, setIsEditable] = useState(contents.temp && !contents.legacy);

  const handleChange = function (e, kind?, ndx = 0) {
    let mutable = { ...contents };
    if (kind === 'notes' || kind === 'discrepancy') {
      mutable[kind] = e.target.value;
    } else if (kind === 'partPriceChange') {
      mutable.parts[ndx].unitPrice = e.target.value;
      const part = mutable.parts[ndx];
      mutable.parts[ndx].subtotal = part.unitPrice * part.quantity;
    } else if (kind === 'partQuantityChange') {
      mutable.parts[ndx].quantity = e.target.value;
      const part = mutable.parts[ndx];
      mutable.parts[ndx].subtotal = part.unitPrice * part.quantity;
    } else if (kind === 'active') {
      mutable[kind] = !checked;
      setChecked(!checked);
    } else if (kind === 'isFlatRate') {
      mutable[kind] = !mutable[kind];
    } else {
      mutable[kind] = Number(e.target.value);
    }
    mutable.subTotal = mutable.laborCost + mutable.partsCost;
    setContents(mutable);
    handleItemUpdate(index, mutable);
  };
  return (
    <div
      key={item.id}
      className={`
        ${
          !checked && 'bg-white opacity-50 hover:opacity-100'
        } flex flex-col px-2 my-4 first:mt-0 relative border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b`}>
      <div className="flex justify-between py-2">
        <div>
          {!isEditable && (
            <input
              type="checkbox"
              onClick={(e) => handleChange(e, 'active')}
              className="mt-1 rounded h-3.5 w-3.5 -ml-3 mr-0.5 cursor-pointer"
              onChange={() => {}}
              id={item.id}
              name={item.id}
              checked={checked}
            />
          )}
          <p></p>
        </div>
        <div className="flex gap-2">
          {isEditable ? (
            <PencilSquareIcon
              onClick={(e) => {
                e.preventDefault();
                if (contents.discrepancy) {
                  setIsEditable(!isEditable);
                }
              }}
              title="Edit"
              className="bg-brand-electric text-brand-pale w-12 h-6 p-0.5 border border-brand-pale rounded cursor-pointer transition hover:opacity-80"
            />
          ) : (
            <PencilSquareIcon
              onClick={(e) => {
                e.preventDefault();
                setIsEditable(!isEditable);
              }}
              title="Edit"
              className="text-brand-electric bg-white hover:bg-brand-pale w-12 h-6 p-0.5 cursor-pointer border border-brand-pale rounded transition"
            />
          )}
          {contents.temp && (
            <TrashIcon
              onClick={() => {
                handleRemoveItem(index);
              }}
              title="Delete"
              className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
            />
          )}
        </div>
      </div>
      <div
        className="cursor-pointer py-3"
        onClick={(e) => {
          if (isEditable) return;
          handleChange(e, 'active');
        }}>
        <div
          className={
            `${isEditable && 'bg-brand-offwhite'}` +
            'flex flex-col peer-hover:border-l-4 peer-hover:-ml-[calc(2rem+4px)] peer-hover:-m-8 peer-hover:p-8 peer-hover:-mb-4 peer-hover:pb-4 transition'
          }>
          {isEditable && (
            <div className="flex justify-end">
              <label className="flex items-center">
                <span className="mr-2">Bill Flat Rate</span>
                <input
                  type="checkbox"
                  onClick={(e) => handleChange(e, 'isFlatRate')}
                  className="rounded h-3.5 w-3.5 mr-0.5 cursor-pointer"
                  onChange={() => {}}
                  id={item.id}
                  name={item.id}
                  checked={contents.isFlatRate}
                />
              </label>
            </div>
          )}
          <div className="flex justify-between">
            <div className="flex flex-col text-sm w-2/3">
              <span className="font-semibold">Line Item</span>
              {isEditable ? (
                <input
                  className="border shadow-inner h-8 px-2"
                  defaultValue={contents.discrepancy}
                  onChange={(e) => handleChange(e, 'discrepancy')}
                />
              ) : (
                <span className="-mt-0.5">{contents.discrepancy}</span>
              )}
            </div>
            {!contents.isFlatRate && (
              <div className="flex flex-col text-sm w-1/6 items-end">
                <span className="font-semibold">Hours</span>
                {isEditable ? (
                  <input
                    type="number"
                    className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                    defaultValue={contents.laborHours}
                    onChange={(e) => handleChange(e, 'laborHours')}></input>
                ) : (
                  <span className="-mt-0.5">{contents.laborHours}</span>
                )}
              </div>
            )}
            <div className="flex flex-col text-sm w-1/6 items-end">
              <span className="font-semibold">Subtotal</span>
              {isEditable ? (
                <input
                  type="number"
                  className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                  defaultValue={contents.laborCost}
                  onChange={(e) => handleChange(e, 'laborCost')}></input>
              ) : (
                <span className="-mt-0.5">{formatToFixed(contents.laborCost)}</span>
              )}
            </div>
          </div>
          <div className={`${!isEditable && !contents.notes ? 'hidden' : 'flex'} flex-col text-sm bg-slate-50 border px-4 py-2 mt-2`}>
            <span className="text-xs text-brand-dark font-semibold uppercase">Action Taken</span>
            {isEditable ? (
              <textarea
                className="border-slate-200 p-1 font-mono text-xs focus:ring-0 placeholder:text-brand/50 shadow-inner"
                onChange={(e) => handleChange(e, 'notes')}
                value={contents.notes}
                placeholder="Begin typing description..."
              />
            ) : (
              <span className="font-mono text-xs py-1">{contents.notes}</span>
            )}
          </div>
          <table className={`${contents.parts?.length === 0 ? '-mt-2 invoice-thead' : 'my-4 bg-white'} rounded w-full`}>
            <thead className="border border-b-0">
              <tr className="text-left text-sm border-b">
                <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
                <th className="px-2 font-semibold w-36">Part #</th>
                <th className="px-2 font-semibold">Description</th>
                <th className="px-2 font-semibold text-right">Unit Price</th>
                <th className="px-2 font-semibold text-right">Subtotal</th>
              </tr>
            </thead>
            <tbody className="border">
              {contents.parts.map((part, ndx) => {
                return (
                  <tr className="text-sm" key={ndx}>
                    {isEditable ? (
                      <input
                        type="number"
                        className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                        defaultValue={part.quantity}
                        onChange={(e) => handleChange(e, 'partQuantityChange', ndx)}></input>
                    ) : (
                      <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                    )}
                    <td className="align-top px-2">{part.partNumber}</td>
                    <td className="align-top px-2">{part.description}</td>
                    {isEditable ? (
                      <input
                        type="number"
                        className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                        defaultValue={part.unitPrice}
                        onChange={(e) => handleChange(e, 'partPriceChange', ndx)}></input>
                    ) : (
                      <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                    )}

                    <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
                  </tr>
                );
              })}
              {!contents.temp && (
                <>
                  <tr className="text-sm border-t bg-slate-50">
                    <td></td>
                    <td></td>
                    <td className="px-2 font-medium">Parts Cost:</td>
                    <td></td>
                    <td className="px-2 text-right">{formatToFixed(contents.partsCost)}</td>
                  </tr>
                  <tr className="text-sm bg-slate-50">
                    <td></td>
                    <td></td>
                    <td className="px-2 font-medium">Labor Cost:</td>
                    <td></td>
                    <td className="px-2 text-right">{formatToFixed(contents.laborCost)}</td>
                  </tr>
                </>
              )}
              <tr className="text-sm border-t bg-slate-100">
                <td></td>
                <td></td>
                <td className="px-2 font-bold">Item Subtotal:</td>
                <td></td>
                <td className="px-2 text-right font-bold">{formatUSD(contents.subTotal)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import AircraftSpruceIcon from "assets/icons/AircraftSpruceIcon";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import airsyncLogo from "assets/images/airsync_logo.png";
import airblyLogo from "assets/images/airbly_logo.jpg";
import foreflightLogo from "assets/images/foreflight_logo.jpg";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import AirSyncSetup from "./Flyouts/AirSyncSetup";
import AirblySetup from "./Flyouts/AirblySetup";
import { DisconnectAirblyDocument, DisconnectForeflightDocument, GetForeflightLoginDocument, GetOrganizationSettingsDocument, GetOrganizationSettingsQuery, GetUserInfoDocument } from "graphql/generated";
import { useSession } from "contexts";
import { useState } from "react";
import Modal from "components/Modal/Modal";
import Paywall from "components/Paywall/Paywall";
import { OrgSubtypes } from "utils/orgTypes";

const aircraftSpruceLink = "https://www.aircraftspruce.com/?utm_campaign=coflyt_partnership&utm_medium=website&utm_source=coflyt";

export default function IntergationsSettings({ organization } : { organization: GetOrganizationSettingsQuery['organization'] }) {
    const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
    const { organizationId, organizationSubtype } = useSession();
    const { data : { me } = {} } = useQuery(GetUserInfoDocument);
    const { data : { foreflightLoginLink } = {}} = useQuery(GetForeflightLoginDocument); 
    const [ disconnectAirbly ] = useMutation(DisconnectAirblyDocument, { refetchQueries: [{ query: GetOrganizationSettingsDocument, variables: { id: organizationId } }] } );
    const [ disconnectForeflight ] = useMutation(DisconnectForeflightDocument, { refetchQueries: [{ query: GetOrganizationSettingsDocument, variables: { id: organizationId } }] } );
    const [ showAirblyModal, setShowAirblyModal ] = useState(false);
    const [ showForeFlightModal, setShowForeFlightModal ] = useState(false);
    return (
        <>
            { showAirblyModal && <Modal message={'You are about to disconnect Airbly.'} accept={ () => {
                disconnectAirbly({ variables: { organizationId } });
                setShowAirblyModal(false);
            } } cancel={setShowAirblyModal} /> }
            { showForeFlightModal && <Modal message={'You are about to disconnect ForeFlight.'} accept={ () => {
                disconnectForeflight({ variables: { organizationId } });
                setShowForeFlightModal(false);
            } } cancel={setShowForeFlightModal} /> }
            <Flyout {...flyoutProps} />
            {organizationSubtype === OrgSubtypes.FREE ? <Paywall /> :
            <Card title="Intergations">
                <div className="justify-between flex items-center border-b-2 border-slate-200 rounded-b">
                    <div className="flex gap-2 items-center">
                        <a href={aircraftSpruceLink} target="_blank"><AircraftSpruceIcon className="h-20 w-20" /></a>
                        <div className="flex flex-col">
                            <span className="font-bold text-brand-dark">Aircraft Spruce</span>
                            <span className="font-semibold text-gray-400 text-sm">Everything for planes and pilots since 1965.</span>
                        </div>
                    </div>
                    <a href={aircraftSpruceLink} target="_blank"><Button text="Shop" color="navy" size="sm" /></a>
                </div>
                <div className="justify-between flex items-center py-5 border-b-2 border-slate-200 rounded-b">
                    <div className="flex gap-3 items-center">
                        <a href="https://www.air-sync.com"><img src={airsyncLogo} className="w-20" /></a>
                        <div className="flex flex-col">
                            <span className="font-bold text-brand-dark">AirSync</span>
                            <span className="font-semibold text-gray-400 text-sm">Sync your AirSync flights with Coflyt.</span>
                        </div>
                    </div>
                    <Button text="Setup" color="navy" size="sm" onClick={() => updateFlyout({ title: 'Connect AirSync', content: <AirSyncSetup closeFlyout={closeFlyout} /> })}  />
                </div>
                <div className="justify-between flex items-center mt-1 border-b-2">
                    <div className="flex gap-3 items-center">
                        <a href="http://airbly.com"><img src={airblyLogo} className="w-20" /></a>
                        <div className="flex flex-col">
                            <span className="font-bold text-brand-dark">Airbly</span>
                            <span className="font-semibold text-gray-400 text-sm">Sync your Airbly flights with Coflyt.</span>
                        </div>
                    </div>
                    { organization?.airblyReadKey ? 
                        <Button text="Disconnect" color="red" size="sm" onClick={() => setShowAirblyModal(true) }  />
                        :
                        <Button text="Setup" color="navy" size="sm" onClick={() => updateFlyout({ title: 'Airbly Setup', content: <AirblySetup closeFlyout={closeFlyout} /> })}  />
                    }
                </div>
                <div className="justify-between flex items-center mt-1">
                    <div className="flex gap-3 items-center pl-2">
                        <a href="http://foreflight.com"><img src={foreflightLogo} className="w-16" /></a>
                        <div className="flex flex-col pl-2">
                            <span className="font-bold text-brand-dark">ForeFlight</span>
                            <span className="font-semibold text-gray-400 text-sm">Sync your Foreflight logs with Coflyt.</span>
                        </div>
                    </div>
                    {me?.selectedOrganizationProfile?.foreflightInfo ? 
                        <Button text="Disconnect" color="red" size="sm" onClick={() => setShowForeFlightModal(true) }  />
                    :
                        <>{foreflightLoginLink && <a href={foreflightLoginLink}><Button text="Setup" color="navy" size="sm" onClick={() =>{} }  /></a>}</>
                    }
                </div>
            </Card> }
        </>
    )
}
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export default function Redirect({ url = '' }: {url: string}) {
    useEffect(() => {
        window.location.href = url;
    });
    return ( 
        <div>
            <h1>Redirecting...</h1>
        </div>
    )
}
import { useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { AirplaneIcon } from "assets/icons";
import StatusButton from "components/StatusButton/StatusButton";
import { Table } from "components/Table/Table";
import { useSession } from "contexts";
import { GetServiceRequestsDocument } from "graphql/generated";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatApiDate } from "utils/formatter";
import { orderCase } from "utils/statuses";

export function OpenServiceRequests() {
    const { user } = useSession();
    const navigate = useNavigate();
    const { data: { mroServiceRequests } = {} } = useQuery( GetServiceRequestsDocument, { variables: { where: {
        status: { in: ['OPEN'] },
        mroOrganizationId: { equals: user?.mroOrganizationId },
    } } } );
    const [search, setSearch] = useState('');
    //SERVICE REQUEST COLUMNS
    const serviceColumns = useMemo(
        () => [
          {
            Header: 'Aircraft',
            accessor: (obj) => [obj?.mroCraft?.tailNumber, `${obj?.mroCraft?.make} ${obj?.mroCraft?.model}`, obj?.status],
            className: 'w-64 shrink-0',
            Cell: ({ value, row }: any) => {
              if (Array.isArray(value)) {
                return (
                  <div className="flex items-center gap-2">
                    <div
                      className={`${orderCase[value[2]]?.textColor} ${
                        orderCase[value[2]]?.bgColor
                      } rounded h-8 w-8 flex items-center justify-center`}>
                      <AirplaneIcon className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-lg font-bold text-brand-dark">{value[0]}</span>
                      <span className="-mt-2">{value[1]}</span>
                    </div>
                  </div>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'Description',
            accessor: 'title',
            className: 'w-full',
          },
          {
            Header: 'Customer',
            accessor: 'mroCustomer.name',
            className: 'flex-wrap w-full',
          },
          {
            Header: 'Requested',
            accessor: 'createdAt',
            className: 'w-full justify-center max-w-[8rem]',
            Cell: ({ value }) => {
              return formatApiDate(value);
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            className: 'w-64 shrink-0',
            Cell: ({ value, row }: any) => {
              return (
                <StatusButton
                  readOnly={true}
                  context="order"
                  text={row.values.mroWorkOrderId ? value + '/Work Order Created' : value}
                  className=""
                  onSelect={(e) => {}}
                />
              );
            },
          },
        ],
        []
    );
    return (
        <div className=" bg-white shadow-blue">
            <div className="flex flex-col">
                <div className="flex items-center justify-between py-4 px-6">
                    <h1 className="text-brand-dark text-left font-bold text-lg">Open Service Requests</h1>
                    <div className="flex items-center gap-2">
                        <div className="relative flex items-center">
                            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                            <input
                                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </div>
                        {/* <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" /> */}
                    </div>
                </div>
                <div className="flex flex-wrap pt-1 pb-2 overflow-x-auto">
                    <Table
                        columns={serviceColumns}
                        data={mroServiceRequests ?? []}
                        className="sm-head w-full"
                        onRowClick={(row) => navigate('/mro/mro-service-requests/'+row.id)}
                    />
                </div>
            </div>
        </div>
    );
}
import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_1645)">
        <path
          d="M8.19995 14C12.9069 14 15.9439 8.716 16.0709 8.492C16.2419 8.188 16.2429 7.816 16.0719 7.512C15.9459 7.287 12.9309 2 8.19995 2C3.44495 2 0.450949 7.289 0.325949 7.514C0.156949 7.817 0.157949 8.186 0.327949 8.489C0.453949 8.713 3.46895 14 8.19995 14ZM8.19995 4C11.0389 4 13.2359 6.835 14.0179 8C13.2339 9.166 11.0369 12 8.19995 12C5.35895 12 3.16195 9.162 2.38095 7.999C3.15795 6.835 5.34595 4 8.19995 4Z"
          fill="currentColor"
        />
        <path
          d="M8.19995 10C9.30452 10 10.2 9.10457 10.2 8C10.2 6.89543 9.30452 6 8.19995 6C7.09538 6 6.19995 6.89543 6.19995 8C6.19995 9.10457 7.09538 10 8.19995 10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_1645">
          <rect width="16" height="16" fill="white" transform="translate(0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

import { useMutation, useQuery } from '@apollo/client'; // need for signing
import { useState } from 'react';
import { formatApiDate } from 'utils/formatter';
import { ArrowDownTrayIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline';
import { BarsArrowDownIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { SoundwaveIcon } from 'assets/icons';
import LogEntryRow from '../partials/LogEntryRow';
import { GetMroLogEntryDocument, GetMroOrganizationNameDocument, GetWorkOrderLogEntriesDocument, UpdateMroLogEntryDocument } from 'graphql/generated';
import { logEntryCase } from 'utils/statuses';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import EditLogEntryFlyout from '../Edit';
import Modal from 'components/Modal/Modal';
import { useSession } from 'contexts';

export default function LogEntryFlyout({ logEntryId }: { logEntryId: string }) {
  const [expandAll, setExpandAll] = useState(true);
  const [modal, setModal] = useState(false);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();

  const { data: { mroLogEntry } = {} } = useQuery(GetMroLogEntryDocument, {
    variables: { mroLogEntryId: logEntryId },
  });

  const [updateLogEntry] = useMutation(UpdateMroLogEntryDocument, {
    refetchQueries: [GetWorkOrderLogEntriesDocument, { query: GetMroLogEntryDocument, variables: { mroLogEntryId: logEntryId } }],
  });

  const { user } = useSession();
  const { data: { getMroOrganizationName : orgName = "" } = {} } = useQuery(GetMroOrganizationNameDocument, { variables: { orgId: user.mroOrganizationId } });
  const signer = mroLogEntry?.signer ? mroLogEntry?.signer?.firstName + ' ' + mroLogEntry?.signer?.lastName : orgName;
  return (
    <>
      <Flyout {...flyoutProps} />
      {modal && (
        <Modal
          cancel={setModal}
          accept={() => updateLogEntry({ variables: { input: { id: logEntryId, status: 'VOID' } } })}
          message={'You are about to void ' + mroLogEntry?.title + ', this cannot be undone.'}
        />
      )}
      <div className={`flex flex-col gap-2 p-4 ${!logEntryId && 'flex-col items-center'}`}>
        <div className="flex justify-end">
          <a href={mroLogEntry?.pdfLink} target="_blank" rel="noreferrer" download>
            <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
          </a>
        </div>
        <div className="flex flex-col text-sm bg-white p-4 border border-slate-200 rounded">
          <div className="flex justify-between h-8 items-center">
            <span className="font-bold text-brand">Status:</span>
            <div
              className={`${
                mroLogEntry?.status ? logEntryCase[mroLogEntry?.status].textColor + ' ' + logEntryCase[mroLogEntry?.status].bgColor : ''
              }
              text-xs uppercase w-24 rounded py-1 px-2 text-center font-bold`}>
              {mroLogEntry?.status}
            </div>
          </div>
          <div className="flex justify-between h-8 items-center">
            <span className="font-bold text-brand">Signed:</span>
            <span>{mroLogEntry?.signedAt || mroLogEntry?.status === 'VOID' ? formatApiDate(mroLogEntry?.signedAt) : 'N/A'}</span>
          </div>
          <div className="flex justify-between h-8 items-center">
            <span className="font-bold text-brand">Signer:</span>
            <span>{mroLogEntry?.signedAt ? signer : 'N/A'}</span>
          </div>
          <div className="flex justify-between h-8 items-center">
            <span className="font-bold text-brand">Serial Number:</span>
            <span>{mroLogEntry?.mroComponent?.serialNumber}</span>
          </div>
        </div>
        <div className="flex flex-col border bg-white rounded shadow border-slate-200 p-6 mt-3">
          <div className="flex justify-between items-center w-full relative">
            <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">
              Intervals ({mroLogEntry?.intervals.length})
            </span>
          </div>
          <hr className="my-2 -m-6" />
          {mroLogEntry?.intervals.length > 0 && (
            <div className="flex flex-wrap -mx-0.5 mt-1">
              {mroLogEntry?.intervals.map((interval, idx) => (
                <div key={idx} className={`${mroLogEntry?.intervals.length === 1 ? 'w-full' : 'w-1/2'} p-0.5`}>
                  <div className="flex items-center relative">
                    <span className="w-3/5 h-6 px-2 bg-slate-50 text-sm font-medium rounded-l border border-r-0 border-slate-200 placeholder:text-slate-400">
                      {interval.name}
                    </span>
                    <span className="w-3/5 h-6 px-2 bg-slate-50 text-sm font-medium rounded-l border border-r-0 border-slate-200 placeholder:text-slate-400">
                      {interval.intervalLogs?.[0]?.startValue}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={`flex flex-col rounded w-full`}>
          <div className="flex justify-between items-center text-brand-dark font-bold p-4 border border-slate-200 bg-white rounded-t shadow-blue-t">
            <div className="flex items-center gap-2">
              <SoundwaveIcon className="h-6 w-6 p-1 bg-gray-100 shadow rounded-sm" />
              <h1>Squawks</h1>
            </div>
            {expandAll ? (
              <BarsArrowUpIcon
                onClick={() => {
                  setExpandAll(false);
                }}
                className="h-4 w-4 cursor-pointer"
              />
            ) : (
              <BarsArrowDownIcon
                onClick={() => {
                  setExpandAll(true);
                }}
                className="h-4 w-4 cursor-pointer"
              />
            )}
          </div>
          <div className="flex flex-col w-full py-1 px-2 bg-white rounded-b border-t-0 border border-slate-200">
            {mroLogEntry?.mroLogEntryItems.map((entry, index) => {
              return (
                <LogEntryRow
                  itemNumber={entry?.mroWorkOrderItem?.itemNumber}
                  description={entry.description}
                  key={entry.id}
                  index={index}
                  expandAll={expandAll}
                />
              );
            })}
          </div>
        </div>
        <div className="flex flex-col text-sm bg-white p-4 border border-slate-200 rounded">
          <h1 className="font-bold">RTS Statment</h1>
          <span className="rounded border p-4 text-sm border-slate-200 bg-white disabled:resize-none w-full mt-2">
            {mroLogEntry?.certificationText}
          </span>
        </div>
        {mroLogEntry?.status !== 'VOID' && (
          <div className="flex self-end text-sm p-4 gap-5">
            {mroLogEntry?.status !== 'SIGNED' && (
              <button
                onClick={() =>
                  updateFlyout({ title: 'Edit and Sign', content: <EditLogEntryFlyout logEntry={mroLogEntry} closeFlyout={closeFlyout} /> })
                }
                className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                <>
                  <PencilIcon className="h-2.5 w-2.5 mr-1" />
                  Edit and Sign
                </>
              </button>
            )}
            <button
              onClick={() => setModal(true)}
              className="flex items-center font-semibold bg-brand-purple text-white border border-brand-purple cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
              <>
                <TrashIcon className="h-2.5 w-2.5 mr-1" />
                Void
              </>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

import { Formik, Form, Field } from 'formik';
import { FormLabel, FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation } from '@apollo/client';
import {
  CreatePartDocument,
  GetMroPartRequestDocument,
  GetMroPartsDocument,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';
import { ManufacturerLookup } from '../Components/ManufacturerLookup';

export default function NewPart({ closeFlyout, partNumber, workOrderPartId, partDescription }: { closeFlyout: (didCreate?: boolean) => void, partNumber?: string, workOrderPartId?: string, partDescription?: string }) {
  const initialValues = {
    partNumber: partNumber ?? '',
    description: partDescription ?? '',
    manufacturer: '',
    isSerialized: false,
  };
  const { user } = useSession();
  const [createPart] = useMutation(CreatePartDocument, {
    refetchQueries: [
      { query: GetMroPartsDocument, variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } } },
      ...( workOrderPartId ? [GetMroPartRequestDocument] : [])
    ],
  });

  const [ selectedManufacturer, setSelectedManufacturer ] = useState<any>();
  const [ manufacturer, setManufacturer ] = useState<string>('');

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      createPart({
        variables: {
          input: {
            partNumber: values.partNumber,
            description: values.description,
            manufacturer: { connectOrCreate: { 
              where: { id: selectedManufacturer?.id ?? '1' },
              create: { name: manufacturer }
            } },
            mroOrganization: { connect: { id: user?.mroOrganizationId } },
            isSerialized: values.isSerialized,
            ...(workOrderPartId && { mroWorkOrderParts: { connect: [{ id: workOrderPartId }], } })
          },
        },
      })
        .then(() => closeFlyout(true))
        .catch(console.error);
    },
    [createPart, closeFlyout, user, selectedManufacturer, manufacturer]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikInput name="partNumber" label="Part #" className='w-1/2' />
                <div className="w-1/2">
                  <ManufacturerLookup { ...{ selectedManufacturer, setSelectedManufacturer, manufacturer, setManufacturer } } />
                </div>
              </div>
              <div className="flex gap-1">
                <FullFormikInput name="description" label="Description" />
              </div>
              <div className="flex justify-between mt-5 gap-1">
                <FormLabel>Serialized Part</FormLabel>
                <Field type="checkbox" name="isSerialized" label="Test" />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              <Button text="Create Part" color="navy" size="xs" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

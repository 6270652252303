import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import { formatDateChange } from 'utils/formatter';
import { addTime, subTime, toLocalISOTime } from 'utils/time';

const DatePicker = (props) => {
  const { setFieldValue } = useFormikContext();
  const currentDate = props?.value ? new Date(props?.value) : new Date();
  const currentTime = format(currentDate, 'yyyy-MM-dd');
  const minTime = props?.min && toLocalISOTime(addTime(props?.min, 30)).slice(0, 16);
  const maxTime = props?.max && toLocalISOTime(subTime(props?.max, 30)).slice(0, 16);
  
  return (
    <input
      type="date"
      {...{
        ...props,
        className: 'rounded border px-2 w-2/3 border-gray-300 ' + (props?.className ?? ''),
        value: currentTime,
        min: minTime,
        max: maxTime,
        onChange : (e) => {
          const newDate = formatDateChange(e.target.value);
          setFieldValue(props.name, newDate);
          props.afterChange && props.afterChange(e);
        }
      }}></input>
  );
};
export default DatePicker;

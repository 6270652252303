import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { ServiceFormContext } from '..';
import { useQuery } from '@apollo/client';
import { GetMroOrganizationLocationsDocument } from 'graphql/generated';

const fieldCss = 'slc-checked left-2 border-2 checked:bg-none border-brand-pale checked:border-brand-electric focus:ring-0 bg-slate-50',
  labelCss = 'w-full border border-brand-pale rounded p-2 pl-8 text-sm font-semibold text-brand cursor-pointer';

const Issues = (props) => {
  const { formStep, changeStep, setIssuesInput, issuesInput, mroOrganization } = useContext(ServiceFormContext);
  const [view, setView] = useState('selector');
  const { data: { getMroOrganizationLocations : locations } = {} } = useQuery( GetMroOrganizationLocationsDocument, { variables: { orgId: mroOrganization?.id } } )
  const handleSubmit = (values, { resetForm }: FormikHelpers<typeof props.issueValues>) => {
    setIssuesInput(values);
    resetForm();
  };
  useEffect(() => {
    if (locations && issuesInput.location === '') {
      setIssuesInput({ ...issuesInput, location: locations[0].code });
    }
  }, [locations]);
  return (
    <div className="flex flex-col w-96 p-6">
      <Formik initialValues={issuesInput} onSubmit={handleSubmit} enableReinitialize>
        {({ setFieldValue, submitForm, values }) => (
          <Form className="mt-2">
            <div className={`${view === 'selector' ? '' : 'hidden'} flex flex-col`}>
              <h2 className="text-2xl font-bold text-brand">What can we help you with?</h2>
              <span className="text-sm text-slate-500 mb-4">Let us know which service you are interested in.</span>
              <div role="group" className="flex flex-col">
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="General Maintenance" id="gm" />
                  <label htmlFor="gm" className={labelCss}>
                    General Maintenance
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="AOG" id="aog" />
                  <label htmlFor="aog" className={labelCss}>
                    AOG
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="Inspections" id="insp" />
                  <label htmlFor="insp" className={labelCss}>
                    Inspections
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="Avionics" id="avi" />
                  <label htmlFor="avi" className={labelCss}>
                    Avionics
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="Prebuy Services" id="ps" />
                  <label htmlFor="ps" className={labelCss}>
                    Prebuy Services
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="Warranty" id="warr" />
                  <label htmlFor="warr" className={labelCss}>
                    Warranty
                  </label>
                </div>
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="service" value="Other" id="oth" />
                  <label htmlFor="oth" className={labelCss}>
                    Other
                  </label>
                </div>
              </div>
              {values.service === 'Other' && (
                <div className="flex items-center relative mt-2">
                  <Field className={labelCss + ' pl-1'} name="otherService" placeholder="Type of Service" required></Field>
                </div>
              )}
              <div className="flex justify-between mt-8">
                <button
                  type="button"
                  className="text-brand underline font-medium text-sm float-right"
                  onClick={() => changeStep(formStep, false, true)}>
                  Go Back
                </button>
                <Button
                  text="Continue"
                  size="sm"
                  color="navy"
                  className="disabled:opacity-20"
                  disabled={!values.service}
                  onClick={() => {
                    setView('description');
                  }}
                  type="button"
                />
              </div>
            </div>
            <div className={view === 'selector' ? 'hidden' : ''}>
              <h2 className="text-3xl md:text-2xl font-bold text-brand">What should we know?</h2>
              <span className="md:text-sm text-slate-500">Clarify any specific details below.</span>
              <div className="flex flex-col mt-4">
                <label className="text-brand text-sm font-semibold">Notes</label>
                <Field
                  as="textarea"
                  className="rounded border border-slate-300 bg-slate-50 px-2 text-brand focus:shadow-inner"
                  name="description"
                  rows="6"
                  required></Field>
                <ErrorMessage name="description" component="div" className="text-red-500 text-xs italic" />
              </div>
              <hr className="mt-8 mb-4" />
              <h2 className="text-3xl md:text-2xl font-bold text-brand">Location of Service</h2>
              <div role="group" className="flex flex-col">
                { locations?.map((location, index) => (
                  <div key={location.id} className="flex items-center relative mt-2">
                    <Field className={fieldCss} type="radio" name="location" value={location.code} id={location.id} />
                    <label htmlFor={location.id} className={labelCss}>
                      {location.code}
                    </label>
                  </div>
                ) ) }
                <div className="flex items-center relative mt-2">
                  <Field className={fieldCss} type="radio" name="location" value={"other"} id="other" />
                  <label htmlFor="other" className={labelCss}>
                    Other
                  </label>
                </div>
              </div>
              { values.location == "other" && <Field
                className={'rounded border py-2 border-slate-300 bg-slate-50 w-full text-brand focus:shadow-inner pl-1 mt-4'}
                name="otherLocation"
                placeholder="Other Location (KJFK, KTEB, etc.)"
                required></Field> }
              <hr className="mt-8 mb-4" />
              <div className="flex justify-between">
                <button type="button" className="text-brand underline font-medium text-sm float-right" onClick={() => setView('selector')}>
                  Go Back
                </button>
                <Button
                  text="Continue"
                  size="sm"
                  color="navy"
                  onClick={() => {
                    submitForm();
                    changeStep(formStep, 'confirm');
                  }}
                  type="button"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Issues;

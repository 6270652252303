import { SerializedItem } from '../Partials/AddSerializedParts';
import { GetMroPartWarehouseQuery } from 'graphql/generated';
import { PartConditions } from './PartConditions';

export default function ReceiveSerializedPartItem({
  contents,
  updateContent,
  mroPartWarehouses,
}: {
  contents: SerializedItem;
  updateContent: any;
  mroPartWarehouses: GetMroPartWarehouseQuery['mroPartWarehouses'];
}) {
  return (
    <div
      key={contents.index}
      className={`flex justify-between items-start w-full px-2 border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b transition-all py-3`}>
      <div className="flex flex-col w-full text-slate-700 gap-2">
        <div className="flex justify-between items-stretch gap-2">
          <div className="flex flex-col w-3/6">
            <label className="text-xs text-slate-700 font-semibold">Serial Number</label>
            <input
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 strip-style"
              defaultValue={contents.serialNumber}
              onChange={(e) => updateContent(e.target.value, contents.index, 'serialNumber')}
            />
          </div>
          <div className="flex flex-col w-2/6">
            <label className="text-xs text-slate-700 font-semibold">Condition</label>
            <select
              value={contents.condition}
              onChange={(e) => updateContent(e.target.value, contents.index, 'condition')}
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 strip-style">
              <PartConditions />
            </select>
          </div>
          <div className="flex flex-col w-1/6">
            <label className="text-xs text-slate-700 font-semibold">Unit Price</label>
            <input
              type="number"
              min={0}
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 text-right strip-style"
              defaultValue={Number(contents.unitPrice).toFixed(2)}
              onChange={(e) => updateContent(Number(e.target.value).toFixed(2), contents.index, 'unitPrice')}
            />
          </div>
        </div>
        <div className="flex justify-between items-stretch gap-2">
          <div className="flex flex-col w-1/3">
            <label className="text-xs text-slate-700 font-semibold">Lot Number</label>
            <input
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 text-right strip-style"
              defaultValue={contents.lotNumber}
              onChange={(e) => updateContent(e.target.value, contents.index, 'lotNumber')}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-xs text-slate-700 font-semibold">Warehouse</label>
            <select
              value={contents.warehouse}
              onChange={(e) => updateContent(e.target.value, contents.index, 'warehouse')}
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 strip-style">
              <option key={'n/a'} value={'n/a'}>
                N/A
              </option>
              {mroPartWarehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-xs text-slate-700 font-semibold">Bin</label>
            <input
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-12 md:h-10 text-right strip-style"
              defaultValue={contents.bin}
              onChange={(e) => updateContent(e.target.value, contents.index, 'bin')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

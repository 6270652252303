import { FormLabel } from 'components/Form/StandardForm';
import VendorLookup from '../Components/VendorLookup';
import { useEffect } from 'react';

const EditOrView = ({ className, valueKey, placeholder, edit, vendorInfo, setVendorInfo, label = undefined }) => {
  const baseClass = 'font-semibold border border-slate-300 px-1 text-sm rounded bg-white ';
  if (edit) {
    return (
      <input
        key={valueKey}
        type="text"
        className={baseClass + className}
        value={vendorInfo[valueKey]}
        placeholder={placeholder}
        onChange={(e) => setVendorInfo({ ...vendorInfo, [valueKey]: e.target.value })}
      />
    );
  } else {
    return (
      <div key={valueKey} className={'flex flex-col mr-16'}>
        {label && (<FormLabel>{label}</FormLabel>)}
        <span key={valueKey} className={'text-sm w-full'}>
          {vendorInfo[valueKey]}
        </span>
      </div>
    );
  }
};

export default function VendorCard({
  vendorInfo,
  setVendorInfo,
  selectedVendor,
  setSelectedVendor,
  edit,
}: {
  vendorInfo: any;
  setVendorInfo: any;
  selectedVendor: any;
  setSelectedVendor: any;
  edit: boolean;
}) {
  useEffect(() => {
    if (selectedVendor === 'newCode') {
      setVendorInfo({
        code: vendorInfo.vendorCode,
        name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
      });
    } else if (selectedVendor) {
      setVendorInfo({
        code: selectedVendor.vendorCode,
        name: selectedVendor.name,
        address: selectedVendor?.address?.address ?? '',
        address2: selectedVendor?.address?.address2 ?? '',
        city: selectedVendor?.address?.city ?? '',
        state: selectedVendor?.address?.region ?? '',
        zip: selectedVendor?.address?.postalCode ?? '',
        phone: selectedVendor?.phoneNumbers?.phone ?? '',
        fax: selectedVendor?.phoneNumbers?.fax ?? '',
      });
    }
  }, [selectedVendor]);

  return (
    <div className="flex flex-col gap-1 px-2">
      <div className="flex justify-between items-center w-full gap-3">
        {edit ? (
          <VendorLookup
            {...{
              selectedVendor,
              setSelectedVendor,
              vendorCode: vendorInfo.code,
              setVendorCode: (code) => setVendorInfo({ ...vendorInfo, code: code }),
              className: 'w-full',
            }}
          />
        ) : (
          <div className="flex flex-col mb-3 mr-16">
            <FormLabel>Vendor Code</FormLabel>
            <EditOrView className="" valueKey="code" placeholder="Vendor Code" {...{ edit, vendorInfo, setVendorInfo }} />
          </div>
        )}
        <div className="flex flex-col mb-3 w-full">
          <FormLabel>Name</FormLabel>
          <EditOrView className="" valueKey="name" placeholder="Vendor Name" {...{ edit, vendorInfo, setVendorInfo }} />
        </div>
      </div>
      <div className="flex flex-col w-full gap-2">
        <FormLabel>Address</FormLabel>
        <EditOrView className="" valueKey="address" placeholder="Address" {...{ edit, vendorInfo, setVendorInfo }} />
        <EditOrView className="" valueKey="address2" placeholder="Address Line 2" {...{ edit, vendorInfo, setVendorInfo }} />
        <div className="flex items-center w-full gap-2">
          <EditOrView className="w-3/6" valueKey="city" placeholder="City" label="City" {...{ edit, vendorInfo, setVendorInfo }} />
          <EditOrView className="w-1/6" valueKey="state" placeholder="State" label="State" {...{ edit, vendorInfo, setVendorInfo }} />
          <EditOrView className="w-2/6" valueKey="zip" placeholder="Postal Code" label="Postal Code" {...{ edit, vendorInfo, setVendorInfo }} />
        </div>
      </div>
      <div className="flex flex-col w-full mt-2 gap-2">
        <div className="flex items-center w-full gap-2">
          <div className="flex flex-col mb-3 w-full">
            <FormLabel>Phone Number</FormLabel>
            <EditOrView className="" valueKey="phone" placeholder="Phone #" {...{ edit, vendorInfo, setVendorInfo }} />
          </div>
          <div className="flex flex-col mb-3 w-full">
            <FormLabel>Fax Number</FormLabel>
            <EditOrView className="" valueKey="fax" placeholder="Fax #" {...{ edit, vendorInfo, setVendorInfo }} />
          </div>
        </div>
      </div>
    </div>
  );
}

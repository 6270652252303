import { useState, useMemo, useEffect, useContext } from 'react';
import { Table } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { GetMroPartOrdersDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import { formatApiDate } from 'utils/formatter';
import StatusButton from 'components/StatusButton/StatusButton';
import UserIcon from 'components/UserIcon/UserIcon';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import ViewPartOrder from './View';
import { PartsContext } from '..';
import { create, insertBatch, Lyra, PropertiesSchema, search } from '@lyrasearch/lyra';

const lyraSchema: PropertiesSchema = {
  partOrderNumber: 'string',
  vendor: 'string',  
};

const Orders = function ({
  updateFlyout,
  closeFlyout,
  partId,
}: {
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  partId?: string;
}) {
  const [activeTab, setActiveTab] = useState('Open');
  const tabs = ['Open', 'Closed', 'All'];
  const { user } = useSession();
  const { data: { mroPartOrders = [] } = {} } = useQuery(GetMroPartOrdersDocument, {
    variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } },
  });
  const { filter } = useContext(PartsContext);
  const [searchIndex, setSearchIndex] = useState<Lyra<typeof lyraSchema>>();
  const [tableData, setTableData] = useState([]);

  // HANDLES FILTER TAB FUNCTIONALITY
  useEffect(() => {
    if (mroPartOrders?.length)
      (async () => {
        let mutable = mroPartOrders;
        if (activeTab !== 'All') {
          if (activeTab === 'Open') {
            mutable = mroPartOrders?.filter((orders) => orders.status !== 'VOID' && orders.status !== 'RECEIVED');
          } else {
            mutable = mroPartOrders?.filter(
              (req) =>
                req.status === 'REQUESTED' ||
                req.status === 'AWAITING_BACKORDER' ||
                req.status === 'ORDERED' ||
                req.status === 'PENDING' ||
                req.status === 'BACKORDER_CREATED' ||
                req.status === 'PENDING_BACKORDER'
            );
          }
        }
        if (filter) {
          const result = await search(searchIndex, { term: filter, properties: '*' });
          const ids = result.hits.map((item) => item.id);
          mutable = mutable.filter((profile) => ids.includes(profile.id));
        }
        setTableData(mutable);
      })();
  }, [mroPartOrders, filter, searchIndex, activeTab, partId]);

  useEffect(() => {
    if (mroPartOrders?.length) {
      create({
        schema: lyraSchema,
      }).then((db) =>
        insertBatch(
          db,
          mroPartOrders.map((order) => ({
            id: order.id,
            partOrderNumber: order.orderNumber,
            vendor: order.mroPartVendor?.name,
          }))
        ).then(() => setSearchIndex(db))
      );
    }
  }, [mroPartOrders]);
  const columns = useMemo(
    () => [
      {
        Header: 'PO #',
        accessor: 'orderNumber', // accessor is the "key" in the data
        className: 'w-1/6',
        Cell: ({ value, row }: any) => {
          return <span className="font-bold bg-brand text-white rounded px-2 py-1">#{value}</span>;
        },
      },
      {
        Header: 'Vendor',
        accessor: 'mroPartVendor',
        className: 'w-2/6',
        Cell: ({ value }: any) => {
          return value?.name ? <span className="text-slate-500">{value.name}</span> : <></>;
        },
      },
      {
        Header: 'Buyer',
        accessor: 'buyer',
        className: 'justify-end w-1/12 min-w-[96px]',
        Cell: ({ value }: any) => {
          return value ? (
            <UserIcon
              iconSize="sm"
              iconText={value?.firstName + ' ' + value?.lastName}
              iconId={value?.firstName + ' ' + value?.lastName}
              iconColor={value?.profileColor}
              className=""
            />
          ) : (
            <></>
          );
        },
      },
      {
        Header: 'Items',
        accessor: 'mroPartOrderItems.length',
        className: 'justify-end w-1/12 min-w-[96px]',
        Cell: ({ value }: any) => {
          return <span className="text-slate-500">{value}</span>;
        },
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: 'justify-center w-1/6',
        Cell: ({ value, row }: any) => {
          return <span className="text-slate-500">{formatApiDate(value)}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-1/6 min-w-[180px]',
        Cell: ({ value }: any) => {
          return <StatusButton readOnly={true} context="partOrder" text={value ?? 'N_/_A'} className="" onSelect={(e) => {}} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <div
        className={`flex flex-col justify-between items-start border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -mt-2 ${
          !partId && '-ml-6 mb-3 px-8'
        }`}>
        <div className="flex justify-between w-full">
          <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
        </div>
      </div>
      <div className={`bg-white border-slate-200 ${partId && 'border'}`}>
        <Table
          columns={columns}
          data={tableData}
          onRowClick={(row) => updateFlyout({ title: 'Order #' + row.orderNumber, content: <ViewPartOrder partOrderId={row.id} /> })}
        />
      </div>
    </>
  );
};

export default Orders;

import { useMutation, useQuery } from '@apollo/client';
import {  
    CreateFavoriteRouteDocument,
  GetFavoriteRoutesDocument
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { FullFormikInput } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import RouteItem, { Route } from '../../Components/RouteItem';
import { useState } from 'react';

const NewFavoriteRoute = function ({ closeFlyout }: { closeFlyout: () => void }) {
    const { organizationId } = useSession();
    const [createFavoriteRoute] = useMutation(CreateFavoriteRouteDocument, { 
        refetchQueries: [{ query: GetFavoriteRoutesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const [route, setRoute] = useState<Route[]>([{ icao:'' }, { icao:'' }]);
    function removeAirport(index: number) {
        const newRoute = [...route];
        newRoute.splice(index, 1);
        setRoute(newRoute);
      }
    
      function updateAirport(index: number, type: string, value: string) {
        const newRoute = [...route];
        newRoute[index][type] = value;
        setRoute(newRoute);
      }
    return (
    <>
        <Formik enableReinitialize
        initialValues={{ name : ''}}
        onSubmit={(values) => createFavoriteRoute({ variables: { input:{ name: values.name, 
            icaos: route.map((airport) => airport.icao),
            ...( route.filter(airport => airport.id).length > 0 && { airports: { connect: route.filter((airport) => airport?.id).map((airport) => ({ id: airport.id })) } }),
            organization: { connect: { id: organizationId } } } } }) }>
        {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
                <Form>
                    <Card className="flex flex-col p-4 h-full m-4">
                        <FullFormikInput name="name" label="Name" autoComplete="off" />
                        <h3 className="text-lg font-semibold my-2">Route</h3>
                        <div className="flex w-full justify-between gap-7 mb-9">
                            <div className='flex flex-col w-full'>
                            {route.map((airport, index) => (
                                <RouteItem key={index} temp={ index > 1 } index={index} route={airport} handleItemUpdate={updateAirport} handleRemoveItem={removeAirport} />
                            ))}
                            </div>
                        </div>
                    </Card>
                    <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
                        <Button text="Add New Route" type='submit' size='xs' color='navy' />
                    </div>
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default NewFavoriteRoute;

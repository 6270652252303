import { ErrorMessage, Field } from 'formik';
import { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef, useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { BoltIcon, Cog6ToothIcon, FlagIcon, SignalIcon, WrenchIcon } from '@heroicons/react/20/solid';
import { EngineIcon, AirplaneIcon, SoundwaveIcon, StatisticsIcon } from 'assets/icons';

export const FormInput = forwardRef<HTMLInputElement, ComponentPropsWithRef<'input'> & { stripStyle: boolean }>(function FormInput(
  { stripStyle, ...props },
  ref
) {
  const className = props.className ?? '';
  delete props.className;
  return (
    <input
      className={
      `rounded border border-slate-300 bg-slate-50 ${props?.type !== 'checkbox' && 'h-12 md:h-10 mb-3 px-2'} text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm w-full disabled:bg-slate-100 disabled:cursor-not-allowed` +
        (stripStyle ? ' strip-style ' : ' ') +
        (props.type === 'number' ? 'text-right no-spinner' : '') +
        className
      }
      {...props}
      ref={ref}
    />
  );
});

export const FormLabel = forwardRef<HTMLLabelElement, ComponentPropsWithRef<'label'> & { locked?: boolean; }>(function FormLabel(props, ref) {
  return <label className={`text-sm font-medium text-brand ${props.locked && 'opacity-30'}`} {...props} ref={ref} />;
});

export const StyledFormikError = (props: ComponentPropsWithoutRef<typeof ErrorMessage>) => (
  <span className="absolute -bottom-1 right-1 text-red-500 text-xs">
    <ErrorMessage name={props.name} component="div" className="text-red-500 text-xs italic" />
  </span>
);

export const FieldContainer = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return <div className="flex flex-col w-full">{children}</div>;
};

export const StyledFormikField = forwardRef<typeof Field, ComponentPropsWithRef<typeof Field> & { stripStyle: boolean, isVertical: boolean }>(
  function StyledFormikField(props, ref) {
    const isVertical = props.isVertical ?? false;
    const validate = props.validate ?? undefined;
    return (
      <div className={isVertical ? "w-2/3" :"relative"}>
        <Field validate={validate} ref={ref} as={FormInput} {...props} />
        <StyledFormikError name={props.name} />
      </div>
    );
  }
);

export const StyledFormikSelect = forwardRef<typeof Field, ComponentPropsWithRef<typeof Field>>(function StyledFormikSelect(props, ref) {
  return (
    <div className="relative">
      <Field
        ref={ref}
        as="select"
        className="rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm w-full pr-8 text-ellipsis"
        {...props}></Field>
      <StyledFormikError name={props.name} />
    </div>
  );
});

export const FullFormikInput = ({
  label,
  name,
  className,
  autoComplete,
  isVertical,
  fieldClassName,
  validate,
  ...fieldProps
}: { label?: string; name: string; stripStyle?: boolean; isVertical?: boolean; validate?: any; fieldClassName?: string; } & ComponentPropsWithoutRef<'input'>) => {
  return (
    <div className={className ?? 'w-full'}>
      <FieldContainer>
        {!isVertical && label && <FormLabel>{label}</FormLabel>}
        <div className={`${isVertical && 'flex justify-between gap-5 items-end'}`}> 
          {isVertical && label && <FormLabel className='mb-3'>{label}</FormLabel>}
          <StyledFormikField validate={validate} name={name} isVertical={isVertical} {...{ ...( fieldClassName && { className: fieldClassName } ) }} {...fieldProps} />
        </div>
      </FieldContainer>
    </div>
  );
};

export const FullFormikCheckBox = ({
  label,
  name,
  className,
  isVertical,
  opposite,
  locked,
  ...fieldProps
}: { label?: string; name: string; stripStyle?: boolean; isVertical?: boolean; opposite?: boolean; locked?: boolean; } & ComponentPropsWithoutRef<'input'>) => {
  return (
    <div className={className ?? 'h-full'}>
      <FieldContainer>
        {!isVertical && label && <FormLabel>{label}</FormLabel>}
        <div className={`flex justify-center ${isVertical && 'gap-5 items-end'}`}> 
          {!opposite && <StyledFormikField name={name} type="checkbox" disabled={locked} className={`form-checkbox mt-2 p-3 ${locked && 'cursor-not-allowed opacity-40'}`}  {...fieldProps} />}
          {isVertical && label && <FormLabel locked={locked}>{label}</FormLabel>}
          {opposite && <StyledFormikField name={name} type="checkbox" disabled={locked} className={`form-checkbox mt-2 p-3 ${locked && 'cursor-not-allowed opacity-40'}`}  {...fieldProps} />}
        </div>
      </FieldContainer>
    </div>
  );
};

export const FullFormikSelect = ({
  label,
  name,
  className,
  ...fieldProps
}: { label?: string; name: string } & ComponentPropsWithoutRef<typeof Field>) => {
  return (
    <div className={className ?? `w-full ${fieldProps.disabled && 'opacity-25'}`}>
      <FieldContainer>
        {label && <FormLabel>{label}</FormLabel>}
        <StyledFormikSelect name={name} {...fieldProps} />
      </FieldContainer>
    </div>
  );
};

export const FullFormikTextarea = ({
  label,
  name,
  placeholder,
  className,
  ...fieldProps
}: { label?: string; name: string, placeholder?:string } & ComponentPropsWithoutRef<typeof Field>) => {
  return (
    <div className={className ?? 'w-full'}>
      <FieldContainer>
        {label && <FormLabel>{label}</FormLabel>}
        <StyledFormikField
          as="textarea"
          className="rounded border p-2 text-sm bg-white border-slate-200 focus:bg-white placeholder:text-slate-400 w-full"
          name={name}
          placeholder={placeholder??"Begin typing..."}
          rows="3"
          {...fieldProps}
        />
      </FieldContainer>
    </div>
  );
};

const selStyle = 'w-5 h-5 text-slate-500 p-0.5';

const iconObj = {
  BoltIcon: <BoltIcon className={selStyle} />,
  Cog6ToothIcon: <Cog6ToothIcon className={selStyle} />,
  FlagIcon: <FlagIcon className={selStyle} />,
  SignalIcon: <SignalIcon className={selStyle} />,
  WrenchIcon: <WrenchIcon className={selStyle} />,
  EngineIcon: <EngineIcon className={selStyle} />,
  AirplaneIcon: <AirplaneIcon className={selStyle} />,
  SoundwaveIcon: <SoundwaveIcon className={selStyle} />,
  StatisticsIcon: <StatisticsIcon className={selStyle} />,
};

export const IconDropdown = function ({
  label,
  iconName,
  setIconName,
}: {
  label?: boolean;
  iconName?: string;
  setIconName?: (name: string) => void;
}) {
  const [displayIcon, setDisplayIcon] = useState(<></>);
  const [isOpen, setIsOpen] = useState(false);
  const icoStyle = 'w-9 h-9 p-2.5 cursor-pointer hover:text-slate-500 transition';

  useEffect(() => {
    setDisplayIcon(iconObj[iconName ?? 'Cog6ToothIcon']);
  }, [iconName]);

  return (
    <div className="flex flex-col">
      {label && <label className="text-sm font-medium text-brand">Icon</label>}
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="flex items-stretch relative cursor-pointer group">
        <div className="flex items-center justify-center w-10 h-10 bg-slate-50 rounded-l border border-slate-300">{displayIcon}</div>
        <div className="flex items-center px-0.5 h-10 bg-white border rounded-r border-l-0 border-slate-300 group-hover:bg-slate-50">
          <ChevronDownIcon className="h-2.5 w-2.5 text-slate-500" />
        </div>
        {isOpen && (
          <>
            <div className="flex flex-col bg-slate-50 border border-slate-300 rounded p-1.5 absolute top-10 mt-2 shadow-blue after-caret -left-1 z-40">
              <div className="flex">
                <button
                  onClick={() => {
                    setIconName('Cog6ToothIcon');
                  }}>
                  <Cog6ToothIcon className={`${icoStyle} ${iconName === 'Cog6ToothIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('AirplaneIcon');
                  }}>
                  <AirplaneIcon className={`${icoStyle} ${iconName === 'AirplaneIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('EngineIcon');
                  }}>
                  <EngineIcon className={`${icoStyle} ${iconName === 'EngineIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    setIconName('WrenchIcon');
                  }}>
                  <WrenchIcon className={`${icoStyle} ${iconName === 'WrenchIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('FlagIcon');
                  }}>
                  <FlagIcon className={`${icoStyle} ${iconName === 'FlagIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('BoltIcon');
                  }}>
                  <BoltIcon className={`${icoStyle} ${iconName === 'BoltIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    setIconName('SignalIcon');
                  }}>
                  <SignalIcon className={`${icoStyle} ${iconName === 'SignalIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('SoundwaveIcon');
                  }}>
                  <SoundwaveIcon className={`${icoStyle} ${iconName === 'SoundwaveIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
                <button
                  onClick={() => {
                    setIconName('StatisticsIcon');
                  }}>
                  <StatisticsIcon className={`${icoStyle} ${iconName === 'StatisticsIcon' ? 'text-slate-500' : 'text-slate-400'}`} />
                </button>
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-transparent top-0 left-0 z-30 cursor-default"></div>
          </>
        )}
      </div>
    </div>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import Logo from 'assets/icons/logo_w.svg';
import Button from 'components/Button/Button';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import { Form, Formik } from 'formik';
import { GetInvitationDocument, NewUserSignupDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgTypes } from 'utils/orgTypes';


const Invitation = () => {
    const [initialValues, setInitialValues] = useState({ name: '', password: '', confirm: '' });
    const { id, token } = useParams();
    const { login, setOrganizationId, setOrganizationType } = useSession();
    const navigate = useNavigate();
    const { data: { getInvitation: userProfile } = {} } = useQuery( GetInvitationDocument, { variables: { id, token } } );
    const [signup] = useMutation(NewUserSignupDocument);
    useEffect(() => {
        if(userProfile) setInitialValues({ name: userProfile.nameInvite, password: '', confirm: '' });
    }, [userProfile]);
    return (
        <main className="flex md:items-center justify-center min-h-[100vh] bg-brand-gray">
            <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center bg-white md:bg-white/0 md:max-w-6xl w-full md:min-h-[768px] md:shadow md:mx-6 relative">
                <div className="w-1/3 hidden md:flex flex-col bg-brand-dark relative rounded-l">
                    <div className="flex flex-col">
                    <div className="flexitems-center p-8">
                        <img className="max-h-[3rem] border-white" src={Logo} alt="" />
                    </div>
                    <p className="text-brand-pale font-bold px-8 text-2xl mb-3">
                        Join Your Coflyt Org
                    </p>
                    <p className="text-brand-pale px-8">
                        You’re just a few clicks away from streamlining your aircraft management.
                    </p>
                    </div>
                    <span className="text-brand-pale absolute bottom-8 left-0 w-full text-center text-xs">
                    © {new Date().getFullYear()} Coflyt. All rights reserved.
                    </span>
                </div>
                <div className="flex items-center justify-center md:w-2/3 rounded md:p-16 bg-white rounded-r mb-8 md:mb-0">
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            try{
                                await signup({ variables: { input: { email: userProfile.emailInvite, password: values.password, firstName: values.name.split(' ')[0], lastName: values.name.split(' ')[1], userOrganizationProfileId:userProfile?.id } } });
                                const userLogin = await login(userProfile.emailInvite, values.password);
                                if(userLogin.organizationId){
                                    setOrganizationType( OrgTypes.PART91 );
                                    setOrganizationId(userLogin.organizationId);
                                    navigate('/crafts');
                                }else{
                                    alert("Error: user is not tied to an organization");
                                }
                            } catch (error) {
                            }
                        }}>
                        {({ values, isSubmitting, errors }) => (
                        <Form className="w-96">
                            <div className="flex flex-col mb-4">
                                <span className="text-3xl md:text-2xl font-bold text-brand">Welcome to Coflyt</span>
                            </div>
                            <div className="flex flex-col mb-4">
                                <span className="font-semibold text-gray-500">Join {userProfile?.organization?.name} </span>
                            </div>
                            <div className="flex flex-col">
                                <FullFormikInput name="name" label="Name" autoComplete="off" />
                            </div>
                            <div className="flex flex-col">
                                <FullFormikInput name="password" type="password" label="Password" autoComplete="off" />
                            </div>
                            <div className="flex flex-col">
                                <FullFormikInput name="confirm" type="password" label="Confirm Password" validate={(value) => (value === values.password ? '' : "Passwords Don't Match")} />
                            </div>
                            <p className="mb-0 mt-4 text-center text-sm -mx-10">
                                By clicking 'Complete Account Registration', you agree to our <a href="https://coflyt.com/tos" className='underline text-brand-electric' target="_blank">terms of service</a> and <a href="https://coflyt.com/privacy" className='underline text-brand-electric' target="_blank">privacy policy</a>
                            </p>
                            <div className="flex justify-center items-center mt-10">
                                <div className='flex gap-3'>
                                    <Button text="Complete Account Registration" color="" size="sm" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit" />
                                </div>
                            </div>
                        </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </main>
    );
};

export default Invitation;
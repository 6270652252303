import { Formik, Form, Field } from 'formik';
import { useContext, useState } from 'react';
import Button from 'components/Button/Button';
import { PlusIcon } from '@heroicons/react/24/solid';
import { AirplaneIcon } from 'assets/icons';
import { getMroCustomer, ServiceFormContext } from '..';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import Modal from 'components/Modal/Modal';
import { useLazyQuery } from '@apollo/client';
import { LookupTailDocument } from 'graphql/generated';

const fieldCss = 'slc-checked left-2 border-2 checked:bg-none border-brand-pale checked:border-brand-electric focus:ring-0 bg-slate-50',
  labelCss = 'flex items-center w-full border border-brand-pale rounded p-2 pl-8 font-bold text-brand-dark cursor-pointer';

const CraftSelect = (props) => {
  const {
    formStep,
    changeStep,
    user,
    setCraftId,
    craftId,
    organization,
    setOrganization,
    mroOrganization,
    resetState,
    setTailNumber,
    setTailInfo,
    setCustomerInput,
  } = useContext(ServiceFormContext);
  const [selectedOrganization, setSelectedOrganization] = useState<typeof user.organizations[number]>(
    organization || user?.userOrganizationProfiles[0].organization
  );
  const { user: loggedInUser, logout } = useSession();
  const [ modalOpen, setModalOpen ] = useState(false);
  const initialValues = { craft: craftId || '', tailNumber: '' };
  const [lookupTail] = useLazyQuery(LookupTailDocument);
  // useEffect(() => {
  //   if (!organization && user.organizations.length > 0) {
  //     setOrganization(user.organizations[0]);
  //   }
  // }, [organization, user.organizations]);
  return (
    <>
      { modalOpen && ( <Modal message='You are about to sign out of Coflyt' accept={async () => {     
        await logout(); 
        resetState(true);
       }} cancel={setModalOpen} /> ) }
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          setOrganization(selectedOrganization);
          const selectedCraft = selectedOrganization.crafts.find((craft) => craft.id === values.craft);
          if (values.craft === 'new') {
            var tempTail = values.tailNumber.toUpperCase();
            if (tempTail.startsWith('N')) {
              tempTail = tempTail.substring(1);
            }
            const tailInfo = (await lookupTail({ variables: { tailNumber: tempTail } }))?.data.registrations?.[0];
            setTailNumber(values.tailNumber); // tail lookup
            setTailInfo(tailInfo);
            changeStep(formStep, 'craftInfo');
          } else if (getMroCustomer(selectedOrganization, mroOrganization?.id)) {
            const customer = getMroCustomer(selectedOrganization, mroOrganization?.id);
            const customerBilling = customer.billingAddress;
            setCustomerInput({
              address1: customerBilling?.address,
              address2: '',
              city: customerBilling?.city,
              state: customerBilling?.region,
              zip: customerBilling?.postalCode,
              phone: customerBilling?.phone,
              email: customerBilling?.email,
              name: customer?.name,
              companyName: selectedOrganization?.name,
            }); // this is manily for looks on submission form (i.e not currently editable )
            setCraftId(values.craft);
            setTailNumber(selectedCraft.tailNumber);
            changeStep(formStep, 'issues');
          } else {
            setCraftId(values.craft);
            setTailNumber(selectedCraft.tailNumber);
            changeStep(formStep, 'contactInfo');
          }
        }}>
        {({ values, setFieldValue }) => (
          <Form className="w-96 p-6">
            <>
              <div className="flex flex-col mb-2">
                <span className="text-3xl md:text-2xl font-bold text-brand">Choose An Aircraft</span>
                <span className="md:text-sm text-slate-500 mb-4">Which aircraft is being serviced?</span>
              </div>
              <div role="group" className="flex flex-col">
                {/* IF MULTIPLE ORGANIZATIONS: hide FOR EACH section, populate based on selected organization */}
                {user?.organizations?.length > 1 && (
                  <>
                    <select
                      className="border-brand-pale rounded text-brand font-bold"
                      value={selectedOrganization?.id || user?.organizations?.[0]?.id || ''}
                      onChange={(e) => setSelectedOrganization(user?.organizations?.find((org) => org.id === e.target.value))}>
                      <option disabled value="">
                        Choose Organization
                      </option>
                      {user?.organizations?.map((org, index) => {
                        return (
                          <option key={index} value={org.id}>
                            {org.name}
                          </option>
                        );
                      })}
                    </select>
                    <hr className="my-2" />
                  </>
                )}
                {/*  */}
                {/* FOR EACH */}
                {selectedOrganization?.crafts?.map((craft, index) => (
                  <div className="flex items-center relative mb-2" key={craft.id}>
                    <Field className={fieldCss} type="radio" name="craft" value={craft.id} id={craft.id} />
                    <label htmlFor={craft.id} className={labelCss}>
                      <AirplaneIcon className="h-4 w-4 mr-2 text-brand" />
                      <span className="font-bold text-brand-dark">{craft.tailNumber}</span>
                    </label>
                  </div>
                ))}
                {selectedOrganization && selectedOrganization.aircraftLimit > selectedOrganization.crafts.length  && (
                  <div className="flex items-center relative">
                    <Field className={fieldCss} type="radio" name="craft" value="new" id="new" />
                    <label htmlFor="new" className={`${labelCss} bg-slate-50`}>
                      <PlusIcon className="h-4 w-4 mr-2 text-brand" />
                      <span className="font-bold text-brand-dark">New Aircraft</span>
                    </label>
                  </div>
                )}
                { values.craft === 'new' && (
                  <>
                  <label className="text-brand text-sm font-semibold mt-4">Tail Number</label>
                  <input
                    className={`${props.fieldCss} font-bold placeholder:font-normal`}
                    value={values.tailNumber}
                    placeholder="ex. N123AB"
                    required
                    onChange={(e) => {
                      setFieldValue( "tailNumber" , e.target.value); //Remove N in front of tail
                    }}
                  />
                  </>
                )
                }
                { selectedOrganization.aircraftLimit <= selectedOrganization.crafts.length && (
                  <div className="flex items-center relative text-xs text-center">
                    <span>Cannot add more crafts to account</span>
                  </div> )}

              </div>
              <div className="flex justify-between items-center mt-8">
                { loggedInUser ? 
                  <Button text="Sign Out" size="sm" type="button" onClick={() => setModalOpen(true)} />
                :  
                  <button type="button" className="underline text-brand font-semibold text-sm" onClick={() => resetState()}>
                    Go Back
                  </button>
                }
                <Button text="Next" color="navy" size="sm" type="submit" />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CraftSelect;

import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Table } from 'components/Table/Table';
import { formatApiDate, formatToFixed, formatUSD } from 'utils/formatter';
import UserIcon from 'components/UserIcon/UserIcon';
import NewLaborFlyout from '../NewLaborFlyout';
import LaborDetailFlyout from '../LaborDetailFlyout';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroWorkOrderItemDocument, UpdateMroLaborEntryDocument, GetMroDashboardDocument, GetMroWorkOrderDocument } from 'graphql/generated';
import Modal from 'components/Modal/Modal';
import { useSession } from 'contexts';
export const LaborSection = function ({ updateFlyout, flyoutProps, itemId, context, locked = false, workOrderId = '' }) {
  const { data: { mroWorkOrderItem } = {} } = useQuery(GetMroWorkOrderItemDocument, {
    variables: { id: itemId },
    skip: !itemId,
    returnPartialData: true,
  }); 
  const { user } = useSession();
  const [updateLaborEntry] = useMutation(UpdateMroLaborEntryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } }, 
    { query: GetMroDashboardDocument, variables: { mroEmployeeId: user.mroOrganizationId } }
  ],
    
  });
  const [modal, setModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  /* LABOR ENTRY TABLE HANDLING SECTION */
  const tableData = useMemo(() => {
    if (!mroWorkOrderItem) return [];
    return mroWorkOrderItem.mroLaborEntries;
  }, [mroWorkOrderItem]);
  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Date',
        id: 'date',
        accessor: (row) => row.endTime ?? '', // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500 whitespace-nowrap border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => {
          return (
            <>
              {!value && (
                <div className="flex gap-1 items-center">
                  <div className="bg-rose-500 rounded-full h-2 w-2"></div>
                  <span className="font-semibold">RECORDING</span>
                </div>
              )}
              <span className="font-semibold">{formatApiDate(value)}</span>
            </>
          );
        },
      },
      {
        Header: 'Performer',
        id: 'performedBy',
        accessor: (row) => row.mroEmployeeProfile ?? '', // accessor is the "key" in the data
        className: 'w-full justify-end text-xs text-slate-500 whitespace-nowrap border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => {
          if (row.original.mroEmployeeProfile) {
            return (
              <div className="flex -ml-[0.125rem] -my-[0.125rem]">
                <UserIcon
                  iconSize="xs"
                  iconText={`${value.firstName} ${value.lastName}`}
                  iconId={`${value.firstName} ${value.lastName}`}
                  iconColor={value.profileColor}
                  className="border-2 border-slate-50"
                />
                {/* <div className="self-center ml-1">
                  {value.firstName} {value.lastName}
                </div> */}
              </div>
            );
          } else {
            return <span className="">-</span>;
          }
        },
      },
      {
        Header: 'Hours',
        id: 'hours',
        accessor: (row) => row.hours ?? '', // accessor is the "key" in the data
        className: 'w-full justify-end text-xs text-slate-500 whitespace-nowrap border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => {
          
          value === '' && (value = '-');
          return <span className="">{ value !== '-' ? formatToFixed(value) : '-' }</span>;
        },
      },
      ...((context !== 'dashboard' && !mroWorkOrderItem?.isFlatRate)
        ? [
            {
              Header: 'Unit Price',
              id: 'unitPrice',
              accessor: (row) => row.billableRate ?? row.unitPrice, // accessor is the "key" in the data
              className: 'w-full justify-end text-xs text-slate-500 whitespace-nowrap border-dashed border-brand-pale',
              Cell: ({ value, row }: any) => {
                if (!value) return '-';
                return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
              },
            },
            {
              Header: 'Total Price',
              id: 'totalPrice',
              accessor: (obj) => obj.billableRate * obj.hours || obj.quantity * obj.unitPrice, // accessor is the "key" in the data
              className: 'w-full justify-end text-xs text-slate-500 font-bold whitespace-nowrap border-dashed border-brand-pale',
              Cell: ({ value, row }: any) => {
                return <span className="">{value ? Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 'N/A'}</span>;
              },
            },
          ]
        : []),
    ],
    [ context ]
  );
  /* */
  const stopAndOpenLaborEntry = () => {
    updateLaborEntry({ variables: { input: { id: selectedSection, endTime: new Date(), status: 'COMPLETED' } } });
    updateFlyout({
      title: 'Edit Labor Entry',
      content: <LaborDetailFlyout laborEntryId={selectedSection} workOrderItemId={itemId} workOrderId={workOrderId} {...flyoutProps} />,
    });
  };
  return (
    <>
      {modal && (
        <Modal
          cancel={setModal}
          accept={stopAndOpenLaborEntry}
          message="In order view or edit a labor entry, the labor entry has to be stopped."
        />
      )}

      <div className="flex flex-col justify-between w-full border rounded-b rounded-r shadow-blue relative z-30 bg-white border-slate-200">
        <div className="p-4">
          <div className="flex items-center gap-2 w-full bg-slate-50 border rounded border-slate-200 p-1">
            <DocumentTextIcon className="h-7 w-7 bg-brand-pale text-brand-electric rounded p-[0.375rem]" />
            <h2 className="text-brand-dark text-left font-bold">Labor Entries</h2>
          </div>
          <div className="">
            {tableData?.length ? (
              <Table
                data={tableData}
                columns={columns}
                onRowClick={(row) => {
                  if (context === 'dashboard' || locked) return;
                  if (!row.mroPart && !row.endTime) {
                    // we need to stop the recording first
                    setSelectedSection(row.id);
                    return setModal(true);
                  }
                  updateFlyout({
                    title: 'Edit Labor Entry',
                    content: <LaborDetailFlyout laborEntryId={row.id} workOrderItemId={itemId} workOrderId={workOrderId} {...flyoutProps} />,
                  });
                }}
              />
            ) : (context === 'dashboard' || locked) ? (
              <div className="pt-2 mx-2">
                <div className="bg-brand-offwhite border border-brand-pale rounded p-2 px-3 font-semibold text-brand text-sm">
                  No entries found.
                </div>
              </div>
            ) : (
              <div className="flex text-sm font-medium bg-brand-offwhite border border-brand-pale rounded p-2 mt-2 text-slate-500 mx-2">
                <button
                  className="flex items-center font-semibold text-brand cursor-pointer w-full"
                  onClick={() =>
                    updateFlyout({ title: 'Add Labor Entry', content: <NewLaborFlyout isFlatRate={mroWorkOrderItem?.isFlatRate} workOrderItemId={itemId} {...flyoutProps} /> })
                  }>
                  <PlusIcon className="h-2.5 w-2.5 mr-1" />
                  Add a labor entry
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-4 items-center justify-end border-t border-slate-200 rounded-b px-4 py-1.5 text-sm pl-12">
          { (context !== 'dashboard' && !mroWorkOrderItem?.isFlatRate ) && (
            <div className="flex items-center gap-2">
              <span className="font-bold text-brand">Total Labor Billable:</span>
              <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded">
                {formatUSD(tableData?.reduce((acc, cur) => acc + cur.billableRate * cur.hours, 0))}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import { useQuery } from "@apollo/client";
import TabCards from "components/TabCards/TabCards";
import UserIcon from "components/UserIcon/UserIcon";
import { useSession } from "contexts";
import { GetDashboardBadgesDocument, GetMroEmployeeDocument } from "graphql/generated";
import useSaveState from "hooks/useSaveState";
import { useEffect, useMemo, useState } from "react";
import { OpenServiceRequests } from "./Components/OpenServiceRequests";
import { UpcomingWorkOrders } from "./Components/UpcomingWorkOrders";
import { PendingPartRequests } from "./Components/PendingPartRequests";
import { ActiveLaborEntries } from "./Components/ActiveLaborEntries";
import { Link } from "react-router-dom";

export default function ManagerDashboard () {
    const { user } = useSession();
    const { data: { mroEmployeeProfile } = {}, error } = useQuery(GetMroEmployeeDocument, {
        variables: { mroEmployeeId: user.mroEmployeeProfileId },
    });
    const { data : { mroOrganization, mroWorkOrderParts } = {} } = useQuery(GetDashboardBadgesDocument, { 
        variables: { mroOrganizationId: user.mroOrganizationId } 
    }); 
    const tabs = [
        {
            title: 'Services Requests',
            content: (
                <OpenServiceRequests />
            ),
        },
        { 
            title: 'Work Orders',
            content: (
                <UpcomingWorkOrders />
            )
        },
        {   title: 'Part Requests'+(' ('+mroWorkOrderParts?.length+')'), 
            content: (
                <PendingPartRequests />
            ) 
        },
        { title: 'Active Labor Entries',
            content: (
                <ActiveLaborEntries />
            )
        },
    ];
    const [currentTab, setCurrentTab] = useSaveState('managerDashTabs', tabs[0].title);

    return(
        <div className="flex flex-col gap-3 overflow-x-auto">
            <div className="flex gap-1 justify-between flex-wrap lg:flex-nowrap">
                <div
                    className={`rounded-t flex items-center gap-2 p-5 bg-white flex-none grow border border-slate-300 shadow-blue justify-center lg:justify-start w-1/2`}>
                    <UserIcon
                    iconSize="xl"
                    iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                    iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                    iconColor={mroEmployeeProfile?.profileColor}
                    className=""
                    />
                    <div className="flex flex-col">
                    <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">
                        {mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                    </h1>
                    <div className="-mt-1 text-sm text-slate-500">
                        <span className="text-brand-electric font-medium mr-2 pr-2">{mroEmployeeProfile?.title}</span>
                    </div>
                    </div>
                </div>
                <div className="flex w-full gap-1 lg:w-1/2">
                    <div className="w-1/3">
                        <div className="flex flex-col justify-center items-center text-white bg-brand-dark rounded h-full">
                            <div onClick={() => setCurrentTab("Services Requests")} className="flex flex-col justify-center items-center text-center cursor-pointer px-2">
                                <span className="text-sm">Open Service Requests</span>
                                <span className="font-bold">{mroOrganization?.mroServiceRequests?.length ?? 0}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col justify-center items-center text-white bg-brand py-2 rounded h-full">
                            <div onClick={() => setCurrentTab("Work Orders")} className="flex flex-col justify-center items-center text-center cursor-pointer px-2">
                                <span className="text-sm">Due Soon Work Orders</span>
                                <span className="font-bold">{mroOrganization?.mroWorkOrders?.length ?? 0}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col justify-center items-center text-white bg-brand-electric py-2 rounded h-full">
                            <div onClick={() => setCurrentTab("Active Labor Entries")} className="flex flex-col justify-center items-center text-center cursor-pointer px-2">
                                <span className="text-sm">Active Labor Entries</span>
                                <span className="font-bold">{mroOrganization?.mroLaborEntries?.length ?? 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TabCards items={tabs} setActiveTitle={setCurrentTab} activeTitle={currentTab} addBars={false} />
        </div>
    )
}
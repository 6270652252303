import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateSerializedPartDocument,
  GetMroPartInventoriesDocument,
  GetMroPartWarehouseDocument,
  GetMroPartsDocument,
  GetMroSerilizedPartsDocument,
  PartCondition,
} from 'graphql/generated';
import { useCallback } from 'react';
import { useSession } from 'contexts';
import { PartConditions } from 'views/Part145/Parts/Orders/Components/PartConditions';

const initialValues = {
  condition: 'NEW' as PartCondition,
  serialNumber: '',
  unitPrice: 0,
  lotNumber: '',
  warehouse: 'n/a',
  coreCost: 0,
  bin: '',
};

const NewSerializedInventoryFlyout = function ({ closeFlyout, partId }: { closeFlyout: () => void; partId: string }) {
  const { user } = useSession();
  const [createSerializedPart] = useMutation(CreateSerializedPartDocument, {
    refetchQueries: [
      GetMroSerilizedPartsDocument,
      GetMroPartInventoriesDocument
    ],
  });

  const { data: { mroPartWarehouses = [] } = {} } = useQuery(GetMroPartWarehouseDocument, {
    variables: {
      input: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });
  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const warehouseId = values.warehouse === 'n/a' ? null : values.warehouse;
      delete values.warehouse;
      createSerializedPart({
        variables: {
          input: {
            mroPart: { connect: { id: partId } },
            ...(warehouseId && { mroPartWarehouse: { connect: { id: warehouseId } } }),
            ...values,
          },
        },
      })
        .then(() => closeFlyout())
        .catch(console.error);
    },
    [createSerializedPart, closeFlyout, user]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikInput name="serialNumber" label="Serial #" />
                <FullFormikSelect name="condition" label="Condition">
                  <PartConditions />
                </FullFormikSelect>
              </div>
              <div className="flex gap-1">
                <FullFormikInput type="number" name="unitPrice" label="Unit Price" />
                <FullFormikInput type="number" name="coreCost" label="Core Cost" />
              </div>
              <div className="flex gap-1">
                <FullFormikInput name="lotNumber" label="Lot #" />
                <FullFormikSelect name="warehouse" label="Warehouse">
                  <option value="n/a">N/A</option>
                  {mroPartWarehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </FullFormikSelect>
                <FullFormikInput name="bin" label="Bin" />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              <Button text="Create Inventory" color="navy" size="xs" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewSerializedInventoryFlyout;

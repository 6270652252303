import { CaretRightIcon, CheckInCircleIcon, CircleSelectionIcon, SandClockIcon, TimelapseIcon, CalendarIcon } from '../../assets/icons';
import { ExclamationTriangleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { formatApiDate } from 'utils/formatter';
import { GetRequestsQuery, GetSquawksQuery } from 'graphql/generated';
import { FlyoutProps } from 'components/Flyout/Flyout';
import StatusButton from 'components/StatusButton/StatusButton';
import View from 'views/Part91/Aircraft/Requests/View';
import { XCircleIcon } from '@heroicons/react/24/solid';

export default function Request({ request, updateFlyout, closeFlyout, ...props }: { updateFlyout: (data:any) => void, closeFlyout: () => void, request: GetRequestsQuery['craftServiceRequests'][number] } & { [key: string]: any }) {
  const Icon = ({ className }) => {
    switch (request.status) {
      case 'SERVICE_REQUESTED':
        return (
          <div className={className + ' text-brand-electric bg-brand-pale border-brand-electric'}>
            <CircleSelectionIcon className="h-6 w-6" />
          </div>
        );
      case 'COMPLETED':
        return (
          <div className={className + ' bg-slate-100 text-slate-500 border-slate-500'}>
            <CheckInCircleIcon className="h-6 w-6" />
          </div>
        );
      case 'INVOICE_PENDING':
      case 'ESTIMATE_PENDING':
        return (
          <div className={className + ' bg-violet-100 text-brand-purple border-brand-purple'}>
            <TimelapseIcon className="h-6 w-6" />
          </div>
        );
      case 'SCHEDULED':
        return (
          <div className={className + ' bg-slate-100 border-brand text-brand'}>
            <SandClockIcon className="h-6 w-6" />
          </div>
        );
      case 'PENDING':
      case 'IN_PROGRESS':
        return (
          <div className={className + ' bg-violet-100  text-brand-purple border-brand-purple'}>
            <CalendarIcon className="h-5 w-5" />
          </div>
        );
      case 'CLOSED':
        return (
          <div className={className + ' bg-violet-100  text-brand-purple border-brand-purple'}>
            <XCircleIcon className="h-5 w-5" />
          </div>
        );
    }
  };
  return (
    <>
      <div
        onClick={() => updateFlyout( { title: request.title, content: <View id={request.id} closeFlyout={closeFlyout} /> } )}
        className={`${
          request.status === 'RESOLVED' ? 'grayscale opacity-60 hover:opacity-100' : ''
        } group cursor-pointer hover:shadow-sm transition transition-smooth px-2`}>
        <div className={`${props.last ? '' : 'border-b'} py-3 px-2 flex flex-col w-full relative`}>
          <div className="flex flex-col lg:flex-row items-center px-2 relative">
            <div className="flex flex-col w-full lg:w-1/2 pr-4">
              <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                <div className="flex items-center">
                  <Icon className="h-10 w-10 mr-4 rounded-full border flex items-center justify-center " />
                  <div className="">
                    <div className="flex items-center">
                      <h1 className="font-bold text-lg text-brand-dark -mb-1">{request.title}</h1>
                    </div>
                    <div className="flex items-center w-full pr-8 lg:pr-0">
                      <div className="flex items-center">
                        <PencilSquareIcon className="h-4 w-4" />
                        <span className="text-sm">
                          {request.userProfile?.user?.firstName} {request?.userProfile?.user?.lastName}
                        </span>
                      </div>
                      <span className="text-xs">&nbsp;- {formatApiDate(request.createdAt)}</span>
                    </div>
                  </div>
                </div>
                {/* <div className="hidden lg:flex flex-col items-end text-sm">
                  <span className="">{formatApiDate(squawk.openedAt)}</span>
                </div> */}
              </div>
            </div>
            <div className="flex w-full lg:w-1/2 text-sm lg:justify-center mt-2 lg:mt-0 lg:pl-4">
              <div className="flex flex-col md:flex-col-reverse rounded items-stretch grow text-sm">
                <div className="-mt-[1px] flex flex-col items-center justify-center grow font-bold gap-2">
                  <div className='flex items-center gap-2'>
                    <span>Shop: </span>
                    <div className='flex flex-col items-center'>
                      <span className='font-semibold'>{request.mroOrganizationName}</span>
                    </div>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span>Status:</span>
                    <div className="flex items-center justify-end">
                      <StatusButton text={request.status} context='craftRequest' readOnly={true} className=''  />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CaretRightIcon className="md:hidden h-4 w-4 ml-4 absolute top-4 lg:top-auto right-4" />
          </div>
        </div>
      </div>
    </>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import {
  FormLabel,
  FullFormikInput,
  FullFormikSelect,
  FullFormikTextarea,
  StyledFormikField,
  IconDropdown,
} from 'components/Form/StandardForm';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { webFormat } from 'utils/statuses';
import { useSession } from 'contexts';
import { Field, Form, Formik } from 'formik';
import {
  CreateMroWorkOrderItemDocument,
  GetItemCategoriesDocument,
  GetMroDashboardDocument,
  GetMroWorkOrderDocument,
  GetOrganizationRateDocument,
  SignoffStatus,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import EmployeeSelect from './EmployeeSelect';
import SignoffSelector from './partials/SignoffSelector';

interface Values {
  title: string;
  description: string;
  notes: string;
  status: string;
  estimatedHours: number;
  category: string;
  newCategory: string;
  iconName: string;
}

export default function NewItemFlyout({
  closeFlyout,
  workOrderId,
  parentId,
  categoryId,
}: {
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  workOrderId: string;
  parentId?: string;
  categoryId?: string;
}) {
  const [openSelection, setOpenSelection] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedSignoffs, setSelectedSignoffs] = useState([]);
  const [createOrderItem] = useMutation(CreateMroWorkOrderItemDocument);
  const initialValues = useMemo(
    () => ({
      title: '',
      description: '',
      notes: '',
      status: 'NOT_STARTED',
      estimatedHours: 0,
      category: categoryId || 'new',
      newCategory: '',
      iconName: 'Cog6ToothIcon',
      isFlatRate: false,
      flatRate: 0,
    }),
    [categoryId]
  );
  const { data: { mroWorkOrderItemCategories } = {} } = useQuery(GetItemCategoriesDocument, { variables: { workOrderId } });
  const { data : { mroWorkOrder : { billableRate } = {} } = {} } = useQuery(GetOrganizationRateDocument, { variables: { mroWorkOrderId: workOrderId } });
  const { user } = useSession();

  const handleSignoffSelection = function (val) {
    let tempArr = [...selectedSignoffs];
    const i = tempArr.findIndex((e) => e === val);
    //remove from selectedSignoffs array
    if (i > -1) {
      tempArr.splice(i, 1);
    }
    //add to selectedSignoffs array
    else {
      tempArr.push(val);
    }
    setSelectedSignoffs(tempArr);
  };

  const handleSubmit = ({ category, newCategory, iconName, ...values }: Values) => {
    createOrderItem({
      variables: {
        input: {
          ...values,
          estimatedHours: Number(values.estimatedHours ?? 0),
          mroWorkOrder: {
            connect: {
              id: workOrderId,
            },
          },
          assignees: {
            connect: Array.from(selectedProfiles).map((profile) => ({ id: profile.id })),
          },
          mroOrganization: { connect: { id: user.mroOrganizationId } },
          mroWorkOrderSignoffs: {
            create: selectedSignoffs.map((signOff) => {
              return { title: webFormat(signOff), status: SignoffStatus.Unassigned };
            }),
          },
          ...(parentId && {
            parent: {
              connect: {
                id: parentId,
              },
            },
          }),
          ...(!parentId && {
            category:
              category === 'new'
                ? {
                    create: {
                      mroOrganization: { connect: { id: user.mroOrganizationId } },
                      mroWorkOrder: { connect: { id: workOrderId } },
                      title: newCategory,
                      iconName: iconName,
                    },
                  }
                : { connect: { id: category } },
          }),
        },
      },
      refetchQueries: [
        { query: GetMroWorkOrderDocument, variables: { id: workOrderId } },
        GetMroDashboardDocument,
        { query: GetItemCategoriesDocument, variables: { workOrderId: workOrderId } },
      ],
    })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  return (
    <>
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {!parentId && (
                <div className="flex px-10 py-6 mb-2 gap-2 bg-white border border-slate-200 rounded shadow-blue">
                  <FullFormikSelect name="category" label="Category" className={values.category === 'new' ? 'w-1/2' : 'w-full'}>
                    <option value="new">New Category</option>
                    {mroWorkOrderItemCategories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </FullFormikSelect>
                  {values.category === 'new' && (
                    <>
                      <IconDropdown label={true} iconName={values.iconName} setIconName={(val) => setFieldValue('iconName', val)} />
                      <FullFormikInput name="newCategory" label="New Category Name" className="w-1/2" />
                    </>
                  )}
                </div>
              )}
              <div className="flex flex-col w-full border border-slate-200 rounded py-10 shadow-blue bg-white">
                <div className="group flex justify-end item p-2 mr-10 gap-2 items-center">
                  <Field
                    className="rounded border-slate-200 p-1 -my-[1px] text-right strip-style text-sm font-semibold text-brand-dark bg-slate-50"
                    id="isFlatRate"
                    name="isFlatRate"
                    type="checkbox"
                  />
                  <dt onClick={ ()=> setFieldValue('isFlatRate', !values.isFlatRate) } className="text-sm font-medium text-slate-500">Bill Flat Rate</dt>
                </div>
                <div className="flex w-full mb-2 px-10">
                  <div className="flex flex-col mr-2 w-2/3">
                    <FormLabel>Title</FormLabel>
                    <StyledFormikField id="title" name="title" required />
                  </div>
                  <div className="flex flex-col w-1/3">
                    <FormLabel>Est. Hours</FormLabel>
                    <StyledFormikField id="estimatedHours" name="estimatedHours" type="number" />
                  </div>
                  { values.isFlatRate && <div className="flex ml-2 flex-col w-1/3">
                    <FormLabel>Amount</FormLabel>
                    <StyledFormikField id="flatRate" name="flatRate" type="number" />
                    <button type='button' onClick={()=> setFieldValue('flatRate', billableRate*Number(values.estimatedHours))} className='text-center text-sm mt-[-10px] underline'>{Number(values.estimatedHours) > 0 && Number(values.estimatedHours)*billableRate !== Number(values.flatRate) 
                      ? "Recommended: " + Number(values.estimatedHours)*billableRate : '' }</button>
                  </div> }
                </div>
                <div className="px-10">
                  <div className="flex gap-2 mb-2">
                    <FullFormikTextarea name="notes" label="Notes" />
                  </div>
                  <FormLabel>Technicians</FormLabel>
                  <EmployeeSelect selectedProfiles={selectedProfiles} setSelectedProfiles={setSelectedProfiles} />
                </div>
                <div className="px-10 relative w-full">
                  <FormLabel>Required Signoffs</FormLabel>
                  <div
                    className={`
                      ${openSelection ? 'border-brand-electric ring-2 ring-brand-pale text-brand' : 'border-slate-300 text-slate-500'} 
                      flex justify-between items-center p-2 pr-4 cursor-pointer hover-shadow-blue transition-all rounded w-full border border-slate-300 bg-slate-50 h-10`}
                    onClick={() => {
                      setOpenSelection(!openSelection);
                    }}>
                    <span className="text-sm font-medium group-hover:text-brand transition">
                      {selectedSignoffs.length > 0
                        ? selectedSignoffs.map((val, i) => {
                            i !== selectedSignoffs.length - 1 && (val = val + ', ');
                            return webFormat(val);
                          })
                        : 'None'}
                    </span>
                    <ChevronUpDownIcon className="h-4 w-4 group-hover:text-brand transition" />
                  </div>
                  {openSelection && <SignoffSelector selectedSignoffs={selectedSignoffs} handleSignoffSelection={handleSignoffSelection} />}
                </div>
              </div>
              <div className="flex justify-end items-center mt-4">
                <Button text="Add Squawk" color="navy" size="sm" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { ServiceFormContext } from '..';
import Button from 'components/Button/Button';
import { useSession } from 'contexts';
import { useNavigate } from 'react-router-dom';
const Completed = () => {
  const { user } = useSession();
  const { resetState } = useContext(ServiceFormContext);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center gap-10 w-96 p-6">
      <h2 className="text-3xl md:text-3xl font-bold text-center text-brand place-self-center">
        Thanks for reaching out someone will be in touch shortly
      </h2>
      <EnvelopeIcon className="h-20 w-20 place-self-center animate-bounce" />
      { user && <Button onClick={() => navigate('/aircraft/requests')} text="Back to Coflyt App" color="navy" size="sm" /> }
      <Button onClick={() => resetState()} text="New Request" color="navy" size="sm" />
    </div>
  );
};

export default Completed;

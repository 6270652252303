import { Fragment, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Combobox, Listbox, Transition } from '@headlessui/react';
import { useLazyQuery } from '@apollo/client';
import { GetMroPartAndInventoriesQuery, GetMroPartsDocument, QueryMode } from 'graphql/generated';
import { FormLabel } from 'components/Form/StandardForm';
import { useSession } from 'contexts';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function PartLookup({
  selectedPart,
  setSelectedPart,
  hideLabel = false,
  className = '',
}: {
  selectedPart: any;
  setSelectedPart: any;
  hideLabel?: boolean;
  className?: string;
}) {
  const [query, setQuery] = useState('');
  const { user } = useSession();
  const [search, { data: { mroParts: filteredParts } = { mroParts: [] } }] = useLazyQuery(GetMroPartsDocument);
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        search({
          variables: {
            where: {
              OR: [
                {
                  partNumber: {
                    contains: query.trim().replaceAll(' ', ' & '),
                    mode: QueryMode.Insensitive,
                  },
                },
                {
                  description: {
                    contains: query.trim().replaceAll(' ', ' & '),
                    mode: QueryMode.Insensitive,
                  },
                }
              ],
              mroOrganizationId: { equals: user.mroOrganizationId },
            },
            limit: 5,
          },
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query, search, user]);

  return (
    <Combobox as="div" value={selectedPart} onChange={setSelectedPart} className={className}>
      {!hideLabel && <Combobox.Label as={FormLabel}>Part #</Combobox.Label>}
      <div className={hideLabel ? 'relative' : 'relative mb-4'}>
        <Combobox.Input<'input', any>
          className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(part) => ''}
          placeholder="Search for a part number"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredParts.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredParts.slice(0, 4).map((part) => (
              <Combobox.Option<'li', typeof filteredParts[number]>
                key={part.id}
                value={part}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }>
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span className={classNames('truncate', selected && 'font-semibold')}>{part.partNumber}</span>
                      {part.isSerialized && (
                        <span className={classNames('ml-2 truncate uppercase text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>
                          Serialized
                        </span>
                      )}
                      <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>
                        {part.description}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export function PartNumberLookup({
  selectedPart,
  setSelectedPart,
  partNumber,
  setPartNumber,
}: {
  selectedPart: any;
  setSelectedPart: any;
  partNumber: any;
  setPartNumber: any;
}) {
  const [query, setQuery] = useState('');
  const { user } = useSession();
  const searchBox = useRef(null);

  const [search, { data: { mroParts: filteredParts } = { mroParts: [] } }] = useLazyQuery(GetMroPartsDocument);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        search({
          variables: {
            where: {
              OR: [
                {
                  partNumber: {
                    contains: query.trim().replaceAll(' ', ' & '),
                    mode: QueryMode.Insensitive,
                  },
                },
                {
                  description: {
                    contains: query.trim().replaceAll(' ', ' & '),
                    mode: QueryMode.Insensitive,
                  },
                }
              ],
              mroOrganizationId: { equals: user.mroOrganizationId },
            },
            limit: 5,
          },
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query, search, user]);
  return (
    <Combobox as="div" value={selectedPart?.partNumber ?? 'newPart'} onChange={() => {}}>
      <Combobox.Label as={FormLabel}>Part #</Combobox.Label>
      <div className="relative mb-4">
        <input
          className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
          onChange={(event) => {
            setQuery(event.target.value);
            setPartNumber(event.target.value);
            setSelectedPart(null);
          }}
          value={partNumber}
          onClick={() => searchBox.current?.click()}
          placeholder="Search or enter a part number"
        />

        <Combobox.Button ref={searchBox} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          { filteredParts?.[0]?.partNumber !== partNumber && <Combobox.Option
            key={'newPart'}
            value={'newPart'}
            onClick={() => {
              setSelectedPart('newPart');
            }}
            className={({ active }) =>
              classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
            }>
            {({ active, selected }) => (
              <>
                <div className="flex">
                  <span className={'font-semibold'}>New Part</span>
                </div>
                {selected && (
                  <span
                    className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option> }
          {filteredParts.length > 0 &&
            filteredParts.slice(0, 4).map((part) => (
              <Combobox.Option<'li', any>
                key={part.id}
                value={part.partNumber}
                onClick={() => {
                  setPartNumber(part.partNumber);
                  setSelectedPart(part);
                }}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }>
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span className={classNames('truncate', selected && 'font-semibold')}>{part.partNumber}</span>
                      <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>
                        {part.description}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export function PartInventoryLookup({
  selectedPartInventory,
  setSelectedPartInventory,
  partInventories,
}: {
  selectedPartInventory: any;
  setSelectedPartInventory: any;
  partInventories: GetMroPartAndInventoriesQuery['mroPart']['availablePartInventories'];
}) {
  if (!partInventories) return null;

  return (
    <Listbox value={selectedPartInventory} onChange={setSelectedPartInventory}>
      {({ open }) => (
        <>
          <Listbox.Label as={FormLabel}>Inventory</Listbox.Label>
          <div className="relative mb-4">
            <Listbox.Button className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full">
              <span className="inline-flex w-full truncate">
                {(selectedPartInventory && (
                  <>
                    <span className="truncate">{selectedPartInventory?.condition}</span>
                    <span className="truncate ml-2 text-gray-500">{selectedPartInventory?.mroPartWarehouse?.name}</span>
                    <span className="ml-2 truncate text-gray-500">
                      ({selectedPartInventory?.quantity} in stock ${selectedPartInventory?.unitPrice ?? 0})
                    </span>
                  </>
                )) || <span className="truncate">Select an Inventory</span>}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {partInventories.map((partInventory) => (
                  <Listbox.Option
                    key={partInventory.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={partInventory}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {partInventory?.condition}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {partInventory?.mroPartWarehouse?.name}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                            ({partInventory?.quantity} in stock ${selectedPartInventory?.unitPrice ?? 0})
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export function PartSerializedParts({
  selectedSerializedPart,
  setSelectedSerializedPart,
  serializedParts,
}: {
  selectedSerializedPart: any;
  setSelectedSerializedPart: any;
  serializedParts: GetMroPartAndInventoriesQuery['mroPart']['availableSerializedParts'];
}) {
  if (!serializedParts) return null;

  return (
    <Listbox value={selectedSerializedPart} onChange={setSelectedSerializedPart}>
      {({ open }) => (
        <>
          <Listbox.Label as={FormLabel}>Serialized Part</Listbox.Label>
          <div className="relative mb-4">
            <Listbox.Button className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full">
              <span className="inline-flex w-full truncate">
                {(selectedSerializedPart && (
                  <>
                    <span className="truncate">{selectedSerializedPart?.serialNumber}</span>
                    <span className="truncate ml-2 text-gray-500">{selectedSerializedPart?.condition}</span>
                    <span className="ml-2 truncate text-gray-500">(${selectedSerializedPart?.unitPrice ?? 0})</span>
                  </>
                )) || <span className="truncate">Select a Serialized Part</span>}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {serializedParts.map((partSerializedPart) => (
                  <Listbox.Option
                    key={partSerializedPart.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={partSerializedPart}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {partSerializedPart.serialNumber}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {partSerializedPart.condition}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                            (${partSerializedPart.unitPrice ?? 0})
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import {  
    CreateFavoriteRouteDocument,
  DeleteFavoriteRouteDocument,
  GetFavoriteRouteDocument,
  GetFavoriteRoutesDocument,
  UpdateFavoriteRouteDocument
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { FullFormikInput } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import RouteItem, { Route } from '../../Components/RouteItem';
import { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';

const EditFavoriteRoute = function ({ closeFlyout, id }: { closeFlyout: () => void, id: string }) {
    const { organizationId } = useSession();
    const { data : { favoriteRoute } = {} } = useQuery( GetFavoriteRouteDocument, { variables: { id: id } } );
    const [updateFavoriteRoute] = useMutation(UpdateFavoriteRouteDocument, { 
        refetchQueries: [{ query: GetFavoriteRoutesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const [ deleteFavoriteRoute ] = useMutation( DeleteFavoriteRouteDocument, { variables: { id },
        refetchQueries: [{ query: GetFavoriteRoutesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
    });
    const [route, setRoute] = useState<Route[]>([{ icao:'' }, { icao:'' }]);
    const [ showModel, setShowModel ] = useState(false);
    function removeAirport(index: number) {
        const newRoute = [...route];
        newRoute.splice(index, 1);
        setRoute(newRoute);
    }

    function updateAirport(index: number, type: string, value: string) {
        const newRoute = [...route];
        newRoute[index][type] = value;
        setRoute(newRoute);
    }

    const [ initialValues, setInitialValues ] = useState({ name: favoriteRoute?.name ?? '', sort: favoriteRoute?.sort+1 ?? 0 });
    
    useEffect(() => {
        if (favoriteRoute) {
            setRoute(favoriteRoute?.icaos?.map((icao) => ({ id: favoriteRoute?.airports?.find((airport) => airport.icao === icao )?.id, icao })) ?? [{ icao:'' }, { icao:'' }]);
            setInitialValues({ name: favoriteRoute?.name ?? '', sort: favoriteRoute?.sort+1 ?? 0 });
        }
    }, [favoriteRoute]);
    return (
    <>
        { showModel && <Modal message={'You are about to delete this favorite route.'} accept={ () => {
            deleteFavoriteRoute();
            setShowModel(false);
            closeFlyout();
        } } cancel={setShowModel} /> }
        <Formik enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => updateFavoriteRoute({ variables: { input:{ name: values.name, 
            sort: values.sort-1,
            id,
            icaos: route.map((airport) => airport.icao),
            ...( route.filter(airport => airport.id).length > 0 && { airports: { connect: route.filter((airport) => airport?.id).map((airport) => ({ id: airport.id })) } }),
            organization: { connect: { id: organizationId } } } } }) }>
        {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
                <Form>
                    <Card className="flex flex-col p-4 h-full m-4">
                        <FullFormikInput name="name" label="Name" autoComplete="off" />
                        <FullFormikInput name="sort" label="Sort" autoComplete="off" />
                        <h3 className="text-lg font-semibold my-2">Route</h3>
                        <div className="flex w-full justify-between gap-7 mb-9">
                            <div className='flex flex-col w-full'>
                            {route.map((airport, index) => (
                                <RouteItem key={index} temp={ index > 1 } index={index} route={airport} handleItemUpdate={updateAirport} handleRemoveItem={removeAirport} />
                            ))}
                            </div>
                        </div>
                    </Card>
                    <div className="bg-gray-50 px-4 py-5 flex items-center justify-end gap-3">
                        <Button text="Cancel" size='sm' color='pale' onClick={closeFlyout} />
                        <Button text="Delete" size='sm' color='red' onClick={() => setShowModel(true)} />
                        <Button text="Save" type='submit' size='sm' color='navy' />
                    </div>
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default EditFavoriteRoute;

import { useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikInput, FullFormikTextarea } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { CreateMroLocationDocument, CreateMroRtsTextDocument, GetLocationsDocument, GetLocationsQuery, GetMroRtsTextsDocument, UpdateMroLocationDocument } from "graphql/generated";

export default function NewRTSText({ closeFlyout }: { closeFlyout: () => void }) {
    const { user } = useSession();
    const [createRTSText] = useMutation(CreateMroRtsTextDocument, {
        refetchQueries: [{ query: GetMroRtsTextsDocument, variables: { mroOrganizationId: user?.mroOrganizationId } }],
    });
    const initialValues = {
        title: '',
        body: '',
    };
    return (
        <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
            createRTSText({
                variables: {
                    input: {
                        mroOrganization: { connect: { id: user.mroOrganizationId } },
                        ...values
                    },
                },
            })
            .then(() => closeFlyout())
            .catch(console.error);
        }}>
            <Form className="flex flex-col gap-5 mx-5">
                <Card className="">
                    <div className="flex gap-5 flex-col">
                        <FullFormikInput name="title" placeholder="Title" label="Name" />
                        <FullFormikTextarea name="body" placeholder="RTS Statement Here" label="RTS Statement" />
                    </div>
                </Card>
                <div className="flex justify-end">
                    <Button text="Create" color="navy" size="xs" type="submit" />
                </div>
            </Form>
        </Formik>
    );
}
import { useMutation, useQuery } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, PlusIcon } from "@heroicons/react/24/solid";
import UserIcon from "components/UserIcon/UserIcon";
import { useSession } from "contexts";
import { GetMroEmployeeDocument, GetOrganizationsDocument, GetUserInfoDocument, SwitchOrganizationDocument } from "graphql/generated";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import isMobileApp from "utils/isMobileApp";
import { OrgTypes } from "utils/orgTypes";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function UserMenu({ sidebarOpen, altLoginUrl } 
    : { sidebarOpen?: boolean; altLoginUrl?: string}) {
    const { organizationId, setOrganizationId, logout, user, organizationType, setCraftId, setLoginSession, setOrganizationType } = useSession();
    const { data: { organizations } = { organizations: [] } } = useQuery(GetOrganizationsDocument, { variables: { where: { userOrganizationProfiles: { 
        some: { user: { is:{ id: { equals: user?.id } } } } 
    } } } });
    const [switchOrganization] = useMutation(SwitchOrganizationDocument);
    const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
        variables: { mroEmployeeId: user?.mroEmployeeProfileId },
        skip: !user?.mroEmployeeProfileId,
    }); 
    const { data: { me } = {}, client } = useQuery(GetUserInfoDocument);
    const navigate = useNavigate();
    const userNavigation = [
        { name: 'Settings', href: `${ organizationType === OrgTypes.PART145 ? '/mro' : ''}/account/settings` },
    ];
    const firstName =  organizationType === OrgTypes.PART145 ? mroEmployeeProfile?.firstName : me?.firstName;
    const lastName =  organizationType === OrgTypes.PART145 ? mroEmployeeProfile?.lastName : me?.lastName;
    const orgName = organizationType === OrgTypes.PART145 ? mroEmployeeProfile?.mroOrganization?.name : organizations.find((o) => o.id === organizationId)?.name;

    return (
      <>
        <Menu as="div" className={'md:group flex justify-end w-full' + (sidebarOpen ? '' : '')}>
          <Menu.Button className={'md:w-full' + (sidebarOpen ? ' p-2' : '')}>
            <div
              className={
                'flex rounded cursor-pointer transition-all pr-1 border border-brand bg-brand-electric/10 hover:shadow-inner hover:border-brand-electric hover:bg-brand ease-in-out' +
                (sidebarOpen ? '' : ' hidden')
              }>
              <div className={'flex items-center p-2 rounded-l transition w-full' + (sidebarOpen ? '' : ' hidden')}>
                <UserIcon
                  iconSize="lg"
                  iconText={firstName + ' ' + lastName}
                  iconId={firstName + ' ' + lastName}
                  iconColor={mroEmployeeProfile?.profileColor}
                  className=""
                />
                <div className="flex-col px-2 block overflow-hidden text-left text-ellipsis">
                  <span className="text-white font-bold text-left whitespace-nowrap">
                    {firstName} {lastName}
                  </span>
                  <span className="flex text-left text-xs text-brand-offwhite font-medium whitespace-nowrap -mt-1">
                    {orgName}
                  </span>
                </div>
              </div>
              <ChevronUpDownIcon className="h-auto w-8 px-1.5 text-white rounded-r hidden md:block" />
            </div>
            <div className="flex items-center w-full justify-end md:justify-center py-1">
              <UserIcon
                iconSize="md"
                iconText={firstName + ' ' + lastName}
                iconId={firstName + ' ' + lastName}
                iconColor={mroEmployeeProfile?.profileColor}
                className={sidebarOpen ? 'hidden' : 'hidden md:flex'}
              />
              <UserIcon
                iconSize="xl"
                iconText={firstName + ' ' + lastName}
                iconId={firstName + ' ' + lastName}
                iconColor={mroEmployeeProfile?.profileColor}
                className={'flex md:hidden'}
              />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className={`md:origin-bottom absolute ${
                sidebarOpen ? 'left-0 md:-mb-1 md:w-[calc(100%-1.5rem)]' : 'md:left-10 md:-mb-12 md:w-32'
              }  origin-top right-[-5px] md:right-0 md:bottom-24 md:top-[unset] top-20  w-[50%] bottom-full focus:outline-none z-[99] mx-3`}>
              <div className="bg-brand rounded shadow-lg shadow-brand border border-brand-electric/50">
                {organizationType === OrgTypes.PART91 && 
                  <>
                    {organizations?.map((item) => (
                      <Menu.Item key={item.id}>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active || organizationId === item.id ? 'bg-brand-dark rounded' : '',
                              `block px-4 py-2 text-white w-full text-left ${organizationId === item.id ? 'font-bold' : 'font-semibold text-sm'} my-1`
                            )}
                            onClick={async () => {
                              setOrganizationId(item.id);
                              setCraftId(null);
                              const result = await switchOrganization({ variables: { organizationId: item.id } });
                              if (result.data?.switchOrganization) {
                                await setLoginSession(result.data.switchOrganization.accessToken, result.data.switchOrganization.refreshToken);
                              }
                              await client.resetStore();
                              navigate('/crafts');
                            }}>
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item key={'newOrg'}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-brand-dark rounded' : '',
                            `flex px-4 py-2 text-white w-full text-left font-semibold text-sm my-1`
                          )}
                          onClick={() => {
                            navigate('/entities/new');
                          }}>
                          
                          <PlusIcon className="h-4 w-4 mr-2" />
                          <span>New Organization</span>
                        </button>
                      )}
                    </Menu.Item>
                    <hr className="w-full border-brand-electric/50 border-2" />
                  </>
                }
                {organizationType === OrgTypes.PART91 && !isMobileApp() && mroEmployeeProfile &&
                  <>
                    <Menu.Item key={mroEmployeeProfile?.id}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active? 'bg-brand-dark rounded' : '',
                            `block px-4 py-2 text-white w-full text-left font-semibold text-sm my-1`
                          )}
                          onClick={async () => {
                            setOrganizationType(OrgTypes.PART145);
                            navigate('/mro/dashboard');
                          }}>
                          Switch to MRO
                        </button>
                      )}
                    </Menu.Item>
                    <hr className="w-full border-brand-electric/50 border-2" />
                  </>
                }
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? 'bg-brand-dark rounded' : '',
                          'block px-4 py-2 text-sm text-white font-medium transition mt-1'
                        )}>
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-brand-dark rounded' : '',
                        'block px-4 py-2 text-sm text-white w-full text-left font-medium transition mb-1'
                      )}
                      onClick={() => {
                        logout()
                          .then(() => {
                            navigate(altLoginUrl ?? '/auth');
                          })
                          .catch(console.error);
                      }}>
                      Sign Out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
}


import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';

export type MultiselectItem = {
    name: string;
    value: any;
}

const Multiselect = (
    { items: importItems, initialSelected, setSelectedItems, disabled = false } : 
    {
        items: MultiselectItem[]; 
        initialSelected?: MultiselectItem[];
        setSelectedItems: (items:MultiselectItem[]) => void;
        disabled?: boolean;
    }) => {
    // state showing if dropdown is open or closed
    const [dropdown, setDropdown] = useState(false);
    // managing dropdown items (list of dropdown items)
    const [items, setItems] = useState( importItems );
    useEffect(() => {
        setItems(importItems);
    }, [importItems]);
    // contains selected items
    const [selectedItems, setSelected] = useState<MultiselectItem[] >(initialSelected ?? []);
    const [didInit, setDidInit] = useState(false);
    
    useEffect(() => {
        if(didInit && initialSelected) {
            setDidInit(true);
            setSelected(initialSelected);
        }
    }, [initialSelected]);

    // search input value
    const [search, setSearch] = useState('');
    const toogleDropdown = () => {
        setDropdown(!dropdown)
    };
    // adds new item to multiselect 
    const addItem = (item) => {
        setSelected(selectedItems.concat(item));
        setDropdown(false);
        setSearch('');
        setSelectedItems(selectedItems.concat(item));
    };
    // removes item from multiselect
    const removeItem = (item) => {
        const filtered = selectedItems.filter((e) => e.name !== item.name);
        setSelected(filtered);
        setDropdown(false);
        setSearch('');
        setSelectedItems(filtered);
    }

    return (
    <div className="autcomplete-wrapper">
        <div className="autcomplete">
            <div className="w-full flex flex-col items-center mx-auto relative">
                <div className="w-full">
                    <div className="flex flex-col items-center">
                        <div className="w-full">
                            <div className={`my-2 p-1 flex border border-gray-200 ${disabled ? 'bg-slate-100 cursor-not-allowed pointer-events-none' : 'bg-white' }  rounded w-full`}>
                                <div className="flex flex-auto flex-wrap">
                                    {
                                        selectedItems?.map((item, index) => {
                                            return (
                                                <div key={index} className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full bg-brand-pale border border-black ">
                                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">{ item.name }</div>
                                                        <div className="flex flex-auto flex-row-reverse">
                                                            <div onClick={() => removeItem(item)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                                                className="feather feather-x cursor-pointer hover:text-brand-electric rounded-full w-4 h-4 ml-2">
                                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                </div>);
                                        })
                                    }
                                    <div className="flex-1">
                                        <input placeholder="" value={search} onClick={() => setDropdown(true)}  onBlur={() => setTimeout(() => {
                                            setDropdown(false);
                                            setSearch('');
                                        }, 200)}  onChange={(e)=> setSearch(e.target.value)} className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"/>
                                    </div>
                                </div>
                                <div className="text-gray-300 w-8 py-1 pl-2 pr-1 flex items-center" onClick={toogleDropdown}>
                                    <button type='button' onBlur={() => setTimeout(() => {
                                            setDropdown(false);
                                            setSearch('');
                                        }, 200)} className={`cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none rotate-180 transform transition-transform ${ dropdown && 'rotate-0' }`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                                            <polyline points="18 15 12 9 6 15"></polyline>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    { dropdown && <Dropdown {...{addItem, removeItem, items, selectedItems, search}} />}
                </div>
            </div>
        </div>
    </div>)
};

export default Multiselect;
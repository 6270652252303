import { Table } from 'components/Table/Table';
import { GetMroWorkOrderItemQuery } from 'graphql/generated';
import { useMemo } from 'react';
import { formatApiDateTime } from 'utils/formatter';

export default function History({ mroWorkOrderItem }: { mroWorkOrderItem: GetMroWorkOrderItemQuery['mroWorkOrderItem'] }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timestamp', // accessor is the "key" in the data
        className: 'w-2/4 pb-3',
        Cell: ({ value }) => formatApiDateTime(value),
      },
      {
        Header: 'User',
        accessor: 'name', // accessor is the "key" in the data
        className: 'w-full font-semibold pb-3',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Operation',
        accessor: 'operation', // accessor is the "key" in the data
        className: 'w-1/4 pb-3',
        Cell: ({ value }) => value,
      },
    ],
    []
  );
  return (
    <div className="flex flex-col relative m-2 p-2 border border-slate-200 rounded shadow-blue bg-white">

    </div>
  );
}

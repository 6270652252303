import { FullFormikInput, FullFormikSelect, IconDropdown } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { Form, Formik } from 'formik';
import { CreateCategoryDocument, GetMroComponentsDocument, GetMroWorkOrderDocument, GetMroWorkOrderQuery } from 'graphql/generated';
import { useMutation, useQuery } from '@apollo/client';

const initialValues = {
  newCategory: '',
  iconName: 'Cog6ToothIcon',
  component: '',
  make: '',
  model: '',
  year: '',
  serialNumber: '',
  name: '',
};

export default function NewCategoryFlyout({
  workOrderId,
  mroCraftId,
  closeFlyout,
  itemCategories,
}: {
  mroCraftId: string;
  workOrderId: string;
  closeFlyout?: () => void;
  itemCategories?: GetMroWorkOrderQuery['mroWorkOrder']['itemCategories'];
}) {
  const [mutate] = useMutation(CreateCategoryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: workOrderId } }],
  });
  const { data: { mroComponents = [] } = {} } = useQuery(GetMroComponentsDocument, { variables: { mroCraftId: mroCraftId } });
  const handleSubmit = function (values: typeof initialValues) {
    if (itemCategories?.find((item) => item.title.toLowerCase() === values.newCategory.toLowerCase()))
      return alert('Category already exists');
    mutate({
      variables: {
        input: {
          title: values.newCategory,
          iconName: values.iconName,
          ...(values.component !== '' && {
            mroComponent:
              values.component === 'new'
                ? {
                    create: {
                      mroCraft: { connect: { id: mroCraftId } },
                      name: values.name,
                      make: values.make,
                      model: values.model,
                      year: parseInt(values.year, 10),
                      serialNumber: values.serialNumber,
                    },
                  }
                : { connect: { id: values.component } },
          }),
          mroWorkOrder: {
            connect: {
              id: workOrderId,
            },
          },
        },
      },
    });
    closeFlyout();
  };

  return (
    <>
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col w-full border rounded shadow bg-white py-10">
                <div className="flex px-10 gap-2">
                  <IconDropdown label={true} iconName={values.iconName} setIconName={(name: string) => setFieldValue('iconName', name)} />
                  <FullFormikInput name="newCategory" label="Name" />
                </div>
                <div className="flex px-10 gap-2">
                  <FullFormikSelect name="component" label="Component" className={'w-full'}>
                    <option value="">None</option>
                    {mroComponents?.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.name} ({component.serialNumber})
                      </option>
                    ))}
                    <option value="new">New Component</option>
                  </FullFormikSelect>
                </div>
                {values.component === 'new' && (
                  <>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="name" label="Name" className="w-full" />
                    </div>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="make" label="Make" className="w-1/2" />
                      <FullFormikInput name="model" label="Model" className="w-1/2" />
                    </div>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="year" label="Year" className="w-1/2" />
                      <FullFormikInput name="serialNumber" label="Serial No." className="w-1/2" />
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-end items-center mt-4">
                <Button text="Create Category" color="navy" size="sm" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftsWithUsersDocument,
  GetFlightDocument,
  IntervalType,
  UpdateFlightDocument,
  GetFlightPurposesDocument,
  GetFlightsDocument,
  GetCraftDocument,
  DeleteFlightDocument,
  GetOrganizationFlightBillingDocument,
  LogType,
  IntervalSubtype,
  FlightUpdateWithIntervalsInput,
} from 'graphql/generated';
import { Formik, Form, Field } from 'formik';
import { useSession } from 'contexts';
import { useContext, useEffect, useMemo, useState } from 'react';
import Toast, { useToast } from 'components/Toast/Toast';
import { FormLabel, FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import DatePicker from 'components/DatePicker/DatePicker';
import Button from 'components/Button/Button';
import RouteItem, { Route } from '../Components/RouteItem';
import { ArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid';
import IntervalItem from '../Components/IntervalItem';
import { FlightSchema } from '../New';
import Modal from 'components/Modal/Modal';
import PassengerItem, { Passenger } from '../Components/PassengerItem';
import { applyTimezone, formatApiDate, formatDateChange, formatToFixedNumber, formatUSD } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Table/Table';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';
import { OrgSubtypes } from 'utils/orgTypes';
import isMobileApp from 'utils/isMobileApp';
import { EyeIcon } from 'assets/icons';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';


export function stripTypenames (value) {
  if (Array.isArray(value)) {
      return value.map(stripTypenames)
  } else if (value !== null && typeof(value) === "object") {
    const newObject = {}
    for (const property in value) {
        if (property !== '__typename') {
          newObject[property] = stripTypenames(value[property])
        }
    }
    return newObject
  } else {
    return value
  }
}

type FlightFiles = File | { signedUrl?: string; name?:string; id?: string };

const EditFlight = function ({ closeFlyout, flightId }: { closeFlyout: () => void, flightId: string }) {
  const { data: { flight } = {} } = useQuery(GetFlightDocument, { variables: { id:flightId }, pollInterval: 0, });
  const { showToast, toastProps } = useToast();
  const { organizationId, craftId, organizationSubtype, user } = useSession();
  const { data: { flightPurposes } = {} } = useQuery(GetFlightPurposesDocument, { variables: { where: { organizationId: { equals: organizationId } } }, pollInterval: 0 });
  const { data: { organization } = {} } = useQuery(GetOrganizationFlightBillingDocument, { variables: { id: organizationId }, pollInterval: 0 });
  const [files, setFiles] = useState<FlightFiles[]>([]);
  const navigate = useNavigate();
  const [showDocument, setShowDocument ] = useState(null);
  const ability = useContext(AbilityContext);
  
  const [ updateFlight ] = useMutation(UpdateFlightDocument, { refetchQueries: [
    GetFlightsDocument,
    { query: GetFlightDocument, variables: { id: flightId  } },
    ...( craftId ? [{ query: GetCraftDocument, variables: { craftId } }] : [] )
  ] });
  const [ deleteFlight ] = useMutation(DeleteFlightDocument, { refetchQueries: [
      GetFlightsDocument,
      { query: GetCraftDocument, variables: { craftId } }
  ] });
  const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
    variables: { organizationId },
    pollInterval: 0,
  });
             
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left w-3/12',
        accessor: 'createdAt',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(formatDateChange(value))}</span>
            </div>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'value',
        className: 'text-center w-3/12',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatUSD(value)}</span>
            </div>
          );
        },
      },
      {
        Header: 'Pilot',
        accessor: 'pilotName',
        className: 'text-center w-3/12',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Correction',
        accessor: 'inputData',
        className: 'w-2/12',
        Cell: ({ value }: any) => {
          const isCorrection = value.function !== 'BILL_FLIGHT';
          return (
            <div className="w-full flex justify-center pl-4">
              <span>{isCorrection && <CheckIcon className='w-5' /> }</span>
            </div>
          );
        },
      },
    ],
    []
  );

  const [ currentCraft, setCurrentCraft ] = useState(crafts?.find((craft) => craft.id === flight?.craft?.id) ?? crafts?.[0]);
  const [ route, setRoute ] = useState<Route[]>([{ icao:'' }, { icao:'' }]);
  const [ showModal, setShowModal ] = useState(false);
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [ initialValues, setInitialValues ] = useState({
    flightDate: new Date(),
    purposeId: flightPurposes?.[0]?.id ?? '',
    customPurpose: 'Other',
    craft: currentCraft,
    pilot: flight?.userProfileId ?? '',
    intervals: [],
    consumables: [],
    notes: flight?.notes?? '',
    fuelAddedBefore: '',
    fuelAddedAfter: '',
    oilAdded: '',
    billable: false,
    proRataShare: false,
    position: flight?.position ?? '',
  });
  
  useEffect(() => {
    if(crafts){
      setCurrentCraft( crafts?.find((craft) => craft.id === flight?.craft.id) );
    }
    if(flight){
      setFiles( flight?.attachments?.map((attach) => ({  id: attach.id, signedUrl: attach.signedUrl, name: attach.name })) );
      setRoute( flight.icaos.map((icao) => ({ icao: icao, id: flight?.route?.find((airport) => airport.icao === icao )?.id })) );
      setPassengers( flight?.passengers.map((passenger) => ({ name: passenger?.user?.lastName ? passenger?.user?.firstName + ' ' + passenger?.user?.lastName : passenger?.nameInvite, 
        id: passenger.id })) );
        
      const fuelAdded = formatToFixedNumber(flight?.consumableEntries?.find((entry) => entry.name === "Fuel Added" )?.value);
      const fuelAddedBefore = formatToFixedNumber(flight?.consumableEntries?.find((entry) => entry.name === "Fuel Added Before" )?.value);
      const fuelAddedAfter = formatToFixedNumber(flight?.consumableEntries?.find((entry) => entry.name === "Fuel Added After" )?.value);
      const oilAdded = formatToFixedNumber(flight?.consumableEntries?.find((entry) => entry.name === "Oil Added" )?.value);
      setInitialValues({
        flightDate: flight?.flightDate,
        customPurpose: flight?.customPurpose,
        purposeId: flight?.purpose?.id ?? 'Other',
        craft: crafts?.find((craft) => craft.id === flight?.craft?.id) ?? crafts?.[0],
        pilot: flight?.userProfileId ?? currentCraft?.userProfiles?.[0]?.id,
        intervals:  flight.normalIntervals,
        consumables: flight.consumables,
        notes: flight?.notes?? '',
        fuelAddedBefore:  fuelAddedBefore ? fuelAddedBefore.toString() : ( fuelAdded ? fuelAdded.toString(): '' ),
        fuelAddedAfter: fuelAddedAfter ? fuelAddedAfter.toString() : '',
        oilAdded: oilAdded ? oilAdded.toString() : '',
        billable: flight.billable,
        proRataShare: flight?.proRataShare,
        position: flight?.position ?? '',
      });
    }
  }, [ flight, crafts ] );
  useEffect(() => {
    if (crafts && craftId){
      setCurrentCraft(crafts.find((craft) => craft.id === craftId) ?? crafts[0]);
    }
  }, [crafts, craftId]);
  function addAirport() {
    setRoute([...route, { icao:'' }]);
  }

  function removeAirport(index: number) {
    const newRoute = [...route];
    newRoute.splice(index, 1);
    setRoute(newRoute);
  }

  function updateAirport(index: number, type: string, value: string) {
    const newRoute = [...route];
    newRoute[index][type] = value;
    setRoute(newRoute);
  }

  function addPassengers() {
    setPassengers([...passengers, { name:'', id:'' }]);
  }

  function removePassenger(index: number) {
    const newPassengers = [...passengers];
    newPassengers.splice(index, 1);
    setPassengers(newPassengers);
  }

  function updatePassenger(index: number, type: string, value: string) {
    setPassengers((prev) => {
      const newPassengers = [...prev];
      newPassengers[index][type] = value;
      return newPassengers;
    });
  }

  const handleSubmit = async (
    { intervals = [], consumables = [], purposeId, fuelAddedBefore, fuelAddedAfter, oilAdded, pilot, ...values }: typeof initialValues,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (nextState?: Partial<any> | undefined) => void
  ) => {
    const createFormattedIntervals = (data: any[], type: IntervalType) => stripTypenames(data).map(interval => ({
      ...interval,
      startValue: formatToFixedNumber(interval?.startValue),
      endValue: formatToFixedNumber(interval?.endValue),
      intervalType: type,
    }));
  
    const intervalsForFlight = [
      ...createFormattedIntervals(intervals, IntervalType.Normal),
      ...createFormattedIntervals(consumables, IntervalType.Consumable),
    ];
  
    const findEntryId = (name: string) => flight?.consumableEntries?.find(entry => entry.name === name)?.id || '';
    const fuelAddedBeforeId = findEntryId("Fuel Added Before");
    const fuelAddedAfterId = findEntryId("Fuel Added After");
    const oilAddedId = findEntryId("Oil Added");
    const pilotId = pilot ?? currentCraft?.userProfiles?.[0]?.id;
  
    const createOrUpdateEntries = (id: string, value: any, name: string) =>
      id && value
        ? { updateMany: [{ where: { id: { equals: id } }, data: { value } }] }
        : value
        ? { create: [{ name, value, userOrgProfile: { connect: { id: pilotId } } }] }
        : {};
  
    const flightInput: FlightUpdateWithIntervalsInput = {
      ...values,
      id: flightId,
      isPending: false,
      position: Number(values.position),
      icaos: route.map(airport => airport.icao),
      route: {
        disconnect: flight?.route.filter(airport => airport.id && !route.find(r => r?.id === airport?.id)).map(airport => ({ id: airport?.id })),
        connect: route.filter(airport => airport.id).map(airport => ({ id: airport?.id })),
      },
      consumableEntries: {
        ...createOrUpdateEntries(fuelAddedBeforeId, fuelAddedBefore, 'Fuel Added Before'),
        ...createOrUpdateEntries(fuelAddedAfterId, fuelAddedAfter, 'Fuel Added After'),
        ...createOrUpdateEntries(oilAddedId, oilAdded, 'Oil Added'),
      },
      attachments: {
        disconnect: flight?.attachments.filter(attach => attach.id).map(attach => ({ id: attach?.id })),
        connect: files.filter(attach => (attach as any)?.id).map(attach => ({ id: (attach as any)?.id })),
      },
      ...(flightPurposes?.find(p => purposeId === p.id)?.name === 'Maintenance' && { billable: false }),
      craft: { connect: { id: currentCraft.id } },
      ...(pilotId && { userProfile: { connect: { id: pilotId } } }),
      usageLogId: flight?.usageRecord?.id,
      ...(purposeId && purposeId !== 'Other' && { purpose: { connect: { id: purposeId } } }),
      intervalsForFlight,
      passengers: passengers
        ? {
            disconnect: flight?.passengers.filter(passenger => passenger.id).map(passenger => ({ id: passenger?.id })),
            connect: passengers.filter(passenger => (passenger as any)?.id).map(passenger => ({ id: (passenger as any)?.id })),
          }
        : undefined,
    };
  
    try {
      await updateFlight({ variables: { input: flightInput } });
      if (files.some(file => (file as any)?.size)) {
        const formData = new FormData();
        files.forEach(file => (file as any)?.size && formData.append('files', file as any));
        formData.append('flightId', flightId);
        await fetch(`${process.env.REACT_APP_API_URL}/attachments/upload`, { method: 'POST', body: formData });
      }
      setSubmitting(false);
      closeFlyout();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <>
      {showDocument && <DocumentViewer uri={showDocument?.uri} fileType={showDocument?.fileType} close={()=>setShowDocument(null)} />}
      { showModal && <Modal message={`You are about to delete this flight.${flight?.transactions && flight.billable ? ' Note: Deleting a billable flights will refund the pilot(s) who were billed' : ''}`} 
        accept={ () => {
          deleteFlight({ variables: { id: flightId } });
          setShowModal(false);
          closeFlyout();
        }}
        cancel={setShowModal} /> }
      <Toast {...toastProps} />
      <Formik enableReinitialize 
        initialValues={initialValues}
        validationSchema={FlightSchema}
        validateOnMount={true}
        onSubmit={()=>{}}>
        {({ isSubmitting, setSubmitting, resetForm, values, setFieldValue, errors, validateForm }) => {
          const totalBillableInterval = +Math.max((values?.intervals?.find( (inter) => inter?.name === currentCraft?.billingInterval?.name )?.endValue ?? 0) - (values?.intervals?.find( (inter) => inter?.name === currentCraft?.billingInterval?.name )?.startValue ?? 0), 0).toFixed(2);
          const totalBillable = (values.proRataShare ? (currentCraft?.hourlyRate * totalBillableInterval) / (passengers.length + 1) : currentCraft?.hourlyRate * totalBillableInterval).toFixed(2);
          const fuelIndex = values?.consumables?.findIndex((item) => item.subtype === IntervalSubtype.Fuel);
          const oilIndex = values?.consumables?.findIndex((item) => item.subtype === IntervalSubtype.Oil);
          const isMaintenance = flightPurposes?.find((p) => values?.purposeId === p.id )?.name === 'Maintenance';
          return (
            <Form>
              <div className="flex flex-col p-4 h-full">
                <div className="border-t border-gray-200">
                  <Card title='General'>
                    <div className="flex w-full md:flex-row flex-col justify-between gap-9 mb-9">
                      <div className='flex flex-col w-full'>
                        <FullFormikSelect
                        name="craft"
                        label="Craft *"
                        value={currentCraft?.id}
                        onChange={(e) => {
                          setCurrentCraft(crafts?.find((c) => c.id === e.target.value));
                        }}>
                          {crafts?.map((craftObj) => (
                            <option key={craftObj.id} value={craftObj.id}>
                              {craftObj.label}
                            </option>
                          ))}
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FullFormikSelect
                        name="pilot"
                        label="Pilot *">
                          {currentCraft?.userProfiles.map((craftObj) => (
                            <option key={craftObj.id} value={craftObj.id}>
                              {craftObj?.user?.lastName ? craftObj?.user?.firstName + ' ' + craftObj?.user?.lastName : craftObj?.nameInvite }
                            </option>
                          ))}
                        </FullFormikSelect>
                      </div>
                    </div>
                    <div className="flex w-full md:flex-row flex-col justify-between gap-9">
                      <div className='flex  w-full'>
                        <FullFormikSelect name="purposeId" label="Purpose">
                          {flightPurposes?.map((purpose) => (
                            <option key={purpose.id} value={purpose.id}>
                              {purpose.name}
                            </option>
                          ))}
                          <option key="other" value="Other">
                            Other
                          </option>
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FormLabel>Flight Date</FormLabel>
                        <Field as={DatePicker} className="w-full" name="flightDate" />
                      </div>
                    </div>
                    <div className="flex w-full mt-5">
                      { flight?.soruce && <div className='flex w-full'>
                        <dt className="font-medium text-gray-500 w-1/3">Info Source:</dt>
                        <dd className="text-gray-900 w-2/3">{flight?.soruce}</dd>
                      </div> }
                    </div>
                  </Card>
                  <Card title='Passengers'>
                    <div className="flex w-full justify-between gap-9 mb-3">
                      <div className='flex flex-col w-full'>
                        {passengers.map((profile, index) => (
                            <PassengerItem key={index} temp={ true } index={index} 
                            options={currentCraft?.userProfiles?.filter((userProfile) => 
                              !passengers.find((value) => value.id === userProfile.id) && values.pilot !== userProfile.id
                            ).map((userProfile) => ({ id: userProfile.id, name: userProfile?.user?.lastName ? userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName : userProfile?.nameInvite }))} 
                            passenger={profile} handleItemUpdate={updatePassenger} handleRemoveItem={removePassenger} />
                        ))}
                      </div>
                    </div>
                    <div className="flex w-full justify-between">
                      <div className='flex flex-col'>
                        <FullFormikCheckBox name="proRataShare" className='cursor-not-allowed opacity-40 pointer-events-none' label='Enable Pro Rata Share' isVertical={true} />
                        <span className='text-xs text-slate-500'>Cannot edit and resent payments</span>
                      </div> 
                      <Button onClick={() => addPassengers()} size='xs' color='navy' className='' text="Add Passenger" />
                    </div>
                  </Card>
                  <Card title='Route'>
                    <div className="flex w-full justify-between gap-9 mb-3">
                      <div className='flex flex-col w-full'>
                        {route.map((airport, index) => (
                          <RouteItem key={index} temp={ index > 1 } index={index} route={airport} handleItemUpdate={updateAirport} handleRemoveItem={removeAirport} />
                        ))}
                      </div>
                    </div>
                    <div className="flex w-full justify-end">
                      <Button onClick={() => addAirport()} size='xs' color='navy' className='' text="Add Airport" />
                    </div>
                  </Card>
                  <Card title='Intervals'>
                    <div className='flex mb-4 font-semibold'>
                      <span className='w-1/3'>Name:</span>
                      <span className='w-1/3'>Out:</span>
                      <span className='w-1/3'>In:</span>
                    </div>
                    { [...values.intervals ?? []].sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)?.map((interval, index) => (
                      <IntervalItem required={true} key={index} interval={interval} error={errors?.intervals?.[index]} index={index} handleItemUpdate={(id, type, value) => {
                        //Find Idx of interval
                        const idx = values.intervals.findIndex((inter) => inter.id === id);
                        const newIntervals = [...values.intervals];
                        newIntervals[idx] = { ...newIntervals[idx], [type]:value };
                        setFieldValue('intervals', newIntervals);                      
                      }} />
                    )) }
                  </Card>
                  <Card title='Consumables'>
                    { organizationSubtype === OrgSubtypes.FREE ? 
                      <>
                        <span className='text-lg font-semibold text-slate-500'>Consumables are not available for free organizations</span>
                      </>
                    : 
                      <>{ values?.consumables && <>
                        <div className='flex mb-4 font-semibold'> 
                        <span className='w-1/3 ml-4'>&nbsp;</span>
                          <span className='w-1/3 ml-4'>Fuel Out:</span>
                          <span className='w-1/3 ml-4'>Fuel In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[fuelIndex], name: 'Fuel' }} error={errors?.consumables?.[fuelIndex]} index={fuelIndex} handleItemUpdate={(id, type, value) => {
                            const newIntervals = [...values?.consumables];
                            newIntervals[fuelIndex] = { ...newIntervals[fuelIndex], [type]: value };
                            setFieldValue('consumables', newIntervals);                         
                          }} />
                        <div className='flex mb-4 font-semibold text-sm'> 
                          <span className='w-1/3 ml-1'>&nbsp;</span>
                          <span className='w-1/3 ml-1'>Fuel Added Before:</span>
                          <span className='w-1/3 ml-1'>Fuel Added After:</span>
                        </div>
                        <div className='flex mb-8 text-sm'>
                          <span className='w-1/3 self-center'>Fuel Added</span>
                          <FullFormikInput name="fuelAddedBefore" onChange={(e) => { 
                            setFieldValue('fuelAddedBefore', e.target.value);
                          }} className="w-1/3 pr-4 h-12" />
                          <FullFormikInput name="fuelAddedAfter" onChange={(e) => { 
                            setFieldValue('fuelAddedAfter', e.target.value);
                          }} className="w-1/3 pr-4 h-12" />
                          
                        </div>
                        <hr className='my-4 border-y-[2px] border-rounded border-slate-100'/>
                        <div className='flex mb-4 font-semibold'> 
                          <span className='w-1/3 ml-4'>&nbsp;</span>
                          <span className='w-1/3 ml-4'>Oil Out:</span>
                          <span className='w-1/3 ml-4'>Oil In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[oilIndex], name: 'Oil' }} error={errors?.consumables?.[oilIndex]} index={oilIndex} handleItemUpdate={(id, type, value) => {                          
                            const newIntervals = [...values?.consumables];
                            newIntervals[oilIndex] = { ...newIntervals[oilIndex], [type]: value };
                            setFieldValue('consumables', newIntervals);                  
                          }} />
                        <div className='flex mb-4'>
                          <span className='w-1/2 self-center'>Oil Added</span>
                          <FullFormikInput name="oilAdded" className="w-1/3" />
                        </div>
                      </>} </>
                    }
                  </Card>
                  <Card title='Flight Attachments'>
                    <div
                    className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      if (e.dataTransfer.items) {
                        // Use DataTransferItemList interface to access the file(s)
                        [...e.dataTransfer.items].forEach((item, i) => {
                          // If dropped items aren't files, reject them
                          if (item.kind === 'file') {
                            const file = item.getAsFile();
                            setFiles((cur) => [...cur, file]);
                          }
                        });
                      } else {
                        // Use DataTransfer interface to access the file(s)
                        [...e.dataTransfer.files].forEach((file, i) => {
                          setFiles((cur) => [...cur, file]);
                        });
                      }
                    }}>
                      <div className="space-y-1 text-center">
                          <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                multiple
                                className="sr-only"
                                onChange={(e) => setFiles((cur) => [...cur, ...Array.from(e.target.files)])}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                      </div>
                    </div>
                    <div className="text-xs text-indigo-800 py-2">
                      {Array.from(files ?? [])
                        .map((file, index) => ( <div key={index} className='flex gap-3 my-2'>
                          <span className='text-lg'>{file.name}</span> 
                          
                          { !isMobileApp() && (file as any).signedUrl && <a href={(file as any).signedUrl} className="font-medium text-indigo-600 hover:text-indigo-500" download>
                            <ArrowDownIcon
                                title="Download"
                                className="text-brand-500 bg-white w-12 h-6 p-0.5 border border-brand-200 rounded cursor-pointer hover:bg-brand-50 transition"
                            /> 
                          </a>}
                          { isMobileApp() && (file as any).signedUrl &&
                            <button onClick={() => setShowDocument({uri:(file as any).signedUrl, fileType: (file as any)?.fileType })}><EyeIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" /></button>
                          }
                          <TrashIcon
                              onClick={() => {
                                setFiles((cur) => cur.filter((f) => f.name !== file.name));
                              }}
                              title="Delete"
                              className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
                          /> 
                        </div> ) )}
                    </div>
                  </Card>
                  <div className="bg-white px-4 py-5 mt-4 flex items-center justify-center">
                    <dt className="text-sm font-medium text-gray-500 w-1/3">Notes</dt>
                    <Field as="textarea" name="notes" className="w-2/3 border-gray-300 rounded" />
                  </div>
                  { organization?.flightBillingEnabled && !isMaintenance && <div className="bg-white px-4 py-5 my-4 flex flex-col items-center shadow border border-slate-200 item-center">
                    <div className='flex w-full justify-between' onClick={() => setFieldValue('billable', !values.billable)}>
                      <dt className="text-sm font-medium text-gray-500 w-1/3">Flight Billable</dt>
                      <FullFormikCheckBox name="billable" />
                    </div>
                    {values.billable && <div className='flex justify-end w-full my-4'>
                      <div className='w-full flex flex-col bg-brand-pale p-5 gap-10'>
                        <div className='justify-between flex w-full'>
                          <dt className="font-medium text-gray-500">Estimated Flight Charges</dt>
                          <span className="text-brand-dark">$ {+totalBillable * (values.proRataShare ? passengers.length + 1 : 1)}</span>
                        </div>
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">{totalBillableInterval} {webFormat(currentCraft?.billingInterval?.intervalSubType)} * {formatUSD(currentCraft?.hourlyRate)}/hr hourly rate</dt>
                        </div>
                        {values.proRataShare && <>
                          <div className='justify-between flex w-full'>
                            <dt className="font-medium text-gray-500">Pro Rata Share</dt>
                            <span className="text-brand-dark">$ { +totalBillable}</span>
                          </div>
                          <div className='justify-end flex w-full'>
                            <dt className="font-medium text-gray-500">{totalBillableInterval}/hr hourly rate per person</dt>
                          </div>
                        </>
                        }
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">This flight will generate a transaction based on the Hourly Rate of {formatUSD(currentCraft?.hourlyRate)} multiplied by {totalBillableInterval} {webFormat(currentCraft?.billingInterval?.intervalSubType)} hours flown {values.proRataShare ? `divided by ${(passengers.length + 1)} people` : ''}. <br></br>(Note editing a flight will not update the transaction)</dt>
                        </div>
                      </div>
                    </div>}
                    <span className="text-sm text-brand-dark">Note: Editing Flight Time will apply a new charge correction</span>
                  </div> }
                  {isMaintenance && <div className="bg-white px-4 py-5 my-4 flex items-center justify-center">
                    <dt className="text-sm font-medium text-gray-500 w-1/3">Maintenance Flight</dt>
                    <span className="text-sm text-gray-900 w-2/3">This flight is marked as a maintenance flight and will not be billed</span>
                  </div>}
                  { flight?.transactions.length !== 0 && <Card title='Tranactions'>
                    <Table
                      columns={columns}
                      data={flight?.transactions ?? []}
                      onRowClick={(row) => {
                        permissionHandler('Payment Admin', ability) &&
                        navigate('/payments?requestsTab=Admin&paymentId=' + row.id);
                      }}
                    />
                  </Card>}
                  <hr />
                  <div className="bg-gray-50 px-4 py-5 flex items-start justify-end gap-10">
                    <Button onClick={() => setShowModal(true)} text="Delete" color="red" size="sm" type="button" />
                    <div className="flex flex-col">
                      <Button disabled={isSubmitting || Object.keys(errors).length > 0} onClick={async (e) => {  
                          handleSubmit(values, setSubmitting, resetForm);                        
                      }} 
                        text={flight?.isPending ? "Submit Flight" : "Save"} color="navy" size="sm" type="submit" />
                      { Object.keys(errors).length > 0 && <span className='text-xs text-red-500'>{'Unable to Save Invaild Data'}</span> }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        } }
      </Formik>
    </>
  );
};

export default EditFlight;

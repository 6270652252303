import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PlusIcon } from 'assets/icons';
import { Table } from 'components/Table/Table';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { GetFlightsDocument, LogType, SortOrder } from 'graphql/generated';
import { useSession } from 'contexts';
import { formatApiDate, formatDateChange, removeK } from 'utils/formatter';
import { EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import New from './New';
import EditFlight from './Edit';
import useParamState from 'hooks/useParamState';
import Tabs from 'components/Tabs/Tabs';
import FavoriteRoutes from './FavoriteRoutes';
import NewFavoriteRoute from './FavoriteRoutes/New';
import { Menu, Transition } from '@headlessui/react';
import isMobileApp from 'utils/isMobileApp';
import BulkImport from './Components/BulkImport';

const Flights = () => {
  const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
  const { organizationId } = useSession();
  const [activeTab, setActiveTab] = useParamState('requestsTab', 'Flights');
  const [ limit, setLimit ] = useState(10);
  const [ skip, setSkip ] = useState(0);
  const [ sortBy, setSortBy ] = useState(['flightDate', 'desc']);
  const [ showImport, setShowImport ] = useState(false);
  const [ query, setQuery ] = useState('');

  const { error, data : { flights, flightCount, pendingFlights, pendingFlightCount } = {}, refetch } = useQuery(GetFlightsDocument, { variables: { where: { 
    craft: { is: { organizationId: { equals: organizationId } } },
    isPending: { equals: false },
  }, skip, limit, orderBy: [ { [sortBy[0]] : { sort: sortBy[1] } }, { position: { sort: SortOrder.Desc} } ] }});
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const searchData = {
        where: { ...(query && { OR: [
          { notes: { startsWith: query } },
          { purpose: { is: { name: { startsWith: query} } } },
          { icaos: { has: query.toUpperCase() } },
          { craft: { is: { name: { startsWith: query } } } },
          { userProfile: { is:{ user: { is: { firstName: { startsWith: query } } } } } },
          { userProfile: { is:{ user: { is: { lastName: { startsWith: query } } } } } },
        ], }),
        isPending: { equals: false },
        craft: { is: { organizationId: { equals: organizationId } } }
       },
      };
      refetch(searchData); 
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [query, refetch]);
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left w-2/12',
        accessor: 'flightDate',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(value)}</span>
            </div>
          );
        },
      },
      {
        Header: 'Route',
        id: 'Route',
        accessor: 'icaos',
        className: 'text-center w-2/12',
        Cell: ({ row, value }: any) => {
          return value?.length ? `${removeK(value?.[0])} - ${removeK(value?.at(-1))}` : 'Unknown';
        }
      },
      {
        Header: 'Purpose',
        accessor: (row: any) => row?.purposeName,
        className: 'text-center w-2/12',
        Cell: ({ row, value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{value ?? 'Unknown'}</span>
            </div>
          );
        }
      },
      {
        Header: 'Pilot',
        accessor: (row: any) => `${row?.pilotName}`,
        className: 'text-left w-2/12',
      },
      {
        Header: 'Aircraft',
        accessor: (row: any) => `${row.craft.name}`,
        className: 'text-left w-2/12',
        
      },
      {
        Header: 'Flight Time',
        accessor: (row: any) => ({
          flightTime: row.flightLog,
        }),
        className: 'text-left w-2/12',
        Cell: ({ row, value }) => {
          return (
          <div className="w-full text-left">
            <span>{value?.flightTime}</span>
          </div>
          );
        },
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        className: 'text-center w-2/12 truncate overflow-hidden text-ellipsis',
      },
    ],
    []
  );
  const tabs = pendingFlightCount === 0 ? ['Flights', 'Favorites'] :  [ 'Flights', {title: 'Pending', count: pendingFlightCount 
  }, 'Favorites' ];
  if (error) return <p>Error :(</p>;
  return (
    <>
      { showImport && <BulkImport cancel={setShowImport} completed={()=> refetch()} /> }
      <Flyout {...flyoutProps} />
      <div className="">
        {/* Title */}
        <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
          <div className="flex items-center">
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Flights</h1>
          </div>
          <div className="flex justify-end gap-2 flex-wrap">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
              <input
                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            { activeTab === "Flights" && <button
              onClick={()=>updateFlyout({ title: 'New Flight', content: <New closeFlyout={()=>{closeFlyout();}} /> })}
              className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
              <PlusIcon className="h-3 w-3 mr-1" />
              Add New Flight
            </button> }
            { activeTab === "Favorites" && <button
              onClick={()=> updateFlyout({ title: 'New Favorite Route', content: <NewFavoriteRoute closeFlyout={()=>{closeFlyout();}} /> })}
              className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
              <PlusIcon className="h-3 w-3 mr-1" />
              Add Route
            </button> }
            <Menu
              as="div"
              className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
              <Menu.Button className="">
                <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                  <Menu.Item>
                    {({ active }) => (
                      isMobileApp() ?
                        <button onClick={async () => { await navigator.share({ title:"Export Flights from Coflyt", url: process.env.REACT_APP_API_URL+"/flights/"+organizationId , text: "Export Flights from Coflyt"}) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Export</button>
                      :
                      <a
                        href={process.env.REACT_APP_API_URL+"/flights/"+organizationId}
                        className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                        download>
                        Export
                      </a>
                    )}
                  </Menu.Item>
                  { !isMobileApp() && <Menu.Item>
                    {({ active }) => (
                      <>
                        <button
                          onClick={()=>setShowImport(true)}
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          >
                          Bulk Import
                        </button>
                      </>
                    )}
                  </Menu.Item> }
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center border border-slate-200 pt-1 bg-slate-50 w-full px-4">
            <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
          </div> 
          <div className="p-2 md:p-6 bg-white rounded border-slate-300 w-full shadow-blue">
            { activeTab !== 'Favorites' && 
              <>
                {(flights?.length ===0 && activeTab === "Flights" ) ? 
                  <div className='flex flex-col justify-center items-center gap-7'>
                    <div className="text-center text-lg text-slate-400 pt-10">No Flights</div>
                    <button                    
                      onClick={()=>updateFlyout({ title: 'New Flight', content: <New closeFlyout={()=>{closeFlyout();}} /> })}
                      className="flex items-center justify-center border border-brand text-sm rounded font-semibold py-1.5 px-4 w-48">
                      <PlusIcon className="h-3 w-3 mr-1" />
                      Add First Flight
                    </button>
                  </div>
                  :
                  
                  <Table
                    className='w-full'
                    skip={skip} limit={limit} count={activeTab === 'Pending' ? pendingFlightCount : flightCount}
                    updateLimitAndSkip = {(lm, sk) => { setLimit(lm); setSkip(sk); }}
                    setSortBy={(sort) => setSortBy(sort)}
                    columns={columns as any}
                    data={( activeTab === 'Pending' ? 
                      pendingFlights : 
                      [...(flights ?? [])].sort((a,b) => {
                        if(sortBy[0] === 'flightDate' && sortBy[1] === 'desc' && a.craft.id === b.craft.id) {
                          if(a.craft.loggingOption === LogType.Logtach){
                            return (Number(a.normalIntervals?.find((interval) => interval.name.includes('Tach'))?.endValue)) > (Number(b.normalIntervals?.find((interval) => interval.name.includes('Tach'))?.endValue)) ? -1 : 1;
                          }else{
                            return (Number(a.normalIntervals?.find((interval) => interval.name.includes('Hobbs'))?.endValue)) > (Number(b.normalIntervals?.find((interval) => interval.name.includes('Hobbs'))?.endValue)) ? -1 : 1;
                          }
                        }
                        return 0;
                      })) ?? []}
                    onRowClick={(row: any) => updateFlyout({ title: activeTab === 'Pending' ? 'Submit Flight' : 'Edit Flight', content: <EditFlight flightId={row.id} closeFlyout={()=>{closeFlyout();}} /> })}
                    />
                }
              </>
            }
            { activeTab === 'Favorites' && 
              <FavoriteRoutes/>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default Flights;
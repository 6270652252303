import { useMutation, useQuery } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Modal from 'components/Modal/Modal';
import { Form, Formik } from 'formik';
import { DeleteMroWorkOrderBillingItemDocument, GetMroWorkOrderBillingItemDocument, GetMroWorkOrderBillingItemsDocument, MroBillableType, UpdateMroWorkOrderBillingItemDocument } from 'graphql/generated';
import { useState } from 'react';


export default function BillableItemFlyout({
    closeFlyout,
    billableItemId,
}: {
    closeFlyout: FlyoutHookReturn['closeFlyout'];
    billableItemId?: string;
}) {
    const [ showModal, setShowModal ] = useState(false);
    const { data : { mroWorkOrderBillingItem } = {} } = useQuery(GetMroWorkOrderBillingItemDocument, { variables: { id: billableItemId } });
    const [ updateMroWorkOrderBillingItem ] = useMutation(UpdateMroWorkOrderBillingItemDocument, {
        refetchQueries: [ GetMroWorkOrderBillingItemsDocument ],
    });
    const [ deleteMroWorkOrderBillingItem ] = useMutation(DeleteMroWorkOrderBillingItemDocument, {
        refetchQueries: [ GetMroWorkOrderBillingItemsDocument ],
    });
    const handleSubmit = async (values) => {
        delete values.__typename;
        await updateMroWorkOrderBillingItem({
            variables: {
                input: {
                    ...values,
                },
            },
        });
        closeFlyout();
    };
    const billableName = mroWorkOrderBillingItem?.isDiscount ? "Discount" : "Charge";
    return (
        <>
            { showModal && <Modal message={"You are about to delete "+mroWorkOrderBillingItem?.name+" "+billableName} 
                accept={async () => { 
                    await deleteMroWorkOrderBillingItem({
                        variables: {
                            id: billableItemId,
                        },
                    });
                    closeFlyout();
                 }}
                cancel={setShowModal} /> }
            <Formik initialValues={mroWorkOrderBillingItem} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form className='mx-3'>
                        <Card>
                            <div className="flex gap-5">
                                <FullFormikInput name="name" label={billableName+" Name"} />
                                <div className='justify-center'>
                                    <FullFormikCheckBox name="active" label="Active" />
                                </div>          
                            </div>
                            <div className="flex gap-5">
                                <FullFormikInput type="number" name="value" label={ values?.isPercentage ? "Percentage %" : "Amount $" } />
                                <div className='justify-center'>
                                    <FullFormikCheckBox name="isPercentage" label="Percent" />
                                </div>                                
                                <FullFormikSelect name="billableType" label="Apply to">
                                    <option value={MroBillableType.Both}>Both (Labor and Parts)</option>
                                    <option value={MroBillableType.Labor}>Labor</option>
                                    <option value={MroBillableType.Parts}>Parts</option>
                                </FullFormikSelect>
                            </div>
                        </Card>
                        <div className="flex justify-end items-center mt-4 gap-4">
                            <Button text="Delete" color="red" size="xs" 
                                icon={<TrashIcon className="h-3 w-3 mr-1"/>} type='button'
                                onClick={() => setShowModal(true)} /> 
                            <Button text="Save" color="navy" size="xs" type='submit' />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ArchiveBoxIcon, PencilIcon, TrashIcon, WrenchIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import StatusButton from 'components/StatusButton/StatusButton';
import {
  GetMroWorkOrderItemDocument,
  GetMroWorkOrderPartDocument,
  RemoveMroWorkOrderPartDocument,
  GetMroEmployeeDocument,
  UpdateMroWorkOrderPartStatusDocument,
  GetMroPartRequestsDocument,
} from 'graphql/generated';
import { useState } from 'react';
import PartDetailEdit from './partials/PartDetailEdit';
import Modal from 'components/Modal/Modal';
import { useSession } from 'contexts';
import { formatApiDate } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import SubmitPartsRequest from './SubmitPartsRequest';

export default function PartDetailFlyout({
  partId,
  workOrderItemId,
  setIsOpen,
  context,
}: {
  context: string;
  partId: string;
  workOrderItemId: string;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { user } = useSession();
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
  });
  const { data: { mroWorkOrderPart } = {} } = useQuery(GetMroWorkOrderPartDocument, {
    variables: { id: partId },
  });
  const [updateOrderPartStatus] = useMutation(UpdateMroWorkOrderPartStatusDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } }],
  });
  const [removeOrderPart] = useMutation(RemoveMroWorkOrderPartDocument, {
    variables: { id: partId },
    refetchQueries: [
      { query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } },
      {
        query: GetMroPartRequestsDocument,
        variables: {
          where: {
            mroWorkOrderItem: { is: { mroWorkOrder: { is: { mroOrganizationId: { equals: user.mroOrganizationId } } } } },
          },
        },
      },
    ],
  });
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const handleStatusChange = (status: string) => {
    updateOrderPartStatus({ variables: { id: partId, status, requesterId: user?.mroEmployeeProfileId } });
  };

  return (
    <>
      <Flyout {...flyoutProps} /> 
      {modal && (
        <Modal
          cancel={() => setModal(false)}
          accept={() => {
            removeOrderPart();
            setIsOpen(false);
          }}
          message="Are you sure you want to remove this part?"
        />
      )}
      <div className="p-4">
        {!edit ? (
          <>
            <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
              <dl className="flex flex-col w-full p-2">
                <div className="flex justify-between pb-2 border-b border-slate-100">
                  <div className="flex flex-col gap-2">
                    <span className="font-bold text-brand-dark">{mroWorkOrderPart?.partNumber}</span>
                    {(mroWorkOrderPart?.mroPart?.description || mroWorkOrderPart?.partDescription ) && (
                      <span className="text-sm mt-[-10px]">Description: {mroWorkOrderPart?.mroPart?.description ?? mroWorkOrderPart?.partDescription}</span>
                    )}
                  </div>
                  <PencilIcon
                    onClick={() => setEdit(true)}
                    className="h-6 w-7 p-1 bg-slate-100 rounded hover:opacity-70 cursor-pointer"
                  />
                </div>
                <div className="flex justify-between pb-2 pt-5">
                  <span className="text-sm font text-brand-dark">Status</span>
                  <span className="w-[150px]">
                    <StatusButton readOnly={true} context="parts" text={mroWorkOrderPart?.status} className="" />
                  </span>
                </div>
                <div className="flex justify-between pb-2 pt-5">
                  <span className="text-sm font text-brand-dark">Need By:</span>
                  <span>{formatApiDate(mroWorkOrderPart?.neededDate)}</span>
                </div>
                {mroWorkOrderPart?.shippingType && <div className="flex justify-between pb-2 pt-5">
                  <span className="text-sm font text-brand-dark">Shipping Type:</span>
                  <span className="font-semibold">{webFormat(mroWorkOrderPart?.shippingType)}</span>
                </div> }
                <div className={`${mroWorkOrderPart?.notes && 'hidden'} mt-2`}>
                  <dt className="text-sm font-medium text-brand-dark">Notes</dt>
                  <p className="rounded border border-slate-200 p-4 text-sm bg-slate-50">{mroWorkOrderPart?.notes}</p>
                </div>
                <div
                  className={`${
                    mroWorkOrderPart?.mroPartOrder?.trackingInfo?.trackingNumber ? '' : 'hidden'
                  } flex justify-between pb-2 pt-5`}>
                  <dt className="text-sm font text-brand-dark">Tracking Info:</dt>
                  <span className="text-slate-500 font-bold">{`${mroWorkOrderPart?.mroPartOrder?.trackingInfo?.carrier} ${mroWorkOrderPart?.mroPartOrder?.trackingInfo?.trackingNumber}`}</span>
                </div>
                { context !== 'dashboard' && (
                <>
                  <hr className="mb-4 mt-2 border-slate-200" />
                  {mroWorkOrderPart?.costPrice && <div className="flex justify-between py-2">
                    <span className="text-sm font-medium text-slate-500">Cost Price</span>
                    <span>{mroWorkOrderPart?.costPrice}</span>
                  </div> }
                  <div className="flex justify-between py-2">
                    <span className="text-sm font-medium text-slate-500">Unit Price</span>
                    <span>{mroWorkOrderPart?.unitPrice}</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span className="text-sm font-medium text-slate-500">Quantity</span>
                    <span>{mroWorkOrderPart?.quantity}</span>
                  </div>
                  <hr className="mb-6 mt-2 border-slate-200" />
                  <div className="flex justify-between pt-2">
                    <span>Total Price</span>
                    <dd className="text-sm font-semibold text-brand-dark">
                      {Number(+mroWorkOrderPart?.unitPrice * +mroWorkOrderPart?.quantity)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) ?? 'N/A'}
                    </dd>
                  </div>
                </> 
                )}
              </dl>
            </div>
            <div className="flex justify-end items-center mt-4 gap-3">
              {context !== 'dashboard' && mroWorkOrderPart?.status === 'REQUESTED' && (
                <>
                  <Button
                    text="Cancel Request"
                    color="pale"
                    icon={<XMarkIcon className="h-3 w-3 self-center mr-1" />}
                    onClick={() => handleStatusChange('DRAFT')}
                    size="xs"
                  />
                </>
              )}
              {mroWorkOrderPart?.status === 'AWAITING_APPROVAL' && (
                <>
                  <Button
                    text="Cancel Approval"
                    color="pale"
                    icon={<XMarkIcon className="h-3 w-3 self-center mr-1" />}
                    onClick={() => handleStatusChange('DRAFT') }
                    size="xs"
                  />
                </>
              )}
              {mroWorkOrderPart?.status === 'READY_FOR_PICKUP' && (
                <>
                  <Button
                    text="Picked Up"
                    icon={<ArchiveBoxIcon className="h-3 w-3 self-center mr-1" />}
                    onClick={() => handleStatusChange('PICKED_UP')}
                    color="navy"
                    size="xs"
                  />
                </>
              )}
              {mroWorkOrderPart?.status === 'PICKED_UP' && (
                <>
                  <Button
                    text="Installed"
                    icon={<WrenchIcon className="h-3 w-3 self-center mr-1" />}
                    onClick={() => handleStatusChange('INSTALLED')}
                    color="green"
                    size="xs"
                  />
                </>
              )}
              {(mroWorkOrderPart?.status === 'DRAFT' ||
                mroWorkOrderPart?.status === 'CANCELLED' ||
                mroWorkOrderPart?.status === 'INSTALLED') && (
                <Button
                  text="Remove Part"
                  color=""
                  icon={<TrashIcon className="h-3 w-3 self-center mr-1" />}
                  className="bg-red-500"
                  onClick={() => setModal(true)}
                  size="xs"
                />
              )}
              {context !== 'dashboard' && mroWorkOrderPart?.status === 'DRAFT' && (
                <Button
                  text="Request Part"
                  icon={<PaperAirplaneIcon className="h-3 w-3 self-center mr-1" />}
                  onClick={() => handleStatusChange('REQUESTED')}
                  color="navy"
                  size="xs"
                />
              )}
               {context !== 'dashboard' && mroWorkOrderPart?.status === 'AWAITING_APPROVAL' && (
                <Button
                  text="Approve and Submit Request"
                  icon={<PaperAirplaneIcon className="h-3 w-3 self-center mr-1" />}
                  onClick={() => updateFlyout({ title: "Submit Part Request", content: <SubmitPartsRequest { ...{ setIsOpen, workOrderItemId, workOrderPart:mroWorkOrderPart } } /> })}
                  color="navy"
                  size="xs"
                />
              )}
              {context === 'dashboard' && mroWorkOrderPart?.status === 'DRAFT' && (
                <Button
                  text="Request Approval"
                  icon={<PaperAirplaneIcon className="h-3 w-3 self-center mr-1" />}
                  onClick={() => handleStatusChange('AWAITING_APPROVAL')}
                  color="orange"
                  size="xs"
                />
              )}
            </div>
          </>
        ) : (
          <PartDetailEdit context={context} part={mroWorkOrderPart} workOrderItemId={workOrderItemId} setEdit={(e) => setEdit(e)} />
        )}
      </div>
    </>
  );
}

import { CheckIcon } from 'assets/icons';
import React from 'react';
import { MultiselectItem } from './Multiselect';
const Dropdown = ({items, addItem, selectedItems, removeItem, search} : 
  {items: MultiselectItem[]; addItem: (item:any)=> void; selectedItems: any; removeItem: (item:any)=> void; search:string; }) => {
  return (
    <div className='relative'>
      <div id="dropdown" className="absolute shadow top-100 w-full bg-white z-40 transformlef-0 rounded max-h-select overflow-y-auto ">
        <div className="flex flex-col w-full">
            { items?.filter((item) => search === '' ? true : item?.name.toLowerCase().includes(search.toLowerCase()) ).map((item, key) => {
                const isSelected = selectedItems?.find((selectedItem) => selectedItem.name === item.name);
                return (
                  <div key={key} 
                  className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-brand-pale" 
                  onClick={() => isSelected ? removeItem(item) : addItem(item)}>
                    <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:bg-pale" >
                      <div className="w-full items-center flex">
                        <div className="mx-2 leading-6 flex justify-between w-full">
                          { item.name }
                          { isSelected && <CheckIcon className="h-5 w-5" aria-hidden="true" /> }
                        </div>
                      </div>
                    </div>
                  </div>
                );
            })}
        </div>
      </div>
    </div>
  );

};

export default Dropdown;
import UserIcon from 'components/UserIcon/UserIcon';
import { PaperClipIcon, PencilIcon } from '@heroicons/react/24/solid';
import { webFormat } from 'utils/statuses';
import { GetMroWorkOrderItemQuery } from 'graphql/generated';

export default function DetailsTop({ mroWorkOrderItem, toggleEdit, setSelectedProfiles, context = '', locked = false }: {
  mroWorkOrderItem: GetMroWorkOrderItemQuery['mroWorkOrderItem'],
  toggleEdit: (bool: boolean) => void,
  setSelectedProfiles: any,
  context?: string
  locked?: boolean
}) {
  const handleEditToggle = function () {
    setSelectedProfiles(mroWorkOrderItem?.assignees);
    toggleEdit(true);
  };
  return (
    <div className="flex flex-col relative w-full border border-slate-200 rounded pt-4 pb-10 shadow-blue bg-white">
      <dl className="flex flex-col w-full p-2 px-10">
        {!locked && <div className="flex justify-between py-2 border-b border-slate-100">
          <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.title}</dd>
          <PencilIcon onClick={() => handleEditToggle()} className="h-6 w-7 p-1 bg-slate-100 rounded hover:opacity-70 cursor-pointer" />
        </div>}
        <div className="flex justify-between py-2 border-b border-slate-100">
          <dt className="text-sm font-medium text-slate-500">Status</dt>
          <dd className="text-sm font-semibold text-brand-dark">{webFormat(mroWorkOrderItem?.status)}</dd>
        </div>
        <div className="flex justify-between py-2 border-b border-slate-100">
          <dt className="text-sm font-medium text-slate-500">Category</dt>
          <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.category?.title}</dd>
        </div>
        <div className="flex justify-between py-2 border-b border-slate-100">
          <dt className="text-sm font-medium text-slate-500">Estimated Hours</dt>
          <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.estimatedHours ?? 'N/A'}</dd>
        </div>
        <div className="flex justify-between py-2 border-b border-slate-100">
          <dt className="text-sm font-medium text-slate-500">Total Hours</dt>
          <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.totalLaborHours}</dd>
        </div>
        {context !== 'dashboard' && (
          <>
            <div className="flex justify-between py-2 border-b border-slate-100">
              <dt className="text-sm font-medium text-slate-500">Bill Flat Rate</dt>
              <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.isFlatRate ? 'Yes' : 'No'}</dd>
            </div>
            { mroWorkOrderItem?.isFlatRate && <div className="flex justify-between py-2 border-b border-slate-100">
              <dt className="text-sm font-medium text-slate-500">Amount</dt>
              <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.flatRate}</dd>
            </div> }
          </>
        )}
        <div className="flex justify-between items-center py-2">
          <dt className="text-sm font-medium text-slate-500">Assignees</dt>
          <dd className="flex -mr-1 -my-[0.125rem]">
            {mroWorkOrderItem?.assignees.map((item, index) => {
              if (!item) return null;
              return (
                <UserIcon
                  key={index}
                  iconSize="sm"
                  iconText={item.firstName + ' ' + item.lastName}
                  iconId={item.firstName + ' ' + item.lastName}
                  iconColor={item.profileColor}
                  className="border-2 border-slate-50"
                />
              );
            })}
          </dd>
        </div>
      </dl>
      <div className={`${mroWorkOrderItem?.notes ? '' : 'hidden'} px-10 mt-2`}>
        <dt className="text-sm font-medium text-slate-500">Notes</dt>
        <p className="rounded border border-slate-200 p-4 text-sm bg-slate-50">{mroWorkOrderItem?.notes}</p>
      </div>
      <div className={`${mroWorkOrderItem?.attachments?.length ? '' : 'hidden'} px-10 mt-2`}>
        <dt className="text-sm font-medium text-gray-500">Attachments</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
            {mroWorkOrderItem?.attachments?.map((attach) => (
              <li key={attach.id} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-2 w-0 flex-1 truncate">{attach.name}</span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href={attach.signedUrl} className="font-medium text-indigo-600 hover:text-indigo-500" download>
                    Download
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </dd>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import SignoffSelector from './SignoffSelector';
import { useMutation } from '@apollo/client';
import { GetMroWorkOrderItemDocument, SignoffStatus, UpdateMroWorkOrderItemDocument } from 'graphql/generated';
import { webFormat } from 'utils/statuses';

export default function SignoffEdit({ mroWorkOrderItem, itemId, toggleEdit }) {
  const [selectedSignoffs, setSelectedSignoffs] = useState([]);
  const [updateWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } }],
  });

  useEffect(() => {
    if (mroWorkOrderItem?.mroWorkOrderSignoffs) {
      setSelectedSignoffs(mroWorkOrderItem.mroWorkOrderSignoffs);
    }
  }, [mroWorkOrderItem]);

  const handleSignoffSelection = function (value) {
    let tempArr = [...selectedSignoffs];
    const i = tempArr.findIndex((e) => e === value || e.title === webFormat(value));
    //remove from selectedSignoffs array
    if (i > -1) {
      tempArr.splice(i, 1);
    }
    //add back Sign Off
    //add to selectedSignoffs array
    else {
      tempArr.push(value);
    }
    setSelectedSignoffs(tempArr);
  };
  const handleUpdateSignOff = function () {
    const signOffsToDelete = mroWorkOrderItem?.mroWorkOrderSignoffs.filter(function (prevSignOff) {
      // disconnect/delete old signoffs
      return !selectedSignoffs.find(function (nextSignOff) {
        return nextSignOff.id === prevSignOff.id;
      });
    });
    const signOffsToCreate = selectedSignoffs.filter(
      (signOff) =>
        typeof signOff === 'string' &&
        !mroWorkOrderItem?.mroWorkOrderSignoffs.find((cSignOff) => {
          return webFormat(signOff) === cSignOff.title;
        })
    ); // check if sign off is already there
    updateWorkOrderItem({
      variables: {
        input: {
          id: itemId,
          mroWorkOrderSignoffs: {
            ...(signOffsToDelete.length > 0 && {
              delete: signOffsToDelete.map((signOff) => {
                return { id: signOff.id };
              }),
            }),
            ...(signOffsToCreate.length > 0 && {
              create: signOffsToCreate.map((signOffName) => {
                return { title: webFormat(signOffName), status: SignoffStatus.Unassigned };
              }),
            }),
          },
        },
      },
    });
    toggleEdit(false);
  };
  return (
    <div>
      <SignoffSelector handleSignoffSelection={handleSignoffSelection} selectedSignoffs={selectedSignoffs} />
      <div className="flex items-center px-10 w-full bg-white py-2 rounded-b border-t border-slate-200 justify-end gap-4">
        <button
          onClick={() => toggleEdit(false)}
          type="submit"
          className="flex items-center justify-center hover:opacity-70 cursor-pointer">
          <span className="text-xs font-semibold cursor-pointer text-slate-500">Cancel</span>
        </button>
        <button
          onClick={handleUpdateSignOff}
          type="submit"
          className="flex items-center justify-center self-end hover:opacity-70 cursor-pointer bg-brand px-3 rounded py-1">
          <span className="text-xs cursor-pointer text-white font-semibold">Save Changes</span>
        </button>
      </div>
    </div>
  );
}

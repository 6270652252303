import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_1185)">
        <path
          d="M11.2 16C10.9348 15.9999 10.6804 15.8945 10.493 15.707L8.49295 13.707L9.90695 12.293L11.2 13.586L14.493 10.293L15.9069 11.707L11.9069 15.707C11.7195 15.8945 11.4651 15.9999 11.2 16Z"
          fill="currentColor"
        />
        <path
          d="M7.19995 14H2.19995V5H14.2V9H16.2V3C16.2 2.73478 16.0946 2.48043 15.9071 2.29289C15.7195 2.10536 15.4652 2 15.2 2H13.2V0H11.2V2H9.19995V0H7.19995V2H5.19995V0H3.19995V2H1.19995C0.934735 2 0.680381 2.10536 0.492844 2.29289C0.305308 2.48043 0.199951 2.73478 0.199951 3L0.199951 15C0.199951 15.2652 0.305308 15.5196 0.492844 15.7071C0.680381 15.8946 0.934735 16 1.19995 16H7.19995V14Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_1185">
          <rect width="16" height="16" fill="white" transform="translate(0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

import { useMutation, useQuery } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Modal from 'components/Modal/Modal';
import { Form, Formik } from 'formik';
import { DeleteMroWorkOrderIntervalDocument, GetMroWorkOrderIntervalDocument, GetMroWorkOrderIntervalsDocument, UpdateMroWorkOrderIntervalDocument } from 'graphql/generated';
import { useState } from 'react';


export default function IntervalFlyout({
    closeFlyout,
    intervalId,
}: {
    closeFlyout: FlyoutHookReturn['closeFlyout'];
    intervalId?: string;
}) {
    const [ showModal, setShowModal ] = useState(false);
    const { data : { mroWorkOrderInterval } = {} } = useQuery(GetMroWorkOrderIntervalDocument, { variables: { id: intervalId } });
    const [updateInterval] = useMutation(UpdateMroWorkOrderIntervalDocument, {
        refetchQueries: [ { query: GetMroWorkOrderIntervalDocument, variables: { id: mroWorkOrderInterval?.id } } ],
    });
    const [deleteInterval] = useMutation(DeleteMroWorkOrderIntervalDocument, {
        refetchQueries: [ { query: GetMroWorkOrderIntervalDocument, variables: { id: mroWorkOrderInterval?.id } } ],
        onCompleted: () => closeFlyout(),
    });
    const initialValues = {
        name: mroWorkOrderInterval?.name,
        value: mroWorkOrderInterval?.value,
    };

    const handleSubmit = async (values) => {
        await updateInterval({
            variables: {
                input: {
                    id: intervalId,
                    ...values
                },
            },
        });
        closeFlyout();
    };
    return (
        <>
            { showModal && <Modal message={"You are about to delete "+mroWorkOrderInterval?.name+" interval."} 
                accept={() => deleteInterval({ variables: { id: intervalId } })}
                cancel={setShowModal} /> }

            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form className='mx-3'>
                        <Card>
                            <div className="flex gap-5">
                                <FullFormikInput name="name" label="Name" />
                                <FullFormikInput type="number" name="value" label="Value" />
                            </div>
                        </Card>
                        <div className="flex justify-end items-center mt-4 gap-4">
                            <Button text="Delete" color="red" size="xs" 
                                icon={<TrashIcon className="h-3 w-3 mr-1"/>} type='button'
                                onClick={() => setShowModal(true)} /> 
                            <Button text="Save" color="navy" size="xs" type='submit' />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
  );
}

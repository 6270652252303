import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
    GetMroWorkOrderTemplatesDocument,
    GetMroWorkOrderTemplatesQuery,
    RemoveMroWorkOrderTemplateDocument,
    UpdateMroWorkOrderTemplateDocument,
} from 'graphql/generated';
import { useSession } from 'contexts';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import { TrashIcon } from 'assets/icons';

const WorkOrderTemplateFlyout = function ({ workOrderTemplate, closeFlyout } : 
    { workOrderTemplate: GetMroWorkOrderTemplatesQuery['mroWorkOrderTemplates'][0], closeFlyout: () => void }) {

    const { user } = useSession();
    const [showModal, setShowModal] = useState(false);
    const [updateWorkOrderTemplate] = useMutation(UpdateMroWorkOrderTemplateDocument, { refetchQueries: [GetMroWorkOrderTemplatesDocument] });
    const [deleteWorkOrderTemplate] = useMutation(RemoveMroWorkOrderTemplateDocument, { refetchQueries: [GetMroWorkOrderTemplatesDocument] });

    const handleSubmit = async function (values) {
        await updateWorkOrderTemplate({
            variables: {
                input: {
                    id: workOrderTemplate.id,
                    title: values.title,
                },
            },
        });
        closeFlyout();
    }
    const handleDelete = async function () {
        await deleteWorkOrderTemplate({
            variables: {
                id: workOrderTemplate.id,
            },
        });
        setShowModal(false);
        closeFlyout();
    }
    return (
        <>
            { showModal && <Modal message="Are you sure you want to delete this Work Order Template" cancel={setShowModal} accept={handleDelete} />}
            <div className="p-4">
                <Formik initialValues={{
                    title: workOrderTemplate.title,
                }} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form>
                    {/* WORK ORDER TEMPLATE */}
                    <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                        <div className="flex gap-1">
                        <FullFormikInput name="title" label="Title" />
                        </div>
                    </div>
                    <div className="flex justify-end items-center mt-4 gap-2">
                        <Button text="Delete" color="red" size="sm" type="button" icon={<TrashIcon className="h-3 w-3 mr-1"/>} onClick={() => setShowModal(true)} />
                        <Button text="Save" color="navy" size="sm" type="submit" />
                    </div>
                    </Form>
                )}
                </Formik>
            </div>
        </>
    );
};

export default WorkOrderTemplateFlyout;

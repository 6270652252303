import { useSession } from 'contexts';
import { format } from 'date-fns';
import { applyTimezone } from 'utils/formatter';
import { addTime, subTime, toLocalISOTime } from 'utils/time';

export const dateTimePickerUTCFomatter = (date) => {
  return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1).toString().padStart(2, '0') + '-' + date.getUTCDate().toString().padStart(2, '0') + 'T' + date.getUTCHours().toString().padStart(2, '0') + ':' + date.getUTCMinutes().toString().padStart(2, '0');
}

const DateTimePicker = (props) => {
  const currentDate = props?.value ?? format(new Date(), 'yyyy-MM-dd\'T\'HH:mm');
  //const minTime = props?.min ? toLocalISOTime(applyTimezone(addTime(props.min, 30), Number(user.timezone))) : undefined;
  //const maxTime = props?.max ? toLocalISOTime(applyTimezone(subTime(props.max, 30), Number(user.timezone))) : undefined;
  return (
    <input
      {...props}
      type="datetime-local"
      className={`rounded border px-2 w-2/3 border-gray-300 ${props?.className ?? ''}`}
      value={currentDate}
    />
  );
};

export default DateTimePicker;

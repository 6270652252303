import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import { FormLabel, StyledFormikField } from 'components/Form/StandardForm';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { GetMroLaborEntryDocument, GetMroWorkOrderDocument, GetMroWorkOrderItemDocument, UpdateMroLaborEntryDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';
import UserIcon from 'components/UserIcon/UserIcon';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { differenceInHours, differenceInMinutes } from 'date-fns';

interface Values {
  costRate: string;
  billableRate: string;
  hours: string;
  startTime: Date | string;
  endTime: Date | string;
}

export default function LaborDetailFlyout({
  laborEntryId,
  workOrderItemId,
  workOrderId,
  isOpen,
  setIsOpen,
}: {
  laborEntryId: string;
  workOrderItemId: string;
  workOrderId?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { data: { mroLaborEntry } = {} } = useQuery(GetMroLaborEntryDocument, {
    variables: { id: laborEntryId },
  });
  const [updateLaborEntry] = useMutation(UpdateMroLaborEntryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } },
      { query: GetMroWorkOrderDocument, variables: { id: workOrderId } }],
  });

  const [initialValues, setInitialValues] = useState<Values>({
    costRate: '',
    billableRate: '',
    hours: '',
    startTime: '',
    endTime: '',
  });

  useEffect(() => {
    if (mroLaborEntry) {
      setInitialValues({
        costRate: mroLaborEntry?.costRate ?? '',
        billableRate: mroLaborEntry?.billableRate ?? '',
        hours: mroLaborEntry?.hours?.toString() ?? '',
        startTime: mroLaborEntry?.startTime,
        endTime: mroLaborEntry?.endTime,
      });
    }
  }, [mroLaborEntry]);
  const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    updateLaborEntry({
      variables: {
        input: {
          id: laborEntryId,
          costRate: parseFloat(values.costRate),
          billableRate: parseFloat(values.billableRate),
          hours: parseFloat(values.hours),
          startTime: new Date(values.startTime),
          endTime: new Date(values.endTime),
        },
      },
    })
      .then(() => {
        setSubmitting(false);
        setIsOpen(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="p-4">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
              <dl className="flex flex-col w-full p-2">
                <div className="flex justify-between py-2 border-b border-slate-100">
                  <FormLabel className="text-sm font-medium text-slate-500">Status</FormLabel>
                  <span className="text-sm font-semibold text-brand-dark">{mroLaborEntry?.status}</span>
                </div>
                <div className="flex justify-between py-2 border-b border-slate-100 mt-10">
                  <FormLabel className="text-sm font-medium text-slate-500">Start Time</FormLabel>
                  <Field as={DateTimePicker} className="w-2/5" name="startTime" onChange={( e )=>{
                    setFieldValue('startTime', e.target.value);
                    const diff = (differenceInMinutes(values.startTime ? new Date(values.endTime) : new Date(), new Date(e.target.value))/60);
                    setFieldValue('hours', diff > 0 ? diff.toFixed(2) : '0' );
                  } }/>
                </div>
                <div className="flex justify-between py-2 border-b border-slate-100">
                  <FormLabel className="text-sm font-medium text-slate-500">End Time</FormLabel>
                  <Field as={DateTimePicker} className="w-2/5" name="endTime" onChange={( e )=>{
                    setFieldValue('endTime', e.target.value);
                    const diff = (differenceInMinutes(new Date(e.target.value), values.startTime ? new Date(values.startTime) : new Date())/60);
                    setFieldValue('hours', diff > 0 ? diff.toFixed(2) : '0');
                  } } />
                </div>
                <div className="flex justify-between py-2">
                  <FormLabel className="text-sm font-medium text-slate-500">Technician</FormLabel>
                  <UserIcon
                    iconSize="sm"
                    iconText={mroLaborEntry?.mroEmployeeProfile?.firstName + ' ' + mroLaborEntry?.mroEmployeeProfile?.lastName}
                    iconId={mroLaborEntry?.mroEmployeeProfile?.firstName + ' ' + mroLaborEntry?.mroEmployeeProfile?.lastName}
                    iconColor={mroLaborEntry?.mroEmployeeProfile?.profileColor}
                    className="border-2 border-slate-50"
                  />
                </div>
                <div className={`${mroLaborEntry?.notes ? '' : 'hidden'} mt-4`}>
                  <dt className="text-sm font-medium text-slate-500">Notes</dt>
                  <p className="rounded border border-slate-200 p-4 text-sm bg-slate-50">{mroLaborEntry?.notes}</p>
                </div>
              </dl>
            </div>
            <div className="flex flex-col w-full border rounded p-8 shadow bg-white mt-4">
              <dl className="flex flex-col w-full p-2">
                <div className="flex justify-between pb-2">
                  <FormLabel className="text-sm font-medium text-slate-500">Billable Rate</FormLabel>
                  <StyledFormikField stripStyle id="billableRate" name="billableRate" type="number" />
                </div>
                <div className="flex justify-between py-2">
                  <FormLabel className="text-sm font-medium text-slate-500">Cost Rate</FormLabel>
                  <StyledFormikField stripStyle id="costRate" name="costRate" type="number" />
                </div>
                <div className="flex justify-between py-2">
                  <FormLabel className="text-sm font-medium text-slate-500">Hours</FormLabel>
                  <StyledFormikField id="hours" name="hours" type="number" step=".01" />
                </div>
                <hr className="mb-6 mt-2" />
                <div className="flex justify-between">
                  <FormLabel className="text-sm font-medium text-slate-500">Total Cost</FormLabel>
                  <dd>
                    {Number(+values?.costRate * +values.hours)?.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                    }) ?? 'N/A'}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <FormLabel className="text-sm font-medium text-slate-500">Total Billable</FormLabel>
                  <dd>
                    {Number(+values?.billableRate * +values.hours)?.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                    }) ?? 'N/A'}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="flex justify-end mt-4">
              <Button text="Save Changes" color="navy" size="sm" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import { PartCondition } from "graphql/generated"

//create options for PartConditions
export const PartConditions = () => {
    return (
        <>
            <option value={PartCondition.New}>New</option>
            <option value={PartCondition.Used}>Used</option>
            <option value={PartCondition.Overhauled}>Overhauled</option>
            <option value={PartCondition.Refurbished}>Refurbished</option>
            <option value={PartCondition.Core}>Core</option>
            <option value={PartCondition.AsRemoved}>As Removed</option>
            <option value={PartCondition.Serviceable}>Serviceable</option>
            <option value={PartCondition.Inspected}>Inspected</option>
        </>
    )
}
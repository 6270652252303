import React from 'react';

const AirplaneInactiveIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      stroke="currentColor"
      strokeWidth="1px"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_425_189)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2 11.2V9.6L8.8 5.6V1.2C8.8 0.536 8.264 0 7.6 0C6.936 0 6.4 0.536 6.4 1.2V5.6L0 9.6V11.2L6.4 9.2V13.6L4.8 14.8V16L7.6 15.2L10.4 16V14.8L8.8 13.6V9.2L15.2 11.2Z"
          fill="transparent"
        />
      </g>
      <defs>
        <clipPath id="clip0_425_189">
          <rect width="15.2" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AirplaneInactiveIcon;

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import {
  GetCraftsWithUsersDocument,
  GetReservationDocument,
  UpdateReservationDocument,
  ReservationUpdateInput,
  GetReservationsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { FullFormikCheckBox, FullFormikTextarea } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { StyledSelect } from 'views/Part91/Aircraft/Squawks/Edit';
import { applyTimezone } from 'utils/formatter';
import DateTimePicker, { dateTimePickerUTCFomatter } from 'components/DateTimePicker/DateTimePicker';
import DatePicker from 'components/DatePicker/DatePicker';

const ReservationSchema = Yup.object().shape({
  from: Yup.date().required("Required"),
  to: Yup.date().required("Required"),
});

const Edit = function ({ closeFlyout, id }: {closeFlyout:(any?)=> void, id:string }) {
    
    const [initialValues, setInitialValues] = useState({ from: '', to: '', pilot: '', craft: '', notes: '', isMaintenance: false, allDay: false });
    const { showToast, toastProps } = useToast();
    const { organizationId, craftId, user } = useSession();
    const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
        variables: { organizationId },
        pollInterval: 0,
    });
    
    const { data:{ reservation } = { reservation: undefined } }  = useQuery(GetReservationDocument, { variables: { id } });
    const [updateReservation] = useMutation(UpdateReservationDocument, {refetchQueries: [ 
        {query: GetReservationsDocument, variables: { where: { craft: { is:{ organizationId: { equals: organizationId } } } } }  } ,
        {query: GetReservationDocument, variables: { id }}
    ] }); 
    const [currentCraft, setCurrentCraft] = useState(crafts?.find((craft) => craft.id === craftId) ?? crafts?.[0]);


    useEffect(() => {
        const from = applyTimezone(reservation?.from, Number(reservation?.timezone), Number(user.timezone));
        const to = applyTimezone(reservation?.to, Number(reservation?.timezone), Number(user.timezone));
        setInitialValues({
            from: dateTimePickerUTCFomatter(from),
            to: dateTimePickerUTCFomatter(to),
            pilot:reservation?.pilotId,
            notes:reservation?.notes,
            craft:reservation?.craftId,
            isMaintenance: reservation?.isMaintenance,
            allDay: reservation?.allDay
        });
    }, [reservation]);
    
    useEffect(() => {
        if (crafts){
            const selectedCraft = crafts.find((craft) => craft.id === craftId) ?? crafts[0];
            setCurrentCraft(selectedCraft);
        }
    }, [crafts]);

    const handleSubmit = async (
    { pilot, ...values }: typeof initialValues,
    { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>
    ) => {        
         //Hack to fix timezone issue
        let from = new Date(values.from+':00Z');
        from.setHours(from.getHours() + (Number(user.timezone)*-1));
        let to = new Date(values.to+':00Z');
        to.setHours(to.getHours() + (Number(user.timezone)*-1));
        if(values.allDay){
            to = new Date(values.to);
            from = new Date(values.from);
            from.setHours(12,0,0,0);
            to.setHours(12,0,0,0);
          }
        const reservationInput: ReservationUpdateInput = {
            id,
            ...values,
            to,
            from,
            craft: { connect: { id: currentCraft.id } },
            ...(pilot ? { pilot: { connect: { id: pilot } } } : {}),
        };

        try {
            await updateReservation({
                variables: {
                    input:reservationInput
                },
            })
        } catch (error) {
            const resError = error as ApolloError;
            if(resError.message === "Reservation Conflict"){
                showToast({ type:ToastLength.Normal, title:"Reservation Conflict", subtitle:"Reservation already made at that time" });
            }
            setSubmitting(false);
            return;
        }
        setSubmitting(false);
        closeFlyout(reservationInput);
        resetForm();
    };

    return (
    <>
        <Toast {...toastProps} />
        <Formik 
        enableReinitialize 
        initialValues={initialValues}
        validationSchema={ReservationSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
            <Form>
                <Card className='m-4'>
                    <div className="">
                        <dl>
                            <div className="bg-white px-4 py-5 flex items-center justify-between">
                            <dt className="text-sm font-medium text-gray-500 w-1/3">From *</dt>
                                {
                                    values.allDay ?
                                    <Field value={values.from} as={DatePicker} name="from" />
                                    :
                                    <Field value={values.from} as={DateTimePicker} name="from" />
                                }
                            </div>
                            <div className="bg-white px-4 py-5 flex items-center justify-between">
                                <dt className="text-sm font-medium text-gray-500 w-1/3">To *</dt>
                                {
                                    values.allDay ?
                                    <Field value={values.to} as={DatePicker} name="to" />
                                    :
                                    <Field value={values.to} as={DateTimePicker} name="to" />
                                }
                            </div>
                            <div className="px-4 py-5 flex items-center justify-between">
                                <FullFormikCheckBox opposite={true} isVertical={true} name="allDay" label="Is all day?" />
                            </div>
                            <div className="px-4 py-5 flex items-center justify-between">
                                <dt className="text-sm font-medium text-gray-500 w-1/3">Aircraft *</dt>
                                <Field
                                as={StyledSelect}
                                name="craft">
                                {crafts?.map((craftObj) => (
                                    <option key={craftObj.id} value={craftObj.id}>
                                    {craftObj.label}
                                    </option>
                                ))}
                                </Field>
                            </div>
                            <div className="px-4 py-5 flex items-center justify-between">
                                <dt className="text-sm font-medium text-gray-500 w-1/3">Pilot *</dt>
                                <Field
                                as={StyledSelect}
                                name="pilot"
                                disabled={values.isMaintenance}
                                value={values.isMaintenance ? '' : values.pilot}>
                                {values.isMaintenance && <option value="None">{values.isMaintenance ? 'N/A' : ''}</option>}
                                {(crafts?.find( (craft) => craft?.id === values.craft  ) )?.userProfiles?.filter((userProfile) => userProfile.user).map((userObj) => (
                                    <option key={userObj.id} value={userObj.id}>
                                    {userObj?.user?.firstName} {userObj?.user?.lastName}
                                    </option>
                                ))}
                                </Field>
                            </div>
                            <div className="bg-white px-4 py-5 flex items-center justify-center">
                            <dt className="text-sm font-medium text-gray-500 w-1/3">Schedule for Maintenance</dt>
                                <div className="w-2/3">
                                    <Field type="checkbox" name="isMaintenance" />
                                </div>
                            </div>
                            <div className="bg-white px-4 py-5 flex items-center justify-between">
                            <dt className="text-sm font-medium text-gray-500 w-1/3">Notes</dt>
                                <FullFormikTextarea name="notes" className="w-2/3 border-gray-300 rounded" placeholder='Notes...' />
                            </div> 
                        </dl>
                    </div>
                </Card>
                <div className="px-4 py-5 flex items-center justify-end gap-10">
                    <Button text="Cancel" size='xs' onClick={closeFlyout} color='pale' />
                    <Button text="Save" type='submit' size='xs' color='navy' />
                
                </div>
            </Form>
        )}
        </Formik>
    </>
    );
};

export default Edit;

import { useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikInput } from "components/Form/StandardForm";
import Modal from "components/Modal/Modal";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetLocationsDocument, GetLocationsQuery, RemoveMroLocationDocument, UpdateMroLocationDocument } from "graphql/generated";
import { useState } from "react";

export default function ViewLocation({ location, closeFlyout }: { location: GetLocationsQuery["mroLocations"][0]; closeFlyout: () => void }) {
    const { user } = useSession();
    const [ showModal, setShowModal ] = useState(false);
    const [deleteLocation] = useMutation(RemoveMroLocationDocument, {
        onCompleted: () => closeFlyout(),
        refetchQueries: [{ query: GetLocationsDocument, variables: { where : { mroOrganizationId: { equals: user?.mroOrganizationId } } } }],
    });
    const [updateLocation] = useMutation(UpdateMroLocationDocument, {
        refetchQueries: [{ query: GetLocationsDocument, variables: { where : { mroOrganizationId: { equals: user?.mroOrganizationId } } } }],
    });
    const initialValues = {
        name: location.name,
        code: location.code,
    };
    return (
        <>
            { showModal && ( <Modal message={'You are about to delete this location.'} accept={ () => deleteLocation({ variables:{ id: location?.id } })} cancel={setShowModal} /> ) }
            <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
                updateLocation({
                    variables: {
                        input: {
                            id: location.id,
                            ...values
                        },
                    },
                })
                .then(() => closeFlyout())
                .catch(console.error);
            }}>
                <Form className="flex flex-col gap-5 mx-5">
                    <Card className="">
                        <div className="flex gap-5">
                            <FullFormikInput name="name" placeholder="Location Name" label="Name" />
                            <FullFormikInput name="code" placeholder="Location Code" label="Code" />
                        </div>
                    </Card>
                    <div className="flex justify-end gap-10">
                        <Button text="Delete" color="red" size="xs" onClick={() => setShowModal(true) } />
                        <Button text="Save" color="navy" size="xs" type="submit" />
                    </div>
                </Form>
            </Formik>
        </>
    );
}
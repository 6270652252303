import { BriefcaseIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import UserIcon from 'components/UserIcon/UserIcon';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GetMroEmployeeDocument } from 'graphql/generated';
import { formatApiDate, formatApiTime } from 'utils/formatter';
import { isActive, isUpcoming, webFormat } from 'utils/statuses';

const OrderCard = ({ order, idx, active }) => (
  <Link
    to={`/mro/work-orders/${order.id}`}
    className={`${idx > 0 && 'mt-2'} ${
      active ? 'hover:shadow-sm' : 'opacity-70 hover:opacity-100'
    } group cursor-pointer transition-all mb-1 last:mb-0`}
    key={order.id}>
    <div
      className={`${
        active ? 'bg-brand-offwhite border-brand-pale' : 'bg-slate-50 border-slate-200'
      } flex items-center gap-2 border rounded-t transition-all bg-white group-hover:bg-brand-offwhite p-2`}>
      <BriefcaseIcon
        className={`${active ? 'bg-brand-pale text-brand-electric' : 'bg-slate-200 text-slate-400'} h-8 w-8 rounded p-[0.375rem]`}
      />
      <div className="grow">
        <div className="flex items-center justify-between">
          <span className="text-sm text-brand-dark text-left font-bold">{order.title}</span>
          <ChevronRightIcon className="w-3 h-3 text-slate-500" />
        </div>
      </div>
    </div>
    <div
      className={`${
        active ? 'border-brand-pale group-hover:bg-brand-offwhite' : 'bg-slate-100'
      } flex items-center justify-between bg-white border border-t-0 rounded-b p-2 transition-all`}>
      <h3 className="font-bold text-brand-dark uppercase text-xs">{webFormat('AWAITING_SIGNOFF')}</h3>
      <span className={`${active ? 'bg-brand-pale text-brand-electric' : 'bg-slate-200 text-slate-400'} text-xs font-bold rounded px-1`}>
        {order.mroCraft?.tailNumber}
      </span>
    </div>
  </Link>
);

const Employee = function () {
  const { employeeId } = useParams();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: employeeId },
  });

  const workOrders = mroEmployeeProfile?.mroWorkOrders;
  const activeOrders = workOrders?.filter((order) => isActive(order.status));
  const upcomingOrders = workOrders?.filter((order) => isUpcoming(order.status));
  return (
    <>
      <div className="bg-white border rounded border-slate-300">
        <div className="flex rounded-t items-center justify-between p-6">
          <div className="flex items-center">
            <UserIcon
              iconSize="xl"
              iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconColor={mroEmployeeProfile?.profileColor}
              className=""
            />
            <div className="ml-2 flex flex-col">
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">
                {mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              </h1>
              <div className="-mt-1 text-sm text-slate-500">
                <span className="text-brand-electric font-medium mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.title}</span>
                <span className="mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.phone}</span>
                <span>{mroEmployeeProfile?.email}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="bg-brand-pale text-sm rounded px-6 py-1 text-brand-electric font-bold">Active</div>
            <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" />
          </div>
        </div>
      </div>
      <div className="flex rounded mt-2 border bg-slate-100 border-slate-300">
        <div className="flex flex-col bg-white rounded-l w-2/3 p-4 border-r">
          <div className="">
            <h2 className="text-brand-dark font-bold border-b border-slate-200 p-2 px-4">Recent Tasks</h2>
            <div className="px-2 py-4">
              {mroEmployeeProfile?.mroLaborEntries.map((item, index) => {
                return (
                  <Link
                    to={'/mro/work-orders/' + item.mroWorkOrderItem?.mroWorkOrder?.id}
                    key={index}
                    className="block w-full border-brand-pale group mb-1 rounded bg-white hover:bg-brand-offwhite transition-all">
                    <div
                      className={`${
                        item.endTime
                          ? 'opacity-50 border-slate-300 group-hover:opacity-100 group-hover:border-brand-pale'
                          : 'border-brand-pale'
                      } flex border rounded items-center justify-between p-4 w-full transition-all`}>
                      <div className="flex items-center w-3/5">
                        <WrenchScrewdriverIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
                        <div className="flex flex-col">
                          <span className="text-lg font-bold text-brand-dark">{item.mroWorkOrderItem.title}</span>
                          <span className="-mt-1 text-brand-electric font-medium text-sm">{item.mroWorkOrderItem.mroWorkOrder.title}</span>
                        </div>
                      </div>
                      <div className="flex flex-col grow">
                        <div className="flex items-center justify-between gap-2 pb-0.5">
                          <span className="text-xs text-slate-500 uppercase">Datetime</span>
                          <h3 className="font-bold text-brand-dark uppercase text-sm">
                            {formatApiDate(item.startTime)} {formatApiTime(item.startTime)}
                          </h3>
                        </div>
                        <div className="flex items-center justify-between gap-2 border-t border-dashed border-brand-pale pt-0.5">
                          <span className="text-xs text-slate-500 uppercase">Status</span>
                          <h3 className="font-bold text-brand-electric uppercase text-sm">{webFormat(item.status)}</h3>
                        </div>
                      </div>
                      <ChevronRightIcon className="w-4 h-4 ml-4 text-slate-500" />
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/3 p-6 shadow-inner">
          <div className="flex flex-col bg-white rounded border border-slate-200 shadow-lg">
            <h2 className="text-brand-dark font-bold p-4 border-b border-dashed border-brand-pale">Active Work Orders</h2>
            <div className="p-4">
              {activeOrders?.map((order, idx) => (
                <OrderCard order={order} idx={idx} active={true} key={order.id} />
              ))}
            </div>
          </div>
          <div className="flex flex-col bg-white rounded border border-slate-200 shadow-lg mt-2">
            <h2 className="text-brand-dark font-bold p-4 border-b border-dashed border-brand-pale">Upcoming Work Orders</h2>
            <div className="p-4">
              {!upcomingOrders?.length && (
                <span className="text-sm text-center font-medium w-full block bg-slate-50 text-slate-500 border border-slate-200 py-1 rounded">
                  No Upcoming Work Orders.
                </span>
              )}
              {upcomingOrders?.map((order, idx) => (
                <OrderCard order={order} idx={idx} active={false} key={order.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee;

import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PlusIcon } from '../../../../../assets/icons';
import { LockClosedIcon, TrashIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import New from './New';
import { GetComponentsDocument, GetCraftDocument, RemoveComponentDocument } from 'graphql/generated';
import { useSession } from 'contexts/SessionContext';
import Button from 'components/Button/Button';
import { Table } from 'components/Table/Table';
import Card from 'components/Card/Card';
import Modal from 'components/Modal/Modal';

const Components = () => { 
  const { craftId } = useSession();
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { loading, error, data } = useQuery(GetComponentsDocument, { variables: { craftId } });
  const [ removeComponent ] = useMutation(RemoveComponentDocument, {
    refetchQueries: [
      { query: GetComponentsDocument, variables: { craftId } },
      { query: GetCraftDocument, variables: { craftId } },
    ],
  });
  const [showDeleted, setShowDeleted] = useState<any>(false);
  const [showLocked, setShowLocked] = useState<any>(false);
  const columns = useMemo(
        () => [
          {
            Header: 'Name',
            id: 'name',
            accessor: 'name', // accessor is the "key" in the data
            className: 'w-4/6 items-start pr-2',
          },
          {
            Header: 'Squawks (Active)',
            id: 'squawks',
            accessor: 'squawks', // accessor is the "key" in the data
            className: 'w-1/6 flex justify-center items-center',
            Cell: ({ value, row }: any) => {
              return <span className="px-2 text-center py-1">{value?.filter(squawk => squawk?.status?.toLowerCase() !== 'resolved').length}</span>;
            },
          },
          {
            Header: '',
            id: 'delete',
            key: 'delete',
            accessor: 'squawks', // accessor is the "key" in the data
            className: 'w-1/6 flex justify-center items-center',
            Cell: ({ value, row }: any) => {
              if(value?.filter(squawk => squawk?.status?.toLowerCase() !== 'resolved').length > 0) {
                return <LockClosedIcon className=" bg-white w-12 h-6 p-0.5 border border-slate-300 rounded cursor-not-allowed" 
                      onClick={()=> setShowLocked(row.original)}/>;
              }else{
                return <TrashIcon className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition" 
                  onClick={()=> setShowDeleted(row.original)} />;
              }
            },
          },
        ], []
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      { showDeleted && <Modal message={`You are about to delete the ${showDeleted.name} component`} 
                              accept={()=> removeComponent({ variables:{ id: showDeleted?.id } })} cancel={setShowDeleted} /> }
      { showLocked && <Modal message={'You cannot delete a component with open squawk'} accept={()=>{}} cancel={setShowLocked} /> }
      <Flyout {...flyoutProps} />
      <Card className="px-2 md:px-8 py-3 m-3 rounded bg-white">
        { data.components && data.components.length > 0 ? 
            <Table columns={columns} 
            data={data?.components?.map(value => ({
              ...value,
              locked: true
            })) ?? []} 
            onRowClick={(row) => {}} />
          : <div className="text-center mt-4">No components found</div>
        }
      </Card>
      <div className='flex justify-end px-3 py-4'>
        <Button text="Add Component" color="navy" size="xs" icon={<PlusIcon className="h-4 w-4 mr-2" />} 
          onClick={() => updateFlyout({title: "New Components", content: <New closeFlyout={closeFlyout} /> })} />
      </div>      
    </>
  );
};

export default Components;

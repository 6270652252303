import { useEffect, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon as CheckFillIcon, XCircleIcon as XFillIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { severityOptions } from 'views/Part145/WorkOrders/Order/WorkOrderTabs/Estimates/partials/NewEstimateItem';
import { formatToFixed, formatUSD } from 'utils/formatter';

type InputProps = {
  id: string;
  title: string;
  desc: string;
  severity: string;
  laborCost: number;
  partsCost: number;
  hours: number;
  parts: any[];
  serviceStatuses: any[];
  setServiceStatuses: React.Dispatch<React.SetStateAction<any[]>>;
};

const ServiceDropdown = function ({ id, title, desc, laborCost, partsCost, hours, severity, parts, serviceStatuses, setServiceStatuses }: InputProps) {
  const [active, setActive] = useState(serviceStatuses.find((obj) => obj.id === id)?.status);
  useEffect(() => {
    setActive(serviceStatuses.find((obj) => obj.id === id)?.status);
  }, [serviceStatuses]);
  
  const handler = function (status) {
    const newObj = { id, laborCost, partsCost, parts, severity, status };
    let tempArr = serviceStatuses;
    let index = tempArr.findIndex((obj) => obj.id === id);
    if (index === -1) {
      tempArr.push(newObj);
    } else {
      tempArr[index] = newObj;
    }
    setActive(status);
    setServiceStatuses([...tempArr]);
  };
  return (
    <div className="group flex flex-col items-start px-2 -mx-2 hover:bg-slate-50 border-slate-300 border-b last:border-b-0 pt-3 pb-2">
      <div className='flex justify-between w-full items-center'>
        <div className="flex font-bold px-2 leading-4 relative">
          {title}
          {severity === 'REQUIRED' && <ExclamationTriangleIcon className="h-3.5 w-3.5 -mr-2 text-amber-500 absolute right-full top-0.5" />}
        </div>
        <div
          className={`${severityOptions[severity]?.textColor} ${severityOptions[severity]?.bgColor} h-6 w-26 text-sm flex font-semibold rounded mr-2 mt-0.5`}>
          <span className="flex w-full justify-center py-1 px-3 items-center select-none capitalize">
            {severityOptions[severity]?.label}
          </span>
        </div>
      </div>
      <span className="w-full text-xs px-2 border-slate-200 py-2">{desc}</span>
      <table className={`my-4 bg-white rounded w-full`}>
        <thead className="border">
          <tr className="text-sm border-t">
            <td></td>
            <td className="px-2 font-bold text-right">Est Hours:</td>
            <td className="px-2">{hours}</td>
            <td className="px-2 font-bold text-right">Labor Cost:</td>
            <td className="px-2 text-right">{formatToFixed(laborCost)}</td>
          </tr>
        </thead>

        {parts?.length > 0 && <thead className="border border-b-0">
          <tr className="text-left text-sm border-b">
            <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
            <th className="px-2 font-semibold w-36">Part #</th>
            <th className="px-2 font-semibold">Description</th>
            <th className="px-2 font-semibold text-right">Unit Price</th>
            <th className="px-2 font-semibold text-right">Subtotal</th>
          </tr>
        </thead> }
        <tbody className="border">
          {parts.map((part, ndx) => {
            return (
              <tr className="text-sm" key={ndx}>
                <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                <td className="align-top px-2">{part.partNumber}</td>
                <td className="align-top px-2">{part.description}</td>
                <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
              </tr>
            );
          })}
          <tr className="text-sm border-t bg-slate-50">
            <td></td>
            <td></td>
            <td className="px-2 font-medium">Parts Cost:</td>
            <td></td>
            <td className="px-2 text-right">{formatToFixed(partsCost)}</td>
          </tr>
          <tr className="text-sm bg-slate-50">
            <td></td>
            <td></td>
            <td className="px-2 font-medium">Labor Cost:</td>
            <td></td>
            <td className="px-2 text-right">{formatToFixed(laborCost)}</td>
          </tr>
          <tr className="text-sm border-t bg-slate-100">
            <td></td>
            <td></td>
            <td className="px-2 font-bold">Item Subtotal:</td>
            <td></td>
            <td className="px-2 text-right font-bold">{formatUSD(partsCost+laborCost)}</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end items-center w-full border-brand-pale border-dashed px-2 pt-0.5">
        <div className="flex gap-3">
          {active === false ? (
            <div className={`flex border-2 rounded-lg p-1 items-center border-rose-500 text-rose-500`} onClick={() => {
              handler(false);
            }}>
              <span>Decline</span>
              <XFillIcon
                className="w-7 h-7 cursor-pointer"
              />
            </div>
          ) : (
            <div onClick={() => {
              handler(false);
            }} className={`grayscale opacity-40 text-rose-500 bg-rose-50 cursor-pointer flex border-2 rounded-lg p-1 items-center border-rose-500`}>
              <span>Decline</span>
              <XCircleIcon className="w-7 h-7 cursor-pointer" />
            </div>
          )}

          {active ? (
            <div onClick={() => {
              handler(true);
            }} className={`text-brand-electric bg-brand-pale cursor-pointer flex border-2 rounded-lg p-1 items-center`}>
              <span>Agree</span>
              <CheckFillIcon className="w-7 h-7 cursor-pointer" />
            </div>
          ) : (
            <div onClick={() => {
              handler(true);
            }} className={`grayscale opacity-40 text-brand-electric bg-brand-pale cursor-pointer flex border-2 rounded-lg p-1 items-center border-brand-electric`}>
              <span>Agree</span>
              <CheckCircleIcon className="w-7 h-7 cursor-pointer" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceDropdown;

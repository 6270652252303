import Button from 'components/Button/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GetMroPartAndInventoriesDocument,
  GetMroPartRequestDocument,
  GetMroPartRequestsDocument,
  FulfillRequestDocument,
  GetMroPartInventoriesDocument,
  GetMroPartsDocument,
  GetMroOrgnizationPartPriceDocument,
} from 'graphql/generated';
import PartLookup, { PartInventoryLookup, PartSerializedParts } from 'views/Part145/Parts/Components/PartLookup';
import { useEffect, useState } from 'react';
import { useSession } from 'contexts';
import { Form, Formik } from 'formik';
import { FormLabel, FullFormikInput, StyledFormikField } from 'components/Form/StandardForm';
import { partMarkup } from 'utils/partMarkup';

const unitPriceConverter = (unitPrice: number, markUpPecentage: number) => {
  return (unitPrice + (unitPrice * (markUpPecentage * .01))).toFixed(2);
}
const FulfillRequest = function ({ partRequestId, closeFlyout }: { partRequestId: string; closeFlyout: () => void }) {
  const { user } = useSession();
  const { data: { mroWorkOrderPart } = {} } = useQuery(GetMroPartRequestDocument, { variables: { partRequestId } });
  const { data: { mroOrganization: { mroPartPriceMatrix } } = { mroOrganization: { mroPartPriceMatrix: { partMatrix:[] } } } } = useQuery(GetMroOrgnizationPartPriceDocument, { variables: { id: user.mroOrganizationId } });
  const [getPart, { data: { mroPart } = { mroPart: undefined } }] = useLazyQuery(GetMroPartAndInventoriesDocument, { fetchPolicy: 'network-only' });
  const [updatePartRequest] = useMutation(FulfillRequestDocument, {
    refetchQueries: [
      { query: GetMroPartsDocument, variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } } },
      {
        query: GetMroPartRequestsDocument,
        variables: {
          where: { mroWorkOrderItem: { is: { mroWorkOrder: { is: { mroOrganizationId: { equals: user.mroOrganizationId } } } } } },
        },
      },
      { query: GetMroPartRequestDocument, variables: { partRequestId } },
      { query: GetMroPartInventoriesDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
    ],
  });

  const [initialValues, setInitialValues] = useState({
    unitPrice: 0,
    costPrice: 0,
    partMarkup: 0,
  });

  const [selectedPart, setSelectedPart] = useState(mroWorkOrderPart?.mroPart);
  const [selectedPartInventory, setSelectedPartInventory] = useState(mroWorkOrderPart?.mroPartInventory);
  const [selectedSerializedPart, setSelectedSerializedPart] = useState(mroWorkOrderPart?.mroSerializedPart);
  
  const [lastPartId, setLastPartId] = useState<undefined | string>();

  useEffect(() => {
    if (selectedPart && lastPartId !== selectedPart.id) {
      getPart({ variables: { id: selectedPart.id } }).then(({ data: { mroPart: newPart } }) => {
        setLastPartId(newPart.id);
        setSelectedPartInventory(newPart.availablePartInventories?.[0]);
      });
    }
  }, [selectedPart, getPart, mroPart, lastPartId]);
  useEffect(() => {
    if (mroWorkOrderPart && getPart) {
      const costPrice = mroWorkOrderPart.costPrice ?? mroWorkOrderPart.unitPrice;
      setSelectedPart(mroWorkOrderPart.mroPart);
      setSelectedPartInventory(mroWorkOrderPart.mroPartInventory);
      getPart({ variables: { id: mroWorkOrderPart.mroPart?.id } });
      setLastPartId(mroWorkOrderPart.mroPart?.id);
      setInitialValues({
        ...initialValues,
        unitPrice: mroWorkOrderPart.unitPrice,
        costPrice,
        partMarkup: Number((((mroWorkOrderPart.unitPrice - costPrice) / costPrice) * 100).toFixed(2)),
      });
    }
  }, [mroWorkOrderPart, getPart]);

  const fulfillRequest = (values: typeof initialValues) => {
    updatePartRequest({
      variables: {
        input: {
          id: partRequestId,
          mroPart: { connect: { id: selectedPart.id } },
          partNumber: selectedPart.partNumber,
          unitPrice: Number(values.unitPrice),
          costPrice: Number(values.costPrice),
          ...(selectedPartInventory?.id && { mroPartInventory: { connect: { id: selectedPartInventory.id } } }),
          ...(selectedSerializedPart?.id && { mroSerializedPart: { connect: { id: selectedSerializedPart.id } } }),
          status: 'READY_FOR_PICKUP',
        },
      },
    });
    closeFlyout();
  }; 
  const recommendBasePrice = Number(selectedPartInventory?.unitPrice || selectedSerializedPart?.unitPrice);
  const [recommendPartPrice, recommendPercent] = partMarkup(recommendBasePrice, mroPartPriceMatrix?.partMatrix);
  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={fulfillRequest} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <PartLookup {...{ selectedPart, setSelectedPart, partNumber: mroWorkOrderPart.partNumber }} />
              {/* SERIALIZED PARTS */}
              {mroPart?.availableSerializedParts?.length > 0 && (
                <PartSerializedParts
                  {...{
                    selectedSerializedPart,
                    setSelectedSerializedPart,
                    serializedParts: selectedPart && mroPart?.availableSerializedParts,
                  }}
                />
              )}
              {selectedPart?.isSerialized && mroPart?.availableSerializedParts?.length === 0 && (
                <span className="text-sm text-center text-red-500">No Serialized Parts available</span>
              )}
              {/* INVENTORY PARTS */}
              {!selectedPart?.isSerialized && mroPart?.availablePartInventories?.length > 0 && (
                <PartInventoryLookup
                  {...{
                    selectedPartInventory,
                    setSelectedPartInventory,
                    partInventories: selectedPart && mroPart?.availablePartInventories,
                  }}
                />
              )}
              {selectedPart?.isSerialized === false && mroPart?.availablePartInventories?.length === 0 && (
                <span className="text-sm text-center text-red-500">No inventory available for this part</span>
              )}
              {selectedPartInventory && selectedPartInventory.quantity < mroWorkOrderPart.quantity && (
                <span className="text-sm text-center text-red-500">Not enough inventory available for this part</span>
              )}
              <div className="flex gap-1 mt-3">
                <div className='flex flex-col w-full'>
                  <FullFormikInput min={0} type="number" name="costPrice" label="Cost Price" stripStyle step="0.01" onChange={(e) => {
                    setFieldValue('costPrice', e.target.value);
                    setFieldValue('unitPrice', unitPriceConverter(Number(e.target.value), Number(values.partMarkup)));
                  }} />
                  <button type='button' onClick={()=> setFieldValue('costPrice', recommendBasePrice)} className='text-center text-sm mt-[-10px] underline'>{recommendBasePrice && recommendBasePrice !== Number(values.costPrice) ? 
                  "Recommended: " + (recommendBasePrice) : '' }</button>
                </div>
                <div className='flex flex-col w-full'>
                  <FullFormikInput min={0} type="number" name="partMarkup" label="Mark Up %" stripStyle step="0.01" onChange={(e) => {
                    setFieldValue('partMarkup', e.target.value);
                    setFieldValue('unitPrice', unitPriceConverter(Number(values.costPrice), Number(e.target.value)));
                  }} />
                  <button type='button' onClick={()=> setFieldValue('partMarkup', (recommendPercent*100))} className='text-center text-sm mt-[-10px] underline'>{recommendPartPrice && recommendPercent*100 !== Number(values.partMarkup) ? 
                    "Recommended: " + (recommendPercent*100) : '' }</button>
                </div>
                <div className='flex flex-col w-full'>
                  <FullFormikInput min={0} type="number" name="unitPrice" label="Unit Price" stripStyle step="0.01" onChange={(e) => {
                    setFieldValue('unitPrice', e.target.value);
                    setFieldValue('costPrice', (Number(e.target.value)*(1-(Number(values.partMarkup)*.01))).toFixed(2) );
                  }} />
                  <button type='button' onClick={()=> setFieldValue('unitPrice', recommendPartPrice)} className='text-center text-sm mt-[-10px] underline'>{recommendPartPrice && recommendPartPrice !== Number(values.unitPrice) 
                      ? "Recommended: " + recommendPartPrice : '' }</button>
                </div>
              </div>
              
            </div>
            {/* BUTTONS */}
            <div className="flex justify-end items-center mt-4 gap-3">
              <button onClick={closeFlyout} className="flex text-sm font-semibold justify-center items-center cursor-pointer text-slate-500">
                Cancel
              </button>
              <Button
                text="Fulfill"
                color="navy"
                disabled={
                  !(selectedPart?.isSerialized && selectedSerializedPart) &&
                  !(!selectedPart?.isSerialized && selectedPartInventory?.quantity >= mroWorkOrderPart?.quantity)
                }
                size="xs"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FulfillRequest;

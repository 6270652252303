import { ChangeEventHandler, useMemo, useState, useEffect } from 'react';
import {
  CreateMroInvoiceDocument,
  GetMroWorkOrderQuery,
  GetWorkOrderBillingDocument,
  GetWorkOrderBillingQuery,
  MroInvoiceCreateInput,
  LastInvoiceDocument,
  GetMroWorkOrderBillingItemsDocument,
} from 'graphql/generated';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { addDays } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { DocumentCheckIcon } from '@heroicons/react/24/solid';
import { randomID } from 'utils/random';
import { useSession } from 'contexts';
import { formatWithZeros } from 'utils/formatter';
import { convertToFee } from 'utils/charge';
import InvoiceView from '../partials/InvoiceView';

const NewInvoice = function ({
  workOrder,
  customer,
  closeFlyout,
}: {
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  customer: GetWorkOrderBillingQuery['mroWorkOrder']['mroCustomer'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
}) {
  const initialVals = useMemo(() => {
    const init = {
      invoiceNumber: '0001',
      invoiceDate: new Date().toISOString(),
      dueDate: addDays(new Date(), 7).toISOString(),
      customer: {
        name: customer.name,
        email: customer.email,
        address1: customer.billingAddress?.address,
        address2: customer.billingAddress?.address2,
        craft: {
          tail: workOrder.mroCraft.tailNumber,
          year: workOrder.mroCraft.year,
          make: workOrder.mroCraft.make,
          model: workOrder.mroCraft.model,
        },
      },
      notes: '',
      items: workOrder.mroWorkOrderItems
        .filter((item) => !item.parentId && item.status !== 'ABORT' && item.status !== 'CUSTOMER_DENIED')
        .map((item) => {
          const partsCost = Number(item.mroWorkOrderParts.reduce((acc, part) => acc + part.unitPrice * part.quantity, 0));
          const laborCost = Number(item.isFlatRate ? item.flatRate : item.totalLaborBillable);
          return {
            id: item.id,
            discrepancy: item.title,
            laborHours: item.totalLaborHours,
            laborCost,
            partsCost,
            active: item.status === 'COMPLETED',
            isFlatRate: item.isFlatRate,
            temp: false,
            notes: item?.description,
            subTotal: laborCost + partsCost,
            model: item.category?.mroComponent?.model,
            serialNumber: item.category?.mroComponent?.serialNumber,
            parts: item.mroWorkOrderParts
              .map((part) => {
                return {
                  quantity: part.quantity,
                  partNumber: part.mroPart?.partNumber ?? part?.partNumber,
                  description: part.mroPart?.description ?? part?.partDescription,
                  unitPrice: part.unitPrice,
                  subtotal: part.unitPrice * part.quantity,
                };
              }),
          };
        }),
      charges: [],
      discounts: [],
      laborSummary: {
        shopLabor: workOrder.mroWorkOrderItems
          .filter((item) => item.status === 'COMPLETED')
          .reduce((acc, item) => acc + Number(item.isFlatRate ? item.flatRate : item.totalLaborBillable), 0),
        outsideLabor: 0,
        totalHours: workOrder.mroWorkOrderItems
          .filter((item) => item.status === 'COMPLETED')
          .reduce((acc, item) => acc + item.totalLaborHours, 0),
      },
      priceSummary: {
        laborFlat: 0,
        laborTM: 0,
        totalLabor: workOrder?.mroWorkOrderItems
          .filter((item) => item.status === 'COMPLETED')
          ?.reduce((acc, item) => acc + Number(item?.isFlatRate ? item?.flatRate : item?.totalLaborBillable), 0),
        totalParts: 0,
        totalCharges: 0,
        totalShipping: 0,
        tax: 0,
        taxRate: 0,
        amountDue: 0,
        deposit: 0,
        discount: 0,
      },
    };

    init.priceSummary.totalParts = init.items.filter((item) => item.active).reduce((acc, item) => acc + item.partsCost, 0);
    const subtotal = init.priceSummary.totalLabor + init.priceSummary.totalParts;
    init.charges.forEach((charge) => {
      if (charge.percent) charge.total = Math.round(charge.percent * subtotal) / 100;
    });
    init.priceSummary.totalCharges = Math.round(init.charges.reduce((sum, charge) => sum + charge.total, 0) * 100) / 100;
    init.priceSummary.tax = 0;
    init.priceSummary.amountDue =
      init.priceSummary.totalLabor +
      init.priceSummary.totalParts +
      init.priceSummary.totalCharges +
      init.priceSummary.totalShipping +
      (subtotal + init.priceSummary.totalCharges) * init.priceSummary.tax;

    return init;
  }, [workOrder, customer]);
    
  const [invoiceItems, setInvoiceItems] = useState<(typeof initialVals.items[number] & { id?: string | number; notes?: string })[]>(
    initialVals.items
  );
  const [taxes, setTaxes] = useState(initialVals.priceSummary.tax);
  const [charges, setCharges] = useState<typeof initialVals.charges>(initialVals.charges);
  const [discounts, setDiscounts] = useState<typeof initialVals.discounts>(initialVals.discounts);

  const [vals, setVals] = useState({
    invoiceNumber: initialVals.invoiceNumber,
    invoiceDate: initialVals.invoiceDate,
    dueDate: initialVals.dueDate,

    totalShipping: initialVals.priceSummary.totalShipping,
    totalParts: initialVals.priceSummary.totalParts,
    totalLabor: initialVals.priceSummary.totalLabor,
    totalHours: initialVals.laborSummary.totalHours,
    totalCharges: Math.round(initialVals.charges.reduce((acc, charge) => acc + charge.total, 0) * 100) / 100,
    discount: initialVals.priceSummary.discount,
    total: initialVals.priceSummary.amountDue,

    notes: initialVals.notes,
  });
  const { user } = useSession();
  const { data: { lastInvoice } = {} } = useQuery(LastInvoiceDocument, { variables: { mroOrgId: user?.mroOrganizationId ?? '' } });

  const [createInvoice] = useMutation(CreateMroInvoiceDocument, {
    refetchQueries: [
      {
        query: GetWorkOrderBillingDocument,
        variables: { mroWorkOrderId: workOrder.id },
      },
      {
        query: LastInvoiceDocument,
        variables: { mroOrgId: user?.mroOrganizationId ?? '' },
      },
    ],
  });

  const handleSubmit = () => {
    const newVals: typeof initialVals & { amount?: number; status?: string; mroWorkOrder?: MroInvoiceCreateInput['mroWorkOrder'] } = {
      ...initialVals,
    };
    const activeItems = invoiceItems.filter((item) => item.active);
    const laborFlat = Math.round(activeItems.filter((item) => item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    const laborTM = Math.round(activeItems.filter((item) => !item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    // const totalCharges = Math.round(charges.reduce((acc, cur) => acc + cur.total, 0) * 100) / 100;
    newVals.invoiceNumber = formatWithZeros(Number(vals.invoiceNumber), 6).toString();
    newVals.invoiceDate = vals.invoiceDate;
    newVals.dueDate = vals.dueDate;
    newVals.items = activeItems;
    newVals.priceSummary = {
      amountDue: vals.total,
      deposit: initialVals.priceSummary.deposit,
      tax: Math.round((vals.totalLabor + initialVals.priceSummary.totalParts + vals.totalLabor) * taxes * 100) / 100,
      taxRate: taxes,
      totalCharges: vals.totalCharges,
      laborFlat: laborFlat,
      laborTM: laborTM,
      totalLabor: vals.totalLabor,
      totalParts: vals.totalParts,
      totalShipping: vals.totalShipping,
      discount: vals.discount,
    };
    newVals.laborSummary = {
      shopLabor: vals.totalLabor,
      outsideLabor: 0,
      totalHours: vals.totalHours,
    };
    newVals.amount = vals.total;
    newVals.mroWorkOrder = { connect: { id: workOrder.id } };
    newVals.status = 'PENDING';
    newVals.charges = charges;
    newVals.discounts = discounts;
    newVals.notes = vals.notes;

    createInvoice({ variables: { input: newVals } })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  useEffect(() => {
    // TO DO: MADE SURE INVOICE NUMBER IS UNIQUE
    setVals({ ...vals, invoiceNumber: formatWithZeros(Number(lastInvoice?.invoiceNumber ?? 0) + 1, 6) ?? formatWithZeros(1, 6) });
  }, [lastInvoice]);
  return (
    <div className="flex flex-col p-4 h-full">
      <InvoiceView
        edit={true}
        invoice={{ ...vals, ...initialVals }}
        location={workOrder?.mroLocation?.code}
        workOrderId={workOrder.id}
        {...{ invoiceItems, setInvoiceItems, discounts, setDiscounts , setCharges, charges, taxes, setTaxes, vals, setVals }}
      />

      <div className="flex gap-2 items-center justify-end mt-2 pb-20">
        <button
          onClick={handleSubmit}
          className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          <>
            <DocumentCheckIcon className="h-2.5 w-2.5 mr-1" />
            Create Invoice
          </>
        </button>
      </div>
    </div>
  );
};

export default NewInvoice;

import React from 'react';

const SandClockIcon = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6 3C6.6 2.77909 6.77909 2.6 7 2.6H17C17.2209 2.6 17.4 2.77909 17.4 3V5.92893C17.4 7.09588 16.9364 8.21504 16.1113 9.0402L14.2828 10.8686C13.658 11.4935 13.658 12.5065 14.2828 13.1314L16.1113 14.9598C16.9364 15.785 17.4 16.9041 17.4 18.0711V21C17.4 21.2209 17.2209 21.4 17 21.4H7C6.77909 21.4 6.6 21.2209 6.6 21V18.0711C6.6 16.9041 7.06357 15.785 7.88873 14.9598L9.71716 13.1314C10.342 12.5065 10.342 11.4935 9.71716 10.8686L7.88873 9.0402C7.06357 8.21504 6.6 7.09588 6.6 5.92893V3Z"
        stroke="currentColor"
        strokeWidth="1.75"
      />
      <path
        d="M7 21.4C6.86205 21.4 6.74042 21.3302 6.66851 21.2239L11.7439 16.9944C11.8923 16.8708 12.1077 16.8708 12.2561 16.9944L17.3315 21.2239C17.2596 21.3302 17.1379 21.4 17 21.4H7Z"
        stroke="currentColor"
        strokeWidth="1.75"
      />
    </svg>
  );
};

export default SandClockIcon;

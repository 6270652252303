import { Craft, Reservation } from "graphql/generated";
import { applyTimezone, formatCalendarHour } from "utils/formatter";
import { useSession } from "contexts";
import { areDaysEqual, getMinutePercentage } from "../utils/date";

export const eventColors = 
["bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white",
"bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", "bg-cyan-500 text-white", "bg-sky-500 text-white", 
"bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white", "bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", 
"bg-cyan-500 text-white", "bg-sky-500 text-white", "bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white",
"bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", "bg-cyan-500 text-white", "bg-sky-500 text-white", "bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white", "bg-lime-500 text-white", "bg-green-500 text-white",
 "bg-teal-500 text-white", "bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white", 
"bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white",
"bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", "bg-cyan-500 text-white", "bg-sky-500 text-white", 
"bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white", "bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", 
"bg-cyan-500 text-white", "bg-sky-500 text-white", "bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white",
"bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", "bg-cyan-500 text-white", "bg-sky-500 text-white", "bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white", "bg-red-500 text-white", "bg-orange-500 text-white", "bg-amber-500 text-white", "bg-yellow-500 text-white", "bg-lime-500 text-white", "bg-green-500 text-white",
 "bg-teal-500 text-white", "bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white"];

export enum TimeSlotsType {
    Week,
    Day
}

function TimeSlots({ data, events = [], onClick, type, crafts }: { crafts?: Craft[], data: Array<Array<Date>>, events?: Array<any>, onClick: (any) => void, type: TimeSlotsType }) {
    const { user } = useSession();
    let colorIndex = -1;
    const eventColorMap = {};

    // Create color mapping for user IDs only once
    for (const event of events) {
        if (!eventColorMap[event.userId]) {
            colorIndex += 1;
            eventColorMap[event.userId] = eventColors[colorIndex];
        }
    }

    const dateConversion = (event: Reservation, field: string) =>
        new Date(applyTimezone(event[field], Number(event.timezone), Number(user.timezone)));

    return (
        <div className="flex rounded-b border-b w-full border-r border-l border-brand">
            {data.map((col, colI) => { 
            var didShowTextOnCol = [];
            return (
                <div
                    className={`flex flex-col ${colI === 0 ? "flex-1" : "min-w-[100px] flex-[1.7]"}`}
                    key={colI}
                >
                    {col.map((row) => {
                        const showTime = colI === 0 && row.getMinutes() === 0;
                        return (
                            <div className="border flex h-[26px]" key={row.toISOString()}>
                                {colI === 0 ? (
                                    <div className="flex w-full">{showTime ? formatCalendarHour(row) : "\xa0"}</div>
                                ) : (
                                    events.map((event: any, eventI) => {
                                        const eventRes = event as Reservation;
                                        const [percentShow, rangeType] = getMinutePercentage(
                                            row,
                                            dateConversion(event, 'from'),
                                            dateConversion(event, 'to')
                                        );

                                        if (
                                            (type === TimeSlotsType.Day && crafts && crafts[colI - 1].id !== eventRes.craftId) ||
                                            (type === TimeSlotsType.Week && percentShow === 0 && 
                                             (areDaysEqual(row, dateConversion(event, 'to')) || areDaysEqual(row, dateConversion(event, 'from'))))
                                        ) {
                                            return null;
                                        }

                                        if (percentShow === 0) return null;

                                        // Cache color mapping only once
                                        if (!eventColorMap[eventI]) {
                                            colorIndex += 1;
                                            eventColorMap[eventI] = colorIndex;
                                        }

                                        const percentStyle = `h-[${String(percentShow + 1).padStart(2, '0')}]%`;
                                        let buttonClassName = `${eventRes.isMaintenance ? 'flex truncate bg-slate-500 text-white' : eventColorMap[event.userId]} ${rangeType === "from" && percentShow !== 100 ? "mb-[-2px]" : "my-[-2px]"} py-3 w-full ${percentStyle}`;
                                        let textForButton = "\xa0";

                                        if (percentShow === 100 && !didShowTextOnCol[event.id] && row.getHours() < 22) {
                                            didShowTextOnCol[event.id] = true;
                                            textForButton = event.name;
                                            buttonClassName += ` z-10 text-xs break-words hover:overflow-visible hover:z-50 hover:text-black`;
                                        }

                                        return (
                                            <button onClick={() => onClick(event)} className={buttonClassName} key={event.id}>
                                                {textForButton}
                                            </button>
                                        );
                                    })
                                )}
                            </div>
                        );
                    })}
                </div>
            )})}
        </div>
    );
}


export default TimeSlots;
import { useMutation, useQuery } from '@apollo/client';
import {
    GetDocumentDocument,
    GetDocumentsDocument,
    RemoveDocumentDocument,
    UpdateDocumentDocument,
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import Toast, { useToast } from 'components/Toast/Toast';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { ArrowDownTrayIcon, PrinterIcon } from '@heroicons/react/24/solid';
import Modal from 'components/Modal/Modal';
import isMobileApp from 'utils/isMobileApp';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import { EyeIcon } from '@heroicons/react/24/outline';

const Edit = function ({ closeFlyout, id }: {closeFlyout:(any?)=> void, id:string }) {

    const [initialValues, setInitialValues] = useState({ name: '' });
    const [modal, setModal] = useState(false);
    const [showDocument, setShowDocument ] = useState(null);
    const { showToast, toastProps } = useToast();
    
    const { data: { document } = {} } = useQuery(GetDocumentDocument, { variables: { id } });
    const [updateDocument] = useMutation(UpdateDocumentDocument, { refetchQueries: [GetDocumentsDocument] }); 
    const [removeDocument] = useMutation(RemoveDocumentDocument, { refetchQueries: [GetDocumentsDocument] }); 
    

    useEffect(() => {
        if (document) {
            setInitialValues({ name: document.name });
        }
    }
    , [document]);
    const handleSubmit = (values) => {
        updateDocument({ variables: { input: { id , name: values.name } } } )
        .then(closeFlyout)
        .catch(console.error);
    };

    const deleteReservation = ()=>{
        removeDocument({ variables: { id } } ).then(() => {
            setModal(false);
            closeFlyout();
        }).catch(console.error);

    }
    return (
    <>        
        <Toast {...toastProps} />
        {showDocument && <DocumentViewer uri={showDocument?.uri} fileType={showDocument?.fileType} close={()=>setShowDocument(null)} />}
        {modal && <Modal cancel={setModal} accept={deleteReservation} message={`Are you sure you want to delete the '${ document.name }' Document ?`}/> }
        <Formik 
            enableReinitialize 
            initialValues={initialValues}
            onSubmit={handleSubmit}>
            {({ isSubmitting, values }) => (
            <Form>
                <div className="flex flex-col p-4  rounded">
                    <div className="flex gap-1 justify-end mb-2">
                        { isMobileApp() ? 
                            <EyeIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" onClick={() => setShowDocument({uri:document?.attachment?.signedUrl, fileType: document?.attachment?.fileType })} />
                        : 
                        <a href={document?.attachment?.signedUrl} target="_blank" rel="noreferrer" download>
                            <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
                        </a> }
                    </div>
                    <div className="border-t border-gray-200">
                        {/* HEADER */}
                        <div className="flex flex-col gap-4 w-full justify-end bg-white rounded shadow border border-slate-200 p-6">
                            <FullFormikInput name="name" label="Document Name" />
                        </div>
                        <div className="bg-gray-50 px-2 py-5 flex items-center justify-end gap-4">
                            <Button 
                                type='button'
                                onClick={() => setModal(true)}
                                text='Delete'
                                color='red'
                                size='xs' />
                            <Button 
                                type='button'
                                onClick={closeFlyout}
                                text='Cancel'
                                color='pale'
                                size='xs' />
                            <Button 
                                type='submit'
                                text='Save'
                                color='navy'
                                size='xs' />
                        </div> 
                    </div>
                </div>
            </Form>
            )}
        </Formik>
    </>
    );
};

export default Edit;

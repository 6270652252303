import { addMinutes, subMinutes } from 'date-fns';
export const addTime = (date: Date | string, minutes: number): Date => {
  const dateObj = new Date(date);
  return addMinutes(dateObj, 30);
};
export const subTime = (date: Date | string, minutes: number): Date => {
  const dateObj = new Date(date);
  return subMinutes(dateObj, 30);
};
export const toLocalISOTime = (date: Date | string): string => {
  const dateObj = new Date(date);
  var tzoffset = new Date().getTimezoneOffset() * 60000;
  return new Date(dateObj.getTime() - tzoffset).toISOString().slice(0, -1);
};

const offsetToIANATimezone = {
  "-12": "Etc/GMT+12",              // International Date Line West
  "-11": "Pacific/Midway",           // Samoa
  "-10": "Pacific/Honolulu",         // Hawaii Standard Time
  "-09": "America/Anchorage",        // Alaska Standard Time
  "-08": "America/Los_Angeles",      // Pacific Standard Time
  "-07": "America/Denver",           // Mountain Standard Time
  "-06": "America/Chicago",          // Central Standard Time
  "-05": "America/New_York",         // Eastern Standard Time
  "-04": "America/Santiago",         // Chile Standard Time
  "-03:30": "America/St_Johns",      // Newfoundland Standard Time
  "-03": "America/Argentina/Buenos_Aires", // Argentina
  "-02": "America/Noronha",          // Fernando de Noronha
  "-01": "Atlantic/Azores",          // Azores
  "00": "Etc/GMT",                   // GMT
  "01": "Europe/Berlin",             // Central European Time
  "02": "Europe/Athens",             // Eastern European Time
  "03": "Europe/Moscow",             // Moscow Standard Time
  "03:30": "Asia/Tehran",            // Iran Standard Time
  "04": "Asia/Dubai",                // Gulf Standard Time
  "04:30": "Asia/Kabul",             // Afghanistan Time
  "05": "Asia/Karachi",              // Pakistan Standard Time
  "05:30": "Asia/Kolkata",           // India Standard Time
  "05:45": "Asia/Kathmandu",         // Nepal Time
  "06": "Asia/Dhaka",                // Bangladesh Time
  "06:30": "Asia/Yangon",            // Myanmar Time
  "07": "Asia/Bangkok",              // Indochina Time
  "08": "Asia/Shanghai",             // China Standard Time
  "09": "Asia/Tokyo",                // Japan Standard Time
  "09:30": "Australia/Adelaide",     // Australian Central Time
  "10": "Australia/Sydney",          // Australian Eastern Time
  "11": "Pacific/Noumea",            // New Caledonia Time
  "12": "Pacific/Auckland",          // New Zealand Standard Time
  "13": "Pacific/Tongatapu",         // Tonga Time
  "14": "Pacific/Kiritimati",        // Line Islands Time
};

function convertOffsetToIANATimezone(offset) {
  return offsetToIANATimezone[offset] || "Unknown Timezone";
}


export function changeTimezone(date, userTimezone) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: convertOffsetToIANATimezone(userTimezone)
  }));

  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); 

}
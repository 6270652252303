import { useQuery } from "@apollo/client";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import Toast, { useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { GetMroRtsTextsDocument, GetMroWorkOrderTemplatesDocument } from "graphql/generated";
import { useMemo } from "react";
import WorkOrderTemplateFlyout from "./WorkOrderTemplateFlyout";
import { formatApiDate } from "utils/formatter";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import NewRTSText from "./RTSText/new";
import ViewRTSText from "./RTSText/view";

export default function WorkOrderSettings() {
    const { user } = useSession();
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const { data: { mroWorkOrderTemplates } = {} } = useQuery(GetMroWorkOrderTemplatesDocument, { variables: { mroOrganizationId: user?.mroOrganizationId } });
    const { data: { mroRTSTexts } = {} } = useQuery(GetMroRtsTextsDocument, { variables: { mroOrganizationId: user?.mroOrganizationId } });
    const columns = useMemo(
        () => [
            {
                Header: 'Template Name',
                accessor: 'title',
                className: 'w-2/3',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-1/3',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
    const columnRTS = useMemo(
        () => [
            {
                Header: 'RTS Name',
                accessor: 'title',
                className: 'w-2/3',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-1/3',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
    return (
        <>
            <Flyout {...flyoutProps} />
            <div className="flex flex-col">
                <Card title="Work Order Templates" className="flex flex-col w-full gap-2 p-10 rounded-l">
                    <div className="flex items-center gap-2 border border-slate-300 rounded">
                        <Table columns={columns} 
                            className="w-full bg-white border rounded"
                            data={mroWorkOrderTemplates ?? []}
                            onRowClick={(row) => updateFlyout(
                                {
                                    title: 'Edit Work Order Template',
                                    content: (
                                        <WorkOrderTemplateFlyout 
                                            workOrderTemplate={row}
                                            closeFlyout={closeFlyout}
                                            />
                                    )
                                }
                        )} />
                    </div>
                </Card>
                <Card title="RTS Text" className="flex flex-col w-full gap-2 p-10 rounded-l">
                    <div className="flex items-center gap-2 border border-slate-300 rounded">
                        <Table columns={columnRTS} 
                            className="w-full bg-white border rounded"
                            data={mroRTSTexts ?? []}
                            onRowClick={(row) => updateFlyout(
                                {
                                    title: 'Edit RTS Text',
                                    content: (
                                        <ViewRTSText 
                                            id={row.id}
                                            closeFlyout={closeFlyout}
                                            />
                                    )
                                }
                        )} />
                    </div>
                    <div className="flex justify-end">
                        <Button text="New RTS" color="navy" size="xs" onClick={() => updateFlyout({ title:"New RTS Statement", content: <NewRTSText closeFlyout={closeFlyout} /> })} />
                    </div>
                </Card>
            </div>
        </>
    )
}
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useParamState(key, initialState) {
  const location = useLocation();  
  let navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const getInitialValue = () => {
    const urlValue = searchParams.get(key);
    return urlValue !== null ? urlValue : initialState;
  };

  const [state, setState] = useState(getInitialValue());

  useEffect(() => {
    const existingParams = new URLSearchParams(location.search);
    existingParams.set(key, state);
    const newURL = `${location.pathname}?${existingParams.toString()}`;
    navigate(newURL);
  }, [key, state, location.pathname]);

  return [state, setState];
};

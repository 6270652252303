import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { SignUpContext } from '..';
import { ApolloError, useMutation } from '@apollo/client';
import { NewUserSignupDocument, OnboardOrgDocument, Role, SignupForServiceDocument } from 'graphql/generated';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import * as Yup from 'yup';
import { FullFormikInput } from 'components/Form/StandardForm';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const ConfirmRegistrationSchema = Yup.object().shape({
    ownerName: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    zip: Yup.number(),
});

const ConfirmRegistration = (props) => {
    const { prevStep, setTailInfo, tailInfo, changeStep, formStep, tailNumber } = useContext(SignUpContext);
    const { toastProps, showToast } = useToast();
    const [ info, setInfo ] = useState({ ownerName: '', address: '', city: '', state: '', zip: '' });
    useEffect(() => {
        setInfo({
            ownerName: tailInfo?.name,
            address: tailInfo?.street,
            city: tailInfo?.city,
            state: tailInfo?.state,
            zip: tailInfo?.zipCode,
        });
    }, [tailInfo]);
    return (
        <div className='relative'>
            <Toast {...toastProps} />
            <Formik
                validationSchema={ConfirmRegistrationSchema}
                initialValues={info}
                enableReinitialize
                onSubmit={async (values: typeof info) => {
                    try {
                        setTailInfo({
                            ...tailInfo,
                            name: values.ownerName,
                        });
                        changeStep(formStep, 'configureAircraft');
                    } catch (error) {
                        const { message } = error as ApolloError;
                        showToast({ title:message, type: ToastLength.Long });
                    }
                }}>
                {({ values, isSubmitting, errors }) => (
                    <Form className="w-96">
                        <div className="flex flex-col mb-4">
                            <span className="text-3xl md:text-2xl font-bold text-brand">Let’s Confirm Your Details</span>
                        </div>
                        <div className="flex flex-col mb-4">
                            <span className="font-semibold text-gray-400">Check the information below from your FAA registration and update for accuracy, if needed.</span>
                        </div>
                        <div className="flex items-center justify-between p-2 w-full bg-brand-pale my-6 rounded border-brand/30 border">
                            <div className="flex items-center">
                            <InformationCircleIcon className="h-4 w-4 mr-1 text-brand-electric opacity-50" />
                            <span className="text-sm text-brand font-semibold">Data loaded from tail number</span>
                            </div>
                            <span className="text-xs font-bold bg-brand-electric/20 text-brand rounded px-2 py-1">{tailInfo?.nNumber ?? tailNumber}</span>
                        </div>
                        <div className="flex flex-col">
                            <FullFormikInput name="ownerName" label="Owner Name" autoComplete="off" />
                        </div>
                        <div className="flex flex-col">
                            <FullFormikInput name="address" label="Address" autoComplete="off" />
                        </div>
                        <div className="flex gap-3">
                            <FullFormikInput name="city" label="City" autoComplete="off" />
                            <FullFormikInput name="state" label="State" autoComplete="off" />
                            <FullFormikInput name="zip" label="Zip" autoComplete="off" />
                        </div>
                        <div className="flex flex-col gap-5 justify-center items-center mt-5">
                            <div className='flex gap-3'>
                                <Button text="Continue" color="" size="sm" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit" />
                            </div>
                            <div className='flex gap-3'>
                                <Button text="Go Back" color="pale" size="sm" disabled={isSubmitting} onClick={() => changeStep(formStep, prevStep[prevStep.length - 1], true)} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ConfirmRegistration;

import { Reservation } from "graphql/generated";
import { eventColors } from "../components/TimeSlots";
import { areDaysEqual, getDaysOfWeek, isBetweenDays } from "../utils/date"
import { randomID } from "utils/random";

function Month({ date, events, onEventSelected }:{ date:Date, events?:Array<any>, onEventSelected:(any)=>void }) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    var lastDayOfPriorMon = new Date(date.getFullYear(), date.getMonth(), 0);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var firstDayOfNextMon = new Date(date.getFullYear(), date.getMonth()+1, 1);
    var days:Array<Date> = [];

    //prior month days
    for (let day = firstDay.getDay(); day > 0; day--) {
        days.push(new Date(lastDayOfPriorMon.getFullYear(),lastDayOfPriorMon.getMonth(), (lastDayOfPriorMon.getDate()-day)+1));
    }
    //current month days
    for (let day = 0; day < lastDay.getDate(); day++) {
        days.push(new Date(firstDay.getFullYear(), firstDay.getMonth(),day+1));
    }
    //next month days
    for (let day = 0; day < 7-(lastDay.getDay()+1); day++) {
        days.push(new Date(firstDayOfNextMon.getFullYear(), firstDayOfNextMon.getMonth(),day+1));
    }

    var [colorIndex, eventColorMap] = [-1, {}];
    events.forEach((event)=>{
        if(!eventColorMap[event.userId]){
            colorIndex += 1;
            eventColorMap[event.userId] = eventColors[colorIndex];
        }
    });    
    return (
      <div className="bg-white px-5 justify-between items-center rounded-b border-slate-300 shadow-blue">
        <section className="py-5">
            {/* days of week */}
            <div className="grid grid-cols-7 text-lg rounded-t border border-brand">
                {daysOfWeek.map((value, index, arr)=>{
                    return(<div className={(index===arr.length-1?"border-transparent ":"")+"border-r border-brand w-full flex justify-center py-2"} key={index}>{value}</div>);
                })}
            </div>
            {/* days */}
            <div className="grid grid-cols-7 rounded-b border-b border-r border-l border-brand min-h-[70]">
                {days.map((day, index)=>{
                    const week = getDaysOfWeek(day);
                    return <div className={`${day.getDay()!==6?"border-r ":""} relative flex flex-col whitespace-pre-wrap border-brand min-h-[100px] text-xs r w-full ${(index<days.length-7?"border-b":"")}`} key={index}>
                        <div className="px-1">{day.getDate()}</div>
                        {/* events */}
                        {events
                        .sort((a, b) => {
                            // Convert event times to Date objects for comparison
                            const aStart = new Date(a.from);
                            const aEnd = new Date(a.to);
                            const bStart = new Date(b.from);
                            const bEnd = new Date(b.to);
                    
                            // Calculate the duration of each event in milliseconds
                            const aDuration = aEnd.getTime() - aStart.getTime();
                            const bDuration = bEnd.getTime() - bStart.getTime();
                    
                            // Sort by duration, longer events first
                            return bDuration - aDuration;
                        })
                        .map((event, eventI)=>{
                            const eventRes = (event as Reservation);
                            const shouldShow = isBetweenDays(day, new Date(eventRes.from), new Date(eventRes.to));
                            if(shouldShow){
                                if(eventColorMap[eventI] === undefined){
                                    colorIndex += 1;
                                    eventColorMap[eventI] = colorIndex;
                                }
                                const isStartDay = areDaysEqual(day, new Date(eventRes.from));
                                const isEndDay = areDaysEqual(day, new Date(eventRes.to));
                                return (<button onClick={()=>onEventSelected(event)} className={`${eventRes.isMaintenance ? 'bg-slate-500 text-white' : eventColorMap[event.userId]} ${!isStartDay? "text-opacity-0" : "text-[#ffffff00]"} my-1 py-2 ${isStartDay && "rounded-l-lg z-10"} ${isEndDay&&"rounded-r-lg"}`} key={eventI}>{event.name}</button>);
                            }else{
                                return null;
                            }
                        })}
                        {/* special dates i.e today */}
                        <span className="text-center bg-brand text-white mt-2 absolute bottom-0 w-full">{areDaysEqual(day, new Date())&&"Today"}</span>
                        {!areDaysEqual(day, new Date())&&<div>&nbsp;</div>}
                    </div>
                })}
            </div>  
            
        </section>

      </div>
    );
}

export default Month;
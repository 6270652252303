import { useMutation, useQuery } from '@apollo/client';
import {  
  CreateFlightPurposeDocument,
  GetFlightPurposesDocument,
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { FullFormikInput } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';

const NewFlightPurpose = function ({ closeFlyout }: { closeFlyout: () => void }) {
    const { organizationId } = useSession();
    const [createFlightPurpose] = useMutation(CreateFlightPurposeDocument, { 
        refetchQueries: [{ query: GetFlightPurposesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    return (
    <>
        <Formik enableReinitialize
        initialValues={{ name : ''}}
        onSubmit={(values) => createFlightPurpose({ variables: { input:{ name: values.name, isCustom: true, organization: { connect: { id: organizationId } } } } }) }>
        {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
                <Form>
                    <Card className="flex flex-col p-4 h-full m-4">
                        <FullFormikInput name="name" label="Name" autoComplete="off" />
                    </Card>
                    <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
                        <Button text="Add New Purpose" type='submit' size='xs' color='navy' />
                    </div>
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default NewFlightPurpose;

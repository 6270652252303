import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_425_324)">
        <path d="M8.19999 11.4L2.79999 6L4.19999 4.6L8.19999 8.6L12.2 4.6L13.6 6L8.19999 11.4Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_425_324">
          <rect width="16" height="16" fill="white" transform="translate(0.200012)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

import { useState, useMemo } from 'react';
import { Table } from 'components/Table/Table';
import { BookOpenIcon, PlusIcon, PrinterIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GetMroWorkOrderQuery, GetWorkOrderLogEntriesDocument } from 'graphql/generated';
import StatusButton from 'components/StatusButton/StatusButton';
import LogEntryFlyout from './View';
import PrintLogEntryFlyout from './PrintLogEntriesFlyout';
import NewLogEntryFlyout from './New';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';

export default function Logs({
  mroWorkOrder,
  updateFlyoutMini,
  updateFlyout,
  closeFlyout,
  ...props
}: {
  mroWorkOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  updateFlyoutMini?: FlyoutHookReturn['updateFlyout'];
}) {
  const [activeStatus, setActiveStatus] = useState('INCOMPLETE');

  const { data } = useQuery(GetWorkOrderLogEntriesDocument, {
    variables: { mroWorkOrderId: mroWorkOrder?.id },
  });
  const logEntries = useMemo(() => {
    return (
      data?.mroWorkOrder?.mroLogEntries?.filter((item) => {
        if (activeStatus === 'ALL') return true;
        const status = item.status.toUpperCase()?.replaceAll(' ', '_');
        if (activeStatus === 'INCOMPLETE' && status === 'INCOMPLETE') return true;
        if (activeStatus === 'SIGNED' && status === 'SIGNED') return true;
        if (activeStatus === 'VOID' && status === 'VOID') return true;
        return false;
      }) ?? []
    );
  }, [data, activeStatus]);

  const columns = useMemo(
    () => [
      {
        Header: 'Logs',
        accessor: 'title', // accessor is the "key" in the data
        className: 'w-full font-semibold pb-3',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Squawks',
        accessor: 'mroLogEntryItems.length',
        className: 'flex justify-end w-full',
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-64 min-w-[8rem]',
        Cell: ({ value, row }: any) => {
          return <StatusButton readOnly={true} context="logEntry" text={value.toUpperCase()} className="" onSelect={(e) => {}} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex justify-between items-center border-y border-slate-200 px-4 pt-1 bg-slate-50">
        <div className="flex items-start">
          <button
            className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('INCOMPLETE')}
            disabled={activeStatus === 'INCOMPLETE'}>
            Incomplete
          </button>
          <button
            className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('SIGNED')}
            disabled={activeStatus === 'SIGNED'}>
            Signed
          </button>
          <button
            className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('VOID')}
            disabled={activeStatus === 'VOID'}>
            Void
          </button>
          <button
            className="text-sm p-2 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('ALL')}
            disabled={activeStatus === 'ALL'}>
            All
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner border-slate-100 border">
        {/* LOG ENTRY */}
        <div className="flex flex-col w-full">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <BookOpenIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">Logs</h1>
          </div>
          {logEntries.length > 0 ? (
            <Table
              className="w-full bg-white border rounded"
              columns={columns}
              data={logEntries}
              onRowClick={(row) =>
                updateFlyout({
                  title: row.title,
                  content: <LogEntryFlyout logEntryId={row.id} />,
                })
              }
            />
          ) : (
            <h2 className="font-bold text-sm text-center py-4 border-x-2">No Logs Found</h2>
          )}
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          <div className="mt-2 self-end flex gap-5">
            {logEntries.length > 0 && (
              <button
                className="flex items-center text-brand-dark hover:text-brand-electric text-xs font-semibold relative z-20 px-4 py-2 border border-slate-200 hover:border-brand-pale rounded bg-white hover:bg-brand-offwhite transition"
                onClick={() =>
                  updateFlyout({
                    title: 'Print Logs',
                    content: <PrintLogEntryFlyout logEntries={logEntries} />,
                  })
                }>
                <PrinterIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
                Print Logs
              </button>
            )}
            <button
              disabled={mroWorkOrder?.itemCategories?.length === 0}
              className="flex items-center text-xs font-semibold relative z-20 px-4 py-2 border border-slate-200 hover:border-brand-pale rounded bg-brand text-white hover:opacity-80 transition disabled:opacity-80"
              onClick={() =>
                updateFlyout({
                  title: 'New Log Entry',
                  content: (
                    <NewLogEntryFlyout
                      categories={mroWorkOrder.itemCategories}
                      workOrderId={mroWorkOrder.id}
                      closeFlyout={() => closeFlyout()}
                    />
                  ),
                })
              }>
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              New Log
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full"></div>
    </>
  );
}

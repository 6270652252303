import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CreateMroRoleDocument, GetMroRoleDataDocument, UpdateMroRoleDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { createMongoAbility, MongoAbility } from '@casl/ability';
import { PlusIcon } from '@heroicons/react/24/outline';

const Roles = function (props) {
  const { user } = useSession();
  const { data: { mroActions, mroOrganization } = {}, refetch } = useQuery(GetMroRoleDataDocument, {
    variables: { mroOrganizationId: user?.mroOrganizationId },
    skip: !user?.mroOrganizationId,
  });
  const [updateRole] = useMutation(UpdateMroRoleDocument);
  const [createRole] = useMutation(CreateMroRoleDocument, {
    refetchQueries: [
      {
        query: GetMroRoleDataDocument,
        variables: { mroOrganizationId: user?.mroOrganizationId },
      },
    ],
  });
  const roles = useMemo(() => [...(mroOrganization?.mroRoles ?? [])], [mroOrganization]);

  const [abilities, setAbilities] = useState<
    {
      id: string;
      ability: MongoAbility;
      newRules: MongoAbility['rules'];
    }[]
  >([]);
  const [newRole, setNewRole] = useState<string>('');

  useEffect(() => {
    if (roles) {
      setAbilities(
        roles.map((role) => {
          return {
            id: role.id,
            ability: createMongoAbility(role.caslRules ?? []),
            newRules: JSON.parse(JSON.stringify(role.caslRules)) ?? [],
          };
        })
      );
    }
  }, [roles]);

  if (!roles) return null;
  if (!abilities) return null;
  if (!mroOrganization) return null;

  return (
    <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded shadow-blue overflow-x-auto">
      <div>
        <table>
          <thead>
            <tr>
              <th className="p-4 text-left">Action</th>
              {roles
                ?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                ?.map((role) => (
                  <th className="p-4" key={role.id}>
                    {role.title}
                  </th>
                ))}
              <th className="p-4 text-left flex gap-3 items-center">
                <input className="px-1 py-0.5 w-32 leading-3" type="text" value={newRole} onChange={(e) => setNewRole(e.target.value)} />
                <button
                  onClick={() => {
                    if (!newRole) return;
                    createRole({
                      variables: {
                        input: {
                          mroOrganization: { connect: { id: user?.mroOrganizationId } },
                          title: newRole,
                        },
                      },
                    });
                    setNewRole('');
                  }}
                  className="flex items-center justify-center h-7 w-7 bg-brand-pale text-brand-electric rounded-full border border-dashed border-brand-electric cursor-pointer transition-all relative hover:text-white hover:bg-brand-electric hover:border-solid">
                  <PlusIcon className="h-4 w-4" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {mroActions?.map((fullAction) => {
              const { action, subject } = fullAction;
              return (
                <tr key={action + subject}>
                  <td className="p-4">{action + ' ' + subject}</td>
                  {!!abilities?.length &&
                    roles?.map((role) => {
                      const { newRules, ability } = abilities.find((a) => a.id === role.id) ?? {};
                      if (!newRules || !ability) return null;
                      return (
                        <td className="p-4" key={role.id}>
                          <input
                            type="checkbox"
                            defaultChecked={ability.can(action, subject)}
                            onChange={(e) => {
                              const newRule = newRules.find((r) => r.action === action && r.subject === subject);
                              if (!newRule) {
                                newRules.push({ action, subject, inverted: !e.target.checked });
                              } else {
                                e.target.checked ? (newRule.inverted = false) : (newRule.inverted = true);
                              }
                              setAbilities([...abilities]);
                            }}
                          />
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          className="bg-brand text-white px-4 py-2 rounded"
          onClick={() => {
            Promise.all(
              abilities.map((obj) => {
                return updateRole({
                  variables: {
                    input: {
                      id: obj.id,
                      caslRules: obj.newRules.filter((r) => r.inverted !== true),
                    },
                  },
                });
              })
            ).then(() => refetch());
          }}>
          Save
        </button>
      </div>
    </div>
  );
};

export default Roles;

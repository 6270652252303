/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { AddConversationParticipantDocument, CreateCustomerThreadDocument, GetCustomerThreadsDocument, GetTwilioTokenDocument } from 'graphql/generated';
import { Client as ConversationsClient, Conversation, Message, Participant, Paginator } from '@twilio/conversations';
import { useMutation, useQuery } from '@apollo/client';
import { useSession } from 'contexts';
import CustomerChatMessage from './CustomerChatMessage';

const CustomerChat = function ({ customerId, mroWorkOrderId, phoneNumber }: { customerId: string, mroWorkOrderId?: string, phoneNumber?: string }) {
  const [msgInput, setMsgInput] = useState('');
  const [conversationsClient, setConversationsClient] = useState<ConversationsClient | null>(null);
  const [getToken] = useMutation(GetTwilioTokenDocument);
  const { user } = useSession();
  const { data: { mroCommunicationThreads } = {}, refetch, loading } = useQuery( GetCustomerThreadsDocument, { variables: { where: { 
    mroOrganizationId: { equals: user.mroOrganizationId }, 
    mroCustomerId: { equals: customerId }, 
    ...( mroWorkOrderId && { mroWorkOrderId: { equals: mroWorkOrderId } } )
  } } } );
  const [ createMroCommunicationThread ] = useMutation(CreateCustomerThreadDocument);
  const [ addConversationParticipant ] = useMutation(AddConversationParticipantDocument);
  const [conversation, setConversation] = useState<Conversation>();
  const [messagesPag, setMessagesPag] = useState<Paginator<Message>>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [oldMessages, setOldMessages] = useState<Message[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const messagesEndRef = useRef(null);
  const messagesSplitRef = useRef(null);
  const scrollToBottom = (behavior = 'smooth') => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };
  useEffect(() => {
    if (!oldMessages.length) scrollToBottom('auto');
  }, [messagesPag, oldMessages]);
  
  useEffect(() => {
    const func = async () => {
      if (!loading && mroCommunicationThreads && mroCommunicationThreads.length === 0) {
        const com = await createMroCommunicationThread({ variables: { input: { title: 'Test Chat' , 
          mroOrganization: { connect: { id: user.mroOrganizationId } }, 
          mroCustomer: { connect: { id: customerId } } ,
          ...( mroWorkOrderId && { mroWorkOrder: { connect: { id: mroWorkOrderId } } }),
        },
        } });
        await addConversationParticipant({ variables: { input:{ communicationThreadId: com?.data?.createMroCommunicationThread?.id, phoneNumber } } });
        refetch();
      }
    };
    func().catch(console.error);
  }, [mroCommunicationThreads]);

  useEffect(() => {
    const func = async () => {
      const { data: tokenData } = await getToken();
      setConversationsClient(new ConversationsClient(tokenData.getTwilioAccessToken));
    };
    func().catch(console.error);
  }, [getToken]);
  
  useEffect(() => {
    if (conversationsClient && mroCommunicationThreads?.length) {
      conversationsClient.getConversationBySid(mroCommunicationThreads?.[0]?.twilioConversationSid).then((convo) => { 
        setConversations((convs) => [...convs, convo]);
        setConversation(convo);
        convo.getMessages(10).then((msgsPag) => {
          setMessages(msgsPag.items);
          setMessagesPag(msgsPag);
        });
        scrollToBottom();
        convo.getParticipants().then((particips) => {
          setParticipants(particips);
        });
      }).catch((error) => console.log(error));
      conversationsClient.on('connectionStateChanged', (state) => {
        if (state === 'connected') {
          setConversations([]);
        }
      });
      conversationsClient.on('messageAdded', (msg) => {
        setMessages((msgs) => [...msgs, msg]);
        scrollToBottom();
      });
      return () => {
        conversationsClient.removeAllListeners();
      };
    }
  }, [conversationsClient, mroCommunicationThreads]);  
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (conversation)
      conversation.sendMessage(msgInput, {
        friendlyName: user?.firstName+' '+user?.lastName,

      });
    setMsgInput('');
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      if (messagesPag.hasPrevPage)
        messagesPag
          .prevPage()
          .then((oldMsgs) => {
            setMessages((msgs) => [...oldMessages, ...msgs]);
            setOldMessages(oldMsgs.items);
            setMessagesPag(oldMsgs);
            messagesSplitRef.current?.scrollIntoView();
          })
          .catch(console.error);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full h-[calc(100%-73px)] relative bg-white">
        <form className="w-full flex items-center p-4 absolute bottom-0 border-y border-slate-300 bg-slate-50" onSubmit={handleSubmit}>
          <PaperClipIcon className="h-8 w-8 p-1 rounded-full mr-2 text-brand-electric" />
          <input
            className="w-full bg-white rounded-l border-r-0 p-2 border border-slate-300 font-medium text-brand-dark text-sm"
            placeholder="Type message"
            value={msgInput}
            onChange={(e) => setMsgInput(e.target.value)}></input>
          <button className="flex items-center bg-brand-pale text-brand-electric border border-brand-electric text-sm rounded-r font-semibold py-2 px-4">
            <PaperAirplaneIcon className="h-4 w-4 mr-1 rounded-full" />
            Send
          </button>
        </form>

        {/* MESSAGES CONTAINER */}
        <div className="flex flex-col pt-0 px-4 pb-8 shadow-inner h-[calc(100%-72px)] overflow-y-auto" onScroll={handleScroll}>
          {!messagesPag && <div className="self-center mt-4">Loading...</div>}
          {messagesPag && (
            <>
              {!messagesPag.hasPrevPage && <div className="self-center mt-4">Beginning of Conversation</div>}
              {oldMessages.map((item) => (
                <CustomerChatMessage item={item} user={user} key={item.sid} />
              ))}
              <div ref={messagesSplitRef} />
              {messages.map((item) => (
                <CustomerChatMessage item={item} user={user} key={item.sid} />
              ))}
            </>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

export default CustomerChat;

import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroWorkOrderDocument, UpdateMroWorkOrderItemDocument } from 'graphql/generated';
import { formatApiDate, formatToFixed } from 'utils/formatter';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Category from './Category';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { useSession } from 'contexts';
import UserCard from 'components/UserCard/UserCard';
import FlyoutMini from 'components/FlyoutMini/FlyoutMini';

export default function WorkOrderFlyout({
  orderId,
  workOrderSelected,
  closeFlyout,
  handleTaskSelect,
}: {
  orderId: string;
  workOrderSelected?: () => void;
  closeFlyout?: () => void;
  handleTaskSelect?: (task: any) => boolean;
}) {
  const { flyoutProps, updateFlyout } = useFlyout();
  const { flyoutProps: flyoutMiniProps, updateFlyout: updateFlyoutMini } = useFlyout();
  const { data: { mroWorkOrder } = {} } = useQuery(GetMroWorkOrderDocument, { variables: { id: orderId } });
  const [activeStatus, setActiveStatus] = useState('IN_PROGRESS');
  const [updateWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument);

  const { selectedTask, setSelectedTask } = useSession();
  const tableData = React.useMemo(
    () =>
      mroWorkOrder?.itemCategories?.reduce((acc, cur) => {
        acc[cur.id] = cur.mroWorkOrderItems.filter((item) => {
          
          const status = item.status?.toUpperCase()?.replaceAll(' ', '_');
          if (item.parentId) return false;
          if (status === 'ABORT' || status === 'CUSTOMER_DENIED') return false;
          if (activeStatus === 'ALL') return true;
          
          if (activeStatus === 'IN_PROGRESS' && status !== 'COMPLETED') return true;
          if (activeStatus === 'COMPLETED' && status === 'COMPLETED') return true;
          return false;
        });
        return acc;
      }, {}) ?? {},
    [mroWorkOrder, activeStatus]
  );

  const columns = React.useMemo(() => {
    const column = [
      {
        Header: '',
        id: 'expander',
        className: 'w-4 relative z-[101]',
        Cell: ({ row }) => {
          return (
            <span className="flex items-center" {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <ChevronDownIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              ) : (
                <ChevronRightIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              )}
            </span>
          );
        },
      },
      {
        Header: '#',
        accessor: 'itemNumber',
        className: 'w-[40px]',
        Cell: ({ value, row }) => {
          return (
            <span className="flex items-center font-bold">
              {row.original?.categoryNumber ?? ''}.{value}
            </span>
          );
        },
      },
      {
        Header: 'Squawks',
        accessor: 'title', // accessor is the "key" in the data
        className: 'w-72',
        Cell: ({ row, value }) => {
          let childrenNum = row.original.children.length;
          return (
            <button className="text-left font-semibold truncate -mx-4 px-4 -my-3 py-3 w-full" title={value}>
              <span className={row?.original?.status === 'ABORT' && 'line-through'}>{value}</span>
              {childrenNum > 0 && <span className="ml-1 text-xs text-slate-500">({childrenNum})</span>}
            </button>
          );
        },
      },
      {
        Header: 'Signoffs',
        id: 'signoffs',
        accessor: (items) => items.mroWorkOrderSignoffs,
        className: 'w-[80px] -mx-4',
        Cell: ({ row, value }) => {
          return (
            <div className="ml-1">
              <span className="font-semibold">{value.filter((item) => item.status === 'SIGNED').length}</span>
              <span>/{value.length}</span>
            </div>
          );
        },
      },
      {
        Header: 'Parts',
        accessor: (items) => items.mroWorkOrderParts,
        // accessor is the "key" in the data
        className: 'w-[60px]',
        Cell: ({ row, value }) => {
          return (
            <div className="relative">
              {value.filter((item) => item.status === 'READY_FOR_PICKUP').length > 0 && (
                <span className="absolute flex h-3 w-3 right-[-10px] top-[-5px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-brand"></span>
                </span>
              )}
              <span className="font-semibold">{value.filter((item) => item.status === 'INSTALLED').length}</span>
              <span>/{value.filter((item) => item.status !== 'VOID').length}</span>
            </div>
          );
        },
      },
      {
        Header: 'Hours',
        accessor: 'totalLaborHours',
        className: 'w-[80px]',
        Cell: ({ row, value }) => {
          return (
            <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">
              {formatToFixed(value)}
              <span className="text-xs text-slate-500 ml-0.5 font-medium">/{row.original.estimatedHours}</span>
            </button>
          );
        },
      },
      {
        Header: '',
        accessor: 'status',
        id: 'select',
        className: 'w-30',
        Cell: ({ row, value }) => {
          return (
            <div className="w-full text-left -mx-4 px-4 -my-2 py-2 font-semibold">
              {selectedTask?.id !== row.original.id && (
                <button
                  onClick={() => {
                    handleTaskSelect(row.original) && closeFlyout();
                  }}
                  className="bg-brand-pale px-[15px] rounded-lg">
                  Select
                </button>
              )}
            </div>
          );
        },
      },
    ];
    return column;
  }, [closeFlyout, setSelectedTask]);
  return (
    <>
      <Flyout {...flyoutProps} />
      <FlyoutMini {...flyoutMiniProps} />
      {/* WORK ORDER INFO */}
      <div className="flex p-4">
        <div className="flex flex-col w-1/2 border rounded bg-slate-50 p-2">
          <UserCard
            onClick={() => {}}
            firstName={mroWorkOrder?.primaryContact?.firstName}
            lastName={mroWorkOrder?.primaryContact?.lastName}
            name={mroWorkOrder?.mroCustomer?.name}
            email={mroWorkOrder?.primaryContact?.email}
            phoneNumber={mroWorkOrder?.primaryContact?.phoneNumbers?.primary}
            updateFlyoutMini={updateFlyoutMini}
            customerId={mroWorkOrder?.mroCustomer.id}
            mroWorkOrderId={mroWorkOrder?.id}
          />
          <div className="flex justify-between mb-2">
            <label className="w-48 font-semibold text-sm text-slate-500">Arrival Date</label>
            <span className="text-sm font-semibold text-brand-dark">{formatApiDate(mroWorkOrder?.arrivalDate)}</span>
          </div>
          <div className="flex justify-between">
            <label className="w-48 font-semibold text-sm text-slate-500">Promise Date</label>
            <span className="text-sm font-semibold text-brand-dark">{formatApiDate(mroWorkOrder?.promiseDate)}</span>
          </div>
        </div>
        <div className="p-4 w-1/2">
          <div className="flex flex-col">
            <span className="font-semibold text-xs uppercase text-slate-500">Primary Concern</span>
            <span className="text-lg font-bold text-brand-dark leading-5">{mroWorkOrder?.primaryConcern}</span>
          </div>
          <div className="flex flex-col mt-4">
            <span className="font-semibold text-xs text-slate-500 uppercase">Notes</span>
            <p className="font-medium text-brand-dark rounded text-sm">{mroWorkOrder?.notes}</p>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div className="flex justify-between items-center border-y border-slate-200 px-4 pt-1 bg-slate-50">
        <div className="flex items-start">
          <button
            className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('IN_PROGRESS')}
            disabled={activeStatus === 'IN_PROGRESS'}>
            In Progress
          </button>
          <button
            className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('COMPLETED')}
            disabled={activeStatus === 'COMPLETED'}>
            Completed
          </button>
          <button
            className="text-sm p-2 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
            onClick={() => setActiveStatus('ALL')}
            disabled={activeStatus === 'ALL'}>
            All
          </button>
        </div>
      </div>
      <div className="bg-slate-100 shadow-inner rounded-b">
        <div className="flex flex-col p-4">
          {mroWorkOrder?.itemCategories?.map((category) => (
            <Category
              key={category.id}
              category={category}
              updateFlyout={updateFlyout}
              closeFlyout={closeFlyout}
              tableData={tableData}
              context={'flyout'}
              mroWorkOrder={mroWorkOrder}
              columns={columns}
              updateWorkOrderItem={updateWorkOrderItem}
            />
          ))}
        </div>
        <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full"></div>
      </div>
    </>
  );
}

import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import CustomerChat from 'views/Part145/CustomerChat/CustomerChat';

function UserCard({
  firstName,
  lastName,
  email,
  name,
  phoneNumber,
  updateFlyoutMini,
  onClick,
  customerId,
  mroWorkOrderId,
}: {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  name: string;
  customerId: string;
  mroWorkOrderId?: string;
  updateFlyoutMini?;
  onClick?;
}) {
  return (
    <div className="flex items-center mb-3 pb-3 border-b">
      <button
        onClick={onClick ?? undefined}
        className={`${
          onClick ? '' : 'pointer-events-none'
        } flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale border-brand-electric w-8 h-8 rounded mr-2`}>
        {firstName?.[0]}
      </button>
      <div className="flex flex-col">
        <span className="text-lg font-bold text-brand-dark">
          {firstName} {lastName}
        </span>
        <span className="text-sm -mt-1">{email}</span>
        <span className="text-sm">{phoneNumber}</span>
      </div>
      <div className="flex grow shrink justify-end">
        <div
          onClick={() => updateFlyoutMini({ title: name, content: <CustomerChat phoneNumber={phoneNumber} customerId={customerId} mroWorkOrderId={mroWorkOrderId} /> })}
          className="flex font-bold items-center text-brand-electric bg-brand-pale py-2 px-4 rounded text-sm cursor-pointer hover:opacity-70 transition-all">
          <ChatBubbleLeftRightIcon className="h-4 w-4 mr-1" /> Chat
        </div>
      </div>
    </div>
  );
}

export default UserCard;

import { AbilityTuple, MongoAbility, MongoQuery } from "@casl/ability"
import { AbilityContext } from "contexts/AbilityContext";
import { useContext } from "react";

export const permissionHandler = (permission: string, ability: MongoAbility<AbilityTuple, MongoQuery>, orgType? :String) => {
    let userRolesRequired: string[] = [];
    
    switch (permission) {
        case 'Squawks':
            userRolesRequired = ["Admin", 'Owner', "A & P", 'Pilot', "Student", "Sales Broker"];
            break;
        case 'Logbooks':
            userRolesRequired = ["Admin", 'Owner', "A & P", "Pilot", "Student", "Sales Broker"];
            break;
        case 'Documents':
            userRolesRequired = ["Admin", 'Owner', "A & P", "Pilot", "Student", "Sales Broker"];
            break;
        case 'Requests':
            userRolesRequired = ['Admin'];
            break;
        case 'Flights':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Reservations':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Reports':
            userRolesRequired = ["Admin", 'Owner', "A & P", "Student"];
            break;
        case 'Payments':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Announcements':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Create Announcement':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        case 'Payment Admin':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        case 'Add Users':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        default:
            break;
    }

    let can = false;
    for (const subject of userRolesRequired) {
        if(ability.can('PART91', subject)) {
            can = true;
            break;
        }
    }
    

    if(orgType?.includes("SOLO")){
        switch (permission) {
            case 'Reservations':
                can = false;
                break;
            case 'Reports':
                can = false;
                break;
            case 'Payments':
                can = false;
                break;
            case 'Payments':
                can = false;
                break;
        }
    }
    return can;
}

export const ContatinsPermission = ({ permission, children }) => {
    const abilities = useContext(AbilityContext);
    let can = permissionHandler(permission, abilities);
    return (
        <>
            {can ? 
            <>
                {children} 
            </> : null}
        </>
    );
}
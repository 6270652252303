import { useQuery } from '@apollo/client';
import { useSession } from 'contexts';
import { MroCustomerLookupDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, CheckIcon, PlusIcon } from '@heroicons/react/24/outline';

const selectionClass =
  'flex items-center group grow bg-white text-brand-dark cursor-pointer p-2 mb-1 w-full relative rounded border border-dashed border-white border-b-brand-pale last:border-b-white transition-colors hover:border-brand-pale hover:bg-brand-pale/20 hover:border-solid disabled:border-solid disabled:text-brand-electric disabled:bg-brand-pale disabled:border-brand-electric';

export function CustomerSingleSelect({ selectedCustomer, setSelectedCustomer, tailNumber, initialCustomers }) {
  const [query, setQuery] = useState('');
  const { user } = useSession();

  const { data: { mroCustomers: filteredCustomers } = {}, refetch } = useQuery(MroCustomerLookupDocument, {
    variables: {
      where: { mroOrganizationId: { equals: user.mroOrganizationId }, mroCrafts: { some: { tailNumber: { equals: tailNumber } } } },
    },
  });

  useEffect(() => {
    if (query) {
      const timeOutId = setTimeout(
        () =>
          refetch({
            where: {
              OR: [
                {
                  name: {
                    search: query.trim().replaceAll(' ', '|'),
                  },
                },
                {
                  email: {
                    search: query.trim().replaceAll(' ', '|'),
                  },
                },
              ],
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    } else {
      refetch({
        where: { mroOrganizationId: { equals: user.mroOrganizationId }, mroCrafts: { some: { tailNumber: { equals: tailNumber } } } },
      });
    }
  }, [query, refetch, tailNumber, user.mroOrganizationId]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center relative">
          <MagnifyingGlassIcon className="h-4 w-4 text-brand-electric left-3 absolute" />
          <div className="flex flex-wrap w-full pl-9 rounded border border-slate-300 bg-slate-50 h-10">
            <input
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Type the name of a customer"
              className="w-full rounded bg-slate-50 grow text-brand font-bold focus:outline-0 placeholder:text-slate-400 placeholder:text-sm placeholder:font-normal"></input>
          </div>
        </div>
      </div>
      <div className="rounded h-64 bg-white overflow-y-auto shadow-inner p-2 border border-slate-300 mt-2">
        {filteredCustomers?.map((cust, index) => {
          return (
            <div
              key={cust.id}
              className="flex flex-row-reverse items-center rounded last:mb-0 relative group border-b border-brand-pale border-dashed last:border-b-0">
              <button
                type="button"
                onClick={(e) => {
                  setSelectedCustomer(cust);
                }}
                disabled={selectedCustomer?.id === cust.id}
                className={selectionClass}>
                <div className="flex">
                  <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale group-disabled:bg-brand-electric group-disabled:text-white border-brand-electric w-9 h-9 rounded mr-2">
                    {cust.name[0]}
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="font-bold truncate">{cust.name}</span>
                    <span className="truncate text-xs text-brand -mt-1 font-semibold opacity-70">
                      {initialCustomers.find((c) => c.id === cust.id) ? tailNumber : '(New Aircraft)'}
                    </span>
                  </div>
                </div>
                {selectedCustomer?.id === cust.id && (
                  <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 pointer-events-none" />
                )}
              </button>
            </div>
          );
        })}
        <button
          type="button"
          onClick={(e) => {
            setSelectedCustomer('newCust');
          }}
          disabled={selectedCustomer === 'newCust'}
          className={selectionClass + ' mt-1'}>
          <div className="flex">
            <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale group-disabled:bg-brand-electric group-disabled:text-white border-brand-electric w-9 h-9 rounded mr-2">
              <PlusIcon className="h-4 w-4" />
            </div>
            <div className="flex items-center">
              <span className="font-bold truncate">Add New Customer</span>
            </div>
          </div>
          {selectedCustomer === 'newCust' && (
            <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 pointer-events-none" />
          )}
        </button>
      </div>
    </>
  );
}

import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_1187)">
        <path
          d="M15.2 2H13.2V1C13.2 0.4 12.8 0 12.2 0H11.2C10.6 0 10.2 0.4 10.2 1V2H6.19995V1C6.19995 0.4 5.79995 0 5.19995 0H4.19995C3.59995 0 3.19995 0.4 3.19995 1V2H1.19995C0.599951 2 0.199951 2.4 0.199951 3V15C0.199951 15.6 0.599951 16 1.19995 16H15.2C15.8 16 16.2 15.6 16.2 15V3C16.2 2.4 15.8 2 15.2 2ZM14.2 14H2.19995V6H14.2V14Z"
          fill="currentColor"
        />
        <path d="M11.2 11H9.19995V13H7.19995V11H5.19995V9H7.19995V7H9.19995V9H11.2V11Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_436_1187">
          <rect width="16" height="16" fill="white" transform="translate(0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

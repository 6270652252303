import Button from 'components/Button/Button';
import VLogo from 'assets/temp/victory_lane.svg';
import Logo from 'assets/icons/logo_navbar_w.svg';
import { useQuery } from '@apollo/client';
import { GetMroInvoiceDocument, GetMroInvoiceWithTokenDocument, GetMroOrganizationInfoDocument } from 'graphql/generated';
import { Link, useParams } from 'react-router-dom';
import { ArrowDownTrayIcon, PrinterIcon } from '@heroicons/react/24/solid';
import printJS from 'print-js';
import InvoiceView from 'views/Part145/WorkOrders/Order/WorkOrderTabs/Invoices/partials/InvoiceView';


const ViewInvoice = (props) => {
  const { invoiceId, token } = useParams();
  const { data: { mroInvoice } = {} } = useQuery(GetMroInvoiceDocument, { skip: !!token, variables: { mroInvoiceId: decodeURI(invoiceId) } });
  const { data: { mroInvoiceWithToken } = {} } = useQuery(GetMroInvoiceWithTokenDocument, { skip: !token, variables: { id: decodeURI(invoiceId), token } });
  const { data: { mroOrganizationInfo } = {} } = useQuery(GetMroOrganizationInfoDocument, { variables: { id: mroInvoice?.mroOrganizationId } } );
  const invoice = mroInvoice || mroInvoiceWithToken; 
  return (
    <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center bg-white md:bg-white/0 md:max-w-6xl w-full md:min-h-[768px] md:shadow md:mx-6">
      <div className="w-1/3 hidden md:flex flex-col bg-brand-dark relative rounded-l">
        <div className="flex justify-center items-center p-8">
          {mroOrganizationInfo?.icon && <img className="max-h-[4rem] pr-8" src={mroOrganizationInfo?.icon} alt="" /> }
          <img className="max-h-[2.5rem] w-1/2 border-l border-white pl-8" src={Logo} alt="" />
        </div>
        <div className="flex flex-col">
          <h1
            className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 py-4 -ml-2 bg-brand relative border-b-2 border-[#162551]"
            id="ribbon">
            {mroOrganizationInfo?.name}
          </h1>
          <p className="text-brand-pale font-medium my-8 px-8">
            Our customer portal allows you to manage the status of your aircraft and maintenance.
          </p>
          <span className="text-brand-pale font-medium px-8">
              Need additional help? <Link to='https://coflyt.com/contact-us' className="font-bold text-white underline">Contact Us.</Link>
          </span>
        </div>
        <span className="text-brand-pale absolute bottom-8 left-0 w-full text-center text-xs">
          © {new Date().getFullYear()} Coflyt. All rights reserved.
        </span>
      </div>
      <div className="flex md:max-h-[768px] overflow-y-auto justify-center md:w-2/3 rounded pt-8 pb-24 md:p-16 md:pb-16 md:pt-16 bg-white rounded-r mb-8 md:mb-0">
        {mroInvoice?.status !== 'VOID' ? (
          <div className="flex flex-col w-100 px-6 my-auto">
            <InvoiceView
              invoice={{ ...invoice, ...invoice?.priceSummary }}
              charges={invoice?.charges}
              discounts={invoice?.discounts}
              invoiceItems={invoice?.items}
              edit={false}
            />
            {/* BUTTONS */}
            <div className="flex self-end text-sm p-4 gap-5">
              <a href={invoice?.pdfLink} target="_blank" rel="noreferrer">
                <Button
                  color="pale"
                  icon={<ArrowDownTrayIcon className="h-4 w-4" />}
                  className="gap-2 hover:opacity-40 justify-center items-center"
                  size="sm"
                  text="Download"
                />
              </a>
              <Button
                color="navy"
                onClick={() => printJS(invoice?.pdfLink)}
                icon={<PrinterIcon className="h-4 w-4" />}
                className="gap-2 hover:opacity-40 justify-center items-center"
                size="sm"
                text="Print"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-100 px-6 my-auto">
            <h1>Invoice No Longer Available</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewInvoice;

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Combobox } from '@headlessui/react';
import { GetJascCategoriesDocument, GetJascCategoriesQuery } from 'graphql/generated';
import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type JascSystem = GetJascCategoriesQuery['jascCategories'][number]['jascSystems'][number];

export default function JascSelect(props) {
  const [query, setQuery] = useState('');
  const [queryInput, setQueryInput] = useState('');
  const { data: { jascCategories } = { jascCategories: [] } } = useQuery(GetJascCategoriesDocument);
  useEffect(() => {
    const timeOutId = setTimeout(() => setQuery(queryInput), 50);
    return () => clearTimeout(timeOutId);
  }, [queryInput]);

  const filteredCategories = useMemo(() => {
    const filtered: typeof jascCategories = [];
    if (!query) return jascCategories;
    jascCategories.forEach((category) => {
      const filteredSystems = category.jascSystems.filter((system) =>
        `${system.code} - ${system.name}`.toLowerCase().includes(query.toLowerCase())
      );
      if (filteredSystems.length) {
        filtered.push({
          jascSystems: filteredSystems,
          ...category,
        });
      }
    });
    return filtered;
  }, [query, jascCategories]);
  return (
    <Combobox as="div" multiple {...props}>
      <div className="relative w-full">
        <Combobox.Input
          className="text-sm w-full rounded-md border border-slate-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQueryInput(event.target.value)}
          displayValue={(systems : any) =>
            systems?.length ? systems?.map((system) =>{
              const systemSplit = system.split('/');
              const [systemId, systemName, systemCode ] = [systemSplit[0], systemSplit[1], systemSplit[2]];
              return (`${systemCode} - ${systemName}`);
            }).join(', ') : ''
          }
          placeholder="Select JASC System"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredCategories.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-[25rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCategories.map((category) => (
              <React.Fragment key={category.id}>
                <span className="pl-2 text-sm font-medium">
                  {category.code} - {category.name}
                </span>
                {category.jascSystems.map((system) => {
                  return(
                  <Combobox.Option
                    key={system.id}
                    value={system.id+'/'+system.name+'/'+system.code}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-4 pr-9 text-xs',
                        active ? 'bg-brand text-white' : 'text-slate-900'
                      )
                    }>
                    {({ active, selected }) => (
                      <>
                        <span className={classNames('block truncate', selected && 'font-semibold')}>
                          {system.code} - {system.name}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ) })}
              </React.Fragment>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

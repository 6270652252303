import { useContext, useState } from 'react';
import UserIcon from 'components/UserIcon/UserIcon';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { EmployeeSingleSelect } from '../EmployeeSelect';
import Button from 'components/Button/Button';
import { Formik, Form } from 'formik';
import { formatApiDateTime } from 'utils/formatter';
import {
  SignoffStatus, 
  GetMroWorkOrderItemDocument,
  MroWorkOrderSignoffUpdateInput,
  GetMroEmployeeDocument,
  GetMroWorkOrderItemQuery,
  UpdateMroWorkOrderItemDocument,
  UpdateMroWorkOrderSignoffStatusDocument,
} from 'graphql/generated';
import { useMutation, useQuery } from '@apollo/client';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import Modal from 'components/Modal/Modal';
import { AbilityContext } from 'contexts/AbilityContext';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const statuses = {
  UNASSIGNED: { class: 'bg-white border-brand-pale cursor-pointer hover:shadow-inner', text: 'Request Review' },
  AWAITING_SIGNOFF: { class: 'bg-brand-pale border-brand-pale text-brand-electric', text: 'Awaiting Signoff' },
  SIGNED: {
    class: 'bg-brand-electric border-brand-electric text-white',
    text: (
      <>
        <CheckCircleIcon className="h-5 w-5" />
        <span>Signed</span>
      </>
    ),
  },
};

export default function Signoff({
  title,
  status,
  date,
  assignee,
  locked,
  id,
  allSignoffs,
  mroWorkOrderItemId,
}: {
  title: string;
  status: SignoffStatus;
  date: Date;
  assignee: any;
  locked: boolean;
  id: string;
  mroWorkOrderItemId: string;
  allSignoffs: GetMroWorkOrderItemQuery['mroWorkOrderItem']['mroWorkOrderSignoffs'];
}) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [error, setError] = useState(false);
  const [updateWorkOrderSignOffStatus] = useMutation(UpdateMroWorkOrderSignoffStatusDocument, { refetchQueries: [GetMroWorkOrderItemDocument] });
  const { user } = useSession();
  const ability = useContext(AbilityContext);
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
  });

  const updateSignOff = (statusUpdate: string, employeeId?:string) => {
    updateWorkOrderSignOffStatus({
      variables: {
          id,
          status: statusUpdate,
          mroEmployeeProfileId: employeeId,
      },
    });
  };

  return (
    <>
      {modal && (
        <Modal
          cancel={setModal}
          accept={() =>
            updateSignOff(SignoffStatus.Signed, mroEmployeeProfile.id)
          }
          message={'The Signoff is not assigned to you, would you like to assign to yourself and sign?'}
        />
      )}
      <div className="last:border-b-0 last:pb-0 border-b border-brand-pale border-dashed pb-4 relative w-full">
        <div
          onClick={() => !locked && ability.can('Manage', 'WO Signoffs') && setSelectorOpen(!selectorOpen)}
          className={`flex group items-center rounded p-2 gap-8 ${
            ability.can('Manage', 'WO Signoffs') && ! locked ? 'cursor-pointer hover:bg-brand-offwhite' : 'bg-slate-100 cursor-not-allowed'
          } transition select-none`}>
          <div className="flex items-center gap-3 w-full">
            {assignee ? (
              <UserIcon
                iconSize="lg"
                iconText={assignee.firstName + ' ' + assignee.lastName}
                iconId={assignee.id}
                iconColor=""
                className=""
              />
            ) : (
              <PlusIcon className={`h-10 w-10 p-2 rounded-full bg-brand-pale text-brand-electric border border-brand-electric border-dashed 
                ${ ability.can('Manage', 'WO Signoffs') && !locked &&  `group-hover:bg-brand-electric group-hover:text-white transition`} `}/>
            )}
            <div>
              <h1 className="font-bold">{title}</h1>
              <h2 className="flex items-center gap-1 text-sm -mt-1 text-slate-500">
                <PencilSquareIcon className="h-3 w-3" />
                {assignee ? assignee.firstName + ' ' + assignee.lastName : 'Unassigned'}
              </h2>
            </div>
          </div>
          <span className="font-semibold text-sm whitespace-nowrap">{formatApiDateTime(date) || '--/--/----'}</span>
          <button
            className={`flex items-center justify-center w-1/4 gap-1 rounded border text-sm font-bold py-1.5 shrink-0 transition ${statuses[status].class}`}>
            {statuses[status].text}
          </button>
        </div>
        { selectorOpen && (
          <div className="w-full p-2">
            {status === 'UNASSIGNED' && (
              <>
                {/* TODO: Either add roles for employee profiles or make titles an enum */}
                <EmployeeSingleSelect selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} titles={['Manager']} />
                <div className="flex justify-end w-full mt-2">
                  <Button
                    color="navy"
                    size="xs"
                    text="Save"
                    onClick={() => {
                      setSelectorOpen(false);
                      updateSignOff(SignoffStatus.AwaitingSignoff, selectedProfile.id);
                    }}
                  />
                </div>
              </>
            )}
            {status === 'AWAITING_SIGNOFF' && (
              <>
                <Formik
                  initialValues={{ pin: '' }}
                  onSubmit={(values) => {
                    if (assignee?.pin !== mroEmployeeProfile.pin && mroEmployeeProfile.pin === values.pin) return setModal(true);
                    if (assignee?.pin !== values.pin) return setError(true);
                    updateSignOff(SignoffStatus.Signed);
                    setSelectorOpen(false);
                  }}>
                  <Form className="flex flex-col gap-4">
                    <div className="flex flex-col border border-slate-200 rounded relative bg-slate-50">
                      <div className="bg-white p-2 rounded-t border-b border-slate-200 text-sm font-semibold">Pin Required</div>
                      <FullFormikInput name="pin" label="Pin" onClick={() => setError(false)} className="px-2 mt-2" />
                      {error && <h2 className="p-1 text-sm text-red-500">Incorrect Pin</h2>}
                    </div>
                    <div className="flex justify-end px-2 gap-2">
                      <Button
                        size="xs"
                        type="button"
                        onClick={() => {
                          updateSignOff(SignoffStatus.Unassigned, "disconnect");
                          setSelectorOpen(false);
                        }}
                        color="offwhite"
                        text="Remove Assigned"
                      />
                      <Button size="xs" type="submit" color="navy" text="Submit" />
                    </div>
                  </Form>
                </Formik>
              </>
            )}
            {status === 'SIGNED' && (
              <>
                <div className="flex items-center justify-between gap-8 w-full border border-brand-electric rounded shadow-blue p-3">
                  <div className="flex items-center gap-2 px-3">
                    <CheckCircleIcon className="h-8 w-8 text-brand-electric" />
                    <span className="font-bold -ml-1 pl-1">{assignee?.firstName + ' ' + assignee?.lastName}</span>
                    <h1 className=""> Signed</h1>
                  </div>
                </div>
                <div className="flex justify-end px-2 gap-2 mt-3">
                  <Button
                    size="xs"
                    onClick={() => {
                      updateSignOff(SignoffStatus.Unassigned, "disconnect");
                      setSelectorOpen(false);
                    }}
                    color="offwhite"
                    text="Remove Signature"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

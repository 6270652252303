import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext } from 'react';
import Button from 'components/Button/Button';
import { ServiceFormContext } from '..';

export const contactValues = { name: '', companyName: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '' };

const ContactInfo = (props) => {
  const { prevStep, formStep, changeStep, setCustomerInput, user, tailInfo } = useContext(ServiceFormContext);

  //Prefill Contact Info
  contactValues.email = user?.email;

  contactValues.companyName = tailInfo?.name;
  contactValues.address1 = tailInfo?.street;
  contactValues.address2 = tailInfo?.street2;
  contactValues.city = tailInfo?.city;
  contactValues.state = tailInfo?.state;
  contactValues.zip = tailInfo?.zipCode;

  return (
    <Formik
      initialValues={contactValues}
      onSubmit={(values) => {
        setCustomerInput(values);
        changeStep(formStep, 'issues');
      }}>
      <Form className="w-96 p-6 mt-12 md:mt-0">
        <h2 className="text-3xl md:text-2xl font-bold text-brand mb-4">Billing</h2>
        <label className="text-sm font-medium text-brand">Personal Information</label>
        <div className="flex flex-col">
          <Field placeholder="Full Name" className={props.fieldCss} name="name" required></Field>
          <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic" />
        </div>
        <div className="flex flex-col">
          <Field placeholder="Company Name" className={props.fieldCss} name="companyName"></Field>
          <ErrorMessage name="companyName" component="div" className="text-red-500 text-xs italic" />
        </div>
        <label className="text-sm font-medium text-brand">Address</label>
        <div className="flex flex-col">
          <Field placeholder="Address" className={props.fieldCss} name="address1" required></Field>
          <ErrorMessage name="address1" component="div" className="text-red-500 text-xs italic" />
        </div>
        <div className="flex flex-col">
          <Field placeholder="Address Line 2" className={props.fieldCss} name="address2"></Field>
          <ErrorMessage name="address2" component="div" className="text-red-500 text-xs italic" />
        </div>
        <div className="flex">
          <div className="flex w-3/6 flex-col">
            <Field placeholder="City" className={props.fieldCss} name="city" required></Field>
            <ErrorMessage name="city" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="w-1/6 px-1">
            <div className="flex flex-col">
              <Field placeholder="State" maxLength="2" className={props.fieldCss} name="state" required></Field>
              <ErrorMessage name="state" component="div" className="text-red-500 text-xs italic" />
            </div>
          </div>
          <div className="flex w-2/6 flex-col">
            <Field placeholder="Postal Code" className={props.fieldCss} name="zip" required></Field>
            <ErrorMessage name="zip" component="div" className="text-red-500 text-xs italic" />
          </div>
        </div>
        <label className="text-sm font-medium text-brand">Contact Information</label>
        <div className="flex flex-col">
          <Field placeholder="Email" className={props.fieldCss} name="email" type="email" required></Field>
          <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
        </div>
        <div className="flex flex-col">
          <Field placeholder="Phone #" className={props.fieldCss} name="phone" type="tel" required></Field>
          <ErrorMessage name="phone" component="div" className="text-red-500 text-xs italic" />
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            className="underline text-brand font-semibold text-sm"
            onClick={() => changeStep(formStep, prevStep[prevStep.length - 1], true)}>
            Go Back
          </button>
          <Button text="Next" color="navy" size="sm" type="submit" />
        </div>
      </Form>
    </Formik>
  );
};

export default ContactInfo;

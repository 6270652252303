import { XMarkIcon } from '@heroicons/react/24/outline';

// Expected Data Structure
/* 
props: {
  isOpen: bool,                                                                         // open/closed state
  data: {
    title: string,                                                                      // name of window
    content: JSX                                                                        // component from components/Flyout/Content
  },
}
*/

function FlyoutMini(props) {
  return (
    <div
      className={`${
        props.isOpen ? '' : 'translate-y-full'
      } w-full md:w-[55%] hover-shadow md:max-w-lg border shadow-xl z-50 transition-all ease-in-out fixed -mt-4 md:mt-0 bottom-0 right-0 md:mx-5 h-[44rem] bg-[#FAFAFD]`}>
      <div className="w-full p-4 bg-white text-brand flex items-center justify-between border-b rounded-t">
        <span className="text-lg font-bold">{props.data.title}</span>
        <XMarkIcon className="h-6 w-6 cursor-pointer relative z-50" onClick={() => props.setIsOpen(false)} />
      </div>
      {props.data.content}
    </div>
  );
}

export default FlyoutMini;

import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftDocument,
  GetComponentsDocument,
  AnnouncementCreateInput,
  CreateAnnouncementDocument,
  GetAnnouncementsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import JascSelect, { JascSystem } from 'components/JascCategories/JascSelect';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import FilePicker from 'components/FilePicker/FilePicker';
import { FullFormikCheckBox, FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import ReactQuill from 'react-quill';

const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

const initialValues = {
  title: '',
  content: '',
  active: true,
  pinned: false,
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const New = function ({ closeFlyout }: { closeFlyout: () => void}) {
  const { user, organizationId } = useSession();
  const [createAnnouncement] = useMutation(CreateAnnouncementDocument, {
    refetchQueries: [
      'GetAnnouncementsDocument'
    ],
  });
  const [prefill, setPrefill] = useState(initialValues);
  const [files, setFiles] = useState<File[]>([]);
  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const announcementsInput: AnnouncementCreateInput = {
      title: values.title,
      content: values.content,
      active: values.active,
      pinned: values.pinned,
      organization: { connect: { id: organizationId } },
      creator: { connect: { id: user.userOrganizationProfileId } },
    };
    await createAnnouncement({ variables: { input: announcementsInput } });
    setSubmitting(false);
    setPrefill({ ...initialValues });
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Card className='m-4'>
            <dl>
              <div className="p-4 flex items-center justify-center w-full">
                <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
              </div>
              <div className="p-4 flex items-center justify-around w-full">
                <FullFormikCheckBox name='active' label='Active' opposite={true} isVertical={true} className='' />
                <FullFormikCheckBox name='pinned' label='Pinned' opposite={true} isVertical={true} className='' />
              </div>
              <div className="pb-20 mt-2">
                <dt className="mb-2">Content</dt>
                <ReactQuill className='h-64 mb-5' theme="snow" value={values.content} onChange={(value) => setFieldValue('content', value)} />
              </div>
            </dl>
          </Card>
          <div className="px-4 mb-10 flex items-center justify-end">
            <Button
              type="submit"
              size='xs'
              disabled={isSubmitting}
              text='Create Announcement' />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default New;

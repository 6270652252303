import { useMutation, useQuery } from '@apollo/client';
import {
  GetWorkOrderBillingQuery,
  GetMroInvoiceDocument,
  UpdateMroInvoiceDocument,
  GetWorkOrderBillingDocument,
  GetMroWorkOrderQuery,
  SendInvoiceDocument,
  UpdateWoStatusDocument,
  GetMroWorkOrderDocument,
  UpdateInvoiceStatusDocument,
} from 'graphql/generated';
import { useState } from 'react';
import { ArrowDownTrayIcon, BanknotesIcon, PencilIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Modal from 'components/Modal/Modal';
import EditInvoiceFlyout from '../Edit';
import { Link } from 'react-router-dom';
import Invoice from '../partials/InvoiceView';
import printJS from 'print-js';
import SendEmailFlyout from '../../Estimates/partials/SendEmailFlyout';
export default function InvoiceFlyout({
  mroInvoiceId,
  workOrder
}: {
  mroInvoiceId: string;
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
}) {
  const [modal, setModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data: { mroInvoice } = {} } = useQuery(GetMroInvoiceDocument, { variables: { mroInvoiceId: mroInvoiceId } });
  const [updateInvoice] = useMutation(UpdateInvoiceStatusDocument, {
    refetchQueries: [GetWorkOrderBillingDocument, { query: GetMroInvoiceDocument, variables: { mroInvoiceId: mroInvoiceId } }, 
      { query: GetMroWorkOrderDocument, variables: { id: workOrder.id } }],
  });
  const [sendInvoice] = useMutation(SendInvoiceDocument, {
    refetchQueries: [{ variables: { mroInvoiceId: mroInvoiceId }, query: GetMroInvoiceDocument }],
  }); 
  return (
    <>
      <Flyout {...flyoutProps} />
      {modal && (
        <Modal
          cancel={setModal}
          accept={() => updateInvoice({ variables: { id: mroInvoiceId, status: 'VOID' } }) }
          message={'You are about to Void Invoice #' + mroInvoice?.invoiceNumber + ', this cannot be undone.'}
        />
      )}
      <div className="flex flex-col p-4 h-full mb-20">
        <div className="flex gap-1 justify-end mb-2">
          <button
            onClick={() => {
              printJS(mroInvoice?.pdfLink);
            }}>
            <PrinterIcon className="h-6 w-10 p-[0.1875rem] rounded bg-slate-200 text-slate-500" />
          </button>
          <a href={mroInvoice?.pdfLink} target="_blank" rel="noreferrer" download>
            <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
          </a>
        </div>
        <Invoice
          invoice={{ ...mroInvoice, ...mroInvoice?.priceSummary, ...mroInvoice?.laborSummary }}
          charges={mroInvoice?.charges}
          discounts={mroInvoice?.discounts}
          invoiceItems={mroInvoice?.items}
          status={mroInvoice?.status}
          edit={false}
        />

        {mroInvoice?.status !== 'VOID' && (
          <div className="flex self-end text-sm p-4 gap-5">
            {mroInvoice?.status !== 'PAID' && (
              <>
                <Link to={'/mro/service-request/invoice/' + mroInvoiceId} className="hover:text-brand text-xs text-blue-500 pr-1">
                  Preview
                </Link>
                <button
                  disabled={submitting}
                  onClick={async () => {
                    updateFlyout({
                      title: 'Send Invoice',
                      content: (
                        <SendEmailFlyout
                          closeFlyout={closeFlyout}
                          submit={async (emails) => {
                            setSubmitting(true);
                            await sendInvoice({ variables: { id: mroInvoiceId, emails } });
                            setSubmitting(false);     
                          }}
                          primaryContact={workOrder?.primaryContact}
                          allContacts={workOrder?.mroCustomer?.mroContacts ?? []}
                        />
                      ),
                    });
                  }} // TODO: replace with customer email
                  className="flex items-center font-semibold bg-brand-electric text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                  <>
                    {mroInvoice?.status === 'SENT' ? 'Resend Invoice' : 'Send Invoice'}
                  </>
                </button>
              </>
            )}
            <button
              onClick={() =>
                updateFlyout({
                  title: 'Edit Invoice',
                  content: <EditInvoiceFlyout invoice={mroInvoice} workOrder={workOrder} closeFlyout={closeFlyout} />,
                })
              }
              className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
              <>
                <PencilIcon className="h-2.5 w-2.5 mr-1" />
                Edit
              </>
            </button>
            {mroInvoice?.status !== 'PAID' && (
              <button
                onClick={() => { 
                  updateInvoice({ variables: { id: mroInvoiceId, status: 'PAID' } });
                 }}
                className="flex items-center font-semibold bg-red-500 text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                <>
                  <BanknotesIcon className="h-2.5 w-2.5 mr-1" />
                  Mark as Paid
                </>
              </button>
            )}
            <button
              onClick={() => setModal(true)}
              className="flex items-center font-semibold bg-brand-purple text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
              <>
                <TrashIcon className="h-2.5 w-2.5 mr-1" />
                Void
              </>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

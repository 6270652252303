import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroDashboardOrdersDocument, GetMroDashboardDocument, GetMroWorkOrderItemDocument, StopTimerDocument } from 'graphql/generated';
import { taskCase } from 'utils/statuses';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { BriefcaseIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import UserIcon from 'components/UserIcon/UserIcon';
import { useSession } from 'contexts';
import Flyout from 'components/Flyout/Flyout';
import { useFlyout } from 'components/Flyout/Flyout';
import { Switch } from '@headlessui/react';
import { Table, TaskTable } from 'components/Table/Table';
import { formatApiDate } from 'utils/formatter';
import StatusButton from 'components/StatusButton/StatusButton';
import WorkOrderItem from '../../WorkOrders/Order/WorkOrderItems/WorkOrderItem';
import WorkOrderFlyout from '../../WorkOrders/Order/Flyout';
import { Clock } from './Components/Clock';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Tabs from 'components/Tabs/Tabs';
import { Can } from 'contexts/AbilityContext';
import WorkOrderNumber from 'components/WorkOrderNumber/WorkOrderNumber';

const userTabs = [
  { title: 'All' },
  { title: 'In Progress', task: ['AWAITING_SIGNOFF', 'STALLED', 'IN_PROGRESS'], order: ['HOLD', 'AWAITING_PARTS', 'IN_PROGRESS'] },
  { title: 'Upcoming', task: ['NOT_STARTED', 'PREPARING'], order: ['DRAFT', 'SCHEDULED'] },
];

const TechnicianDashboard = function (props) {
  const [showAll, setShowAll] = useState(false);
  const [currentTab, setCurrentTab] = useState('orders');
  const [userTab, setUserTab] = useState('All');
  const { user, selectedTask, setSelectedTask, timerActive, setTimerActive } = useSession();
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
  const [orderQuery, setOrderQuery] = useState('');
  const [taskQuery, setTaskQuery] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [stopTask, setStopTask] = useState(null);
  const employeeId = user?.mroEmployeeProfileId;
  const { data: { mroEmployeeProfile, mroWorkOrderItems } = {} } = useQuery(GetMroDashboardDocument, {
    variables: { mroEmployeeId: employeeId },
  });
  const { data: { mroWorkOrders } = {} } = useQuery(GetMroDashboardOrdersDocument, {
    variables: { mroOrganizationId: user?.mroOrganizationId },
  });

  const [stopTimer] = useMutation(StopTimerDocument, {
    refetchQueries: [
      GetMroDashboardDocument,
      { query: GetMroWorkOrderItemDocument, variables: { id: mroEmployeeProfile?.currentLaborEntry?.mroWorkOrderItem?.id } },
    ],
  });

  const filterTaskData = React.useMemo(() => {
    return mroWorkOrderItems?.filter((item) => {
      if (
        !item.title?.toUpperCase().includes(taskQuery.toUpperCase()) &&
        !item.mroWorkOrder.mroCraft.tailNumber?.toUpperCase().includes(taskQuery.toUpperCase())
      )
        return false;
      if (userTab === 'All') return true;
      const status = item.status?.toUpperCase()?.replaceAll(' ', '_');
      if (userTab === 'In Progress' && userTabs[1].task.includes(status)) return true;
      if (userTab === 'Upcoming' && userTabs[2].task.includes(status)) return true;
      return false;
    });
  }, [mroWorkOrderItems, taskQuery, userTab]);

  const dueSoonTasks = React.useMemo(() => {
    return mroWorkOrderItems?.filter((item) => {
      return new Date(item.mroWorkOrder?.promiseDate).getTime() - new Date().getTime() < 60 * 60 * 24 * 1000 * 7;
    });
  }, [mroWorkOrderItems]);

  const filterOrderData = React.useMemo(() => {
    return (showAll ? mroWorkOrders ?? [] : mroEmployeeProfile?.mroWorkOrders ?? [])?.filter((item) => {
      if (
        !item.title?.toUpperCase().includes(orderQuery.toUpperCase()) &&
        !item.mroCraft.tailNumber?.toUpperCase().includes(orderQuery.toUpperCase())
      )
        return false;
      if (userTab === 'All') return true;
      const status = item.status?.toUpperCase()?.replaceAll(' ', '_');
      if (userTab === 'In Progress' && userTabs[1].order.includes(status)) return true;
      if (userTab === 'Upcoming' && userTabs[2].order.includes(status)) return true;
      return false;
    });
  }, [mroEmployeeProfile?.mroWorkOrders, mroWorkOrders, showAll, userTab, orderQuery]);

  const handleStopTimer = function () {
    stopTimer({
      variables: {
        laborEntryId: mroEmployeeProfile?.currentLaborEntry?.id,
      },
    }).then(() => {
      setSelectedTask(stopTask);
      setTimerActive(false);
    });
  };

  const handleClick = function (task) {
    if (timerActive) {
      setStopTask(task);
      setShowAlert(true);
      return false;
    }
    setSelectedTask(task);
    setTimerActive(false);
    return true;
  };

  useEffect(() => {
    if (mroEmployeeProfile?.currentLaborEntry && !selectedTask) {
      setSelectedTask(mroEmployeeProfile.currentLaborEntry);
      setTimerActive(true);
    }
  }, [mroEmployeeProfile, selectedTask, setSelectedTask, setTimerActive]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Craft',
        accessor: (order) => order.mroCraft, // accessor is the "key" in the data
        className: 'w-3/12 pl-8',
        Cell: ({ value, row }: any) => {
          return (
            <div className="flex items-center gap-2 max-w-[300px]">
              <BriefcaseIcon className="h-10 w-10 p-2 bg-brand-pale text-brand-electric rounded shrink-0" />
              <div className="flex flex-col">
                <span className="text-brand-dark font-bold text-base">{value.tailNumber}</span>
                <span className="font-medium uppercase text-slate-500 text-sm -mt-1">
                  {value.make} {value.model}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Order #',
        accessor: (obj) => {
          return {
            workOrderNum: obj.workOrderNum,
            mroWorkOrderCategory: obj.mroWorkOrderCategory,
          };
        },
        className: 'w-full max-w-[10rem]',
        Cell: ({ value }) => {
          const color = value.mroWorkOrderCategory?.color ? "text-[" + value.mroWorkOrderCategory?.color + "]" : 'text-slate-500';
          return (
            <div className='flex flex-col justify-center'>
              <WorkOrderNumber code={value.mroWorkOrderCategory?.code} workOrderNum={value.workOrderNum} />
              <span className={`text-xs p-0.5 ${ color } font-semibold text-center`}>{value.mroWorkOrderCategory?.name}</span>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'title',
        className: 'w-4/12',
      },
      {
        Header: 'Arrival',
        accessor: 'arrivalDate',
        className: 'border-l justify-center th-white w-[12.5%]',
        Cell: ({ value, row }: any) => {
          return <span className="font-semibold text-sm whitespace-nowrap">{formatApiDate(value)}</span>;
        },
      },
      {
        Header: 'Promise',
        accessor: 'promiseDate',
        className: 'border-r justify-center th-white w-[12.5%]',
        Cell: ({ value, row }: any) => {
          return <span className="font-semibold text-sm whitespace-nowrap">{formatApiDate(value)}</span>;
        },
      },
      {
        Header: 'Assignee(s)',
        accessor: 'assignees',
        className: 'w-2/12 justify-end pr-8',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <div className="flex -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                if (!item) return null;
                return (
                  <UserIcon
                    key={index}
                    iconSize="sm"
                    iconText={item.firstName + ' ' + item.lastName}
                    iconId={item.firstName + ' ' + item.lastName}
                    iconColor={item.profileColor}
                    className="border-2 border-slate-50"
                  />
                );
              })}
            </div>
          );
        },
      },
    ],
    []
  );
  const taskColumns = React.useMemo(
    () => [
      {
        Header: 'Squawks',
        accessor: 'title',
        className: 'w-4/12 xl:w-6/12',
        Cell: ({ value, row }: any) => {
          return (
            <div className="flex items-center gap-2 overflow-hidden">
              <WrenchScrewdriverIcon
                className={`h-9 w-9 p-2.5 ${taskCase[row.original.status]?.bgColor} ${
                  taskCase[row.original.status]?.textColor
                } rounded shrink-0`}
              />
              <span className="text-brand-dark font-bold truncate">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Assignee(s)',
        accessor: 'assignees',
        className: 'w-2/12 xl:w-1/12 justify-end',
        Cell: ({ value, row }: any) => {
          return (
            <div className="flex -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                if (!item) return null;
                return (
                  <UserIcon
                    key={index}
                    iconSize="sm"
                    iconText={item.firstName + ' ' + item.lastName}
                    iconId={item.firstName + ' ' + item.lastName}
                    iconColor={item.profileColor}
                    className="border-2 border-slate-50"
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Craft',
        accessor: (task) => task.mroWorkOrder?.mroCraft?.tailNumber || task.tailNumber,
        className: 'w-2/12 xl:w-1/12',
        Cell: ({ value, row }: any) => {
          return (
            <div className="flex items-center">
              <span className="font-bold text-brand text-sm bg-brand-offwhite px-2 h-full rounded border border-brand-pale">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Order #',
        accessor: (obj) => {
          return {
            workOrderNum: obj.mroWorkOrder.workOrderNum,
            mroWorkOrderCategory: obj.mroWorkOrder.mroWorkOrderCategory,
          };
        },
        className: 'w-full max-w-[10rem]',
        Cell: ({ value }) => {
          return (
            <WorkOrderNumber code={value.mroWorkOrderCategory?.code} workOrderNum={value.workOrderNum} />
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-2/12',
        Cell: ({ value, row }: any) => {
          return <StatusButton readOnly={true} context="task" text={value} className="px-2" onSelect={(e) => {}} />;
        },
      },
      {
        Header: '',
        id: 'timer',
        accessor: '',
        className: 'w-1/12',
        Cell: ({ value, row }: any) => {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleClick(row.original);
              }}
              disabled={selectedTask?.id === row.original.id}
              className="bg-brand-pale px-[10px] disabled:invisible rounded-lg">
              Select
            </button>
          );
        },
      },
    ],
    [selectedTask, timerActive]
  );
  return (
    <>
      {showAlert && (
        <Modal
          message={'You already have a task in progress. Are you sure you want to switch?'}
          cancel={setShowAlert}
          accept={handleStopTimer}
        />
      )}
      <div className="flex flex-col items-center pb-30">
        <Flyout {...flyoutProps} />
        <div className="flex w-full gap-2">
          <div className="flex flex-col rounded-t items-stretch justify-between gap-2 w-full">
            <div className="flex gap-1 justify-between flex-wrap lg:flex-nowrap">
              <div
                className={`${
                  selectedTask ? 'rounded lg:grow-0' : 'rounded-t'
                } flex items-center gap-2 p-5 bg-white flex-wrap grow border border-slate-300 shadow-blue justify-center lg:justify-start`}>
                <UserIcon
                  iconSize="xl"
                  iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                  iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                  iconColor={mroEmployeeProfile?.profileColor}
                  className=""
                />
                <div className="flex flex-col">
                  <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">
                    {mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                  </h1>
                  <div className="-mt-1 text-sm text-slate-500">
                    <span className="text-brand-electric font-medium mr-2 pr-2">{mroEmployeeProfile?.title}</span>
                  </div>
                </div>
                <div className="flex flex-col w-28 h-fit ml-8 bg-brand-pale py-1 px-2 rounded -my-2">
                  <div className="text-brand font-bold">{mroWorkOrderItems?.length ?? 0} Tasks</div>
                  <div className="text-brand-electric font-bold">{dueSoonTasks?.length ?? 0} Due Soon</div>
                </div>
              </div>
              {selectedTask && (
                <div className="flex-1 grow">
                  <Clock
                    context="dashboard"
                    updateFlyout={updateFlyout}
                    closeFlyout={closeFlyout}
                    setDashboardTimerActive={(status) => {
                      setTimerActive(status);
                    }}
                  />
                </div>
              )}
            </div>

            {/* <Timer context="dashboard" updateFlyout={updateFlyout} flyoutProps={flyoutProps} closeFlyout={closeFlyout} />
            <hr className="border-dashed border-brand-pale mt-2 pt-2 -mx-4" /> */}
            {/* BODY */}
            <div className="flex items-start rounded gap-2 pb-24">
              <div className="flex flex-col w-full relative z-20">
                {/* TABS */}
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-start -mb-[1px] relative z-20">
                    <button
                      className={`${
                        currentTab !== 'orders' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
                      } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
                      disabled={currentTab === 'orders'}
                      onClick={() => setCurrentTab('orders')}>
                      Work Orders {mroEmployeeProfile?.mroWorkOrders && '(' + mroEmployeeProfile?.mroWorkOrders?.length + ')'}
                    </button>
                    <button
                      className={`${
                        currentTab !== 'tasks' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
                      } text-sm p-2 md:px-6 font-semibold rounded-t border border-slate-300`}
                      disabled={currentTab === 'tasks'}
                      onClick={() => setCurrentTab('tasks')}>
                      Squawks {mroWorkOrderItems && '(' + mroWorkOrderItems?.length + ')'}
                    </button>
                  </div>
                  <div className={`${currentTab === 'tasks' && 'opacity-30 pointer-events-none'} flex items-center gap-1 transition px-2`}>
                    <span className="text-sm text-slate-500 font-medium">Show Unowned</span>
                    <Switch
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                      className={`${showAll ? 'bg-brand-electric' : 'bg-slate-300'}
                        relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
                      <span
                        aria-hidden="true"
                        className={`${showAll ? 'translate-x-6' : 'translate-x-0'}
                          pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>
                </div>
                <div className="border border-slate-300 bg-white rounded-b rounded-r shadow-blue">
                  {/* WORK ORDER PANE */}
                  {currentTab === 'orders' && (
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between py-4 px-6">
                        <h1 className="text-brand-dark text-left font-bold text-lg">{showAll ? 'All Work Orders' : 'Your Work Orders'}</h1>
                        <div className="flex items-center gap-2">
                          <div className="relative flex items-center">
                            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                            <input
                              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                              placeholder="Search"
                              value={orderQuery}
                              onChange={(e) => {
                                setOrderQuery(e.target.value);
                              }}
                            />
                          </div>
                          {/* <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" /> */}
                        </div>
                      </div>
                      <div className="flex items-start px-6 bg-slate-50 border-y border-slate-200">
                        <Tabs items={userTabs.map( item => item.title )} activeItem={userTab} setActiveItem={setUserTab} />
                      </div>
                      <div className="flex flex-wrap pt-1 pb-2 overflow-x-auto w-full">
                        <Table
                          columns={columns}
                          data={filterOrderData}
                          className="sm-head w-full"
                          context="dashboard"
                          handleClick={handleClick}
                          taskColumns={taskColumns}
                          onRowClick={(row) => {
                            updateFlyout({
                              title: row.original.title,
                              content: (
                                <WorkOrderFlyout
                                  workOrderSelected={() => setSelectedTask(row.original)}
                                  handleTaskSelect={handleClick}
                                  closeFlyout={closeFlyout}
                                  orderId={row.original.id}
                                />
                              ),
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* TASK PANE */}
                  {currentTab === 'tasks' && (
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between p-4 px-6">
                        <h1 className="text-brand-dark text-left font-bold text-lg">Your Squawks</h1>
                        <div className="flex items-center gap-2">
                          <div className="relative flex items-center">
                            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                            <input
                              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                              placeholder="Search"
                              value={taskQuery}
                              onChange={(e) => {
                                setTaskQuery(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start px-6 bg-slate-50 border-y border-slate-200">
                        {userTabs.map((tab, ndx) => (
                          <button
                            className="text-sm p-2 pb-1 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
                            key={ndx}
                            onClick={() => setUserTab(tab.title)}
                            disabled={userTab === tab.title}>
                            {tab.title}
                          </button>
                        ))}
                      </div>
                      <div className="flex flex-col py-3 px-4 overflow-x-auto w-full">
                        <TaskTable
                          columns={taskColumns}
                          data={filterTaskData}
                          className="sm-head w-full"
                          context="dashboard"
                          onRowClick={(row) => {
                            updateFlyout({
                              title: (
                                <div className="flex gap-4 w-full">
                                  <div>{row.original.title}</div>
                                  {row.original.id !== selectedTask?.id && (
                                    <Button
                                      text="Select"
                                      color="navy"
                                      size="xs"
                                      onClick={() => {
                                        closeFlyout();
                                        handleClick(row.original);
                                      }}
                                    />
                                  )}
                                </div>
                              ),
                              content: <WorkOrderItem context="dashboard" closeFlyout={closeFlyout} itemId={row.original.id} />,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicianDashboard;

import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import { FullFormikSelect } from "components/Form/StandardForm";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from '@apollo/client';
import { ChargebeePortalDocument, GetOrganizationSettingsQuery, GetPlansDocument, StripeSubscriptionDocument, StripeSubscriptionQuery, UpdateCraftAmountDocument, GetUserCouponDocument } from 'graphql/generated';
import Card from 'components/Card/Card';
import SegmentControl from 'components/SegmentControl/SegmentControl';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Button from 'components/Button/Button';
import { formatUSD } from 'utils/formatter';
import PlanPricing from './Components/PlanPricing';
import LegacyPlanPricing, { CBInfo } from './Components/LegacyPlanPricing';
import ChargeebeeManagmentModal from './Components/ChargeebeeManagmentModal';
import ChargeebeePaymentModal from './Components/ChargeebeePaymentModal';
import isMobileApp from 'utils/isMobileApp';
import {Elements} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function BillingSettings({ organization, organizationRefetch } : { organization: GetOrganizationSettingsQuery['organization'], organizationRefetch: () => Promise<any> }) {
    const { organizationId } = useSession(); 
    // const { data: { stripePlans } = {} } = useQuery(GetPlansDocument);
    //const { data : { stripeSubscription } = {}, refetch } = useQuery(StripeSubscriptionDocument, { variables: { id: organization?.stripeSubscriptionId }, pollInterval: 200000 });
    const { data: { chargebeePortal } = {}, refetch : reloadPortal } = useQuery( ChargebeePortalDocument, { variables: { organizationId }, pollInterval: 0 });
    const { data: { me: { coupon } } = { me: {  } } } = useQuery(GetUserCouponDocument);
    const [ updateCraftAmount ] = useMutation(UpdateCraftAmountDocument);
    const [ crafts, setCrafts ] = useState(1);
    const [ showManagmentModal, setShowManagmentModal ] = useState(false);
    const [ showPaymentModal, setPaymentModal ] = useState(null);
    useEffect(() => {
        if(organization) {
            setCrafts(organization?.aircraftLimit);
        }
    }, [organization]);

    const cbInfo = organization?.cbInfo as CBInfo;
    const nextBillingDate = new Date(cbInfo?.cb_next_billing_at ?? new Date());
    const isActive = cbInfo?.cb_status === 'active' || cbInfo?.cb_status === 'in_trial';
    const { showToast, toastProps } = useToast();
    // const craftQuantity = Number(stripeSubscription?.craftSubscription?.quantity ?? 0);
    // const freePlanes = organization?.freeAircrafts;
    // const paidPlanes = organization?.aircraftLimit-organization?.freeAircrafts;
    // const totalCost = ((Number(stripeSubscription?.craftSubscription?.cost ?? 0)*.01) * craftQuantity) + (Number(stripeSubscription?.cost ?? 0)*.01);

    // const prorateCostCrafts = Number(stripeSubscription?.craftProrateCost ?? 0) * (crafts-(organization?.aircraftLimit ?? 0));
    // const newCostCrafts =  totalCost + Number(stripeSubscription?.craftCost ?? 0) * (crafts-(organization?.aircraftLimit ?? 0));
    const options = {
        mode: 'subscription',
        amount: (showPaymentModal?.price ?? 0)*100,
        currency: 'usd'
    } as StripeElementsOptions;
    return (
        <div className="relative">
            <Toast {...toastProps} />
            {showManagmentModal && 
                <ChargeebeeManagmentModal cancel={() => { 
                    setShowManagmentModal(false);
                    reloadPortal();
                }} url={chargebeePortal ?? ''} />      
            }
            {showPaymentModal &&
                <Elements stripe={stripePromise} options={options}>
                    <ChargeebeePaymentModal cancel={setPaymentModal} plan={showPaymentModal} isTrial={!cbInfo?.cb_trial_ends_at} />
                </Elements>
            }
            <Formik initialValues={ {} } enableReinitialize onSubmit={ async (values)=> { }} >
                {({ values, isSubmitting }) => (
                    <Form className="flex flex-col w-full gap-2 p-3 rounded-l">
                            { cbInfo?.cb_subscription_id && organization?.organizationType !== "FREE" ? 
                                <>
                                    <Card className='' titleSize='xl' title='Current Plan' subtitle='You may change it at any moment.'>
                                        <div className='flex justify-between flex-wrap gap-4 items-center mb-2'>
                                            <div className='text-center'>
                                                <h1 className="text-brand-dark font-semibold text-2xl">{cbInfo?.cb_plan_name}</h1>
                                                <h2 className="text-slate-500 font-semibold text-2xl">$ {(Number(cbInfo?.cb_next_billing_amount)*.01)}</h2>
                                                <h2 className="text-slate-500 font-semibold uppercase">/{cbInfo?.cb_plan_billing_period_unit}</h2>
                                            </div>
                                            <div>
                                                {isActive && <h3 className="text-slate-800">You will be billed ${(Number(cbInfo?.cb_next_billing_amount)*.01)} on {format(nextBillingDate, 'dd MMMM yyyy')}</h3>}
                                                {cbInfo?.cb_status && <h3 className="text-slate-800">You membership will be canceled on {format(nextBillingDate, 'dd MMMM yyyy')}</h3>}
                                            </div>
                                            <div>
                                                { isMobileApp() ? <span>You are unable to manage your subscription within the app. We know this is not ideal.</span> : 
                                                <button onClick={() => setShowManagmentModal(true)} className='bg-brand-dark text-white font-bold px-5 py-2 rounded uppercase'>Manage Subscription</button>}
                                            </div>
                                        </div>  
                                        {/* Stripe Billing */}
                                        {/* <div className='flex justify-between flex-wrap gap-4 items-center mb-2'>
                                            <div className='text-center'>
                                                <h1 className="text-brand-dark font-semibold text-2xl">{stripeSubscription?.planName}</h1>
                                                <h2 className="text-slate-500 font-semibold text-2xl">$ {(Number(stripeSubscription?.cost)*.01)}</h2>
                                                <h2 className="text-slate-500 font-semibold uppercase">/{stripeSubscription?.interval}</h2>
                                            </div>
                                            <div>
                                                {stripeSubscription?.billingDate && !stripeSubscription?.isPendingCancel && <h3 className="text-slate-800">You will be billed ${totalCost} on {format(nextBillingDate, 'dd MMMM yyyy')}</h3>}
                                                {stripeSubscription?.isPendingCancel && <h3 className="text-slate-800">You membership will be canceled on {format(nextBillingDate, 'dd MMMM yyyy')}</h3>}
                                            </div>
                                            <div>
                                                <a href={stripeSubscription?.customerPortalUrl} className='bg-brand-dark text-white font-bold px-5 py-2 rounded uppercase'>Manage Subscription</a>
                                            </div>
                                        </div>                                  */}
                                        {/* <div className='flex justify-between items-center border-t-2 pt-2 flex-wrap gap-4'>
                                            {stripeSubscription?.craftSubscription ? <div className='text-center'>
                                                <h1 className="text-brand-dark font-semibold text-2xl">{stripeSubscription?.craftSubscription?.planName}</h1>
                                                <h2 className="text-slate-500 font-semibold text-2xl">{formatUSD(craftQuantity*(Number(stripeSubscription?.craftSubscription?.cost)*.01))}</h2>
                                                <h2 className="text-slate-500 font-semibold text-lg">({craftQuantity} x {formatUSD(Number(stripeSubscription?.craftSubscription?.cost)*.01)})</h2>
                                                <h2 className="text-slate-500 font-semibold uppercase">/{stripeSubscription?.craftSubscription?.interval}</h2>
                                            </div> : <>&nbsp;</>}
                                            <div>
                                                <h3 className="text-slate-800">You have {freePlanes} Free Aircraft { paidPlanes ? 'and ' + paidPlanes + ' paid Aircraft' : '' }</h3>
                                            </div>
                                            <div className='flex gap-10'>
                                                <div className='text-center flex flex-col justify-center'>
                                                    <h1 className="text-brand-dark font-semibold text-2xl">Craft Limit</h1>
                                                    <div className='flex gap-4'>
                                                        <Button disabled={!(crafts > freePlanes)} text='-' color='navy' size='xs' onClick={() => setCrafts(crafts-1)} />
                                                        <h2 className="text-slate-500 font-semibold text-2xl">{crafts}</h2>
                                                        <Button disabled={crafts >= 20} text='+' color='navy' size='xs' onClick={() => setCrafts(crafts+1)} />
                                                    </div>
                                                    <h2 className="text-slate-500 mt-2 font-semibold uppercase">Current ({organization?.aircraftLimit})</h2>
                                                </div>
                                                <div className='flex flex-col items-center self-center gap-2'>
                                                    <h1 className="text-brand-dark font-semibold text-lg">New Costs</h1>
                                                    <h2 className="text-slate-800">{formatUSD(prorateCostCrafts)} (Prorate Adjustment)</h2>
                                                    <h2 className="text-slate-800">{formatUSD(newCostCrafts)} (New Next Bill)</h2>
                                                    <Button text={organization?.aircraftLimit <= crafts ? 'Add' : 'Remove' } color='navy' size='xs' disabled={isUpdating || crafts === organization.aircraftLimit } onClick={async () => {
                                                        setIsUpdating(true);
                                                        showToast({ title: 'Updating Craft', type: ToastLength.Normal});
                                                        await updateCraftAmount({ variables: { amount: crafts } });
                                                        setTimeout(async () => { 
                                                            await refetch();
                                                            await organizationRefetch();
                                                            setIsUpdating(false);
                                                        }, 2000); // wait for stripe to update
                                                    }} />
                                                </div>
                                                
                                            </div>
                                        </div> */}
                                    </Card>
                                </>
                            :
                                <LegacyPlanPricing planSelected={(plan)=> setPaymentModal(plan)} coupon={coupon} isTrial={!cbInfo?.cb_trial_ends_at} showToast={showToast} />
                                // <PlanPricing stripePlans={stripePlans} />
                            }
                    </Form> )}
            </Formik>
        </div>
    )
}
import { AirplaneIcon } from 'assets/icons';
import { useState } from 'react';
import { GetMroCustomerQuery } from 'graphql/generated';
import CustomerOrder from './CustomerOrder';
import { formatApiDate } from 'utils/formatter';
import { CaretRightIcon, CaretDownIcon } from 'assets/icons';
import { Link } from 'react-router-dom';

const CustomerCraft = function ({
  mroCraft,
  craftsLength,
}: {
  mroCraft: GetMroCustomerQuery['mroCustomer']['mroCrafts'][number] & { status?: string };
  craftsLength: number;
}) {
  const [expanded, setExpanded] = useState(craftsLength === 1);
  const active = mroCraft.mroWorkOrders.length > 0 ? true : false;

  return (
    <>
      <div
        className={`
          ${active ? 'shadow-blue-t border-brand-pale' : 'shadow-inner border-slate-200'} 
          ${expanded ? 'rounded-t' : 'rounded'} 
          flex flex-col p-4 border rounded-t cursor-pointer`}
        onClick={() => {
          setExpanded(!expanded);
        }}>
        <div className={`${active ? 'border-brand-pale' : 'border-slate-200'} flex items-center justify-between border-b pb-4`}>
          <div className="flex items-center gap-2">
            <Link
              to={`/mro/crafts/${mroCraft.id}`}
              className={`${
                active ? 'bg-brand-pale text-brand-electric ' : 'bg-slate-100 text-slate-500'
              } rounded h-12 w-12 flex items-center justify-center`}>
              <AirplaneIcon className="h-6 w-6" />
            </Link>
            <div className="flex flex-col">
              <span className={`${active ? 'text-brand-dark' : 'text-slate-500'} text-2xl font-bold`}>{mroCraft.tailNumber}</span>
              <span className="text-sm -mt-1">{mroCraft.year + ' ' + mroCraft.make + ' ' + mroCraft.model}</span>
            </div>
          </div>
          {expanded ? <CaretDownIcon className="h-4 w-4" /> : <CaretRightIcon className="h-4 w-4" />}
        </div>
        <div className="flex gap-1 pt-4 text-sm">
          <div className="flex items-center justify-center gap-2 bg-white border border-brand-pale w-1/3 font-medium rounded py-1">
            <span className="font-bold border-r pr-2">Arrival</span>
            {formatApiDate(mroCraft?.mroWorkOrders[0]?.arrivalDate)}
          </div>
          <div className="flex items-center justify-center gap-2 bg-white border border-brand-pale w-1/3 font-medium rounded py-1">
            <span className="font-bold border-r pr-2">Promise</span>
            {formatApiDate(mroCraft?.mroWorkOrders[0]?.promiseDate)}
          </div>
          <div
            className={`
            ${active ? 'text-brand-electric bg-brand-offwhite border-brand-pale' : 'text-slate-500 border-slate-200'} 
            flex items-center justify-center gap-2 border w-1/3 font-medium rounded py-1`}>
            <span className="font-bold border-r pr-2">Discrepancies</span>
            {mroCraft?.mroWorkOrders?.reduce((acc, cur) => acc + cur.mroWorkOrderItems.length, 0)}
          </div>
        </div>
      </div>
      {expanded && (
        <div className="flex flex-col border-brand-pale transition-all border border-t-0 p-4 shadow-inner bg-brand-offwhite rounded-b gap-2">
          {mroCraft.mroWorkOrders.map((order, ndx) => {
            return <CustomerOrder key={ndx} order={order} />;
          })}
        </div>
      )}
    </>
  );
};

export default CustomerCraft;

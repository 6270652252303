import { useQuery } from '@apollo/client';
import { GetMroWorkOrderDocument } from 'graphql/generated';
import { Link } from 'react-router-dom';
import StatusButton from 'components/StatusButton/StatusButton';
import { BriefcaseIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { taskCase } from 'utils/statuses';

const CustomerOrder = function (props) {
  const { data: { mroWorkOrder } = {} } = useQuery(GetMroWorkOrderDocument, {
    variables: { id: props.order.id },
  });
  return (
    <>
      <div className="group relative bg-white rounded border border-brand-pale shadow-blue transition-all">
        <Link
          to={'/mro/work-orders/' + props.order.id}
          className="flex justify-between items-center p-3 border-b border-brand-pale border-dashed">
          <div className="flex items-center gap-2 relative z-10">
            <BriefcaseIcon className="h-8 w-8 p-1.5 bg-brand-pale text-brand-electric rounded shrink-0" />
            <span className="text-brand-dark font-bold truncate">{props.order.title}</span>
          </div>
          <div className="w-48">
            <StatusButton readOnly={true} context="order" text={props.order.status} className="px-2" onSelect={(e) => {}} />
          </div>
        </Link>
        <div className="p-3 rounded-b">
          <div className="bg-white rounded p-1">
            {mroWorkOrder?.mroWorkOrderItems?.map((item, index) => {
              return (
                <Link
                  to={'/mro/work-orders/' + props.order.id}
                  key={item.id}
                  className="flex items-center justify-between py-2 px-3 hover:bg-brand-offwhite rounded transition">
                  <div className="flex items-center gap-2 overflow-hidden">
                    <WrenchScrewdriverIcon
                      className={`h-6 w-6 p-1.5 ${taskCase[item.status]?.bgColor} ${taskCase[item.status]?.textColor} rounded shrink-0`}
                    />
                    <span className="font-semibold truncate text-sm">{item.title}</span>
                  </div>
                  <div className="w-44">
                    <StatusButton readOnly={true} context="task" text={item.status} className="px-2" onSelect={(e) => {}} />
                  </div>
                </Link>
              );
            })}
            {mroWorkOrder?.mroWorkOrderItems?.length === 0 && (
              <div className="bg-brand-offwhite border border-brand-pale rounded p-2 font-semibold text-brand text-center text-sm -m-2">
                No discrepancies on this work order.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerOrder;

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';

export default function Modal({ cancel, accept, message, submessage='', submessage2='' }) {
  return (
    <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
      <div
        onClick={() => {
          cancel(false);
        }}
        className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0"></div>
      <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-md w-full">
        <XMarkIcon
          onClick={() => {
            cancel(false);
          }}
          className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
        />
        <div className="flex gap-3 items-center p-8">
          <ExclamationTriangleIcon className="h-14 w-14 p-3 bg-amber-100 text-amber-400 rounded-lg" />
          <div>
            <h1 className="font-bold text-brand leading-5">{message}</h1>
            {submessage && <div className="text-sm text-brand py-2 leading-5">{submessage}</div>}
            {submessage2 && <div className="text-sm text-brand py-2 leading-5">{submessage2}</div>}
            <h2 className="text-sm text-slate-500">Would you like to continue?</h2>
          </div>
        </div>
        <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-t border-slate-300 bg-slate-50">
          {/* <Button color="white" size="xs" text="Cancel" onClick={()=>{ cancel(false) }}/> */}
          <span
            className="text-sm cursor-pointer font-medium hover:opacity-70 transition"
            onClick={() => {
              cancel(false);
            }}>
            Cancel
          </span>
          <Button
            color="navy"
            size="xs"
            text="Continue"
            onClick={() => {
              accept();
              cancel(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

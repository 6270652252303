import { useState, useEffect } from 'react';
import { FullFormikSelect, FullFormikTextarea } from 'components/Form/StandardForm';
import { ErrorMessage, Form, Formik, Field } from 'formik';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import UserIcon from 'components/UserIcon/UserIcon';
import { PlusIcon } from '@heroicons/react/24/outline';
import { webFormat } from 'utils/statuses';
import { GetItemCategoriesDocument, GetMroDashboardDocument, GetMroWorkOrderDocument, GetMroWorkOrderItemDocument, GetMroWorkOrderItemQuery, UpdateMroWorkOrderItemDocument } from 'graphql/generated';
import EmployeeSelect from '../EmployeeSelect';

interface Values {
  title: string;
  description: string;
  notes: string;
  status: string;
  estimatedHours?: number;
  isFlatRate: boolean;
  flatRate?: number;
  category: string;
}

export default function DetailsTopEdit({
  mroWorkOrderItem,
  itemId,
  selectedProfiles,
  setSelectedProfiles,
  refetch,
  toggleEdit,
  context = '',
}: {
  mroWorkOrderItem: GetMroWorkOrderItemQuery['mroWorkOrderItem'];
  itemId: string;
  selectedProfiles: any;
  setSelectedProfiles: any;
  refetch: () => Promise<ApolloQueryResult<GetMroWorkOrderItemQuery>>;
  toggleEdit: (bool: boolean) => void;
  context?: string;
}) {
  const [toggleEmployeeSelect, setToggleEmployeeSelect] = useState(false);
  const [initialValues, setInitialValues] = useState<Values>({
    title: '',
    description: '',
    notes: '',
    status: '',
    estimatedHours: 0,
    flatRate: 0,
    isFlatRate: false,
    category: '',
  });
  const [files, setFiles] = useState<File[]>([]);
  const [updateWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } },
      { query: GetMroWorkOrderDocument,  variables: { id: mroWorkOrderItem?.mroWorkOrder?.id } },],
  });
  const { data: { mroWorkOrderItemCategories } = {} } = useQuery(GetItemCategoriesDocument, { variables: { workOrderId: mroWorkOrderItem.mroWorkOrder.id } });

  const handleSubmit = async (values: Values) => {
    updateWorkOrderItem({
      variables: {
        input: {
          id: mroWorkOrderItem?.id,
          description: values.description,
          notes: values.notes,
          estimatedHours: values.estimatedHours ?? 0,
          title: values.title,
          isFlatRate: values.isFlatRate,
          flatRate: values.flatRate,
          assignees: { set: selectedProfiles?.map((prof) => ({ id: prof.id })) },
          category: { connect: { id: values.category } },
        },
      },
    })
      .then(() => toggleEdit(false))
      .catch(console.error);
    if (files) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('mroWorkOrderItemId', mroWorkOrderItem?.id);
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
      if (res.ok) {
        refetch();
      }
    }
  };

  useEffect(() => {
    setInitialValues({
      title: mroWorkOrderItem?.title,
      description: mroWorkOrderItem?.description,
      notes: mroWorkOrderItem?.notes,
      status: mroWorkOrderItem?.status,
      estimatedHours: mroWorkOrderItem?.estimatedHours,
      isFlatRate: mroWorkOrderItem?.isFlatRate,
      flatRate: mroWorkOrderItem?.flatRate,
      category: mroWorkOrderItem?.category?.id,
    });
  }, [mroWorkOrderItem]);

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <div className="flex flex-col w-full border rounded pt-4 shadow-blue bg-white">
            <div className="flex flex-col w-full p-2 px-10">
              <div className="flex justify-between py-2 border-b border-slate-100">
                <Field
                  className="border -my-[1px] w-full p-1 rounded border-slate-200 disabled:border-none strip-style text-sm font-semibold text-brand-dark bg-slate-50 disabled:bg-transparent"
                  id="title"
                  name="title"
                  disabled={context === 'dashboard'}
                />
              </div>
              <div className="flex justify-between py-2 border-b border-slate-100">
                <dt className="text-sm font-medium text-slate-500">Status</dt>
                <dd className="text-sm font-semibold text-brand-dark">{webFormat(mroWorkOrderItem?.status)}</dd>
              </div>
              <div className="flex justify-between py-2 border-b gap-10 border-slate-100">
                <dt className="text-sm font-medium text-slate-500">Category</dt>
                <FullFormikSelect name="category" label="" className='w-32' disabled={context === 'dashboard'}>
                {mroWorkOrderItemCategories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </FullFormikSelect>
              </div>
              <div className="flex justify-between py-2 border-b border-slate-100">
                <dt className="text-sm font-medium text-slate-500">Estimated Hours</dt>
                <Field
                  className="rounded border-slate-200 disabled:border-none p-1 -my-[1px] text-right strip-style text-sm font-semibold text-brand-dark bg-slate-50 disabled:bg-transparent"
                  id="estimatedHours"
                  name="estimatedHours"
                  type="number"
                  disabled={context === 'dashboard'}
                />
                <ErrorMessage name="estimatedHours" />
              </div>
              <div className="flex justify-between py-2 border-b border-slate-100">
                <dt className="text-sm font-medium text-slate-500">Total Hours</dt>
                <dd className="text-sm font-semibold text-brand-dark">{mroWorkOrderItem?.totalLaborHours}</dd>
              </div>
              {context !== 'dashboard' && (
                <>
                  <div className="flex justify-between py-2 border-b border-slate-100">
                    <dt className="text-sm font-medium text-slate-500">Bill Flat Rate</dt>
                    <Field
                      className="rounded border-slate-200 p-1 -my-[1px] text-right strip-style text-sm font-semibold text-brand-dark bg-slate-50"
                      id="isFlatRate"
                      name="isFlatRate"
                      type="checkbox"
                    />
                    <ErrorMessage name="isFlatRate" />
                  </div>
                  { values.isFlatRate && <div className="flex justify-between py-2 border-b border-slate-100">
                    <dt className="text-sm font-medium text-slate-500">Amount</dt>
                    <Field
                      className="rounded border-slate-200 disabled:border-none p-1 -my-[1px] text-right strip-style text-sm font-semibold text-brand-dark bg-slate-50 disabled:bg-transparent"
                      id="flatRate"
                      name="flatRate"
                      type="number"
                    />
                    <ErrorMessage name="flatRate" />
                  </div> } 
                  <div className="flex justify-between items-center py-2">
                    <dt className="text-sm font-medium text-slate-500">Assignees</dt>
                    <dd className="flex -mr-1 -my-[0.125rem]">
                      {selectedProfiles?.map((item, index) => {
                        if (!item) return null;
                        return (
                          <UserIcon
                            key={index}
                            iconSize="sm"
                            iconText={item.firstName + ' ' + item.lastName}
                            iconId={item.firstName + ' ' + item.lastName}
                            iconColor={item.profileColor}
                            className="border-2 border-slate-50"
                          />
                        );
                      })}
                      <div
                        onClick={() => {
                          setToggleEmployeeSelect(!toggleEmployeeSelect);
                        }}
                        className="flex items-center justify-center h-8 w-8 bg-brand-pale text-brand-electric hover:text-white hover:bg-brand-electric rounded-full border hover:border-solid border-dashed border-brand-electric cursor-pointer transition-all relative">
                        <PlusIcon className="h-4 w-4" />
                      </div>
                    </dd>
                  </div>
                </>
              )}
              <div className={`${toggleEmployeeSelect ? '' : 'hidden'} border border-slate-300 p-4 rounded shadow-inner mt-2`}>
                <EmployeeSelect selectedProfiles={selectedProfiles} setSelectedProfiles={setSelectedProfiles} />
              </div>
            </div>
            <div className="px-10">
              <div className="flex gap-2 mb-2">
                <div className="flex flex-col w-full">
                  <FullFormikTextarea name="notes" label="Notes" />
                </div>
              </div>
            </div>
            <div className="px-10 py-4 border-t border-slate-200">
              <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                Attachments
              </label>
              <div
                className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  if (e.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    [...e.dataTransfer.items].forEach((item, i) => {
                      // If dropped items aren't files, reject them
                      if (item.kind === 'file') {
                        const file = item.getAsFile();
                        setFiles((cur) => [...cur, file]);
                      }
                    });
                  } else {
                    // Use DataTransfer interface to access the file(s)
                    [...e.dataTransfer.files].forEach((file, i) => {
                      setFiles((cur) => [...cur, file]);
                    });
                  }
                }}>
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        multiple
                        className="sr-only"
                        onChange={(e) => setFiles((cur) => [...cur, ...Array.from(e.target.files)])}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                </div>
              </div>
              <p className="text-xs text-indigo-800">
                {Array.from(files ?? [])
                  .map((file) => file.name)
                  .join(', ')}
              </p>
            </div>
            <div className="flex items-center px-10 w-full bg-white py-2 rounded-b border-t border-slate-200 justify-end gap-4">
              <div
                onClick={() => {
                  toggleEdit(false);
                  setFiles([]);
                }}
                className="flex items-center justify-center hover:opacity-70 cursor-pointer">
                <span className="text-xs font-semibold cursor-pointer text-slate-500">Cancel</span>
              </div>
              <button
                type="submit"
                className="flex items-center justify-center self-end hover:opacity-70 cursor-pointer bg-brand px-3 rounded py-1">
                <span className="text-xs cursor-pointer text-white font-semibold">Save Changes</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export type CraftInterval = {
    name: string;
    value: number;
}


export default function CraftIntervals({ intervals }: { intervals: CraftInterval[] }){
    const colors = ['bg-brand-dark', 'bg-brand', 'bg-brand-electric', 'bg-brand-purple', 'bg-cyan-500'];
    return (
        <div className="w-full mb-3 flex gap-1 flex-wrap justify-center">
            {intervals?.slice(0,5).map( (interval, index) => {
                return (
                    <div key={index} className={`flex flex-col justify-center items-center font-bold text-white ${colors[index]} rounded-lg w-[32%] py-3 text-center`}>
                        <span className="text-sm">{interval.name}</span>
                        <span className="text-sm">{interval.value}</span>
                    </div>
                )
            })}
        </div>
    )
}
import { useState, useEffect } from 'react';

const parseQueryParams = ( search ) => {
  const params = new URLSearchParams(search);
  const stateFromURL = params.get('state');
  return stateFromURL ? JSON.parse(stateFromURL) : null;
};

const serializeStateToURL = (key : string, state : string) => {
  const queryParams = new URLSearchParams();
  queryParams.set(key, state);
  return queryParams.toString();
};

export default function useSaveState(key, initialState ){
  const [state, setState] = useState(() => {
    // Check if state is available in the URL, use it; otherwise, use the initial state
    const stateFromURL = parseQueryParams(window.location.search);
    return stateFromURL || JSON.parse(localStorage.getItem(key)) || initialState;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
    const urlSearchParams = serializeStateToURL(key,state);
    const newURL = `${window.location.pathname}?${urlSearchParams}`;
    window.history.replaceState(null, '', newURL);
  }, [key, state]);

  return [state, setState];
};
import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetMroPartInventoriesDocument,
  GetMroPartInventoryDocument,
  GetMroPartWarehouseDocument,
  GetMroPartsDocument,
  PartCondition,
  UpdateMroPartInventoryDocument,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';

const InventoryFlyout = function ({ partInventoryId, closeFlyout }: { partInventoryId: string; closeFlyout: () => void }) {
  const { user } = useSession();
  const { data: { mroPartInventory } = {} } = useQuery(GetMroPartInventoryDocument, { variables: { id: partInventoryId } });
  const [updatePartInventory] = useMutation(UpdateMroPartInventoryDocument, {
    refetchQueries: [
      { query: GetMroPartInventoriesDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
      GetMroPartsDocument,
    ],
  });
  const { data: { mroPartWarehouses = [] } = {} } = useQuery(GetMroPartWarehouseDocument, {
    variables: {
      input: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });

  const [initialValues, setInitialValues] = useState({
    condition: '' as PartCondition,
    quantity: 0,
    unitPrice: 0,
    lotNumber: '',
    bin: '',
    warehouse: 'n/a',
  });

  useEffect(() => {
    if (mroPartInventory) {
      setInitialValues({
        condition: mroPartInventory.condition ?? PartCondition.New,
        quantity: Number(mroPartInventory.quantity ?? 0),
        lotNumber: mroPartInventory.lotNumber ?? '',
        bin: mroPartInventory.bin ?? '',
        unitPrice: Number(mroPartInventory.unitPrice ?? 0),
        warehouse: mroPartInventory.mroPartWarehouse?.id ?? 'n/a',
      });
    }
  }, [mroPartInventory]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const warehouseId = values.warehouse === 'n/a' ? null : values.warehouse;
      delete values.warehouse;
      updatePartInventory({
        variables: {
          input: {
            id: partInventoryId,
            ...values,
            ...(warehouseId ? { mroPartWarehouse: { connect: { id: warehouseId } } } : { mroPartWarehouse: null }),
          },
        },
      })
        .then(() => closeFlyout())
        .catch((e) => console.log(e));
    },
    [partInventoryId, updatePartInventory]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikSelect name="condition" label="Condition">
                  <option value="NEW">New</option>
                  <option value="USED">Used</option>
                  <option value="REFURBISHED">Refurbished</option>
                </FullFormikSelect>
                <FullFormikInput min={0} type="number" name="unitPrice" label="Unit Price" />
                <FullFormikInput type="number" name="quantity" label="Qty." />
              </div>
              <div className="flex gap-1">
                <FullFormikInput name="lotNumber" label="Lot #" />
                <FullFormikSelect name="warehouse" label="Warehouse">
                  <option value="n/a">N/A</option>
                  {mroPartWarehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </FullFormikSelect>
                <FullFormikInput name="bin" label="Bin" />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              <Button text="Save" color="navy" size="sm" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InventoryFlyout;

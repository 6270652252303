import { CheckIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { convertToFee } from 'utils/charge';
import { formatUSD } from 'utils/formatter';
import { webFormat } from 'utils/statuses';

type Charge = {
    description: string;
    total: number;
    percent: number;
    active: boolean;
    temp?: boolean;
    type?: string;
}

export default function FeeItem(
    { fee, updateFee, removeFee, edit, totalLabor, totalParts, isDiscount } : {
        fee: Charge;
        edit: boolean;
        updateFee?: any;
        removeFee?: any;
        totalLabor?: number;
        totalParts?: number;
        isDiscount?: boolean;
    }) {
    const feeName = isDiscount ? "Discount" : "Charge";     
    return (
        <div
            className='flex justify-between cursor-pointer items-center py-2 w-full border-b-2 border-dashed last:border-none border-slate-100'>
            <div className='flex items-center gap-4 w-full'>
                { edit ? (
                    <input
                    type="checkbox"
                    onChange={() => {
                        const temp = { ...fee };
                        temp.active = !temp.active;
                        updateFee(temp);
                    }}
                    checked={fee.active}
                    className='flex rounded h-3.5 w-3.5 mr-5 ml-1 cursor-pointer'
                    /> 
                ) : (
                    <CheckIcon className='flex rounded h-3.5 w-3.5 mr-5' /> 
                ) }
                <input 
                    value={fee.description} 
                    placeholder={fee.percent !== undefined ? `New Percent ${feeName}` : `New Flat ${feeName}`}
                    disabled={!edit || !fee.temp} 
                    onChange={(e) => {
                        var temp = fee;
                        temp.description = e.target.value;
                        updateFee(temp);
                    }}
                    className="bg-slate-50 text-sm font-medium rounded border p-2 border-slate-200 disabled:bg-white disabled:border-none disabled:shadow-none placeholder:text-slate-400" />

                { fee?.percent !== undefined ? <>
                 { edit && fee.temp ? (
                        <select value={fee.type} onChange={(e)=> {
                            const temp = { ...fee };
                            temp.type = e.target.value;
                            temp.total = convertToFee(totalLabor, totalParts, temp);
                            updateFee(temp);
                        }} className="rounded border p-2 text-sm font-bold bg-slate-50 border-slate-300 focus:bg-white w-28">
                            <option value={"BOTH"}>Both</option>
                            <option value={"PARTS"}>Parts</option>
                            <option value={"LABOR"}>Labor</option>
                        </select>
                    ) : (
                        <div className="p-2 text-sm w-28 font-bold">
                            {webFormat(fee.type)}
                        </div>
                    )
                } </> : <span className='w-28 p-2'>&nbsp;</span> }
            </div>
            { edit && fee.temp ? (
                <div className='relative gap-5 flex justify-end ml-3'>
                    <div className='flex self-center justify-center'>
                        <span>{fee.percent !== undefined && `(${fee.total})`}</span>
                    </div>
                    <input
                        className="w-20 text-sm border-slate-200 bg-slate-50 pr-6 shadow-inner no-spinner rounded text-right disabled:bg-white disabled:border-none disabled:shadow-none"
                        value={fee?.percent !== undefined ? fee?.percent?.toString() : fee?.total?.toString()}
                        type="number"
                        min={0}
                        disabled={!edit} 
                        step={0.01}
                        onChange={(e) => {
                            const temp = { ...fee };
                            if (fee.percent !== undefined) {
                                temp.percent = Number(e.target.value ?? 0);
                                temp.total = convertToFee(totalLabor, totalParts, temp);
                            } else {
                                temp.total = Math.round(Number(e.target.value ?? 0) * 100) / 100;
                            }
                            updateFee(temp);
                        }}
                    />
                    <span className="ml-[-36px] text-xs pt-3 font-semibold">{fee.percent !== undefined ? '%':'\xa0\xa0\xa0'}</span> 
                </div>
                ) : (
                    <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 font-bold ml-2">
                        <span>{fee.percent !== undefined && `(${fee.total})`}</span>
                        <span>{fee.percent === undefined ? formatUSD(fee.total) : fee.percent+'%'}</span>
                    </div>
            )   }
            <div className='flex ml-10'>
                { fee.temp ? ( 
                    <TrashIcon
                        onClick={() => removeFee()}
                        title="Delete"
                        className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
                        /> 
                ) : ( <span className='w-12 h-6 p-0.5'>&nbsp;</span> ) }
            </div>
        </div>
    );
}
import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_432_1398)">
        <path
          d="M14.2 8V11H12.2V9C12.2 8.73478 12.0947 8.48043 11.9071 8.29289C11.7196 8.10536 11.4652 8 11.2 8H9.61401L7.90701 6.293C7.71952 6.10545 7.46521 6.00006 7.20001 6H1.20001C0.934796 6 0.680442 6.10536 0.492905 6.29289C0.305369 6.48043 0.200012 6.73478 0.200012 7L0.200012 15C0.200012 15.2652 0.305369 15.5196 0.492905 15.7071C0.680442 15.8946 0.934796 16 1.20001 16H11.2C11.4652 16 11.7196 15.8946 11.9071 15.7071C12.0947 15.5196 12.2 15.2652 12.2 15V13H14.2V16H16.2V8H14.2Z"
          fill="currentColor"
        />
        <path d="M3.20001 4H5.20001V2H8.20001V0H0.200012V2H3.20001V4Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_432_1398">
          <rect width="16" height="16" fill="white" transform="translate(0.200012)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

import { formatApiDateTime } from 'utils/formatter';

export default function PartInfo({ mroPart }) {
  return (
    <div className="bg-white border rounded-b rounded-r border-slate-300 shadow-blue mb-10">
      {/* Part Info */}
      <div className="p-4">
        <div className="flex flex-col">
          <span className="font-semibold text-xs uppercase text-slate-500">Description</span>
          <span className="text-lg font-bold text-brand-dark leading-5">{mroPart?.description}</span>
        </div>
        <div className="flex flex-col mt-4">
          <span className="font-semibold text-xs text-slate-500 uppercase">Created</span>
          <p className="font-medium text-brand-dark rounded text-sm">{formatApiDateTime(mroPart?.createdAt)}</p>
        </div>
      </div>
    </div>
  );
}

import { useLazyQuery } from "@apollo/client";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Airport, GetAirportsDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { removeK } from "utils/formatter";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export type Passenger = {
    id?: string;
    name: string;
};



export default function PassengerItem(
{   passenger, 
    index, 
    temp = true,
    options,
    handleRemoveItem, 
    handleItemUpdate 
} : {
    passenger: Passenger;
    index: number;
    temp?: boolean;
    options: Passenger[];
    handleRemoveItem: (index: number) => void;
    handleItemUpdate: (index: number, type : string, value: string) => void;
}) {
    const [selectedPassengers, setSelectedPassengers] = useState(options);
    useEffect(() => {
        const timeOutId = setTimeout(
            () =>{},
               //!passenger.id && setSelectedPassengers((prev) => prev.filter((pass) => pass.name !== passenger.name)),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [passenger.name]);
    useEffect(() => {
        if(options?.find((option) => option.name === passenger.name)) {
            handleItemUpdate(index, 'id', options.find((option) => option.name === passenger.name)?.id ?? '');
        }else if(passenger.name === ''){
            handleItemUpdate(index, 'id', '');
        }
    }, [passenger.name]);
    return ( <div className="flex w-full gap-2 mb-9">
        <Combobox value={passenger.name}>
            <div className={'relative w-full'}>
                <Combobox.Input<'input', any>
                className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
                onChange={(event) => handleItemUpdate(index, 'name', event.target.value)}
                value={passenger.name}
                placeholder="Search for an Passenger"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {selectedPassengers?.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {selectedPassengers.map((option) => (
                    <Combobox.Option<'li', string>
                        key={option.id}
                        value={option.name}
                        onClick={() => { 
                            handleItemUpdate(index, 'id', option.id);
                            handleItemUpdate(index, 'name', option.name);
                        }}
                        className={({ active }) =>
                            classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                        }>
                        {({ active, selected }) => (
                        <>
                            <div className="flex">
                                <span className={classNames('truncate', selected && 'font-semibold')}>{option.name}</span>
                            </div>

                            {selected && (
                            <span
                                className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                                )}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            )}
                        </>
                        )}
                    </Combobox.Option>
                    ))}
                </Combobox.Options>
                )}
            </div>
        </Combobox>
        { temp &&
        <TrashIcon
            onClick={() => {
                handleRemoveItem(index);
            }}
            title="Delete"
            className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
        />  }
    </div> );
    
}
import { useQuery } from "@apollo/client";
import { BriefcaseIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import UserIcon from "components/UserIcon/UserIcon";
import { useSession } from "contexts";
import { GetMroLaborEntriesDocument, GetPendingPartRequestsDocument } from "graphql/generated";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatApiDate, formatToFixed, formatWithZeros } from "utils/formatter";
import SubmitPartsRequest from "views/Part145/WorkOrders/Order/WorkOrderItems/SubmitPartsRequest";

export function ActiveLaborEntries() {
    const { user } = useSession();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const { data: { mroLaborEntries } = {} } = useQuery(GetMroLaborEntriesDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const columns = useMemo(
      () => [
        {
          Header: 'Start Date',
          id: 'date',
          accessor: (row) => row.startTime ?? '', // accessor is the "key" in the data
          className: 'w-full text-slate-500 whitespace-nowrap',
          Cell: ({ value, row }: any) => {
            return (
              <div className="flex gap-1 items-center">
                <div className="bg-rose-500 rounded-full h-2 w-2"></div>
                <span className="font-semibold">{formatApiDate(value)}</span>
              </div>
            );
          },
        },
        {
          Header: 'Performer',
          id: 'performedBy',
          accessor: (row) => row.mroEmployeeProfile ?? '', // accessor is the "key" in the data
          className: 'w-full justify-end text-slate-500 whitespace-nowrap',
          Cell: ({ value, row }: any) => {
            if (row.original.mroEmployeeProfile) {
              return (
                <div className="flex -ml-[0.125rem] -my-[0.125rem]">
                  <UserIcon
                    iconSize="md"
                    iconText={`${value.firstName} ${value.lastName}`}
                    iconId={`${value.firstName} ${value.lastName}`}
                    iconColor={value.profileColor}
                    className="border-2 border-slate-50"
                  />
                </div>
              );
            } else {
              return <span className="">-</span>;
            }
          },
        },
        {
          Header: 'Hours',
          id: 'hours',
          accessor: (row) => row.hours ?? '', // accessor is the "key" in the data
          className: 'w-full justify-end text-slate-500 whitespace-nowrap',
          Cell: ({ value, row }: any) => {
            value === '' && (value = '-');
            return <span className="">{formatToFixed(value)}</span>;
          },
        },
        {
          Header: 'Unit Price',
          id: 'unitPrice',
          accessor: (row) => row.billableRate ?? row.unitPrice, // accessor is the "key" in the data
          className: 'w-full justify-end text-slate-500',
          Cell: ({ value, row }: any) => {
            if (!value) return '-';
            return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
          },
        },
        {
          Header: 'Total Price',
          id: 'totalPrice',
          accessor: (obj) => obj.billableRate * obj.hours || obj.quantity * obj.unitPrice, // accessor is the "key" in the data
          className: 'w-full justify-end text-slate-500 font-bold whitespace-nowrap',
          Cell: ({ value, row }: any) => {
            return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
          },
        },
      ],
      []
    );
    return (
      <>
        <Flyout {...flyoutProps} />
        <div className=" bg-white shadow-blue">
            <div className="flex flex-col">
                <div className="flex items-center justify-between py-3 px-6">
                    <h1 className="text-brand-dark text-left font-bold text-lg">Active Labor Entries</h1>
                </div>
                <div className="flex flex-wrap p-2 overflow-x-auto">
                    <Table
                        columns={columns}
                        data={mroLaborEntries ?? []}
                        className="sm-head w-full"
                        onRowClick={(row) => {}}
                    />
                </div>
            </div>
        </div>
      </>
    );
}
import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import { FullFormikSelect } from "components/Form/StandardForm";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { UpdateUserDocument, UpdateUserProfileDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { webFormat } from "utils/statuses";

const StandardNotificationsOptions = () => ( <>
    <option value={"NONE"} >No Notifications</option>
    <option value={"EMAIL"}>Email</option>
    <option value={"SMS"}>Sms</option>
    <option value={"EMAIL_AND_SMS"}>Email and Sms</option>
</>);

const notificationTypes = {
    "SQWAUK" : "NONE",
    "RESERVATIONS" : "NONE",
    "PAYMENTS" : "NONE",
    "REPORTS" : "NONE",
    "ANNOUNCEMENTS" : "ALL",
    "NEWS_ALERTS" : "ALL",
};

export interface NotificationSettings {
    notification: string;
    type: string;
}

export default function NotificationsSettings({ settings, id }) {
    const { user } = useSession();
    const [ notificationSettings, setNotificationSettings ] = useState(notificationTypes);
    const [updateUserProfile] = useMutation(UpdateUserProfileDocument);
    useEffect(() => {
        if (settings) {
            const newSettings = { ...notificationTypes, ...settings };
            setNotificationSettings(newSettings);
        }
    }, [settings]);
    const { toastProps, showToast } = useToast();
    return (
        <div className="bg-white border border-slate-300 rounded flex">
            <Toast {...toastProps} />
            <Formik initialValues={ notificationSettings } enableReinitialize onSubmit={ async (values)=> { 
                await updateUserProfile({ variables:{ input: { notificationSettings: values, id } } });
                showToast({ title: 'Notfication Preferences Updated', type:ToastLength.Normal });
            }} >
                {({ values, isSubmitting }) => (
                    <Form className="flex flex-col w-full md:w-2/3 gap-2 p-10 rounded-l">
                        <span className="font-bold text-xs text-brand uppercase mb-6 border-b py-0.5 -mx-4 pl-4">Notification Settings</span>
                        <FullFormikSelect name={"SQWAUK"} label="Receive Squawk Notifications" >
                            <StandardNotificationsOptions />
                        </FullFormikSelect>
                        <FullFormikSelect name={"RESERVATIONS"} label="Get alerted on new reservations" >
                            <StandardNotificationsOptions />
                        </FullFormikSelect>
                        <FullFormikSelect name={"PAYMENTS"} label="Get alerted on payments due" >
                            <StandardNotificationsOptions />
                        </FullFormikSelect>
                        <FullFormikSelect name={"REPORTS"} label="Get periodic email reports on your aircrafts' status." >
                            <option value={"NONE"} >No Notifications</option>
                            <option value={"WEEKLY"} >Weekly</option>
                        </FullFormikSelect> 
                        <FullFormikSelect name={"ANNOUNCEMENTS"} label="Get Announcement Emails from your Organization" >
                            <option value={"NONE"}>No Notifications</option>
                            <option value={"ALL"} >All</option>
                        </FullFormikSelect>
                        <FullFormikSelect name={"NEWS_ALERTS"} label="Get News Alert Emails from Coflyt" >
                            <option value={"NONE"}>No Notifications</option>
                            <option value={"ALL"} >All</option>
                        </FullFormikSelect>
                        <Button text="Save" color="navy" size="sm" type="submit" disabled={isSubmitting} className="disabled:opacity-50" />
                    </Form> )}
            </Formik>
        </div>
    )
}
import UserIcon from 'components/UserIcon/UserIcon';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Message, Media } from '@twilio/conversations';
import { SessionUser } from 'contexts';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

const CustomerChatMessage = ({ item, user }: { item: Message; user: SessionUser }) => {
  if (item.author !== user?.email) {
    // received messages
    if (item.attachedMedia.length > 0) {
      let attachments = item.attachedMedia;
      return (
        <React.Fragment key={item.sid}>
          {item.body && (
            <div data-time={format(item.dateCreated, 't')} className="flex relative max-w-prose mt-8">
              <UserIcon iconSize="xl" iconText={item.author} iconId={null} iconColor="cust" className="" />
              <div className="flex items-center bg-slate-100 rounded font-medium text-brand-dark px-4 py-2 text-sm ml-2 mr-14">
                <p>
                  {item.body} - {item.index}
                </p>
              </div>
              <span className="text-xs text-slate-500 absolute -bottom-6 left-14">{format(item.dateCreated, 'MM/dd/yyyy HH:mm')}</span>
            </div>
          )}
          <div className="flex relative max-w-prose mt-8">
            {!item.body && <UserIcon iconSize="xl" iconText={item.author} iconId={null} iconColor="cust" className="" />}
            <div className={`flex -mb-6 ${item.body ? '' : 'ml-2'}`}>
              {attachments.map((attch, ndx) => {
                return <AttachmentThumb attch={attch} key={ndx} />;
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div data-time={format(item.dateCreated, 't')} className="flex max-w-prose relative mt-8" key={item.sid}>
          <UserIcon iconSize="xl" iconText={item.author} iconId={null} iconColor="cust" className="" />
          <div className="flex items-center bg-slate-100 rounded font-medium text-brand-dark px-4 py-2 text-sm ml-2 mr-14">
            <p>
              {item.body} - {item.index}
            </p>
          </div>
          <span className="text-xs text-slate-500 absolute -bottom-6 left-14">{format(item.dateCreated, 'MM/dd/yyyy HH:mm')}</span>
        </div>
      );
    }
  } else {
    // sent messages
    if (item.attachedMedia.length > 0) {
      let attachments = item.attachedMedia;
      return (
        <React.Fragment key={item.sid}>
          {item.body && (
            <div data-time={format(item.dateCreated, 't')} className="flex flex-row-reverse relative self-end max-w-prose mt-8">
              <UserIcon iconSize="xl" iconText={item.author} iconId={null} iconColor="blue" className="" />
              <div className="flex items-center bg-brand-pale rounded font-medium text-brand-dark px-4 py-2 text-sm mr-2 ml-14">
                <p>{item.body}</p>
              </div>
              <span className="text-xs text-slate-500 absolute -bottom-6 left-14">{format(item.dateCreated, 'MM/dd/yyyy HH:mm')}</span>
            </div>
          )}
          <div className="flex flex-row-reverse self-end max-w-prose relative mt-8">
            <div className="flex -mb-6 mr-14">
              {attachments.map((attch, ndx) => {
                return <AttachmentThumb attch={attch} key={ndx} />;
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div data-time={format(item.dateCreated, 't')} className="flex flex-row-reverse relative self-end max-w-prose mt-8" key={item.sid}>
          <UserIcon iconSize="xl" iconText={item.author} iconId={null} iconColor="blue" className="" />
          <div className="flex items-center bg-brand-pale rounded font-medium text-brand-dark px-4 py-2 text-sm mr-2 ml-14">
            <p>
              {item.body} - {item.index}
            </p>
          </div>
          <span className="text-xs text-slate-500 absolute -bottom-6 left-14">{format(item.dateCreated, 'MM/dd/yyyy HH:mm')}</span>
        </div>
      );
    }
  }
};

function AttachmentThumb({ attch }: { attch: Media }) {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const func = async () => {
      const cacheUrl = await attch.getContentTemporaryUrl();
      if (cacheUrl) setUrl(cacheUrl);
      else setUrl(await attch.getContentTemporaryUrl());
    };
    func().catch(console.error);
  }, [attch]);

  return (
    <div>
      <a
        target="_blank"
        href={url}
        className="flex items-center justify-center h-16 w-32 rounded border border-brand-pale"
        rel="noreferrer">
        <DocumentArrowDownIcon className="h-8 w-8 text-brand-electric" />
      </a>
      <div className="flex justify-between items-center w-32 text-sm mt-1">
        <span className="block whitespace-nowrap text-ellipsis overflow-hidden">{attch.filename}</span>
        <span className="text-slate-500 text-xs">{Math.round(attch.size / 1000)}KB</span>
      </div>
    </div>
  );
}

export default CustomerChatMessage;

import { useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { CreateMroLocationDocument, CreateMroOrganizationFeeDocument, CreateMroRtsTextDocument, GetLocationsDocument, GetMroOrganizationFeesDocument, MroFeeType, } from "graphql/generated";

export default function NewFee({ closeFlyout }: { closeFlyout: () => void }) {
    const { user } = useSession();
    const [createFee] = useMutation(CreateMroOrganizationFeeDocument, {
        refetchQueries: [{ query: GetMroOrganizationFeesDocument, variables: { mroOrganizationId: user.mroOrganizationId } }],
    });
    const initialValues = {
        name: '',
        description: '',
        active: true,
        onByDefault: false,
        isPercentage: false,
        value: 0,
        billableType: MroFeeType.Both
    };
    return (
        <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
            createFee({
                variables: {
                    input: {
                        mroOrganization: { connect: { id: user.mroOrganizationId } },
                        ...values
                    },
                },
            })
            .then(() => closeFlyout())
            .catch(console.error);
        }}>
            {({ values }) => (
                <Form className="flex flex-col gap-5 mx-5">
                    <Card className="">
                        <div className="flex gap-5 flex-col">
                            <FullFormikInput name="name" placeholder="Fee Name" label="Fee Name" />
                            <FullFormikInput name="description" placeholder="Fee Description" label="Description" />
                            <div className="flex justify-center gap-10">
                                <FullFormikCheckBox name="active" label="Active" isVertical={true} opposite={true} />
                                <FullFormikCheckBox name="onByDefault" label="On By Default" isVertical={true} opposite={true} />
                                <FullFormikCheckBox name="isPercentage" label="Pecentage" isVertical={true} opposite={true} />
                            </div> 
                            <div className="flex gap-5">
                                <FullFormikInput name="value" placeholder="Fee Amount" label={values.isPercentage ? "Percentage" : "Fee Amount" } />
                                <FullFormikSelect disabled={!values.isPercentage} name="billableType" label="Billable Type" >
                                    <option value={MroFeeType.Both}>Both</option>
                                    <option value={MroFeeType.Parts}>Parts</option>
                                    <option value={MroFeeType.Labor}>Labor</option>
                                </FullFormikSelect>
                            </div>
                        </div>
                    </Card>
                    <div className="flex justify-end">
                        <Button text="Create Fee" color="navy" size="xs" type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    );
}
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from 'contexts';
import { Link, useLocation } from 'react-router-dom';
import { OrgTypes } from 'utils/orgTypes';
import { GetServiceUserDocument, LookupTailQuery, MainLoginMutation, NewUserSignupMutation, OnboardCraftInput } from 'graphql/generated';
import { useQuery } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import CreateAccount from './Steps/CreateAccount';
import Logo from 'assets/icons/logo_w.svg';
import AddTailNumber from './Steps/AddTailNumber';
import ConfirmRegistration from './Steps/ConfirmRegistration';
import ConfigureAircraft from './Steps/ConfigureAircraft';
import IntercomChat from 'components/IntercomChat/IntercomChat';


interface SignUpContextInterface {
  tailNumber: string;
  setTailNumber: React.Dispatch<React.SetStateAction<string>>;
  tailInfo: LookupTailQuery['registrations'][number];
  setTailInfo: React.Dispatch<React.SetStateAction<LookupTailQuery['registrations'][number]>>;
  craftId: string;
  setCraftId: React.Dispatch<React.SetStateAction<string>>;
  login: NewUserSignupMutation['signup'];
  setLogin: React.Dispatch<React.SetStateAction<NewUserSignupMutation['signup']>>;
  formStep: string;
  changeStep: any;
  prevStep: string[];
  coupon: string;
  // setFormStep: React.Dispatch<React.SetStateAction<string>>;
  craftInput: OnboardCraftInput;
  setCraftInput: React.Dispatch<React.SetStateAction<OnboardCraftInput>>;
  resetState: () => void;
  markAsComplete: () => void;
}

export const SignUpContext = createContext<SignUpContextInterface | null>(null);
const fieldCss =
  'rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-4 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm';

const getStep = (step: string) => {
  switch (step) {
    case 'confirmRegistration':
      return <ConfirmRegistration />;
    case 'addTailNumber':
      return <AddTailNumber />;
    case 'configureAircraft':
      return <ConfigureAircraft />;
    default:
      return <CreateAccount />;
  }
};

const SignUp = () => {
  const { user, logout } = useSession();
  const [login, setLogin] = useState<SignUpContextInterface['login'] | undefined>();
  const [craftId, setCraftId] = useState<string | undefined>();
  const [tailNumber, setTailNumber] = useState<string | undefined>();
  const [formStep, setFormStep] = useState('createAccount');
  const [prevStep, setPrevStep] = useState(['createAccount']);
  const [tailInfo, setTailInfo] = useState<LookupTailQuery['registrations'][number] | undefined>();
  const [craftInput, setCraftInput] = useState<OnboardCraftInput>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const { coupon } = useParams();
  const defPath = window.location.pathname; 
  const steps = [ { 
    title: "Create Your Account", active: formStep === "createAccount", complete: prevStep.find(step => step === 'createAccount') ? true : false
  }, { 
    title: "Add Your Tail Number", active: formStep === "addTailNumber", complete: prevStep.find(step => step === 'addTailNumber') ? true : false
  }, { 
    title: "Confirm Registration Info", active: formStep === "confirmRegistration", complete: prevStep.find(step => step === 'confirmRegistration') ? true : false
  }, {
    title: "Configure Aircraft", active: formStep === "configureAircraft", complete: prevStep.find(step => step === 'configureAircraft') ? true : false
 } ];

  useEffect(() => {
    window.onpopstate = (e) => {
      setFormStep(prevStep[prevStep.length - 1]);
      let newArr = prevStep.slice(0, -1);
      setPrevStep(newArr);
      if (!prevStep.length) {
        setLogin(undefined);
        navigate(defPath);
      }
    };
  });

  useEffect(() => {
    if (user) {
      changeStep('createAccount', 'addTailNumber', false);
    }
  }, [user]);

  const changeStep = function (prev, next, backwards) {
    if (next) {
      setFormStep(next);
      navigate(defPath + '?step=' + prevStep.length);
    }
    if (backwards) {
      setFormStep(prevStep[prevStep.length - 1]);
      let newArr = prevStep.slice(0, -1);
      setPrevStep(newArr);
    } else {
      setPrevStep([...prevStep, prev]);
    }
  };

  const resetState = () => {
    setCraftId(undefined);
    setTailNumber(undefined);
    setFormStep('createAccount');
    setPrevStep(['createAccount']);
    setTailInfo(undefined);
    logout();
  };

  const markAsComplete = () => {
    setPrevStep(['tailLookup']);
  };

  const contextValue = {
    tailNumber,
    setTailNumber,
    craftId,
    setCraftId,
    login,
    setLogin,
    formStep,
    changeStep,
    prevStep,
    tailInfo,
    setTailInfo,
    resetState,
    craftInput,
    setCraftInput,
    markAsComplete,
    coupon
  };
  return (
    <>
      <main className="flex md:items-center justify-center min-h-[100vh] bg-brand-gray">
        <SignUpContext.Provider value={contextValue}>
          <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center bg-white md:bg-white/0 md:max-w-6xl w-full md:min-h-[768px] md:shadow md:mx-6 relative">
            <div className="w-1/3 hidden md:flex flex-col bg-brand-dark relative rounded-l">
              <div className="flex flex-col">
                <div className="flexitems-center p-8">
                  <img className="max-h-[3rem] border-white" src={Logo} alt="" />
                </div>
                <p className="text-brand-pale font-bold px-8 text-2xl mb-3">
                  Let's Get Started
                </p>
                <p className="text-brand-pale px-8">
                  You’re just a few clicks away from streamlining your aircraft management.
                </p>
                <ol className='mt-12'>
                  { steps.map((step, index) => {
                    return <li key={index} className={`text-brand-pale font-bold text-lg px-8 ${step.active ? 'font-bold' : 'font-medium opacity-50'}`}>
                      <div className='flex gap-2 items-center'>
                        <div className={`w-6 h-6 rounded-full flex-shrink-0 ${step.complete ? 'bg-brand-pale' : 'border-4 border-brand-pale'}`}></div>
                        <span className={`text-wrap`}>{step.title}</span>
                      </div>
                      <span className='pl-[9px] my-[-10px]'>{ index+1 === steps.length ? '' : '|'}</span>
                    </li>
                  } )}
                </ol>
              </div>
              <span className="text-brand-pale absolute bottom-8 left-0 w-full text-center text-xs">
                © {new Date().getFullYear()} Coflyt. All rights reserved.
              </span>
            </div>
            <div className="flex flex-wrap items-center justify-center md:w-2/3 rounded md:p-16 bg-white rounded-r px-6 mb-8 md:mb-0">{getStep(formStep)}</div>
          </div>
        </SignUpContext.Provider>
      </main>
      <IntercomChat />
    </>
  );
};

export default SignUp;
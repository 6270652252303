import { useEffect } from 'react';
import Logo from 'assets/icons/logo_navbar_w.svg';
import { ResetPasswordDocument, SendResetEmailDocument } from 'graphql/generated';
import Button from 'components/Button/Button';
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FullFormikInput } from 'components/Form/StandardForm';
import * as Yup from 'yup';
const requestValues = {
  email: '',
};

const resetValues = {
  password: '',
  passwordConfirmation: '',
};

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase character')
    .matches(/[0-9]/, 'Must contain at least one number')
    .required('Required'),
  passwordConfirmation: Yup.string().required('Required'),
});


const ResetPassword = (props) => {
  const navigate = useNavigate();
  const { id, token, type } = useParams();
  const [sendEmail, { data: { sendPasswordResetEmail } = { sendPasswordResetEmail: undefined }, error }] = useMutation(
    SendResetEmailDocument,
    {}
  );
  const [resetPasswordMut, { data: { resetPassword } = { resetPassword: undefined }, error: resetError }] =
    useMutation(ResetPasswordDocument);

  useEffect(() => {
    if (error && error.graphQLErrors) alert(error.graphQLErrors[0].message);
  }, [error]);

  useEffect(() => {
    if (resetError && resetError.graphQLErrors) alert(resetError.graphQLErrors[0].message);
  }, [resetError]);

  return (
    <div className="flex flex-col justify-center items-center h-full w-[28rem] min-h-[100vh] md:border-x border-brand-pale border-dashed relative">
      <div className="flex justify-center w-full min-w-[100vw] md:border-y border-brand-pale border-dashed absolute py-8">
        <div className="w-full rounded mdborder border-slate-300 hover-shadow border-b-4 min-h-[100vh] md:min-h-0 md:w-96 pt-8 md:pt-5 bg-white">
          {!token ? (
            <Formik
              initialValues={requestValues}
              onSubmit={async (values: typeof requestValues) => {
                await sendEmail({ variables: { email: values.email } }).catch(console.error);
              }}>
              {({ isSubmitting }) => (
                <Form className="">
                  <div
                    id="ribbon"
                    className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 -ml-2 bg-brand relative border-b-2 border-r-1 border-t border-t-brand border-[#162551] rounded-r w-full">
                    <img alt="logo" className="w-32 py-2" src={Logo} />
                  </div>
                  <div className="p-10">
                    <div className="flex flex-col mb-4">
                      <span className="text-2xl font-bold text-brand">Forgot your password?</span>
                      <span className="md:text-sm text-slate-500">We can email you a reset link.</span>
                    </div>
                    <FullFormikInput name="email" type="email" label="Email" />
                    <div className="flex items-center justify-between mt-6">
                      <Link to={type === 'mro' ? '/mro/auth/' : '/auth/'} className="underline text-brand font-semibold text-sm">
                        Go Back
                      </Link>
                      <Button
                        text={!!sendPasswordResetEmail ? 'Email Sent!' : 'Send Email'}
                        color="navy"
                        size="sm"
                        type="submit"
                        disabled={isSubmitting || !!sendPasswordResetEmail}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={resetValues}
              validationSchema={ResetPasswordSchema}
              onSubmit={async (values: typeof resetValues) => {
                const { data, errors } = await resetPasswordMut({ variables: { id, token, password: values.password } });
                if (errors) alert(errors[0]);
                if (data) navigate('/mro/auth');
              }}
              validate={(values: typeof resetValues) => {
                if (values.password !== values.passwordConfirmation) return { passwordConfirmation: 'Passwords must match' };
              }}>
              {({ isSubmitting }) => (
                <Form className="">
                  <div
                    id="ribbon"
                    className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 -ml-2 bg-brand relative border-b-2 border-r-1 border-t border-t-brand border-[#162551] rounded-r w-full">
                    <img alt="logo" className="w-32 py-2" src={Logo} />
                  </div>
                  <div className="p-10">
                    <div className="flex flex-col mb-4">
                      <span className="text-2xl font-bold text-brand">Enter your new password</span>
                      <span className="md:text-sm text-slate-500">
                        Must be at least 8 characters and contain one uppercase and one number.
                      </span>
                    </div>
                    <FullFormikInput name="password" type="password" label="New Password" autoComplete="new-password" required />
                    <FullFormikInput
                      name="passwordConfirmation"
                      type="password"
                      label="Confirm Password"
                      autoComplete="new-password"
                      required
                    />
                    <div className="flex items-center justify-between mt-6">
                      <Link to={'/mro/auth/'} className="underline text-brand font-semibold text-sm">
                        Go Back
                      </Link>
                      <Button text="Save" color="navy" size="sm" type="submit" disabled={isSubmitting || !!resetPassword} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

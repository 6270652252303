import { useQuery, useMutation } from '@apollo/client';
import Button from 'components/Button/Button';
import Flyout, { FlyoutHookReturn, useFlyout } from 'components/Flyout/Flyout';
import { GetMroPartOrderDocument, UpdateMroPartOrderDocument } from 'graphql/generated';
import ReceivePartOrderItem from '../Components/ReceivePartOrderItem';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';

export function Receive({ partOrderId, closeFlyout }: { partOrderId: string; closeFlyout: FlyoutHookReturn['closeFlyout'] }) {
  const { data: { mroPartOrder } = {} } = useQuery(GetMroPartOrderDocument, { variables: { id: partOrderId } });
  const [updatePartOrder] = useMutation(UpdateMroPartOrderDocument, { refetchQueries: [GetMroPartOrderDocument] });
  const { flyoutProps, closeFlyout: closeImport, updateFlyout } = useFlyout();
  const [showAlert, setShowAlert] = useState(false);
  const updatePartOrderStatus = () => {
    updatePartOrder({
      variables: {
        input: {
          id: partOrderId,
          status: 'RECEIVED',
        },
      },
    })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  const markOrderAsReceived = () => {
    if (mroPartOrder.mroPartOrderItems.find((item) => item.status !== 'RECEIVED')) {
      setShowAlert(true);
    } else {
      updatePartOrderStatus();
    }
  };
  return (
    <>
      <Flyout {...flyoutProps} />
      {showAlert && <Modal message={'Not all items have been received'} cancel={setShowAlert} accept={updatePartOrderStatus} />}
      <div className="p-4">
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-4">
          <div className="flex-initial mb-4">
            {mroPartOrder?.mroPartOrderItems?.length > 0 && (
              <>
                <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3">
                  <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Items</span>
                </div>
                {mroPartOrder?.mroPartOrderItems?.map((item, index) => {
                  return <ReceivePartOrderItem key={item.id} closeFlyout={closeImport} {...{ updateFlyout, item, index, partOrderId }} />;
                })}
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end items-center mt-4 gap-2">
          <Button text="Mark Order as Received" color="navy" size="xs" onClick={markOrderAsReceived} />
        </div>
      </div>
    </>
  );
}

import { Formik, Form, Field } from 'formik';
import Button from 'components/Button/Button';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { CreateMroEmployeeDocument, GetMroRolesDocument } from 'graphql/generated';
import { useSession } from 'contexts';

const NewEmployeeSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  mroRoleId: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  costRate: Yup.number().required('Required'),
  billableRate: Yup.number().required('Required'),
});

const initialValues = { firstName: '', lastName: '', email: '', phone: '', mroRoleId: '', costRate: '0.00', billableRate: '0.00' };

const NewEmployee = function ({ closeFlyout }: { closeFlyout: () => void}){
  const { user } = useSession();
  const [createEmployee] = useMutation(CreateMroEmployeeDocument, {
    refetchQueries: ['GetMroEmployees'],
  });

  const handleSubmit = ({ mroRoleId, ...values }: typeof initialValues) => {
    createEmployee({
      variables: {
        input: {
          mroOrganization: { connect: { id: user.mroOrganizationId } },
          title: roles.find((role) => role.id === mroRoleId)?.title,
          mroRole: { connect: { id: mroRoleId } },
          ...values,
          email: values.email.toLowerCase(),
        },
      },
    }).then(() => closeFlyout()).catch(console.error);
  };

  const { data: { mroOrganization } = {} } = useQuery(GetMroRolesDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
  const roles = mroOrganization?.mroRoles;

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} validationSchema={NewEmployeeSchema} onSubmit={handleSubmit}>
        <Form>
          {/* Employee INFORMATION */}
          <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
            <div className="flex gap-1">
              <FullFormikInput name="firstName" label="First Name" />
              <FullFormikInput name="lastName" label="Last Name" />
            </div>
            <FullFormikInput name="email" label="Email" type="email" />
            <FullFormikInput name="phone" label="Phone #" />
            <FullFormikSelect name="mroRoleId" label="Role">
              <option value="" className="text-brand/80" disabled>
                Select a Role
              </option>
              {roles?.map((role) => (
                <option value={role.id} key={role.id}>
                  {role.title}
                </option>
              ))}
            </FullFormikSelect>
            <div className="flex gap-1">
              <div className="w-1/2">
                <span className="text-sm font-medium text-brand">Cost Rate</span>
                <div className="flex items-center">
                  <div className="flex items-center justify-center rounded-l p-2 border border-slate-300 border-r-0 w-9 font-semibold text-sm">
                    $
                  </div>
                  <Field
                    type="number"
                    name="costRate"
                    step="0.01"
                    className="rounded-r border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full strip-style"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <span className="text-sm font-medium text-brand">Billable Rate</span>
                <div className="flex items-center">
                  <div className="flex items-center justify-center rounded-l p-2 border border-slate-300 border-r-0 w-9 font-semibold text-sm">
                    $
                  </div>
                  <Field
                    type="number"
                    name="billableRate"
                    step="0.01"
                    className="rounded-r border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full strip-style"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center mt-4">
            <Button text="Submit" color="navy" size="sm" type="submit" />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default NewEmployee;

import { Cache, useMutation, useQuery } from '@apollo/client';
import { GetCaslRulesDocument, GetMroCaslRulesDocument, GetOrgSubtypeDocument, GetTimezoneDocument, LogoutDocument, MainLoginDocument, SetSelectCraftDocument } from 'graphql/generated';
import { parseJwt } from 'index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AbilityContext } from './AbilityContext';
import { createMongoAbility } from '@casl/ability';
import { OrgSubtypes, OrgTypes } from 'utils/orgTypes';


export type SessionUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mroOrganizationId: string;
  mroEmployeeProfileId: string;
  userOrganizationProfileId: string;
  selectedCraftId: string;
  role: string;
  timezone: string;
  legacyId?: string;
};

const SessionContext = React.createContext<{
  craftId: string | null;
  setCraftId: ((id: string) => Promise<void>) | null;
  organizationId: string | null;
  setOrganizationId: React.Dispatch<React.SetStateAction<string>> | null;
  organizationType: OrgTypes | null;
  setOrganizationType: React.Dispatch<React.SetStateAction<OrgTypes>> | null;
  organizationSubtype: OrgSubtypes | null;
  user: SessionUser | null;
  logout: (() => Promise<void>) | null;
  login: ((email: string, password: string, impersonate? : boolean) => Promise<any>) | null;
  setLoginSession: ( (accessToken: string, refreshToken: string) => Promise<any> ) | null;
  selectedTask: any | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<object>> | null;
  timerActive: boolean | null;
  setTimerActive: React.Dispatch<React.SetStateAction<boolean>> | null;
}>({
  craftId: null,
  setCraftId: null,
  organizationId: null,
  setOrganizationId: null,
  organizationType:null,
  setOrganizationType:null,
  organizationSubtype:null,
  user: null,
  logout: null,
  login: null,
  setLoginSession: null,
  selectedTask: null,
  setSelectedTask: null,
  timerActive: null,
  setTimerActive: null,
});

export const SessionProvider = ({ children }) => {
  const [selectedTask, setSelectedTask] = useState<any>();
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [craftId, setCraftId] = useState( localStorage.getItem('craftId') && localStorage.getItem('craftId') !== 'null' ? localStorage.getItem('craftId') : null); 
  const [organizationId, setOrganizationId] = useState(localStorage.getItem('organizationId') || null);
  const [organizationType, setOrganizationType] = useState( localStorage.getItem('organizationType') as OrgTypes || null);
  const [logoutMut, { client }] = useMutation(LogoutDocument);
  const [loginMut] = useMutation(MainLoginDocument);
  const [selectCraftMut] = useMutation(SetSelectCraftDocument);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('coflytAccessToken'));
  const { data: { me: { timezone } } = { me: {timezone: ''} } } = useQuery( GetTimezoneDocument );
  const user = useMemo<SessionUser>(() => {
    let jwt: any;
    try {
      jwt = parseJwt(accessToken);
    } catch (e) {}
    if (jwt)
      return {
        id: jwt.userId,
        firstName: jwt.firstName,
        lastName: jwt.lastName,
        email: jwt.email,
        mroOrganizationId: jwt.mroOrganizationId,
        mroEmployeeProfileId: jwt.mroEmployeeProfileId,
        userOrganizationProfileId: jwt.userOrganizationProfileId,
        selectedCraftId: jwt.selectedCraftId,
        role: jwt.role,
        timezone: timezone ?? jwt.timezone,
        legacyId: jwt.legacyId,
      };
  }, [accessToken, timezone]);
  const { data: { caslRules : mroCaslRules } = { caslRules: JSON.parse(localStorage.getItem('mroCaslRules')) ?? [] }, refetch : mroRefetch } = useQuery(GetMroCaslRulesDocument, { skip: !user?.mroEmployeeProfileId } );
  const { data: { caslRules } = { caslRules: JSON.parse(localStorage.getItem('caslRules')) ?? [] }, refetch } = useQuery(GetCaslRulesDocument, { skip: !user?.userOrganizationProfileId } ); 
  const { data } = useQuery(GetOrgSubtypeDocument, {
    variables: { organizationId },
    skip: organizationId === null,
  });
  
  const organizationSubtype =
    data?.organization?.organizationType ??
    localStorage.getItem('organizationSubtype') ??
    OrgSubtypes.FREE;
  useEffect(() => {
    if (mroCaslRules) localStorage.setItem('mroCaslRules', JSON.stringify(mroCaslRules));
  }, [mroCaslRules]);

  useEffect(() => {
    if (caslRules) localStorage.setItem('caslRules', JSON.stringify(caslRules));
  }, [caslRules]);

  useEffect(() => {
    localStorage.setItem('craftId', craftId === 'null' ? null : craftId);
  }, [craftId]);

  useEffect(() => {
    localStorage.setItem('organizationSubtype', organizationSubtype === 'null' ? null : organizationSubtype);
  }, [organizationSubtype]);

  useEffect(() => {
    const oldId = localStorage.getItem('organizationId');
    localStorage.setItem('organizationId', organizationId === 'null' ? null : organizationId);
    if (oldId !== organizationId) {
      localStorage.setItem('craftId', null);
    }
    if(user?.userOrganizationProfileId) refetch();
  }, [organizationId]);

  useEffect(() => {
    localStorage.setItem('organizationType', organizationType?.toString());
  }, [organizationType]);

  useEffect(() => {
    localStorage.setItem('organizationSubtype', organizationSubtype?.toString());
  }, [organizationSubtype]);

  const login = useCallback(
    async (email, password, impersonate? : boolean) => {
      try {
        const { data: res } = await loginMut({ variables: { email, password, impersonate } });
        if(res.login?.redirectToken){
          localStorage.setItem('coflytLegacyLogin', 'true');
          window.location.replace(process.env.REACT_APP_LEGACY_API_URL + "legacy_login_redirect?email=" + email + "&token=" + encodeURIComponent(res.login?.redirectToken));
          return { organizationId: 'redirecting'};
        };

        localStorage.setItem('coflytRefreshToken', res.login.refreshToken);
        localStorage.setItem('coflytAccessToken', res.login.accessToken.split('.').slice(0, 2).join('.'));
        setAccessToken(res.login.accessToken.split('.').slice(0, 2).join('.'));
        const jwt = parseJwt(res.login.accessToken);
        setCraftId(jwt?.selectedCraftId);
        localStorage.setItem('craftId', jwt?.selectedCraftId);
        if(jwt.mroOrganizationId){ mroRefetch(); } // if mroOrganizationId is present, reload the roles
        if(jwt.userOrganizationProfileId){ refetch(); } // if userOrganizationProfileId is present, reload the roles
        return {
          id: jwt.userId,
          firstName: jwt.firstName,
          lastName: jwt.lastName,
          email: jwt.email,
          organizationId: jwt.organizationId,
          mroOrganizationId: jwt.mroOrganizationId,
          mroEmployeeProfileId: jwt.mroEmployeeProfileId,
          userOrganizationProfileId: jwt.userOrganizationProfileId,
          role: jwt.role,
          timezone: jwt.timezone,
          legacyId: jwt.legacyId
        };
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [loginMut]
  );

  const logout = useCallback(async () => {
    setCraftId(null);
    setOrganizationId(null);
    localStorage.clear();
    setAccessToken(null);
    await logoutMut();
    await client.clearStore();
    await client.cache.reset();
  }, [logoutMut, client]);

  const setLoginSession = useCallback(async (aToken, refreshToken) => {
    localStorage.setItem('coflytRefreshToken', refreshToken);
    localStorage.setItem('coflytAccessToken', aToken.split('.').slice(0, 2).join('.'));
    setAccessToken(aToken.split('.').slice(0, 2).join('.'));
    const jwt = parseJwt(aToken);
    setCraftId(jwt?.selectedCraftId);
    localStorage.setItem('craftId', jwt?.selectedCraftId);

    if(jwt.mroOrganizationId){ mroRefetch(); } // if mroOrganizationId is present, reload the roles
    if(jwt.userOrganizationProfileId){ refetch(); } // if userOrganizationProfileId is present, reload the roles
    return {
      id: jwt.userId,
      firstName: jwt.firstName,
      lastName: jwt.lastName,
      email: jwt.email,
      organizationId: jwt.organizationId,
      mroOrganizationId: jwt.mroOrganizationId,
      mroEmployeeProfileId: jwt.mroEmployeeProfileId,
      userOrganizationProfileId: jwt.userOrganizationProfileId,
      role: jwt.role,
      timezone: jwt.timezone,
      legacyId: jwt.legacyId
    };
  }, []);

  const setSelectedCraft = useCallback(
    async (id) => {
      setCraftId(id);
      localStorage.setItem('craftId', id);
      if (!id) return;
      selectCraftMut({ variables: { craftId: id } });
    } , [selectCraftMut]
  );


  const value = {
    craftId,
    setCraftId: setSelectedCraft,
    organizationId,
    setOrganizationId,
    organizationType,
    setOrganizationType,
    user,
    logout,
    login,
    setLoginSession,
    selectedTask,
    setSelectedTask,
    timerActive,
    setTimerActive,
    organizationSubtype: (organizationSubtype as OrgSubtypes),
  };
  
  let caslRulesArray = [...(mroCaslRules ?? []), ...(caslRules ?? [])];
  
  const ability = createMongoAbility(caslRulesArray);

  return (
    <SessionContext.Provider value={value}>
      <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

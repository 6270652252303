import { FullFormikInput, FullFormikSelect, IconDropdown } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { Form, Formik, FormikHelpers } from 'formik';
import { GetCategoryDetailsDocument, GetMroWorkOrderDocument, UpdateCategoryDocument } from 'graphql/generated';
import { useMutation, useQuery } from '@apollo/client';

const initialValues = {
  title: '',
  iconName: 'Cog6ToothIcon',
  component: '',
  make: '',
  model: '',
  year: '',
  serialNumber: '',
  categoryNumber: 0,
  name: '',
};

export default function EditCategoryFlyout({ categoryId, closeFlyout }: { categoryId: string; closeFlyout?: () => void }) {
  const { data: { mroWorkOrderItemCategory: category } = {} } = useQuery(GetCategoryDetailsDocument, { variables: { id: categoryId } });
  const [mutate] = useMutation(UpdateCategoryDocument, {
    refetchQueries: [
      { query: GetMroWorkOrderDocument, variables: { id: category?.mroWorkOrderId } },
      { query: GetCategoryDetailsDocument, variables: { id: categoryId } },
    ],
  });

  const components = category?.mroWorkOrder?.mroCraft?.mroComponents ?? [];

  const handleSubmit = function (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) {
    setSubmitting(true);
    mutate({
      variables: {
        input: {
          id: categoryId,
          title: values.title,
          iconName: values.iconName,
          categoryNumber: values.categoryNumber,
          ...( values.component && { mroComponent:
            values.component === 'new'
              ? {
                  create: {
                    mroCraft: { connect: { id: category?.mroWorkOrder?.mroCraft?.id } },
                    name: values.name,
                    make: values.make,
                    model: values.model,
                    year: parseInt(values.year, 10),
                    serialNumber: values.serialNumber,
                  },
                }
              : { connect: { id: values.component } } 
            }),
        },
      },
    }).then(() => {
      setSubmitting(false);
      closeFlyout();
    });
  };

  return (
    <>
      <div className="p-4">
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
            title: category?.title ?? '',
            iconName: category?.iconName ?? 'Cog6ToothIcon',
            component: category?.mroComponent?.id ?? '',
            categoryNumber: category?.categoryNumber ?? 0,
          }}
          onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col w-full border rounded shadow bg-white py-10">
                <div className="flex px-10 gap-2">
                  <IconDropdown label={true} iconName={values.iconName} setIconName={(name: string) => setFieldValue('iconName', name)} />
                  <FullFormikInput name="title" label="Name" />
                  <FullFormikInput name="categoryNumber" type='number' label="Category #" className="min-w-[1rem]" />
                </div>
                <div className="flex px-10 gap-2">
                  <FullFormikSelect name="component" label="Component" className={'w-full'}>
                    <option value="">None</option>
                    {components?.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.model} ({component.serialNumber})
                      </option>
                    ))}
                    <option value="new">New Component</option>
                  </FullFormikSelect>
                </div>
                {values.component === 'new' && (
                  <>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="name" label="Name" className="w-full" />
                    </div>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="make" label="Make" className="w-1/2" />
                      <FullFormikInput name="model" label="Model" className="w-1/2" />
                    </div>
                    <div className="flex px-10 gap-2">
                      <FullFormikInput name="year" label="Year" className="w-1/2" />
                      <FullFormikInput name="serialNumber" label="Serial No." className="w-1/2" />
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-end items-center mt-4">
                <Button text="Save Changes" color="navy" size="sm" type="submit" disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

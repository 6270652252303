import { formatOrderNumber } from 'utils/formatter';

//Example invocation
//<WorkOrderNumber workOrderNum={1} code={'CLT'} />

function WorkOrderNumber({ workOrderNum, code }) {

  return (
    <span className={`${workOrderNum ? 'bg-brand-pale' : 'bg-slate-200' } text-brand text-sm font-medium px-2.5 py-0.5 rounded-lg`}>
      {formatOrderNumber(workOrderNum, code)}
    </span>
 
  );
}

export default WorkOrderNumber;

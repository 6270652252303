import { useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import { useSession } from "contexts";
import { GetMroPartWarehouseDocument, GetMroWorkOrderTemplatesDocument } from "graphql/generated";
import { useMemo } from "react";
import { formatApiDate } from "utils/formatter";
import NewWarehouseFlyout from "./NewWarehouseFlyout";
import UpdateWarehouseFlyout from "./UpdateWarehouseFlyout";

export default function PartSettings() {
    const { user } = useSession();
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const { data: { mroPartWarehouses } = {} } = useQuery(GetMroPartWarehouseDocument, { variables: { input: { mroOrganizationId: { equals: user?.mroOrganizationId } } } });
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                className: 'w-2/3',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-1/3',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
 
    return (
        <>
            <Flyout {...flyoutProps} />
            <div className="flex">
                <form className="flex flex-col w-full gap-2 p-10 rounded-l">
                    <span className="font-bold text-xs text-brand uppercase mb-6 border-b py-0.5 -mx-4 pl-4">Warehouse Name</span>
                    <Table columns={columns} 
                        className="w-full bg-white border rounded"
                        data={mroPartWarehouses ?? []}
                        onRowClick={(row) => updateFlyout({ title: row.name, content: <UpdateWarehouseFlyout mroPartWarehouse={row} closeFlyout={closeFlyout} /> })} />
                    <div className="flex justify-end">
                        <Button text="Add Warehouse" color="navy" size="xs" onClick={() => updateFlyout({ title: 'New Warehouse', content: <NewWarehouseFlyout closeFlyout={closeFlyout} /> }) } />
                    </div>
                </form>
            </div>
        </>
    )
}
import { useState, useMemo } from 'react';
import { Table } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { GetMroSerilizedPartsDocument } from 'graphql/generated';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import { webFormat } from 'utils/statuses';
import SerializedInventoryFlyout from './View';

const SerializedInventory = function ({
  updateFlyout,
  partId,
  closeFlyout,
}: {
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  partId?: string;
  closeFlyout: () => void;
}) {
  const [activeTab, setActiveTab] = useState('Available');
  const tabs = ['Available', 'Installed', 'All'];
  const { data: { mroSerializedParts = [] } = {} } = useQuery(GetMroSerilizedPartsDocument, {
    variables: { where: { mroPartId: { equals: partId } } },
  });
  const tableData = useMemo(() => {
    switch (activeTab) {
      case 'All':
        return mroSerializedParts;
      case 'Available':
        return mroSerializedParts?.filter((ser) => ser.isAvailable === true);
      case 'Installed':
        return mroSerializedParts?.filter((ser) => ser.isAvailable === false);
    }
  }, [activeTab, mroSerializedParts]);
  const columns = useMemo(
    () => [
      {
        Header: 'Serial #',
        accessor: 'serialNumber',
        className: 'w-3/12',
        Cell: ({ value, row }: any) => {
          return <span className="font-bold">{value}</span>;
        },
      },
      {
        Header: 'Warehouse',
        accessor: 'mroPartWarehouse',
        className: 'w-1/6',
        Cell: ({ value, row }: any) => {
          return <span className="font-semibold">{value?.name ?? 'N/A'}</span>;
        },
      },
      {
        Header: 'Condition',
        accessor: 'condition',
        className: 'w-1/6',
        Cell: ({ value, row }: any) => {
          return webFormat(value);
        },
      },
      {
        Header: 'Available',
        accessor: 'isAvailable',
        className: 'w-1/6',
        Cell: ({ value, row }: any) => {
          return <span>{value ? 'Yes' : 'No'}</span>;
        },
      },
      {
        Header: 'Bin',
        accessor: 'bin',
        className: 'w-1/6',
        Cell: ({ value, row }: any) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: 'Unit Price',
        accessor: 'unitPrice',
        className: 'justify-end w-1/6',
        Cell: ({ value, row }: any) => {
          return <span>{value ? '$' + Number(value).toFixed(2) : ''}</span>;
        },
      },
    ],
    []
  );
  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between items-start border border-slate-200 pt-1">
        <div className="flex justify-between w-full">
          <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
        </div>
      </div>
      <div className="bg-white border border-slate-200">
        <Table
          columns={columns}
          data={tableData}
          onRowClick={(obj) =>
            updateFlyout({
              title: `${obj.isAvailable ? 'Edit ' : ''}Serialized Inventory`,
              content: <SerializedInventoryFlyout closeFlyout={closeFlyout} partInventoryId={obj.id} />,
            })
          }
        />
      </div>
    </div>
  );
};

export default SerializedInventory;

import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
    CreateMroPartWarehouseDocument,
    GetMroPartWarehouseDocument,
    GetMroWorkOrderPartDocument,
    GetMroWorkOrderPartsDocument,
} from 'graphql/generated';
import { useSession } from 'contexts';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import { TrashIcon } from 'assets/icons';

const NewWarehouseFlyout = function ({ closeFlyout } : 
    { closeFlyout: () => void }) {

    const { user } = useSession();
    const [createMroPartWarehouse] = useMutation(CreateMroPartWarehouseDocument, { refetchQueries: [{ query: GetMroPartWarehouseDocument, variables:{ input: { mroOrganizationId: { equals: user?.mroOrganizationId } } } }] });

    const handleSubmit = async function (values) {
        await createMroPartWarehouse({
            variables: {
                input: {
                    name: values.name,
                    mroOrganization:{
                        connect:{
                            id: user?.mroOrganizationId
                        }
                    }
                },
            },
        });
        closeFlyout();
    }
    return (
        <>
            <div className="p-4">
                <Formik initialValues={{
                    name: '',
                }} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form>
                        {/* NEW WAREHOUSE */}
                        <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                            <div className="flex gap-1">
                                <FullFormikInput name="name" label="Name" />
                            </div>
                        </div>
                        <div className="flex justify-end items-center mt-4 gap-2">
                            <Button text="Create" color="navy" size="sm" type="submit" />
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        </>
    );
};

export default NewWarehouseFlyout;

import { useQuery } from '@apollo/client';
import SegmentControl from 'components/SegmentControl/SegmentControl';
import { useSession } from 'contexts';
import { GetReportInfoDocument } from 'graphql/generated';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, MapRef, Source } from 'react-map-gl';
import isMobileApp from 'utils/isMobileApp';

export default function FlightReports() {
    const { organizationId } = useSession();
    const [selectedTab, setSelectedTab] = useState('All Time');
    const { data: { reportInfo } = {} } = useQuery( GetReportInfoDocument, { variables: { id: organizationId, timeframe: selectedTab } } );
    const mapRef = useRef<MapRef>(null);
    const dashboardStats = [{ title: 'Flight Time', value: reportInfo?.flightTime }, { title: 'Flights', value: reportInfo?.flights }, { title: 'X/C Miles (est.)', value: reportInfo?.xcMiles }, { title: 'XC Flight Time', value: reportInfo?.xcFlightTime}];
    const [mapLoaded, setMapLoaded] = useState(false);
    const routes = useMemo(
      () => {  
        return (<Source id={"route_layer"} type="geojson" data={{
            type: "FeatureCollection",
            features: reportInfo?.routes?.slice(0, -1)?.map((route, index) => {
              const nextRoute = reportInfo?.routes[index+1];
              return {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [Number(route?.long), Number(route?.lat)],
                        [ Number(nextRoute?.long), Number(nextRoute?.lat)]
                    ]
                }
              }
            })
          }}>
            <Layer
              id="lineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round"
              }}
              paint={{
                "line-width": 5,
                "line-color": "#007cbf"
              }}
            />
          </Source>)
      },
      [reportInfo]
    );
    const pins = useMemo(
      () =>  
      (<Source id="points" type="geojson" data={{
        type: "FeatureCollection",
        features: reportInfo?.routes?.map((route, index) => {
          return {
            type: "Feature",
            properties: {
              title: route?.name
            },
            geometry: {
                type: 'Point',
                coordinates: [ Number(route?.long), Number(route?.lat) ]
            }
          }
        })
      }}>
        <Layer
          id="points"
          type="symbol"
          source="my-data"
          layout={{
            "icon-image": "airport-15",
            "text-field": "{title}",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
          }}
          paint={{
            "text-color": "white"
          }}
        />
      </Source>),
      [reportInfo]
    );
    mapRef.current?.on('load', () => {
      setMapLoaded(true);
    });
    useEffect(() => {
      if(reportInfo && mapLoaded){
        mapRef.current?.setCenter([Number(reportInfo?.currentLocation.long), Number(reportInfo?.currentLocation.lat)]);
      }
    }, [mapRef, reportInfo, mapLoaded]);
    return (
        <div>
          <SegmentControl items={['All Time', 'This Year', 'Last Year']} activeItem={selectedTab} onChange={setSelectedTab} className="w-64" />
          <div className="flex flex-row items-center justify-between flex-wrap">
            {dashboardStats.map((stat, index) => (
              <div key={index} className="p-1 w-1/2 lg:w-1/4">
                <div className={`flex flex-col justify-center items-center text-white bg-brand py-4 rounded`}>
                  <div className="flex flex-col justify-center items-center">
                    <span className="">{stat.title}</span>
                    <span className="text-xl font-bold">{stat.value}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <Map
              ref={mapRef}
              mapLib={import('mapbox-gl')}
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                longitude: -100,
                latitude: 40,
                zoom: 3
              }}
              style={{width: '100%', height: 600}}
              dragPan={true}
              mapStyle="mapbox://styles/tiagom87/cjznxb4pt02zt1cqio6t4ph46"
            >
              {routes}
              {pins}
            </Map>
          </div>
        </div>
    );
}
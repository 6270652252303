import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { getMroCustomer, ServiceFormContext } from '..';
import { useMutation } from '@apollo/client';
import { CreateCraftDocument } from 'graphql/generated';
import { FullFormikInput } from 'components/Form/StandardForm';

const initialValues = {
  ownerName: '',
  make: '',
  model: '',
  year: '',
  homeBase: '',
  engineCount: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
};

const CraftInfo = (props) => {
  const {
    prevStep,
    formStep,
    changeStep,
    setCraftInput,
    tailNumber,
    tailInfo,
    setTailInfo,
    user,
    organization,
    mroOrganization,
    setCraftId,
  } = useContext(ServiceFormContext);
  const [prefill, setPrefill] = useState(initialValues);
  const [createCraft] = useMutation(CreateCraftDocument);

  useEffect(() => {
    if (tailInfo) {
      setPrefill({
        ownerName: tailInfo?.name,
        make: tailInfo?.manufacturer,
        model: tailInfo?.model,
        year: tailInfo?.yearMfr?.toString() ?? '',
        homeBase: '',
        engineCount: tailInfo?.engineCount.toString(),
        address: tailInfo?.street,
        address2: tailInfo?.street2,
        city: tailInfo?.city,
        state: tailInfo?.state,
        postalCode: tailInfo?.zipCode,
        country: tailInfo?.country,
      });
    }
  }, [tailInfo]);
  return (
    <Formik
      initialValues={prefill}
      enableReinitialize
      onSubmit={async (values) => {
        const input = {
          name: values.make + ' ' + values.model,
          make: values.make,
          model: values.model,
          year: parseInt(values.year),
          engineCount: parseInt(values.engineCount),
          homeBase: values.homeBase.toUpperCase(),
          tailNumber,
        };
        setCraftInput(input);
        setTailInfo({
          id: tailInfo?.id,
          onAccount: true,
          nNumber: tailNumber,
          name: values.ownerName,
          manufacturer: values.make,
          model: values.model,
          yearMfr: values.year,
          street: values.address,
          street2: values.address2,
          city: values.city,
          state: values.state,
          zipCode: values.postalCode,
          country: values.country,
          engineCount: parseInt(values.engineCount),
        });
        if (user && organization) {
          const { data } = await createCraft({
            variables: {
              craft: {
                ...input,
                organization: {
                  connect: {
                    id: organization.id,
                  },
                },
              },
            },
          });
          setCraftId(data.createCraft.id);
          if (!!getMroCustomer(organization, mroOrganization?.id)) {
            changeStep('craftSelect', 'issues');
          } else {
            changeStep('craftSelect', 'contactInfo');
          }
        } else {
          changeStep(formStep, 'signup');
        }
      }}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="w-96">
          <div className="flex flex-col">
            <span className="text-3xl md:text-2xl font-bold text-brand">Let's confirm some details</span>
            <p className="md:text-sm text-slate-500">
              We have tried to fill out most of the information below from your FAA registration. Please check it for accuracy.
            </p>
          </div>
          <div className="flex items-center justify-between p-2 w-full bg-brand-pale my-6 rounded border-brand/30 border">
            <div className="flex items-center">
              <InformationCircleIcon className="h-4 w-4 mr-1 text-brand-electric opacity-50" />
              <span className="text-sm text-brand font-semibold">Data loaded from tail number</span>
            </div>
            <span className="text-xs font-bold bg-brand-electric/20 text-brand rounded px-2 py-1">{tailInfo?.nNumber}</span>
          </div>
          <div className="flex gap-1">
            <div className="flex w-1/6 flex-col">
            <FullFormikInput name="year" label='Year' required />
            </div>
            <div className="flex w-2/6 flex-col">
              <FullFormikInput name="make" label='Make' required />
            </div>
            <div className="flex w-3/6 flex-col">
            <FullFormikInput name="model" label='Model' required />
            </div>
          </div>
          <div className="flex gap-1">
            <div className="flex w-3/6 flex-col">
              <label className="text-sm font-medium text-brand">Home Airport Code</label>
              <Field name="homeBase" className={props.fieldCss + ' text-transform: uppercase'} required></Field>
              <ErrorMessage name="homeBase" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex w-3/6 flex-col">
              <label className="text-sm font-medium text-brand">Engine Count</label>
              <Field name="engineCount" className={props.fieldCss} required></Field>
              <ErrorMessage name="engineCount" component="div" className="text-red-500 text-xs italic" />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-brand">Owner Name</label>
            <Field name="ownerName" className={props.fieldCss}></Field>
            <ErrorMessage name="ownerName" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-brand">Address</label>
            <Field placeholder="Line 1" className={props.fieldCss} name="address"></Field>
            <ErrorMessage name="address" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex flex-col -mt-2">
            <Field placeholder="Line 2" className={props.fieldCss} name="address2"></Field>
            <ErrorMessage name="address2" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex gap-1">
            <div className="flex w-3/6 flex-col">
              <label className="text-sm font-medium text-brand">City</label>
              <Field className={props.fieldCss} name="city"></Field>
              <ErrorMessage name="city" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex flex-col w-1/6">
              <label className="text-sm font-medium text-brand">State</label>
              <Field className={props.fieldCss} name="state"></Field>
              <ErrorMessage name="state" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex w-2/6 flex-col">
              <label className="text-sm font-medium text-brand">Postal Code</label>
              <Field className={props.fieldCss} name="postalCode"></Field>
              <ErrorMessage name="postalCode" component="div" className="text-red-500 text-xs italic" />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="underline text-brand font-semibold text-sm"
              onClick={() => changeStep(formStep, prevStep[prevStep.length - 1], true)}>
              Go Back
            </button>
            <Button text="Next" color="navy" size="sm" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CraftInfo;

import { useState, useMemo } from 'react';
import { Table } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { GetMroPartInventoriesDocument } from 'graphql/generated';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import InventoryFlyout from './View';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import { webFormat } from 'utils/statuses';

const Inventory = function ({
  updateFlyout,
  closeFlyout,
  partId,
}: {
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  partId?: string;
}) {
  const [activeTab, setActiveTab] = useState('In Stock');
  const tabs = ['In Stock', 'Out Of Stock', 'All'];
  const { user } = useSession();
  const { data: { mroPartInventories = [] } = {} } = useQuery(GetMroPartInventoriesDocument, {
    variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId }, ...(partId && { mroPartId: { equals: partId } }) } },
  });
  const tableData = useMemo(() => {
    switch (activeTab) {
      case 'All':
        return mroPartInventories;
      case 'In Stock':
        return mroPartInventories?.filter((inv) => inv.quantity > 0);
      case 'Out Of Stock':
        return mroPartInventories?.filter((inv) => inv.quantity === 0);
    }
  }, [mroPartInventories, activeTab]);

  const columns = useMemo(
    () => [
      ...(!partId
        ? [
            {
              Header: 'Part #',
              accessor: 'mroPart.partNumber', // accessor is the "key" in the data
              className: 'w-1/6',
              Cell: ({ value, row }: any) => {
                return <span className="font-bold bg-slate-100 text-brand-dark rounded px-2 py-1">{value}</span>;
              },
            },
            {
              Header: 'Description',
              accessor: 'mroPart.description',
              className: 'w-2/6',
              Cell: ({ value, row }: any) => {
                return <span className="font-medium underline">{value}</span>;
              },
            },
          ]
        : [
            {
              Header: 'Warehouse',
              accessor: 'mroPartWarehouse',
              className: 'w-1/6',
              Cell: ({ value, row }: any) => {
                return <span className="font-bold">{value?.name ?? 'N/A'}</span>;
              },
            },
            {
              Header: 'Condition',
              accessor: 'condition',
              className: 'w-1/6',
              Cell: ({ value, row }: any) => {
                return webFormat(value);
              },
            },
            {
              Header: 'Bin',
              accessor: 'bin',
              className: 'w-1/6',
              Cell: ({ value, row }: any) => {
                return <span>{value}</span>;
              },
            },
          ]),
      {
        Header: 'Qty.',
        accessor: 'quantity',
        className: 'justify-end w-1/6',
        Cell: ({ value, row }: any) => {
          return (
            <span className="text-slate-500">
              <span className="select-none">x</span>
              {value}
            </span>
          );
        },
      },
      {
        Header: 'Lot #',
        id: 'lotNumber',
        accessor: 'lotNumber',
        className: 'justify-end w-1/6',
        Cell: ({ value, row }: any) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: 'Unit Price',
        accessor: 'unitPrice',
        className: 'justify-end w-1/6',
        Cell: ({ value, row }: any) => {
          return <span>{value ? '$' + Number(value).toFixed(2) : ''}</span>;
        },
      },
    ],
    [partId]
  );

  return (
    <>
      <div className="flex flex-col justify-between items-start border-y border-slate-200 pt-1 bg-slate-50 -mt-2">
        <div className="flex justify-between w-full">
          <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
          {!partId && (
            <div className="flex gap-5">
              {/* PART TYPE */}
              <div className="flex gap-3 my-auto pb-[0.5]">
                <h2 className="text-left text-sm text-brand-dark pt-1">Type:</h2>
                <select
                  id="partType"
                  name="partType"
                  className="h-7 py-1 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm text-sm border-slate-300 rounded-md">
                  <option>Standard</option>
                  <option>Serizlized</option>
                </select>
              </div>
              {/* WAREHOUSE LOCATION */}
              <div className="flex gap-3 my-auto pb-[0.5]">
                <h2 className="text-left text-sm text-brand-dark pt-1">Warehouse:</h2>
                <select
                  id="warehouse"
                  name="warehouse"
                  className="h-7 py-1 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm text-sm border-slate-300 rounded-md">
                  <option>All</option>
                  <option>Warehouse 1</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`bg-white border-slate-200 ${partId && 'border'}`}>
        <Table
          columns={columns}
          data={tableData}
          onRowClick={(obj) =>
            updateFlyout({ title: 'Edit Part Inventory', content: <InventoryFlyout closeFlyout={closeFlyout} partInventoryId={obj.id} /> })
          }
        />
      </div>
    </>
  );
};

export default Inventory;

import { PlusIcon } from 'assets/icons';
import { PaperAirplaneIcon, TrashIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import { format } from 'date-fns';
import { useMemo, useState, useEffect, useCallback } from 'react';

function EmailItem( { email, name, enabled, onChange, onDelete, finalItem, temp }: 
  { email: string; name: string; enabled: boolean; onChange: (key: string, value: any) => void; onDelete: () => void; finalItem?: boolean; temp?: boolean } ) {
  return (
    <div className={`flex items-center justify-between ${ !finalItem && 'border-b-2 border-dashed'} p-2 cursor-pointer`} onClick={() =>{
      onChange("enabled", !enabled);
    }}>
      <div className='flex items-center gap-10'>
        <input
          type="checkbox"
          checked={enabled}
          onChange={(e) => {}}
          className="mt-1 rounded h-3.5 w-3.5 mr-0.5"
        />
        <div className="flex flex-col">
          {temp ? (
            <input type="text" 
              className="font-semibold border border-slate-300 px-1 text-sm rounded lg:w-64 bg-white h-12 md:h-10" 
              placeholder="email@test.com"
              value={email}
              onClick={(e) => e.stopPropagation() }
              onChange={(e) => onChange('email', e.target.value) }  />
          ) : (
            <>
              <div className="font-semibold">{name}</div>
              <div className="text-slate-400">{email}</div>
            </>
          ) }
        </div>
      </div>
      { temp && <TrashIcon
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        title="Delete"
        className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
      /> }
    </div>
  );
}

export default function SendEmailFlyout({
  primaryContact,
  allContacts,
  submit,
  closeFlyout,
  ...props
}: {
  primaryContact: any;
  allContacts: any;
  submit: ( emails: string[] ) => void;
  closeFlyout: () => void;
}) {
  const [emails, setEmails] = useState([
    { email:primaryContact?.email, name: primaryContact?.firstName + " " + primaryContact?.lastName, enabled: true },
    ...( allContacts.filter((contact) => contact.email !== primaryContact.email).map(
      (contact) => { return { email: contact.email, name: contact.firstName + " " + contact.lastName, enabled: false } } ) ?? [] ),
  ]);
  return (
    <>
    <div className="p-4 max-h-[95%] flex flex-col pb-20">
      <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-2">
        <div className="flex-initial mb-4 p-2">
          {emails.map((entry, index) => {            
            return (
              <EmailItem
                key={index}
                email={entry.email}
                name={entry.name}
                enabled={entry.enabled}
                finalItem={index === emails.length - 1}
                temp={entry.temp}
                onDelete={() => {
                  setEmails((em) => {
                    let mutable = [...em];
                    mutable.splice(index, 1);
                    return mutable;
                  });
                }}
                onChange={(key, value) => {
                  setEmails((em) => {
                    let mutable = [...em];
                    mutable[index][key] = value;
                    return mutable;
                  });
                }}
              />
            );
          } )}
        </div>
        <div className="flex items-center justify-between border-t border-slate-200 rounded-b py-3 px-4 text-sm -mx-4">
          <button
            onClick={() => setEmails((em) => [...em, { email:'', name: '', enabled: true, temp: true }])}
            className="flex shrink-0 items-center bg-white text-brand-dark border border-slate-300 text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
            <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
            Add Email
          </button>
        </div>
      </div>
      <div className="flex justify-end my-2 pb-10">
        <Button
          color="navy"
          size="xs"
          text="Send Emails"
          icon={<PaperAirplaneIcon className="h-3 w-3 mr-1" />}
          className='disabled:opacity-50'
          disabled={emails.filter((email) => email.enabled).length === 0}
          onClick={() => { 
            closeFlyout(); 
            submit(emails.filter((email) => email.enabled).map((email) => email.email));
          }}
        />
      </div>
    </div>
  </>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import {
  InspectionGroupCreateInput,
  CreateInspectionGroupDocument,
  GetInspectionsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';

const New = function ( { closeFlyout } : { closeFlyout: () => void } ) {
  const { craftId } = useSession();
  const [createInspectionGroup] = useMutation(CreateInspectionGroupDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }] });
  const initialValues = {
    name: '',
    description: ''
  }; 
  const [prefill, setPrefill] = useState(initialValues);


  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const inspectionInput: InspectionGroupCreateInput = {
      ...values,
      craft: { connect: { id: craftId } },
    };
    await createInspectionGroup({ variables: { input: inspectionInput } });
    setSubmitting(false);
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form className='p-2'>
          <Card>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="flex flex-col w-full">
                <FullFormikInput name="name" label="Name" autoComplete="off" />
                <FullFormikInput name="description" label="Description" autoComplete="off" />
              </div>
            </div>
          </Card>
          <div className="px-4 mt-4 flex items-center justify-end">
            <Button text="Create Group" color="navy" size="xs" type='submit' disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default New;

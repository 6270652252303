import { useSession } from 'contexts';
import { ReactNode, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { OrgTypes } from 'utils/orgTypes';
import { useIntercom } from 'react-use-intercom';

export default function Part145Layout({ children }: { children?: ReactNode }) {
  const { user, organizationType } = useSession();
  const navigate = useNavigate();
  const { shutdown } = useIntercom();
  useEffect(() => {
    if (!user) {
      navigate('/mro/auth');
    }
    if(organizationType === OrgTypes.PART91){ //kick out any users who are not PART145
      navigate('/crafts');
    }
  }, [user, navigate]);
  shutdown();

  return (
    <>
      <Sidebar>
        <main className={`flex flex-col items-center justify-center`}>
          <div className="w-full max-w-7xl relative p-4 2xl:border-x border-dashed border-brand-pale mt-2">
            <Outlet />
          </div>
        </main>
      </Sidebar>
    </>
  );
}

import { useQuery, useMutation } from '@apollo/client';
import { DeleteMroWorkOrderItemDocument, GetMroWorkOrderDocument, GetMroWorkOrderItemDocument, RequestAllWorkOrderItemsPartsDocument, SignoffStatus, UpdateMroWorkOrderItemDocument } from 'graphql/generated';
import { useState, useEffect, useContext } from 'react';
import { InformationCircleIcon, ClipboardDocumentListIcon, ReceiptRefundIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Button from 'components/Button/Button';
import NewPartFlyout from './NewPartFlyout';
import NewLaborFlyout from './NewLaborFlyout';
import { LaborSection } from './partials/LaborSection';
import Signoff from './partials/Signoff';
import DetailsTopEdit from './partials/DetailsTopEdit';
import DetailsTop from './partials/DetailsTop';
import { CogIcon, LockClosedIcon, PencilIcon } from '@heroicons/react/24/solid';
import SignoffEdit from './partials/SignOffEdit';
import History from './partials/History';
import { PartsSection } from './partials/PartsSection';
import { AbilityContext } from 'contexts/AbilityContext';
import Modal from 'components/Modal/Modal';

const signOffStatusOrder = [SignoffStatus.Unassigned, SignoffStatus.AwaitingSignoff, SignoffStatus.Signed];

export default function WorkOrderItem({ itemId, context, closeFlyout }: { itemId: string; context?: string; closeFlyout?: () => void }) {
  const { flyoutProps, updateFlyout } = useFlyout();
  /* FORM HANDLING SECTION */
  const [currentSection, setCurrentSection] = useState('details');
  const [editToggled, toggleEdit] = useState(false);
  const { data: { mroWorkOrderItem } = {}, refetch, previousData } = useQuery(GetMroWorkOrderItemDocument, {
    skip: itemId === null,
    variables: { id: itemId },
    pollInterval: 300000, // 5 minutes
  });
  const [updateMroWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } }],
  });
  const [deleteMroWorkOrderItem] = useMutation(DeleteMroWorkOrderItemDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: mroWorkOrderItem?.mroWorkOrder?.id } }],
    onCompleted: () => {
      closeFlyout();
    }
  });
  const [requestAllWorkOrderItemsParts] = useMutation(RequestAllWorkOrderItemsPartsDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } }]
  });
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [enableEntry, toggleEntry] = useState(false);
  const [entryText, setEntryText] = useState('');
  const [workPerformed, setWorkPerformed] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [conflict, setConflict] = useState('');
  const ability = useContext(AbilityContext);
  useEffect(() => {
    if (mroWorkOrderItem) {
      toggleEntry(!!mroWorkOrderItem?.enableLogEntry);
      setEntryText(mroWorkOrderItem?.logEntry);
      setWorkPerformed(mroWorkOrderItem?.description);
    }
  }, [mroWorkOrderItem]);
  const disableWorkPerformed = mroWorkOrderItem?.mroWorkOrderSignoffs?.length > 0 && !mroWorkOrderItem?.mroWorkOrderSignoffs.find((signOff) => signOff.status !== SignoffStatus.Signed);
  const partContext = mroWorkOrderItem?.mroWorkOrder.isEstimate ? 'estimate' : context;
  const locked = mroWorkOrderItem?.status === 'ABORT' || mroWorkOrderItem?.status === 'CUSTOMER_DENIED';

  return (
    <>
      { showModal && ( 
        <Modal
        message={`You are about to request all parts for this squawk.`}
        accept={() => requestAllWorkOrderItemsParts({ variables: {  id: itemId  } })}
        cancel={setShowModal}
        />
      ) }
      { conflict && ( 
        <Modal
        message={`The description of work was updated recently, would you like to overwrite with your changes?`}
        submessage={`Your changes: ${conflict}`}
        submessage2={`Update Changes: ${mroWorkOrderItem?.description}`}
        accept={() => updateMroWorkOrderItem({ variables: { input: { id: itemId, description: conflict } } })}
        cancel={setConflict}
        />
      ) }
      { showDeleteModal && ( 
        <Modal
        message={`You are about to delete this squawk. (This will delete any subtasks associated with this squawk)`}
        accept={() => deleteMroWorkOrderItem({ variables: { id: itemId } }) }
        cancel={setShowDeleteModal}
        />
      ) }
      <Flyout {...flyoutProps} />
      <div className="flex justify-between items-center border-b border-slate-200 px-4 pt-1 bg-white sticky top-10 z-10">
        <div className="flex justify-between w-full">
          <div className="flex items-start">
            <button
              className="flex items-center gap-1 text-sm p-2 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
              onClick={() => {
                toggleEdit(false);
                setCurrentSection('details');
              }}
              disabled={currentSection === 'details'}>
              <InformationCircleIcon className="h-4 w-4" />
              Details
            </button>
            <>
              <button
              className="flex items-center gap-1 text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
              onClick={() => {
                toggleEdit(false);
                setCurrentSection('manage');
              }}
              disabled={currentSection === 'manage'}>
              <ClipboardDocumentListIcon className="h-4 w-4" />
              Manage
            </button>
            <button
              className="flex items-center gap-1 text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold relative disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
              onClick={() => {
                toggleEdit(false);
                setCurrentSection('parts');
              }}
              disabled={currentSection === 'parts'}>
              <CogIcon className="h-5 w-5" />
              Parts
              {mroWorkOrderItem?.mroWorkOrderParts?.filter((item) => item.status === 'READY_FOR_PICKUP').length > 0 && (
                <span className="absolute flex h-3 w-3 right-0 top-0">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-brand"></span>
                </span>
              )}
            </button>
            {context !== 'dashboard' && (
              <button
                className="flex items-center gap-1 text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
                onClick={() => {
                  toggleEdit(false);
                  setCurrentSection('logEntry');
                }}
                disabled={currentSection === 'logEntry'}>
                <BookOpenIcon className="h-4 w-4" />
                Log Entry
              </button>
            )}
            </>
          </div>
          {/* {context !== 'dashboard' && (
            <button
              className="flex items-center gap-1 text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
              onClick={() => {
                setCurrentSection('history');
              }}
              disabled={currentSection === 'history'}>
              <ReceiptRefundIcon className="h-4 w-4" />
              History
            </button>
          )} */}
        </div>
      </div>
      {currentSection === 'details' && (
        <div className="flex flex-col gap-2 p-4">
          {editToggled ? (
            <DetailsTopEdit
              mroWorkOrderItem={mroWorkOrderItem}
              itemId={itemId}
              selectedProfiles={selectedProfiles}
              setSelectedProfiles={setSelectedProfiles}
              refetch={refetch}
              context={context}
              toggleEdit={toggleEdit}
            />
          ) : (
            <DetailsTop
              mroWorkOrderItem={mroWorkOrderItem}
              toggleEdit={toggleEdit}
              context={context}
              locked={locked}
              setSelectedProfiles={setSelectedProfiles}
            />
          )}
          <LaborSection updateFlyout={updateFlyout} flyoutProps={flyoutProps} itemId={itemId} workOrderId={mroWorkOrderItem?.mroWorkOrder?.id} locked={locked} context={context ?? 'workOrder'} />
          {context !== 'dashboard' && !locked && (
            <div className="flex justify-end gap-2">
              <Button
                text="Add Labor Entry"
                color="navy"
                size="xs"
                onClick={() =>
                  updateFlyout({ title: 'Add Labor Entry', content: <NewLaborFlyout workOrderItemId={itemId} isFlatRate={mroWorkOrderItem?.isFlatRate} {...flyoutProps} /> })
                }
              />            
            </div>
          )}
          { mroWorkOrderItem?.mroWorkOrder?.isEstimate && <div className="flex justify-end gap-2 py-8">
            <Button
              text="Delete Squawk"
              color="red"
              size="sm"
              onClick={() => { 
                setShowDeleteModal(true);
              }}
            /> 
          </div> }
        </div>
      )}
      {currentSection === 'manage' && (
        <section className="flex flex-col gap-2 p-4">
          <div className='flex items-center'>
            <h1 className="font-bold">Description of Work Performed</h1>  
            { (disableWorkPerformed || locked) && (<LockClosedIcon className="h-4 w-4 ml-2" />)}
          </div>
          <div className="flex flex-col gap-2 bg-white border border-slate-200 rounded p-4 mb-4">
            <textarea
              value={workPerformed}
              disabled={disableWorkPerformed || locked}
              onChange={(e) => setWorkPerformed(e.target.value)}
              placeholder="Description of Work Performed..."
              onBlur={async () => { 
                const result = await refetch();
                if (result.data.mroWorkOrderItem.updatedAt !== mroWorkOrderItem.updatedAt && result.data.mroWorkOrderItem.description !== mroWorkOrderItem.description) {
                  setConflict(workPerformed);
                }else{
                  updateMroWorkOrderItem({ variables: { input: { id: itemId, description: workPerformed } } });
                }
              }}
              className="rounded border p-2 text-sm bg-slate-50 border-slate-200 disabled:bg-slate-200 focus:bg-white placeholder:text-slate-400 w-full"></textarea>
          </div>
          <div className="flex justify-between p-2 border-b border-slate-100">
            <h1 className="font-bold">Required Signoffs</h1>
            {ability.can("Manage", "WO Signoffs") && !locked ? (
              <PencilIcon
                onClick={() => toggleEdit(true)}
                className={`h-6 w-7 p-1 transition-opacity bg-slate-100 rounded hover:opacity-70 cursor-pointer ${
                  editToggled && 'invisible'
                }`}
              />
            ) : (
              <LockClosedIcon className="h-6 w-7 p-1 bg-slate-100 rounded" />
            )}
          </div>
          { editToggled ? (
            <SignoffEdit mroWorkOrderItem={mroWorkOrderItem} itemId={itemId} toggleEdit={toggleEdit} />
          ) : (
            <div className="flex flex-col gap-2 bg-white border border-slate-200 rounded p-4">
              {mroWorkOrderItem?.mroWorkOrderSignoffs?.length > 0 && (
                <>
                  {mroWorkOrderItem.mroWorkOrderParts.find((part) => part.status !== 'INSTALLED') && (
                    <div className='p-4 flex justify-center'>
                      <LockClosedIcon className="h-4 w-4 ml-2" />
                      <h2 className="font-bold text-sm text-center">Unresolved Parts still on Squawk</h2>
                    </div>
                  )}
                  {mroWorkOrderItem.mroLaborEntries.find((labor) => labor.endTime === null) && (
                    <div className='p-4 flex justify-center'>
                      <LockClosedIcon className="h-4 w-4 ml-2" />
                      <h2 className="font-bold text-sm text-center">Active Labor still on Squawk</h2>
                    </div>
                  )}
                  {mroWorkOrderItem?.description === '' && (
                    <div className='p-4 flex justify-center'>
                      <LockClosedIcon className="h-4 w-4 ml-2" />
                      <h2 className="font-bold text-sm text-center">Cannot Leave Description of Work Blank</h2>
                    </div>
                  )}
                </>
              )}
             
              {mroWorkOrderItem?.mroWorkOrderSignoffs?.length > 0 ? (
                [...mroWorkOrderItem?.mroWorkOrderSignoffs]
                  .sort((a, b) => signOffStatusOrder.indexOf(a.status) - signOffStatusOrder.indexOf(b.status))
                  .map((signoff, index) => {
                    return (
                      <Signoff
                        key={index}
                        id={signoff.id}
                        title={signoff.title}
                        status={signoff.status}
                        date={signoff.date}
                        locked={ 
                          !!mroWorkOrderItem.mroWorkOrderParts.find((part) => part.status !== 'INSTALLED') ||
                          !!mroWorkOrderItem.mroLaborEntries.find((labor) => labor.endTime === null) ||
                          mroWorkOrderItem.description === '' ||
                          locked
                        }
                        assignee={signoff.assignee}
                        allSignoffs={mroWorkOrderItem.mroWorkOrderSignoffs}
                        mroWorkOrderItemId={itemId}
                      />
                    );
                  })
              ) : (
                <h2 className="font-bold text-sm text-center p-4">No Signoff Required</h2>
              )}
            </div>
          )}
        </section>
      )}
      {currentSection === 'logEntry' && (
        <section className="flex flex-col gap-2 p-4">
          <div className="flex justify-between">
            <h1 className="font-bold">Log Entry</h1>
            { locked ? <LockClosedIcon className="h-6 w-7 p-1 bg-slate-100 rounded" /> :
              <input
                checked={enableEntry}
                onChange={(e) => {
                  toggleEntry(!enableEntry);
                  updateMroWorkOrderItem({ variables: { input: { id: mroWorkOrderItem.id, enableLogEntry: !enableEntry } } });
                }}
                className="h-4 w-4 focus:ring-0 rounded"
                type="checkbox"
              />
            }
            
          </div>
          <div className="flex flex-col gap-2 bg-white border border-slate-200 rounded p-4 mb-4">
            {enableEntry ? (
              <textarea
                value={entryText}
                disabled={locked}
                onChange={(e) => setEntryText(e.target.value)}
                placeholder="Log entry details..."
                onBlur={(e) => updateMroWorkOrderItem({ variables: { input: { id: itemId, logEntry: entryText } } })}
                className="rounded border p-2 text-sm bg-slate-50 border-slate-200 focus:bg-white placeholder:text-slate-400 w-full"></textarea>
            ) : (
              <h2 className="font-bold text-sm text-center">Log Entry Disabled</h2>
            )}
          </div>
        </section>
      )}
      {currentSection === 'parts' && (
        <section className="flex flex-col gap-2 p-4">
          {mroWorkOrderItem?.status === 'COMPLETED' && (
            <div className='p-4 flex justify-center'>
              <LockClosedIcon className="h-4 w-4 ml-2" />
              <h2 className="font-bold text-sm text-center">Cannot add/edit parts on Completed Squawks</h2>
            </div>
          )}
          <PartsSection updateFlyout={updateFlyout} flyoutProps={flyoutProps} itemId={itemId} locked={locked || mroWorkOrderItem?.status === 'COMPLETED'} context={ partContext ?? 'workOrder'} />
          <div className="flex justify-end gap-2">
            { !mroWorkOrderItem.mroWorkOrder.isEstimate && mroWorkOrderItem.mroWorkOrderParts.find((part) => part.status === 'DRAFT') && <Button
                text="Request All Parts"
                color="blue"
                disabled={locked || mroWorkOrderItem?.status === 'COMPLETED'}
                size="xs"
                onClick={() => setShowModal(true) } 
            /> }
            <Button
              text="Add Part"
              color="navy"
              size="xs"
              disabled={locked || mroWorkOrderItem?.status === 'COMPLETED'}
              onClick={() =>
                updateFlyout({ title: 'Add Part', content: <NewPartFlyout context={partContext} workOrderItemId={itemId} workOrderId={mroWorkOrderItem?.mroWorkOrder?.id} {...flyoutProps} /> })
              }
            />
          </div>
        </section>
      )}
      {currentSection === 'history' && <History mroWorkOrderItem={mroWorkOrderItem} />}
    </>
  );
}

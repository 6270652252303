import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_1191)">
        <path
          d="M15.2 2H13.2V0H11.2V2H9.19995V0H7.19995V2H5.19995V0H3.19995V2H1.19995C0.646951 2 0.199951 2.447 0.199951 3V15C0.199951 15.553 0.646951 16 1.19995 16H15.2C15.753 16 16.2 15.553 16.2 15V3C16.2 2.447 15.753 2 15.2 2ZM14.2 14H2.19995V6H14.2V14Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_1191">
          <rect width="16" height="16" fill="white" transform="translate(0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

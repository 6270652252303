import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.20001 16C7.9348 16 7.68044 15.8946 7.49291 15.7071C7.30537 15.5196 7.20001 15.2652 7.20001 15V1C7.20001 0.734784 7.30537 0.48043 7.49291 0.292893C7.68044 0.105357 7.9348 0 8.20001 0C8.46523 0 8.71958 0.105357 8.90712 0.292893C9.09466 0.48043 9.20001 0.734784 9.20001 1V15C9.20001 15.2652 9.09466 15.5196 8.90712 15.7071C8.71958 15.8946 8.46523 16 8.20001 16Z"
        fill="currentColor"
      />
      <path
        d="M5.20001 13C4.9348 13 4.68044 12.8946 4.49291 12.7071C4.30537 12.5196 4.20001 12.2652 4.20001 12V4C4.20001 3.73478 4.30537 3.48043 4.49291 3.29289C4.68044 3.10536 4.9348 3 5.20001 3C5.46523 3 5.71958 3.10536 5.90712 3.29289C6.09466 3.48043 6.20001 3.73478 6.20001 4V12C6.20001 12.2652 6.09466 12.5196 5.90712 12.7071C5.71958 12.8946 5.46523 13 5.20001 13Z"
        fill="currentColor"
      />
      <path
        d="M2.20001 10C1.9348 10 1.68044 9.89464 1.49291 9.70711C1.30537 9.51957 1.20001 9.26522 1.20001 9V7C1.20001 6.73478 1.30537 6.48043 1.49291 6.29289C1.68044 6.10536 1.9348 6 2.20001 6C2.46523 6 2.71958 6.10536 2.90712 6.29289C3.09466 6.48043 3.20001 6.73478 3.20001 7V9C3.20001 9.26522 3.09466 9.51957 2.90712 9.70711C2.71958 9.89464 2.46523 10 2.20001 10Z"
        fill="currentColor"
      />
      <path
        d="M11.2 13C10.9348 13 10.6804 12.8946 10.4929 12.7071C10.3054 12.5196 10.2 12.2652 10.2 12V4C10.2 3.73478 10.3054 3.48043 10.4929 3.29289C10.6804 3.10536 10.9348 3 11.2 3C11.4652 3 11.7196 3.10536 11.9071 3.29289C12.0947 3.48043 12.2 3.73478 12.2 4V12C12.2 12.2652 12.0947 12.5196 11.9071 12.7071C11.7196 12.8946 11.4652 13 11.2 13Z"
        fill="currentColor"
      />
      <path
        d="M14.2 10C13.9348 10 13.6804 9.89464 13.4929 9.70711C13.3054 9.51957 13.2 9.26522 13.2 9V7C13.2 6.73478 13.3054 6.48043 13.4929 6.29289C13.6804 6.10536 13.9348 6 14.2 6C14.4652 6 14.7196 6.10536 14.9071 6.29289C15.0947 6.48043 15.2 6.73478 15.2 7V9C15.2 9.26522 15.0947 9.51957 14.9071 9.70711C14.7196 9.89464 14.4652 10 14.2 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;

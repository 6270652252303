import Button from 'components/Button/Button';
import EmployeeSelect from '../../WorkOrderItems/EmployeeSelect';
import { useState } from 'react';

const EditAssignees = function ({ selectedAssignees, setSelectedAssignees, closeFlyout }) {
  const [intermediary, setIntermediary] = useState(selectedAssignees);
  return (
    <>
      <div className="p-4">
        <div className="bg-white shadow-blue p-10 border border-slate-200 rounded">
          <h2 className="text-xl font-bold text-brand">Assign Technicians</h2>
          <span className="text-sm text-slate-500 mb-2 block">Any number of users can be assigned to a work order.</span>
          <EmployeeSelect selectedProfiles={intermediary} setSelectedProfiles={setIntermediary} />
        </div>
        <div className="flex justify-end mt-2 w-full gap-1">
          <Button
            type="button"
            text="Cancel"
            color="white"
            size="sm"
            onClick={() => {
              closeFlyout();
            }}
          />
          <Button
            type="button"
            text="Save Changes"
            color="navy"
            size="sm"
            onClick={() => {
              setSelectedAssignees(intermediary);
              closeFlyout();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditAssignees;

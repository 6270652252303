import { StarIcon } from "@heroicons/react/24/solid";
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import Tabs from "components/Tabs/Tabs";
import { Document, GetDocumentsDocument, UpdateDocumentDocument } from "graphql/generated";
import { useEffect, useMemo, useState } from "react";
import { formatApiDate } from "utils/formatter";
import Edit from "./Edit";
import { useMutation } from "@apollo/client";
import isMobileApp from "utils/isMobileApp";

const getStar = (favorite) => {
  return favorite ? <StarIcon className="h-6 w-6 rounded text-brand-electric" /> : <OutlineStarIcon className="h-6 w-6 rounded text-brand-electric" />;
}
export function DocumentsView( { data, updateFlyout, closeFlyout } : { data: Document[], updateFlyout: (data:any) => void, closeFlyout: () => void, } ) {
    const [ updateDocument ] = useMutation( UpdateDocumentDocument, { refetchQueries: [ GetDocumentsDocument ] });
    const columns = useMemo(
        () => [
          {
            Header: 'Date',
            accessor: 'createdAt', // accessor is the "key" in the data
            className: 'w-1/4',
            Cell: ({ value, row }: any) => {
              return <span className="font-bold bg-slate-100 text-brand-dark rounded px-2 py-1">{formatApiDate(value)}</span>;
            },
          },
          {
            Header: 'File Name',
            id: 'file',
            accessor: (document) => {
              return { name: document.name, favorite: document.favorite, url: document?.attachment?.signedUrl };
            } , // accessor is the "key" in the data
            className: 'ml-4 w-3/4 items-start',
            Cell: ({ value, row }: any) => {
              return <div className='flex w-full justify-between'> 
                <span className="py-1">{value.name}</span>
                <div className='flex gap-6'>
                  { !isMobileApp() && <a href={value.url} onClick={ (e)=> e.stopPropagation() } target="_blank" rel="noreferrer" className='flex h-8 w-8 p-1 rounded bg-brand-pale hover:opacity-70' download>
                    <ArrowDownTrayIcon className="h-6 w-6 rounded text-brand-electric" />
                  </a>}
                  <button 
                    onClick={(e) => { 
                      e.stopPropagation();
                      updateDocument({ variables: { input: { id: row.original.id, favorite: !value.favorite  } } });
                    }}
                    className="h-8 w-8 bg-brand-pale p-1 rounded hover:opacity-70" >
                      { getStar(value.favorite) }
                  </button>
                </div>
              </div>;
            },
          },
        ],
        []
    );
    const [tableData, setTableData] = useState([]);
    const [tab, setTab] = useState('All');
    useEffect(() => {
        if(data) {
            const newDocuments = [...data]?.sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
            if( tab === 'All' ){
                setTableData(newDocuments);
            }else{
                setTableData(newDocuments.filter((document) => document.favorite));
            }
        }
    }, [ tab, data ]);
    return (
        <>
            {/* Body */}
            <div className="flex flex-col w-full">
                <div className="flex justify-between items-center border border-slate-200 pt-1 bg-slate-50 w-full px-4">
                    <Tabs items={ [ 'All', 'Favorites' ] } setActiveItem={setTab} activeItem={tab} />
                </div>
                {/* Tables Document */}
                <div className="p-6 bg-white border rounded border-slate-300 w-full shadow-blue">
                    { tableData.length > 0 ? 
                        <Table columns={columns} 
                            data={tableData} 
                            onRowClick={(row) =>                   
                                updateFlyout({
                                title: <div className='flex gap-3'>
                                    <>
                                    { "Document: "+row.name }
                                    </> 
                                    { getStar(row.favorite) }
                                </div>,
                                content: <Edit id={row.id} closeFlyout={closeFlyout} />  
                        }) } />
                    : <div className="flex justify-center items-center p-4">
                        <h1 className="text-center text-slate-500">No Documents Found</h1>
                    </div> }
                </div>
            </div>
        </>
    );
}
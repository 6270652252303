import { useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import WorkOrderNumber from "components/WorkOrderNumber/WorkOrderNumber";
import { useSession } from "contexts";
import { GetPendingPartRequestsDocument } from "graphql/generated";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { formatApiDate, formatOrderNumber, formatWithZeros } from "utils/formatter";
import SubmitPartsRequest from "views/Part145/WorkOrders/Order/WorkOrderItems/SubmitPartsRequest";

export function PendingPartRequests() {
    const { user } = useSession();
    const [search, setSearch] = useState('');
    const { data: { mroWorkOrderParts } = {} } = useQuery(GetPendingPartRequestsDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const columns = useMemo(
      () => [
        {
          Header: 'Needed By',
          id: 'date',
          accessor: (row) => row.neededDate ?? '', // accessor is the "key" in the data
          className: 'w-2/12 text-slate-500 py-5',
          Cell: ({ value, row }: any) => {
            return <span className="font-semibold">{formatApiDate(value)}</span>;
          },
        },
        {
          Header: () => <div className="mx-[-10px]">Part #</div>,
          id: 'partNumber',
          accessor: (row) => {
            return {
              partNumber: row.partNumber ?? '',
              partDescription: row.partDescription ?? '',
              mroPart: row?.mroPart,
              id: row.id,
            };
          }, // accessor is the "key" in the data
          className: `w-5/12 text-xs text-slate-500 justify-start content-start`,
          Cell: ({ value, row }: any) => {
            if (value) {
              return (
                <div className="mx-[-10px]">
                  <div className="text-brand-dark text-base font-bold bg-slate-100 rounded px-2 py-1">{value.partNumber}</div>
                  <div className="">{value?.mroPart ? value?.mroPart?.description : value?.partDescription ?? ''}</div>
                </div>
              );
            } else {
              return <span className="">-</span>;
            }
          },
        },
        {
          Header: 'Order #',
          accessor: (obj) => {
            return {
              workOrderNum: obj.mroWorkOrder.workOrderNum,
              mroWorkOrderCategory: obj.mroWorkOrder.mroWorkOrderCategory,
            };
          },
          className: 'w-2/12',
          Cell: ({ value }) => {
            return (
              <WorkOrderNumber code={value.mroWorkOrderCategory?.code} workOrderNum={value.workOrderNum} />
            );
          },
        },
        {
          Header: () => <div>Quantity</div>,
          id: 'quantity',
          accessor: (row) => row.quantity ?? '', // accessor is the "key" in the data
          className: 'w-1/12 text-slate-500 justify-end',
          Cell: ({ value, row }: any) => {
            value === '' && (value = '-');
            return <span className="">{value}</span>;
          },
        },
        {
          Header: 'Unit Price',
          id: 'unitPrice',
          accessor: (row) => row.billableRate ?? row.unitPrice, // accessor is the "key" in the data
          className: 'w-1/12 justify-end text-slate-500',
          Cell: ({ value, row }: any) => {
            return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
          },
        },
        {
          Header: 'Total Price',
          id: 'totalPrice',
          accessor: (obj) => obj.billableRate * obj.hours || obj.quantity * obj.unitPrice, // accessor is the "key" in the data
          className: 'w-1/12 justify-center text-slate-500 font-bold',
          Cell: ({ value, row }: any) => {
            return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
          },
        },
      ],
      []
    );
    return (
      <>
        <Flyout {...flyoutProps} />
        <div className=" bg-white shadow-blue">
            <div className="flex flex-col">
                <div className="flex items-center justify-between py-4 px-6">
                    <h1 className="text-brand-dark text-left font-bold text-lg">Awating Approval Part Requests</h1>
                    <div className="flex items-center gap-2">
                        <div className="relative flex items-center">
                            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                            <input
                                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </div>
                        {/* <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" /> */}
                    </div>
                </div>
                <div className="flex flex-wrap pt-1 pb-2 overflow-x-auto">
                    <Table
                    columns={columns}
                    data={mroWorkOrderParts ?? []}
                    className="sm-head w-full"
                    onRowClick={(row) => updateFlyout(
                        {
                          title: 'Edit Part Request',
                          content: (
                            <SubmitPartsRequest workOrderPart={row} setIsOpen={(open)=> !open && closeFlyout() } />
                          )
                        }
                    )}
                    />
                </div>
            </div>
        </div>
      </>
    );
}
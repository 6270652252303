import Paywall from "components/Paywall/Paywall";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { useSession } from "contexts";
import { useState } from "react";
import { OrgSubtypes } from "utils/orgTypes";
import FlightReports from "./Partials/FlightReports";
import FuelOilReports from "./Partials/FuelOilReports";

const Reports = function() {
  const { organizationSubtype } = useSession();
  const [selectedTab, setSelectedTab] = useState('Flights');
  return(
    <>
      {organizationSubtype === OrgSubtypes.FREE ? <Paywall /> : 
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center gap-10">
            <h2 className="text-brand-dark font-bold text-xl">Reports</h2>
            <SegmentControl items={['Flights', 'Fuel & Oil']} activeItem={selectedTab} onChange={setSelectedTab} className="w-64" />
          </div>
          <hr className="my-4"/>
          {/* Dashboard */}
          { selectedTab === 'Flights' && <FlightReports /> }
          { selectedTab === 'Fuel & Oil' && <FuelOilReports /> }
        </div>
      }
    </>
  );
}

export default Reports;
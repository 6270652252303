import React, { useState, useEffect } from 'react';
import { formatFieldDate } from 'utils/formatter';
import { BriefcaseIcon } from '@heroicons/react/24/solid';
import { ChatBubbleLeftRightIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { PlusIcon, ChevronDownIcon, ChevronRightIcon, ArrowsRightLeftIcon, CheckIcon } from '@heroicons/react/24/outline';
import UserIcon from 'components/UserIcon/UserIcon';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetMroWorkOrderDocument,
  UpdateMroWorkOrderDocument,
  NewOrderCustomersQuery,
  UpdateMroWorkOrderItemDocument,
  GetMroWorkOrderCategoriesDocument,
} from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import StatusButton from 'components/StatusButton/StatusButton';
import Button from 'components/Button/Button';
import { Formik, Form, Field } from 'formik';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import Category from '../Components/Category';
import { formatToFixed } from 'utils/formatter';
import EditAssignees from './Components/EditAssignees';
import { useSession } from 'contexts';

const EditContact = function ({ contacts, setSelectedContact, selectedContact, closeFlyout }) {
  const [intermediary, setIntermediary] = useState(selectedContact);
  return (
    <>
      <div className="p-4">
        <div className="bg-white shadow-blue p-10 border border-slate-200 rounded">
          <h2 className="text-xl font-bold text-brand">Select a Contact</h2>
          <span className="text-sm text-slate-500 mb-2 block">Who should receive notifications for this work order?</span>
          <div className="rounded h-full max-h-[16rem] bg-slate-50 overflow-y-auto shadow-inner p-2 border border-slate-300 mt-2">
            <div className="bg-white rounded border border-slate-200 shadow-blue p-2">
              {contacts?.map((contact) => (
                <button
                  key={contact.id}
                  type="button"
                  onClick={(e) => {
                    setIntermediary(contact);
                  }}
                  disabled={intermediary?.id === contact.id}
                  className="flex items-center group grow bg-white text-brand-dark cursor-pointer p-2 mb-1 w-full relative rounded border border-dashed border-white border-b-brand-pale last:border-b-white transition-colors hover:border-brand-pale hover:bg-brand-pale/20 hover:border-solid disabled:border-solid disabled:text-brand-electric disabled:bg-brand-pale disabled:border-brand-electric">
                  <div className="flex">
                    <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale group-disabled:bg-brand-electric group-disabled:text-white border-brand-electric w-9 h-9 rounded mr-2">
                      {contact.firstName[0]}
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="font-bold truncate">{contact.firstName + ' ' + contact.lastName}</span>
                      <span className="truncate text-sm text-slate-500 -mt-1">{contact.email}</span>
                    </div>
                  </div>
                  {intermediary?.id === contact.id && (
                    <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 pointer-events-none" />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2 w-full gap-1">
          <Button
            type="button"
            text="Cancel"
            color="white"
            size="sm"
            onClick={() => {
              closeFlyout();
            }}
          />
          <Button
            type="button"
            text="Save Changes"
            color="navy"
            size="sm"
            onClick={() => {
              closeFlyout();
              setSelectedContact(intermediary);
            }}
          />
        </div>
      </div>
    </>
  );
};

const EditWorkOrder = function () {
  const [initialValues, setInitialValues] = useState({ mroTitle: '', arrivalDate: '', promiseDate: '', primaryConcern: '', notes: '', mroContactName: '', mroContactEmail: '', mroContactPhoneNumber: '' });
  const [contacts, setContacts] = useState([]);
  const [updateWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument);
  const [selectedContact, setSelectedContact] = useState<NewOrderCustomersQuery['mroCustomers'][number]['mroContacts'][number]>();
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('n/a');
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const navigate = useNavigate();
  const { workOrderId } = useParams();
  const { data: { mroWorkOrder } = {} } = useQuery(GetMroWorkOrderDocument, { variables: { id: workOrderId } });
  const { user } = useSession();
  const { data : { mroWorkOrderCategories } =  {} } = useQuery(GetMroWorkOrderCategoriesDocument, { variables: { where: { mroOrganizationId: { equals: user?.mroOrganizationId } } } } );
  const [updateOrder] = useMutation(UpdateMroWorkOrderDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: workOrderId } }],
  });
  const tableData = React.useMemo(
    () =>
      mroWorkOrder?.itemCategories?.reduce((acc, cur) => {
        acc[cur.id] = cur.mroWorkOrderItems.filter((item) => {
          if (item.parentId) return false;
          return true;
        });
        return acc;
      }, {}) ?? {},
    [mroWorkOrder]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        className: 'w-4 relative z-[101]',
        Cell: ({ row }) => {
          return (
            <span className="flex items-center" {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <ChevronDownIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              ) : (
                <ChevronRightIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              )}
            </span>
          );
        },
      },
      {
        Header: 'Squawks',
        accessor: 'title', // accessor is the "key" in the data
        className: 'w-6/12',
        Cell: ({ row, value }) => {
          let childrenNum = row?.original?.children?.length;
          return (
            <button className="text-left font-semibold truncate -mx-4 px-4 -my-3 py-3 " title={value}>
              {value}
              {childrenNum > 0 && <span className="ml-1 text-xs text-slate-500">({childrenNum})</span>}
            </button>
          );
        },
      },
      {
        Header: 'Assignee(s)',
        accessor: 'assignees',
        className: 'w-1/12',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <div className="flex -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                if (!item) return null;
                return (
                  <UserIcon
                    key={index}
                    iconSize="sm"
                    iconText={item.firstName + ' ' + item.lastName}
                    iconId={item.firstName + ' ' + item.lastName}
                    iconColor={item.profileColor}
                    className="border-2 border-slate-50"
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Hours',
        accessor: 'totalLaborHours',
        className: 'w-1/12',
        Cell: ({ row, value }) => {
          return (
            <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">
              {formatToFixed(value)}
              <span className="text-xs text-slate-500 ml-0.5 font-medium">/{row.original.estimatedHours}</span>
            </button>
          );
        },
      },
      {
        Header: 'Billable TOT',
        accessor: 'totalLaborBillable',
        className: 'w-1/12 grow',
        Cell: ({ row, value }) => {
          return <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">{formatToFixed(value)}</button>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'shrink-0 w-48',
        Cell: ({ value, row }: any) => {
          return (
            <StatusButton
              readOnly={false}
              context="task"
              text={value}
              className=""
              onSelect={(status) =>
                updateWorkOrderItem({
                  variables: {
                    input: {
                      id: row.original.id,
                      status,
                    },
                  },
                })
              }
            />
          );
        },
      },
    ],
    [updateWorkOrderItem]
  );

  useEffect(() => {
    setSelectedAssignees(mroWorkOrder?.assignees);
    setContacts(mroWorkOrder?.mroCustomer?.mroContacts);
    setSelectedContact(mroWorkOrder?.primaryContact);
    setInitialValues({
      mroTitle: mroWorkOrder?.title,
      arrivalDate: formatFieldDate(mroWorkOrder?.arrivalDate),
      promiseDate: formatFieldDate(mroWorkOrder?.promiseDate),
      primaryConcern: mroWorkOrder?.primaryConcern,
      notes: mroWorkOrder?.notes,
      mroContactName: mroWorkOrder?.primaryContact?.firstName + ' ' + mroWorkOrder?.primaryContact?.lastName,
      mroContactEmail: mroWorkOrder?.primaryContact?.email,
      mroContactPhoneNumber: mroWorkOrder?.primaryContact?.phoneNumbers?.primary,
    });
    setSelectedCategory(mroWorkOrder?.mroWorkOrderCategory?.id ?? 'n/a');
  }, [mroWorkOrder]);

  useEffect(() => {
    setInitialValues( (prev) => ({
      ...prev,
      mroContactName: selectedContact?.firstName + ' ' + selectedContact?.lastName,
      mroContactEmail: selectedContact?.email,
    }));
  }, [selectedContact]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const mroPromiseDate = values.promiseDate + 'T12:00:00'; // Set to Noon easten time
        const mroArrivalDate = values.arrivalDate + 'T12:00:00'; // Set to Noon easten time
        updateOrder({
          variables: {
            input: {
              id: workOrderId,
              title: values.mroTitle,
              ...(values.arrivalDate ? { arrivalDate: mroArrivalDate } : {}),
              ...(values.promiseDate ? { promiseDate: mroPromiseDate } : {}),
              primaryConcern: values.primaryConcern,
              notes: values.notes,
              assignees: {
                connect: selectedAssignees.map((item) => ({ id: item.id })),
              },
              primaryContact : { 
                connect: { id: selectedContact.id },
              },
              firstName: values.mroContactName.split(' ')[0] ?? '',
              lastName: values.mroContactName.split(' ')[1] ?? '',
              email: values.mroContactEmail ?? '',
              phone: values?.mroContactPhoneNumber ?? '',
              ...( selectedCategory !== 'n/a' ? { mroWorkOrderCategory: {
                connect: { id: selectedCategory },
              } } : {}),
            },
          },
        })
          .then(() => navigate('../'))
          .catch(console.error);
      }}>
      <Form>
        <Flyout {...flyoutProps} />
        <div className="flex items-center justify-between w-full mb-2">
          <h1 className="text-2xl font-bold">Editing</h1>
          <div className="flex gap-2">
            <Button type="button" text="Cancel" color="white" size="xs" onClick={() => navigate('../')} />
            <Button type="submit" text="Save Changes" color="navy" size="xs" />
          </div>
        </div>
        <div className="bg-white border rounded border-slate-300 shadow-blue">
          {/* HEADER SECTION */}
          <div className="flex flex-col">
            <div className="flex rounded-t items-center justify-between p-6">
              <div className="flex items-center w-7/12">
                <BriefcaseIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem] shrink-0" />
                <Field
                  name="mroTitle"
                  className="text-brand-dark text-left font-bold text-2xl bg-slate-50 rounded border border-slate-300 p-1 w-full"></Field>
                <div className='pl-3 mt-[-15px]'>
                  <span className='font-semibold pr-2'>Category:</span>
                  <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value) } className="border border-slate-300 rounded mr-1 mt-[-3px] text-sm">
                    <option value="n/a">N/A</option>
                    {mroWorkOrderCategories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="cursor-not-allowed opacity-70">
                <div className="flex items-stretch pointer-events-none">
                  <StatusButton readOnly={false} context="order" text={mroWorkOrder?.status} className="" onSelect={() => {}} />
                  <div className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded">
                    <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
              <ul className="flex">
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.tailNumber}</h3>
                  <span className="text-xs text-slate-500">TAIL #</span>
                </li>
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.year}</h3>
                  <span className="text-xs text-slate-500">YEAR</span>
                </li>
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.make}</h3>
                  <span className="text-xs text-slate-500">MAKE</span>
                </li>
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.model}</h3>
                  <span className="text-xs text-slate-500">MODEL</span>
                </li>
              </ul>
              <div className="flex">
                <div className="flex-col">
                  <div className="flex justify-end -mr-[2px]">
                    {selectedAssignees?.map((item, index) => {
                      return (
                        <UserIcon
                          key={item.id}
                          iconSize="sm"
                          iconText={`${item.firstName} ${item.lastName}`}
                          iconId={`${item.firstName} ${item.lastName}`}
                          iconColor={item.profileColor}
                          className="border-2 border-slate-50"
                        />
                      );
                    })}
                    <div
                      onClick={() =>
                        updateFlyout({
                          title: 'Manage Assignees',
                          content: (
                            <EditAssignees
                              selectedAssignees={selectedAssignees}
                              setSelectedAssignees={setSelectedAssignees}
                              closeFlyout={closeFlyout}
                            />
                          ),
                        })
                      }
                      className="flex items-center justify-center h-7 w-7 bg-brand-pale text-brand-electric rounded-full border border-dashed border-brand-electric cursor-pointer transition-all relative mb-0.5 hover:text-white hover:bg-brand-electric hover:border-solid">
                      <PlusIcon className="h-4 w-4" />
                    </div>
                  </div>
                  <span className="text-xs text-slate-500 w-full text-right block">ASSIGNEES</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start pt-2 -mb-[1px]">
          <span className="bg-white border-b-white text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300 cursor-pointer shadow-blue-t">
            Overview
          </span>
          <span className="bg-slate-50 text-slate-500 text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300 cursor-not-allowed">
            Billing
          </span>
          <span className="bg-slate-50 text-slate-500 text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300 cursor-not-allowed">
            Request
          </span>
        </div>
        <div className="bg-white border rounded-b rounded-r border-slate-300 shadow-blue">
          {/* USER CARD AND INFO */}
          <div className="flex p-4">
            <div className="flex flex-col w-1/2 border rounded bg-slate-50 p-4">
              <div className="flex items-center mb-3 pb-5 border-b">
                <div
                  className="flex items-stretch group hover-shadow-blue transition">
                  <div className="flex items-center border border-slate-200 -m-1.5 p-1.5 pr-6 bg-white rounded-l">
                    <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale border-brand-electric w-8 h-8 rounded mr-2">
                      {selectedContact?.firstName[0]}
                    </div>
                    <div className="flex flex-col gap-1">
                      <Field
                      name="mroContactName"
                      className="text-lg font-bold bg-slate-50 text-brand-dark rounded border border-slate-300"></Field>
                      <Field
                      name="mroContactEmail"
                      placeholder="test@test.com"
                      className="text-sm bg-slate-50 rounded border border-slate-300"></Field>
                      <Field
                      name="mroContactPhoneNumber"
                      placeholder="+1 (555) 555-5555"
                      className="text-sm bg-slate-50 rounded border border-slate-300"></Field>
                    </div>
                  </div>
                  <ArrowsRightLeftIcon 
                  onClick={() =>
                    updateFlyout({
                      title: 'Manage Contacts',
                      content: (
                        <EditContact
                          contacts={contacts}
                          setSelectedContact={setSelectedContact}
                          selectedContact={selectedContact}
                          closeFlyout={closeFlyout}
                        />
                      ),
                    })
                  }
                  className="h-auto w-7 px-1.5 text-brand-electric rounded-r bg-brand-offwhite group-hover:bg-slate-100 transition border border-slate-200 -my-1.5" />
                </div>

                <div className="flex grow shrink justify-end">
                  <div className="flex font-bold items-center text-brand-electric bg-brand-pale py-2 px-4 rounded text-sm cursor-not-allowed transition-all opacity-70">
                    <ChatBubbleLeftRightIcon className="h-4 w-4 mr-1" /> Chat
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mb-1">
                <label className="w-48 font-semibold text-sm text-slate-500">Arrival Date</label>
                <Field
                  name="arrivalDate"
                  className="rounded border border-slate-200 text-xs font-semibold text-brand-dark"
                  type="date"></Field>
              </div>
              <div className="flex justify-between items-center">
                <label className="w-48 font-semibold text-sm text-slate-500">Promise Date</label>
                <Field
                  name="promiseDate"
                  className="rounded border border-slate-200 text-xs font-semibold text-brand-dark"
                  type="date"></Field>
              </div>
            </div>
            <div className="p-4 w-1/2">
              <div className="flex flex-col">
                <span className="font-semibold text-xs uppercase text-slate-500">Primary Concern</span>
                <FullFormikInput name="primaryConcern" className="text-sm mt-1" />
              </div>
              <div className="flex flex-col -mb-5">
                <span className="font-semibold text-xs text-slate-500 uppercase">Notes</span>
                <FullFormikTextarea name="notes" className="text-sm mt-1" />
              </div>
            </div>
          </div>
          {/* TABLE */}
          <div title="Disabled While Editing" className="relative cursor-not-allowed">
            <div className="flex items-center justify-center w-full h-[calc(100%-1rem)] absolute top-0 left-0 bg-slate-100/50 z-30 backdrop-blur border border-slate-200 shadow-inner"></div>
            <div className="flex justify-between items-center border-y border-slate-200 px-4 pt-1 bg-slate-50">
              <div className="flex items-start">
                <button
                  className="text-sm p-2 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
                  disabled={true}>
                  All
                </button>
                <button
                  className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
                  disabled={false}>
                  In Progress
                </button>
                <button
                  className="text-sm text-slate-500 p-2 md:px-4 mx-1 font-semibold disabled:font-bold disabled:text-brand-electric disabled:border-b-4 disabled:border-brand-electric"
                  disabled={false}>
                  Completed
                </button>
              </div>
            </div>
            <div className="bg-slate-100 shadow-inner rounded-b">
              <div className="flex flex-col p-4">
                {mroWorkOrder?.itemCategories?.map((category) => (
                  <Category
                    key={category?.id}
                    context="page"
                    category={category}
                    updateFlyout={updateFlyout}
                    closeFlyout={closeFlyout}
                    tableData={tableData}
                    mroWorkOrder={mroWorkOrder}
                    columns={columns}
                    updateWorkOrderItem={updateWorkOrderItem}
                    setModal={() => {}}
                  />
                ))}
                {mroWorkOrder?.mroWorkOrderItems?.length === 0 ? (
                  <div className="flex items-center justify-center rounded-b">
                    <button className="flex items-center font-semibold text-brand cursor-pointer text-sm bg-white p-2 rounded border border-slate-200 shadow-blue hover:bg-brand-offwhite hover:border-brand-pale hover:text-brand-electric transition">
                      <PlusIcon className="h-2.5 w-2.5 mr-1" />
                      Create First Squawk
                    </button>
                  </div>
                ) : (
                  <button className="self-end">
                    <span className="text-brand-dark hover:text-brand-electric text-xs font-semibold relative z-20 px-4 py-2 border border-slate-200 hover:border-brand-pale rounded bg-white hover:bg-brand-offwhite transition">
                      + Add Category
                    </span>
                  </button>
                )}
              </div>
              <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full"></div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default EditWorkOrder;

import { useEffect, useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import PartLookup from '../../Components/PartLookup';
import { webFormat } from 'utils/statuses';
import { formatApiDateTrunc, formatFieldDate, formatWithZeros } from 'utils/formatter';
import { PartCondition } from 'graphql/generated';
import { PartConditions } from './PartConditions';

export default function PartOrderItems({ entry, handleItemUpdate, handleRemoveItem, index, edit }) {
  const [checked, setChecked] = useState(entry.active);
  const [isEditable, setIsEditable] = useState(entry.temp && edit);
  const [contents, setContents] = useState({
    part: entry.part,
    partQuantity: entry.partQuantity,
    condition: entry.condition,
    partPrice: Number(entry?.partPrice ?? 0),
    requestedDate: entry.requestedDate,
    notes: entry.notes,
  });

  useEffect(() => {
    let mutable = { ...contents, active: checked };
    handleItemUpdate(index, mutable);
  }, [checked, contents, handleItemUpdate, index]);

  const handleChange = function (e, kind?) {
    let mutable = { ...contents, active: checked };
    if (kind === 'part') {
      mutable[kind] = e;
    } else if (kind === 'partQuantity' || kind === 'partPrice') {
      mutable[kind] = Number(e.target.value);
    } else if (kind) {
      mutable[kind] = e.target.value;
    }
    setContents(mutable);
    handleItemUpdate(index, mutable);
  };
  return (
    <div
      className={`
          ${
            edit && !checked && 'bg-white opacity-50 hover:opacity-100'
          } flex justify-between items-start w-full px-6 border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b transition-all py-3`}>
      <div
        className="flex w-full cursor-pointer -my-3 py-3"
        onClick={() => {
          !isEditable && setChecked(!checked);
        }}>
        {edit && !isEditable && (
          <input
            type="checkbox"
            className="mt-1 rounded h-3.5 w-3.5 -ml-3 mr-0.5 cursor-pointer"
            onChange={(e) => handleChange(e)}
            id={entry.id}
            name={entry.id}
            checked={checked}></input>
        )}
        <div className="flex flex-col w-full">
          {isEditable ? (
            <div className="text-slate-700">
              <div className="flex justify-between items-stretch gap-1">
                <div className="flex w-full flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Part #</label>
                  <PartLookup
                    hideLabel={true}
                    className="h-12 md:h-10"
                    selectedPart={contents.part}
                    setSelectedPart={(part) => handleChange(part, 'part')}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Condition</label>
                  <select
                    value={entry.condition}
                    onChange={(e) => handleChange(e, 'condition')}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-28 h-12 md:h-10 strip-style self-end">
                    <PartConditions />
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">QTY.</label>
                  <input
                    type="number"
                    min={0}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-16 h-12 md:h-10 text-right strip-style self-end"
                    defaultValue={contents.partQuantity}
                    onChange={(e) => handleChange(e, 'partQuantity')}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Unit Price</label>
                  <input
                    type="number"
                    min={0}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-16 h-12 md:h-10 text-right strip-style self-end"
                    defaultValue={contents.partPrice.toFixed(2)}
                    onChange={(e) => handleChange(e, 'partPrice')}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-xs text-slate-700 font-semibold">Notes:</label>
                <textarea
                  rows={4}
                  className="w-full text-xs px-2 leading-tight border p-2 rounded mt-1 border-slate-300 focus:shadow-inner focus:bg-white"
                  defaultValue={contents.notes}
                  onChange={(e) => handleChange(e, 'notes')}></textarea>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-xs text-slate-700 font-semibold self-end">Need By:</label>
                <input
                  type="date"
                  className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-28 h-12 md:h-10 text-right strip-style self-end"
                  defaultValue={formatFieldDate(contents.requestedDate)}
                  onChange={(e) => handleChange(e, 'requestedDate')}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center h-6">
                <div className="flex px-2 flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Part #</label>
                  <h2 className="text-sm relative">{contents?.part?.partNumber ?? ''}</h2>
                </div>
                <div className="flex gap-5">
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">Condition</label>
                    <span className="font-medium px-1 rounded text-sm">{webFormat(contents.condition) ?? 'NEW'}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">QTY.</label>
                    <span className="font-medium px-1 rounded text-sm">{contents.partQuantity}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">Unit Price</label>
                    <span className="font-medium px-1 rounded text-sm">${contents.partPrice.toFixed(2)}</span>
                  </div>
                </div>
              </div>
              {contents.notes && (
                <div className="bg-white border-l-2 border-slate-200 border px-4 py-1 ml-2 my-2 rounded">
                  <span className="text-xs text-brand-dark font-semibold uppercase">Notes</span>
                  <p className="font-normal text-sm text-gray-700 -mt-0.5">{contents.notes}</p>
                </div>
              )}
              <div className="flex justify-between items-center my-3">
                <div className="flex items-center">
                  { entry.workOrderNumber && ( <>
                  <span className="bg-brand-pale text-brand text-sm font-medium px-2.5 py-1 rounded-lg mr-1">
                    {'#' + formatWithZeros(entry.workOrderNumber, 4)}
                  </span>
                  {entry.workOrderTitle} </> )}
                </div> 
                <div className="flex">
                  <label className="text-xs text-slate-700 font-semibold self-end">Need By:</label>
                  <span className="font-medium px-1 rounded text-sm self-end">{formatApiDateTrunc(contents.requestedDate)}</span>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center mt-2 h-6 bg-slate-100">
            <h2 className="text-sm px-2 relative">Subtotal:</h2>
            <span className="font-medium px-1 rounded text-sm">${(contents?.partPrice * contents?.partQuantity)?.toFixed(2) ?? 0}</span>
          </div>
        </div>
      </div>
      {edit && (
        <div className="flex flex-col gap-0.5 ml-2 -mr-2">
          <div className="flex flex-col gap-1 w-full">
            {isEditable ? (
              <PencilSquareIcon
                onClick={() => {
                  if (contents?.part?.partNumber) {
                    setIsEditable(!isEditable);
                  }
                }}
                title="Edit"
                className="bg-brand-electric text-brand-pale w-12 h-6 p-0.5 border border-brand-pale rounded cursor-pointer transition hover:opacity-80"
              />
            ) : (
              <PencilSquareIcon
                onClick={() => {
                  setIsEditable(!isEditable);
                }}
                title="Edit"
                className="text-brand-electric bg-white hover:bg-brand-pale w-12 h-6 p-0.5 cursor-pointer border border-brand-pale rounded transition"
              />
            )}
            {entry?.temp && (
              <TrashIcon
                onClick={() => {
                  handleRemoveItem(index);
                }}
                title="Delete"
                className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { ChangeEventHandler, useEffect, useState } from 'react';
import NewInvoiceItem from './NewInvoiceItem';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { formatUSD } from 'utils/formatter';
import InvoiceItem from './InvoiceItem';
import { invoiceCase, webFormat } from 'utils/statuses';
import { CheckIcon } from 'assets/icons';
import { convertToFee } from 'utils/charge';
import { randomID } from 'utils/random';
import FeeItem from '../../Estimates/partials/FeeItem';
import { format } from 'date-fns';
import { useSession } from 'contexts';
import { useQuery } from '@apollo/client';
import { GetMroOrganizationFeesDocument, GetMroWorkOrderBillingItemsDocument } from 'graphql/generated';


export default function InvoiceView({
  invoice,
  invoiceItems,
  setInvoiceItems,
  edit,
  charges,
  setCharges,
  discounts,
  setDiscounts,
  taxes,
  setTaxes,
  vals,
  setVals,
  status,
  location,
  workOrderId
}: {
  invoice: any;
  invoiceItems: any;
  setInvoiceItems?: (items: any) => void;
  edit: boolean;
  charges: any;
  setCharges?: (charges: any) => void;
  discounts?: any;
  setDiscounts?: (discounts: any) => void;
  taxes?: number;
  setTaxes?: (taxes: number) => void;
  vals?: any;
  setVals?: (vals: any) => void;
  status?: string;
  location?: string;
  workOrderId?: string;
}) {
  const { user } = useSession();
  const { data: { mroOrganizationFees } = {} } = useQuery(GetMroOrganizationFeesDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
  const { data: { mroWorkOrderBillingItems } = {} } = useQuery(GetMroWorkOrderBillingItemsDocument, 
    { variables: { where: { mroWorkOrderId: { equals: workOrderId} } }, skip: !workOrderId });
  const [standardCharges, setStandardCharges ] = useState([]);

  const handleRemoveItem = function (index) {
    let mutable = [...invoiceItems];
    mutable.splice(index, 1);
    setInvoiceItems(mutable);
  };
  const handleItemUpdate = function (index, newItem) {
    let mutable = [...invoiceItems];
    mutable[index] = { ...mutable[index], ...newItem };
    setInvoiceItems(mutable);
    const activeItems = mutable.filter((item) => item.active);
    const laborCost = Math.round(activeItems.reduce((acc, item) => acc + item.laborCost, 0) * 100) / 100;
    const partsCost = activeItems.reduce((acc, item) => acc + item.partsCost, 0);

    setVals({
      ...vals,
      totalParts: partsCost,
      totalLabor: laborCost,
      totalHours: activeItems.reduce((acc, item) => acc + item.laborHours, 0),
    });
    setCharges(
      charges.map((charge) => {
        return {
          ...charge,
          ...(charge.percent !== undefined && { total: convertToFee(laborCost, partsCost, charge) }),
        };
      })
    );
  };

  const handleChange: (field: string) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (field) => (e) => {
    setVals({ ...vals, [field]: e.target.value });
  };

  //ADDS AN ITEM WITH TEMP:TRUE, THIS MAKES IT DELETABLE. IF FALSE NO DELETE BUTTON.
  const handleAddItem = function () {
    setInvoiceItems([
      ...invoiceItems,
      {
        id: randomID(8),
        discrepancy: 'Item ' + (invoiceItems.length + 1),
        laborHours: 0,
        partsCost: 0,
        laborCost: 0,
        subTotal: 0,
        active: true,
        temp: true,
        parts: [],
        notes: '',
        isFlatRate: false,
        model: '',
        serialNumber: '',
      },
    ]);
  };

  useEffect(() => {
    if(!vals) return;    
    const tempCharge = charges?.map((charge) => {
      return {
        ...charge,
        ...(charge.percent !== undefined && { total: convertToFee(vals.totalLabor, vals.totalParts, charge) }),
      };
    });
    const tempDiscount = discounts?.map((discount) => {
      return {
        ...discount,
        ...(discount.percent !== undefined && { total: convertToFee(vals.totalLabor, vals.totalParts, discount) }),
      };
    });
    setCharges(tempCharge);
    setDiscounts(tempDiscount);
  }, [invoiceItems]);


  useEffect(() => {
    if(!vals) return;
    const chargeTotal = Math.round(charges?.filter((charge) => charge.active && charge.total).reduce((acc, charge) => acc + Number(charge.total), 0) * 100) / 100;
    const discountTotal = Math.round(discounts?.filter((discount) => discount.active).reduce((acc, discount) => acc + Number(discount.total), 0) * 100) / 100;
    let newTotal = Math.round((vals?.totalLabor + invoice.totalParts + vals.totalCharges) * taxes * 100) / 100;
    newTotal += Math.round(invoiceItems.filter((item) => item.active).reduce((acc, item) => acc + item.subTotal, 0) * 100) / 100;
    newTotal += Number(vals.totalShipping);
    newTotal -= discountTotal;
    newTotal += chargeTotal;
    setVals((tempVals)=> { return { ...tempVals, total: newTotal, totalCharges: chargeTotal, discount: discountTotal } });
  }, [taxes, vals?.totalShipping, vals?.discount, charges, discounts, invoiceItems, setInvoiceItems, setVals, vals?.totalLabor, vals?.totalCharges]);

  const doesContatinCharge = (chargeName) => {
    return charges?.some((charge) => charge.description === chargeName) || charges?.some((charge) => charge.id === chargeName);
  };

  //Standard Charges and Billing Items
  useEffect(() => {
    if(!standardCharges || !setCharges) return;
    standardCharges?.forEach((charge) => {
      if(charge.onByDefault && !doesContatinCharge(charge.id)) {
        setCharges([...charges, charge]);
      }
    });
  }, [standardCharges, mroWorkOrderBillingItems]);

  useEffect(() => {
    if(!mroOrganizationFees) return;
    setStandardCharges( mroOrganizationFees?.filter((charge) => charge.active).map((charge) => { 
      return{
        ...charge,
        ...(charge.isPercentage && { percent: charge.value }),
        type: charge.billableType,
      };
    }))
  }, [mroOrganizationFees, invoiceItems]);


  return (
    <>
      {/* HEADER */}
      <div className="flex flex-col gap-4 w-full justify-end bg-white rounded shadow border border-slate-200 p-6">
        <div className="flex justify-between items-end shrink-0">
          <span className="text-slate-600">Invoice Number:</span>
          <h1 className="flex text-brand-dark justify-end font-bold text-xl">
            <span className="text-slate-500 font-normal">#</span>
            <input
              className="w-[110px] text-brand-dark font-bold placeholder:text-slate-400 border -ml-5 pl-5 bg-transparent shadow-inner disabled:border-0 disabled:shadow-none"
              placeholder=""
              disabled={!edit}
              value={invoice?.invoiceNumber}
              onChange={handleChange?.('invoiceNumber')}
            />
          </h1>
        </div>
        {status && (
          <div className="flex justify-between items-end shrink-0">
            <span className="text-slate-600">Status:</span>
            <div
              className={` ${
                invoiceCase[status.toUpperCase()].bgColor + ' ' + invoiceCase[status.toUpperCase()].textColor
              } text-xs uppercase rounded py-1 px-2 text-center`}>
              <span className="font-bold">{status}</span>
            </div>
          </div>
        )}
        <div className="flex justify-between items-end shrink-0">
          <span className="text-slate-600">Due Date:</span>
          <input
            className="text-brand-dark font-bold -mt-0.5 p-0 border-0 text-right"
            type="date"
            disabled={!edit}
            value={invoice.dueDate && format(new Date(invoice?.dueDate.slice(0, 16)), 'yyyy-MM-dd')}
            onChange={handleChange?.('dueDate')}
          />
        </div>
        <div className="flex justify-between items-end shrink-0">
          <span className="text-slate-600">Issued:</span>
          <input
            className="text-brand-dark font-bold -mt-0.5 p-0 border-0 text-right"
            type="date"
            disabled={!edit}
            defaultValue={invoice.invoiceDate && format(new Date(invoice?.invoiceDate.slice(0, 16)), 'yyyy-MM-dd')}
            onChange={handleChange?.('invoiceDate')}
          />
        </div>
      </div>
      {/* ITEMS LIST */}
      <div className="bg-white rounded shadow border border-slate-200 p-6 mt-3">
        <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Line Items</span>
        <hr className="my-2 -m-6" />
        {invoiceItems?.map((item, index) => {
          return edit ? (
            <NewInvoiceItem
              item={item}
              index={index}
              key={item.id}
              handleRemoveItem={handleRemoveItem}
              handleItemUpdate={handleItemUpdate}
            />
          ) : (
            <InvoiceItem item={item} index={index} key={item.id} />
          );
        })}
        {edit && (
          <div className="flex justify-end mt-2">
            <button
              onClick={() => {
                handleAddItem();
              }}
              className="flex shrink-0 items-center place-self-end bg-white text-brand-dark border border-slate-300 text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              Add Item
            </button>
          </div>
        )}
      </div>
      {/* SHIPPING */}
      <div className="bg-white rounded shadow border border-slate-200 p-6 mt-3">
        <div className="flex justify-between items-center w-full relative">
          <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Shipping</span>
        </div>
        <hr className="my-2 -m-6" />
        <div className="flex justify-between text-sm pt-4 gap-3">
          <span className="text-slate-600">Shipping:</span>
          <input
            disabled={!edit}
            className="w-[110px] h-6 text-brand-dark border-slate-200 rounded placeholder:text-slate-400 border bg-transparent shadow-inner text-right disabled:border-0 disabled:shadow-none px-1"
            value={edit ? invoice?.totalShipping : formatUSD(invoice?.totalShipping)}
            onChange={handleChange?.('totalShipping')}
          />
        </div>
      </div>
      {/* DISCOUNTS LISTS */}
      { ( edit || discounts?.length > 0) && (
        <div className="bg-white rounded shadow border border-slate-200 p-6 mt-3">
          <div className="flex justify-between items-center w-full relative">
            <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Discounts</span>
          </div>
          <hr className="my-2 -m-6" />
          <div className="flex flex-wrap -mx-0.5 mt-1 overflow-auto">
            {discounts?.map((discount, idx) => { 
              return (
                <FeeItem fee={discount} isDiscount={true} key={idx} edit={edit} totalLabor={invoice.totalLabor} totalParts={invoice.totalParts} updateFee={(d) => {
                  const temp = [...discounts];
                  temp[idx] = d;
                  setDiscounts(temp);
                }} removeFee={() => {
                  setDiscounts([...discounts.slice(0, idx), ...discounts.slice(idx + 1)]);
                }} />
              )
            } )}
          </div>
          {edit && (
            <div className="flex justify-end my-3 gap-3">
              <div
                onClick={() => {
                  setDiscounts([...discounts, { description: '', percent: 0, total: 0, active: true, temp: true }]);
                }}
                className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
                <PlusIcon className="h-3 w-3" />
                <span className="text-xs font-semibold w-full ml-0.5">New Percent Discount</span>
              </div>
              <div
                onClick={() => {
                  setDiscounts([...discounts, { description: '', total: 0, active: true, temp: true }]);
                }}
                className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
                <PlusIcon className="h-3 w-3" />
                <span className="text-xs font-semibold w-full ml-0.5">New Flat Discount</span>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 my-4">
            Subtotal:
            <span className="font-bold ml-2">
              {formatUSD(Math.round(discounts?.filter((discount) => discount.active).reduce((acc, discount) => acc + Number(discount.total), 0) * 100) / 100)}
            </span>
          </div>
        </div> )
      }
      {/* CHARGES LIST */}
      <div className="flex flex-col border-t bg-white rounded shadow border border-slate-200 p-6 mt-3">
        {standardCharges.filter((charge) => !edit ? doesContatinCharge(charge.id) : true ).length > 0 && (
          <>
            <div className="flex justify-between items-center w-full relative">
              <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Standard Charges:</span>
            </div>
            <hr className="my-2 -m-6" />
            <div className='flex text-sm mt-1'>
              {standardCharges.filter((charge) => !edit ? doesContatinCharge(charge.id) : true ).map((charge, idx) => (
                <button key={idx}
                  disabled={!edit}
                  onClick={
                    () => {
                      if(doesContatinCharge(charge.description)){
                        setCharges([...charges.filter((char) => char.id !== charge.id)]);
                      }else {
                        setCharges([...charges, charge]);
                      }
                    }
                  }
                  className='flex justify-between cursor-pointer items-center py-2 w-full border-b-2 border-dashed last:border-none border-slate-100'>
                    <div className='flex items-center'>
                      { edit ? (
                        <input
                        type="checkbox"
                        onChange={() => {}}
                        checked={doesContatinCharge(charge.id)}
                        className='flex rounded h-3.5 w-3.5 mr-5 cursor-pointer'
                        /> 
                      ) : (
                        <CheckIcon className='flex rounded h-3.5 w-3.5 mr-5' /> 
                      ) }
                      <span className="flex text-slate-600">{charge.name}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-slate-600">{charge.isPercentage ? `(${convertToFee(vals?.totalLabor, vals?.totalParts, { percent: charge.value, type: charge.billableType })})` : ''}</span>
                    </div>
                    <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 font-bold ml-2">
                        {charge.isPercentage ? `${charge.value}% on ${webFormat(charge.billableType)}` : formatUSD(charge.value)}
                    </div>
                </button>
              ))}
            </div>
          </>
        )}
        <div className="flex justify-between items-center w-full relative mt-3">
          <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Additional Charges ({charges?.filter((item) => !item.id )?.length})</span>
        </div>
        <hr className="my-2 -m-6" />
        {charges?.length > 0 && (
          <div className="flex flex-wrap -mx-0.5 mt-1 overflow-auto">
            {charges.map((charge, idx) => { 
              if(charge.id){ return null; }
              return (
                <FeeItem fee={charge} key={idx} edit={edit} totalLabor={invoice.totalLabor} totalParts={invoice.totalParts} updateFee={(c) => {
                  const temp = [...charges];
                  temp[idx] = c;
                  setCharges(temp);
                }} removeFee={() => {
                  setCharges([...charges.slice(0, idx), ...charges.slice(idx + 1)]);
                }} />
              )
            } )}
          </div>
        )}
        {edit && (
          <div className="flex justify-end my-3 gap-3">
            <div
              onClick={() => {
                setCharges([...charges, { description: '', percent: 0, total: 0, active: true, temp: true }]);
              }}
              className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
              <PlusIcon className="h-3 w-3" />
              <span className="text-xs font-semibold w-full ml-0.5">New Percent Charge</span>
            </div>
            <div
              onClick={() => {
                setCharges([...charges, { description: '', total: 0, active: true, temp: true }]);
              }}
              className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
              <PlusIcon className="h-3 w-3" />
              <span className="text-xs font-semibold w-full ml-0.5">New Flat Charge</span>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 my-4">
          Subtotal:
          <span className="font-bold ml-2">
            {formatUSD(invoice.totalCharges)}
          </span>
        </div>
      </div>
      {/* NOTES */}
      <div className="flex flex-col border-t bg-white rounded shadow border border-slate-200 p-6 mt-3">
        <div className="flex justify-between items-center w-full relative">
          <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Notes:</span>
        </div>
        <hr className="my-2 -m-6" />
        <textarea
          value={vals?.notes ?? invoice?.notes}
          onChange={handleChange?.('notes')}
          disabled={!edit}
          placeholder="Invoice Notes"
          className="rounded border p-2 text-sm bg-slate-50 border-slate-200 disabled:border-0 disabled:resize-none disabled:shadow-non focus:bg-white placeholder:text-slate-400 w-full"></textarea>
      </div>
      {/* TOTAL SECTION */}
      <div className="bg-white rounded shadow border border-slate-200 p-6 mt-3">
        <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Totals</span>
        <hr className="my-2 -m-6" />
        <div className="mt-4 px-2 -mx-2">
          <div className="flex mt-2 gap-2">
            <div className="flex flex-col w-2/5 border p-2 text-sm h-full rounded border-slate-200 border-dashed">
              <span className="font-semibold border-b border-slate-200 -mt-0.5 pb-1 mb-1.5">Labor Summary</span>
              <div className="flex justify-between">
                <span>Shop Labor:</span>
                <span>{formatUSD(invoice.totalLabor)}</span>
              </div>
              <div className="flex justify-between">
                <span>Total Hours:</span>
                <span>{invoice.totalHours}</span>
              </div>
            </div>
            <div className="flex flex-col w-3/5 p-2 pb-1 text-sm border rounded border-slate-200">
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Labor:</span>
                <span className="w-1/2">{formatUSD(invoice.totalLabor)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Parts:</span>
                <span className="w-1/2">{formatUSD(invoice.totalParts)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Charges:</span>
                <span className="w-1/2">{formatUSD(invoice.totalCharges)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Shipping:</span>
                <span className="w-1/2">{formatUSD(invoice.totalShipping)}</span>
              </div>
              {/* <div className="flex justify-between text-right">
                    <span className="w-1/2">Tax:</span>
                    <span className="w-1/2">{formatUSD((totalLabor + totalParts + totalCharges) * taxes )}</span>
                </div> */}
              {invoice?.discount > 0 && <div className="flex justify-between text-right">
                <span className="w-1/2">Discount:</span>
                <span className="w-1/2">-{formatUSD(invoice.discount)}</span>
              </div> }
              <div className="flex justify-between text-right">
                <span className="w-1/2">Amount Due:</span>
                <span className="w-1/2">{formatUSD(invoice.total ?? invoice.amount)}</span>
              </div>
              {/* <div className="flex justify-between text-right border-t border-slate-200 border-dashed mt-0.5 pt-0.5">
                    <span className="w-1/2">Deposit:</span>
                    <span className="w-1/2">{formatUSD(initialVals.priceSummary.deposit)}</span>
                </div>
                <div className="flex justify-between text-right font-bold border-t pt-1 mt-1 text-base bg-slate-100 -mx-2 -mb-1 pr-2 pb-1">
                    <span className="w-1/2">Balance Due:</span>
                    <span className="w-1/2">{formatUSD(total - initialVals.priceSummary.deposit)}</span>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

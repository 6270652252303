import { useMemo } from 'react';
import { Table } from 'components/Table/Table';
import NewInvoice from './New';
import { PlusIcon } from 'assets/icons';
import { DocumentCheckIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GetWorkOrderBillingDocument } from 'graphql/generated';
import { formatApiDate, formatWithZeros } from 'utils/formatter';
import InvoiceFlyout from './View';
import { invoiceCase } from 'utils/statuses';

export default function Invoices({ mroWorkOrder, updateFlyoutMini, updateFlyout, closeFlyout, ...props }) {
  const { data: { mroWorkOrder: billingData } = {} } = useQuery(GetWorkOrderBillingDocument, {
    variables: { mroWorkOrderId: mroWorkOrder?.id },
  });

  const invoices = useMemo(() => {
    if (billingData) return billingData.mroInvoices;
    return [];
  }, [billingData]);
  
  const invoiceColumns = useMemo(
    () => [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber', // accessor is the "key" in the data
        className: 'w-full',
        Cell: ({ value, row }: any) => {
          return (
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm w-full text-center">
              {value}
            </span>
          );
        },
      },
      {
        Header: 'Issued',
        accessor: 'invoiceDate',
        className: 'w-full',
        Cell: ({ value }) => formatApiDate(value),
      },
      {
        Header: 'Due',
        accessor: 'dueDate',
        className: 'w-full',
        Cell: ({ value }) => formatApiDate(value),
      },
      {
        Header: 'Services',
        accessor: 'items.length',
        className: 'w-full justify-end',
      },
      {
        Header: 'Cost',
        accessor: 'amount',
        className: 'w-full justify-end',
        Cell: ({ value }) =>
          Number(value)?.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-full min-w-[8rem]',
        Cell: ({ value, row }: any) => {
          return (
            <div
              className={`${
                invoiceCase[value.toUpperCase()].bgColor + ' ' + invoiceCase[value.toUpperCase()].textColor
              } text-xs uppercase w-full rounded py-1 px-2 text-center`}>
              <span className="font-bold">{value}</span>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner">
        <div className="flex flex-col w-full">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <DocumentCheckIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">All Invoices</h1>
          </div>
          <Table
            className="w-full bg-white border rounded"
            columns={invoiceColumns}
            data={invoices || []}
            onRowClick={(row) => {
              updateFlyout({
                title: 'Invoice #' + formatWithZeros(row.invoiceNumber, 6),
                content: <InvoiceFlyout mroInvoiceId={row.id} workOrder={mroWorkOrder} />,
              });
            }}
          />
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          {!invoices?.length && (
            <div className="flex items-center justify-center p-4 bg-slate-50 rounded-b border text-sm -mt-[2px] border-slate-200 shadow-inner">
              <button
                className="flex items-center font-semibold text-brand cursor-pointer underline"
                onClick={() =>
                  updateFlyout({
                    title: 'New Invoice',
                    content: <NewInvoice workOrder={mroWorkOrder} customer={billingData.mroCustomer} closeFlyout={closeFlyout} />,
                  })
                }>
                <PlusIcon className="h-2.5 w-2.5 mr-1" />
                Create your first invoice
              </button>
            </div>
          )}
          <div className="mt-2 self-end">
            <button
              className="flex items-center bg-brand text-white border border-brand-electric text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all"
              onClick={() => {
                updateFlyout({
                  title: 'New Invoice',
                  content: <NewInvoice workOrder={mroWorkOrder} customer={billingData.mroCustomer} closeFlyout={closeFlyout} />,
                });
              }}>
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              New Invoice
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full" />
    </>
  );
}

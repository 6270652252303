import { useMutation } from '@apollo/client';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import { useSession } from 'contexts';
import { GetCraftsDocument, GetCraftsQuery, UpdateCraftsDocument } from 'graphql/generated';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function OveraircraftLimit({ reload, crafts, limit } : { reload: () => void, crafts: GetCraftsQuery['crafts'], limit: number}){
    const [selectCrafts, setSelectCrafts] = useState([]);
    const {organizationId} = useSession();
    const [ updateCrafts ] = useMutation(UpdateCraftsDocument, {refetchQueries: [{query: GetCraftsDocument, variables: { organizationId }}]});
    const navigate = useNavigate();
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-slate-100 flex items-center justify-center z-50">
            <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-md w-full mt-10">
                <div className="flex gap-3 items-center p-8">
                    <ExclamationTriangleIcon className="h-14 w-14 p-3 bg-amber-100 text-amber-400 rounded-lg" />
                    <div>
                        <h1 className="font-bold text-brand leading-5">Too Many Aircraft for Organization</h1>
                        <div className="font-semibold text-brand py-2 leading-5">Please select aircraft you would like to remove or add on more</div>
                    </div>
                </div>
                <div className='mb-8 flex flex-col gap-2'>
                    {crafts?.map((craft, index) => (
                        <div key={index} className="flex gap-2 items-center">
                            <input type='checkbox' className="h-5 w-5" value={selectCrafts.find((craftId) => craft.id)} onChange={(e) => {
                                if(e.target.checked){
                                    setSelectCrafts([...selectCrafts, craft.id]);
                                } else {
                                    setSelectCrafts(selectCrafts.filter((id) => id !== craft.id));
                                }
                            }}/>
                            <div className="font-bold text-brand">{craft.tailNumber} - {craft.name}</div>
                        </div>
                    ))}
                </div>
                { crafts.length - limit > selectCrafts.length && <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-t border-slate-300 bg-slate-50">
                    <h3>You need to select at least {crafts.length - limit} Aircraft</h3>
                </div> }
                <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-slate-300 bg-slate-50">
                    <Button
                        color="red"
                        size="xs"
                        text="Delete"
                        disabled={selectCrafts.length < crafts.length - limit}
                        onClick={() => {
                                updateCrafts({ variables: { input: selectCrafts.map((craftId) => ({
                                    id: craftId,
                                    organization: { disconnect: { } }
                                })) }});
                            }
                        }
                    />
                    <Button
                        color="navy"
                        size="xs"
                        text="Add More"
                        onClick={() => {
                            navigate('/account/settings?settingsTab=Billing');
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

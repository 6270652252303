import React, { useState, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { GetMroCraftsDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { AirplaneIcon, PlusIcon } from 'assets/icons';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import NewCraft from './New';

const Crafts = function (props) {
  const [activeTab, setActiveTab] = useSaveState('craftActiveTab','Active');
  const [query, setQuery] = useState<string>();
  const { user } = useSession();
  const { data: { mroCrafts } = {}, refetch } = useQuery(GetMroCraftsDocument, {
    variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } },
  });
  const navigate = useNavigate();
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();

  useEffect(() => {
    if (query !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        refetch(
          query
            ? {
                where: {
                  mroOrganizationId: { equals: user.mroOrganizationId },
                  OR: [
                    { tailNumber: { search: query.trim().replaceAll(' ', ' & ') } },
                    { make: { search: query.trim().replaceAll(' ', ' & ') } },
                    { model: { search: query.trim().replaceAll(' ', ' & ') } },
                  ],
                },
              }
            : { where: { mroOrganizationId: { equals: user.mroOrganizationId } } }
        ).then(() => setActiveTab('All'));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [query, refetch, user]);

  // TABLE COLUMNS
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tail Number',
        accessor: (craft: any) => ({
          tailNumber: craft.tailNumber,
          active: craft.mroWorkOrders.find((order) => order.status !== 'CLOSED' && order.status !== 'COMPLETED'),
        }),
        className: 'w-1/6',
        Cell: ({ value }: any) => {
          return (
            <div
              key={value}
              className={`${
                value.active ? 'bg-brand-pale text-brand-electric' : 'border-slate-300 bg-slate-50 text-slate-400'
              } m-[0.125rem] flex items-center rounded px-1.5 py-0.5 border`}>
              <span className="font-bold">{value.tailNumber}</span>
            </div>
          );
        },
      },
      {
        Header: 'Make/Model/Year',
        accessor: (craft: any) => ({
          make: craft.make,
          model: craft.model,
          year: craft.year,
        }),
        className: 'grow w-1/6 font-bold',
        Cell: ({ value, row }: any) => value.make + ' ' + value.model + ' ' + value.year,
      },
      {
        Header: 'Customer Info',
        accessor: (craft: any) => ({
          name: craft?.mroCustomer?.name ?? 'Unknown',
          email: craft?.mroCustomer?.email,
        }),
        className: 'grow w-1/6 font-bold',
        Cell: ({ value, row }: any) => (
          <div className="flex items-center w-full">
            <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale border-brand-electric w-9 h-9 rounded mr-2">
              {value.name[0]}
            </div>
            <div className="flex items-center justify-between w-full max-w-screen-md">
              <div className="flex flex-col">
                <span className="text-lg font-bold text-brand-dark">{value.name}</span>
                <span className="-mt-1 text-brand-electric">{value.email}</span>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  // HANDLES FILTER TAB FUNCTIONALITY
  const tabs = ['Active', 'Inactive', 'All'];
  const tableData = React.useMemo(() => {
    switch (activeTab) {
      case 'All':
        return mroCrafts;
      case 'Active':
        return mroCrafts?.filter((craft) => craft.mroWorkOrders.find((order) => order.status !== 'CLOSED' && order.status !== 'COMPLETED'));
      case 'Inactive':
        return mroCrafts?.filter(
          (craft) => !craft.mroWorkOrders.find((order) => order.status !== 'CLOSED' && order.status !== 'COMPLETED')
        );
    }
  }, [mroCrafts, activeTab]);

  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center">
          <AirplaneIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Crafts</h1>
        </div>
        <div className="flex items-stretch gap-2">
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
            <input
              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <button
            className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4"
            onClick={() => updateFlyout({ title: 'Add A Craft', content: <NewCraft closeFlyout={() =>{
              closeFlyout();
              setActiveTab('All');
            }} /> })}
            >
            <PlusIcon className="h-3 w-3 mr-1" />
            Add New
          </button>
        </div>
      </div>
      <div className="p-6 bg-white border rounded border-slate-300 w-full max-w-[calc(78rem-2px)] shadow-blue">
        <div className="flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-3">
          <Tabs items={tabs} activeItem={activeTab} setActiveItem={setActiveTab} />
        </div>
        <Table
          columns={columns}
          data={tableData ?? []}
          onRowClick={(row) => {
            navigate('/mro/crafts/' + row.id);
          }}
        />
      </div>
    </>
  );
};

export default Crafts;

import { useMutation } from '@apollo/client';
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker/DatePicker';
import { FormLabel, FullFormikSelect, FullFormikTextarea, StyledFormikField } from 'components/Form/StandardForm';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  GetMroWorkOrderDocument,
  GetMroWorkOrderItemDocument,
  GetMroWorkOrderPartDocument,
  GetMroWorkOrderPartQuery,
  UpdateMroWorkOrderPartDocument,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { webFormat } from 'utils/statuses';
import { PartNumberLookup } from 'views/Part145/Parts/Components/PartLookup';

interface Values {
  unitPrice: string;
  costPrice?: string;
  quantity: string;
  condition: string;
  shippingType: string;
  neededDated: Date;
  partDescription?: string;
  notes: string;
}

export default function PartDetailEdit({
  part,
  workOrderItemId,
  setEdit,
  context,
}: {
  part: GetMroWorkOrderPartQuery['mroWorkOrderPart'];
  workOrderItemId: string;
  setEdit: (edit: boolean) => void;
  context?: string;
}) {
  const [selectedPart, setSelectedPart] = useState<any>(part?.mroPart);
  const [partNumber, setPartNumber] = useState<string>(part?.partNumber ?? '');
  const [updateOrderPart] = useMutation(UpdateMroWorkOrderPartDocument, {
    refetchQueries: [
      { query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } },
      { query: GetMroWorkOrderPartDocument, variables: { id: part?.id } },
      { query: GetMroWorkOrderDocument,  variables: { id: part.mroWorkOrderItem.mroWorkOrder.id } },
    ],
  });

  const [initialValues, setInitialValues] = useState<Values>({
    unitPrice: part?.unitPrice ?? '',
    costPrice: part?.costPrice ?? '',
    quantity: part?.quantity?.toString() ?? '',
    condition: '',
    shippingType: part?.shippingType ?? '',
    neededDated: part.neededDate ?? new Date(),
    partDescription: part?.partDescription ?? '',
    notes: part.notes ?? '',
  });

  useEffect(() => {
    if (part) {
      setInitialValues({
        unitPrice: part?.unitPrice ?? '',
        costPrice: part?.costPrice ?? '',
        quantity: part?.quantity?.toString() ?? '',
        condition: '',
        shippingType: part?.shippingType ?? '',
        neededDated: part.neededDate ?? new Date(),
        partDescription: part?.partDescription ?? '',
        notes: part.notes ?? '',
      });
    }
  }, [part]);

  const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    updateOrderPart({
      variables: {
        input: {
          id: part?.id,
          partNumber: partNumber,
          partDescription: values.partDescription,
          ...(selectedPart && { mroPart: { connect: { id: selectedPart?.id } } }),
          neededDate: new Date(values.neededDated),
          shippingType: values.shippingType,
          unitPrice: parseFloat(values.unitPrice),
          costPrice: parseFloat(values.costPrice),          
          notes: values.notes,
          quantity: selectedPart?.isSerialized ? 1 : parseInt(values.quantity),
        },
      },
    })
    .then(() => {
      setSubmitting(false);
      setEdit(false);
    })
    .catch((err) => console.error(err));
  };
  const fullEdit = part.status === 'DRAFT';
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
            <dl className="flex flex-col w-full p-2">
              {fullEdit ?
                <PartNumberLookup {...{ selectedPart, setSelectedPart, partNumber, setPartNumber }} />
                :
                <div className="flex flex-col w-full relative">
                  <FormLabel>Part Number</FormLabel>
                  <StyledFormikField
                    id="partNumber"
                    name="partNumber"
                    value={partNumber}
                    disabled={!fullEdit}
                    className="w-full bg-state-500 disabled:bg-white disabled:border-none disabled:cursor-not-allowed"
                  />
                </div>
              }
              {selectedPart?.description && <div className="text-sm">Description: {selectedPart?.description}</div>}
              { (!selectedPart || selectedPart === 'newPart') && <div className="flex flex-col w-full relative">
                  <FormLabel>Part Description</FormLabel>
                  <StyledFormikField
                    id="partDescription"
                    name="partDescription"
                    disabled={!fullEdit}
                  />
                </div> }
              <div className="mt-2">
                <FormLabel>Need By</FormLabel>
                <Field disabled={!fullEdit} as={DatePicker} className="w-full bg-state-500 disabled:bg-white disabled:border-none disabled:cursor-not-allowed" name="neededBy"></Field>
              </div>
              {context !== 'dashboard' && (<div className="mt-3">
                {fullEdit ? 
                  <FullFormikSelect name="shippingType" label="Shipping Speed">
                    <option>N/A</option>
                    <option value="GROUND">Ground</option>
                    <option value="2_DAY">2 Day</option>
                    <option value="STANDARD_OVERNIGHT">Standard Overnight</option>
                    <option value="PRIORITY_OVERNIGHT">Priority Overnight</option>
                  </FullFormikSelect>
                  :
                  <div className="flex flex-col w-full relative">
                    <FormLabel>Shipping Speed</FormLabel>
                    <span>{webFormat(values.shippingType)}</span>
                  </div>
                }
                </div>)
              }
              <div className="mt-2">
                <FullFormikTextarea name="notes" label="Notes" />
              </div>
              {context !== 'dashboard' && (
                <>
                  <hr className="mb-4 mt-2 border-slate-200" />
                  <div className="flex justify-between py-2">
                    <FormLabel className="text-sm font-medium text-slate-500">Cost Price</FormLabel>
                    <StyledFormikField stripStyle id="costPrice" name="costPrice" type="number" dir="rtl" />
                  </div>
                  <div className="flex justify-between py-2">
                    <FormLabel className="text-sm font-medium text-slate-500">Unit Price</FormLabel>
                    <StyledFormikField stripStyle id="unitPrice" name="unitPrice" type="number" dir="rtl" />
                  </div>
                  <div className="flex justify-between py-2">
                    <FormLabel className="text-sm font-medium text-slate-500">Quantity</FormLabel>
                    {selectedPart?.isSerialized ? (
                      <StyledFormikField name="blank" placeholder="Cannot set QTY for Serialized Part" disabled />
                    ) : (
                      <StyledFormikField id="quantity" name="quantity" type="number" dir="rtl" disabled={!fullEdit}/>
                    )}
                  </div>
                  <hr className="mb-6 mt-2 border-slate-200" />
                  <div className="flex justify-between pt-2">
                    <FormLabel>Total Price</FormLabel>
                    <dd className="text-sm font-semibold text-brand-dark">
                      {Number(+values?.unitPrice * +values.quantity)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) ?? 'N/A'}
                    </dd>
                  </div>
                </>)}
            </dl>
          </div>
          <div className="flex justify-end items-center mt-4 gap-3">
            <div onClick={() => setEdit(false)} className="flex items-center justify-center hover:opacity-70 cursor-pointer">
              <span className="text-xs font-semibold cursor-pointer text-slate-500">Cancel</span>
            </div>
            <Button text="Save Changes" color="navy" size="xs" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { InboxIcon } from '@heroicons/react/24/solid';
import { AirplaneIcon } from 'assets/icons';
import { useQuery } from '@apollo/client';
import { GetServiceRequestsDocument, GetServiceRequestQuery } from 'graphql/generated';
import { formatApiDate } from 'utils/formatter';
import { useNavigate } from 'react-router-dom';
import StatusButton from 'components/StatusButton/StatusButton';
import { orderCase } from 'utils/statuses';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';

const ServiceRequests = function (props) {
  const [activeTab, setActiveTab] = useSaveState('serviceRequestsTab','Open');
  const [query, setQuery] = useState<string>();
  const { user } = useSession();
  const { data: { mroServiceRequests } = {}, refetch } = useQuery(GetServiceRequestsDocument, {
    variables: {
      where: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });
  const navigate = useNavigate();
  // TABLE COLUMNS
  const columns = React.useMemo(
    () => [
      {
        Header: 'Aircraft',
        accessor: (obj) => [obj?.mroCraft?.tailNumber, `${obj?.mroCraft?.make} ${obj?.mroCraft?.model}`, obj?.status],
        className: 'w-64 shrink-0',
        Cell: ({ value, row }: any) => {
          if (Array.isArray(value)) {
            return (
              <div className="flex items-center gap-2">
                <div
                  className={`${orderCase[value[2]]?.textColor} ${
                    orderCase[value[2]]?.bgColor
                  } rounded h-8 w-8 flex items-center justify-center`}>
                  <AirplaneIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-lg font-bold text-brand-dark">{value[0]}</span>
                  <span className="-mt-2">{value[1]}</span>
                </div>
              </div>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: 'Description',
        accessor: 'title',
        className: 'w-full',
      },
      {
        Header: 'Customer',
        accessor: 'mroCustomer.name',
        className: 'w-full max-w-[10rem]',
      },
      {
        Header: 'Request Date',
        accessor: 'createdAt',
        className: 'w-full justify-center max-w-[8rem]',
        Cell: ({ value }) => {
          return formatApiDate(value);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-64 shrink-0',
        Cell: ({ value, row }: any) => {
          return (
            <StatusButton
              readOnly={true}
              context="request"
              text={value}
              className=""
              onSelect={(e) => {}}
            />
          );
        },
      },
    ],
    []
  );

  // HANDLES SEARCH BAR FUNCTIONALITY
  useEffect(() => {
    if (query !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        refetch(
          query
            ? {
                where: { title: { search: query.trim().replaceAll(' ', ' & ') } },
              }
            : { where: undefined }
        ).then(() => setActiveTab('Open'));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [query, refetch]);

  // HANDLES FILTER TAB FUNCTIONALITY
  const tabs = ['Open', 'Closed', 'All'];
  const tableData = React.useMemo(() => {
    let statuses = [];
    switch (activeTab) {
      case 'All':
        return mroServiceRequests;
      case 'Open':
        statuses = ['open'];
        break;
      case 'Closed':
        statuses = ['closed'];
        break;
    }
    return mroServiceRequests?.filter((item) => statuses.includes(item.status?.toLowerCase()));
  }, [mroServiceRequests, activeTab]);
  return (
    <>
      <div className="flex justify-between flex-wrap gap-4 items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center">
          <InboxIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-xl md:text-2xl">Service Requests</h1>
        </div>
        <div className="flex items-stretch gap-2">
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
            <input
              value={query ?? ''}
              onChange={(e) => setQuery(e.target.value)}
              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="p-6 bg-white border rounded border-slate-300 w-full max-w-[calc(78rem-2px)] shadow-blue">
        <div className="flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-3">
          <Tabs items={tabs} activeItem={activeTab} setActiveItem={setActiveTab} />
        </div>
        <Table
          columns={columns}
          data={tableData || []}
          onRowClick={(serviceRequest: GetServiceRequestQuery['mroServiceRequest']) => navigate(serviceRequest.id)}
        />
      </div>
    </>
  );
};

export default ServiceRequests;

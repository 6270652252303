import Card from "components/Card/Card";
import { Table } from "components/Table/Table";
import { useMemo } from "react";
import { formatApiDate } from "utils/formatter";
import ViewLocation from "./View";
import { useQuery } from "@apollo/client";
import { GetLocationsDocument } from "graphql/generated";
import { useSession } from "contexts";
import Button from "components/Button/Button";
import NewLocation from "./New";

export default function LocationList({ updateFlyout, closeFlyout } : { updateFlyout: (props: { title: string, content: JSX.Element }) => void, closeFlyout: () => void }) {
    const { user } = useSession();
    const { data: { mroLocations } = {} } = useQuery(GetLocationsDocument, { variables: { where : { mroOrganizationId: { equals: user?.mroOrganizationId } } } });
    const columns = useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'code',
                className: 'w-1/6',
            },
            {
                Header: 'Name',
                accessor: 'name',
                className: 'w-3/6',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-2/6',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
    return (
        <Card title="Organization Locations">
            <div className="flex items-center gap-2 border border-slate-300 rounded">
                <Table columns={columns}
                onRowClick={(row) =>{ 
                    updateFlyout({ title: 'Edit Location', content: <ViewLocation closeFlyout={closeFlyout} location={row} /> })}}
                data={mroLocations ?? []}/>
            </div>
            <div className="flex justify-end mt-3">
                <Button text="Create New Location" color="navy" size="xs" onClick={() =>{
                    updateFlyout({ title: 'New Location', content: <NewLocation closeFlyout={closeFlyout} /> });
                }} />
            </div>
        </Card>
    );
}
export const isBetweenDays = (date: Date, from: Date, to: Date): boolean => {
    // Reset the time part for accurate date comparison
    const fromDateOnly = new Date(from.getFullYear(), from.getMonth(), from.getDate());
    const toDateOnly = new Date(to.getFullYear(), to.getMonth(), to.getDate());
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return dateOnly >= fromDateOnly && dateOnly <= toDateOnly;
};

export const getDaysOfWeek = (dateForWeek:Date):Array<Date>=>{
    var daysOfWeek = [];
    for (let day = 0; day < 7; day++) {
        var tempDate = new Date(dateForWeek.getFullYear(), dateForWeek.getMonth(), (dateForWeek.getDate()-dateForWeek.getDay())+day);
        daysOfWeek[day] = tempDate;
    }
    return daysOfWeek;
}




export const areDaysEqual = (date1:Date,date2:Date):boolean=>{
    if(date1.getMonth()===date2.getMonth() && date1.getFullYear()===date2.getFullYear() && date1.getDate()===date2.getDate()){
        return true;
    }
    return false;
}

export const isBetweenHours = (date: Date, from: Date, to: Date): boolean => {
    if (!isBetweenDays(date, from, to)) {
        return false;
    }

    const isSameYearMonthDay = (d1: Date, d2: Date): boolean => {
        return d1.getFullYear() === d2.getFullYear() &&
               d1.getMonth() === d2.getMonth() &&
               d1.getDate() === d2.getDate();
    };

    const fromDateSameDay = isSameYearMonthDay(date, from);
    const toDateSameDay = isSameYearMonthDay(date, to);

    if (fromDateSameDay && toDateSameDay) {
        return date.getHours() >= from.getHours() && date.getHours() <= to.getHours();
    }

    if (fromDateSameDay) {
        return date.getHours() >= from.getHours();
    }

    if (toDateSameDay) {
        return date.getHours() <= to.getHours();
    }

    return true;
};

export const isBetweenMins = (date: Date, from: Date, to: Date): string => {
    if (isBetweenDays(date, from, to)) {
        if (from.getFullYear() === date.getFullYear() && from.getMonth() === date.getMonth() && from.getDate() === date.getDate() && from.getHours() === date.getHours()) {
            return "from";
        }
        if (to.getFullYear() === date.getFullYear() && to.getMonth() === date.getMonth() && to.getDate() === date.getDate() && to.getHours() === date.getHours()) {
            return "to";
        }
    }
    return "none";
};

export const getMinutePercentage = (date: Date, from: Date, to: Date): [number, string] => {
    let percent = 0;
    const rangeType = isBetweenMins(date, from, to);

    if (isBetweenHours(date, from, to)) {
        percent = 100;
    } else if (rangeType === "from") {
        const minutesFromStart = date.getMinutes() - from.getMinutes();
        percent = (minutesFromStart / 60) * 100;
    } else if (rangeType === "to") {
        const minutesToEnd = to.getMinutes() - date.getMinutes();
        percent = (minutesToEnd / 60) * 100;
    }

    if (percent < 0) {
        percent = 0;
    }
    if (percent > 100) {
        percent = 100;
    }

    return [Math.round(percent), rangeType];
};
import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_1834)">
        <path d="M9.19996 8V1H7.19996V8H4.79996L8.19996 12L11.6 8H9.19996Z" fill="currentColor" />
        <path
          d="M15.2 16H1.19995C0.599951 16 0.199951 15.6 0.199951 15V6C0.199951 5.4 0.599951 5 1.19995 5H4.19995V7H2.19995V14H14.2V7H12.2V5H15.2C15.8 5 16.2 5.4 16.2 6V15C16.2 15.6 15.8 16 15.2 16Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_1834">
          <rect width="16" height="16" fill="white" transform="translate(0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

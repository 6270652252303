import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroPartWarehouseDocument, GetMroSerilizedPartDocument, PartCondition, UpdateSerializedPartDocument } from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';
import { webFormat } from 'utils/statuses';
import { Link } from 'react-router-dom';
import { formatOrderNumber, formatWithZeros } from 'utils/formatter';
import { PartConditions } from 'views/Part145/Parts/Orders/Components/PartConditions';

const SerializedInventoryFlyout = function ({ partInventoryId, closeFlyout }: { partInventoryId: string; closeFlyout: () => void }) {
  const { user } = useSession();

  const { data: { mroSerializedPart } = {} } = useQuery(GetMroSerilizedPartDocument, { variables: { id: partInventoryId } });

  const { data: { mroPartWarehouses = [] } = {} } = useQuery(GetMroPartWarehouseDocument, {
    variables: {
      input: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });
  const [updateSerializedPart] = useMutation(UpdateSerializedPartDocument, { refetchQueries: [GetMroSerilizedPartDocument] });

  const [initialValues, setInitialValues] = useState({
    condition: 'NEW' as PartCondition,
    serialNumber: '',
    bin: '',
    lotNumber: '',
    unitPrice: 0,
    coreCost: 0,
    warehouse: 'n/a',
  });
  useEffect(() => {
    if (mroSerializedPart) {
      setInitialValues({
        condition: mroSerializedPart?.condition ?? PartCondition.New,
        serialNumber: mroSerializedPart.serialNumber ?? '',
        unitPrice: Number(mroSerializedPart.unitPrice ?? 0),
        coreCost: Number(mroSerializedPart.coreCost ?? 0),
        bin: mroSerializedPart.bin ?? '',
        lotNumber: mroSerializedPart.lotNumber ?? '',
        warehouse: mroSerializedPart.mroPartWarehouse?.id ?? 'n/a',
      });
    }
  }, [mroSerializedPart]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const warehouseId = values.warehouse === 'n/a' ? null : values.warehouse;
      delete values.warehouse;
      updateSerializedPart({
        variables: {
          input: {
            id: partInventoryId,
            ...(warehouseId ? { mroPartWarehouse: { connect: { id: warehouseId } } } : { mroPartWarehouse: null }),
            ...values,
          },
        },
      })
        .then(closeFlyout)
        .catch(console.error);
    },
    [partInventoryId]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikInput name="serialNumber" label="Serial #" disabled={!mroSerializedPart?.isAvailable} />
                {mroSerializedPart?.isAvailable ? (
                  <FullFormikSelect name="condition" label="Condition">
                    <PartConditions />
                  </FullFormikSelect>
                ) : (
                  <FullFormikInput name="blank" value={webFormat(mroSerializedPart?.condition)} label="Condition" disabled />
                )}
              </div>
              <div className="flex gap-1">
                <FullFormikInput type="number" name="unitPrice" label="Unit Price" disabled={!mroSerializedPart?.isAvailable} />
                <FullFormikInput type="number" name="coreCost" label="Core Cost" disabled={!mroSerializedPart?.isAvailable} />
              </div>
              <div className="flex gap-1">
                <FullFormikInput name="lotNumber" label="Lot #" disabled={!mroSerializedPart?.isAvailable} />
                {mroSerializedPart?.isAvailable ? (
                  <FullFormikSelect name="warehouse" label="Warehouse">
                    <option value="n/a">N/A</option>
                    {mroPartWarehouses.map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </FullFormikSelect>
                ) : (
                  <FullFormikInput name="blank" value={mroSerializedPart?.mroPartWarehouse?.name} label="Warehouse" disabled />
                )}
                <FullFormikInput name="bin" label="Bin" disabled={!mroSerializedPart?.isAvailable} />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              {mroSerializedPart?.isAvailable && <Button text="Save" color="navy" size="sm" type="submit" />}
              {!mroSerializedPart?.isAvailable && mroSerializedPart?.mroWorkOrderParts?.[0] && (
                <Link
                  className="font-medium underline"
                  to={'/mro/work-orders/' + mroSerializedPart?.mroWorkOrderParts?.[0].mroWorkOrder.id}>
                  Installed on
                  <span className="bg-brand-pale text-brand text-sm font-medium px-2.5 py-2 rounded-lg ml-1">
                    {'#' + formatOrderNumber(mroSerializedPart?.mroWorkOrderParts?.[0].mroWorkOrder.workOrderNum, mroSerializedPart?.mroWorkOrderParts?.[0].mroWorkOrder.mroLocation.code)}
                  </span>
                </Link>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SerializedInventoryFlyout;

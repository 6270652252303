import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import { Form, Formik } from 'formik';
import { CreateMroWorkOrderIntervalDocument, GetMroWorkOrderIntervalsDocument } from 'graphql/generated';

interface IntervalItem {
    name: string;
    value: number;
}

export default function NewIntervalItemFlyout({
    closeFlyout,
    workOrderId
}: {
    closeFlyout: FlyoutHookReturn['closeFlyout'];
    workOrderId?: string;
}) {
    const [createInterval] = useMutation(CreateMroWorkOrderIntervalDocument, {
        refetchQueries: [ { query: GetMroWorkOrderIntervalsDocument, variables: { where: { mroWorkOrderId: { equals: workOrderId } } } } ],
    });
    const initialValues : IntervalItem = {
        name: '',
        value: 0,
    };

    const handleSubmit = async (values) => {
        try {
            await createInterval({
                variables: {
                    input: {
                        mroWorkOrder: { connect: { id: workOrderId } },
                        ...values
                    },
                },
            });
        }catch(error) {
            console.log(error);
        }
        closeFlyout();
    };
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form className='mx-3'>
                        <Card>
                            <div className="flex gap-5">
                                <FullFormikInput name="name" label="Name" autoComplete='off' />  
                                <FullFormikInput type="number" name="value" label="Value" />
                            </div>
                        </Card>
                        <div className="flex justify-end items-center mt-4">
                            <Button text={"Add Interval"} color="navy" size="xs" type='submit' />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
  );
}

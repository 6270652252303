import { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import * as Yup from 'yup';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CreateMroCustomerAndCraftDocument,
  GetMroCustomersDocument,
  LookupTailDocument,
  NewOrderCustomersDocument,
} from 'graphql/generated';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useSession } from 'contexts';

const fieldCss =
  'rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm';

const initialVals = {
  ownerName: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  year: null,
  make: '',
  model: '',
  tailNumber: '',
  companyName: '',
  custAddress: '',
  custAddress2: '',
  custCity: '',
  custState: '',
  custPostalCode: '',
  email: '',
  phone: '',
};

const AircraftInformationSchema = Yup.object().shape({
  year: Yup.number(),
  make: Yup.string(),
  model: Yup.string(),
  engineCount: Yup.number(),
});

const NewCustomerForm = function ({
  postSubmit,
  tailNum,
  context,
  setStep,
}: {
  postSubmit?: (props?: any) => void;
  tailNum?: string;
  context?: string;
  setStep?: any;
}) {
  const [activeTab, setActiveTab] = useState(tailNum ? 'step2' : 'step1');
  const [tailNumber, setTailNumber] = useState(tailNum ?? '');
  const [tailInfo, setTailInfo] = useState<typeof initialVals>(initialVals);
  const [lookupTail] = useLazyQuery(LookupTailDocument);
  const [createCustomer] = useMutation(CreateMroCustomerAndCraftDocument, {
    refetchQueries: [NewOrderCustomersDocument, GetMroCustomersDocument],
  });
  const { user } = useSession();

  const handleTail = useCallback(
    async function () {
      try {
        const formatedTail = tailNumber.startsWith('N') ? tailNumber.substring(1) : tailNumber;
        const tailReturn = (await lookupTail({ variables: { tailNumber: formatedTail } }))?.data?.registrations[0];
        if(tailReturn?.nNumber) setTailNumber(tailReturn?.nNumber); // tail lookup
        setTailInfo({
          ...initialVals,
          ...tailReturn,
          address: tailReturn.street,
          address2: tailReturn.street2,
          postalCode: tailReturn.zipCode,
          make: tailReturn.manufacturer,
          ownerName: tailReturn.name,
          companyName: tailReturn.name,
          custAddress: tailReturn.street,
          custAddress2: tailReturn.street2,
          custCity: tailReturn.city,
          custState: tailReturn.state,
          custPostalCode: tailReturn.zipCode,
        });
        setActiveTab('step2');
      } catch(error) {
        setActiveTab('step2');
        setTailNumber(tailNumber);
        setActiveTab('step2');
      }
    },
    [lookupTail, tailNumber]
  );

  useEffect(() => {
    if (!tailNum) {
      return;
    } else {
      handleTail();
    }
  }, [tailNum]);
  
  const handleSubmit = (vals: typeof initialVals) => {
    createCustomer({
      variables: {
        input: {
          mroOrganizationId: user?.mroOrganizationId,
          craft: {
            tailNumber: tailNumber,
            year: Number(vals.year),
            make: vals.make,
            model: vals.model,
            ownerName: vals.ownerName,
            address: {
              title: 'Aircraft Address',
              address: vals.address,
              address2: vals.address2,
              city: vals.city,
              region: vals.state,
              postalCode: vals.postalCode,
            },
          },
          customer: {
            name: vals.companyName,
            phoneNumbers: { primary: vals.phone },
            email: vals.email,
            address: {
              title: 'Primary Address',
              address: vals.custAddress,
              address2: vals.custAddress2,
              phone: vals.phone,
              email: vals.email,
              city: vals.custCity,
              region: vals.custState,
              postalCode: vals.custPostalCode,
            },
          },
        },
      },
    }).then((res) => {
      if (postSubmit) postSubmit({ mroCustomer: res.data.onboardMroCustomer });
    });
  };

  return (
    <Formik
      initialValues={{ ...tailInfo, year: tailInfo.year ? Number(tailInfo.year) : null }} enableReinitialize onSubmit={handleSubmit}>
      <Form className="w-full">
        <div className={`${activeTab !== 'step1' ? 'hidden' : 'flex'}`}>
          <div className="flex flex-col w-full">
            <h2 className="text-xl font-bold text-brand mb-2">Select an Aircraft</h2>
            <div className="flex items-center gap-1 w-full relative">
              <MagnifyingGlassIcon className="h-4 w-4 text-brand-electric left-3 absolute" />
              <input
                className={`${fieldCss} font-bold placeholder:font-normal w-full mb-0 pl-8`}
                value={tailNumber}
                name={tailNumber}
                placeholder="Type the tail number (ex. N123AB)"
                onChange={(e) => {
                  setTailNumber(e.target.value.toUpperCase());
                }}
              />
            </div>
            <button
              onClick={() => {
                handleTail();
              }}
              type="button"
              className="flex items-center cursor-pointer justify-center self-end bg-brand text-white font-semibold py-2 px-8 rounded transition hover:opacity-95 mt-4">
              Next
            </button>
          </div>
        </div>
        {/* AIRCRAFT INFORMATION */}
        <div className={`${activeTab !== 'step2' ? 'hidden' : 'flex flex-col'}`}>
          <h2 className="text-xl font-bold text-brand border-b border-slate-200 pb-3 -mx-2 pl-2">Aircraft Information</h2>
          <FullFormikInput name="ownerName" label="Owner Name" className="mt-6" />
          <FullFormikInput name="address" placeholder="Line 1" label="Address" />
          <FullFormikInput name="address2" placeholder="Line 2" className="-mt-1" />
          <div className="flex gap-1">
            <FullFormikInput name="city" label="City" className="w-3/6" />
            <FullFormikInput name="state" label="State" className="w-1/6" />
            <FullFormikInput name="postalCode" label="Postal Code" className="w-2/6" />
          </div>
          <div className="flex gap-1">
            <FullFormikInput name="year" label="Year" className="w-1/6" />
            <FullFormikInput name="make" label="Make" className="w-2/6" />
            <FullFormikInput name="model" label="Model" className="w-2/6" />
            <FullFormikInput name="engineCount" label="Engine Count" className="w-2/6" />
          </div>
          <Field name="tailNumber" className="hidden"></Field>
          <div className="flex justify-between items-center mt-2">
            {!context ? (
              <button type="button" onClick={() => setActiveTab('step1')} className="underline text-brand font-semibold text-sm">
                Go Back
              </button>
            ) : (
              <button type="button" onClick={() => setStep(2)} className="underline text-brand font-semibold text-sm">
                Go Back
              </button>
            )}
            <Button type="button" onClick={() => setActiveTab('step3')} color="navy" size="sm" text="Next" />
          </div>
        </div>
        {/* PERSONAL INFORMATION */}
        <div className={`${activeTab !== 'step3' ? 'hidden' : 'flex flex-col'}`}>
          <h2 className="text-xl font-bold text-brand border-b border-slate-200 pb-3 -mx-2 pl-2">Business Information</h2>
          <FullFormikInput className="mt-6" name="companyName" label="Company Name" />
          <FullFormikInput name="custAddress" placeholder="Line 1" label="Address" />
          <FullFormikInput name="custAddress2" placeholder="Line 2" className="-mt-1" />
          <div className="flex gap-1">
            <FullFormikInput name="custCity" label="City" className="w-3/6" />
            <FullFormikInput name="custState" label="State" className="w-1/6" />
            <FullFormikInput name="custPostalCode" label="Postal Code" className="w-2/6" />
          </div>
          <div className="flex w-full gap-1">
            <FullFormikInput name="email" label="Email" type="email" />
            <FullFormikInput name="phone" label="Phone #" />
          </div>
          <div className="flex justify-between items-center mt-2">
            <button type="button" onClick={() => setActiveTab('step2')} className="underline text-brand font-semibold text-sm">
              Go Back
            </button>
            <Button text="Submit" color="navy" size="sm" type="submit" />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default NewCustomerForm;
import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_432_1105)">
        <path
          d="M3.70001 12H0.700012C0.42387 12 0.200012 12.2239 0.200012 12.5V15.5C0.200012 15.7761 0.42387 16 0.700012 16H3.70001C3.97615 16 4.20001 15.7761 4.20001 15.5V12.5C4.20001 12.2239 3.97615 12 3.70001 12Z"
          fill="currentColor"
        />
        <path
          d="M9.70001 7H6.70001C6.42387 7 6.20001 7.22386 6.20001 7.5V15.5C6.20001 15.7761 6.42387 16 6.70001 16H9.70001C9.97615 16 10.2 15.7761 10.2 15.5V7.5C10.2 7.22386 9.97615 7 9.70001 7Z"
          fill="currentColor"
        />
        <path
          d="M15.7 11H12.7C12.4239 11 12.2 11.2239 12.2 11.5V15.5C12.2 15.7761 12.4239 16 12.7 16H15.7C15.9762 16 16.2 15.7761 16.2 15.5V11.5C16.2 11.2239 15.9762 11 15.7 11Z"
          fill="currentColor"
        />
        <path
          d="M7.26301 3.757C7.5496 3.91637 7.8721 4 8.20001 4C8.52793 4 8.85043 3.91637 9.13701 3.757L12.3 6.4C12.2359 6.59364 12.2022 6.79604 12.2 7C12.1945 7.44432 12.3353 7.87812 12.6008 8.2345C12.8662 8.59089 13.2414 8.85008 13.6687 8.97212C14.096 9.09416 14.5515 9.07227 14.9651 8.90983C15.3787 8.74738 15.7274 8.45341 15.9574 8.07321C16.1875 7.69302 16.286 7.24773 16.238 6.80598C16.1899 6.36422 15.9979 5.95054 15.6915 5.62871C15.3851 5.30688 14.9814 5.09476 14.5425 5.02506C14.1036 4.95535 13.654 5.03194 13.263 5.243L10.1 2.6C10.1641 2.40636 10.1978 2.20396 10.2 2C10.2 1.46957 9.9893 0.960859 9.61423 0.585786C9.23915 0.210714 8.73045 0 8.20001 0C7.66958 0 7.16087 0.210714 6.7858 0.585786C6.41073 0.960859 6.20001 1.46957 6.20001 2C6.2022 2.20396 6.23593 2.40636 6.30001 2.6L3.13701 5.243C2.84961 5.08568 2.52765 5.00219 2.20001 5C1.80445 5 1.41777 5.1173 1.08887 5.33706C0.759974 5.55682 0.503629 5.86918 0.352254 6.23463C0.200879 6.60009 0.161272 7.00222 0.238443 7.39018C0.315613 7.77814 0.506095 8.13451 0.7858 8.41421C1.0655 8.69392 1.42187 8.8844 1.80983 8.96157C2.19779 9.03874 2.59993 8.99913 2.96538 8.84776C3.33083 8.69638 3.64319 8.44004 3.86295 8.11114C4.08272 7.78224 4.20001 7.39556 4.20001 7C4.19783 6.79604 4.1641 6.59364 4.10001 6.4L7.26301 3.757Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_432_1105">
          <rect width="16" height="16" fill="white" transform="translate(0.200012)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

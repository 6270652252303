
export default function Auth145Layout( { children } : { children: React.ReactNode } ) {
    return (
        <div className="flex flex-col justify-center items-center relative mb-10">
            <div className="flex mt-4 w-full relative" >
                <div className="bg-brand w-full mx-8 text-center py-2 rounded-t mb-[-10px] min-w-[100vw] md:min-w-0">
                    <span className="text-2xl text font-semibold text-white">MRO Employee Login</span>
                </div>
            </div>
            {children}
        </div>
    );
}
import { useMemo, useState, useEffect } from 'react';
import {
  GetMroWorkOrderQuery,
  GetWorkOrderBillingDocument,
  GetWorkOrderBillingQuery,
  LastEstimateDocument,
  CreateMroEstimateDocument,
  MroEstimateCreateInput,
  GetOrganizationRateDocument,
  GetMroWorkOrderBillingItemsDocument,
} from 'graphql/generated';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { useMutation, useQuery } from '@apollo/client';
import { DocumentCheckIcon } from '@heroicons/react/24/solid';
import { useSession } from 'contexts';
import EstimateView from '../partials/EstimateView';

const NewEstimate = function ({
  workOrder,
  customer,
  closeFlyout,
}: {
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  customer: GetWorkOrderBillingQuery['mroWorkOrder']['mroCustomer'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
}) {
  const { user } = useSession();
  const { data : { mroWorkOrder : { billableRate } = {} } = {} } = useQuery(GetOrganizationRateDocument, { variables: { mroWorkOrderId: workOrder.id } });

  const initialVals = useMemo(() => {
    const init = {
      estimateNumber: 1,
      date: new Date().toISOString(),
      items: workOrder.mroWorkOrderItems
        .filter((item) => item.status !== 'ABORT')
        .map((item) => {          
          const partsCost = Number(item.mroWorkOrderParts.reduce((acc, part) => acc + part.unitPrice * part.quantity, 0));
          const laborCost = Number(item.isFlatRate ? item.flatRate : (item.estimatedHours * billableRate));
          return {
            id: item.id,
            title: item.title,
            laborHours: item.estimatedHours,
            laborCost: laborCost,
            partsCost,
            active: item.status === 'NOT_STARTED',
            severity: 'DISCRETIONARY',
            isFlatRate: item.isFlatRate,
            temp: false,
            subTotal: laborCost + partsCost,
            model: item.category?.mroComponent?.model,
            serialNumber: item.category?.mroComponent?.serialNumber,
            parts: item.mroWorkOrderParts
              .map((part) => {
                return {
                  quantity: part.quantity,
                  partNumber: part?.mroPart?.partNumber ?? part?.partNumber,
                  description: part?.mroPart?.description ?? part?.partDescription,
                  unitPrice: part.unitPrice,
                  subtotal: part.unitPrice * part.quantity,
                };
              }),
          };
        }),
      charges: [],
      discounts: [],
      laborSummary: {
        shopLabor: workOrder.mroWorkOrderItems
          .filter((item) => item.status === 'NOT_STARTED')
          .reduce((acc, item) => acc + Number(item.isFlatRate ? item.flatRate : (item.estimatedHours * billableRate)), 0),
        outsideLabor: 0,
        totalHours: workOrder.mroWorkOrderItems
          .filter((item) => item.status == 'NOT_STARTED')
          .reduce((acc, item) => item.estimatedHours, 0),
      },
      priceSummary: {
        laborFlat: 0,
        laborTM: 0,
        totalLabor: workOrder.mroWorkOrderItems
          .filter((item) => item.status === 'NOT_STARTED')
          .reduce((acc, item) => acc + Number(item.isFlatRate ? item.flatRate : (item.estimatedHours * billableRate)), 0),
        totalParts: 0,
        totalCharges: 0,
        totalShipping: 0,
        tax: 0,
        taxRate: 0,
        amountDue: 0,
        deposit: 0,
        discount: 0,
      },
    };
    init.priceSummary.totalParts = init.items.filter((item) => item.active).reduce((acc, item) => acc + item.partsCost, 0);
    const subtotal = init.priceSummary.totalLabor + init.priceSummary.totalParts;
    init.charges.forEach((charge) => {
      if (charge.percent) charge.total = Math.round(charge.percent * subtotal) / 100;
    });
    init.priceSummary.totalCharges = Math.round(init.charges.reduce((sum, charge) => sum + charge.total, 0) * 100) / 100;
    init.priceSummary.tax = 0;
    init.priceSummary.amountDue =
      init.priceSummary.totalLabor +
      init.priceSummary.totalParts +
      init.priceSummary.totalCharges +
      init.priceSummary.totalShipping +
      (subtotal + init.priceSummary.totalCharges) * init.priceSummary.tax;
    return init;
  }, [workOrder, customer, billableRate]);

  const [estimateItems, setEstimateItems] = useState<(typeof initialVals.items[number] & { id?: string | number; desc?: string })[]>(
    initialVals.items
  ); 
  const [taxes, setTaxes] = useState(initialVals.priceSummary.tax);
  const [charges, setCharges] = useState<typeof initialVals.charges>(initialVals.charges);
  const [discounts, setDiscounts] = useState<typeof initialVals.discounts>(initialVals.discounts);

  const [vals, setVals] = useState({
    estimateNumber: initialVals.estimateNumber,
    date: initialVals.date,

    totalShipping: initialVals.priceSummary.totalShipping,
    totalParts: initialVals.priceSummary.totalParts,
    totalLabor: initialVals.priceSummary.totalLabor,
    totalHours: initialVals.laborSummary.totalHours,
    totalCharges: Math.round(initialVals.charges.reduce((acc, charge) => acc + charge.total, 0) * 100) / 100,
    discount: initialVals.priceSummary.discount,
    total: initialVals.priceSummary.amountDue,
  });
  useEffect(() => {
    setEstimateItems(initialVals.items);
    setVals({
      ...vals,
      totalParts: initialVals.priceSummary.totalParts,
      totalLabor: initialVals.priceSummary.totalLabor,
      total: initialVals.priceSummary.amountDue,
    });
  }, [initialVals]);

  const { data: { lastEstimate } = {} } = useQuery(LastEstimateDocument, { variables: { mroOrgId: user?.mroOrganizationId ?? '' } });
  const [createEstimate] = useMutation(CreateMroEstimateDocument, {
    refetchQueries: [
      {
        query: GetWorkOrderBillingDocument,
        variables: { mroWorkOrderId: workOrder.id },
      },
      {
        query: LastEstimateDocument,
        variables: { mroOrgId: user?.mroOrganizationId ?? '' },
      }
    ],
  });

  const handleSubmit = () => {
    const newVals: typeof initialVals & { total?: number; status?: string; mroWorkOrder?: MroEstimateCreateInput['mroWorkOrder'] } = {
      ...initialVals,
    };
    const activeItems = estimateItems.filter((item) => item.active);
    const laborFlat = Math.round(activeItems.filter((item) => item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    const laborTM = Math.round(activeItems.filter((item) => !item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    newVals.estimateNumber = Number(vals.estimateNumber);
    newVals.date = vals.date;
    newVals.items = activeItems;
    newVals.priceSummary = {
      amountDue: vals.total,
      deposit: initialVals.priceSummary.deposit,
      tax: Math.round((vals.totalLabor + initialVals.priceSummary.totalParts + vals.totalLabor) * taxes * 100) / 100,
      taxRate: taxes,
      totalCharges: vals.totalCharges,
      laborFlat: laborFlat,
      laborTM: laborTM,
      totalLabor: vals.totalLabor,
      totalParts: vals.totalParts,
      totalShipping: vals.totalShipping,
      discount: vals.discount,
    };
    newVals.laborSummary = {
      shopLabor: vals.totalLabor,
      outsideLabor: 0,
      totalHours: vals.totalHours,
    };
    newVals.total = vals.total;
    newVals.mroWorkOrder = { connect: { id: workOrder.id } };
    newVals.status = 'UNAPPROVED';
    newVals.charges = charges?.filter((charge) => charge.active) ?? [];
    newVals.discounts = discounts?.filter((discount) => discount.active) ?? [];
    createEstimate({ variables: { input: newVals } })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  
  useEffect(() => {
    // TO DO: MADE SURE ESTIMATE NUMBER IS UNIQUE
    setVals({ ...vals, estimateNumber: lastEstimate?.estimateNumber + 1 });
  }, [lastEstimate]);


  return (
    <div className="flex flex-col p-4 h-full">
      <EstimateView
        edit={true}
        estimate={{ ...initialVals, ...vals }}
        location={workOrder?.mroLocation?.code}
        workOrderId={workOrder.id}
        {...{ setCharges, charges, vals, setVals, estimateItems, setEstimateItems, taxes, setTaxes, discounts, setDiscounts }}
      />
      <div className="flex gap-2 items-center justify-end mt-2 pb-20">
        <button
          onClick={handleSubmit}
          className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          <>
            <DocumentCheckIcon className="h-2.5 w-2.5 mr-1" />
            Create Estimate
          </>
        </button>
      </div>
    </div>
  );
};

export default NewEstimate;

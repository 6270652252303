import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftDocument,
  GetComponentsDocument,
  CreateComponentDocument,
  ComponentCreateInput,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Card from 'components/Card/Card';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';


const New = function ({ closeFlyout }: { closeFlyout: () => void}) {
  const { craftId, user } = useSession();
  const [createComponent] = useMutation(CreateComponentDocument, {
    refetchQueries: [
      { query: GetComponentsDocument, variables: { craftId: craftId } },
      { query: GetCraftDocument, variables: { craftId } },
    ],
  });  
  const [prefill, setPrefill] = useState({ name: '' });
  

  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {
    const componentInput: ComponentCreateInput = {
      name: values.name,
      craft: { connect: { id: craftId } },
    };
    await createComponent({ variables: { input: componentInput } });
    setSubmitting(false);
    setPrefill({ name: '' });
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Card className='m-4'>
            <dl>
              <div className="p-4 flex items-center justify-center w-full">
                <FullFormikInput name='name' label='Name' className='w-full' />
              </div>
            </dl>
          </Card>
          <div className="px-4 mb-10 flex items-center justify-end">
            <Button
              type="submit"
              size='xs'
              disabled={isSubmitting}
              text='Create Component' />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default New;

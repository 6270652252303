import { useMutation } from '@apollo/client';
import { CreateServiceRequestDocument } from 'graphql/generated';
import { useContext, useState } from 'react';
import { getMroCustomer, ServiceFormContext } from '..';
import { AirplaneIcon } from 'assets/icons';
import Button from 'components/Button/Button';

const Confirm = () => {
  const {
    craftId,
    mroOrganization,
    tailNumber,
    customerInput,
    issuesInput,
    organization,
    craftInput,
    formStep,
    changeStep,
    markAsComplete,
    user
  } = useContext(ServiceFormContext);
  const [createServiceRequest] = useMutation(CreateServiceRequestDocument);
  const [isSubmitting, setSubmitting] = useState(false);
  const service = issuesInput.service === 'Other' ? issuesInput.otherService : issuesInput.service;
  const userProfile = user?.userOrganizationProfiles.find((profile) => profile.organization.id === organization?.id); 
  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setSubmitting(true);
    createServiceRequest({
      variables: {
        request: {
          craftId,
          mroOrganizationId: mroOrganization?.id,
          organizationId: organization?.id,
          title: tailNumber + '-' + issuesInput.service,
          description: service,
          notes: issuesInput.description,
          location: issuesInput.location,
          ...(craftId && { craftServiceRequests: {
              create: [ 
                {
                  craft: { connect: { id: craftId } },
                  userProfile: { connect: { id: userProfile?.id } },
                  title: tailNumber + '-' + issuesInput.service,
                  description: issuesInput.description,
                  mroOrganization: { connect: { id: mroOrganization?.id ?? '1' } },
                  status: 'SERVICE_REQUESTED',
                }
              ]
          } }),
          ...(!craftId && {
            mroCraft:{
              create: {
                tailNumber: tailNumber,
                year: craftInput?.year,
                make: craftInput?.make,
                model: craftInput?.model,
                ownerName: customerInput?.companyName,
            } }
          }),
          mroCustomer: {
            connectOrCreate: {
              where: {
                id: getMroCustomer(organization, mroOrganization?.id)?.id ?? '',
              },
              create: {
                name: customerInput.name,
                email: customerInput.email,
                phoneNumbers: customerInput.phone,
                mroOrganization: {
                  connect: {
                    id: mroOrganization?.id,
                  },
                },
                ...( organization && { organization: {
                  connect: {
                    id: organization?.id,
                  },
                } }),
                mroContacts: {
                  create: [
                    {
                      firstName: customerInput.name.split(' ')[0],
                      lastName: customerInput.name.split(' ')[1] ?? '',
                      email: customerInput.email,
                      phoneNumbers: customerInput.phone,
                      mroOrganization: {
                        connect: {
                          id: mroOrganization?.id,
                        },
                      },
                    },
                  ],
                },
                ...( customerInput.address1 && { billingAddress: {
                  create: {
                    address: customerInput.address1,
                    address2: customerInput.address2,
                    city: customerInput.city,
                    region: customerInput.state,
                    postalCode: customerInput.zip,
                    country: 'US',
                    title: customerInput.name + 'Billing Address',
                  },
                }} ),
              },
            },
          },
          
        },
      },
    });
    changeStep(formStep, 'completed');
    markAsComplete();
  };

  return (
    <div className="flex flex-col w-96 p-6">
      {/* {JSON.stringify({ craftId, mroOrganizationId, tailNumber, customerInput, issuesInput })} */}
      <h2 className="text-3xl md:text-2xl font-bold text-brand mb-4">Confirm Details</h2>
      <div className="flex-col items-center w-full border bg-slate-50 border-slate-300 rounded-t py-2 px-4 font-bold text-brand-dark">
        <div className="flex">
          <AirplaneIcon className="h-4 w-4 mr-2 text-brand" />
          <span className="font-bold text-brand-dark">
            {tailNumber} - {service}
          </span>
        </div>
        <span>@ {issuesInput.location}</span>
      </div>

      <div className="flex flex-col text-sm text-brand border border-t-0 border-slate-300 rounded-b p-4 mb-2">
        <span className="font-bold">{customerInput.name}</span>
        <span>{customerInput.companyName}</span>
        <span>{customerInput.address1}</span>
        <span>{customerInput.address2}</span>
        <div className="flex gap-1">
          <span>{customerInput.city},</span>
          <span>{customerInput.state}</span>
          <span>{customerInput.zip}</span>
        </div>
        <span>{customerInput.phone}</span>
        <span>{customerInput.email}</span>
      </div>
      { issuesInput.description && (
        <>
          <span className="text-brand text-sm font-semibold">Notes</span>
          <div className="text-sm rounded border border-slate-300 bg-slate-50 p-4 text-brand mb-6">{issuesInput.description}</div>
        </>
      )} 
      <div className="flex justify-between items-center">
        <button
          type="button"
          className="text-brand underline font-medium text-sm float-right"
          onClick={() => changeStep(formStep, false, true)}>
          Go Back
        </button>
        <Button onClick={(e) => !isSubmitting && handleSubmit(e)} text="Submit Request" color="navy" size="sm" type="submit" />
      </div>
    </div>
  );
};

export default Confirm;

import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { GetMroPartRequestsDocument } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { Table } from 'components/Table/Table';
import StatusButton from 'components/StatusButton/StatusButton';
import UserIcon from 'components/UserIcon/UserIcon';
import { useSession } from 'contexts';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import RequestFlyout from './View';
import Tabs from 'components/Tabs/Tabs';
import { formatApiDate } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import { PartsContext } from '..';
import { create, insertBatch, Lyra, PropertiesSchema, search } from '@lyrasearch/lyra';
import WorkOrderNumber from 'components/WorkOrderNumber/WorkOrderNumber';

const lyraSchema: PropertiesSchema = {
  status: 'string',
  partNumber: 'string',
  neededDate: 'string',
  mroWorkOrderTitle: 'string',
  workOrderNum: 'number',
  shippingType: 'string',
};

const Requests = function ({ updateFlyout, partId }: { updateFlyout: FlyoutHookReturn['updateFlyout']; partId?: string }) {
  const { user } = useSession();
  const [activeTab, setActiveTab] = useState('Requested');
  const tabs = ['Requested', 'Pending', 'Pick Ups', 'Completed', 'Cancelled', 'All'];
  const { data: { mroWorkOrderParts = [] } = {} } = useQuery(GetMroPartRequestsDocument, {
    variables: {
      where: {
        mroWorkOrderItem: { is: { mroWorkOrder: { is: { mroOrganizationId: { equals: user.mroOrganizationId } } } } },
        status: { notIn: ['DRAFT', 'AWAITING_APPROVAL'] },
        ...(partId && { mroPartId: { equals: partId } }),
      },
    },
    fetchPolicy: 'network-only',
    pollInterval: 300000, // 5 minutes
  });
  const { filter } = useContext(PartsContext);
  const [searchIndex, setSearchIndex] = useState<Lyra<typeof lyraSchema>>();
  const [tableData, setTableData] = useState([]);

  // HANDLES FILTER TAB FUNCTIONALITY
  useEffect(() => {
    if (mroWorkOrderParts?.length)
      (async () => {
        let mutable = mroWorkOrderParts;
        if (activeTab !== 'All') {
          if (activeTab === 'Pick Ups') {
            mutable = mroWorkOrderParts?.filter((req) => req.status === 'READY_FOR_PICKUP');
          } else if (activeTab === 'Completed') {
            mutable = mroWorkOrderParts?.filter((req) => req.status === 'INSTALLED');
          } else if (activeTab === 'Cancelled') {
            mutable = mroWorkOrderParts?.filter((req) => req.status === 'Cancelled'); 
          }else if (activeTab === 'PEDNING') {
            mutable = mroWorkOrderParts?.filter(
              (req) =>
                req.status === 'AWAITING_BACKORDER' ||
                req.status === 'ORDERED' ||
                req.status === 'PENDING' ||
                req.status === 'BACKORDER_CREATED' ||
                req.status === 'PENDING_BACKORDER'
            );
            
          } else {
            mutable = mroWorkOrderParts?.filter(
              (req) =>
                req.status === 'REQUESTED' ||
                req.status === 'AWAITING_BACKORDER' ||
                req.status === 'ORDERED' ||
                req.status === 'PENDING' ||
                req.status === 'BACKORDER_CREATED' ||
                req.status === 'PENDING_BACKORDER'
            );
          }
        }
        if (filter) {
          const result = await search(searchIndex, { term: filter, properties: '*' });
          const ids = result.hits.map((item) => item.id);
          mutable = mutable.filter((profile) => ids.includes(profile.id));
        }
        setTableData(mutable);
      })();
  }, [mroWorkOrderParts, filter, searchIndex, activeTab, partId]);

  useEffect(() => {
    if (mroWorkOrderParts?.length) {
      create({
        schema: lyraSchema,
      }).then((db) =>
        insertBatch(
          db,
          mroWorkOrderParts.map((req) => ({
            id: req.id,
            status: webFormat(req.status),
            partNumber: req.partNumber,
            neededDate: formatApiDate(req.neededDate),
            shippingType: webFormat(req.shippingType),
            mroWorkOrderTitle: req.mroWorkOrder.title,
            mroWorkOrderNum: req.mroWorkOrder.workOrderNum,
          }))
        ).then(() => setSearchIndex(db))
      );
    }
  }, [mroWorkOrderParts]);

  const columns = useMemo(
    () => [
      {
        Header: 'Need By',
        accessor: (obj) => {
          return {
            neededDate: obj.neededDate,
            shippingType: obj.shippingType,
          };
        }, // accessor is the "key" in the data
        className: 'w-2/12',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <div>{(value.neededDate ? formatApiDate(value.neededDate) : 'N/A') + ' '}</div>
              { value.shippingType && <div className="font-bold">({webFormat(value.shippingType)})</div>}
            </div>
          );
        },
      },
      ...(partId
        ? []
        : [
            {
              Header: 'Part #',
              accessor: (part: any) => ({
                partNumber: part?.partNumber,
                partId: part?.mroPart?.id,
              }), // accessor is the "key" in the data
              className: 'w-2/12',
              Cell: ({ value, row }: any) => {
                return (
                  <Link
                    to={value?.partId ?? '#'}
                    className={`${
                      value?.partId ? 'underline' : 'pointer-events-none'
                    } text-brand-dark font-bold bg-slate-100 rounded px-2 py-1`}>
                    {value?.partNumber}
                  </Link>
                );
              },
            },
          ]),
      {
        Header: 'Work Order',
        accessor: 'mroWorkOrder',
        className: 'w-5/12',
        Cell: ({ value, row }: any) => {
          return (
            <Link to={`/mro/work-orders/${value.id}`} className="font-medium underline">
              <WorkOrderNumber code={value?.mroWorkOrderCategory?.code} workOrderNum={value?.workOrderNum} />
              {value?.title}
            </Link>
          );
        },
      },
      {
        Header: '',
        accessor: 'mroWorkOrderItem.notes',
        className: 'w-1/12 min-w-[96px] justify-end',
        Cell: ({ value, row }: any) => {
          return <>{value && <ChatBubbleBottomCenterTextIcon className="h-6 w-6 p-1 rounded bg-slate-100" />}</>;
        },
      },
      {
        Header: 'Requester',
        accessor: 'requester',
        className: 'justify-end w-1/12 min-w-[96px]',
        Cell: ({ value }: any) => {
          return (
            <UserIcon
              iconSize="sm"
              iconText={value?.firstName + ' ' + value?.lastName}
              iconId={value?.firstName + ' ' + value?.lastName}
              iconColor={value?.profileColor}
              className=""
            />
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'quantity',
        className: 'justify-end w-1/12 min-w-[96px]',
        Cell: ({ value }: any) => {
          return <span>x{value}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-2/12 min-w-[180px]',
        Cell: ({ value }: any) => {
          return <StatusButton readOnly={true} context="parts" text={value ?? 'N_/_A'} className="" onSelect={(e) => {}} />;
        },
      },
    ],
    []
  );

  return (
    <div>
      <div
        className={`flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 -mt-2 ${
          !partId && 'w-[calc(100%+3rem)] -ml-6 px-8'
        } `}>
        <div className="flex items-start">
          <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
        </div>
      </div>
      <div className={`bg-white border-slate-200 ${partId && 'border'}`}>
        <Table
          onRowClick={(obj) => updateFlyout({ title: "Request for Part "+obj.partNumber, content: <RequestFlyout partRequestId={obj.id} /> }) }
          columns={columns}
          data={tableData ?? []}
        />
      </div>
    </div>
  );
};

export default Requests;

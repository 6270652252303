import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PlusIcon } from '../../../../assets/icons';
import {  WrenchIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { GetRequestsDocument, GetSquawksDocument } from 'graphql/generated';
import { useSession } from 'contexts/SessionContext';
import Button from 'components/Button/Button';
import Tabs from 'components/Tabs/Tabs';
import { useNavigate } from 'react-router-dom';
import useParamState from 'hooks/useParamState';
import Request from 'components/Request/Request';

const Requests = () => {
  const { craftId } = useSession();
  const { loading, error, data } = useQuery(GetRequestsDocument, { variables: { where: { craft: { is: { id: { equals: craftId } } } } } });
  const navigate = useNavigate();
  const tabs = [ 'All', 'Open', 'Closed' ];
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [activeTab, setActiveTab] = useParamState('requestsTab', 'All');
  const tableData = useMemo(() => {
    let statuses = [];
    switch (activeTab) {
      case 'All':
        return data?.craftServiceRequests;
      case 'Open':
        statuses = ['service_requested', 'pending', 'estimate_pending', 'invoice_pending', 'scheduled', 'in_progress'];
        break;
      case 'Closed':
        statuses = ['completed', 'closed'];
        break;
    }
    return data?.craftServiceRequests?.filter((item) => statuses.includes(item?.status.toLowerCase())) ?? [];
  }, [data, activeTab]);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.toString()}</p>;
  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="px-2 md:px-8">
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
            <div className="flex items-center">
              <WrenchIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Service Requests</h1>
            </div>
            <div className="flex items-stretch gap-2">
              <Button text="Create Request" color="navy" size="xs" icon={<PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />}
                onClick={ () => navigate('/mro/service-request') } /> 
            </div>
          </div>
        </div>
        {/* Requests List */}
        <div className={`flex`}>
          <div className="w-full">
            <div className="rounded shadow bg-white">
              <div className="flex justify-between items-center border border-slate-200 pt-1 bg-slate-50 w-full px-4 mb-3">
                <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
              </div> 
              {tableData?.map((request, index, arr) => {
                let last = arr.length - 1 === index ? true : false;
                return <Request request={request} updateFlyout={updateFlyout} closeFlyout={closeFlyout} last={last} key={request.id} />;
              })}
              {tableData?.length === 0 && <div className="flex justify-center items-center p-4">
                <h1 className="text-center text-slate-500">No Requests Found</h1>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Requests;

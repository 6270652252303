import { useState } from "react";

//Example invocation
//<SegmentControl items={['New Check', 'Logs']} onChange={ (item, index) => {} } />

function SegmentControl({  items, onChange, activeItem: aItem, className ='' }) {
  let [activeItem, setActiveItem] = useState(aItem ?? items[0]);
  let tabItems = items || [];
  return(
    <div className={"flex rounded border p-1 gap-1 "+className}>
      {tabItems.map((item, index) => {
        return (
          <button 
            key={index}
            type="button"
            onClick={() => {setActiveItem(item); onChange?.(item);}} 
            className={`${activeItem === item ? 'bg-brand text-white' : 'bg-white text-brand-dark'} font-bold rounded p-1 flex-1`}>
            {item}
          </button>
        )
      })}
    </div>
  )
}

export default SegmentControl;

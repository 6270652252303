import { useMemo, useState, useEffect, useCallback, ChangeEventHandler } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button/Button';
import {
  CreateMroPartOrderDocument,
  GetLastPartOrderDocument,
  GetMroPartOrdersDocument,
  GetMroPartRequestsDocument,
  GetMroPartVendorsDocument,
  PartCondition,
} from 'graphql/generated';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { formatDateChange, formatFieldDate, formatUSD, formatWithZeros } from 'utils/formatter';
import { useMutation, useQuery } from '@apollo/client';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { useSession } from 'contexts';
import { randomID } from 'utils/random';
import { Switch } from '@headlessui/react';
import VendorCard from '../Partials/VendorCard';
import PartOrderItems from '../Components/PartOrderItems';

export default function NewPartOrder({ closeFlyout, ...props }: { closeFlyout: FlyoutHookReturn['closeFlyout'] }) {
  const { user } = useSession();
  const { data: { mroWorkOrderParts = [] } = {} } = useQuery(GetMroPartRequestsDocument, {
    variables: {
      where: {
        AND: [
          { status: { equals: 'AWAITING_BACKORDER' } },
          { mroWorkOrderItem: { is: { mroOrganizationId: { equals: user.mroOrganizationId } } } },
        ],
      },
    },
  });
  const { data: { lastPartOrder } = {} } = useQuery(GetLastPartOrderDocument, { variables: { mroOrgId: user.mroOrganizationId } });
  const [createPartOrder] = useMutation(CreateMroPartOrderDocument, {
    refetchQueries: [GetMroPartOrdersDocument, GetLastPartOrderDocument, GetMroPartRequestsDocument, GetMroPartVendorsDocument],
  });
  const { toastProps, showToast } = useToast();
  const [partOrderItems, setPartOrderItems] = useState<
    {
      id: string | number;
      part?: any;
      partQuantity?: number;
      partPrice?: number;
      requestedDate?: Date;
      condition?: PartCondition;
      notes?: string;
      temp?: boolean;
      active?: boolean;
      workOrderPartId?: string;
      workOrderTitle?: string;
      workOrderNumber?: number;
    }[]
  >([]);

  const [partOrder, setPartOrder] = useState({
    orderNumber: '00001',
    issuedDate: new Date(),
    notes: '',
  });

  const [vendorInfo, setVendorInfo] = useState({
    code: '',
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
  });
  const [selectedVendor, setSelectedVendor] = useState<any>();

  const [includePartRequests, setIncludePartRequests] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialItems = useMemo(() => {
    return mroWorkOrderParts?.map((request) => {
      
      return {
        id: randomID(8),
        part: request.mroPart,
        partQuantity: request.quantity,
        partPrice: request.unitPrice,
        requestedDate: request.neededDate,
        condition: PartCondition.New,
        notes: request.notes,
        temp: false,
        active: false,
        workOrderPartId: request.id,
        workOrderTitle: request.mroWorkOrder?.title,
        workOrderNumber: request.mroWorkOrder?.workOrderNum,
      };
    });
  }, [mroWorkOrderParts]);

  useEffect(() => {
    if (initialItems) {
      setPartOrderItems(initialItems);
    }
  }, [initialItems]);

  useEffect(() => {
    if (lastPartOrder) {
      setPartOrder({ ...partOrder, orderNumber: formatWithZeros(Number(lastPartOrder.orderNumber) + 1, 5) });
    }
  }, [lastPartOrder]);

  const handleChange: (field: string) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (field) => (e) => {
    setPartOrder({ ...partOrder, [field]: e.target.value });
  };

  //ADDS AN ITEM WITH TEMP:TRUE, THIS MAKES IT DELETABLE. IF FALSE NO DELETE BUTTON.
  const handleAddItem = function () {
    setPartOrderItems([
      ...partOrderItems,
      {
        id: randomID(8),
        temp: true,
        active: true,
        condition: PartCondition.New,
        part: undefined,
        partQuantity: 0,
        partPrice: 0,
        requestedDate: new Date(),
        notes: '',
      },
    ]);
  };

  const handleRemoveItem = function (index) {
    let mutable = [...partOrderItems];
    mutable.splice(index, 1);
    setPartOrderItems(mutable);
  };

  const handleItemUpdate = useCallback(function (index, newItem) {
    setPartOrderItems((estItems) => {
      let mutable = [...estItems];
      mutable[index] = { ...mutable[index], ...newItem };
      return mutable;
    });
  }, []);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const validItems = partOrderItems?.filter((item) => {
      if (item.temp && item.active) {
        return true;
      } else if (!item?.temp && item?.active && includePartRequests) {
        return true;
      }
      return false;
    });

    if (validItems.find((item) => !item.part)) {
      setIsSubmitting(false);
      return showToast({ title: 'Missing Part', subtitle: 'Please select a part for all items', type: ToastLength.Long });
    }
    if (validItems.length === 0) {
      setIsSubmitting(false);
      return showToast({ title: 'No items to order', subtitle: 'Please select items to order', type: ToastLength.Long });
    }
    createPartOrder({
      variables: {
        input: {
          status: 'DRAFT',
          mroOrganization: { connect: { id: user.mroOrganizationId } },
          buyer: { connect: { id: user.mroEmployeeProfileId } },
          ...partOrder,
          ...(selectedVendor && selectedVendor !== 'newVendor'
            ? { mroPartVendor: { connect: { id: selectedVendor?.id } } }
            : {
                mroPartVendor: {
                  create: {
                    mroOrganization: { connect: { id: user.mroOrganizationId } },
                    vendorCode: vendorInfo.code,
                    name: vendorInfo.name,
                    phoneNumbers: { phone: vendorInfo.phone, fax: vendorInfo.fax },
                    address: {
                      create: {
                        title: vendorInfo.name + ' Address',
                        address: vendorInfo.address,
                        address2: vendorInfo.address2,
                        city: vendorInfo.city,
                        region: vendorInfo.state,
                        postalCode: vendorInfo.zip,
                      },
                    },
                  },
                },
              }),
          mroPartOrderItems: {
            create: partOrderItems.map((item) => {
              return {
                status: 'NOT_RECEIVED',
                mroPart: { connect: { id: item.part.id } },
                ...(item.workOrderPartId && { mroWorkOrderPart: { connect: { id: item.workOrderPartId } } }),
                quantity: item.partQuantity,
                unitPrice: item.partPrice,
                requestedDate: item.requestedDate,
                condition: item.condition as PartCondition,
                notes: item.notes,
                active: item.active,
              };
            }),
          },
        },
      },
    })
      .then(() => {
        setIsSubmitting(false);
        closeFlyout();
      })
      .catch(console.error);
  };

  return (
    <>
      <Toast {...toastProps} />
      <div className="p-4 max-h-[95%] flex flex-col gap-5">
        {/* HEADER */}
        <div className="flex flex-col gap-4 w-full justify-end bg-white rounded shadow border border-slate-200 p-6">
          <div className="flex justify-between items-end shrink-0">
            <span className="text-slate-600">Order Number:</span>
            <h1 className="flex text-brand-dark justify-end font-bold text-xl">
              <span className="text-slate-500 font-normal">#</span>
              <input
                className="w-[110px] text-brand-dark font-bold placeholder:text-slate-400 border -ml-5 pl-5 bg-transparent shadow-inner disabled:border-0 disabled:shadow-none"
                placeholder=""
                value={partOrder.orderNumber}
                onChange={handleChange?.('orderNumber')}
              />
            </h1>
          </div>
          <div className="flex justify-between items-end shrink-0">
            <span className="text-slate-600">Issued Date:</span>
            <input
              className="text-brand-dark font-bold -mt-0.5 p-0 border-0 text-right"
              type="date"
              value={formatFieldDate(partOrder.issuedDate)}
              onChange={(e) => {
                setPartOrder({ ...partOrder, issuedDate: formatDateChange(e.target.value) as Date });
              }}
            />
          </div>
        </div>
        {/* VENDOR */}
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-2">
          <div className="flex-initial mb-4">
            <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3 mb-2">
              <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Vendor</span>
            </div>
            <VendorCard {...{ vendorInfo, setVendorInfo, selectedVendor, setSelectedVendor, edit: true }} />
          </div>
        </div>
        {/* ITEMS */}
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-4">
          <div className="flex-initial mb-4">
            {mroWorkOrderParts?.length > 0 && (
              <>
                <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3">
                  <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Requested Items</span>
                  <div className="flex items-end">
                    <span className="text-sm text-slate-500 font-medium mr-2">Include Requested Items</span>
                    <Switch
                      checked={includePartRequests}
                      onChange={() => setIncludePartRequests(!includePartRequests)}
                      className={`${includePartRequests ? 'bg-brand-electric' : 'bg-slate-300'}
                          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
                      <span
                        aria-hidden="true"
                        className={`${includePartRequests ? 'translate-x-6' : 'translate-x-0'}
                            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>
                </div>
                {includePartRequests &&
                  partOrderItems
                    .filter((item) => item.temp === false)
                    .map((entry, index) => {
                      return <PartOrderItems key={index} edit={true} {...{ handleItemUpdate, handleRemoveItem, index, entry }} />;
                    })}
              </>
            )}
            {partOrderItems.filter((item) => item.temp === true).length > 0 && (
              <>
                <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3">
                  <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Items</span>
                </div>
                {partOrderItems
                  .filter((item) => item.temp === true)
                  .map((entry, index) => {
                    return (
                      <PartOrderItems
                        key={index}
                        entry={entry}
                        edit={true}
                        handleItemUpdate={handleItemUpdate}
                        handleRemoveItem={handleRemoveItem}
                        index={index + mroWorkOrderParts?.length}
                      />
                    );
                  })}
              </>
            )}
            {partOrderItems.length === 0 && (
              <div className="flex justify-center items-center h-15">
                <span className="text-slate-500 text-sm">No Order Items Added</span>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between border-t border-slate-200 rounded-b py-3 px-4 text-sm -mx-4">
            <button
              onClick={() => {
                handleAddItem();
              }}
              className="flex shrink-0 items-center bg-white text-brand-dark border border-slate-300 text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              Add Item
            </button>
            <div className="flex gap-4 items-center">
              <span className="font-bold text-brand">Total:</span>
              <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm">
                {formatUSD(
                  partOrderItems?.reduce((acc, cur) => {
                    if (!cur.active) {
                      return acc;
                    }
                    if (!cur.temp && !includePartRequests) {
                      return acc;
                    }
                    return acc + Number(cur.partPrice * cur.partQuantity);
                  }, 0)
                )}
              </span>
            </div>
          </div>
        </div>
        {/* NOTES */}
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-2">
          <div className="flex-initial mb-4">
            <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3 mb-2">
              <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Order Comments</span>
            </div>
            <div className="flex flex-col gap-2">
              <textarea
                className="border border-slate-200 rounded p-2 text-sm"
                placeholder="Enter order comments here..."
                value={partOrder.notes}
                onChange={handleChange?.('notes')}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2 pb-20">
          <Button
            color="navy"
            size="xs"
            text="Create Part Order"
            disabled={partOrderItems.filter((item) => item.active).length === 0 || isSubmitting}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

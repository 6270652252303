import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikInput, FullFormikTextarea } from "components/Form/StandardForm";
import Modal from "components/Modal/Modal";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetMroRtsTextDocument, GetMroRtsTextsDocument, RemoveMroRtsTextDocument, UpdateMroRtsTextDocument } from "graphql/generated";
import { useEffect, useState } from "react";

export default function ViewRTSText({ id, closeFlyout }: { id: string; closeFlyout: () => void }) {
    const { user } = useSession();
    const [ showModal, setShowModal ] = useState(false);
    const { data: { mroRTSText } = {} } = useQuery(GetMroRtsTextDocument, { variables: { id } });
    const [deleteRTSText] = useMutation(RemoveMroRtsTextDocument, {
        onCompleted: () => closeFlyout(),
        refetchQueries: [{ query: GetMroRtsTextsDocument, variables: { mroOrganizationId: user?.mroOrganizationId } }],
    });
    const [updateRTSText] = useMutation(UpdateMroRtsTextDocument, {
        refetchQueries: [{ query: GetMroRtsTextsDocument, variables: { mroOrganizationId: user?.mroOrganizationId } },
                        { query: GetMroRtsTextDocument, variables: { id } }
        ],
    });
    const [initialValues, setInitialValues] = useState({
        title: mroRTSText?.title,
        body: mroRTSText?.body,
    });
    useEffect(() => {
        setInitialValues({
            title: mroRTSText?.title,
            body: mroRTSText?.body,
        });
    }, [mroRTSText]);
    return (        
        <>
            { showModal && ( <Modal message={'You are about to delete this RTS Statment.'} accept={ () => deleteRTSText({ variables:{ id } })} cancel={setShowModal} /> ) }
            <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
                updateRTSText({
                    variables: {
                        input: {
                            id: mroRTSText.id,
                            ...values
                        },
                    },
                })
                .then(() => closeFlyout())
                .catch(console.error);
            }}>
                <Form className="flex flex-col gap-5 mx-5">
                    <Card className="">
                        <div className="flex gap-5 flex-col">
                            <FullFormikInput name="title" placeholder="Title" label="Name" />
                            <FullFormikTextarea name="body" placeholder="RTS Statement Here" label="RTS Statement" />
                        </div>
                    </Card>
                    <div className="flex justify-end gap-10">
                        <Button text="Delete" color="red" size="xs" onClick={() => setShowModal(true) } />
                        <Button text="Save" color="navy" size="xs" type="submit" />
                    </div>
                </Form>
            </Formik>
        </>
    );
}
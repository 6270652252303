import { useEffect, useState } from 'react';
import { CheckIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GetMroEmployeeProfilesDocument, GetMroEmployeeProfilesQuery } from 'graphql/generated';
import UserIcon from 'components/UserIcon/UserIcon';
import { useSession } from 'contexts';

export default function EmployeeSelect({ selectedProfiles, setSelectedProfiles }) {
  const [query, setQuery] = useState('');
  const { user } = useSession();

  const { data: { mroEmployeeProfiles: filteredProfiles } = { mroEmployeeProfiles: [] }, refetch } = useQuery(
    GetMroEmployeeProfilesDocument,
    { variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } } }
  );

  const handleProfileSelection = function (emp) {
    let tempArr = [...selectedProfiles];
    const i = tempArr.findIndex((e) => e.id === emp.id);
    //remove from selectedProfiles array
    if (i > -1) {
      tempArr.splice(i, 1);
    }
    //add to selectedProfiles array
    else {
      tempArr.push(emp);
    }
    setSelectedProfiles(tempArr);
  };

  let selectedTags = (
    <>
      {selectedProfiles?.length > 0 && <span className="block text-xs text-slate-500 uppercase py-1 mt-2 -mb-1">Selected</span>}
      <div className="flex py-1 mb-2 gap-1">
        {selectedProfiles?.length > 0 ? (
          selectedProfiles?.map((emp, index) => {
            return (
              <div
                key={index}
                onClick={() => handleProfileSelection(emp)}
                className="flex items-center text-brand-electric font-semibold text-sm text-left whitespace-nowrap p-[0.125rem] bg-brand-pale hover:bg-red-100 hover:border-red-500 hover:text-red-500 border border-brand-electric rounded transition cursor-pointer">
                <UserIcon
                  iconSize="xs"
                  iconText={emp.firstName + ' ' + emp.lastName}
                  iconId={emp.firstName + ' ' + emp.lastName}
                  iconColor={emp.profileColor}
                  className="scale-75 mr-[0.125rem]"
                />
                <span>{emp.firstName + ' ' + emp.lastName}</span>
                <XMarkIcon className="h-3 w-3 ml-1" />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );

  useEffect(() => {
    if (query) {
      const timeOutId = setTimeout(
        () =>
          refetch({
            where: {
              AND: [
                {
                  mroOrganizationId: { equals: user.mroOrganizationId },
                },
                {
                  OR: [
                    {
                      firstName: {
                        search: query.trim().replaceAll(' ', '|'),
                      },
                    },
                    {
                      lastName: {
                        search: query.trim().replaceAll(' ', '|'),
                      },
                    },
                  ],
                },
              ],
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    } else {
      refetch({
        where: {
          mroOrganizationId: { equals: user.mroOrganizationId },
        },
      });
    }
  }, [query, refetch, user]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center relative">
          <MagnifyingGlassIcon className="h-4 w-4 text-brand-electric left-3 absolute" />
          <div className={`rounded flex flex-wrap w-full pl-9 w-full rounded-t border border-slate-300 bg-slate-50 h-10`}>
            <input
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Type the name of an employee"
              className="w-full rounded bg-slate-50 grow text-brand font-bold focus:outline-0 placeholder:text-slate-400 placeholder:text-sm placeholder:font-normal"></input>
          </div>
        </div>
      </div>
      <div className="rounded max-h-[16rem] bg-slate-50 overflow-y-auto shadow-inner p-2 border border-slate-300 mt-2">
        <div className="bg-white rounded border border-slate-200 shadow-blue p-2">
          {filteredProfiles.map((emp, index) => {
            return (
              <div
                key={index}
                className="flex flex-row-reverse items-center rounded last:mb-0 relative group border-b border-brand-pale border-dashed last:border-b-0">
                <input
                  id={emp.id}
                  checked={selectedProfiles?.findIndex((e) => e.id === emp.id) > -1 ? true : false}
                  onChange={() => handleProfileSelection(emp)}
                  type="checkbox"
                  name="profileSelection"
                  className="cursor-pointer bg-transparent hidden-check border-0 focus:ring-0 focus:outline-0 absolute right-2 rounded-full"
                />
                <label
                  htmlFor={emp.id}
                  className="flex grow cursor-pointer items-center p-2 border border-white hover:bg-brand-offwhite hover:border-brand-pale rounded transition my-0.5">
                  <UserIcon
                    iconSize="xs"
                    iconText={emp.firstName + ' ' + emp.lastName}
                    iconId={emp.firstName + ' ' + emp.lastName}
                    iconColor={emp.profileColor}
                    className="mr-2"
                  />
                  <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col">
                      <span className="font-bold text-brand-dark">{emp.firstName + ' ' + emp.lastName}</span>
                    </div>
                  </div>
                </label>
                <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 hidden pointer-events-none" />
              </div>
            );
          })}
        </div>
      </div>
      {selectedTags}
    </>
  );
}

export function EmployeeSingleSelect({
  selectedProfile,
  setSelectedProfile,
  titles,
}: {
  selectedProfile: GetMroEmployeeProfilesQuery['mroEmployeeProfiles'][number];
  setSelectedProfile: (profile: GetMroEmployeeProfilesQuery['mroEmployeeProfiles'][number]) => void;
  titles?: string[];
}) {
  const [query, setQuery] = useState('');
  const { user } = useSession();
  const rand = Math.random();

  const { data: { mroEmployeeProfiles: filteredProfiles } = { mroEmployeeProfiles: [] }, refetch } = useQuery(
    GetMroEmployeeProfilesDocument,
    { variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId }, ...(titles && { title: { in: titles } }) } } }
  );

  const handleProfileSelection = function (emp) {
    if (selectedProfile?.id === emp.id) {
      setSelectedProfile(null);
    } else {
      setSelectedProfile(emp);
    }
  };

  useEffect(() => {
    if (query) {
      const timeOutId = setTimeout(
        () =>
          refetch({
            where: {
              AND: [
                {
                  mroOrganizationId: { equals: user.mroOrganizationId },
                  ...(titles && { title: { in: titles } }),
                },
                {
                  OR: [
                    {
                      firstName: {
                        search: query.trim().replaceAll(' ', '|'),
                      },
                    },
                    {
                      lastName: {
                        search: query.trim().replaceAll(' ', '|'),
                      },
                    },
                  ],
                },
              ],
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    } else {
      refetch({
        where: {
          mroOrganizationId: { equals: user.mroOrganizationId },
        },
      });
    }
  }, [query, refetch, user, titles]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center relative">
          <MagnifyingGlassIcon className="h-4 w-4 text-brand-electric left-3 absolute" />
          <div className={`rounded flex flex-wrap w-full pl-9 w-full rounded-t border border-slate-300 bg-slate-50 h-10`}>
            <input
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Type the name of an employee"
              className="w-full rounded bg-slate-50 grow text-brand font-bold focus:outline-0 placeholder:text-slate-400 placeholder:text-sm placeholder:font-normal"></input>
          </div>
        </div>
      </div>
      <div className="rounded max-h-[16rem] bg-slate-50 overflow-y-auto shadow-inner p-2 border border-slate-300 mt-2">
        <div className="bg-white rounded border border-slate-200 shadow-blue p-2">
          {filteredProfiles.map((emp, index) => {
            return (
              <div
                key={index}
                className="flex flex-row-reverse items-center rounded last:mb-0 relative group border-b border-brand-pale border-dashed last:border-b-0">
                <input
                  id={emp.id + rand}
                  checked={selectedProfile?.id === emp.id ? true : false}
                  onChange={() => handleProfileSelection(emp)}
                  type="checkbox"
                  name="profileSelection"
                  className="cursor-pointer bg-transparent hidden-check border-0 focus:ring-0 focus:outline-0 absolute right-2 rounded-full"
                />
                <label
                  htmlFor={emp.id + rand}
                  className="flex grow cursor-pointer items-center p-2 border border-white hover:bg-brand-offwhite hover:border-brand-pale rounded transition my-0.5">
                  <UserIcon
                    iconSize="xs"
                    iconText={emp.firstName + ' ' + emp.lastName}
                    iconId={emp.firstName + ' ' + emp.lastName}
                    iconColor={emp.profileColor}
                    className="mr-2"
                  />
                  <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col">
                      <span className="font-bold text-brand-dark">{emp.firstName + ' ' + emp.lastName}</span>
                    </div>
                  </div>
                </label>
                <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 hidden pointer-events-none" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

import { useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Combobox } from '@headlessui/react';
import { useLazyQuery } from '@apollo/client';
import { GetMroPartManufacturersDocument } from 'graphql/generated';
import { FormLabel } from 'components/Form/StandardForm';
import { useSession } from 'contexts';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function ManufacturerLookup({
  selectedManufacturer,
  setSelectedManufacturer,
  manufacturer,
  setManufacturer,
}: {
  selectedManufacturer: any;
  setSelectedManufacturer: any;
  manufacturer: any;
  setManufacturer: any;
}) {
  const [query, setQuery] = useState('');
  const { user } = useSession();
  const searchBox = useRef(null);

  const [search, { data: { mroPartManufacturers: filteredManufacturers } = 
    { mroPartManufacturers: [] } }] = useLazyQuery(GetMroPartManufacturersDocument);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        search({
          variables: {
            where: {
              name: {
                contains: query.trim().replaceAll(' ', ' & '),
              },
              mroParts: { some: { mroOrganizationId: { equals: user.mroOrganizationId } } },
            },
            limit: 5,
          },
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query, search, user]);
  return (
    <Combobox as="div" className="flex flex-col" value={selectedManufacturer?.name ?? 'newPart'} onChange={() => {}}>
      <Combobox.Label as={FormLabel}>Manufacturer</Combobox.Label>
      <div className="relative mb-4">
        <input
          className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full h-12 md:h-10"
          onChange={(event) => {
            setQuery(event.target.value);
            setManufacturer(event.target.value);
            setSelectedManufacturer(null);
          }}
          value={manufacturer}
          onClick={() => searchBox.current?.click()}
          placeholder="Search or enter a manufacturer"
        />

        <Combobox.Button ref={searchBox} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          { filteredManufacturers?.[0]?.name !== manufacturer && <Combobox.Option
            key={'newPart'}
            value={'newPart'}
            onClick={() => {
              setSelectedManufacturer('newPart');
            }}
            className={({ active }) =>
              classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
            }>
            {({ active, selected }) => (
              <>
                <div className="flex">
                  <span className={'font-semibold'}>New Manufacturer</span>
                </div>
                {selected && (
                  <span
                    className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option> }
          {filteredManufacturers.length > 0 &&
            filteredManufacturers.slice(0, 4).map((manf) => (
              <Combobox.Option<'li', any>
                key={manf.id}
                value={manf.name}
                onClick={() => {
                  setManufacturer(manf?.name);
                  setSelectedManufacturer(manf);
                }}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }>
                {({ active, selected }) => (
                  <>
                    <span className={classNames('truncate', selected && 'font-semibold')}>{manf?.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

import { useEffect, useMemo } from 'react';
import { Table } from 'components/Table/Table';
import NewEstimate from './New';
import { DocumentIcon, PlusIcon } from 'assets/icons';
import { useQuery } from '@apollo/client';
import { GetWorkOrderBillingDocument } from 'graphql/generated';
import { formatApiDate, formatWithZeros } from 'utils/formatter';
import StatusButton from 'components/StatusButton/StatusButton';
import EstimateFlyout from './View';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function Estimates({ mroWorkOrder, updateFlyoutMini, updateFlyout, closeFlyout, ...props }) {
  const { data: { mroWorkOrder: billingData } = {} } = useQuery(GetWorkOrderBillingDocument, {
    variables: { mroWorkOrderId: mroWorkOrder?.id },
  });

  const navigate = useNavigate();
  const location = useLocation();  
  const searchParams = new URLSearchParams(location.search);
  const defPath = '/mro/work-orders/'+mroWorkOrder?.id+'?tab=Estimates&estimateId=';

  const estimates = useMemo(() => {
    if (billingData) return billingData.mroEstimates;
    return [];
  }, [billingData]);

  //Launch Estimate Flyout with URL Params
  useEffect(() => {
    const estimateId = searchParams.get('estimateId');
    if (estimateId) {
      
      searchParams.delete('estimateId');
      const newURL = `${location.pathname}?${searchParams.toString()}`;
      navigate(newURL);
      
      updateFlyout({
        title: (
          <div className="flex items-center gap-2">
            <DocumentIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1>Estimate </h1>
          </div>
        ),
        content: <EstimateFlyout workOrder={mroWorkOrder} mroEstimateId={estimateId} />,
      });
    }
  }, [searchParams, mroWorkOrder]);

  const estimateColumns = useMemo(
    () => [
      {
        Header: 'Estimate #',
        accessor: 'estimateNumber', // accessor is the "key" in the data
        className: 'w-full font-semibold pb-3',
        Cell: ({ value }) => (
          <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm w-full text-center">
            {formatWithZeros(value, 4)}
          </span>
        ),
      },
      {
        Header: 'Date',
        accessor: 'date', // accessor is the "key" in the data
        className: 'w-full font-semibold pb-3',
        Cell: ({ value }) => formatApiDate(value),
      },
      {
        Header: 'Items',
        accessor: 'items.length',
        className: 'flex justify-end w-full',
      },
      {
        Header: 'Cost',
        accessor: 'total',
        className: 'flex justify-end w-full',
        Cell: ({ value }) => Number(value).toLocaleString(undefined, { style: 'currency', currency: 'USD' }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-64 min-w-[8rem]',
        Cell: ({ value, row }: any) => {
          return <StatusButton readOnly={true} context="estimate" text={value.toUpperCase()} className="" onSelect={(e) => {}} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner">
        <div className="flex flex-col w-full">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <DocumentIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">All Estimates</h1>
          </div>
          <Table
            className="w-full bg-white border rounded"
            columns={estimateColumns}
            data={estimates}
            onRowClick={(row) =>
              navigate(defPath + row.id)
            }
          />
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          {!estimates?.length && (
            <div className="flex items-center justify-center p-4 bg-slate-50 rounded-b border text-sm -mt-[2px] border-slate-200 shadow-inner">
              <button
                className="flex items-center font-semibold text-brand cursor-pointer underline"
                onClick={() =>
                  updateFlyout({
                    title: 'New Estimate',
                    content: <NewEstimate workOrder={mroWorkOrder} customer={mroWorkOrder.mroCustomer} closeFlyout={closeFlyout} />,
                  })
                }>
                <PlusIcon className="h-2.5 w-2.5 mr-1" />
                Create your first esimates
              </button>
            </div>
          )}
          <div className="mt-2 self-end">
            <button
              className="flex items-center bg-brand text-white border border-brand-electric text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all"
              onClick={(row) =>
                updateFlyout({
                  title: 'New Estimate',
                  content: <NewEstimate workOrder={mroWorkOrder} customer={mroWorkOrder.mroCustomer} closeFlyout={closeFlyout} />,
                })
              }>
              <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
              New Estimate
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full" />
    </>
  );
}

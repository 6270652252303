import { useMutation, useQuery } from '@apollo/client'; // need for signing
import { useEffect, useState } from 'react';
import {
  GetMroEmployeeDocument,
  GetWorkOrderLogEntriesDocument,
  UpdateMroLogEntryDocument,
  GetMroLogEntryQuery,
  GetMroLogEntryDocument,
} from 'graphql/generated';
import { Form, Formik } from 'formik';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useSession } from 'contexts';

export default function EditLogEntryFlyout({
  closeFlyout,
  logEntry,
}: {
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  logEntry: GetMroLogEntryQuery['mroLogEntry'];
}) {
  const [intervals, setIntervals] = useState(
    logEntry?.intervals.map((interval) => {
      return {
        name: interval.name,
        value: interval?.intervalLogs?.[0].startValue,
      };
    }) ?? []
  );
  const [signIndex, setSignIndex] = useState(0);
  const [signOptions, setSignOptions] = useState([]);

  const findExistingItem = (item) => logEntry.mroLogEntryItems.find((exisitingItem) => exisitingItem?.mroWorkOrderItem?.id === item.id);
  const [selectedSquawks, setSelectedSquawks] = useState(
    logEntry?.itemCategory?.mroWorkOrderItems
      .filter((item) => findExistingItem(item))
      .map((item) => {
        return {
          existingEntryItemId: findExistingItem(item).id,
          id: item.id,
          description: findExistingItem(item).description ?? item.logEntry,
          sortOrder: item.itemNumber,
        };
      })
  );

  const { user } = useSession();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
  });
  const [updateLogEntry] = useMutation(UpdateMroLogEntryDocument, {
    refetchQueries: [
      { query: GetWorkOrderLogEntriesDocument, variables: { mroWorkOrderId: logEntry.mroWorkOrderId } },
      { query: GetMroLogEntryDocument, variables: { mroLogEntryId: logEntry?.id } },
    ],
  });

  useEffect(() => {
    if (mroEmployeeProfile) {
      setSignOptions([mroEmployeeProfile.firstName + ' ' + mroEmployeeProfile.lastName, mroEmployeeProfile?.mroOrganization?.name]); // 0 should be person, 1 should be mroOrganization
    }
  }, [mroEmployeeProfile]);

  const initialValues = {
    categoryIndex: 0,
    title: logEntry?.title,
    certification: logEntry?.certificationText ?? '',
    componentName: logEntry?.mroComponent?.name,
    componentSerialNumber: logEntry?.mroComponent?.serialNumber,
  };
  const handleSubmit = async (values: typeof initialValues, saveOnly?: boolean) => {
    await updateLogEntry({
      variables: {
        input: {
          id: logEntry.id,
          title: values.title,
          status: saveOnly ? 'INCOMPLETE' : 'SIGNED',
          certificationText: values.certification,
          intervals: {
            deleteMany: [{ mroLogEntryId: { equals: logEntry.id } }],
          },
          mroLogEntryItems: {
            deleteMany: [{}],
            create: selectedSquawks
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((squawk, index) => {
                return {
                  mroWorkOrderItem: { connect: { id: squawk.id } },
                  description: squawk.description,
                  sortOrder: index,
                };
              }),
          },
          mroComponent: { update: { data:{ name: values.componentName, serialNumber: values.componentSerialNumber } } },
          ...(!saveOnly && { signer: signIndex === 0 ? { connect: { id: mroEmployeeProfile.id } } : {}, signedAt: Date.now() }),
        },
      },
    });
    if (intervals.length > 0) {
      await updateLogEntry({
        variables: {
          input: {
            id: logEntry.id,
            intervals: {
              create: intervals.map((interval) => {
                return {
                  name: interval.name,
                  intervalLogs: {
                    create: [
                      {
                        startValue: interval.value,
                      },
                    ],
                  },
                };
              }),
            },
          },
        },
      });
    }

    closeFlyout();
  };

  const handleSquawkSeletion = function (value) {
    let tempArr = [...selectedSquawks];
    const i = tempArr.findIndex((e) => e.id === value.id);
    //remove from selectedSignoffs array
    if (i > -1) {
      tempArr.splice(i, 1);
    }
    //add back Sign Off
    //add to selectedSignoffs array
    else {
      tempArr.push({
        ...value,
        ...(value.logEntry && !value.description && { description: findExistingItem(value)?.description ?? value?.logEntry }),
      });
    }
    setSelectedSquawks(tempArr);
  };

  const updateDescription = function (squawk, description) {
    let tempArr = [...selectedSquawks];
    const i = tempArr.findIndex((e) => e.id === squawk.id);
    tempArr[i].description = description;
    setSelectedSquawks(tempArr);
  };

  return (
    <div className="p-4">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => (
          <Form>
            <div className="flex flex-col bg-white p-4 border border-slate-200 rounded">
              <FullFormikInput name="title" label="Title" />
            </div>
            <div className="flex flex-col border bg-white rounded shadow border-slate-200 p-6 mt-3">
              <div className="flex justify-between items-center w-full relative">
                <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Component</span>
              </div>
              <hr className="my-2 -m-6" />
              <FullFormikInput name="componentName" label="Name" />
              <FullFormikInput name="componentSerialNumber" label="Serial Number" />
            </div>
            <div className="flex flex-col border bg-white rounded shadow border-slate-200 p-6 mt-3">
              <div className="flex justify-between items-center w-full relative">
                <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Intervals ({intervals.length})</span>
              </div>
              <hr className="my-2 -m-6" />
              {intervals?.length > 0 && (
                <div className="flex flex-wrap -mx-0.5 mt-1">
                  {intervals.map((interval, idx) => (
                    <div key={idx} className={`${intervals.length === 1 ? 'w-full' : 'w-1/2'} p-0.5`}>
                      <div className="flex items-center relative">
                        <input
                          className="w-3/5 h-6 px-2 bg-slate-50 text-sm font-medium rounded-l border border-r-0 border-slate-200 placeholder:text-slate-400"
                          placeholder="Interval Name"
                          value={interval.name}
                          onChange={(e) => {
                            intervals[idx].name = e.target.value;
                            setIntervals([...intervals]);
                          }}
                        />
                        <input
                          className="w-2/5 h-6 pr-6 text-sm border-slate-200 shadow-inner rounded-r"
                          value={interval.value}
                          type="number"
                          onChange={(e) => {
                            intervals[idx].value = Number(e.target.value);
                            setIntervals([...intervals]);
                          }}
                        />
                        <TrashIcon
                          className="w-6 p-1.5 text-rose-500 cursor-pointer hover:text-slate-500 transition absolute right-0"
                          onClick={() => {
                            setIntervals([...intervals.slice(0, idx), ...intervals.slice(idx + 1)]);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-end my-3">
                <div
                  onClick={() => {
                    setIntervals([...intervals, { name: '', value: 0 }]);
                  }}
                  className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
                  <PlusIcon className="h-3 w-3" />
                  <span className="text-xs font-semibold w-full ml-0.5">New Interval</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white p-4 border border-slate-200 rounded mt-3">
              <h2 className="font-bold text-sm my-2">Squawks</h2>
              {logEntry?.itemCategory &&
                [...logEntry?.itemCategory?.mroWorkOrderItems]
                  .sort((a, b) => a.itemNumber - b.itemNumber)
                  .map((item) => (
                    <div key={item.id}>
                      <div className="p-4 flex gap-3">
                        <input
                          type="checkbox"
                          className="mt-1 rounded h-3.5 w-3.5 -ml-3 mr-0.5 cursor-pointer"
                          id={item.id}
                          onChange={() => handleSquawkSeletion(item)}
                          checked={!!selectedSquawks.find((squawk) => item.id === squawk.id)}
                          name={item.id}
                        />
                        <span>
                          {logEntry.itemCategory.categoryNumber}.{item.itemNumber} - {item.title}
                        </span>
                      </div>
                      <textarea
                        disabled={!selectedSquawks.find((squawk) => item.id === squawk.id)}
                        value={selectedSquawks.find((squawk) => item.id === squawk.id)?.description ?? ''}
                        onChange={(e) => updateDescription(item, e.target.value)}
                        className="rounded border p-2 text-sm bg-slate-50 border-slate-200 disabled:bg-white disabled:resize-none focus:bg-white placeholder:text-slate-400 w-full mt-2"
                      />
                    </div>
                  ))}
            </div>
            <div className="flex flex-col text-sm bg-white focus:bg-white  p-4 mt-4 border border-slate-200 rounded">
              <h1 className="font-bold">RTS Statment</h1>
              <FullFormikTextarea name="certification" placeholder="I Certify that..." />
            </div>
            <div className="flex flex-col text-sm bg-white p-4 mt-4 border border-slate-200 rounded">
              <div className="flex items-center justify-between pl-2 mt-1 gap-5">
                <div className="flex items-stretch grow relative group">
                  <select
                    onChange={(e) => setSignIndex(Number(e.currentTarget.value))}
                    className="absolute w-full h-full z-10 opacity-0 cursor-pointer p-0"
                    value={signIndex}>
                    {signOptions?.map((item, index) => {
                      return (
                        <option value={index} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <div className={`bg-brand text-sm flex font-semibold justify-center items-center rounded relative`}>
                    <span className="text-white flex w-full justify-center py-1 px-3 items-center select-none capitalize">
                      {signOptions[signIndex]}
                    </span>
                    <ChevronDownIcon className={`text-white border-l h-full w-6 py-[6.075px] p-1 rounded-r`} />
                  </div>
                </div>

                <button
                  onClick={() => handleSubmit(values)}
                  className="flex items-center font-semibold bg-brand-electric text-white border border-brand-electric cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                  <PencilIcon className="h-2.5 w-2.5 mr-1" />
                  Sign
                </button>
                <button
                  onClick={() => handleSubmit(values, true)}
                  className="flex items-center font-semibold bg-white text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-50 transition">
                  Save Entry
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

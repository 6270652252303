import { ArrowDownIcon, TrashIcon } from "@heroicons/react/24/solid";

export default function FilePicker ({ files, setFiles }: { files: File[]; setFiles: (files:any) => any }) {
    return ( 
    <>
        <div
        className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
        onDragOver={(e) => {
        e.preventDefault();
        }}
        onDrop={(e) => {
        e.preventDefault();
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...e.dataTransfer.items].forEach((item, i) => {
            // If dropped items aren't files, reject them
            if (item.kind === 'file') {
                const file = item.getAsFile();
                setFiles((cur) => [...cur, file]);
            }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...e.dataTransfer.files].forEach((file, i) => {
                setFiles((cur) => [...cur, file]);
            });
        }
        }}>
        <div className="space-y-1 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </svg>
            <div className="flex text-sm text-gray-600">
            <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                <span>Upload a file</span>
                <input
                id="file-upload"
                name="file-upload"
                type="file"
                multiple
                className="sr-only"
                onChange={(e) => setFiles((cur) => [...cur, ...Array.from(e.target.files)])}
                />
            </label>
            <p className="pl-1">or drag and drop</p>
            </div>
        </div>
        </div>
        <div className="text-indigo-800 flex py-2">
            {Array.from(files ?? [])
                .map((file, index) => ( <div key={index} className='flex gap-3 flex-wrap my-2'>
                <span className='text-sm'>{file.name}</span> 
                {(file as any)?.signedUrl && <ArrowDownIcon
                    onClick={() => {
                        window.open((file as any)?.signedUrl, '_blank');
                    }}
                    title="Download"
                    className="text-brand w-12 h-6 p-0.5 border border-brand rounded cursor-pointer hover:bg-brand-pale transition"
                />}
                <TrashIcon
                    onClick={() => {
                        setFiles((cur) => cur.filter((f) => f.name !== file.name));
                    }}
                    title="Delete"
                    className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
                /> 
                </div> ))}
        </div>
    </>
    );
}
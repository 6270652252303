import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2 0H2.20001C1.60001 0 1.20001 0.4 1.20001 1V15C1.20001 15.6 1.60001 16 2.20001 16H14.2C14.8 16 15.2 15.6 15.2 15V1C15.2 0.4 14.8 0 14.2 0ZM8.20001 13H4.20001V11H8.20001V13ZM12.2 9H4.20001V7H12.2V9ZM12.2 5H4.20001V3H12.2V5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;

import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { StyledFormikField } from 'components/Form/StandardForm';
import ServiceDropdown from './Components/ServiceDropdown';
import Button from 'components/Button/Button';
import Logo from 'assets/icons/logo_navbar_w.svg';
import { useMutation, useQuery } from '@apollo/client';
import { UpdateMroEstimateWithTokenDocument, GetServiceEstimateDocument, GetServiceEstimateTokenDocument, GetServiceEstimateTokenQuery, GetMroOrganizationInfoDocument } from 'graphql/generated';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CheckCircleIcon, ExclamationTriangleIcon, TrashIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import Modal from 'components/Modal/Modal';
import { convertToFee } from 'utils/charge';
import { formatUSD } from 'utils/formatter';

const feeTypes = {
  "PARTS": "Parts",
  "LABOR": "Labor",
  "BOTH": "Parts & Labor",
}
const ViewServiceRequest = (props) => {
  const location = useLocation();
  const isPreview = location.pathname.includes('preview');
  const { requestId, token } = useParams();

  const [showNotAirworthy, setShowNotAirworthy] = useState(false);
  const [showDeny, setShowDeny] = useState(false);
  const { data: { mroEstimateWithToken } = {} } = useQuery(GetServiceEstimateTokenDocument, { skip: !token, variables: { id: decodeURI(requestId), token } });
  const [estimate, setEstimate] = useState<GetServiceEstimateTokenQuery['mroEstimateWithToken']>(null);
  const { data: { mroEstimate } = {} } = useQuery(GetServiceEstimateDocument, { skip: !!token, variables: { id: decodeURI(requestId) } });
  const [updateEstimate] = useMutation(UpdateMroEstimateWithTokenDocument);
  const { data: { mroOrganizationInfo } = {} } = useQuery(GetMroOrganizationInfoDocument, { variables: { id: (mroEstimateWithToken || mroEstimate)?.mroOrganizationId } } );
  const items = estimate?.items.map((item, index) => ({ ...item, id: index }));
  const [stepIndex, setStepIndex] = useState(0);
  const [serviceStatuses, setServiceStatuses] = useState([]);
  const [notes, setNotes] = useState('');
  useEffect(() => {
    if(mroEstimate) setEstimate(mroEstimate);
    if(mroEstimateWithToken) setEstimate(mroEstimateWithToken);
  }, [mroEstimate, mroEstimateWithToken]);

  const handleEstimate = async function () {
    const res = serviceStatuses.filter((s) => s.severity === 'REQUIRED' && !s.status).length;
    if(serviceStatuses.filter((s) => s.status).length === 0){
      return setShowDeny(true);
    }else if (res === 0) {
      setStepIndex(1);
    } else {
      return setShowNotAirworthy(true);
    }
  };
  
  const laborCosts = Number(serviceStatuses.reduce((a, b) => a + (b.status ? b.laborCost : 0), 0)?.toFixed(2));
  const partsCosts = Number(serviceStatuses.reduce((a, b) => a + (b.status ? b.partsCost : 0), 0)?.toFixed(2));
  const serviceCosts = partsCosts + laborCosts;
  const shippingCost = Number(estimate?.priceSummary?.totalShipping);
  const chargesCost = estimate?.charges?.reduce((a, b) => a + convertToFee(Number(laborCosts), Number(partsCosts), b), 0);
  const discounts = estimate?.discounts?.reduce((a, b) => a + convertToFee(Number(laborCosts), Number(partsCosts), b), 0);
  const totalCost = Math.max(serviceCosts + chargesCost + shippingCost - discounts, 0).toFixed(2);
  const steps = [
    <>
      <h1 className="text-3xl md:text-2xl font-bold text-brand mb-4 md:mb-2">Service Estimate</h1>
      <span className="text-sm mb-2">{mroOrganizationInfo?.name} will perform the following services.</span>
      <span className="text-xs mb-2">Check which services you want performed</span>
      <div className="flex flex-col mt-2 -mx-2">
        {items?.map((entry, index) => {
          return (
            <ServiceDropdown
              key={index}
              id={entry.id}
              title={entry.title}
              desc={entry.desc}
              laborCost={entry.laborCost}
              partsCost={entry.partsCost}
              hours={entry.laborHours}
              severity={entry.severity}
              parts={entry.parts}
              serviceStatuses={serviceStatuses}
              setServiceStatuses={setServiceStatuses}
            />
          );
        })}
      </div>
      <hr className="my-2" />
      { (serviceStatuses.find((s) => !s.status) || serviceStatuses?.length !== items?.length || serviceStatuses.length === 0) && 
      <div className='flex w-full justify-end'>
        <div onClick={() => {
            setServiceStatuses(items.map((s) => ({ ...s, status: true })));
          }} className={`text-brand-electric bg-brand-pale cursor-pointer flex border-2 rounded-lg p-1 items-center`}>
          <span>Agree to All</span>
          <CheckCircleIcon className="w-7 h-7 cursor-pointer" />
        </div>
      </div> }
      <div className="w-full pb-16 text-[#374151]">
        <div className="flex justify-between font-bold mt-6 items-center py-2">
          <span>
            Total Services ({serviceStatuses.filter((s) => s.status).length}/{items?.length}):
          </span>
          <span className="text-lg">${serviceCosts}</span>
        </div>
        {serviceStatuses.length !== items?.length && <span className="text-xs text-red-500"> Please Confirm All Items to Continue</span>}
        <div className='my-2'>
          { estimate?.charges && estimate?.charges.length > 0 && 
            <>
              <span className="font-bold text-sm">Charges:</span>
              {estimate?.charges.map((charge, index) => {
                const total = convertToFee(Number(laborCosts), Number(partsCosts), charge).toFixed(2);
                return (
                  <div key={index} className="flex justify-between items-center py-2">
                    <span>{charge.description}</span>
                    <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 font-bold ml-2">
                        <span>{charge.percent !== undefined && `(${total})`}</span>
                        <span>{charge.percent === undefined ? formatUSD(total) : charge.percent+`% ${feeTypes[charge.type]? feeTypes[charge.type] : ''}`}</span>
                    </div>
                  </div>
                );
              } )}
            </>
          }
          {shippingCost > 0 && 
            <div className="flex justify-between items-center py-2">
              <span>Shipping</span>
              <span>${shippingCost?.toFixed(2)}</span>
            </div>
          }
          {estimate?.discounts?.length > 0 && 
            <div className='my-2'>
              <span className="font-bold text-sm">Discounts:</span>
              {estimate?.discounts.map((discount, index) => {
                const total = convertToFee(Number(laborCosts), Number(partsCosts), discount).toFixed(2);
                return (
                  <div key={index} className="flex justify-between items-center py-2">
                    <span>{discount.description}</span>
                    <div className="flex justify-between items-center pl-2 pr-1 py-1 gap-2 text-sm rounded border border-slate-200 bg-slate-50 font-bold ml-2">
                        <span>{discount.percent !== undefined && `-(${total})`}</span>
                        <span>{discount.percent === undefined ? "-"+formatUSD(total) : discount.percent+`% ${feeTypes[discount.type]}`}</span>
                    </div>
                  </div>
                );
              } )}
            </div>
          }
        </div> 
        <hr className="my-2" />
        <div className="flex justify-between font-bold items-center py-2">
          <span>Total:</span>
          <span className="text-lg">${totalCost}</span>
        </div>
        <div className="flex flex-col gap-2 my-2 mb-4">
          <span className='font-bold'>Notes:</span>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter any notes about estimate here..."
            className="rounded border p-2 text-sm bg-slate-50 border-slate-200 disabled:bg-slate-200 focus:bg-white placeholder:text-slate-400 w-full"></textarea>
        </div>
        <div
          className={`${
            serviceStatuses.length !== items?.length && 'pointer-events-none opacity-40 grayscale cursor-not-allowed'
          } flex flex-col transition-all mt-2`}>
          <Button color="navy" type="submit" size="sm" className='self-center px-20' text="Continue" onClick={() => handleEstimate()} />
        </div>
      </div>
    </>,
    <>
      <Formik
        initialValues={{ authSignature: '' }}
        onSubmit={async (values) => {
          if (isPreview) return alert('Cannot Submit on Preview');
          const firstLastNameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
          if (!values.authSignature.match(firstLastNameRegex)) return alert('Invaild Name');
          await updateEstimate({
            variables: {
              input: {
                id: estimate?.id,
                status: 'APPROVED',
                token,
                authorizedTotal: Number(totalCost),
                authorizedBy: values.authSignature,
                items: items.map((_, idx) => {
                  return { customerApproved: serviceStatuses.find((servStat) => servStat.id === idx).status ?? false };
                }),
                notes,
              }
            },
          });
          setStepIndex(2);
        }}>
        <Form className="flex flex-col">
          <h1 className="text-2xl font-bold text-brand mb-4">Maintenance Authorization</h1>
          <span className="text-xs text-brand-dark mb-4 text-justify">{mroOrganizationInfo?.estimateAuthorization}</span>
          <hr className="mb-4 -mx-2 border-brand-pale border-dashed" />
          <h2 className="font-bold text-brand mb-1">Authorized By</h2>
          <div className="signature text-lg">
            <StyledFormikField name="authSignature" />
          </div>
          <div className="flex justify-between items-center -mt-1">
            <button onClick={() => setStepIndex(0)} className="underline text-brand font-semibold text-sm">
              Go Back
            </button>
            <Button color="navy" size="sm" type="submit" text="Submit" />
          </div>
        </Form>
      </Formik>
    </>,
    <>
      <div className="flex flex-col place-items-center gap-10">
        <h1 className="text-2xl font-bold text-brand mb-4">Maintenance Authorization Sent</h1>
        <WrenchScrewdriverIcon className="h-20 w-20 text-brand spin animate-[spin_2s_800ms]" />
      </div>
    </>,
    <>
      <div className="flex flex-col place-items-center gap-20">
        <h1 className="text-2xl font-bold text-brand mb-4">Estimate Denied</h1>
        <ExclamationTriangleIcon className="h-20 w-20 text-brand" />
        <h1 className="text-xl font-bold text-brand mb-4">We will be in touch soon!</h1>
      </div>
    </>,
    <>
      <div className="flex flex-col place-items-center gap-8">
        <h1 className="text-2xl font-bold text-brand mb-4">Estiamte Voided</h1>
        <TrashIcon className="h-20 w-20 text-brand" />
      </div>
    </>,
  ];
  useEffect(() => {
    if (estimate?.status === 'APPROVED' && !isPreview) {
      setStepIndex(2);
    }
    if (estimate?.status === 'DENIED' && !isPreview) {
      setStepIndex(3);
    }
    if (estimate?.status === 'VOID') {
      setStepIndex(4);
    }
  }, [estimate, isPreview]);
  
  return (
    <>
      {/* NON AIRWORTHY MODAL */}
      {showNotAirworthy && (
        <Modal
          cancel={setShowNotAirworthy}
          accept={() => setStepIndex(1)}
          message={`You haven't selected all squawks required for an airworthy maintenance sign-off.`}
        />
      )}
      {/* DENY MODAL */}
      {showDeny && (
        <Modal
          cancel={setShowDeny}
          accept={() => { 
            updateEstimate({
              variables: {
                input: {
                  id: estimate?.id,
                  status: 'DENIED',
                  token,
                  authorizedTotal: 0,
                  authorizedBy: '',
                  items: items.map((_, idx) => {
                    return { customerApproved: serviceStatuses.find((servStat) => servStat.id === idx).status ?? false };
                  }),
                  notes,
                }
              },
            });
            setStepIndex(3);
          }
          }
          message={`You have deined all squawks on this estimate, and are about to deny this estimate.`}
        />
      )}
      <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center bg-white md:bg-white/0 md:max-w-6xl w-full md:min-h-[768px] md:shadow md:mx-6">
        <div className="w-1/3 hidden md:flex flex-col bg-brand-dark relative rounded-l">
          <div className="flex justify-center items-center p-8">
            {mroOrganizationInfo?.icon && <img className="max-h-[4rem] pr-8" src={mroOrganizationInfo?.icon} alt="" /> }
            <img className="max-h-[2.5rem] w-1/2 border-l border-white pl-8" src={Logo} alt="" />
          </div>
          <div className="flex flex-col">
            <h1
              className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 py-4 -ml-2 bg-brand relative border-b-2 border-[#162551]"
              id="ribbon">
              {mroOrganizationInfo?.name}
            </h1>
            <p className="text-brand-pale font-medium my-8 px-8">
              Our customer portal allows you to manage the status of your aircraft and maintenance.
            </p>
            <span className="text-brand-pale font-medium px-8">
              Need additional help? <Link to='https://coflyt.com/contact-us' className="font-bold text-white underline">Contact Us.</Link>
            </span>
          </div>
          <span className="text-brand-pale absolute bottom-8 left-0 w-full text-center text-xs">
            © {new Date().getFullYear()} Coflyt. All rights reserved.
          </span>
        </div>
        <div className="flex md:max-h-[768px] overflow-x-hidden overflow-y-auto justify-center md:w-3/4 rounded pt-8 pb-24 md:p-16 md:pb-16 md:pt-16 bg-white rounded-r mb-8 md:mb-0 md:relative">
          {isPreview && (
            <div className="fixed right-0 top-0 h-16 w-16 z-30">
              <div className="fixed transform rotate-45 bg-brand text-center text-white font-semibold py-1 right-[-34px] top-[32px] w-[170px]">
                Preview
              </div>
            </div>
          )}
          <div className="flex flex-col px-6 my-auto">{steps[stepIndex]}</div>
        </div>
      </div>
    </>
  );
};

export default ViewServiceRequest;

import Button from 'components/Button/Button';
import { GetMroPartOrderQuery, PartCondition } from 'graphql/generated';
import { useReducer, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateMroSerializedPartsDocument,
  GetMroSerilizedPartsDocument,
  GetMroPartsDocument,
  GetMroPartInventoriesDocument,
  GetMroPartWarehouseDocument,
} from 'graphql/generated';
import { useSession } from 'contexts';
import ReceiveSerializedPartItem from '../Components/ReceiveSerializedPartItem';

enum SerializedActionType {
  SET,
  UPDATE,
  REMOVE,
  SETITEMFEILDS,
  UPDATEFEILD,
}

export interface SerializedItem {
  index: number;
  serialNumber: string;
  condition: PartCondition;
  unitPrice: number;
  coreCost: number;
  lotNumber: string;
  bin: string;
  warehouse: string;
}

export function AddSerializedParts({
  importData,
  closeFlyout,
}: {
  importData: GetMroPartOrderQuery['mroPartOrder']['mroPartOrderItems'][0];
  closeFlyout: any;
}) {
  const { user } = useSession();
  const [globalSettings, setGlobalSettings] = useState({ lotNumber: '', warehouse: 'n/a', bin: '' });
  const { data: { mroPartWarehouses = [] } = {} } = useQuery(GetMroPartWarehouseDocument, {
    variables: {
      input: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
      },
    },
  });
  const updateSerializedParts = (state, action) => {
    switch (action.type) {
      case SerializedActionType.SET:
        return action.payload;
      case SerializedActionType.UPDATEFEILD:
        return state.map((item) => {
          if (item.index === action.payload.index) {
            return { ...item, [action.payload.field]: action.payload.value };
          }
          return item;
        });
      case SerializedActionType.UPDATE:
        return state.map((item) => {
          if (item.index === action.payload.index) {
            return action.payload;
          }
          return item;
        });
      case SerializedActionType.SETITEMFEILDS:
        return state.map((item) => {
          return { ...item, ...globalSettings };
        });
      case SerializedActionType.REMOVE:
        return state.filter((item) => item.index !== action.payload.index);
      default:
        return state;
    }
  };

  const [serializedParts, dispatchSerializedParts] = useReducer(updateSerializedParts, []);
  const [createSerializedParts] = useMutation(CreateMroSerializedPartsDocument, {
    refetchQueries: [
      GetMroSerilizedPartsDocument,
      GetMroPartsDocument,
      { query: GetMroPartInventoriesDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
    ],
  });
  useEffect(() => {
    if (importData) {
      let temp: SerializedItem[] = [];
      for (let i = 0; i < importData.quantity; i++) {
        temp.push({
          index: i,
          serialNumber: '',
          condition: importData.condition,
          unitPrice: importData.unitPrice,
          coreCost: 0,
          lotNumber: '',
          bin: '',
          warehouse: 'n/a',
        });
      }
      dispatchSerializedParts({ type: SerializedActionType.SET, payload: temp });
    }
  }, [importData]);

  return (
    <div className="p-4">
      <div className="flex flex-col w-full border border-slate-300 rounded p-6 bg-white mb-3 gap-4">
        <div className="flex justify-between border-b border-slate-200 rounded-b px-4 text-sm -mx-3">
          <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Set Item Fields</span>
        </div>
        <div className="flex justify-between items-stretch gap-2">
          <div className="flex flex-col w-1/3">
            <label className="text-sm text-slate-700 font-semibold">Lot Number</label>
            <input
              type="text"
              className="border border-slate-300 rounded h-10 placeholder:text-slate-400 px-2"
              onChange={(e) => setGlobalSettings({ ...globalSettings, lotNumber: e.target.value })}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-sm text-slate-700 font-semibold">Warehouse</label>
            <select
              value={globalSettings.warehouse}
              onChange={(e) => setGlobalSettings({ ...globalSettings, warehouse: e.target.value })}
              className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white h-10 strip-style">
              <option key={'n/a'} value={'n/a'}>
                N/A
              </option>
              {mroPartWarehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-sm text-slate-700 font-semibold">Bin</label>
            <input
              type="text"
              className="border border-slate-300 rounded h-10 placeholder:text-slate-400 px-2"
              onChange={(e) => setGlobalSettings({ ...globalSettings, bin: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center my-4">
        <Button
          text="Apply to All"
          color="navy"
          size="xs"
          onClick={() => dispatchSerializedParts({ type: SerializedActionType.SETITEMFEILDS, payload: {} })}
        />
      </div>
      <div className="flex flex-col w-full border border-slate-300 rounded p-6 bg-white">
        <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3">
          <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Items({serializedParts.length})</span>
        </div>
        {serializedParts.map((contents, indx) => (
          <ReceiveSerializedPartItem
            key={indx}
            contents={contents}
            mroPartWarehouses={mroPartWarehouses}
            updateContent={(value, index, field) =>
              dispatchSerializedParts({ type: SerializedActionType.UPDATEFEILD, payload: { index, field, value } })
            }
          />
        ))}
      </div>
      <div className="flex justify-end items-center mt-4 ">
        <Button
          text="Add Serialized Parts"
          color="navy"
          disabled={serializedParts.some((item) => item.serialNumber === '')}
          size="xs"
          onClick={() =>
            createSerializedParts({
              variables: {
                input: {
                  serializedParts: serializedParts.map((item) => {
                    const warehouse = item.warehouse === 'n/a' ? null : item.warehouse;
                    delete item.warehouse;
                    delete item.index;
                    return {
                      mroPart: { connect: { id: importData.mroPart.id } },
                      ...(warehouse && { mroPartWarehouse: { connect: { id: warehouse } } }),
                      ...item,
                    };
                  }),
                },
              },
            })
              .then(closeFlyout)
              .catch(console.error)
          }
        />
      </div>
    </div>
  );
}

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { UpdateMroCraftDocument, GetMroRolesDocument, GetMroCraftQuery, GetMroCraftDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import Button from 'components/Button/Button';
import { FormLabel, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';

const EditCraftSchema = Yup.object().shape({
  tailNumber: Yup.string().required('Required'),
});



const EditCraft = function ({ closeFlyout, mroCraft }: { closeFlyout: () => void; mroCraft: GetMroCraftQuery['mroCraft'] }) {
  const { user } = useSession();
  const [updateCraft] = useMutation(UpdateMroCraftDocument, {
    refetchQueries: [{ query: GetMroCraftDocument, variables: { mroCraftId: mroCraft?.id }}],
  });
  const initialValues = { tailNumber: mroCraft?.tailNumber, make: mroCraft?.make, model: mroCraft?.model, ownerName: mroCraft?.ownerName, year: mroCraft?.year, engineCount: mroCraft?.engineCount };
  const handleSubmit = async ({ ...values }: typeof initialValues) => {
    await updateCraft({ variables: { input:{ id: mroCraft.id, ...values } } })
    closeFlyout();
  };


  return (
    <div className="p-4">
      <Formik initialValues={initialValues} validationSchema={EditCraftSchema} onSubmit={handleSubmit}>
        <Form>
          <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
            <FullFormikInput name="tailNumber" label="Tail Number" />
            <FullFormikInput name="make" label="Make" />
            <FullFormikInput name="model" label="Model" />
            <FullFormikInput name="year" label="Year" />
            <FullFormikInput name="engineCount" label="Engine Count" />
          </div>
          <div className="flex justify-end items-center mt-4">
            <Button text="Save" color="navy" size="sm" type="submit" />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default EditCraft;

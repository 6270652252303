import Button from 'components/Button/Button';
import Logo from 'assets/icons/logo.svg';
import isMobileApp from 'utils/isMobileApp';
const Paywall = ({ title = "That's a premium feature!" }) => {
  return (
      <div className="flex flex-col items-center justify-center bg-brand py-40 gap-8 rounded-lg">
        <img className="h-36 bg-white p-4 rounded-lg" src={Logo} alt="" />
        {isMobileApp() ? <h1 className="text-2xl font-bold text-center text-white">Your organization does not have access</h1> :
        <>
        <h1 className="text-2xl font-bold text-center text-white">{title}</h1>
        <Button text="Learn More" size="sm" color="pale" onClick={() => window.location.assign('/account/settings?settingsTab=Billing')} />
        </>}
      </div>
  );
};

export default Paywall;
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import { FormLabel, FullFormikSelect, FullFormikTextarea, StyledFormikField } from 'components/Form/StandardForm';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  CreateMroWorkOrderPartDocument,
  GetMroOrgnizationPartPriceDocument,
  GetMroPartAndInventoriesDocument,
  GetMroPartRequestsDocument,
  GetMroWorkOrderDocument,
  GetMroWorkOrderItemDocument
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { PartNumberLookup } from '../../../Parts/Components/PartLookup';
import * as yup from 'yup';
import { useSession } from 'contexts';
import { partMarkup } from 'utils/partMarkup';
import DatePicker from 'components/DatePicker/DatePicker';
import { addDays } from 'date-fns';
import { formatApiDate, formatDateChange, formatPriceNumber } from 'utils/formatter';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';

interface Values {
  unitPrice: string;
  quantity: string;
  partDescription?: string;
  costPrice: string;
  condition: string;
  neededBy: Date;
  shippingType: string;
  notes: string;
  partMarkup: string;
}

const NewPartSchema = yup.object().shape({
  unitPrice: yup.number().required('Required'),
  quantity: yup.number().required('Required'),
});

export const ShippingSpeeds = () => (
  <>
    <option >N/A</option>
    <option value="GROUND">Ground</option>
    <option value="2_DAY">2 Day</option>
    <option value="STANDARD_OVERNIGHT">Standard Overnight</option>
    <option value="PRIORITY_OVERNIGHT">Priority Overnight</option>
  </>
)
const unitPriceConverter = (unitPrice: number, markUpPecentage: number) => {
  return (unitPrice + (unitPrice * (markUpPecentage * .01))).toFixed(2);
}
  
export default function NewPartFlyout({
  workOrderItemId,
  setIsOpen,
  context,
  workOrderId
}: {
  workOrderItemId: string;
  setIsOpen: (isOpen: boolean) => void;
  context?: string;
  workOrderId?: string;
}) {
  const { user } = useSession();
  const { toastProps, showToast } = useToast();
  const [createOrderPart] = useMutation(CreateMroWorkOrderPartDocument, {
    refetchQueries: [
      { query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } },
      { query: GetMroWorkOrderDocument,  variables: { id: workOrderId } },
      { query: GetMroPartRequestsDocument, variables: {
        where:{
          mroWorkOrderItem: { is: { mroWorkOrder: { is: { mroOrganizationId: { equals: user.mroOrganizationId } } } } },
          status: { notIn: ['DRAFT', 'AWAITING_APPROVAL'] },
        }
      } },
    ],
  });
  const { data: { mroOrganization: { mroPartPriceMatrix } } = { mroOrganization: { mroPartPriceMatrix: undefined } } } = useQuery(GetMroOrgnizationPartPriceDocument, { variables: { id: user.mroOrganizationId } });
  const [selectedPart, setSelectedPart] = useState<any>();
  const [partNumber, setPartNumber] = useState<string>('');
  const [getPart, { data: { mroPart } = { mroPart: undefined } }] = useLazyQuery(GetMroPartAndInventoriesDocument);

  const [initialValues, setInitialValues] = useState<Values>({
    unitPrice: '',
    costPrice: '',
    quantity: '',
    condition: '',
    neededBy: addDays(new Date(), 1),
    notes: '',
    shippingType: undefined,
    partMarkup: '',
  });

  useEffect(() => {
    if (selectedPart === 'newPart') {
      setInitialValues({
        unitPrice: '0',
        costPrice: '0',
        quantity: '',
        condition: '',
        notes: initialValues.notes,
        neededBy: initialValues.neededBy,
        shippingType: initialValues.shippingType,
        partMarkup: '',
      });
    } else if (selectedPart) {
      setPartNumber(selectedPart.partNumber);
      getPart({ variables: { id: selectedPart?.id } });
    }
  }, [selectedPart, getPart]);

  // Get Price based on Part Inventory
  useEffect(() => {
    if (mroPart) {
      const [markupPrice, markPercent] = partMarkup(Number(mroPart.estimatedCost), mroPartPriceMatrix?.partMatrix);
      setInitialValues({
        costPrice: mroPart.estimatedCost.toString() ?? '',
        unitPrice: formatPriceNumber(markupPrice).toString() ?? '',
        quantity: mroPart?.isSerialized ? '1' : '',
        condition: '',
        notes: initialValues.notes,
        neededBy: initialValues.neededBy,
        shippingType: initialValues.shippingType,
        partMarkup: (markPercent*100).toFixed(2),
      });
    }
  }, [mroPart]); 
  const createOrder = (values: Values, status: string, callback = () => {}) => {
    if(partNumber === '') return showToast({ title:'Part Number is required', type: ToastLength.Normal });
    createOrderPart({
      variables: {
        input: {
          partNumber,
          status,
          mroWorkOrderItem: { connect: { id: workOrderItemId } },
          unitPrice: parseFloat(values.unitPrice),
          quantity: parseInt(values.quantity),
          costPrice: parseFloat(values.costPrice),
          shippingType: values.shippingType,
          neededDate: new Date(values.neededBy),
          partDescription: values.partDescription,
          ...(selectedPart?.id && { mroPart: { connect: { id: selectedPart.id } } }),
          requester: { connect: { id: user.mroEmployeeProfileId } },
          notes: values.notes,
        },
      },
    }).then(callback);
  };

  const handleSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    createOrder(values, 'DRAFT', () => {
      setSubmitting(false);
      setSelectedPart(undefined);
      resetForm();
      setIsOpen(false);
    });
  };
  return (
    <>
      <Toast {...toastProps} />
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={NewPartSchema}>
          {({ isSubmitting, values, setFieldValue, isValid }) => (
            <Form>
              <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
                <div className="px-2">
                  <PartNumberLookup {...{ selectedPart, setSelectedPart, partNumber, setPartNumber }} />
                  {selectedPart?.description && <div className="text-sm mb-5 mt-[-10px]">Description: {selectedPart?.description}</div>}
                </div>
                { (!selectedPart || selectedPart === 'newPart') && <div className="flex flex-col w-full relative px-2">
                  <FormLabel>Part Description</FormLabel>
                  <StyledFormikField
                    id="partDescription"
                    name="partDescription"
                  />
                </div> }
                <dl className="flex flex-col w-full px-2">
                  <div className="flex gap-2">
                    {context !== 'dashboard' && (
                      <>
                        <div className="flex flex-col w-full ">
                          <FormLabel>Cost Price</FormLabel>
                          <StyledFormikField
                            stripStyle
                            type="number"
                            id="costPrice"
                            name="costPrice"
                            onChange={(e) => {
                              setFieldValue('costPrice', e.target.value);
                              setFieldValue('unitPrice', unitPriceConverter(Number(e.target.value), Number(values.partMarkup)));
                            }}
                          />
                        </div>
                        <div className="flex flex-col w-full ">
                          <FormLabel>Mark Up %</FormLabel>
                          <StyledFormikField
                            stripStyle
                            type="number"
                            id="partMarkup"
                            name="partMarkup"
                            onChange={(e) => {
                              setFieldValue('partMarkup', e.target.value);
                              setFieldValue('unitPrice', unitPriceConverter(Number(values.costPrice), Number(e.target.value)));
                            }}
                          />
                          <button type='button' onClick={()=> {
                            const [markupPrice, markPercent] = partMarkup(Number(values.costPrice), mroPartPriceMatrix?.partMatrix);
                            setFieldValue('partMarkup', (markPercent*100).toFixed(2));
                            setFieldValue('unitPrice', formatPriceNumber(markupPrice).toString() ?? '');
                          }} className='text-center text-sm mt-[-10px] underline'>{values.costPrice && formatPriceNumber(partMarkup(Number(values.costPrice), mroPartPriceMatrix?.partMatrix)[1] * 100) !== Number(values.partMarkup) ? 
                            "Recommended: " + (partMarkup(Number(values.costPrice), mroPartPriceMatrix?.partMatrix)[1] * 100).toFixed(2) +'%' : '' }</button>
                        </div>
                        <div className="flex flex-col w-full ">
                          <FormLabel>Marked Up Price</FormLabel>
                          <StyledFormikField
                            stripStyle
                            type="number"
                            id="unitPrice"
                            name="unitPrice"
                            onChange={(e) => {
                              setFieldValue('unitPrice', e.target.value);
                              const costPrice = Number(values.costPrice ?? 0);
                              const sellingPrice = Number(e.target.value);
                              setFieldValue('partMarkup', Math.max(0, Number((sellingPrice - costPrice) / costPrice * 100)).toFixed(2));
                            }}
                          />
                        </div>
                      </>
                      
                    )}
                    <div className="flex flex-col w-full relative">
                      <FormLabel>Quantity</FormLabel>
                      {selectedPart?.isSerialized ? (
                        <StyledFormikField name="blank" placeholder="Cannot set QTY for Serialized Part" disabled />
                      ) : (
                        <StyledFormikField name="quantity" type="number" />
                      )}
                    </div>
                  </div>
                  {context !== 'dashboard' && (
                    <>
                      <hr className="my-6" />
                      <div className="flex justify-between">
                        <FormLabel>Total Price</FormLabel>
                        <dd>
                          {Number(+values?.unitPrice * +values.quantity)?.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'USD',
                          }) ?? 'N/A'}
                        </dd>
                      </div>
                    </>
                  )}
                </dl>
                <hr className="my-6" />
                <div className="px-2 mt-4">
                  <FormLabel>Need By</FormLabel>
                  <Field
                    as={DatePicker}
                    value={formatApiDate(values.neededBy)}
                    className="w-full bg-state-500"></Field>
                </div>
                {context !== 'dashboard' && (
                  <div className="px-2 mt-3">
                    <FullFormikSelect name="shippingType" label="Shipping Speed">
                      <ShippingSpeeds />
                    </FullFormikSelect>
                  </div>
                )}
                <div className="px-2 mt-3">
                  <FullFormikTextarea name="notes" label="Notes" />
                </div>
              </div>
              <div className="flex justify-end items-center mt-2 gap-4 ">
                { context !== 'estimate' && <> {context !== 'dashboard' ? (
                  <Button
                    text="Add Part and Request"
                    color="navy"
                    type="button"
                    size="xs"
                    onClick={() =>
                      isValid && createOrder(values, 'REQUESTED', () => {
                        setIsOpen(false);
                        setSelectedPart(undefined);
                      })
                    }
                  />
                ) : (
                  <Button
                    text="Add Part and Request Approval"
                    color="navy"
                    type="button"
                    size="xs"
                    onClick={() =>
                      isValid && createOrder(values, 'AWAITING_APPROVAL', () => {
                        setIsOpen(false);
                        setSelectedPart(undefined);
                      })
                    }
                  />
                )} </> }
                <Button text="Add Part" color="blue" size="xs" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

import React from 'react';

const CircleSelectionIcon = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.05493C7.26456 1.24677 5.67784 1.93206 4.38324 2.96737M11 1.05493C12.7354 1.24677 14.3222 1.93206 15.6168 2.96737M2.96458 4.38673C1.93083 5.68069 1.2466 7.26612 1.05493 9M1.05493 11C1.24673 12.735 1.93171 14.3214 2.96659 15.6158M4.38755 17.0361C5.68136 18.0695 7.26649 18.7534 9 18.9451M11 18.9451C12.7335 18.7534 14.3186 18.0695 15.6125 17.0361M17.0334 15.6158C18.0683 14.3214 18.7533 12.735 18.9451 11M18.9451 9C18.7533 7.265 18.0683 5.67863 17.0334 4.38421"
        stroke="currentColor"
        strokeWidth="1.75"
      />
    </svg>
  );
};

export default CircleSelectionIcon;

import Button from 'components/Button/Button';
import { PartInventoryLookup } from '../../Components/PartLookup';
import { GetMroPartAndInventoriesDocument, GetMroPartOrderQuery, UpdateMroPartInventoryDocument } from 'graphql/generated';
import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';

export function ImportInventory({
  partId,
  closeFlyout,
  importData,
}: {
  partId: string;
  closeFlyout: () => void;
  importData: GetMroPartOrderQuery['mroPartOrder']['mroPartOrderItems'][0];
}) {
  const { data: { mroPart } = {} } = useQuery(GetMroPartAndInventoriesDocument, { variables: { id: partId } });
  const [selectedPartInventory, setSelectedPartInventory] = useState(mroPart?.availablePartInventories?.[0]);
  const [updateInventory] = useMutation(UpdateMroPartInventoryDocument);

  return (
    <div className="p-4">
      <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
        <PartInventoryLookup
          {...{
            selectedPartInventory,
            setSelectedPartInventory,
            partInventories: mroPart?.availablePartInventories,
          }}
        />
        <span className="font-semibold text-slate-500 text-right">Quantity to import: {importData.quantity}</span>
      </div>
      <div className="flex justify-end items-center mt-4 gap-2">
        <Button
          text="Import Inventory"
          color="navy"
          size="xs"
          onClick={() =>
            updateInventory({
              variables: {
                input: {
                  id: selectedPartInventory.id,
                  quantity: selectedPartInventory.quantity + importData.quantity,
                },
              },
            })
              .then(closeFlyout)
              .catch(console.error)
          }
        />
      </div>
    </div>
  );
}

import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";

const jokes = [
    "Why was the airplane arrested? For winging it!",
    "Why did the airplane break up with the airport? It found a better terminal.",
    "What do you call a plane that's not allowed to fly? A plain plane.",
    "Why don't airplanes make good friends? Because they're always taking off!",
    "Why did the airplane join the band? Because it had the perfect pitch!",
    "What's a pilot's favorite type of cheese? Plane cheese!",
    "Why did the airplane sit on the computer? It wanted to surf the web.",
    "What do you call a chicken crossing the road? A poultry in motion. What do you call a chicken crossing the road in a plane? Poultry in flight!",
    "Why don't airplanes tell jokes? Because they always crash and burn!",
    "Why did the airplane bring a suitcase to the party? Because it wanted to pack a good time!",
    "Why was the airplane always calm? It kept its altitude under control!",
    "Why did the airplane sit by the window? It wanted a better view!",
    "Why was the airplane good at soccer? It knew how to handle air balls!",
    "Why did the airplane go to school? It wanted to be a high flier!",
    "What's an airplane's favorite restaurant? The air-bistro!",
    "Why was the airplane's report card all A's? It had high-flying grades!",
    "Why did the airplane go to therapy? It had too many hang-ups!",
    "Why was the airplane always the life of the party? It had great wingmen!",
    `What did the airplane say to the airport? "Don't runway from me, let's be friends!"`,

    "Why did the airplane bring a map to the party? It wanted to make sure it didn't get lost in conversation!",
    "What's an airplane's favorite type of clothing? Flight suits!",
    "Why did the airplane break up with the runway? It needed some space.",
    "What's an airplane's favorite snack? Plane pretzels!",
    "Why did the airplane go to school? It wanted to be a high flier!",
    "What did the airplane say to the airport? 'Don't runway from me, let's be friends!'",
    "Why did the airplane sit next to the computer? It wanted to use the 'cloud' storage.",
    "What do you call a plane that's afraid of heights? A scaredy-plane!",
    "Why don't airplanes ever play hide and seek? Because they always get found!",
    `"What's a pilot's favorite movie? "Airplane" - it really takes off!"`,
    
];
export default function PageError(){
    const navigate = useNavigate();
    const randomIndex = Math.floor(Math.random() * jokes.length);
    return (
        <div className="flex flex-col gap-5 justify-center items-center h-[100vh]">
            <h1 className="text-xl font-semibold">Page Not Found</h1>
            <h3 className="">{jokes[randomIndex]}</h3>
            <Button text="Go Back" color="navy" size="sm" onClick={() => navigate('/auth')} />
        </div>
    );

}
import { useQuery } from "@apollo/client";
import TabCards, { TabCardItem } from "components/TabCards/TabCards";
import { useSession } from "contexts";
import { FuelOilReportsDocument, GetCraftsDocument } from "graphql/generated";
import useSaveState from "hooks/useSaveState";
import { useEffect } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const colorForReports = {
    Fuel_Per_Hobbs_Hour: '#233876', 
    Oil_Per_Hobbs_Hour: '#1C64F2',
    Fuel_Per_Tach_Hour: '#6016EF', 
    Oil_Per_Tach_Hour: '#111d41',
    Fuel_Per_Tach_2_Hour: '#F3F4F6', 
    Oil_Per_Tach_2_Hour: '#1DCD92',
}

export default function FuelOilReports() {
    const { organizationId, craftId } = useSession();
    const { data: { crafts } = {} } = useQuery( GetCraftsDocument, { variables: { organizationId } } );
    const [currentTab, setCurrentTab] = useSaveState('partsCardTab', '');
    const { data: { fuelOilReports } = {fuelOilReports: []} } = useQuery( FuelOilReportsDocument, { variables: { 
        id: crafts?.find((craft)=> currentTab === craft.tailNumber)?.id ?? craftId ?? ''
     } } );
    const fuelUnit = crafts?.find(craft => craft.id === craftId)?.fuelUnit;
    const oilUnit = crafts?.find(craft => craft.id === craftId)?.oilUnit;
    const tabsStyle = 'p-6 bg-white border border-slate-300 w-full rounded-b rounded-r shadow-blue';
    const tabs : TabCardItem[] = crafts?.map(craft => ({
        title: craft.tailNumber,
        content: (
            <div className={tabsStyle}>
                {fuelOilReports.length === 0 ? 
                    <div>No data available</div> 
                    :
                    <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        width={500}
                        height={500}
                        data={fuelOilReports.map((report) => 
                            ({ 
                                "Fuel Per Hobbs Hour": report.Fuel_Per_Hobbs_Hour, 
                                "Oil Per Hobbs Hour": report.Oil_Per_Hobbs_Hour, 
                                "Fuel Per Tach Hour": report.Fuel_Per_Tach_Hour, 
                                "Oil Per Tach Hour": report.Oil_Per_Tach_Hour,
                                "Fuel Per Tach 2 Hour": report.Fuel_Per_Tach_2_Hour, 
                                "Oil Per Tach 2 Hour": report.Oil_Per_Tach_2_Hour,
                                name: report.name
                            }))}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        { Object.keys(fuelOilReports[0] ?? []).filter((key) => key.includes('Per')).map((key) => 
                            fuelOilReports[0][key] && <Line key={key} type="monotone" unit={' '+ (key.includes('Oil') ? oilUnit?.toLowerCase() : fuelUnit?.toLowerCase())} 
                                dataKey={key.replaceAll('_', ' ')} stroke={colorForReports[key]} fill={colorForReports[key]} radius={40} />
                        ) }
                    </LineChart>
                    </ResponsiveContainer>
                }
            </div>
        ),
    })) || [];
    useEffect(() => {
        if (crafts && crafts.length > 0 && !currentTab) {
            setCurrentTab(crafts[0].tailNumber);
        }
    }, [crafts]);
    return (
        <div className="w-full">
            <TabCards items={tabs} 
            activeTitle={currentTab}
            setActiveTitle={setCurrentTab} 
            addBars={false} />
            
        </div>
    );
}
import { useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import { Flyout, useFlyout, type FlyoutHookReturn } from 'components/Flyout/Flyout';
import { Table } from 'components/Table/Table';
import { GetMroWorkOrderIntervalsDocument } from 'graphql/generated';
import { useMemo } from 'react';
import { Column } from 'react-table';
import NewBillableItemFlyout from './New';
import { formatUSD } from 'utils/formatter';
import { ClockIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import IntervalFlyout from './View';
import NewIntervalItemFlyout from './New';


export default function Intervals({
  workOrderId,
}: {
  workOrderId: string;
}) {
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data : { mroWorkOrderIntervals } = {} } = useQuery( GetMroWorkOrderIntervalsDocument, { variables: { where: { mroWorkOrderId: { equals: workOrderId } } } } );

  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => row.name ?? '', // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value}</span>
            </>
          );
        },
      },
      {
        Header: 'Value',
        id: 'value',
        accessor: 'value' , // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value}</span>
            </>
          );
        },
      },
    ], []
  );

  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner">
        <div className="flex flex-col w-full bg-white">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <ClockIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">Intervals</h1>
          </div>
          <Table
              data={mroWorkOrderIntervals ?? []}
              columns={columns}
              className="w-full bg-white border rounded"
              onRowClick={(row) => {
                updateFlyout({
                  title: 'Edit Interval',
                  content: <IntervalFlyout closeFlyout={closeFlyout} intervalId={row.id} />,
                });
              }}
            />
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
        </div>
        <div className="flex justify-end items-center">
          <Button text="Add Interval" color="navy" size="xs" onClick={()=> updateFlyout( {
            title: 'New Interval',
              content: <NewIntervalItemFlyout closeFlyout={closeFlyout} workOrderId={workOrderId} />,
            } )} />
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full" />
    </>
  );
}

import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GetMroEmployeesDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import EmployeeCard from './Partials/EmployeeCard';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import NewEmployee from './New';
import EmployeeModal from './Partials/EmployeeModal';
import { create, insertBatch, Lyra, PropertiesSchema, search } from '@lyrasearch/lyra';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';
import { Can } from 'contexts/AbilityContext';

const lyraSchema: PropertiesSchema = {
  firstName: 'string',
  lastName: 'string',
  title: 'string',
  phone: 'string',
};

const Employees = function (props) {
  const { user } = useSession();
  const { data: { mroEmployeeProfiles } = {} } = useQuery(GetMroEmployeesDocument, {
    variables: { mroOrganizationId: user?.mroOrganizationId },
  });
  const [filter, setFilter] = useState('');
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [searchIndex, setSearchIndex] = useState<Lyra<typeof lyraSchema>>();
  const [tableData, setTableData] = useState({});
  const [activeTab, setActiveTab] = useSaveState('employeeActiveTab', 'All');
  const [modal, setModal] = useState({ active: false, employeeId: '' });
  const tabs = ['All', 'Active', 'Inactive'];
  // HANDLES FILTER TAB FUNCTIONALITY
  useEffect(() => {
    if (mroEmployeeProfiles?.length)
      (async () => {
        let mutable = mroEmployeeProfiles;
        let tempProfiles = {};
        if (activeTab !== 'All') {
          if (activeTab === 'Active') {
            mutable = mroEmployeeProfiles.filter((profile) => profile.latestActivity);
          } else {
            mutable = mroEmployeeProfiles.filter((profile) => !(profile.latestActivity && !profile.latestActivity?.endTime));
          }
        }
        if (filter) {
          const result = await search(searchIndex, { term: filter, properties: '*' });
          const ids = result.hits.map((item) => item.id);
          mutable = mutable.filter((profile) => ids.includes(profile.id));
        }
        mutable.forEach((profile) => {
          if (profile.title in tempProfiles) {
            tempProfiles[profile.title].push(profile);
          } else {
            tempProfiles[profile.title] = [profile];
          }
        });
        setTableData(tempProfiles);
      })();
  }, [mroEmployeeProfiles, filter, searchIndex, activeTab]);

  useEffect(() => {
    if (mroEmployeeProfiles?.length) {
      create({
        schema: lyraSchema,
      }).then((db) =>
        insertBatch(
          db,
          mroEmployeeProfiles.map((prof) => ({
            id: prof.id,
            firstName: prof.firstName,
            lastName: prof.lastName,
            title: prof.title,
            phone: prof.phone,
          }))
        ).then(() => setSearchIndex(db))
      );
    }
  }, [mroEmployeeProfiles]);

  return (
    <>
      <Flyout {...flyoutProps} />
      {modal.active && (
        <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
          <div
            onClick={() => {
              setModal({ active: false, employeeId: '' });
            }}
            className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0"></div>
          <div className="flex flex-col items-center justify-center bg-white rounded-lg border border-slate-300 relative hover-shadow max-w-4xl w-full">
            <EmployeeModal employeeId={modal.employeeId} setModal={setModal} />
          </div>
        </div>
      )}
      <div className="p-6 bg-white border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex flex-col md:flex-row gap-3 justify-between">
          <div className="flex items-center">
            <UserGroupIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Employees</h1>
          </div>
          <div className="flex items-stretch gap-2 sm:flex-wrap justify-end">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
              <input
                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                placeholder="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <Can I="Create" a="Employees">
              <Link
                to="#"
                onClick={() => {
                  updateFlyout({ title: 'Create An Employee', content: <NewEmployee closeFlyout={closeFlyout}/> });
                }}
                className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4 hover:opacity-90">
                <PlusIcon className="h-3 w-3 mr-1" />
                Add New
              </Link>
            </Can>
          </div>
        </div>
      </div>
      <div className="flex border rounded border-slate-300 max-w-[calc(78rem-2px)] w-full shadow-blue">
        <div className="w-full p-6 rounded bg-white">
          <div className="flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-6">
            <Tabs items={tabs} activeItem={activeTab} setActiveItem={setActiveTab} />
          </div>
          {Object.keys(tableData).map((key) => {
            return <EmployeeCard key={key} cat={key} tableData={tableData} setModal={setModal} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Employees;

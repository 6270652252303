import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GetMroPartAndInventoriesDocument,
  GetMroPartRequestDocument,
  GetMroPartRequestsDocument,
  UpdateMroPartRequestDocument,
} from 'graphql/generated';
import PartLookup from 'views/Part145/Parts/Components/PartLookup';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';

const RequestEdit = function ({
  partRequestId,
  lockRequest,
  setEdit,
}: {
  partRequestId: string;
  lockRequest: boolean;
  setEdit: (edit: boolean) => void;
}) {
  const { user } = useSession();
  const { data: { mroWorkOrderPart } = {} } = useQuery(GetMroPartRequestDocument, { variables: { partRequestId } });
  const [selectedPart, setSelectedPart] = useState(mroWorkOrderPart?.mroPart);
  const [getPart, { data: { mroPart } = { mroPart: undefined } }] = useLazyQuery(GetMroPartAndInventoriesDocument);
  const [updatePartRequest] = useMutation(UpdateMroPartRequestDocument, {
    refetchQueries: [
      { query: GetMroPartRequestsDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
      { query: GetMroPartRequestDocument, variables: { partRequestId } },
    ],
  });
  const [lastPartId, setLastPartId] = useState<undefined | string>();
  const [initialValues, setInitialValues] = useState({
    partNumber: '',
    partInventory: '',
    unitPrice: 0,
    costPrice: 0,
    quantity: 0,
    notes: '',
    status: '',
  });

  useEffect(() => {
    if (selectedPart && lastPartId && lastPartId !== selectedPart.id) {
      getPart({ variables: { id: selectedPart.id } }).then(({ data: { mroPart: newPart } }) => {
        setInitialValues((initVals) => ({
          ...initVals,
          ...(newPart.isSerialized && { quantity: 1 }),
          unitPrice: newPart.estimatedCost ?? 0,
        }));
        setLastPartId(newPart.id);
      });
    }
  }, [selectedPart, getPart, mroPart, lastPartId]);

  useEffect(() => {
    if (mroWorkOrderPart && getPart) {
      setSelectedPart(mroWorkOrderPart.mroPart);
      getPart({ variables: { id: mroWorkOrderPart.mroPart?.id } });
      setLastPartId(mroWorkOrderPart.mroPart?.id);
      setInitialValues({
        partNumber: mroWorkOrderPart.mroPart?.partNumber ?? '',
        partInventory: mroWorkOrderPart.mroPartInventory?.condition ?? '',
        unitPrice: mroWorkOrderPart.unitPrice ?? 0,
        costPrice: mroWorkOrderPart.costPrice ?? 0,
        quantity: mroWorkOrderPart.quantity ?? 0,
        notes: mroWorkOrderPart.notes ?? '',
        status: mroWorkOrderPart.status ?? '',
      });
    }
  }, [mroWorkOrderPart, getPart]);

  const handleSubmit = useCallback(
    (values: { unitPrice: number; quantity: number; notes: string; status: string }) => {
      updatePartRequest({
        variables: {
          input: {
            id: partRequestId,
            mroPart: { connect: { id: selectedPart.id } },
            partNumber: selectedPart.partNumber,
            unitPrice: values.unitPrice,
            quantity: values.quantity,
            notes: values.notes,
            status: values.status,
          },
        },
      });
      setEdit(false);
    },
    [partRequestId, updatePartRequest]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* AIRCRAFT INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              {lockRequest ? (
                <FullFormikInput name="blank" label="Part #" placeholder={selectedPart.partNumber} disabled />
              ) : (
                <PartLookup {...{ selectedPart, setSelectedPart }} />
              )}
              <div className="flex gap-1">
                <FullFormikInput min={0} type="number" name="unitPrice" label="Unit Cost" stripStyle step="0.01" />
                <FullFormikInput min={0} type="number" name="costPrice" label="Base Cost" stripStyle step="0.01" />
                {selectedPart?.isSerialized ? (
                  <FullFormikInput type="number" label="Qty." name="blank" placeholder="Cannot set QTY for Serialized Part" disabled />
                ) : (
                  <FullFormikInput min={0} name="quantity" label="Qty." type="number" disabled={lockRequest} />
                )}
              </div>
              <FullFormikTextarea name="notes" label="Notes" />
            </div>
            <div className="flex justify-end items-center mt-4 gap-4">
              <button
                onClick={() => {
                  setEdit(false);
                }}
                className="flex font-semibold justify-center items-center cursor-pointer text-slate-500">
                Cancel
              </button>
              <Button text="Save" color="navy" size="xs" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestEdit;

import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_305_111)">
        <path
          d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_305_111">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

import { formatToFixed, formatUSD } from 'utils/formatter';
import { severityOptions } from './NewEstimateItem';


export default function EstimateItem({ item, index, status }) {
  const isDenied = status === 'APPROVED' && !item.customerApproved;
  return (
    <div
      key={item.id}
      className="flex flex-col px-2 my-2 py-4 first:mt-0 relative border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b">
      <div className="flex flex-col peer-hover:border-l-4 peer-hover:-ml-[calc(2rem+4px)] peer-hover:-m-8 peer-hover:p-8 peer-hover:-mb-4 peer-hover:pb-4 transition">
        <div className='flex justify-between items-center mb-2'>
          <div className={`flex h-6 ${status === 'APPROVED' && 'w-2/5'}`}>
            {status === 'APPROVED' && (
              <div
                className={`${
                  item.customerApproved ? 'text-brand-pale bg-brand-electric' : 'bg-slate-100 text-slate-400'
                } text-xs uppercase w-24 rounded py-1 px-2 text-center`}>
                <span className="font-bold">{item.customerApproved ? 'APPROVED' : 'DEFERRED'}</span>
              </div>
            )}
          </div>
          <div
            className={`${severityOptions[item.severity]?.textColor} ${severityOptions[item.severity]?.bgColor} my-2 text-sm flex font-semibold items-center rounded w-1/4 relative self-end`}>
            <span className="flex w-full justify-center py-1 px-3 items-center select-none capitalize">
              {severityOptions[item.severity]?.label}
            </span>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col text-sm w-2/3">
            <span className="font-semibold">Line Item</span>
            <span className={`${isDenied && 'line-through'} -mt-0.5`}>{item.title}</span>
          </div>
          {!item.isFlatRate && (
            <div className="flex flex-col text-sm w-1/6 items-end">
              <span className="font-semibold">Hours</span>
              <span className="-mt-0.5">{item.laborHours}</span>
            </div>
          )}
          <div className="flex flex-col text-sm w-1/6 items-end">
            <span className="font-semibold">Subtotal</span>
            <span className="-mt-0.5">{formatToFixed(item.laborCost)}</span>
          </div>
        </div>
        <div className={`${!item?.desc ? 'hidden' : 'flex'} flex-col text-sm bg-slate-50 border px-4 py-2 mt-2`}>
          <span className="text-xs text-brand-dark font-semibold uppercase">Description</span>
          <span className="font-mono text-xs py-1">{item.desc}</span>
        </div>
        <table className={`${item?.parts?.length === 0 ? '-mt-2 invoice-thead' : 'my-4 bg-white'} rounded w-full`}>
          <thead className="border border-b-0">
            <tr className="text-left text-sm border-b">
              <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
              <th className="px-2 font-semibold w-36">Part #</th>
              <th className="px-2 font-semibold">Description</th>
              <th className="px-2 font-semibold text-right">Unit Price</th>
              <th className="px-2 font-semibold text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody className="border">
            {item.parts?.map((part, ndx) => {
              return (
                <tr className="text-sm" key={ndx}>
                  <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                  <td className="align-top px-2">{part.partNumber}</td>
                  <td className="align-top px-2">{part.description}</td>
                  <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                  <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
                </tr>
              );
            })}
            {!item.temp && (
              <>
                <tr className="text-sm border-t bg-slate-50">
                  <td></td>
                  <td></td>
                  <td className="px-2 font-medium">Parts Cost:</td>
                  <td></td>
                  <td className="px-2 text-right">{formatToFixed(item.partsCost)}</td>
                </tr>
                <tr className="text-sm bg-slate-50">
                  <td></td>
                  <td></td>
                  <td className="px-2 font-medium">Labor Cost:</td>
                  <td></td>
                  <td className="px-2 text-right">{formatToFixed(item.laborCost)}</td>
                </tr>
              </>
            )}
            <tr className="text-sm border-t bg-slate-100">
              <td></td>
              <td></td>
              <td className="px-2 font-bold">Item Subtotal:</td>
              <td></td>
              <td className="px-2 text-right font-bold">{formatUSD(item.subTotal)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

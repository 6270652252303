import { useCallback, useEffect, useMemo, useState } from 'react';
import { BriefcaseIcon } from '@heroicons/react/24/solid';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CreateWorkOrderDocument,
  GetMroComponentsDocument,
  GetMroDashboardDocument,
  GetMroWorkOrdersDocument,
  GetServiceRequestDocument,
  LookupTailDocument,
  NewOrderCreateCraftMutation,
  NewOrderCustomersDocument,
  NewOrderCustomersQuery,
  RegisteredEngineType,
} from 'graphql/generated';
import { useSession } from 'contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import NewCustomerForm from 'views/Part145/Customers/New/Partials/NewCustomerForm';
import NewMember from 'views/Part145/Customers/Customer/Partials/NewMember';
import TailNumberLookup from './Partials/TailNumberLookup';
import SelectUser from './Partials/SelectUser';
import ContactConfirmation from './Partials/ContactConfirmation';
import AddInfo from './Partials/AddInfo';
import CreateCraftBasicInfo from './Partials/CreateCraftBasicInfo';

const WorkOrders = function () {
  const { flyoutProps, closeFlyout } = useFlyout();
  const navigate = useNavigate();
  const { serviceRequestId } = useParams();
  const { user } = useSession();
  const [createWorkOrder] = useMutation(CreateWorkOrderDocument, {
    refetchQueries: [
      GetMroDashboardDocument,
      { query: GetMroWorkOrdersDocument,  variables: {
        where: {
          mroOrganizationId: { equals: user?.mroOrganizationId },
        },
      }, },
      { query: GetServiceRequestDocument, variables: { id: serviceRequestId } },
    ],
  });
  const [tailNumber, setTailNumber] = useState('');

  const { data: { mroServiceRequest } = {} } = useQuery(GetServiceRequestDocument, {
    skip: serviceRequestId === undefined,
    variables: { id: serviceRequestId ?? '' },
  });
  const [getComponents, { data: { mroComponents = [] } = {} }] = useLazyQuery(GetMroComponentsDocument);
  const [getCustomers, { data: { mroCustomers = [] } = {} }] = useLazyQuery(NewOrderCustomersDocument);
  const [getTailInfo, { data: { registrations } = { registrations: undefined } }] = useLazyQuery(LookupTailDocument);
  const tailInfo = registrations?.[0];
  const [step, setStep] = useState(1);
  const [disableButton, setDisableButton] = useState(true);
  const [selectedEmps, setSelectedEmps] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState<NewOrderCustomersQuery['mroCustomers'][number]>();
  const [selectedContact, setSelectedContact] = useState<NewOrderCustomersQuery['mroCustomers'][number]['mroContacts'][number]>();
  useEffect(() => {
    if (tailNumber && step === 2) {
      getCustomers({ variables: { tailNumber, mroOrganizationId: user.mroOrganizationId } });
    }
    if (tailNumber && step === 4) {
      const mroCraft = selectedCustomer?.mroCrafts?.find((craft) => craft.tailNumber === tailNumber);
      getComponents({ variables: { mroCraftId: mroCraft?.id } });
    }
  }, [getCustomers, getComponents, tailNumber, step, user.mroOrganizationId, selectedCustomer?.mroCrafts]);

  useEffect(() => {
    if (mroServiceRequest && step === 1) {
      setSelectedCustomer(mroServiceRequest.mroCustomer);
      setTailNumber(mroServiceRequest.mroCraft.tailNumber);
      setSelectedContact(mroServiceRequest.mroCustomer.mroContacts[0]);
      setStep(4);
    } else if (!mroServiceRequest && step === 2) {
      setSelectedCustomer((cust) => (cust && mroCustomers.find((c) => c.id === cust.id)));
    }
  }, [mroCustomers, mroServiceRequest, setSelectedCustomer, setTailNumber, setSelectedContact, step]);

  const mroComponentConnectOrCreate = (title: string, mroCraftId: string) => {
    //We need to find the mroComponentId for the mroComponent that has the title we are looking for, we probaly should have a better way handle this
    const mroComponentId = mroComponents?.find((item) => item?.mroWorkOrderItemCategories?.find((cat) => cat?.title === title))?.id ?? '01';
    return {
      where: { id: mroComponentId },
      create: {
        mroCraft: { connect: { id: mroCraftId } },
      },
    };
  };

  const createOrder = useCallback(
    async (values) => {
      const mroTitle = values.mroTitle;
      const mroPromiseDate = new Date(values.promiseDate).setHours(12); // Set to Noon easten time
      const mroArrivalDate = new Date(values.arrivalDate).setHours(12); // Set to Noon easten time
      const locationId = values?.locationId ?? '';
      const mroWorkOrderCategoryId = values?.mroWorkOrderCategoryId ?? '';
      delete values.mroTitle;
      delete values.promiseDate;
      delete values.arrivalDate;
      delete values.locationId;
      delete values.mroWorkOrderCategoryId;
      const mroCraft = selectedCustomer?.mroCrafts?.find((craft) => craft.tailNumber === tailNumber) ?? mroServiceRequest?.mroCraft;
      
      const blankWO = values.mroWorkOrderTemplateId === 'blank'; // If the user selected the blank template, we need to remove the id from the values
      if(blankWO) { delete values.mroWorkOrderTemplateId; }
      const { data: res } = await createWorkOrder({
        variables: {
          input: {
            ...( mroServiceRequest?.craftServiceRequests && {craftServiceRequests: { connect: mroServiceRequest.craftServiceRequests.map((csr) => ({ id: csr.id }) ) } }),
            mroWorkOrderTemplateId: values.mroWorkOrderTemplateId,
            title: mroTitle,
            promiseDate: new Date(mroPromiseDate),
            arrivalDate: new Date(mroArrivalDate),
            ...( selectedCustomer && {mroCustomer: { connect: { id: selectedCustomer.id } }} ),
            mroOrganization: { connect: { id: user.mroOrganizationId } },
            ...( selectedContact && { primaryContact: { connect: { id: selectedContact.id } } }),
            ...(locationId !== '' && { mroLocation: { connect: { id: locationId } } } ),
            status: 'DRAFT',
            assignees: { connect: assignees.map((a) => ({ id: a.id })) },
            mroServiceRequest: serviceRequestId && { connect: { id: serviceRequestId } },
            ...(mroWorkOrderCategoryId !== '' && { mroWorkOrderCategory: { connect: { id: mroWorkOrderCategoryId } } } ),
            mroCraft: {
              connect: {
                id: mroCraft.id,
              },
            },
            ...(values.mroWorkOrderTemplateId || blankWO
              ? {}
              : {
                  itemCategories: {
                    create: [
                      {
                        title: 'Airframe',
                        sortIndex: 0,
                        iconName: 'AirplaneIcon',
                        categoryNumber: 1,
                        mroComponent: { connectOrCreate: mroComponentConnectOrCreate('Airframe', mroCraft.id) },
                      },
                      {
                        title: 'Engine',
                        sortIndex: 100,
                        iconName: 'EngineIcon',
                        categoryNumber: 2,
                        mroComponent: { connectOrCreate: mroComponentConnectOrCreate('Engine', mroCraft.id) },
                      },
                      ...(mroCraft?.engineCount > 0
                        ? [
                            {
                              title: 'Propeller',
                              sortIndex: 200,
                              iconName: 'SoundwaveIcon',
                              categoryNumber: 3,
                              mroComponent: { connectOrCreate: mroComponentConnectOrCreate('Propeller', mroCraft.id) },
                            },
                          ]
                        : []),
                      ...(mroCraft?.engineCount === 2
                        ? [
                            {
                              title: 'Engine 2',
                              sortIndex: 300,
                              iconName: 'EngineIcon',
                              categoryNumber: [RegisteredEngineType.None, RegisteredEngineType.Reciprocating].includes(tailInfo?.engineType) ? 4 : 3,
                              mroComponent: { connectOrCreate: mroComponentConnectOrCreate('Engine 2', mroCraft.id) },
                            },
                            {
                              title: 'Propeller 2',
                              sortIndex: 400,
                              iconName: 'SoundwaveIcon',
                              categoryNumber: 5,
                              mroComponent: { connectOrCreate: mroComponentConnectOrCreate('Propeller 2', mroCraft.id) },
                            },
                          ]
                        : []),
                      {
                        title: 'Miscellaneous',
                        sortIndex: 300,
                        iconName: 'FlagIcon',
                        categoryNumber: tailInfo?.engineCount === 2 ? 6 : 4,
                      },
                    ],
                  },
                }),
            ...values,
          },
        },
      });
      navigate(`/mro/work-orders/${res.createMroWorkOrderFromTemplate.id}`);
    },

    [assignees, createWorkOrder, navigate, selectedContact, selectedCustomer, user, tailNumber, tailInfo, mroComponentConnectOrCreate]
  );
  const modalContents = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <TailNumberLookup
            tailNumber={tailNumber}
            setDisableButton={setDisableButton}
            setTailNumber={setTailNumber}
            setStep={setStep}
            disableButton={disableButton}
          />
        );
      case 2:
        return (
          <SelectUser
            mroCustomers={mroCustomers}
            tailNumber={tailNumber}
            setSelectedCustomer={setSelectedCustomer}
            setStep={setStep}
            selectedCustomer={selectedCustomer}
          />
        );
      case 3:
        return (
          <ContactConfirmation
            mroContacts={selectedCustomer?.mroContacts}
            setSelectedContact={setSelectedContact}
            setStep={setStep}
            selectedContact={selectedContact}
            closeFlyout={closeFlyout}
          />
        );
      // We are skipping this for now
      // case 4:
      //   return (
      //     <AssignWorkers
      //       step={step}
      //       setStep={setStep}
      //       selectedEmps={selectedEmps}
      //       setSelectedEmps={setSelectedEmps}
      //       setAssignees={setAssignees}
      //     />
      //   );

      case 4:
        return <AddInfo setStep={setStep} createOrder={createOrder} mroServiceRequest={mroServiceRequest} />;
      case 97:
        return (
          <NewCustomerForm
            tailNum={tailNumber}
            context="workOrder"
            setStep={setStep}
            postSubmit={(e) => {
              setSelectedCustomer(e.mroCustomer);
              setStep(3);
            }}
          />
        );
      case 98:
        return (
          <NewMember
            addressInfo={selectedCustomer?.billingAddress}
            mroCustomerId={selectedCustomer?.id}
            closeFlyout={closeFlyout}
            setStep={setStep}
            postSubmit={(e) => {
              setSelectedContact(e.mroContact);
              setStep(4);
            }}
          />
        );
      case 99:
        return (
          <CreateCraftBasicInfo
            tailNumber={tailNumber}
            setStep={setStep}
            tailInfo={tailInfo}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedContact={setSelectedContact}
          />
        );
      default:
        return <NewCustomerForm postSubmit={() => setStep(3)} />;
    }
  }, [step, tailNumber, disableButton, mroCustomers, selectedCustomer, selectedContact, closeFlyout, selectedEmps, createOrder]);

  useEffect(() => {
    if (tailNumber) {
      const delayDebounceFn = setTimeout(() => {
        var tempTail = tailNumber.toUpperCase();
        if (tempTail.startsWith('N')) {
          tempTail = tempTail.substring(1);
        }
        getTailInfo({ variables: { tailNumber: tempTail } });
        getCustomers({ variables: { tailNumber, mroOrganizationId: user.mroOrganizationId } }).then((res) => {
          tailNumber.length < 2 ? setDisableButton(true) : setDisableButton(false);
        });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [tailNumber, getTailInfo, getCustomers, user.mroOrganizationId]);

  return (
    <div className="bg-white border rounded border-slate-300 relative">
      {/* HEADER SECTION */}
      <div className="flex flex-col">
        <div className="flex rounded-t items-center w-full p-6">
          <BriefcaseIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem] shrink-0" />
          <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">New Work Order</h1>
        </div>
      </div>
      {/* BODY */}
      <Flyout {...flyoutProps} />
      <div className="flex items-center justify-center w-full h-full border-t border-slate-300 bg-slate-50 py-24 px-4">
        <div className="p-8 bg-white rounded shadow-blue border border-slate-200 w-full max-w-lg">{modalContents}</div>
      </div>
    </div>
  );
};

export default WorkOrders;
import { Fragment, useContext, useEffect } from 'react';
import { ArrowLeftIcon } from '../../../assets/icons';
import logo from '../../../assets/icons/logo_navbar_w.svg';
import { useQuery } from '@apollo/client';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSession } from 'contexts';
import { GetOrganizationsDocument } from 'graphql/generated';
import { OrgTypes } from 'utils/orgTypes';
import UserMenu from './UserMenu';
import { MegaphoneIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { AbilityContext } from 'contexts/AbilityContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BarTop(props) {
  const location = useLocation();
  const href = useLocation().pathname;
  const announcementSelected = href.includes("/announcements");
  const isDev = process.env.REACT_APP_APP_MODE !== 'production';
  const ability = useContext(AbilityContext);

  const { organizationId, setOrganizationId, user, organizationType } = useSession();
  const { data: { organizations } = { organizations: [] } } = useQuery(GetOrganizationsDocument, { variables: { where: { userOrganizationProfiles: { 
    some: { user: { is:{ id: { equals: user?.id } } } } 
   } } } });
 
  useEffect(() => {
    if (!organizationId && organizations && organizations.length) {
      setOrganizationId(organizations[0].id);
    }
  }, [organizationId, organizations, setOrganizationId]);

  const altNav = props?.mobileExtraNav?.filter((nav) => {
    if(ability.can(nav.action, nav.subject) || !nav.action) return true;
    return false;
  });
  return (
    <>
      <div className="flex sticky flex-col top-0 w-screen h-auto z-40 md:z-[60] border-b border-slate-300 shadow-blue">
        {/* Top Bar Mobile */}
        <div className="md:hidden bg-brand-dark w-full">
          {props.context == null || !location.pathname.includes("/aircraft") || Object.keys(props.context).length === 0 ? (
            <div className="flex items-center justify-between h-20 px-4 py-2 w-full">
              <Link to={organizationType === OrgTypes.PART145 ? '/mro/dashboard' : '/crafts'} className={`h-10`}>
                <img src={logo} className="h-10" alt=""/>
              </Link>
              <div className='flex items-center gap-8'>
                {organizationType === OrgTypes.PART91 && <Link to="/announcements">
                  <MegaphoneIcon className={`h-11 w-11  ${announcementSelected ? 'text-white' : 'text-brand-pale' }`} aria-hidden="true" />
                  </Link>}
                {altNav && <Menu
                  as="div"
                  className="flex items-center justify-center relative group transition ml-2 w-[28px]">
                  <Menu.Button className="">
                    <EllipsisVerticalIcon className="h-10 w-10 text-white cursor-pointer" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                      {altNav?.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link to={item.href} className={`${active ? 'bg-slate-100' : ''} px-4 py-3 text-sm text-slate-700 w-full text-right rounded flex justify-between`}>
                              <item.icon className="w-5 h-5 mb-2" aria-hidden="true" />
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>}
                <UserMenu />
              </div>
            </div>
          ) : (
            <div className="flex items-center h-20 px-4 py-2 w-full">
              <Link to={location.pathname === "/aircraft"?"/crafts":"/aircraft"}><ArrowLeftIcon className={` h-4 w-4 ml-1 mr-4 text-white`} aria-hidden="true" /></Link>
              <div className="text-white">
                <h1 className="text-xl font-semibold -mb-1">{props.context.line1}</h1>
                <h2>{props.context.line2}</h2>
              </div>
            </div>
          )}
        </div>
        
      </div>
    </>
  );
}

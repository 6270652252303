import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from "components/Form/StandardForm";
import Modal from "components/Modal/Modal";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetMroOrganizationFeeDocument, GetMroOrganizationFeesDocument, MroFeeType, RemoveMroOrganizationFeeDocument, UpdateMroOrganizationFeeDocument } from "graphql/generated";
import { useState } from "react";

export default function ViewFee({ id, closeFlyout }: { id: string; closeFlyout: () => void }) {
    const { user } = useSession();
    const [ showModal, setShowModal ] = useState(false);
    const [deleteFee] = useMutation(RemoveMroOrganizationFeeDocument, {
        onCompleted: () => closeFlyout(),
        refetchQueries: [{ query: GetMroOrganizationFeesDocument, variables: { mroOrganizationId: user.mroOrganizationId } }],
    });
    const [updateFee] = useMutation(UpdateMroOrganizationFeeDocument, {
        refetchQueries: [{ query: GetMroOrganizationFeesDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
            { query: GetMroOrganizationFeeDocument, variables: { id } }
        ],
    });
    const { data: { mroOrganizationFee } = {} } = useQuery( GetMroOrganizationFeeDocument, { variables: { id } });

    const initialValues = {
        name: mroOrganizationFee?.name,
        description: mroOrganizationFee?.description,
        active: mroOrganizationFee?.active,
        onByDefault: mroOrganizationFee?.onByDefault,
        isPercentage: mroOrganizationFee?.isPercentage,
        value: mroOrganizationFee?.value,
        billableType: mroOrganizationFee?.billableType
    };
    return (
        <>
            { showModal && ( <Modal message={'You are about to delete this Fee.'} accept={ () => deleteFee({ variables:{ id } })} cancel={setShowModal} /> ) }
            <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
                updateFee({
                    variables: {
                        input: {
                            id,
                            ...values
                        },
                    },
                })
                .then(() => closeFlyout())
                .catch(console.error);
            }}>
                {({ values }) => (
                    <Form className="flex flex-col gap-5 mx-5">
                        <Card className="">
                        <div className="flex gap-5 flex-col">
                            <FullFormikInput name="name" placeholder="Fee Name" label="Fee Name" />
                            <FullFormikInput name="description" placeholder="Fee Description" label="Description" />
                            <div className="flex justify-center gap-10">
                                <FullFormikCheckBox name="active" label="Active" isVertical={true} opposite={true} />
                                <FullFormikCheckBox name="onByDefault" label="On By Default" isVertical={true} opposite={true} />
                                <FullFormikCheckBox name="isPercentage" label="Pecentage" isVertical={true} opposite={true} />
                            </div> 
                            <div className="flex gap-5">
                                <FullFormikInput name="value" placeholder="Fee Amount" label={values.isPercentage ? "Percentage" : "Fee Amount" } />
                                <FullFormikSelect disabled={!values.isPercentage} name="billableType" label="Billable Type" >
                                    <option value={MroFeeType.Both}>Both</option>
                                    <option value={MroFeeType.Parts}>Parts</option>
                                    <option value={MroFeeType.Labor}>Labor</option>
                                </FullFormikSelect>
                            </div>
                        </div>
                    </Card>
                        <div className="flex justify-end gap-10">
                            <Button text="Delete" color="red" size="xs" onClick={() => setShowModal(true) } />
                            <Button text="Save" color="navy" size="xs" type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import { Form, Formik } from 'formik';
import { CreateMroWorkOrderBillingItemDocument, GetMroWorkOrderBillingItemsDocument, GetMroWorkOrderBillingItemsQuery, MroBillableType } from 'graphql/generated';

interface BillableItem {
    name: string;
    value: number;
    active: boolean;
    isPercentage?: boolean;
    isDiscount?: boolean;
    billableType: MroBillableType;
}

export default function NewBillableItemFlyout({
    closeFlyout,
    workOrderId,
    isDiscount,
}: {
    closeFlyout: FlyoutHookReturn['closeFlyout'];
    workOrderId?: string;
    isDiscount?: boolean;
}) {
    const [createBillableItem] = useMutation(CreateMroWorkOrderBillingItemDocument, {
        refetchQueries: [ GetMroWorkOrderBillingItemsDocument ],
    });
    const initialValues : BillableItem = {
        name: '',
        value: 0,
        active: true,
        isDiscount,
        isPercentage: false,
        billableType: MroBillableType.Both,
    };

    const handleSubmit = async (values) => {
        await createBillableItem({
            variables: {
                input: {
                    ...values,
                    mroWorkOrder: { connect: { id: workOrderId } },
                },
            },
        });
        closeFlyout();
    };
    const billableName = isDiscount ? "Discount" : "Charge";
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form className='mx-3'>
                        <Card>
                            <div className="flex gap-5">
                                <FullFormikInput name="name" label={billableName+" Name"} />
                                <div className='justify-center'>
                                    <FullFormikCheckBox name="active" label="Active" />
                                </div>          
                            </div>
                            <div className="flex gap-5">
                                <FullFormikInput type="number" name="value" label={ values.isPercentage ? "Percentage %" : "Amount $" } />
                                <div className='justify-center'>
                                    <FullFormikCheckBox name="isPercentage" label="Percent" />
                                </div>                                
                                <FullFormikSelect name="billableType" label="Apply to">
                                    <option value={MroBillableType.Both}>Both (Labor and Parts)</option>
                                    <option value={MroBillableType.Labor}>Labor</option>
                                    <option value={MroBillableType.Parts}>Parts</option>
                                </FullFormikSelect>
                            </div>
                        </Card>
                        <div className="flex justify-end items-center mt-4">
                            <Button text={"Add "+billableName} color="navy" size="xs" type='submit' />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
  );
}

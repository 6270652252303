import { useQuery } from "@apollo/client";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { format } from "date-fns";
import { GetAnnouncementDocument } from "graphql/generated";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../Edit";

export default function Announcement() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
    const { data: { announcement } = { announcement: undefined } } = useQuery(GetAnnouncementDocument, { variables: { id } });
    return (
        <div>
            <Flyout {...flyoutProps} />
            <div className="flex items-center justify-between">
                <Button onClick={() => navigate(-1)} color="pale" className="mb-2" size="xs" text="< Back"/>
                { announcement?.canEdit && <PencilSquareIcon className="h-6 w-6 text-brand hover:opacity-50" onClick={() => updateFlyout({ title: "Edit Announcement", content: <Edit closeFlyout={closeFlyout} announcement={announcement} /> }) } />}
            </div>
            <Card>
                <div className="p-2 flex items-center justify-center w-full">
                    <h1 className="font-bold text-lg md:text-3xl">{announcement?.title}</h1>
                </div>
                {announcement?.createdAt && <dt className="mb-2 font-bold text-center">Published {format(announcement?.createdAt, 'MM-dd-yyyy')}</dt>}
                {announcement?.updatedAt && <dt className="mb-2 font-bold text-center">Published {format(announcement?.createdAt, 'MM-dd-yyyy')}</dt>}
                <dt className="mb-2 text-sm text-center">By: {announcement?.authorName}</dt>
                <div className="p-2">
                    <div dangerouslySetInnerHTML={ { __html: announcement?.content  } } />
                </div>
            </Card>
        </div>
    )

}
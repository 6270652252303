import { useQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import UserIcon from 'components/UserIcon/UserIcon';
import { useSession } from 'contexts';
import { GetMroEmployeeDocument, GetOrganizationsDocument } from 'graphql/generated';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const userNavigation = [{ name: 'Settings', href: '/mro/account/settings' }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MobileMenu(props) {
  const navigate = useNavigate();
  const { organizationId, setOrganizationId, logout, user } = useSession();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
    skip: !user?.mroEmployeeProfileId,
  });
  const { data: { organizations } = { organizations: [] }, error } = useQuery(GetOrganizationsDocument, { variables: { where: { userOrganizationProfiles: { 
    some: { user: { is:{ id: { equals: user?.id } } } } 
   } } } });
  return (
    <>
      <div
        className={`${
          props.mobileMenu ? 'h-screen' : 'h-0 hidden'
        } transition-all ease-in-out duration-300 md:hidden fixed top-0 w-screen bg-brand-dark flex flex-col justify-between z-30`}>
        <div className="w-full px-6 py-4 mt-20">
          {props.navigation.map((item) => {
            return (
              <Link
                to={item.href}
                className={`${
                  item.active ? 'border-r-4' : ''
                } transition-all ease-in-out flex items-center text-white text-lg md:text-xl font-bold mb-6 border-white hover:border-r-4`}
                key={item.name}>
                <item.icon className="w-6 h-6 mr-5" aria-hidden="true" />
                {item.name}
              </Link>
            );
          })}
        </div>
        <Menu as="div" className={'absolute bottom-0 p-1 left-0 group w-full'}>
          <Menu.Button className={'w-full p-2'}>
            <div
              className={
                'flex rounded cursor-pointer transition-all pr-1 border border-brand bg-brand-electric/10 hover:shadow-inner hover:border-brand-electric hover:bg-brand ease-in-out'
              }>
              <div className={'flex items-center p-2 rounded-l transition w-full'}>
                <UserIcon
                  iconSize="lg"
                  iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                  iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
                  iconColor={mroEmployeeProfile?.profileColor}
                  className=""
                />
                <div className="flex flex-col px-2">
                  <span className="text-white font-bold text-left whitespace-nowrap">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <span className="block text-left text-xs text-brand-offwhite font-medium -mt-1 whitespace-nowrap">
                    {organizations.find((o) => o.id === organizationId)?.name}
                  </span>
                </div>
              </div>
              <ChevronUpDownIcon className="h-auto w-8 px-1.5 text-white rounded-r" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className={`origin-bottom absolute left-0 -mb-1 w-[calc(100%-1.5rem)] bottom-full rounded shadow-lg shadow-brand border border-brand-electric/50 bg-brand focus:outline-none z-[99] mx-3`}>
              {organizations?.map((item) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-brand-dark rounded' : '',
                        'block px-4 py-2 text-sm text-white w-full text-left font-semibold my-1'
                      )}
                      onClick={() => {
                        setOrganizationId(item.id);
                        navigate('/crafts');
                      }}>
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
              <hr className="w-full border-brand-electric/50" />
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={classNames(
                        active ? 'bg-brand-dark rounded' : '',
                        'block px-4 py-2 text-sm text-white font-medium transition mt-1'
                      )}>
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-brand-dark rounded' : '',
                      'block px-4 py-2 text-sm text-white w-full text-left font-medium transition mb-1'
                    )}
                    onClick={() => {
                      logout()
                        .then(() => {
                          navigate(props.altLoginUrl ?? '/auth');
                        })
                        .catch(console.error);
                    }}>
                    Sign Out
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

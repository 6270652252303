import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { ShareIcon, XMarkIcon } from '@heroicons/react/24/solid';
export default function DocumentViewer({ close, uri, fileType }) {

  return (
    <div className="fixed flex items-center justify-center h-full w-full bottom-0 left-0 z-50">
      <div
        onClick={() => {
          
        }}
        className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0">
          <ShareIcon
          onClick={async () => {
            try {
              await navigator.share({ title:"Document From Coflyt", url:uri , text: "Check out this document from Coflyt"}) 
            } catch (error) {
              console.log(error);
            }
          }}
          className="h-8 w-8 md:h-10 md:w-10 absolute top-0 md:top-2 right-20 text-slate-500 cursor-pointer transition z-[60]"
          />
          <XMarkIcon
          onClick={() => {
            close();
          }}
          className="h-8 w-8 md:h-10 md:w-10 absolute top-0 md:top-2 right-2 text-slate-500 cursor-pointer transition z-[60]"
          />
          <DocViewer documents={[ { uri, fileType } ]} className='' pluginRenderers={DocViewerRenderers} />
        </div>
    </div>
  );
}

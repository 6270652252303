import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
    CreateMroPartWarehouseDocument,
    GetMroPartWarehouseDocument,
    GetMroPartWarehouseQuery,
    GetMroWorkOrderPartDocument,
    GetMroWorkOrderPartsDocument,
    GetMroWorkOrderPartsQuery,
    RemoveMroPartWarehouseDocument,
    UpdateMroPartWarehouseDocument,
} from 'graphql/generated';
import { useSession } from 'contexts';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';

const UpdateWarehouseFlyout = function ({ closeFlyout, mroPartWarehouse } : 
    { closeFlyout: () => void, mroPartWarehouse: GetMroPartWarehouseQuery['mroPartWarehouses'][0]}) {

    const { user } = useSession();
    const [updateMroPartWarehouse] = useMutation(UpdateMroPartWarehouseDocument, { refetchQueries: [{ query: GetMroPartWarehouseDocument, variables:{ input: { mroOrganizationId: { equals: user?.mroOrganizationId } } } }]});
    const [deleteMroPartWarehouse] = useMutation(RemoveMroPartWarehouseDocument, { refetchQueries: [{ query: GetMroPartWarehouseDocument, variables:{ input: { mroOrganizationId: { equals: user?.mroOrganizationId } } } }]});
    const [showModal, setShowModal] = useState(false);
    const handleSubmit = async function (values) {
        await updateMroPartWarehouse({
            variables: {
                input: {
                    id: mroPartWarehouse.id,
                    name: values.name,
                    mroOrganization:{
                        connect:{
                            id: user?.mroOrganizationId
                        }
                    }
                },
            },
        });
        closeFlyout();
    }
    return (
        <>
            <div className="p-4">
                { showModal && <Modal message="Are you sure you want to delete this Warehouse" cancel={setShowModal} accept={async () => { 
                        await deleteMroPartWarehouse({ variables: { id: mroPartWarehouse.id }});
                        closeFlyout();
                    }} />}
                <Formik initialValues={{
                    name: mroPartWarehouse.name,
                }} onSubmit={handleSubmit} enableReinitialize>
                {({ values }) => (
                    <Form>
                        {/* NEW WAREHOUSE */}
                        <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                            <div className="flex gap-1">
                                <FullFormikInput name="name" label="Name" />
                            </div>
                        </div>
                        <div className="flex justify-end items-center mt-4 gap-2">
                            <Button text="Delete" color="red" size="sm" type="button" onClick={()=> setShowModal(true)} />
                            <Button text="Save" color="navy" size="sm" type="submit" />
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        </>
    );
};

export default UpdateWarehouseFlyout;

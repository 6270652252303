import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

// STEP 1
const TailNumberLookup = function ({ setStep, tailNumber, disableButton, setDisableButton, setTailNumber }) {
  return (
    <form className="flex flex-col" onSubmit={() => !disableButton && setStep(2)}>
      <h2 className="text-xl font-bold text-brand">Select an Aircraft</h2>
      <span className="text-sm text-slate-500 mb-2">Which aircraft is being serviced?</span>
      <div className="flex items-center relative">
        <MagnifyingGlassIcon className="h-4 w-4 text-brand-electric left-3 absolute" />
        <input
          className={`pl-8 grow border rounded border-slate-300 bg-slate-50 h-10 px-2 text-brand focus:bg-white focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm font-bold placeholder:font-normal focus:ring-0 focus:outline-0`}
          placeholder="Type the tail number (ex. N123AB)"
          required
          value={tailNumber}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              setDisableButton(true);
            }
          }}
          onChange={(e) => {
            setTailNumber(e.target.value?.toUpperCase());
          }}
        />
      </div>
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={() => {
            setStep(2);
          }}
          disabled={disableButton}
          type="button"
          className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-2 px-4 disabled:opacity-50">
          Continue
        </button>
      </div>
    </form>
  );
};

export default TailNumberLookup;

import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.7 14.7L8.1 13.3L3.8 9H16V7H3.8L8.1 2.7L6.7 1.3L0 8L6.7 14.7Z" fill="currentColor" />
    </svg>
  );
};

export default Icon;

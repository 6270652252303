import { useMutation } from "@apollo/client";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import Button from "components/Button/Button";
import { FullFormikInput } from "components/Form/StandardForm";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { CreateChargebeeSubscriptionDocument, GetOrganizationSettingsDocument } from "graphql/generated";
import { useState } from "react";


export default function ChargeebeePaymentModal({cancel, plan, isTrial} : { cancel: (cancel: boolean) => void, plan: any, isTrial: boolean }) {
    const { organizationId } = useSession();
    const { toastProps, showToast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [createSubscription] = useMutation(CreateChargebeeSubscriptionDocument, { 
        onCompleted: (data) => {
            if(data.createChargebeeSubscription === 'success'){
                cancel(false);
            }else{ 
                alert('Error:' + data.createChargebeeSubscription);
            }
            setIsSubmitting(false);
        },
        refetchQueries: [{ query: GetOrganizationSettingsDocument,  variables: { id: organizationId }  }]
    });

    let numberOfFreeDays = 30;
    if(plan?.coupon?.couponType === 'FREEDAYS'){
        numberOfFreeDays += Number(plan?.coupon?.discount);
    }
    return (
        <>
            <Toast {...toastProps} position="top" />
            <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
                <div
                onClick={() => {
                    cancel(false);
                }}
                className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0"></div>
                <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-md w-full">
                    <XMarkIcon
                        onClick={() => {
                            cancel(false);
                        }}
                        className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
                    />
                    <Formik initialValues={ { cardNumber: '', cvv: '', month: '', year:'' } } enableReinitialize onSubmit={ async (values)=> {
                        setIsSubmitting(true);
                        await createSubscription({ variables: { input: { ...values, ...(plan.discount && { promoCode: plan?.coupon?.discountId}) }, organizationId, planId: plan.id  } });
                    }}>
                        <Form className="flex flex-col gap-3 items-center p-8">
                            <div className="flex flex-col gap-2 mb-3 text-center">
                                <span className="font-bold text-xl text-brand-dark">{plan?.name} {plan?.billingMode}</span>
                                <span className="text-lg font-semibold">${plan.discount ?? plan?.price} /{plan?.billingMode === 'Monthly' ? 'month' : 'year' }</span>
                                { plan.discount && plan?.coupon?.description && <span className="text-sm">{plan?.coupon?.description}</span> }
                                { plan.discount !== plan.price && <span className="text-sm">Was ${plan.price}</span> }
                            </div>
                            {isTrial && <span className="text-center text-sm">You will be on a trial plan first and be charged in {numberOfFreeDays} days</span>}
                            <span className="font-bold text-lg text-brand-dark">Payment Information</span>
                            <FullFormikInput name="cardNumber" label="Card Number" placeholder="1111 1111 1111 1111" />
                            <div className="flex gap-3">
                                <FullFormikInput name="cvv" label="CVV" placeholder="123" />
                                <FullFormikInput name="month" label="Month" placeholder="12" />
                                <FullFormikInput name="year" label="Year" placeholder="2024" />
                            </div>
                            {/* <ExpressCheckoutElement onConfirm={(info) => { }} /> */}
                            <hr className="w-full border-slate-100 mb-2" />
                            <Button text="Subscribe" color="blue" size="md" type='submit' disabled={isSubmitting}  />
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}
import { useState } from 'react';
import { formatToFixed, formatUSD } from 'utils/formatter';

export default function InvoiceItem({ item, index }) {
  const [contents] = useState({ notes: '', ...item });

  return (
    <div
      key={item.id}
      className="flex flex-col px-2 my-4 first:mt-0 relative border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b">
      <div className="flex flex-col peer-hover:border-l-4 peer-hover:-ml-[calc(2rem+4px)] peer-hover:-m-8 peer-hover:p-8 peer-hover:-mb-4 peer-hover:pb-4 transition">
        <div className="flex justify-between">
          <div className="flex flex-col text-sm w-2/3">
            <span className="font-semibold">Line Item</span>
            <span className="-mt-0.5">{contents.discrepancy}</span>
          </div>
          {!contents.isFlatRate && (
            <div className="flex flex-col text-sm w-1/6 items-end">
              <span className="font-semibold">Hours</span>
              <span className="-mt-0.5">{contents.laborHours}</span>
            </div>
          )}
          <div className="flex flex-col text-sm w-1/6 items-end">
            <span className="font-semibold">Subtotal</span>
            <span className="-mt-0.5">{formatToFixed(contents.laborCost)}</span>
          </div>
        </div>
        <div className={`${!contents.notes ? 'hidden' : 'flex'} flex-col text-sm bg-slate-50 border px-4 py-2 mt-2`}>
          <span className="text-xs text-brand-dark font-semibold uppercase">Action Taken</span>
          <span className="font-mono text-xs py-1">{contents.notes}</span>
        </div>
        <table className={`${contents.parts?.length === 0 ? '-mt-2 invoice-thead' : 'my-4 bg-white'} rounded w-full`}>
          <thead className="border border-b-0">
            <tr className="text-left text-sm border-b">
              <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
              <th className="px-2 font-semibold w-36">Part #</th>
              <th className="px-2 font-semibold">Description</th>
              <th className="px-2 font-semibold text-right">Unit Price</th>
              <th className="px-2 font-semibold text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody className="border">
            {contents.parts.map((part, ndx) => {
              return (
                <tr className="text-sm" key={ndx}>
                  <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                  <td className="align-top px-2">{part.partNumber}</td>
                  <td className="align-top px-2">{part.description}</td>
                  <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                  <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
                </tr>
              );
            })}
            {!contents.temp && (
              <>
                <tr className="text-sm border-t bg-slate-50">
                  <td></td>
                  <td></td>
                  <td className="px-2 font-medium">Parts Cost:</td>
                  <td></td>
                  <td className="px-2 text-right">{formatToFixed(contents.partsCost)}</td>
                </tr>
                <tr className="text-sm bg-slate-50">
                  <td></td>
                  <td></td>
                  <td className="px-2 font-medium">Labor Cost:</td>
                  <td></td>
                  <td className="px-2 text-right">{formatToFixed(contents.laborCost)}</td>
                </tr>
              </>
            )}
            <tr className="text-sm border-t bg-slate-100">
              <td></td>
              <td></td>
              <td className="px-2 font-bold">Item Subtotal:</td>
              <td></td>
              <td className="px-2 text-right font-bold">{formatUSD(contents.subTotal)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

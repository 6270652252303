import { useState, useEffect } from 'react';
import { BriefcaseIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import UserIcon from 'components/UserIcon/UserIcon';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GetMroEmployeeDocument } from 'graphql/generated';
import { formatApiDateTime } from 'utils/formatter';
import { isActive, isUpcoming, webFormat } from 'utils/statuses';
import StatusButton from 'components/StatusButton/StatusButton';
import Tabs from 'components/Tabs/Tabs';
import { Formik, Form } from 'formik';

const OrderCard = ({ order, idx, active }) => (
  <Link
    to={`/mro/work-orders/${order.id}`}
    className={`${idx > 0 && 'mt-2'} ${
      active ? 'hover:shadow-sm' : 'opacity-70 hover:opacity-100'
    } group cursor-pointer transition-all mb-1 last:mb-0`}
    key={order.id}>
    <div
      className={`${
        active ? 'bg-brand-offwhite border-brand-pale' : 'bg-slate-50 border-slate-200'
      } flex items-center gap-2 border rounded-t transition-all bg-white group-hover:bg-brand-offwhite p-2`}>
      <BriefcaseIcon
        className={`${active ? 'bg-brand-pale text-brand-electric' : 'bg-slate-200 text-slate-400'} h-8 w-8 rounded p-[0.375rem]`}
      />
      <div className="grow">
        <div className="flex items-center justify-between">
          <span className="text-sm text-brand-dark text-left font-bold">{order.title}</span>
          <ChevronRightIcon className="w-3 h-3 text-slate-500" />
        </div>
      </div>
    </div>
    <div
      className={`${
        active ? 'border-brand-pale group-hover:bg-brand-offwhite' : 'bg-slate-100'
      } flex items-center justify-between bg-white border border-t-0 rounded-b p-2 transition-all`}>
      <h3 className="font-bold text-brand-dark uppercase text-xs">{webFormat('AWAITING_SIGNOFF')}</h3>
      <span className={`${active ? 'bg-brand-pale text-brand-electric' : 'bg-slate-200 text-slate-400'} text-xs font-bold rounded px-1`}>
        {order.mroCraft?.tailNumber}
      </span>
    </div>
  </Link>
);

const EmployeeModal = function ({ employeeId, setModal }) {
  // const { employeeId } = useParams();
  const [currentTab, setCurrentTab] = useState('Activity');
  const tabs = ['Activity', 'All Assignments', 'Settings'];
                
  const [recentLaborEntries, setRecentLaborEntries] = useState([]);
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: employeeId },
  });

  useEffect(() => {
    if (mroEmployeeProfile) {
      let mutable = Array.from(mroEmployeeProfile?.mroLaborEntries);
      mutable.sort((a, b) => Date.parse(b.startTime) - Date.parse(a.startTime));
      setRecentLaborEntries(mutable);
    }
  }, [mroEmployeeProfile]);

  const workOrders = mroEmployeeProfile?.mroWorkOrders;
  const activeOrders = workOrders?.filter((order) => isActive(order.status));
  const upcomingOrders = workOrders?.filter((order) => isUpcoming(order.status));
  const settings = {
    costRate: mroEmployeeProfile?.costRate,
    billableRate: mroEmployeeProfile?.billableRate,
    title: mroEmployeeProfile?.title,
    phone: mroEmployeeProfile?.phone,
    email: mroEmployeeProfile?.email
  }
  return (
    <>
      <div className="w-full">
        <div className="flex rounded-t items-center justify-between p-6">
          <div className="flex items-center gap-3">
            <UserIcon
              iconSize="xxl"
              iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconColor={mroEmployeeProfile?.profileColor}
              className=""
            />
            <div className="flex flex-col">
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">
                {mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              </h1>
              <div className="-mt-1 text-sm text-slate-500">
                <span className="text-brand-electric font-medium mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.title}</span>
                <span className="mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.phone}</span>
                <span>{mroEmployeeProfile?.email}</span>
              </div>
            </div>
          </div>
          <XMarkIcon
            onClick={() => {
              setModal({ active: false, employeeId: '' });
            }}
            className="h-5 w-5 text-slate-500 cursor-pointer hover:opacity-70 transition"
          />
        </div>
      </div>
      <div className="flex rounded-b border-slate-300 w-full">
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center border-y border-brand-pale px-4 pt-1 bg-brand-offwhite">
            <Tabs items={tabs} activeItem={currentTab} setActiveItem={setCurrentTab} />
          </div>
          <div className="flex flex-col bg-white border-brand-pale rounded-b-lg p-6 h-96 overflow-y-auto shadow-inner shadow-brand/10">
            {currentTab === 'Activity' && (
              <>
                <div>
                  <h2 className="font-semibold text-xs uppercase text-slate-500 pb-4">Current Labor Entry</h2>
                  <div className="rounded">
                    {mroEmployeeProfile?.latestActivity && (
                      <Link
                        to={'/mro/work-orders/' + mroEmployeeProfile?.latestActivity?.mroWorkOrderItem?.mroWorkOrder?.id}
                        className="block border border-brand-pale rounded hover-shadow-blue transition-all">
                        <div className="flex justify-between items-center bg-white rounded-t p-4">
                          <div className="flex items-center gap-2">
                            <BriefcaseIcon className="bg-brand-pale text-brand-electric h-8 w-8 rounded p-[0.375rem]" />
                            <span className="text-brand-dark font-bold truncate text-lg">
                              {mroEmployeeProfile?.latestActivity?.mroWorkOrderItem?.mroWorkOrder?.title}
                            </span>
                          </div>
                          <div className="flex items-stretch gap-2">
                            <span className="flex items-center font-bold text-brand bg-brand-offwhite border border-brand-pale px-2 rounded text-sm">
                              {mroEmployeeProfile?.latestActivity?.mroWorkOrderItem?.mroWorkOrder?.mroCraft?.tailNumber}
                            </span>
                            <div className="flex gap-4 items-center w-48">
                              <StatusButton
                                readOnly={true}
                                context="order"
                                text={mroEmployeeProfile?.latestActivity?.mroWorkOrderItem?.mroWorkOrder?.status}
                                className=""
                              />
                              <ChevronRightIcon className="w-4 h-4 text-slate-500" />
                            </div>
                          </div>
                        </div>
                        <div className="bg-brand-offwhite shadow-inner p-3 px-4 border-t border-brand-pale rounded-b">
                          <div className="flex rounded items-center justify-between p-4 px-7 w-full transition-all bg-white border-brand-pale border">
                            <div className="flex items-center gap-2 overflow-hidden">
                              <WrenchScrewdriverIcon
                                className={`${
                                  mroEmployeeProfile?.latestActivity?.endTime ? 'bg-slate-200' : 'bg-brand-electric text-white'
                                } h-6 w-6 p-1.5 rounded shrink-0`}
                              />
                              <span className="font-semibold truncate text-sm">
                                {mroEmployeeProfile?.latestActivity?.mroWorkOrderItem?.title}
                              </span>
                            </div>
                            <div className="flex items-stretch gap-2">
                              <span className="flex items-center font-bold text-brand bg-brand-offwhite border border-brand-pale px-2 rounded text-sm">
                                {formatApiDateTime(mroEmployeeProfile?.latestActivity?.startTime)}
                              </span>
                              <div className="w-40 mr-0.5">
                                {mroEmployeeProfile?.latestActivity?.endTime ? (
                                  <StatusButton readOnly={true} context="task" text="Finished" className="bg-slate-200" />
                                ) : (
                                  <StatusButton readOnly={true} context="task" text="Active" className="bg-brand-electric text-white" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="mt-6 pt-2 border-t border-brand-pale border-dashed">
                  <h2 className="font-semibold text-xs uppercase text-slate-500 py-4">Recent Labor Entries</h2>
                  <div className="border border-brand-pale rounded p-2 shadow-inner shadow-brand-offwhite">
                    {recentLaborEntries.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className="flex items-center justify-between p-3 px-4 w-full transition-all bg-white border-brand-pale border-b border-dashed last:border-b-0 cursor-pointer hover:bg-brand-offwhite">
                          <div className="flex items-center gap-2 overflow-hidden">
                            <WrenchScrewdriverIcon
                              className={`${item.endTime ? 'bg-slate-200' : 'bg-brand-electric text-white'} h-6 w-6 p-1.5 rounded shrink-0`}
                            />
                            <span className="font-semibold truncate text-sm">{item.mroWorkOrderItem?.title}</span>
                          </div>
                          <div className="flex items-stretch gap-2">
                            <span className="flex items-center font-bold text-brand bg-brand-offwhite border border-brand-pale px-2 rounded text-sm">
                              {formatApiDateTime(item.endTime || item.startTime)}
                            </span>
                            <div className="w-40 mr-0.5">
                              {item.endTime ? (
                                <StatusButton readOnly={true} context="task" text="Finished" className="bg-slate-200" />
                              ) : (
                                <StatusButton readOnly={true} context="task" text="Active" className="bg-brand-electric text-white" />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {currentTab === 'All Assignments' && (
              <>
                <h2 className="font-semibold text-xs uppercase text-slate-500 my-2">Assigned Work Orders</h2>
                <div>
                  {workOrders.map((order, index) => {
                    return (
                      <Link
                        key={index}
                        to={'/mro/work-orders/' + order.id}
                        className="block border border-brand-pale rounded hover-shadow-blue transition-all mt-1">
                        <div className="flex justify-between items-center bg-white rounded p-4">
                          <div className="flex items-center gap-2">
                            <BriefcaseIcon className="bg-brand-pale text-brand-electric h-8 w-8 rounded p-[0.375rem]" />
                            <span className="text-brand-dark font-bold truncate text-lg">{order.title}</span>
                          </div>
                          <div className="flex items-stretch gap-2">
                            <span className="flex items-center font-bold text-brand bg-brand-offwhite border border-brand-pale px-2 rounded text-sm">
                              {order.mroCraft?.tailNumber}
                            </span>
                            <div className="flex gap-4 items-center w-48">
                              <StatusButton readOnly={true} context="order" text={order.status} className="" />
                              <ChevronRightIcon className="w-4 h-4 text-slate-500" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </>
            )}
            {currentTab === 'Settings' && (
              <>
                <Formik
                initialValues={settings}
                onSubmit={() => {}}
                >
                  <Form>
                    <div>

                    </div>
                  </Form>
                </Formik>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeModal;

/* eslint-disable react/jsx-key */
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { useTable, useSortBy, Column, useGlobalFilter, useExpanded } from 'react-table';
import { formatToFixed } from 'utils/formatter';
import UserIcon from 'components/UserIcon/UserIcon';
import StatusButton from 'components/StatusButton/StatusButton';
import NewItemFlyout from 'views/Part145/WorkOrders/Order/WorkOrderItems/NewItemFlyout';
import WorkOrderItem from 'views/Part145/WorkOrders/Order/WorkOrderItems/WorkOrderItem';
import { useSession } from 'contexts';
import { CogIcon } from '@heroicons/react/24/solid';
import PartDetailFlyout from 'views/Part145/WorkOrders/Order/WorkOrderItems/PartDetailFlyout';
import NewPart from 'views/Part145/Parts/New';
import NewPartFlyout from 'views/Part145/WorkOrders/Order/WorkOrderItems/NewPartFlyout';

// HEAD
const Thead = forwardRef<HTMLTableSectionElement, ComponentPropsWithRef<'thead'>>(function Thead({ className, children, ...props }, ref) {
  return (
    <thead ref={ref} className={className} {...props}>
      {children}
    </thead>
  );
});
const Th = forwardRef<HTMLTableCellElement, ComponentPropsWithRef<'th'>>(function Th({ className, children, ...props }, ref) {
  return (
    <th ref={ref} className={className + ' flex items-center p-4 text-brand-dark truncate'} {...props}>
      {children}
    </th>
  );
});
// BODY
const Tbody = forwardRef<HTMLTableSectionElement, ComponentPropsWithRef<'tbody'>>(function Tbody(props, ref) {
  return <tbody ref={ref} {...props}></tbody>;
});
const Tr = forwardRef<HTMLTableRowElement, { index?: number } & ComponentPropsWithRef<'tr'>>(function Tr(
  { index = 0, className, children, ...props },
  ref
) {
  return (
    <tr
      ref={ref}
      className={`flex items-stretch group relative z-[25] last:rounded-b no-hover hover:cursor-pointer bg-white hover:bg-slate-50 border-t border-slate-200 transition-all ${className}`}
      {...props}>
      {children}
    </tr>
  );
});
const Td = forwardRef<HTMLTableCellElement, { primary?: boolean } & ComponentPropsWithRef<'td'>>(function Td(
  { children, primary = false, className, ...props },
  ref
) {
  return (
    <td ref={ref} className={`flex items-stretch p-4 py-2 truncate ${className}`} {...props}>
      {children}
    </td>
  );
});
// UTILITY

export const Table = forwardRef<
  HTMLTableElement,
  {
    columns?: Column<object>[];
    data?: any[];
    onRowClick?: Function;
    closeFlyout?: any;
    workOrderId?: any;
    context?: string;
    updateFlyout: Function;
    updateMroWorkOrderItem: Function;
  } & React.ComponentPropsWithRef<'table'>
>(function Table(
  { columns = [], data = [], children, onRowClick, updateFlyout, updateMroWorkOrderItem, workOrderId, context, closeFlyout, ...props },
  ref
) {
  const { setSelectedTask } = useSession();
  const tableInstance = useTable({ columns, data, autoResetExpanded: false }, useGlobalFilter, useSortBy, useExpanded);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const { selectedTask } = useSession();
  const subColumns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'itemNumber',
        className: 'ml-3 w-4',
        Cell: ({ value, row }) => {
          return <span className="flex items-center font-bold">.{value}</span>;
        },
      },
      {
        Header: 'Squawks',
        accessor: 'title', // accessor is the "key" in the data
        className: 'ml-1 text-xs text-slate-500 w-6/12 rounded-l',
        Cell: ({ value, row }: any) => {
          return (
            <button
              className="w-full grow text-left font-semibold truncate -mx-4 pl-2 pr-4 -my-3 py-3 "
              title={value}
              onClick={() => {
                updateFlyout({
                  title: row.original.title,
                  content: <WorkOrderItem context={context} closeFlyout={closeFlyout} itemId={row.original.id} />,
                });
              }}>
              <span className="">{value}</span>
            </button>
          );
        },
      },
      {
        Header: 'Assignee(s)',
        accessor: 'assignees',
        className: 'w-1/12 pl-6',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <div className="flex -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                if (!item) return null;
                return (
                  <UserIcon
                    key={index}
                    iconSize="xs"
                    iconText={item.firstName + ' ' + item.lastName}
                    iconId={item.firstName + ' ' + item.lastName}
                    iconColor={item.profileColor}
                    className="border-2 border-slate-50"
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Hours',
        accessor: 'totalLaborHours',
        className: 'w-1/12 text-xs text-slate-500 pl-6',
        Cell: ({ value, row }: any) => {
          return (
            <button
              className="w-full grow text-left -mx-4 px-4 -my-3 py-3 font-semibold"
              onClick={() =>
                updateFlyout({
                  title: row.original.title,
                  content: <WorkOrderItem context={context} closeFlyout={closeFlyout} itemId={row.original.id} />,
                })
              }>
              {formatToFixed(value)}
              <span className="text-xs text-slate-500 ml-0.5 font-medium">/{row.original.estimatedHours}</span>
            </button>
          );
        },
      },
      {
        Header: 'Actual Charge',
        accessor: 'totalCost',
        className: 'w-1/12 grow pl-6',
        Cell: ({ row, value }) => {
          return (
            <button
              className="w-full grow text-left -mx-4 px-4 -my-3 py-3 font-semibold"
              onClick={() => {
                updateFlyout({
                  title: row.original.title,
                  content: <WorkOrderItem context={context} closeFlyout={closeFlyout} itemId={row.original.id} />,
                });
              }}>
              {formatToFixed(value)}
            </button>
          );
        },
      },
      // {
      //   Header: '',
      //   accessor: 'comments',
      //   className: 'w-20',
      //   Cell: ({ value, row }: any) => {
      //     if (!Array.isArray(value)) {
      //       value = [value];
      //     }
      //     if (value.length > 0) {
      //       return (
      //         // <div onClick={() => updateFlyout({title: row.values.col1, content: <CustomerChat />})} className="flex text-slate-500">
      //         <div className="flex text-slate-500">
      //           <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-1" />
      //           <span className="font-bold">{value.length}</span>
      //         </div>
      //       );
      //     } else {
      //       return <></>;
      //     }
      //   },
      // },
      ...(context !== 'dashboard'
        ? [
            {
              Header: 'Status',
              accessor: 'status',
              className: 'shrink-0 w-48 pr-2 pl-6',
              Cell: ({ value, row }: any) => {
                return (
                  <StatusButton
                    readOnly={false}
                    context="task"
                    text={value}
                    className=""
                    onSelect={(status) =>
                      updateMroWorkOrderItem({
                        variables: {
                          input: {
                            id: row.original.id,
                            status,
                          },
                        },
                      })
                    }
                  />
                );
              },
            },
          ]
        : [
            {
              Header: '',
              accessor: '',
              id: 'select',
              className: 'w-2/12',
              Cell: ({ row, value }) => {
                return (
                  <div className="w-full text-left -mx-4 px-4 -my-2 py-2 font-semibold">
                    {selectedTask?.id !== row.original.id && (
                      <button
                        onClick={() => {
                          setSelectedTask(row.original);
                          closeFlyout();
                        }}
                        className="bg-brand-pale px-2 rounded-sm">
                        Select
                      </button>
                    )}
                  </div>
                );
              },
            },
          ]),
    ],
    [updateFlyout, updateMroWorkOrderItem]
  );
  
  const subPartColumns = React.useMemo(
    () => [
      {
        Header: 'partIcon',
        accessor: 'workOrderItemId',
        className: '',
        Cell: ({ value, row }) => {
          return <CogIcon className="h-5 w-5" />;
        },
      },
      {
        Header:'Part #',
        id: 'partNumber',
        accessor: (row) => row?.partNumber ?? '', // accessor is the "key" in the data
        className: `w-3/12  text-xs text-slate-500 border-dashed border-brand-pale justify-start`,
        Cell: ({ value, row }: any) => <button 
          className="text-md font-bold w-full grow"
          onClick={()=> 
            updateFlyout({
              title: "Part #"+(row?.original?.partNumber ?? ''),
              content: <PartDetailFlyout setIsOpen={(shouldClose)=> shouldClose && closeFlyout() } 
                        workOrderItemId={row.original.workOrderItemId} context={context} partId={row.original.id} />,
            })
          }>
          {value}
          </button>,
      },
      {
        Header: 'Description',
        id: 'partDescription',
        accessor: (row) => {
          return {
            mroPart: row?.mroPart,
            partDescription: row.partDescription ?? '',
            id: row.id,
          };
        }, // accessor is the "key" in the data
        className: `w-5/12 text-xs text-slate-500 border-dashed border-brand-pale justify-start`,
        Cell: ({ value, row }: any) => <button className="text-md font-bold w-full grow" 
            onClick={()=> 
              updateFlyout({
                title: "Part #"+(row?.original?.partNumber ?? ''),
                content: <PartDetailFlyout setIsOpen={(shouldClose)=> shouldClose && closeFlyout() } 
                workOrderItemId={row.original.workOrderItemId} context={context} partId={row.original.id} />,
              })
            }>
              {value?.mroPart?.description ?? value?.partDescription ?? ''}
          </button>,
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        accessor: (row) => row.quantity ?? '', // accessor is the "key" in the data
        className: 'w-2/12 text-xs text-slate-500 border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => {
          value === '' && (value = '-');
          return <button className="font-bold w-full grow"
                  onClick={()=> 
                    updateFlyout({
                      title: "Part #"+(row?.original?.partNumber ?? ''),
                      content: <PartDetailFlyout setIsOpen={(shouldClose)=> shouldClose && closeFlyout() } 
                                workOrderItemId={row.original.workOrderItemId} context={context} partId={row.original.id} />,
                    })
                  }>
                    {value}
                  </button>;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: (row) => row.status ?? '', // accessor is the "key" in the data
        className: 'w-[200px] text-xs text-slate-500 border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => <StatusButton
          readOnly={true}
          context="parts"
          text={value}
          className=""
          />,
      },
    ],
    [updateFlyout, updateMroWorkOrderItem]
  );

  return (
    <div className="w-full overflow-x-auto text-sm relative">
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <table ref={ref} className="min-w-max flex flex-col" {...getTableProps()} {...props}>
        <Thead className="uppercase">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} className="">
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps([{ className: column.className ?? ' ' }, column.getSortByToggleProps()])}>
                  {column.render('Header')}
                  <span className="">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronUpIcon className="ml-2 h-4 w-4" />
                      ) : (
                        <ChevronDownIcon className="ml-2 w-4 h-4" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const isSelected = context !== 'page' && (row.original as any).id === selectedTask?.id;
            const rowData = (row.original as any).mroWorkOrderParts.map(part => {
              return {
                ...part,
                workOrderItemId: (row.original as any).id,
              };
            });
            return (
              <React.Fragment key={row.getRowProps().key}>
                <Tr
                  {...row.getRowProps()}
                  className={`${row.isExpanded && 'border-slate-30 border-b'} ${isSelected ? 'bg-brand/[.3]' : 'bg-slate-50'}`}
                  index={index}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps([{ className: cell.column.className ?? ' ' }])}
                        onClick={() => {
                          if (!['status', 'expander', 'select'].includes(cell.column.id)) {
                            updateFlyout({
                              title: (row.original as any).title,
                              content: <WorkOrderItem context={context} closeFlyout={closeFlyout} itemId={(row.original as any).id} />,
                            });
                          }
                        }}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
                {row.isExpanded ? (
                  <>
                    <tr className={`pl-5 flex pr-1 -mt-[4px] pt-[3px]`}>
                      <td className="w-full flex flex-col">
                        {(row.original as any).mroWorkOrderParts.length > 0 && (
                          <SubTable columns={subPartColumns} data={rowData} />
                        )}
                        {(row.original as any).children.length > 0 && (
                          <SubTable columns={subColumns} data={(row.original as any).children} />
                        )}
                        <div className='flex'>
                          <div
                            className="flex add-subtask items-center relative pl-2 gap-2 my-2 cursor-pointer group"
                            onClick={(e) => {
                              updateFlyout({
                                title: 'Add Part',
                                content: (
                                  <NewPartFlyout workOrderItemId={(row.original as any).id} workOrderId={workOrderId} setIsOpen={(isOpen) => !isOpen && closeFlyout() } />
                                ),
                              });
                            }}>
                            <button className="h-3.5 w-3.5 flex items-center justify-center bg-brand-electric text-white rounded-full font-bold group-hover:bg-brand transition relative z-30">
                              +
                            </button>
                            <span className="font-bold text-brand-electric text-xs underline group-hover:text-brand transition">
                              Add A Part
                            </span>
                          </div>
                          <div
                            className="flex items-center relative pl-2 gap-2 my-2 cursor-pointer group"
                            onClick={(e) => {
                              updateFlyout({
                                title: 'Create Squawk - ' + (row.original as any).title,
                                content: (
                                  <NewItemFlyout closeFlyout={closeFlyout} workOrderId={workOrderId} parentId={(row.original as any).id} />
                                ),
                              });
                            }}>
                            <span className="font-bold text-brand-electric text-xs underline group-hover:text-brand transition">
                              Add A Subtask
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : null}
              </React.Fragment>
            );
          })}
        </Tbody>
      </table>
    </div>
  );
});

const TdSub = forwardRef<HTMLTableCellElement, { primary?: boolean } & ComponentPropsWithRef<'td'>>(function TdSub(
  { children, primary = false, className, ...props },
  ref
) {
  return (
    <td ref={ref} className={className + ' flex items-center p-4 py-0 group-hover:bg-slate-50'} {...props}>
      {children}
    </td>
  );
});
const TrSub = forwardRef<HTMLTableRowElement, { index?: number } & ComponentPropsWithRef<'tr'>>(function TrSub(
  { index = 0, className, children, ...props },
  ref
) {
  return (
    <tr ref={ref} className={className + ' flex hover:cursor-pointer group transition-all'} {...props}>
      {children}
    </tr>
  );
});

export const SubTable = React.memo(
  forwardRef<
    HTMLTableElement,
    {
      context?: string;
      columns?: Column<object>[];
      data?: any[];
      onRowClick?: Function;
    } & React.ComponentPropsWithRef<'table'>
  >(function SubTable({ columns = [], data = [], children, onRowClick, context, ...props }, ref) {
    const tableInstance = useTable({ columns, data, autoResetExpanded: false }, useGlobalFilter, useSortBy);
    const { rows, prepareRow } = tableInstance;

    return (
      <table className="w-full overflow-x-auto text-sm relative">
        <tbody>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <TrSub
                {...row.getRowProps()}
                className="subtask align-subtask z-[1] bg-white pr-1 transition w-full relative border-slate-200 group rounded pt-2"
                index={index}
                onClick={() => onRowClick && onRowClick(row.original)}>
                {row.cells.map((cell) => {
                  return <TdSub {...cell.getCellProps([{ className: cell.column.className ?? ' ' }])}>{cell.render('Cell')}</TdSub>;
                })}
              </TrSub>
            );
          })}
        </tbody>
      </table>
    );
  })
);

import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  StartTimerDocument,
  StopTimerDocument,
  GetMroDashboardDocument,
  GetMroWorkOrderItemDocument,
  PauseTimerDocument,
  ResumeTimerDocument,
  GetDashboardBadgesDocument,
} from 'graphql/generated';
import { StopIcon, PlayIcon } from '@heroicons/react/24/solid';
import { StopwatchIcon } from 'assets/icons';
import { useSession } from 'contexts';
import { Link } from 'react-router-dom';
import PinEntryModal from 'components/PinEntryModal/PinEntryModal';
import WorkOrderItem from 'views/Part145/WorkOrders/Order/WorkOrderItems/WorkOrderItem';
import StatusButton from 'components/StatusButton/StatusButton';

export const Clock = function ({ context, updateFlyout, closeFlyout, setDashboardTimerActive = (status) => {} }) {
  const [openSelection, setOpenSelection] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [timer, setTimer] = useState(false);
  const [startTimer] = useMutation(StartTimerDocument, { refetchQueries: [GetMroDashboardDocument, GetMroWorkOrderItemDocument, GetDashboardBadgesDocument] });
  const [startTime, setStartTime] = useState<Date>();
  const [pauseTime, setPausedTime] = useState<Date>();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [entryId, setEntryId] = useState<string>();
  const { user, setTimerActive, timerActive } = useSession();
  const employeeId = user?.mroEmployeeProfileId;
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroDashboardDocument, {
    variables: { mroEmployeeId: employeeId },
  });
  const [stopTimer] = useMutation(StopTimerDocument, {
    refetchQueries: [
      GetMroDashboardDocument,
      { query: GetMroWorkOrderItemDocument, variables: { id: mroEmployeeProfile?.currentLaborEntry?.mroWorkOrderItem?.id } },
    ],
  });
  const [pauseTimer] = useMutation(PauseTimerDocument);
  const [resumeTimer] = useMutation(ResumeTimerDocument);
  const { selectedTask, setSelectedTask } = useSession();

  const getTime = useCallback(
    (manualStart?: Date) => {
      const time = Date.now() - (manualStart ?? startTime ?? new Date()).getTime();
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    },
    [startTime]
  );

  useEffect(() => {
    if (mroEmployeeProfile?.currentLaborEntry) {
      setSelectedTask(mroEmployeeProfile.currentLaborEntry.mroWorkOrderItem);
      setStartTime(new Date(mroEmployeeProfile.currentLaborEntry.startTime));
      setPausedTime(mroEmployeeProfile.currentLaborEntry.pauseTime);
      setTimer(true);
    } else {
      setTimer(false);
    }
  }, [mroEmployeeProfile, setSelectedTask]);

  // useEffect(() => {
  //   if (mroWorkOrderItems && selectedTask) {
  //     setSelectedTask(mroWorkOrderItems.find((obj) => obj.id === selectedTask.id));
  //   }
  // }, [mroWorkOrderItems, selectedTask, setSelectedTask]);

  useEffect(() => {
    if (!timer) {
      setStartTime(new Date());
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  }, [selectedTask, timer, timerActive]);

  useEffect(() => {
    if (mroEmployeeProfile?.currentLaborEntry && !startTime) {
      setStartTime(new Date(mroEmployeeProfile.currentLaborEntry.startTime));
      setEntryId(mroEmployeeProfile.currentLaborEntry.id);
      setSelectedTask(mroEmployeeProfile.currentLaborEntry.mroWorkOrderItem);
      setTimer(true);
      getTime(new Date(mroEmployeeProfile.currentLaborEntry.startTime));
    }
  }, [mroEmployeeProfile, getTime, startTime, setSelectedTask]);

  useEffect(() => {
    if (startTime && timer && !pauseTime) {
      const interval = setInterval(() => getTime(), 1000);
      return () => clearInterval(interval);
    }
  }, [getTime, startTime, pauseTime, timer]);

  const handleStartTimer = async () => {
    if (!selectedTask) {
      return;
    }
    setTimer(true);
    setTimerActive(true);
    const { data } = await startTimer({ variables: { employeeProfileId: employeeId, workOrderItemId: selectedTask.id, mroOrganizationId: user.mroOrganizationId } });
    setStartTime(new Date(data.startTimer.startTime));
    setEntryId(data.startTimer.id);
  };

  const handleStopTimer = () => {
    setTimer(false);
    setTimerActive(false);
    stopTimer({
      variables: {
        laborEntryId: entryId ?? mroEmployeeProfile.currentLaborEntry.id,
      },
    }).then(() => {
      setDashboardTimerActive(false);
    });
  };
  //not used yet
  const handleResumeTimer = () => {
    const startToPauseDis = new Date(pauseTime).getTime() - startTime.getTime();
    setStartTime(new Date(new Date().getTime() - startToPauseDis));
    setPausedTime(null);
    resumeTimer({
      variables: {
        laborEntryId: entryId ?? mroEmployeeProfile.currentLaborEntry.id,
      },
    });
  };

  //not used yet
  const handlePauseTimer = () => {
    setPausedTime(new Date());
    pauseTimer({
      variables: {
        laborEntryId: entryId ?? mroEmployeeProfile.currentLaborEntry.id,
      },
    });
  };
  if (context === 'dashboard') {
    return (
      <div className={`${openSelection && 'relative z-[69]'} flex flex-col shadow-blue h-full`}>
        {/* PIN MODAL */}
        {pinModal && (
          <PinEntryModal
            cancel={setPinModal}
            completed={() => handleStartTimer()}
            message={'Start labor entry: ' + mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
            pin={mroEmployeeProfile?.pin ?? 1111}
          />
        )}
        {openSelection && (
          <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0">
            <div
              onClick={() => {
                setOpenSelection(false);
              }}
              className="bg-slate-100/20 backdrop-blur-[1px] w-full h-full absolute top-0 left-0 transition-all"></div>
          </div>
        )}
        {/* CLOCK */}
        <div className="flex flex-col h-full">
          <div
            className={`${
              selectedTask ? 'rounded-tl' : 'rounded-t hidden'
            } flex justify-center xl:justify-between items-center py-2 px-5 gap-4 border border-slate-300 bg-white`}>
            <div className="flex justify-between items-center gap-4">
              <div
                className="flex items-center gap-4 cursor-pointer hover:opacity-70"
                onClick={() =>
                  updateFlyout({
                    title: selectedTask.title,
                    content: <WorkOrderItem context="dashboard" closeFlyout={closeFlyout} itemId={selectedTask.id} />,
                  })
                }>
                <StopwatchIcon className="h-16 w-16 text-brand-electric bg-brand-pale rounded-lg p-2" />
                <div className="flex flex-col min-w-[200px]">
                  {selectedTask?.id && (
                    <div>
                      <span className="text-sm text-brand font-bold">{selectedTask?.id && selectedTask?.title}</span>
                      <span className="text-sm text-brand-electric font-bold">
                        {' | ' + selectedTask?.mroWorkOrder?.mroCraft?.tailNumber}
                      </span>
                    </div>
                  )}
                  <h1 className="text-5xl font-medium text-brand-dark">
                    {hours.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:
                    {minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
                    <span className="text-slate-300">:{seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}</span>
                  </h1>
                  <div className="flex items-center justify-between border-t border-slate-200 border-dashed">
                    <span className="text-xs text-slate-500 uppercase whitespace-nowrap">Time Estimated</span>
                    <span className="font-bold text-brand pt-1 text-sm">{selectedTask ? selectedTask.estimatedHours + 'h' : '--'}</span>
                  </div>
                </div>
              </div>
              {timer ? (
                <div className="flex gap-2">
                  <StopIcon
                    onClick={handleStopTimer}
                    className="h-14 w-14 bg-white text-brand-dark border border-slate-300 rounded-full p-3 cursor-pointer shadow-blue-lg hover:shadow-inner transition"
                  />
                </div>
              ) : (
                <PlayIcon
                  onClick={() => setPinModal(true)}
                  className="h-14 w-14 bg-brand text-white rounded-full p-3 border border-slate-300 cursor-pointer hover:text-brand-electric transition shadow-blue-lg"
                />
              )}
            </div>

            <div className="hidden xl:flex gap-10">
              <div className="flex flex-col text-center gap-1">
                <span className="text-slate-500 uppercase whitespace-nowrap text-center font-semibold">Parts</span>
                <span>
                  <span className="font-bold">
                    {selectedTask?.mroWorkOrderParts?.filter((part) => part.status === 'INSTALLED').length ?? 0}
                  </span>
                  /{selectedTask?.mroWorkOrderParts?.filter((part) => part.status !== 'CANCELLED').length ?? 0}
                </span>
              </div>
              <div className="flex flex-col text-center gap-1">
                <span className="text-slate-500 uppercase whitespace-nowrap text-center font-semibold">Hours</span>
                <span>
                  <span className="font-bold">{selectedTask?.totalLaborHours ? Number(selectedTask?.totalLaborHours).toFixed(2) : 0}</span>/
                  {selectedTask?.estimatedHours}
                </span>
              </div>
              <div className="hidden 2xl:flex flex-col gap-1">
                <span className="font-semibold text-slate-500 uppercase whitespace-nowrap">Status:</span>
                <StatusButton readOnly={true} context="task" text={selectedTask?.status} className="" onSelect={(status) => {}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Link to={timer ? '/mro/dashboard' : '#'}>
        <div
          className={`${
            timer ? 'cursor-pointer' : 'cursor-not-allowed'
          } flex items-center gap-2 ml-4 rounded p-2 h-full ease-in-out transition group`}>
          <StopwatchIcon
            className={`${
              timer
                ? 'bg-brand-pale text-brand-electric group-hover:bg-brand-electric group-hover:text-white'
                : 'bg-slate-100 text-slate-300'
            } h-10 w-10 rounded-lg p-1.5 transition`}
          />
          <div className="flex flex-col min-w-[154px]">
            <h1 className={`${timer ? 'text-brand-dark' : 'text-slate-300'} text-3xl font-medium`}>
              {hours.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:
              {minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              <span className="text-slate-300">:{seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}</span>
            </h1>
          </div>
        </div>
      </Link>
    );
  }
};

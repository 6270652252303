import { useQuery } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import { Table } from 'components/Table/Table';
import { useSession } from 'contexts';
import { GetFlightImportsDocument } from 'graphql/generated';
import { useMemo, useRef, useState } from 'react';
import { formatApiDate, formatApiDateTime, formatDateChange } from 'utils/formatter';

export default function BulkImport({ cancel, completed }) {
  const uploadButton = useRef(null);
  const [file, setFile] = useState(null);
  const { organizationId } = useSession();
  const { data: { imports } = {}, refetch } = useQuery(GetFlightImportsDocument, { variables: { where: { organizationId: { equals: organizationId } } } });
  const fileChanged = async (event) => {
    if(event.target.files.length === 0) return;
    setFile(event.target.files[0]);
  };
  const importFlight = async () => {
    const formData = new FormData();
    uploadButton.current.value = '';
    formData.append('file', file); 
    formData.append('organizationId', organizationId );
    const res = await fetch(process.env.REACT_APP_API_URL + '/flights/import', { method: 'POST', body: formData });
    if(res.ok) {
      refetch();
      completed();
      cancel(false);
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left 2/12 w-full',
        accessor: 'createdAt',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(formatDateChange(value))}</span>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        className: 'text-center w-full 6/12 truncate',
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'text-left w-full 3/12',
      },
      {
        Header: '',
        accessor: 'attachments',
        className: 'text-left w-full 1/12',
        Cell: ({ value }: any) => {
          return (
            <a href={value?.[0]?.signedUrl}><ArrowDownIcon className="text-brand w-8 h-6 p-0.5 border border-brand rounded cursor-pointer hover:bg-brand-pale transition"/></a>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
      <div
        onClick={() => {
          cancel(false);
        }}
        className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0"></div>
      <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-2xl w-full">
        <XMarkIcon
          onClick={() => {
            cancel(false);
          }}
          className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
        />
        <div className="flex flex-col gap-3 items-center p-8">
          <h2 className='text-2xl font-bold'>Bulk Import</h2>
          <hr className="border-slate-300 w-full" />
          <h3 className='text-center'>To bulk import flights, match<a className='text-brand font-bold' download='flight_bulk_import' href={require("../../../../assets/files/flight_bulk_import.csv")}> the bulk import template</a> and upload</h3>
          { !file ? <Button text='Upload' size='sm' className='py-3' onClick={() => uploadButton.current.click()} /> : <h3 className='text-lg font-bold'>{file.name}</h3> }
          <input
            type="file"
            ref={uploadButton}
            onChange={fileChanged}
            style={{ display: 'none' }}
            accept=".csv" />
          { imports && imports.length > 0 && <div className='mt-5 w-full'>
            <h3 className='text-lg font-bold mb-2'>Previous Imports</h3>
            <div className='max-h-48 overflow-y-auto'>
              <Table
                className='w-full'
                columns={columns}
                data={[...imports ?? []].sort((a, b) => b.position - a.position)}
                onRowClick={(row: any) => {}}
              />
            </div>
          </div> }
        </div>
        <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-t border-slate-300 bg-slate-50">
          {/* <Button color="white" size="xs" text="Cancel" onClick={()=>{ cancel(false) }}/> */}
          <span
            className="text-sm cursor-pointer font-medium hover:opacity-70 transition"
            onClick={() => {
              cancel(false);
            }}>
            Cancel
          </span>
          { file && <Button
            color="navy"
            size="xs"
            text="Upload"
            onClick={async () => {
              await importFlight();
            }}
          />}
        </div>
      </div>
    </div>
  );
}

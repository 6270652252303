import { PrinterIcon } from '@heroicons/react/24/solid';
import { GetWorkOrderLogEntriesQuery } from 'graphql/generated';
import PrintLogEntriesRow from './partials/PrintLogEntriesRow';
import { useCallback, useState } from 'react';
import printJS from 'print-js';
import { DownloadIcon } from 'assets/icons';

export default function PrintLogEntryFlyout({ logEntries }: { logEntries: GetWorkOrderLogEntriesQuery['mroWorkOrder']['mroLogEntries'] }) {
  const [selectedEntries, setSelectedEntries] = useState<Set<String>>(new Set(logEntries.map((log) => log.id)));

  const onButtonClick = useCallback(
    (type) => {
      fetch(`${process.env.REACT_APP_API_URL}/mro-log-entries/pdf`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: Array.from(selectedEntries),
        }),
      }).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          if (type === 'print') {
            printJS(fileURL);
          } else {
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'log-entries.pdf';
            alink.click();
          }
        });
      });
    },
    [selectedEntries]
  );
  return (
    <div className={`flex flex-col gap-2 p-4`}>
      <div className="flex flex-col bg-white p-4 border border-slate-200 rounded">
        {logEntries
          .sort((a, b) => a?.itemCategory?.categoryNumber - b?.itemCategory?.categoryNumber)
          .map((log, index) => {
            return (
              <PrintLogEntriesRow
                title={log.title}
                key={log.id}
                numberOfItems={logEntries[index].mroLogEntryItems.length}
                checked={selectedEntries.has(log.id)}
                setChecked={(checked: boolean) => {
                  checked ? selectedEntries.add(log.id) : selectedEntries.delete(log.id);
                  setSelectedEntries(new Set(selectedEntries));
                }}
              />
            );
          })}
      </div>
      <div className="flex mt-2 self-end gap-3">
        <button
          onClick={() => onButtonClick('download')}
          className="flex shrink-0 items-center bg-brand-electric text-white border text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
          <DownloadIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
          Download
        </button>
        <button
          onClick={() => onButtonClick('print')}
          className="flex shrink-0 items-center bg-brand text-white border text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
          <PrinterIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
          Print
        </button>
      </div>
    </div>
  );
}

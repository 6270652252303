import { useQuery } from '@apollo/client';
import { BookmarkSquareIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon, PaperClipIcon, PencilIcon } from '@heroicons/react/24/solid';
import { CalendarDateIcon, EventConfirmIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { Table } from 'components/Table/Table';
import { GetLogbookDocument } from 'graphql/generated';
import { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatApiDate } from 'utils/formatter';
import New from '../Entries/New';
import Card from 'components/Card/Card';
import Edit from '../Edit';
import View from '../Entries/View';
import { Menu, Transition } from '@headlessui/react';
import isMobileApp from 'utils/isMobileApp';

const stripHtmlTags = (htmlString) => {
  // Create a temporary div element to hold the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  
  // Get the text content of the div, which will not include any HTML tags
  return tempDiv.textContent || tempDiv.innerText || "";
};

const Logbook = function () {
  const logBookId = useParams()?.logbookId;
  const { data : { logBook } = { } } = useQuery(GetLogbookDocument, { variables: { logBookId } });
  const logEntries =logBook?.logEntries;
  const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
  const columns = useMemo(() => {
    return [
      {
        Header: 'Title',
        accessor: 'title',
        className: 'w-2/6',
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: 'w-3/6 truncate px-2 max-w-lg max-h-10',
        Cell: ({ cell: { value } }) => (
          <>{stripHtmlTags(value)}</>
        ),
      },
      {
        Header: 'Date',
        accessor: 'logDate',
        className: 'w-1/6',
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center">
            <CalendarDateIcon className="h-3 w-3 mr-1" />
            <span>{formatApiDate(value)}</span>
          </div>
        ),
      },
    ];
  }, []);

  if (!logEntries) return null;

  return (
    <>
      <Flyout {...flyoutProps} />
      <div className={`flex flex-col w-full px-2 md:px-8`}>
        {/* Header */}
        <div className=''>
          <div className="flex-rows items-center bg-white border border-slate-300 rounded shadow-blue mb-4">
            <div className="flex justify-between items-center p-6">
              <div className="flex items-center">
                <EventConfirmIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
                <div>
                  <h1 className="font-bold text-brand-dark text-2xl">{logBook.name}</h1>
                  <h2 className="text-brand-dark">{logBook?.description}</h2>
                </div>
              </div>
              <div className="flex items-stretch gap-4">
                <Button text="New Entry" color="navy" size="xs" icon={<BookmarkSquareIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />}
                  onClick={ () => updateFlyout({ title: 'New Entry', content: <New id={logBookId} closeFlyout={closeFlyout} /> }) } />
                <Menu
                  as="div"
                  className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                  <Menu.Button className="">
                    <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                      <Menu.Item>
                        {({ active }) => (
                          <button onClick={async () => { updateFlyout({ title: 'Edit Logbook', content: <Edit id={logBookId} closeFlyout={closeFlyout} /> }) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Edit</button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          isMobileApp() ?
                            <button onClick={async () => { await navigator.share({ title:"Export Flights from Coflyt", url: process.env.REACT_APP_API_URL+"/logbooks/"+logBookId , text: "Export Logbook from Coflyt"}) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Export</button>
                          :
                          <a
                            href={process.env.REACT_APP_API_URL+"/logbooks/"+logBookId}
                            className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                            download>
                            Export
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <Table
            data={logEntries}
            columns={columns}
            className='w-full'
            onRowClick={(row) => {
              updateFlyout({ title: row.title, content: <View closeFlyout={closeFlyout} id={row.id} /> });
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default Logbook;

import { useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { Flyout, useFlyout, type FlyoutHookReturn } from 'components/Flyout/Flyout';
import { Table } from 'components/Table/Table';
import { GetMroWorkOrderBillingItemsDocument } from 'graphql/generated';
import { useMemo } from 'react';
import { Column } from 'react-table';
import NewBillableItemFlyout from './New';
import { formatUSD } from 'utils/formatter';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import BillableItemFlyout from './View';


export default function BillableItems({
  workOrderId,
}: {
  workOrderId: string;
}) {
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data : { mroWorkOrderBillingItems } = {} } = useQuery( GetMroWorkOrderBillingItemsDocument, { variables: { where: { mroWorkOrderId: { equals: workOrderId } } } } );
  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => row.name ?? '', // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value}</span>
            </>
          );
        },
      },
      {
        Header: 'Value',
        id: 'value',
        accessor: (row) => { return { 
          amount: row.value,
          isPercentage: row.isPercentage,
         }
        } , // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value.isPercentage ? value.amount+"%" : formatUSD(value.amount)}</span>
            </>
          );
        },
      },
      {
        Header: 'Apply To',
        id: 'billableType',
        accessor: (row) => row.billableType, // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value}</span>
            </>
          );
        },
      },
      {
        Header: 'Active',
        id: 'active',
        accessor: (row) => row.active, // accessor is the "key" in the data
        className: 'w-full text-xs text-slate-500',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="font-semibold">{value ? 'Yes' : 'No'}</span>
            </>
          );
        },
      },
    ], []
  );
  const addBillableItem = ( isDiscount ) => {
    updateFlyout({
      title: 'Add ' + (isDiscount ? 'Discount' : 'Charge'),
      content: <NewBillableItemFlyout closeFlyout={closeFlyout} 
                  isDiscount={isDiscount}
                  workOrderId={workOrderId} />,
    });
  };
  const viewBillableItem = ( id, isDiscount ) => {
    updateFlyout({
      title: 'View ' + (isDiscount ? 'Discount' : 'Charge'),
      content: <BillableItemFlyout closeFlyout={closeFlyout} 
                  billableItemId={id} />,
    });
  };
  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner">
        <div className="flex flex-col w-full bg-white">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <PlusIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">Charges</h1>
          </div>
          <Table
              data={mroWorkOrderBillingItems?.filter((item) => !item.isDiscount ) ?? []}
              columns={columns}
              className="w-full bg-white border rounded"
              onRowClick={(row) => {
                viewBillableItem(row.id, false);
              }}
            />
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
        </div>
        <div className="flex justify-end items-center">
          <Button text="Add Charge" color="navy" size="xs" onClick={()=> addBillableItem(false)} />
        </div>
        <div className="flex flex-col w-full bg-white">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <MinusIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">Discounts</h1>
          </div>
          <Table
              data={mroWorkOrderBillingItems?.filter((item) => item.isDiscount ) ?? []}
              columns={columns}
              className="w-full bg-white border rounded"
              onRowClick={(row) => {
                viewBillableItem(row.id, true);
              }}
            />
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
        </div>
        <div className="flex justify-end items-center">
          <Button text="Add Discount" color="navy" size="xs" onClick={()=> addBillableItem(true)} />
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full" />
    </>
  );
}

import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import PartLookup from '../../Components/PartLookup';
import { webFormat } from 'utils/statuses';
import { formatApiDateTrunc, formatDateChange, formatFieldDate } from 'utils/formatter';
import { GetMroPartOrderDocument, GetMroPartOrderQuery, UpdateMroPartOrderItemDocument } from 'graphql/generated';
import { useMutation } from '@apollo/client';
import Button from 'components/Button/Button';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import NewInventoryFlyout from '../../Part/Inventory/New';
import { ImportInventory } from '../Partials/ImportInventory';
import Modal from 'components/Modal/Modal';
import { AddSerializedParts } from '../Partials/AddSerializedParts';
import { PartConditions } from './PartConditions';

export default function ReceivePartOrderItem({
  item,
  partOrderId,
  closeFlyout,
  updateFlyout,
}: {
  item: GetMroPartOrderQuery['mroPartOrder']['mroPartOrderItems'][0];
  partOrderId: string;
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  updateFlyout: FlyoutHookReturn['updateFlyout'];
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [contents, setContents] = useState(item);

  const [updateMroPartOrderItem] = useMutation(UpdateMroPartOrderItemDocument, {
    refetchQueries: [{ query: GetMroPartOrderDocument, variables: { id: partOrderId } }],
  });

  const handleChange = function (e, kind?) {
    let mutable = { ...contents };
    if (kind === 'mroPart') {
      mutable[kind] = e;
    } else if (kind === 'quantity' || kind === 'unitPrice') {
      mutable[kind] = Number(e.target.value);
    } else if (kind === 'requestedDate' || kind === 'partPrice') {
      mutable[kind] = formatDateChange(e.target.value) as Date;
    } else if (kind) {
      mutable[kind] = e.target.value;
    }
    setContents(mutable);
  };

  const markAsReceived = () => {
    updateMroPartOrderItem({
      variables: {
        input: {
          id: contents.id,
          status: 'RECEIVED',
        },
      },
    });
    setContents({ ...contents, status: 'RECEIVED' });
    closeFlyout();
  };

  const markAsNotReceived = () => {
    updateMroPartOrderItem({
      variables: {
        input: {
          id: contents.id,
          status: 'NOT_RECEIVED',
        },
      },
    });
    setContents({ ...contents, status: 'NOT_RECEIVED' });
  };

  const handleSave = () => {
    setIsEditable(false);
    const mroPart = contents.mroPart ? { connect: { id: contents.mroPart.id } } : null;
    delete contents.mroPart;
    updateMroPartOrderItem({
      variables: {
        input: {
          id: contents.id,
          quantity: contents.quantity,
          unitPrice: contents.unitPrice,
          condition: contents.condition,
          notes: contents.notes,
          requestedDate: contents.requestedDate,
          ...(mroPart && { mroPart: mroPart }),
        },
      },
    });
  };
  return (
    <div key={contents.id}>
      {showAlert && (
        <Modal message={'Marking items as not received does remove item from Inventory'} cancel={setShowAlert} accept={markAsNotReceived} />
      )}
      <div
        className={`flex justify-between items-start w-full px-6 border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b transition-all py-3`}>
        <div className="flex flex-col w-full">
          {isEditable ? (
            <div className="text-slate-700">
              <div className="flex justify-between items-stretch gap-1">
                <div className="flex w-full flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Part #</label>
                  <PartLookup
                    hideLabel={true}
                    className="h-12 md:h-10"
                    selectedPart={contents.mroPart}
                    setSelectedPart={(part) => handleChange(part, 'mroPart')}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Condition</label>
                  <select
                    value={contents.condition}
                    onChange={(e) => handleChange(e, 'condition')}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-28 h-12 md:h-10 text-right strip-style self-end">
                    <PartConditions />
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">QTY.</label>
                  <input
                    type="number"
                    min={0}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-16 h-12 md:h-10 text-right strip-style self-end"
                    defaultValue={contents.quantity}
                    onChange={(e) => handleChange(e, 'quantity')}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Unit Price</label>
                  <input
                    type="number"
                    min={0}
                    className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-16 h-12 md:h-10 text-right strip-style self-end"
                    defaultValue={Number(contents.unitPrice).toFixed(2)}
                    onChange={(e) => handleChange(e, 'unitPrice')}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-xs text-slate-700 font-semibold">Notes:</label>
                <textarea
                  rows={4}
                  className="w-full text-xs px-2 leading-tight border p-2 rounded mt-1 border-slate-300 focus:shadow-inner focus:bg-white"
                  defaultValue={contents.notes}
                  onChange={(e) => handleChange(e, 'notes')}></textarea>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-xs text-slate-700 font-semibold self-end">Need By:</label>
                <input
                  type="date"
                  className="font-semibold border border-slate-300 px-1 text-sm rounded bg-white w-28 h-12 md:h-10 text-right strip-style self-end"
                  defaultValue={formatFieldDate(contents.requestedDate)}
                  onChange={(e) => handleChange(e, 'requestedDate')}
                />
              </div>
              <div className="flex gap-2 items-center justify-end mt-2 pb-20">
                <button
                  onClick={() => setIsEditable(false)}
                  className="flex items-center font-semibold text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                  Save Changes
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center h-6">
                <div className="flex px-2 flex-col">
                  <label className="text-xs text-slate-700 font-semibold">Part #</label>
                  <h2 className="text-sm relative">{contents?.mroPart?.partNumber ?? ''}</h2>
                </div>
                <div className="flex gap-5">
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">Condition</label>
                    <span className="font-medium px-1 rounded text-sm">{webFormat(contents?.condition) ?? 'NEW'}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">QTY.</label>
                    <span className="font-medium px-1 rounded text-sm">{contents?.quantity}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-slate-700 font-semibold">Unit Price</label>
                    <span className="font-medium px-1 rounded text-sm">${Number(contents?.unitPrice).toFixed(2)}</span>
                  </div>
                </div>
              </div>
              {contents.notes && (
                <div className="bg-white border-l-2 border-slate-200 border px-4 py-1 ml-2 my-2 rounded">
                  <span className="text-xs text-brand-dark font-semibold uppercase">Notes</span>
                  <p className="font-normal text-sm text-gray-700 -mt-0.5">{contents.notes}</p>
                </div>
              )}
              <div className="flex flex-col mt-2">
                <label className="text-xs text-slate-700 font-semibold self-end">Need By:</label>
                <span className="font-medium px-1 rounded text-sm self-end">{formatApiDateTrunc(contents.requestedDate)}</span>
              </div>
              {contents.status === 'NOT_RECEIVED' && (
                <div className="flex gap-2 items-center justify-end mt-2">
                  <Button onClick={markAsReceived} color="green" text="Mark as Received" size="xs" />
                  {contents?.mroPart?.isSerialized ? (
                    <>
                      <Button
                        onClick={() =>
                          updateFlyout({
                            title: 'Add Serialized Parts: ' + contents?.mroPart?.partNumber,
                            content: <AddSerializedParts importData={contents} closeFlyout={markAsReceived} />,
                          })
                        }
                        color="blue"
                        text="Add Serialized Parts"
                        size="xs"
                      />
                    </>
                  ) : (
                    <>
                      {contents?.mroPart?.mroPartInventories?.length > 0 && (
                        <Button
                          onClick={() =>
                            updateFlyout({
                              title: 'Add Part to Existing Inventory: ' + contents?.mroPart?.partNumber,
                              content: (
                                <ImportInventory importData={contents} closeFlyout={markAsReceived} partId={contents?.mroPart?.id} />
                              ),
                            })
                          }
                          color="navy"
                          text="Import to Existing Inventory"
                          size="xs"
                        />
                      )}

                      <Button
                        onClick={() =>
                          updateFlyout({
                            title: 'Add New Inventory: ' + contents?.mroPart?.partNumber,
                            content: (
                              <NewInventoryFlyout
                                closeFlyout={markAsReceived}
                                partId={contents?.mroPart?.id}
                                importData={{
                                  condition: contents.condition,
                                  quantity: contents.quantity,
                                  unitPrice: contents.unitPrice,
                                }}
                              />
                            ),
                          })
                        }
                        color="blue"
                        text="Add New Inventory"
                        size="xs"
                      />
                    </>
                  )}
                </div>
              )}
              {contents.status === 'RECEIVED' && (
                <div className="flex gap-2 items-center justify-end mt-2">
                  <Button onClick={() => setShowAlert(true)} color="pale" text="Mark as Not Received" size="xs" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 ml-3 -mr-2">
          <div className="flex flex-col gap-1 w-full">
            <div className="flex flex-col mt-[-6px]">
              <span className="text-xs text-slate-700 font-semibold">Status</span>
              <span
                className={`text-sm font-semibold rounded text-center text-white px-2 ${
                  contents?.status !== 'NOT_RECEIVED' ? 'bg-emerald-500' : 'bg-brand'
                }`}>
                {webFormat(contents?.status)}
              </span>
            </div>
            {isEditable ? (
              <PencilSquareIcon
                onClick={() => {
                  if (contents?.mroPart?.partNumber) {
                    setIsEditable(!isEditable);
                  }
                }}
                title="Edit"
                className="bg-brand-electric text-brand-pale w-12 h-6 p-0.5 border border-brand-pale rounded cursor-pointer transition hover:opacity-80"
              />
            ) : (
              <PencilSquareIcon
                onClick={() => {
                  setIsEditable(!isEditable);
                }}
                title="Edit"
                className="text-brand-electric bg-white hover:bg-brand-pale w-12 h-6 p-0.5 cursor-pointer border border-brand-pale rounded transition"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

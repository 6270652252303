import { useEffect, useRef, useState } from 'react';

//Example invocation
//<Tabs items={['New Check', 'Logs']} setActiveItem={setActiveItem} activeItem={activeItem} />

function Tabs({ items, setActiveItem, activeItem, className = '', ...props }) {
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);
  const activeItemValue = activeItem?.title ?? activeItem;
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[items.findIndex((item) =>{ 
        const itemValue = item?.title ?? item;
        return itemValue === activeItemValue;
      })];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeItem, items]);

  let tabItems = items || [];

  return (
    <div className={"flex overflow-x-auto w-full"}>
      <div className="relative">
        <div className="flex space-x-3 border-b mr-4">
          {tabItems.map((item, index) => {
            const value = item?.title ?? item;
            return (
              <button
                key={index}
                ref={(el) => (tabsRef.current[index] = el)}
                className={`text-sm p-2 md:px-4 mr-1 font-medium disabled:font-bold disabled:text-brand-electric`}
                onClick={() => {
                  setActiveItem(value);
                }}
                disabled={activeItem === value}>
                <span>{value}</span>
                { !!item?.count && <span className='ml-2 bg-brand-pale p-1 rounded'>{item?.count}</span>}
              </button>
            );
          })}
        </div>
        <span
          className="absolute bottom-0 block h-1 bg-brand-electric transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
    </div>
  );
}

export default Tabs;

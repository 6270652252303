import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext } from 'react';
import Button from 'components/Button/Button';
import { ServiceFormContext } from '..';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { GetServiceUserDocument, OnboardOrgDocument, Role, SignupForServiceDocument } from 'graphql/generated';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import * as Yup from 'yup';
import { useSession } from 'contexts';

const signupValues = { email: '', password: '', confirm: '' };

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters long'),
});



const SignupForm = (props) => {
  const { setLoginSession } = useSession();
  const { formStep, changeStep, setUser, setOrganization, tailInfo, craftInput, setCraftId } = useContext(ServiceFormContext);
  const [signup] = useMutation(SignupForServiceDocument);
  const [onboardOrg] = useMutation(OnboardOrgDocument);
  const { toastProps, showToast } = useToast();
  const [ getUser ] = useLazyQuery(GetServiceUserDocument);
  return (
    <div className='relative'>
      <Toast {...toastProps} />
      <Formik
        validationSchema={SignUpSchema}
        initialValues={signupValues}
        onSubmit={async (values) => {
          try{
            const { data } = await signup({
              variables: {
                ...values,
              },
            });
            const { data: orgData } = await onboardOrg({
              variables: {
                craft: craftInput,
                organization: {
                  name: tailInfo?.name,
                  userOrganizationProfiles: {
                    create: [
                      {
                        user: {
                          connect: {
                            id: data.signup.user.id,
                          },
                        },
                        role: Role.Admin, // Make the user an admin for new organization 
                      }
                    ]
                  },
                },
              },
            });
            await setLoginSession( data.signup.accessToken, data.signup.refreshToken);
            setCraftId(orgData.onboardOrganization.crafts[0].id);
            setOrganization(orgData.onboardOrganization);
            const onboardingUser = await getUser();
            setUser(onboardingUser.data.me);
            changeStep(formStep, 'contactInfo');
          } catch(error) {
            if(error instanceof ApolloError) {
              showToast({
                title: 'Error',
                subtitle: error.message,
                type: ToastLength.Normal
              })
            }
          }
          
        }}>
        {({ values, isSubmitting }) => (
          <Form className="w-96">
            <div className="flex flex-col mb-4">
              <span className="text-3xl md:text-2xl font-bold text-brand">Create Your Account</span>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Email</label>
              <Field name="email" type="email" className={props.fieldCss}></Field>
              <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Password</label>
              <Field name="password" type="password" className={props.fieldCss}></Field>
              <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-brand">Confirm Password</label>
              <Field
                name="confirm"
                type="password"
                className={props.fieldCss}
                validate={(value) => (value === values.password ? '' : "Passwords Don't Match")}></Field>
              <ErrorMessage name="confirm" component="div" className="text-red-500 text-xs italic" />
            </div>
            <div className="flex justify-between items-center">
              <button className="underline text-brand font-semibold text-sm" type="button" onClick={() => changeStep(formStep, false, true)}>
                Go Back
              </button>
              <div className='flex gap-3'>
                {/* Hidding for now but we can skip the signup flow if needed */}
                {/* <Button text="Skip" color="clear" size="sm" className='disabled:opacity-50' disabled={isSubmitting} onClick={ () => changeStep(formStep, 'contactInfo') } /> */}
                <Button text="Next" color="navy" size="xs" className='disabled:opacity-50' disabled={isSubmitting} type="submit" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;

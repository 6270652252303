import { useState, useEffect, useCallback, ChangeEventHandler } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button/Button';
import {
  GetMroPartOrderDocument,
  GetMroPartOrdersDocument,
  GetMroPartRequestsDocument,
  PartCondition,
  UpdateMroPartOrderAndChangeWoPartStatusDocument,
  UpdateMroPartOrderDocument,
} from 'graphql/generated';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import { formatApiDateTrunc, formatDateChange, formatFieldDate, formatUSD } from 'utils/formatter';
import { useMutation, useQuery } from '@apollo/client';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { randomID } from 'utils/random';
import VendorCard from '../Partials/VendorCard';
import PartOrderItems from '../Components/PartOrderItems';
import StatusButton from 'components/StatusButton/StatusButton';
import { AddTrackingInfo } from '../Partials/AddTrackingInfo';
import { PrinterIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import printJS from 'print-js';
import { Receive } from '../Partials/Receive';
import { useSession } from 'contexts';

const StyledSelect = (props) => (
  <select
    className="rounded border px-6 mr-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

export default function ViewPartOrder({ partOrderId, ...props }: { partOrderId: string }) {
  const { user } = useSession();
  const { data: { mroPartOrder } = {} } = useQuery(GetMroPartOrderDocument, { variables: { id: partOrderId } });

  const [updatePartOrder] = useMutation(UpdateMroPartOrderDocument, { refetchQueries: [GetMroPartOrdersDocument] });
  const [updatePartOrderAndChangeWOPartStatus] = useMutation(UpdateMroPartOrderAndChangeWoPartStatusDocument, {
    refetchQueries: [GetMroPartOrdersDocument, GetMroPartRequestsDocument],
  });

  const { toastProps, showToast } = useToast();
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [partOrderItems, setPartOrderItems] = useState<
    {
      id: string | number;
      part?: any;
      partQuantity?: number;
      partPrice?: number;
      requestedDate?: Date;
      condition?: PartCondition;
      notes?: string;
      temp?: boolean;
      active?: boolean;
      workOrderPartId?: string;
      workOrderTitle?: string;
      workOrderNumber?: number;
      status?: string;
    }[]
  >([]);

  const [partOrder, setPartOrder] = useState({
    orderNumber: '',
    issuedDate: new Date(),
    notes: '',
    tracking: {
      carrier: '',
      trackingNumber: '',
    },
  });

  const [vendorInfo, setVendorInfo] = useState({
    code: '',
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
  });
  const [selectedVendor, setSelectedVendor] = useState<any>();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (mroPartOrder) {
      setSelectedVendor(mroPartOrder.mroPartVendor);
      setVendorInfo({
        code: mroPartOrder.mroPartVendor?.vendorCode ?? '',
        name: mroPartOrder.mroPartVendor?.name ?? '',
        address: mroPartOrder.mroPartVendor?.address?.address ?? '',
        address2: mroPartOrder.mroPartVendor?.address?.address2 ?? '',
        city: mroPartOrder.mroPartVendor?.address?.city ?? '',
        state: mroPartOrder.mroPartVendor?.address?.region ?? '',
        zip: mroPartOrder.mroPartVendor?.address?.postalCode ?? '',
        phone: mroPartOrder.mroPartVendor?.phoneNumbers?.phone ?? '',
        fax: mroPartOrder.mroPartVendor?.phoneNumbers?.fax ?? '',
      });
      setPartOrder({
        orderNumber: mroPartOrder.orderNumber,
        issuedDate: mroPartOrder.issuedDate,
        notes: mroPartOrder.notes,
        tracking: {
          carrier: mroPartOrder.trackingInfo?.carrier ?? '',
          trackingNumber: mroPartOrder.trackingInfo?.trackingNumber ?? '',
        },
      });
      setPartOrderItems(
        mroPartOrder.mroPartOrderItems?.map((request) => {
          return {
            id: randomID(8),
            part: request.mroPart,
            partQuantity: request.quantity,
            partPrice: request.unitPrice,
            requestedDate: request.requestedDate,
            condition: request.condition,
            notes: request.notes,
            temp: false,
            active: request.active,
            status: request.status,
            workOrderPartId: request?.mroWorkOrderPart?.id,
            workOrderTitle: request?.mroWorkOrderPart?.mroWorkOrder.title,
            workOrderNumber: request?.mroWorkOrderPart?.mroWorkOrder.workOrderNum,
          };
        })
      );
    }
  }, [mroPartOrder]);

  const handleChange: (field: string) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (field) => (e) => {
    setPartOrder({ ...partOrder, [field]: e.target.value });
  };

  //ADDS AN ITEM WITH TEMP:TRUE, THIS MAKES IT DELETABLE. IF FALSE NO DELETE BUTTON.
  const handleAddItem = function () {
    setPartOrderItems([
      ...partOrderItems,
      {
        id: randomID(8),
        temp: true,
        active: true,
        condition: PartCondition.New,
        part: undefined,
        partQuantity: 0,
        partPrice: 0,
        requestedDate: new Date(),
        notes: '',
      },
    ]);
  };

  const handleRemoveItem = function (index) {
    let mutable = [...partOrderItems];
    mutable.splice(index, 1);
    setPartOrderItems(mutable);
  };

  const handleItemUpdate = useCallback(function (index, newItem) {
    setPartOrderItems((estItems) => {
      let mutable = [...estItems];
      mutable[index] = { ...mutable[index], ...newItem };
      return mutable;
    });
  }, []);

  const handleSave = () => {
    const validItems = partOrderItems?.filter((item) => item.active);

    if (validItems.find((item) => !item.part)) {
      return showToast({ title: 'Missing Part', subtitle: 'Please select a part for all items', type: ToastLength.Long });
    }
    if (validItems.length === 0) {
      return showToast({ title: 'No items to order', subtitle: 'Please select items to order', type: ToastLength.Long });
    }
    updatePartOrder({
      variables: {
        input: {
          id: partOrderId,
          orderNumber: partOrder.orderNumber,
          issuedDate: new Date(partOrder.issuedDate).setHours(12),
          trackingInfo: partOrder.tracking,
          notes: partOrder.notes,
          ...(mroPartOrder.mroPartVendor.vendorCode === vendorInfo.code ? { 
            mroPartVendor: { update: { data: {
              address: {
               update: { data: { 
                  address: vendorInfo.address,
                  address2: vendorInfo.address2,
                  city: vendorInfo.city,
                  region: vendorInfo.state,
                  postalCode: vendorInfo.zip,
                } },
              },
              phoneNumbers: { phone: vendorInfo.phone, fax: vendorInfo.fax },
              name: vendorInfo.name,
              vendorCode: vendorInfo.code,
            } } }
          } : { 
            mroPartVendor: { connectOrCreate: { where: { id: selectedVendor.id } , 
              create:{
                mroOrganization: { connect: { id: user.mroOrganizationId } },
                vendorCode: vendorInfo.code,
                name: vendorInfo.name,
                address: {
                  create: {
                    title: +vendorInfo.name+' Address',
                    address: vendorInfo.address,
                    address2: vendorInfo.address2,
                    city: vendorInfo.city,
                    region: vendorInfo.state, 
                    postalCode: vendorInfo.zip,
                  },
                },
              }
            } }, 
          }),
          mroPartOrderItems: {
            deleteMany: [{}],
            create: validItems.map((item) => {
              return {
                status: item.status ?? 'NOT_RECEIVED',
                mroPart: { connect: { id: item.part.id } },
                quantity: item.partQuantity,
                unitPrice: item.partPrice,
                requestedDate: new Date(item.requestedDate).setHours(12),
                condition: item.condition,
                notes: item.notes,
                active: item.active,
                ...(item.workOrderPartId && { mroWorkOrderPart: { connect: { id: item.workOrderPartId } } }),
              };
            }),
          },
        },
      },
    })
      .then(() => {
        setEdit(false);
      })
      .catch(console.error);
  };
  return (
    <>
      <Toast {...toastProps} />
      <Flyout {...flyoutProps} />
      <div className="p-4 max-h-[95%] flex flex-col gap-5 pb-5">
        <div className="flex gap-1 justify-end">
          <button
            onClick={() => {
              printJS(mroPartOrder?.pdfLink);
            }}>
            <PrinterIcon className="h-6 w-10 p-[0.1875rem] rounded bg-slate-200 text-slate-500" />
          </button>
          <a href={mroPartOrder?.pdfLink} target="_blank" rel="noreferrer" download>
            <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
          </a>
        </div>
        {/* HEADER */}
        <div className="flex flex-col gap-4 w-full justify-end bg-white rounded shadow border border-slate-200 p-6">
          <div className="flex justify-between items-end shrink-0">
            <span className="text-slate-600">Order Number:</span>
            <h1 className="flex text-brand-dark justify-end font-bold text-xl">
              {edit ? (
                <>
                  <span className="text-slate-500 font-normal">#</span>
                  <input
                    className="w-[110px] text-brand-dark font-bold placeholder:text-slate-400 border -ml-5 pl-5 bg-transparent shadow-inner disabled:border-0 disabled:shadow-none"
                    placeholder=""
                    value={partOrder.orderNumber}
                    onChange={handleChange?.('orderNumber')}
                  />
                </>
              ) : (
                <span className="text-slate-500 font-bold">#{partOrder.orderNumber}</span>
              )}
            </h1>
          </div>
          <div className="flex justify-between items-end shrink-0">
            <span className="text-slate-600">Issued Date:</span>
            {edit ? (
              <input
                className="text-brand-dark font-bold -mt-0.5 p-0 border-0 text-right"
                type="date"
                value={formatFieldDate(partOrder?.issuedDate)}
                onChange={(e) => {
                  setPartOrder({ ...partOrder, issuedDate: formatDateChange(e.target.value) as Date });
                }}
              />
            ) : (
              <span className="text-slate-500 font-bold">{formatApiDateTrunc(partOrder?.issuedDate)}</span>
            )}
          </div>
          {!edit && (
            <div className="flex justify-between items-end shrink-0">
              <span className="text-slate-600">Status:</span>
              <div className="w-40">
                <StatusButton readOnly={true} context="partOrder" text={mroPartOrder?.status} className="" onSelect={(e) => {}} />
              </div>
            </div>
          )}
          {mroPartOrder?.status !== 'DRAFT' && mroPartOrder?.status !== 'VOID' && (
            <div className="flex justify-between items-end shrink-0">
              <span className="text-slate-600">Tracking Info:</span>
              {edit ? (
                <div>
                  <StyledSelect
                    value={partOrder.tracking.carrier}
                    onChange={(e) => setPartOrder({ ...partOrder, tracking: { ...partOrder.tracking, carrier: e.target.value } })}>
                    <option value="">N/A</option>
                    <option value="USPS">USPS</option>
                    <option value="FEDEX">Fedex</option>
                    <option value="UPS">UPS</option>
                    <option value="DHL">DHL</option>
                  </StyledSelect>
                  <input
                    className="w-[150px] text-brand-dark font-bold p-0 border text-right text-lg h-9"
                    value={partOrder.tracking.trackingNumber}
                    onChange={(e) => {
                      setPartOrder({ ...partOrder, tracking: { ...partOrder.tracking, trackingNumber: e.target.value } });
                    }}
                  />
                </div>
              ) : (
                <span className="text-slate-500 font-bold">{`${partOrder.tracking?.carrier} ${partOrder.tracking?.trackingNumber}`}</span>
              )}
            </div>
          )}
        </div>
        {/* VENDOR */}
        {vendorInfo.code && (
          <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-2">
            <div className="flex-initial mb-4">
              <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3 mb-2">
                <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Vendor</span>
              </div>
              <VendorCard {...{ vendorInfo, setVendorInfo, selectedVendor, setSelectedVendor, edit }} />
            </div>
          </div>
        )}
        {/* ITEMS */}
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-4">
          <div className="flex-initial mb-4">
            {partOrderItems.length > 0 && (
              <>
                <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3">
                  <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Items</span>
                </div>
                {partOrderItems.map((entry, index) => {
                  return <PartOrderItems key={index} {...{ handleItemUpdate, handleRemoveItem, index, entry, edit }} />;
                })}
              </>
            )}
          </div>
          <div className="flex items-center justify-between border-t border-slate-200 rounded-b py-3 px-4 text-sm -mx-4">
            {edit && (
              <button
                onClick={() => {
                  handleAddItem();
                }}
                className="flex shrink-0 items-center bg-white text-brand-dark border border-slate-300 text-sm rounded font-semibold py-1 px-3 hover:opacity-90 transition-all">
                <PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
                Add Item
              </button>
            )}
            <div className="flex gap-4 items-center">
              <span className="font-bold text-brand">Total:</span>
              <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm">
                {formatUSD(partOrderItems?.reduce((acc, cur) => acc + Number(cur.partPrice * cur.partQuantity), 0))}
              </span>
            </div>
          </div>
        </div>
        {/* NOTES */}
        <div className="flex flex-col w-full border border-slate-200 rounded bg-white px-4 pt-2">
          <div className="flex-initial mb-4">
            <div className="flex justify-between border-b border-slate-200 rounded-b py-2 px-4 text-sm -mx-3 mb-2">
              <span className="font-bold text-brand-dark uppercase py-0.5 -mx-4 pl-4">Order Comments</span>
            </div>
            <div className="flex flex-col gap-2">
              <textarea
                className="border border-slate-200 rounded p-2 text-sm disabled:bg-slate-50 disabled:border-0 disabled:shadow-none"
                placeholder={edit ? 'Enter order comments here...' : ''}
                value={partOrder.notes ?? ''}
                disabled={!edit}
                onChange={handleChange?.('notes')}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2">
          {edit ? (
            <div className="flex gap-2 items-center justify-end mt-2 pb-20">
              <button
                onClick={() => setEdit(false)}
                className="flex items-center font-semibold text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                Save Changes
              </button>
            </div>
          ) : (
            <div className="flex gap-2 items-center justify-end mt-2 pb-20">
              {mroPartOrder?.status !== 'VOID' && (
                <>
                  <Button
                    color="purple"
                    size="xs"
                    text="Void"
                    onClick={() =>
                      updatePartOrder({
                        variables: {
                          input: {
                            id: partOrderId,
                            status: 'VOID',
                          },
                        },
                      })
                    }
                  />
                  <Button color="navy" size="xs" text="Edit" onClick={() => setEdit(true)} />
                </>
              )}
              {mroPartOrder?.status === 'ORDERED' && (
                <Button
                  color=""
                  size="xs"
                  text="Receive Items"
                  onClick={() => updateFlyout({ title: 'Receive Items', content: <Receive {...{ closeFlyout, partOrderId }} /> })}
                />
              )}
              {mroPartOrder?.status === 'DRAFT' && (
                <Button
                  color=""
                  size="xs"
                  text="Add Tracking/Mark as Ordered"
                  onClick={() =>
                    updateFlyout({
                      title: 'Add Tracking Info',
                      content: (
                        <AddTrackingInfo
                          trackingInfo={mroPartOrder.trackingInfo}
                          setTrackingInfo={(info) =>
                            updatePartOrderAndChangeWOPartStatus({
                              variables: {
                                input: {
                                  id: partOrderId,
                                  trackingInfo: info,
                                  status: 'ORDERED',
                                },
                              },
                            })
                              .then(() => closeFlyout())
                              .catch(console.error)
                          }
                        />
                      ),
                    })
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import {   
  GetUserOrganizationProfileDocument,
  GetUserOrganizationProfilesDocument,
  GetUserOrganizationRolesDocument,
  UpdateUserOrganizationProfileDocument,
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Modal from 'components/Modal/Modal';

export const EditUserSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string(),
    role: Yup.string().min(2).required("Required"),
});

const EditUser = function ({ closeFlyout, id }: { closeFlyout: () => void; id: string }) {
    const { organizationId } = useSession();
    const [updateUserOrganizationProfile] = useMutation(UpdateUserOrganizationProfileDocument, { 
        refetchQueries: [{ query: GetUserOrganizationProfilesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const { data : { userOrganizationProfile } = {} } = useQuery( GetUserOrganizationProfileDocument, { variables: { id } } );
    const { data : { userOrganizationRoles } = {} } = useQuery( GetUserOrganizationRolesDocument, { variables: { where: { organizationId: { equals: organizationId }, sort: { not: null } } } } );
    const [ initialValues, setInitialValues ] = useState({ name : '', email: '', phone: '', role: '' });
    const [ modalOpen, setModalOpen ] = useState(false);
    const name = userOrganizationProfile?.user?.firstName ? `${userOrganizationProfile?.user?.firstName} ${userOrganizationProfile?.user?.lastName}` : userOrganizationProfile?.nameInvite;

    useEffect(() => {
        if(!userOrganizationProfile) return;
        setInitialValues({ name, email: userOrganizationProfile?.user?.email ?? userOrganizationProfile?.emailInvite ?? '', 
            phone: userOrganizationProfile?.phone ?? userOrganizationProfile?.phoneInvite ?? '', 
            role: userOrganizationProfile?.userOrganizationRole?.id ?? '' });
    }, [userOrganizationProfile]);
    const inputBoxStyle = userOrganizationProfile?.status === "INVITED" ? "" : "bg-white my-1 text w-full";
    return (
    <>
        { modalOpen && <Modal message={'You are about to remove '+ name + ' from Organization'} accept={
            ()=> updateUserOrganizationProfile({ variables: { input: {
                id: id,
                organization: { disconnect: {  } }
             }}})
        } cancel={setModalOpen} /> }
        <Formik enableReinitialize
        initialValues={initialValues}
        validationSchema={EditUserSchema}
        onSubmit={(values) => updateUserOrganizationProfile({ variables: { input: { 
            id,
            nameInvite: values.name, 
            emailInvite: values.email.toLowerCase(), 
            phoneInvite: values.phone, 
            userOrganizationRole: { connect: { id: values.role } },
            organization: { connect: { id: organizationId } } } } }) }>
            {({ }) => {
                return (
                    <Form>
                        <Card className="flex flex-col p-4 h-full m-4">
                            <FullFormikInput disabled={userOrganizationProfile?.status !== "INVITED"} fieldClassName={inputBoxStyle} name="name" label="Memeber Name*" autoComplete="off" />
                            <FullFormikInput disabled={userOrganizationProfile?.status !== "INVITED"} fieldClassName={inputBoxStyle} name="email" label="Member Email*" autoComplete="off" />
                            <FullFormikInput disabled={userOrganizationProfile?.status !== "INVITED"} fieldClassName={inputBoxStyle} name="phone" label="Member Phone" autoComplete="off" />
                            <FullFormikSelect name="role" label="Role">
                            { 
                                userOrganizationRoles?.map((role) => {
                                    return <option key={role.id} value={role.id}>{role.title}</option>
                                })
                            }
                            </FullFormikSelect>
                        </Card>
                        <div className="bg-gray-50 px-4 py-5 flex items-center justify-end gap-10">
                            <Button text="Revoke Membership" size='xs' color='red' onClick={() => setModalOpen(true)} />
                            <Button text="Update" type='submit' size='xs' color='navy' />
                        </div>
                    </Form>
            )}}
        </Formik>
    </>
    );
};

export default EditUser;

import { useMutation, useQuery } from '@apollo/client';
import {
  GetWorkOrderBillingDocument,
  GetMroWorkOrderQuery,
  GetMroEstimateDocument,
  SendEstimateDocument,
  UpdateMroEstimateDocument,
  GetMroWorkOrderBillingItemsDocument,
} from 'graphql/generated';
import { useState } from 'react';
import { ArrowDownTrayIcon, PencilIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Modal from 'components/Modal/Modal';
import EditEstimateFlyout from '../Edit';
import { Link } from 'react-router-dom';
import Estimate from '../partials/EstimateView';
import printJS from 'print-js';
import SendEmailFlyout from '../partials/SendEmailFlyout';

export default function EstimateFlyout({
  mroEstimateId,
  workOrder,
}: {
  mroEstimateId: string;
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
}) {
  const [modal, setModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data: { mroEstimate } = {} } = useQuery(GetMroEstimateDocument, { variables: { id: mroEstimateId } });
  const [updateEstimate] = useMutation(UpdateMroEstimateDocument, {
    refetchQueries: [GetWorkOrderBillingDocument, { query: GetMroEstimateDocument, variables: { id: mroEstimateId } }],
  });
  const [sendEstimate] = useMutation(SendEstimateDocument, {
    refetchQueries: [{ variables: { id: mroEstimateId }, query: GetMroEstimateDocument }],
  });
  return (
    <>
      <Flyout {...flyoutProps} />
      {modal && (
        <Modal
          cancel={setModal}
          accept={() => updateEstimate({ variables: { input: { id: mroEstimateId, status: 'VOID' } } })}
          message={'You are about to Void Invoice #' + mroEstimate?.estimateNumber + ', this cannot be undone.'}
        />
      )}
      <div className="flex flex-col p-4 h-full mb-20">
        <div className="flex gap-1 justify-end mb-2">
          <button
            onClick={() => {
              printJS(mroEstimate?.pdfLink);
            }}>
            <PrinterIcon className="h-6 w-10 p-[0.1875rem] rounded bg-slate-200 text-slate-500" />
          </button>
          <a href={mroEstimate?.pdfLink} target="_blank" rel="noreferrer" download>
            <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
          </a>
        </div>
        <Estimate
          estimate={{ ...mroEstimate, ...mroEstimate?.priceSummary, ...mroEstimate?.laborSummary }}
          charges={mroEstimate?.charges}
          discounts={mroEstimate?.discounts}
          estimateItems={mroEstimate?.items}
          status={mroEstimate?.status}
          edit={false}
        />

        {mroEstimate?.status !== 'VOID' && (
          <div className="flex self-end text-sm p-4 gap-5">
            { (mroEstimate?.status === 'UNAPPROVED' || mroEstimate?.status === 'PENDING') && (
              <>
                <Link to={'/mro/service-request/preview/' + mroEstimate?.id} className="hover:text-brand text-xs text-blue-500 pr-1">
                  Preview
                </Link>
                <button
                  disabled={submitting}
                  onClick={async () => {
                    updateFlyout({
                      title: 'Send Estimate',
                      content: (
                        <SendEmailFlyout
                          closeFlyout={closeFlyout}
                          submit={async (emails) => {
                            setSubmitting(true);
                            await sendEstimate({ variables: { id: mroEstimateId, emails } });
                            setSubmitting(false);     
                          }}
                          primaryContact={workOrder?.primaryContact}
                          allContacts={workOrder?.mroCustomer?.mroContacts ?? []}
                        />
                      ),
                    });
                  }} // TODO: replace with customer email
                  className="flex items-center font-semibold bg-brand-electric text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                  <>
                    {mroEstimate?.status === 'PENDING' ? 'Resend Estimate' : 'Send Estimate'}
                  </>
                </button>
              </>
            )}
            { mroEstimate?.status === 'UNAPPROVED' && <button
              onClick={() =>
                updateFlyout({
                  title: 'Edit Estimate',
                  content: <EditEstimateFlyout estimate={mroEstimate} workOrder={workOrder} closeFlyout={closeFlyout} />,
                })
              }
              className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
              <>
                <PencilIcon className="h-2.5 w-2.5 mr-1" />
                Edit
              </>
            </button> }
            <button
              onClick={() => setModal(true)}
              className="flex items-center font-semibold bg-brand-purple text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
              <>
                <TrashIcon className="h-2.5 w-2.5 mr-1" />
                Void
              </>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

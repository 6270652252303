import { Table } from "components/Table/Table";
import { useMemo, useState } from "react";
import { GetReservationsDocument, Reservation } from "graphql/generated";
import { applyTimezone, formatApiDateTimeUTC, formatApiDateUTC } from "utils/formatter";
import { useSession } from "contexts";
import View from "../View";
import { useQuery } from "@apollo/client";
function List({ updateFlyout, closeFlyout }) {
  const { organizationId } = useSession();
  const { user } = useSession();
  const [ limit, setLimit ] = useState(10);
  const [ skip, setSkip ] = useState(0);
  const { data, refetch } = useQuery(GetReservationsDocument, { variables: { 
    where: { craft: { is:{ organizationId: { equals: organizationId } } } },
    limit: limit,
    skip: skip,
  } } );
  const showView = (reservation)=> {
    updateFlyout({ title: 'Reservation', content: <View refreshData={refetch} id={reservation?.id} closeView={closeFlyout} /> });
  }
  const columns = useMemo(
      () => [
        {
          Header: 'From',
          accessor: 'from',
          className: 'text-left w-2/12',
          Cell: ({ value, row }: any) => {
            return (
              <div className="w-full h-full text-left">
                <button>{row?.original?.allDay? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'To',
          accessor: 'to',
          className: 'text-left w-2/12',
          Cell: ({ value, row }: any) => {
            return (
              <div className="w-full h-full text-left">
                <button>{row?.original?.allDay ? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'Craft',
          accessor: (row: any) => `${row?.craft?.craftName ?? ''}`,
          className: 'text-left  w-2/12',
        },
        {
          Header: 'Pilot',
          accessor: (row: any) => `${row.isMaintenance ? "Maintenance" : row?.pilotName ?? ''}`,
          className: 'text-left w-2/12',
        },
        {
          Header: 'Notes',
          accessor: 'notes',
          className: 'text-center w-4/12 truncate overflow-hidden text-ellipsis',
        },
      ],
      []
  );
  return <div className="p-6 bg-white border rounded border-slate-300 w-full shadow-blue">
      <Table
        className="w-full"
        columns={columns}
        data={data?.reservations ?? []}
        onRowClick={showView}
        skip={skip} limit={limit} count={data?.reservationCount ?? 0}
        updateLimitAndSkip = {(lm, sk) => { setLimit(lm); setSkip(sk); }}
        />
  </div>
}
export default List;
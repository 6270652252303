import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import { FullFormikInput } from 'components/Form/StandardForm';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { useSession } from 'contexts';
import { Field, Form, Formik } from 'formik';
import { ChangePasswordDocument, UpdateUserDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';

export default function AccountSettings() {
  const { user } = useSession();
  
  const [changePassword] = useMutation(ChangePasswordDocument);
  const [updateUser] = useMutation(UpdateUserDocument);

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [formPrefill, setFormPrefill] = useState({
    firstName: '',
    lastName: '', 
    password: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { toastProps, showToast } = useToast();

  useEffect(() => {
    if (user)
      setFormPrefill((prefill) => ({
        ...prefill,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      }));
  }, [user]);
  return (
    <>
      <Toast {...toastProps} />
      <div className="flex flex-col lg:flex-row">
        <Formik
          enableReinitialize
          initialValues={formPrefill}
          onSubmit={async (values: typeof formPrefill) => {
            setIsLoadingSave(true);
            await updateUser({
              variables: {
                input: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
              },
            });
            var toastMessage = 'Account Updated';
            if (values.newPassword && values.newPassword === values.confirmPassword) {
              await changePassword({
                variables: {
                  oldPassword: values.password,
                  newPassword: values.newPassword,
                },
              });
              toastMessage = 'Password Updated';
            }else if(values.newPassword){
              toastMessage = 'Passwords not Equal';
            }
            setTimeout(() => { 
              showToast({ title: toastMessage, type:ToastLength.Normal });
              setIsLoadingSave(false);
            }, 1500);
          }}>
          <Form className="flex flex-col w-full lg:w-2/3 gap-2 p-10 rounded-l">
            <span className="font-bold text-xs text-brand uppercase mb-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
              Account Information
            </span>
            <div className="flex items-start">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Name</span>
              <div className="flex flex-col w-2/3">
                <FullFormikInput name="firstName" placeholder="First Name" />
                <FullFormikInput name="lastName" placeholder="Last Name" />
              </div>
            </div>
            
            <span className="font-bold text-xs text-brand uppercase my-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">Change Password</span>
            <div className="flex mb-4">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Password</span>
              <div className="flex flex-col w-2/3">
                <FullFormikInput name="password" type="password" autoComplete="current-password" placeholder="Current Password" />
                <FullFormikInput name="newPassword" type="password" autoComplete="new-password" placeholder="New Password" />
                <FullFormikInput name="confirmPassword" type="password" autoComplete="new-password" placeholder="Confirm New Password" />
              </div>
            </div>
            <Button text={isLoadingSave ? 'Saving...' : 'Save'}
                color="navy"
                size="sm"
                type="submit"
                disabled={isLoadingSave}
                className='mt-5 disabled:opacity-70' />
          </Form>
        </Formik>
      </div>
      </>
  );
}

export default function PrintLogEntriesRow({ title, numberOfItems, checked, setChecked }) {
  return (
    <div className={`border-brand-pale group flex justify-between items-start p-4 -mx-2 border-b border-dashed last:border-b-0`}>
      <input
        type="checkbox"
        className="mt-1 rounded h-3.5 w-3.5 -ml-3 mr-0.5 cursor-pointer"
        onChange={(e) => setChecked(e.currentTarget.checked)}
        checked={checked}></input>
      <h2 className="text-sm px-2 relative">{title}</h2>
      <span className="font-medium px-1 rounded text-sm">Squawks: {numberOfItems}</span>
    </div>
  );
}
import React from 'react';

const CheckInCircleIcon = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9.4" stroke="currentColor" strokeWidth="1.75" />
      <path d="M7 12L10.4375 15L17 8.5" stroke="currentColor" strokeWidth="1.75" />
    </svg>
  );
};

export default CheckInCircleIcon;

import Logo from 'assets/icons/logo_navbar_w.svg';
import Button from 'components/Button/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GetMigrateInfoDocument, SetupPasswordFromTokenDocument } from 'graphql/generated';

const loginValues = {
  password: '',
  confirmPassword: '',
};

const Migration = (props) => {
    const { id, token, loginType } = useParams();
    const { error } = useQuery(GetMigrateInfoDocument, { variables: { id, token } });
    const [ setupPassword ] = useMutation(SetupPasswordFromTokenDocument);
    const navigate = useNavigate();

    return (
    <div className="flex flex-col justify-center items-center w-[28rem] md:border-x border-brand-pale border-dashed h-full">
        <div className="flex justify-center w-full min-w-[100vw] md:border-y border-brand-pale border-dashed">
        <div className="w-full rounded mdborder border-slate-300 hover-shadow border-b-4 min-h-[100vh] md:min-h-0 md:w-96 pt-8 md:pt-5 bg-white">
            <Formik
            initialValues={loginValues}
            onSubmit={async (values: typeof loginValues) => {
                if(values.password !== values.confirmPassword){
                    alert('Passwords do not match');
                    return;
                }else{
                    setupPassword({ variables: { id, token, password: values.password } }).then((res) => {
                        if(res.data?.setupPasswordFromToken){
                            alert('Password set successfully');
                            navigate('/auth');
                        }
                    });
                }
            }}>
                <Form className="">
                    <div
                    id="ribbon"
                    className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 -ml-2 bg-brand relative border-b-2 border-r-1 border-t border-t-brand border-[#162551] rounded-r w-full">
                        <img alt="logo" className="w-32 py-2" src={Logo} />
                    </div>
                    {error ? 
                        <div className="flex flex-col p-10">
                            <div className="flex flex-col mb-4">
                                <span className="text-2xl font-bold text-brand">Invaild Migrate Link</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm font-medium text-brand">{error?.message}</span>
                            </div>
                        </div>
                    :
                    <div className="flex flex-col p-10">
                        <div className="flex flex-col mb-4">
                            <span className="text-2xl font-bold text-brand">Set your new Password</span>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-brand">Password</label>
                            <Field
                            name="password"
                            type="password"
                            className="rounded border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm"></Field>
                            <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
                        </div>
                        <div className="flex flex-col border-b border-brand-pale border-dashed pb-2 px-2 -mx-2">
                            <label className="text-sm font-medium text-brand">Confirm Password</label>
                            <Field
                            name="confirmPassword"
                            type="password"
                            className="rounded border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm"></Field>
                            <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic" />
                        </div>
                        <div className="flex items-center justify-between mt-2">
                            <Button text="Set New Password" color="navy" size="sm" type="submit" />
                        </div>
                    </div> }
                </Form>
            </Formik>
        </div>
        </div>
    </div>
    );
};

export default Migration;

import { useLazyQuery } from '@apollo/client';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { FormLabel } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import { GetMroPartVendorsDocument } from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function VendorLookup({
  selectedVendor,
  setSelectedVendor,
  vendorCode,
  setVendorCode,
  className = '',
}: {
  selectedVendor: any;
  setSelectedVendor: any;
  vendorCode: any;
  setVendorCode: any;
  className?: string;
}) {
  const [query, setQuery] = useState('');
  const { user } = useSession();
  const searchBox = useRef(null);

  const [search, { data: { mroPartVendors: filteredVendors } = { mroPartVendors: [] } }] = useLazyQuery(GetMroPartVendorsDocument);
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        search({
          variables: {
            where: {
              OR: [
                { name: { contains: query.trim().replaceAll(' ', ' & ') } },
                { vendorCode: { contains: query.trim().replaceAll(' ', ' & ') } },
              ],
              mroOrganizationId: { equals: user.mroOrganizationId },
            },
          },
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query, search, user]);
  return (
    <Combobox
      as="div"
      value={selectedVendor && selectedVendor !== 'newCode' ? vendorCode : 'newCode'}
      className={className}
      onChange={() => {}}>
      <Combobox.Label as={FormLabel}>Code</Combobox.Label>
      <div className="relative mb-4">
        <input
          className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
          onChange={(event) => {
            setQuery(event.target.value);
            setVendorCode(event.target.value);
            setSelectedVendor(null);
          }}
          value={vendorCode}
          onClick={() => searchBox.current?.click()}
          placeholder="Search for a Vendor Code"
        />

        <Combobox.Button ref={searchBox} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <Combobox.Option
            key={'newCode'}
            value={'newCode'}
            onClick={() => {
              setSelectedVendor('newCode');
            }}
            className={({ active }) =>
              classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
            }>
            {({ active, selected }) => (
              <>
                <div className="flex">
                  <span className={'font-semibold'}>New Vendor</span>
                </div>
                {selected && (
                  <span
                    className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
          {filteredVendors.length > 0 &&
            filteredVendors.slice(0, 4).map((vendor) => (
              <Combobox.Option<'li', any>
                key={vendor.id}
                value={vendor.vendorCode}
                onClick={() => {
                  setVendorCode(vendor.vendorCode);
                  setSelectedVendor(vendor);
                }}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }>
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span className={classNames('truncate', selected && 'font-semibold')}>{vendor.vendorCode}</span>
                      <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>
                        {vendor.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default VendorLookup;

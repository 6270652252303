//To Do: move this to a better location and allow for dynamic pricing based on MRO

export const partMarkup = (partPrice: number, priceMatrix?: any) => {
  return [partPrice + partPrice * partPrecent(partPrice, priceMatrix), partPrecent(partPrice, priceMatrix)];
};

const partPrecent = (partPrice: number, priceMatrix: any) => {
  if(priceMatrix === undefined) return 0;

  for (let i = 0; i < priceMatrix.length; i++) {
    const { price, markUp, operator } = priceMatrix[i];
    switch (operator) {
      case "<=":
        if (partPrice <= price) {
          return markUp;
        }
        break;
      case ">=":
        if (partPrice >= price) {
          return markUp;
        }
        break;
      case "<":
        if (partPrice < price) {
          return markUp;
        }
        break;
      case ">":
        if (partPrice > price) {
          return markUp;
        }
        break;
      default:
        throw new Error("Invalid comparison operator");
    }
  }

  return 0;
};

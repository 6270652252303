
type Charge = {
  percent?: number;
  min?: number;
  max?: number;
  total?: number;
  type?: string;
}


export function convertToFee(totalLabors: number, totalParts: number, charge: Charge) {
  const { percent, min, max, total: chargeTotal } = charge;
  let total = totalLabors + totalParts;
  if(charge.type === 'LABOR') {
    total = totalLabors;
  }else if(charge.type === 'PARTS') {
    total = totalParts;
  }
  const chargeAmount = percent !== undefined ? Math.min(Math.max(total * (percent ? percent / 100 : 0), min || 0), max || total) : chargeTotal || 0;
  return Number(chargeAmount.toFixed(2));
}
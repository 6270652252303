import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";


export default function ChargeebeeManagmentModal({cancel, url}) {
    return (
        <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
            <div
            onClick={() => {
                cancel(false);
            }}
            className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0"></div>
            <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-md w-full">
                <XMarkIcon
                    onClick={() => {
                        cancel(false);
                    }}
                    className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
                />
                <div className="flex gap-3 items-center p-8">
                    <iframe src={url ?? ''} height={500} ></iframe>
                </div>
            </div>
        </div>
    )
}
import { Formik, Form } from 'formik';
import Button from 'components/Button/Button';
import { FullFormikInput } from 'components/Form/StandardForm';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { AddMemberToMroCustomerDocument, GetMroCustomerDocument, NewOrderCustomersDocument } from 'graphql/generated';
import { useSession } from 'contexts';

const NewEmployeeSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
});

const NewMember = function ({
  mroCustomerId,
  closeFlyout,
  setStep,
  postSubmit,
  addressInfo,
}: {
  mroCustomerId: string;
  closeFlyout: () => void;
  setStep?: any;
  postSubmit?: any;
  addressInfo?: any;
}) {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: addressInfo?.email,
    phone: addressInfo?.phone,
    address: addressInfo?.address,
    address2: addressInfo?.address2,
    city: addressInfo?.city,
    state: addressInfo?.region,
    postalCode: addressInfo?.postalCode,
  };
  const { user } = useSession();
  const [createEmployee] = useMutation(AddMemberToMroCustomerDocument, {
    refetchQueries: [{ query: GetMroCustomerDocument, variables: { mroCustomerId } }, NewOrderCustomersDocument],
  });
  const handleSubmit = (values: typeof initialValues) => {
    createEmployee({
      variables: {
        input: {
          mroCustomerId,
          mroOrganizationId: user?.mroOrganizationId,
          address: {
            title: 'Primary',
            address: values.address,
            address2: values.address2,
            city: values.city,
            region: values.state,
            postalCode: values.postalCode,
          },
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumbers: { primary: values.phone },
        },
      },
    })
      .then((res) => {
        if (postSubmit) {
          postSubmit({ mroContact: res.data.addContactToMroCustomer });
        }
        closeFlyout();
      })
      .catch(console.error);
  };

  return (
    <Formik validationSchema={NewEmployeeSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {/* CONTACT INFORMATION */}
        <h2 className="text-xl font-bold text-brand border-b border-slate-200 pb-3 -mx-2 pl-2">Contact Information</h2>
        <div className="flex flex-col w-full mt-6">
          <div className="flex gap-1">
            <FullFormikInput name="firstName" label="First Name" autoComplete="given-name" />
            <FullFormikInput name="lastName" label="Last Name" autoComplete="family-name" />
          </div>
          <FullFormikInput name="address" placeholder="Line 1" label="Address" className='mb-2' autoComplete="address-line1" />
          <FullFormikInput name="address2" placeholder="Line 2" className="-mt-1" autoComplete="address-line2" />
          <div className="flex gap-1">
            <FullFormikInput name="city" label="City" className="w-3/6" autoComplete="address-level2" />
            <FullFormikInput name="state" label="State" className="w-1/6" autoComplete="address-level1" />
            <FullFormikInput name="postalCode" label="Postal Code" className="w-2/6" autoComplete="postal-code" />
          </div>
          <div className="flex gap-1">
            <FullFormikInput name="email" label="Email" type="email" autoComplete="email" />
            <FullFormikInput name="phone" label="Phone #" autoComplete="tel" />
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          {setStep ? (
            <button type="button" onClick={() => setStep(3)} className="underline text-brand font-semibold text-sm">
              Go Back
            </button>
          ) : (
            <div></div>
          )}
          <Button text="Submit" color="navy" size="sm" type="submit" />
        </div>
      </Form>
    </Formik>
  );
};

export default NewMember;

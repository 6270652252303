import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { taskCase, orderCase, partsCase, requestCase, estimateCase, logEntryCase, webFormat, partOrderCase, squawkCase, severityCase, craftRequestCase, invoiceCase } from 'utils/statuses';

function StatusButton({ readOnly, context, text = '', onSelect = (e) => {}, className }) {
  let instance: object,
    keys = [];
  switch (context) {
    case 'task':
      instance = taskCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'order':
      instance = orderCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'parts':
      instance = partsCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'request':
      instance = requestCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'estimate':
      instance = estimateCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'invoice':
      instance = invoiceCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'logEntry':
      instance = logEntryCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'partOrder':
      instance = partOrderCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'squawk':
      instance = squawkCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'severity':
      instance = severityCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    case 'craftRequest':
      instance = craftRequestCase;
      for (const prop in instance) {
        keys.push(prop);
      }
      break;
    
    default:
      instance = orderCase;
      break;
  }
  if (readOnly) {
    return (
      <div className="flex items-center grow relative">
        <div
          className={`${instance[text]?.textColor} ${instance[text]?.bgColor} text-sm flex font-bold justify-center items-center rounded w-full relative ${className}`}>
          <span className="flex w-full justify-center py-1 px-3 text-center items-center select-none capitalize">
            {instance[text]?.icon}
            {webFormat(text)}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-stretch grow relative group">
        <select
          onChange={(e) => {
            onSelect(e.target.value);
          }}
          className="absolute w-full h-full z-10 opacity-0 cursor-pointer p-0"
          value={text}>
          {keys.map((item, index) => {
            return (
              <option value={item} key={index}>
                {webFormat(item)}
              </option>
            );
          })}
        </select>
        <div
          className={`${instance[text]?.textColor} ${instance[text]?.bgColor} ${className} text-sm flex font-semibold justify-center items-center rounded w-full relative`}>
          <span className="flex w-full justify-center py-1 px-3 items-center select-none capitalize">
            {instance[text]?.icon}
            {webFormat(text)}
          </span>
          <ChevronDownIcon className={`${instance[text]?.drop} border-l h-full w-6 py-[6.075px] p-1 rounded-r`} />
        </div>
      </div>
    );
  }
}

export default StatusButton;

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import Button from 'components/Button/Button';
import { ServiceFormContext } from '..';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GetServiceUserDocument, LoginForServiceDocument } from 'graphql/generated';
import { useSession } from 'contexts';

const loginValues = { email: '', password: '' };

const LoginForm = (props) => {
  const { prevStep, formStep, changeStep, setUser, craftId } = useContext(ServiceFormContext);
  const { login } = useSession();
  const [getUser] = useLazyQuery(GetServiceUserDocument);
  return (
    <Formik
      initialValues={loginValues}
      onSubmit={async (values) => {
        await login(values.email, values.password);
        const { data } = await getUser();
        setUser(data.me); // login user and set userId
        if (craftId) changeStep(formStep, 'contactInfo');
        else changeStep(formStep, 'craftSelect');
      }}>
      {({ isSubmitting }) => (
        <Form className="w-96 p-6">
          <div className="flex flex-col mb-4">
            <span className="text-3xl md:text-2xl font-bold text-brand">Log in to your account</span>
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-brand">Email</label>
            <Field name="email" type="email" className="rounded border border-slate-300 h-12 md:h-10 mb-4 px-2"></Field>
            <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-brand">Password</label>
            <Field name="password" type="password" className="rounded border border-slate-300 h-12 md:h-10 mb-4 px-2"></Field>
            <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex flex-col mt-4">
            <Button text="Login" color="navy" size="md" className='disabled:opacity-50' disabled={isSubmitting} type="submit" />
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="underline text-brand font-semibold text-sm mt-4"
                onClick={() => changeStep(formStep, prevStep[prevStep.length - 1], true)}>
                Go Back
              </button>
              <Link className="underline text-brand font-semibold text-sm mt-4" to={'/mro/auth/forgot-password'}>
                Forgot password?
              </Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {   
    GetOrganizationSettingsDocument,
  SetupAirblyDocument
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { ArrowPathIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { FullFormikInput } from 'components/Form/StandardForm';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';

const AirblySetup = function ({ closeFlyout }: { closeFlyout: () => void }) {
    const { organizationId } = useSession();
    const [ key, setKey ] = useState('');
    const [setupAirbly] = useMutation(SetupAirblyDocument, { refetchQueries: [ {query: GetOrganizationSettingsDocument, variables: { id: organizationId } }] });
    const {showToast, toastProps, isShowing} = useToast();

    return (
    <>
        <Toast {...toastProps} />
        <Formik enableReinitialize
        initialValues={{ key }}
        onSubmit={async (values) => {
            const { data } = await setupAirbly({ variables: { organizationId, key:values.key } });
            if(data.setupAirbly.integration_enabled){
                closeFlyout();
            }else{
                showToast({title: data?.setupAirbly?.message, type: ToastLength.Normal});
            }
        } }>
        {({ isSubmitting }) => {
            return (
                <Form className='px-2'>
                    <Card>
                        <div className='flex flex-col gap-2'>
                            <h1>Automatic Logbooks</h1>
                            <h2>By connecting your Coflyt account with Airbly you will be able to:</h2>
                            <ul className='mt-3 pl-3'>
                                <li>&#8226; Location tracking allows you to always know where your aircraft is and where it has been.</li>
                                <li>&#8226; Automatic logbook generation creates a detailed log of each flight.</li>
                                <li>&#8226; Maintenance tracking ensures your aircraft is maintained and airworthy.</li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-3 mt-10'>
                            <h1 className='text-lg font-bold'>Read-only key</h1>
                            <FullFormikInput name='key' label='' />
                        </div>
                    </Card>
                    <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
                        <Button text="Connect" type='submit' disabled={isSubmitting} size='sm' color='navy' />
                    </div>
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default AirblySetup;

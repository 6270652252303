import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_432_1079)">
        <path
          d="M11.7 4C10.5069 4.00132 9.36312 4.47585 8.51949 5.31948C7.67587 6.16311 7.20134 7.30693 7.20001 8.5C7.20001 10.119 8.55301 12.357 11.334 15.341C11.3808 15.3913 11.4375 15.4314 11.5004 15.4588C11.5634 15.4862 11.6313 15.5003 11.7 15.5003C11.7687 15.5003 11.8366 15.4862 11.8996 15.4588C11.9626 15.4314 12.0192 15.3913 12.066 15.341C14.847 12.357 16.2 10.119 16.2 8.5C16.1987 7.30693 15.7242 6.16311 14.8805 5.31948C14.0369 4.47585 12.8931 4.00132 11.7 4ZM11.7 10C11.4033 10 11.1133 9.91203 10.8667 9.7472C10.62 9.58238 10.4277 9.34811 10.3142 9.07403C10.2007 8.79994 10.171 8.49834 10.2288 8.20736C10.2867 7.91639 10.4296 7.64912 10.6394 7.43934C10.8491 7.22956 11.1164 7.0867 11.4074 7.02882C11.6983 6.97094 11.9999 7.00065 12.274 7.11418C12.5481 7.22771 12.7824 7.41997 12.9472 7.66664C13.112 7.91332 13.2 8.20333 13.2 8.5C13.2 8.89782 13.042 9.27936 12.7607 9.56066C12.4794 9.84196 12.0978 10 11.7 10Z"
          fill="currentColor"
        />
        <path
          d="M3.70001 0C2.77175 0 1.88152 0.368749 1.22514 1.02513C0.568761 1.6815 0.200012 2.57174 0.200012 3.5C0.200012 4.744 1.22301 6.488 3.32801 8.834C3.3749 8.88621 3.43226 8.92797 3.49635 8.95655C3.56044 8.98514 3.62983 8.99991 3.70001 8.99991C3.77019 8.99991 3.83958 8.98514 3.90367 8.95655C3.96776 8.92797 4.02512 8.88621 4.07201 8.834C6.17701 6.488 7.20001 4.744 7.20001 3.5C7.20001 2.57174 6.83126 1.6815 6.17489 1.02513C5.51851 0.368749 4.62827 0 3.70001 0ZM3.70001 4.5C3.50223 4.5 3.30889 4.44135 3.14444 4.33147C2.97999 4.22159 2.85182 4.06541 2.77613 3.88268C2.70044 3.69996 2.68064 3.49889 2.71923 3.30491C2.75781 3.11093 2.85305 2.93275 2.99291 2.79289C3.13276 2.65304 3.31094 2.5578 3.50492 2.51921C3.6989 2.48063 3.89997 2.50043 4.0827 2.57612C4.26542 2.65181 4.4216 2.77998 4.53148 2.94443C4.64136 3.10888 4.70001 3.30222 4.70001 3.5C4.70001 3.76522 4.59466 4.01957 4.40712 4.20711C4.21958 4.39464 3.96523 4.5 3.70001 4.5Z"
          fill="currentColor"
        />
        <path
          d="M7.20001 16C5.87442 15.9984 4.60357 15.4711 3.66623 14.5338C2.72889 13.5964 2.2016 12.3256 2.20001 11C2.20001 10.7348 2.30537 10.4804 2.49291 10.2929C2.68044 10.1054 2.9348 10 3.20001 10C3.46523 10 3.71958 10.1054 3.90712 10.2929C4.09466 10.4804 4.20001 10.7348 4.20001 11C4.20001 11.7956 4.51608 12.5587 5.07869 13.1213C5.6413 13.6839 6.40436 14 7.20001 14C7.46523 14 7.71958 14.1054 7.90712 14.2929C8.09466 14.4804 8.20001 14.7348 8.20001 15C8.20001 15.2652 8.09466 15.5196 7.90712 15.7071C7.71958 15.8946 7.46523 16 7.20001 16Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_432_1079">
          <rect width="16" height="16" fill="white" transform="translate(0.200012)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

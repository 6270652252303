import { useMutation } from '@apollo/client';
import Button from 'components/Button/Button';
import { FormLabel, StyledFormikField } from 'components/Form/StandardForm';
import { Field, Form, Formik, FormikHelpers, setIn } from 'formik';
import { CreateMroLaborEntryDocument, GetMroEmployeeProfilesQuery, GetMroWorkOrderItemDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { EmployeeSingleSelect } from './EmployeeSelect';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { useSession } from 'contexts';

interface Values {
  costRate: string;
  billableRate: string;
  hours: string;
  startTime: string | Date;
  endTime: string | Date;
}

function differenceInMinutes(date1, date2) {
  const millisecondsDiff = Math.abs(date2 - date1); // Get the absolute difference in milliseconds
  const minutesDiff = millisecondsDiff / (1000 * 60); // Convert milliseconds to minutes
  return minutesDiff;
}
const dateWithZeroSeconds = (new Date());
dateWithZeroSeconds.setSeconds(0);

export default function NewLaborFlyout({
  workOrderItemId,
  isOpen,
  setIsOpen,
  isFlatRate
}: {
  workOrderItemId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isFlatRate?: boolean;
}) {
  const NewPartSchema = yup.object().shape({
    costRate: yup.number().default(0),
    ...(!isFlatRate ? { billableRate: yup.number().required('Required') } : {
      billableRate: yup.number().default(0),
    }),
    hours: yup.number().required('Required'),
    startTime: yup.date(),
    endTime: yup.date(),
  });

  const { user } = useSession();
  const [selectedProfile, setSelectedProfile] = useState<GetMroEmployeeProfilesQuery['mroEmployeeProfiles'][number]>();
  const [createLaborEntry] = useMutation(CreateMroLaborEntryDocument);
  const [initialValues, setInitialValues] = useState({
    costRate: selectedProfile?.costRate ?? '',
    billableRate: selectedProfile?.billableRate ?? '',
    hours: '',
    startTime: dateWithZeroSeconds,
    endTime: dateWithZeroSeconds,
  });

  useEffect(() => {
    setInitialValues(
      {
        ...initialValues,
        costRate: selectedProfile?.costRate ?? '',
        billableRate: selectedProfile?.billableRate ?? '',
      }
    )
  }, [selectedProfile]);

  const handleSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    createLaborEntry({
      variables: {
        input: {
          startTime: new Date(values.startTime),
          endTime: new Date(values.endTime),
          costRate: values.costRate === '' ? '0' : values.costRate,
          billableRate: values.billableRate === '' ? 0 : values.billableRate,
          hours: values.hours,
          mroWorkOrderItem: { connect: { id: workOrderItemId } },
          mroEmployeeProfile: { connect: { id: selectedProfile?.id } },
          mroOrganization: { connect: { id: user?.mroOrganizationId } },
        },
      },
      refetchQueries: [
        {
          query: GetMroWorkOrderItemDocument,
          variables: { id: workOrderItemId },
        },
      ],
    })
      .then(() => {
        setIsOpen(false);
        setSubmitting(false);
        resetForm();
      })
      .catch(console.error);
  };

  return (
    <div className="p-4">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={NewPartSchema}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
              <div className="flex flex-col justify-end w-full px-2 mb-2">
                <span className="text-sm font-medium text-brand"> Technician</span>
                <EmployeeSingleSelect selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} />
              </div>
              <div className="flex flex-col w-full px-2">
                <div className="flex gap-2">
                  <div className="flex flex-col justify-end w-2/5 relative">
                    <FormLabel>Hours</FormLabel>
                    <StyledFormikField id="hours" name="hours" type="number" onChange={ (e)=>{
                      setFieldValue('hours', e.target.value);
                      setInitialValues({ ...initialValues, hours: e.target.value });
                    } } />
                  </div>
                  <div className="flex flex-col w-3/5 relative">
                    <FormLabel>Cost Rate</FormLabel>
                    <StyledFormikField stripStyle type="number" id="costRate" name="costRate" />
                  </div>
                  {!isFlatRate && (
                    <>
                      <div className="flex flex-col w-3/5 relative">
                        <FormLabel>Billable Rate</FormLabel>
                        <StyledFormikField stripStyle type="number" id="billableRate" name="billableRate" />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-6" />
                <div className="flex justify-between">
                  <FormLabel>Total Cost</FormLabel>
                  <div>
                    {Number(+values?.costRate * +values.hours)?.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                    }) ?? 'N/A'}
                  </div>
                  {!isFlatRate && (
                    <>
                      <FormLabel>Total Billable</FormLabel>
                      <div>
                      {Number(+values?.billableRate * +values.hours)?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD',
                      }) ?? 'N/A'}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-between py-2 border-b border-slate-100 mt-10">
                  <FormLabel className="text-sm font-medium text-slate-500">Start Time</FormLabel>
                  <Field as={DateTimePicker} className="w-2/5" name="startTime" onChange={( e )=>{
                    setFieldValue('startTime', e.target.value);
                    const diff = (differenceInMinutes(values.startTime ? new Date(values.endTime) : dateWithZeroSeconds, new Date(e.target.value))/60);
                    setFieldValue('hours', diff > 0 ? diff.toFixed(2) : '0' );
                  } }/>
                </div>
                <div className="flex justify-between py-2 border-b border-slate-100">
                  <FormLabel className="text-sm font-medium text-slate-500">End Time</FormLabel>
                  <Field as={DateTimePicker} className="w-2/5" name="endTime" onChange={( e )=>{
                    setFieldValue('endTime', e.target.value);
                    const diff = (differenceInMinutes(new Date(e.target.value), values.startTime ? new Date(values.startTime) : new Date())/60);
                    setFieldValue('hours', diff > 0 ? diff.toFixed(2) : '0');
                  } } />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center mt-4">
              <Button text="Add Entry" color="navy" size="sm" type="submit" disabled={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import Logo from 'assets/icons/logo.svg';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from 'contexts';
import { useEffect } from 'react';
import IntercomChat from 'components/IntercomChat/IntercomChat';
import isMobileApp from 'utils/isMobileApp';

const Welcome = (props) => {
    const { user } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/auth');
        }
    }, [user]);

    return (
        <>
            <main className="flex md:items-center justify-center min-h-[100vh] bg-brand-gray"> 
                <div className="flex flex-col justify-center items-center w-[28rem] md:border-x border-brand-pale border-dashed h-full">
                    <div className="flex justify-center w-full min-w-[100vw] md:border-y border-brand-pale border-dashed">
                        <div className="w-full rounded mdborder border-slate-300 hover-shadow border-b-4 min-h-[100vh] md:min-h-0 md:w-96 bg-white">
                            <div className="flex flex-col p-10 justify-between h-full pb-20">
                                <div>
                                    <div className="flex self-center justify-center items-center mb-10">
                                        <img alt="logo" className="w-22 py-2" src={Logo} />
                                        <span className="text-5xl font-bold text-brand">coflyt</span>
                                    </div>
                                    <div className="flex flex-col self-center items-center">
                                        <span className="text-2xl font-bold text-brand">Fly & enjoy pilot</span>
                                        <span className="text-2xl font-bold text-brand-electric">peace of mind</span>
                                        <span className="text-sm text-slate-500">Know your aircraft is flight-ready</span>
                                    </div>
                                </div>
                                <div className="flex flex-col self-center items-center w-full md:mb-0 mb-20">
                                    <Button text="Create an Account" color="brand" className='w-full' size="lg" onClick={() => navigate('/sign-up')} />
                                    <Button text="Log In" color="navy" size="lg" className='w-full mb-10' onClick={() => navigate('/auth')} />
                                    <p className="mb-0 mt-4 text-center text-sm ">
                                        By signing up, you agree to our <a href="https://coflyt.com/tos" className='underline text-brand-electric' target="_blank">terms of service</a> and <a href="https://coflyt.com/privacy" className='underline text-brand-electric' target="_blank">privacy policy</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <IntercomChat />
        </>
    );
};

export default Welcome;

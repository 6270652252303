import { useEffect, useState } from 'react';
import ViewInspection from '../Inspections/View';
import { DeleteInspectionGroupDocument, GetInspectionsDocument, GetInspectionsQuery, InspectionDueType } from 'graphql/generated';
import { CheckCircleIcon, ClockIcon, Cog8ToothIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Edit from 'views/Part91/Aircraft/Status/Edit';
import EditInspectionFlyout from '../Inspections/Edit';
import { CalendarDateIcon, CaretDownIcon, CaretRightIcon, PlusIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import NewInspectionFlyout from '../Inspections/New';
import ProgressRing from 'components/ProgressRing/ProgressRing';
import Modal from 'components/Modal/Modal';
import { useMutation } from '@apollo/client';
import { useSession } from 'contexts';
import { LockClosedIcon } from '@heroicons/react/24/outline';


export default function InspectionGroup({
  group,
  updateFlyout,
  closeFlyout,
  disableAdding,
  ...props
} : {
  updateFlyout: (flyoutData: { title: string; content: JSX.Element }) => void;
  closeFlyout: () => void;
  group: {
    icon: ({ className }: { className: any }) => JSX.Element;
    color: string;
    title: string;
    note: string;
    inspections: GetInspectionsQuery['craft']['inspectionGroups'][number]['inspections'];
    id: string;
  };
  disableAdding?: boolean;
} & { [key: string]: any }) {
  const { craftId } = useSession();
  const [isOpen, setIsOpen] = useState(group.inspections.length === 0 ? true : false);
  const [overdue, setOverdue] = useState(0);
  const [upcoming, setUpcoming] = useState(0);
  const [ showModal, setShowModal ] = useState(false);
  const [ showPaywall, setShowPaywall ] = useState(false);
  const [deleteInspectionGroup] = useMutation(DeleteInspectionGroupDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }] });
  useEffect(() => {
    let tempOverdue = 0;
    let tempUpcoming = 0;
    group.inspections.filter((inspection) => inspection?.doesRepeat !== false)?.forEach((inspection) => {      
      if (inspection?.due) {
        tempOverdue +=1;
      } else if (inspection?.almostDue) {
        tempUpcoming += 1;
      }
    });
    setOverdue(tempOverdue);
    setUpcoming(tempUpcoming);
  }, [group.inspections]);
  return (
    <>
      {showModal && <Modal message={`You are about to delete the ${group?.title} inspection group`} cancel={setShowModal} accept={() => deleteInspectionGroup({ variables: { id: group.id } })} /> }
      {showPaywall && <Modal message={`Adding more inspections and importing ADS is a premium feature`} cancel={setShowModal} accept={()=>{}} /> }
      <div className={`${isOpen ? 'shadow-lg' : 'shadow'}`}>
        {/* COLLAPSED VIEW */}
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`flex flex-row justify-between md:items-center mt-2 px-2 md:px-4 p-4 pr-10 md:pr-12 w-full rounded bg-white relative z-10 cursor-pointer`}>
          <div className="flex md:w-3/5 items-center">
            <group.icon className="h-8 w-8 p-2 text-brand-electric bg-brand-pale rounded" />
            <div className="ml-2 flex flex-row justify-center items-center gap-2">
              <h1 className="font-bold text-lg md:text-xl">{group.title}</h1>
              <span className="ml-0 text-sm">{`(${group.inspections.length})`}</span>
            </div>
          </div>
          <div className="flex md:w-2/5 justify-between items-center">
            <span className="hidden md:block ml-0 text-sm">{group.note}</span>
            
            <div className="flex items-center grow md:grow-0">
              <div
                className={`${
                  upcoming ? 'flex' : 'hidden'
                } justify-center items-center h-8 md:w-8 p-2 bg-yellow-100 rounded mx-1 font-semibold text-yellow-500 text-xs md:text-sm`}>
                <span>{upcoming}</span>
              </div>
              <div
                className={`${
                  overdue ? 'flex' : 'hidden'
                } justify-center items-center h-8 md:w-8 p-2 bg-red-100 rounded mx-1 font-semibold text-red-500 text-xs md:text-sm`}>
                <span>{overdue}</span>
              </div>
              <Cog8ToothIcon className="h-8 w-8 md:w-12 p-2 bg-slate-100 rounded" onClick={ (e)=> {
                e.stopPropagation();
                updateFlyout({ title: group.title, content: <Edit id={group.id} closeFlyout={closeFlyout} /> });
              } } />
            </div>
          </div>
          <CaretDownIcon className={`${isOpen ? '' : 'hidden'} h-4 w-4 ml-4 absolute top-6 md:top-auto right-4`} />
          <CaretRightIcon className={`${isOpen ? 'hidden' : ''} h-4 w-4 ml-4 absolute top-6 md:top-auto right-4`} />
        </div>

        {/* OPENED BOX */}
        <div className={`${isOpen ? '' : 'hidden'} bg-slate-100 shadow-inner rounded border-x-8 border-white`}>
          {/* INSPECTIONS */}
         { group.inspections.length !== 0?  <div className="p-2 md:p-4 pb-0 md:pb-2">
            {[...group.inspections].sort((a,b) => a.position - b.position)?.map((inspection, index) => {
              const amountValue = Number(inspection?.amountLeft?.[0]);
              const amountUnit = inspection?.amountLeft?.[1];
              const doesNeedSetup = (!inspection.lastPerformed && !inspection.lastPerformedInterval);
              return (
                <div
                  key={index}
                  onClick={() => { 
                    if(inspection.dueMethod === InspectionDueType.Unknown) return updateFlyout({ title: "Inspection Setup", content: <EditInspectionFlyout id={inspection.id} closeFlyout={closeFlyout} /> });
                    updateFlyout({ title: "Inspection Entries", content: <ViewInspection closeFlyout={closeFlyout} id={inspection.id} /> });
                  }}
                  className="flex flex-col bg-white w-full rounded mb-2 cursor-pointer">
                  <div className="flex p-4 justify-between items-center w-full shadow">
                    <div className="md:w-3/5">
                      <div className='flex'>
                        <h1 className="font-bold text-brand-dark text-lg md:mb-0">{inspection.name}</h1>
                      </div>
                      <div className="flex items-center text-xs md:text-sm font-semibold">
                        { inspection.dueMethod !== InspectionDueType.Interval && <CalendarDateIcon className="h-5 w-5 font-bold" /> }
                        { inspection.dueMethod !== InspectionDueType.Date && <ClockIcon className="h-5 w-5 font-bold" /> }
                        <span className="mx-1">Due: {inspection.nextDueText}{inspection?.airworthinessItem ? "*" : '' }</span>
                      </div>
                    </div>
                    
                    {inspection?.doesRepeat !== false ? 
                      <>
                        { doesNeedSetup ? 
                            <span className="font-bold">Needs Setup</span>
                          : <div className="flex flex-col justify-end items-center">
                            <ProgressRing radius={35} stroke={5} warning={inspection.almostDue} progress={inspection?.nextDuePercentage} value={amountValue}  />
                            <span className="font-bold">{amountUnit}</span> 
                          </div> }
                      </>
                    : <>
                      {inspection.lastPerformed ?
                        <CheckCircleIcon className="h-8 w-8 text-green-500" />
                      :
                        <div className='flex flex-col self-center items-center'>
                          <ExclamationCircleIcon className="h-8 w-8 text-red-500" />
                          { inspection.dueMethod === InspectionDueType.Unknown && <span className="font-bold">Needs Setup</span>}
                        </div>
                      }
                      
                    </>}
                  </div>
                </div>
              );
            })}
          </div> : 
            <div className="p-4 flex justify-center items-center bg-pale w-full rounded">
              <Button text="+ Add First Inspection to Group" size='xs' color='offwhite' onClick={() => {
                if(disableAdding){
                  setShowPaywall(true);
                }else {
                  updateFlyout({ title: 'Add Inspection', content: <NewInspectionFlyout closeFlyout={closeFlyout} groupId={group.id} /> });
                }
              }} /> 
            </div>
          }
          {/* BOTTOM BAR */}
          <div className="py-2 bg-white w-full rounded-b flex justify-end items-center gap-4">
            { group.inspections.length == 0 && <Button 
              onClick={() => setShowModal(true)}
              text="Delete Inspection"
              color='red'
              size='xs'/> }
            
            <Button 
              onClick={() => {
                if(disableAdding){
                  setShowPaywall(true);
                }else {
                  updateFlyout({ title: 'Add Inspection', content: <NewInspectionFlyout closeFlyout={closeFlyout} groupId={group.id} /> });
                }
              }}
              icon={disableAdding ? <LockClosedIcon className="h-3 w-3 mr-1"/> : <PlusIcon className="h-3 w-3 mr-1" /> }
              text="Add Inspection"
              className={disableAdding ? 'opacity-50' : ''}
              color='navy'
              size='xs'/>
          </div>
        </div>
      </div>
    </>
  );
}

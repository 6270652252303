import { useContext, useState } from 'react';
import Button from 'components/Button/Button';
import Logo from 'assets/icons/logo.svg';
import { useLazyQuery } from '@apollo/client';
import { LookupTailDocument } from 'graphql/generated';
import { ServiceFormContext } from '..';

const TailLookup = (props) => {
  const [hasAccount, setHasAccount] = useState(false);
  const { formStep, changeStep, tailNumber, setTailNumber, setTailInfo, user } = useContext(ServiceFormContext);
  const [tailInput, setTailInput] = useState(tailNumber);
  const [lookupTail] = useLazyQuery(LookupTailDocument, { fetchPolicy: 'network-only' });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      const formatedTail = tailInput.startsWith('N') ? tailInput.substring(1) : tailInput;
      const tailInfo = (await lookupTail({ variables: { tailNumber: formatedTail } }))?.data?.registrations?.[0];
      setTailNumber(tailInfo.nNumber); // tail lookup
      setTailInfo(tailInfo);
      setHasAccount(tailInfo.onAccount);
      if (!tailInfo?.onAccount || user) {
        changeStep(formStep, 'craftInfo');
      }
    } catch {
      setTailNumber(tailInput);
      setHasAccount(false);
      changeStep(formStep, 'craftInfo');
    }
  };

  if (tailNumber && hasAccount)
    return (
      <div className="flex flex-col w-96 p-6 h-full justify-center">
        <h2 className="text-3xl md:text-2xl font-bold text-brand">Already have an account?</h2>
        <p className="md:text-sm text-slate-500 mt-2 md:mt-0 mb-8">
          It looks like this aircraft is already tied to an account. Would you like to login now or create a new account?
        </p>
        <div className="flex flex-col">
          <Button onClick={() => changeStep(formStep, 'login')} type="submit" color="navy" size="md" text="Login" />
          <button onClick={() => changeStep(formStep, 'craftInfo')} className="underline text-brand font-semibold text-sm mt-4">
            Create New Account
          </button>
        </div>
      </div>
    );

  return (
    <div className="text-left w-96 flex flex-col p-6 -mt-[40px] md:mt-0 md:p-0">
      <span className="text-3xl md:text-2xl font-bold text-brand mb-8 md:mb-4">Get Started</span>
      <div className="flex flex-col">
        <div
          onClick={() => changeStep(formStep, 'login')}
          className="flex items-center justify-center rounded border border-slate-50 hover:border-brand-electric bg-white hover:bg-brand-pale text-brand mb-4 py-4 shadow-ambient cursor-pointer transition duration-300">
          <img className="h-5" src={Logo} alt="" />
          <span className="pl-1 font-bold">Continue with Coflyt</span>
        </div>
        <div className="flex flex-wrap justify-center my-4">
          <hr className="border-slate-300 w-full" />
          <span className="-mt-4 text-brand font-semibold text-lg bg-white px-4">or</span>
        </div>
        <div>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <label className="text-brand text-sm font-semibold">Tail Number</label>
            <input
              className={`${props.fieldCss} font-bold placeholder:font-normal`}
              value={tailInput}
              placeholder="ex. N123AB"
              required
              onChange={(e) => {
                var tempTail = e.target.value.toUpperCase();
                setTailInput(tempTail.at(1) === 'N' ? tempTail.substring(1) : tempTail); //Remove N in front of tail
              }}
            />
            <Button type="submit" color="navy" size="md" text="Use Tail Number" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default TailLookup;

import { ApolloError, useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { ToastLength } from "components/Toast/Toast";
import { ApplyCouponToUserDocument, CouponType, GetUserCouponDocument, GetUserCouponQuery, RemoveCouponFromUserDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import isMobileApp from "utils/isMobileApp";

const soloFeatureList = [
    'Add Your A&P',
    'Document Upload',
    'Track Fuel & Oil Consumption',
    'Export Reports & Tracked Data',
    'Unlimited Inspections Tracking',
    'Automatically Import & Track ADs',
];

const multiFeatureList = [
    'Reservations & Scheduling',
    'Send Announcements to your Users',
    'Manage Payments & Finances',
    'Track Fuel & Oil Consumption',
    'Unlimited Users & Inspections',
    'Automatically Import & Track ADs',
    'Upload Documents & Export Reports',
];

export interface CBInfo{
    cb_coupon: string;
    cb_status: string;
    cb_plan_id: string;
    cb_plan_name: string;
    cb_customer_id: string;
    cb_plan_amount: string;
    cb_activated_at: string;
    cb_trial_ends_at: string;
    cb_next_billing_at: string;
    cb_subscription_id: string;
    cb_trial_starts_at: string;
    cb_next_billing_amount: string;
    cb_payment_method_present: boolean;
    cb_plan_billing_period_unit: string;
}

const chargebeePlans = process.env.REACT_APP_APP_MODE === 'production' ? {
    SoloMonthly: 'plan_GyUFYb7kZnnH6k',
    SoloYearly: 'plan_GyUFU5SXyX3wuE',
    MultiMonthly: 'plan_GyUGjZUKAdfalD',
    MultiYearly: 'plan_GyUGH7WS4oBT39',
} : {
    SoloMonthly: 'plan_GyUFYb7kZnnH6k',
    SoloYearly: 'plan_GyUFU5SXyX3wuE',
    MultiMonthly: 'plan_FaRmOl35xasPB0',
    MultiYearly: 'plan_GyUGH7WS4oBT39',
}

export default function LegacyPlanPricing({planSelected, isTrial, coupon, showToast} :
    {planSelected: (plan: any) => void, isTrial: boolean, coupon: GetUserCouponQuery['me']['coupon'], showToast: any} ){
    const [ billingMode, setBillingMode ] = useState('Monthly');
    const [ couponCode, setCouponCode ] = useState('');
    const [ applyCode ] = useMutation( ApplyCouponToUserDocument, { refetchQueries: [
        { query: GetUserCouponDocument }
    ] } );
    const [ removeCode ] = useMutation( RemoveCouponFromUserDocument, { refetchQueries: [
        { query: GetUserCouponDocument }
    ] } );
    const couponPrice = ( planType: string, price: number ) => {
        if(coupon){
            if(coupon?.supportedPlans.includes(planType+billingMode)){
                if(coupon?.couponType === CouponType.Percentage){
                    return price - (price * coupon?.discount / 100);
                }else if(coupon?.couponType === CouponType.Amount){
                    return price - coupon?.discount;
                }else if(coupon?.couponType === CouponType.Freedays){
                    return price;
                }
            }
        }
        return;
    };
    let plans = [
        { name: 'Solo', price: billingMode === 'Monthly' ? 17 : 170, discount: billingMode === 'Monthly' ? couponPrice('Solo', 17) : couponPrice('Solo', 170),  
            features: soloFeatureList, imgUrl: 'https://coflyt.com/wp-content/uploads/2019/09/pilot_80x80.png' },
        { name: 'Multi', price: billingMode === 'Monthly' ? 43 : 430, discount: billingMode === 'Monthly' ? couponPrice('Multi', 43) : couponPrice('Multi', 430),  
                 features: multiFeatureList, imgUrl: 'https://coflyt.com/wp-content/uploads/2019/09/pilots_80x80.png' },
    ];
    useEffect(() => {
        plans = plans.map((plan) => {
            return {
                ...plan,
                price: plan.discount ? plan.discount : plan.price
            }
        });
    }, [coupon]);
    return (
        <>
            <Card className='justify-center text-center gap-6 flex flex-col px-20 py-10'>
                <h1 className="text-brand-dark font-bold text-3xl">Upgrade to Coflyt Premium</h1>
                <h2 className="text-slate-500 font-semibold">And get all new ways to simplify management, inspections and maintenance of your aircraft.</h2>
                <div className='self-center'>
                    <SegmentControl items={['Monthly', 'Yearly']} className='w-36' activeItem={billingMode} onChange={(item) => setBillingMode(item)} />
                </div>
            </Card>
            <div className='flex flex-col md:flex-row justify-between gap-10'>
                { plans.map((plan, index) => (
                    <Card key={index} className='text-center gap-3 flex flex-col px-10 flex-grow justify-between w-full'>
                        <div className='items-center flex flex-col'>
                            <img src={plan.imgUrl} height={80} width={80} />
                            <div>
                                <h1 className="text-brand-dark font-bold text-xl">{plan.name} {billingMode === "Yearly" ? 'Annual' : billingMode}</h1>
                                { !isMobileApp() && <h2 className="text-slate-400 font-bold text-xs"><span className='text-4xl text-brand-dark'>${ plan.discount ?? plan.price} {plan.discount && <span className='line-through text-3xl'>{ plan.discount !== plan.price && plan.price}</span>}</span> /{billingMode === "Yearly" ? 'year' : 'monthly'}</h2> }
                            </div>
                            <div className='justify-center mt-5'>
                                <ul className='flex flex-col gap-2 items-center'>
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx} className='flex items-center gap-2'>
                                            <span className='font-bold text-green-500'>✓</span>
                                            <span className='text-slate-400 font-semibold'>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        { !isMobileApp() && <div className='mt-5 bottom-0 bg-brand-dark rounded justify-center text-center mx-3 py-2'>
                            <button onClick={()=> planSelected({...plan, billingMode, id: chargebeePlans[plan.name+billingMode], coupon})} className='text-white font-bold text-lg uppercase'>{ isTrial ? 'Start Free Trial' : 'Subscribe'}</button>
                        </div> }
                    </Card>
                ))}
            </div>
            
            {isTrial && <Card title="Organization Coupon">
                {/* Only show coupon option for new customers */}
                { coupon ? <div className='flex flex-col gap-3'>
                    <span className='font-bold'>Coupon Applied: {coupon.code}</span>
                    <span className='font-semibold text-sm'>{coupon.description}</span>
                    <div className="flex justify-start mt-2">
                        <Button text="Remove Code" color="navy" size="xs" onClick={ async () =>{ 
                            try{
                                await removeCode();
                            }catch(e){
                                console.log(e);
                            }

                        }  } />
                    </div>
                </div> : <>
                    <div className='flex flex-col gap-3'>
                        <input value={couponCode} onChange={(e) => setCouponCode(e.target.value)} placeholder='Coupon Code' className='border border-slate-300 rounded h-8 px-2 uppercase' />
                    </div>
                    <div className="flex justify-start mt-2">
                        <Button text="Apply" color="navy" size="xs" onClick={ async () =>{ 
                            try{
                                await applyCode({ variables:{ couponCode: couponCode.toUpperCase() } })
                            }catch(error ){
                                if(error instanceof ApolloError) {
                                    showToast({
                                      title: 'Error',
                                      subtitle: error.message,
                                      type: ToastLength.Normal
                                    })
                                }
                            }

                        }  } />
                    </div>
                </>}
                
            </Card>}
            { isMobileApp() && <div className='flex flex-col gap-5'>
                <span className='text-center text-slate-400 font-semibold'>You are unable to subscribe or make changes to your plan within the app. We know it's not ideal</span>
            </div>}
        </> 
    );
}
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GetUserInfoDocument, GetRequestDocument } from 'graphql/generated';
import Card from 'components/Card/Card';
import { formatApiDate, formatWithZeros } from 'utils/formatter';
import StatusButton from 'components/StatusButton/StatusButton';
import { Table } from 'components/Table/Table';
import { useNavigate } from 'react-router-dom';
const View = function ({ id, closeFlyout: closeView } : { id: string, closeFlyout: () => void}) {
  const { data: { me } = { } } = useQuery(GetUserInfoDocument);
  const { data : { craftServiceRequest } = { } } = useQuery(GetRequestDocument, { variables: { id } });
  const navigate = useNavigate();
  const estimateColumns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left 2/12 w-full',
        accessor: 'date',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(value)}</span>
            </div>
          );
        },
      },
      
      {
        Header: 'Estimate Number',
        accessor: 'estimateNumber',
        className: 'text-center w-full 2/12 w-full', 
        Cell: ({ row, value }: any) => {
          return (
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm w-full text-center">
              {formatWithZeros(value, 4)}
            </span>
          );
        }   
      },
      {
        Header: 'Status',
        accessor: (row: any) => row?.status,
        className: 'text-center w-full 1/12',
        Cell: ({ row, value }: any) => {
          return (
            <div className="w-full text-left">
              <StatusButton text={value} context='estimate' readOnly={true} className=''  />
            </div>
          );
        }
      },
      {
        Header: 'Total Price',
        accessor: `total`,
        className: 'text-left w-full 2/12',
        Cell: ({ value }) =>
        Number(value)?.toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
        }),
      },
    ],
    []
  );
  const invoiceColumns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left 2/12 w-full',
        accessor: 'invoiceDate',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(value)}</span>
            </div>
          );
        },
      },
      
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        className: 'text-center w-full 2/12 w-full',
        Cell: ({ row, value }: any) => {
          return (
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm w-full text-center">
              {formatWithZeros(value, 4)}
            </span>
          );
        }     
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'text-center w-full 1/12',
        Cell: ({ row, value }: any) => {
          return (
            <div className="w-full text-left">
              <StatusButton text={value === 'SENT'? 'PENDING' : value} context='invoice' readOnly={true} className=''  />
            </div>
          );
        }
      },
      {
        Header: 'Total Price',
        accessor: `amount`,
        className: 'text-left w-full 2/12',
        Cell: ({ value }) =>
          Number(value)?.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
        }),
      },
    ],
    []
  );
  return (
    <>
      <div className="px-4">
        <Card>
          <div className="p-4 flex flex-col w-full gap-2">
            <div className='flex justify-between'>
              <h1 className="font-bold text-lg md:text-xl">{craftServiceRequest?.title}</h1>
            </div>
            <h2 className=''><span className='font-semibold'>Description:</span> {craftServiceRequest?.description}</h2>
            <h3 className='text-sm'><span className='font-semibold'>Request Created:</span> {formatApiDate(craftServiceRequest?.createdAt)}</h3>
            <div className='flex gap-2 items-center'>
                <span className='font-semibold'>Status:</span>
                <div className="flex items-center justify-end">
                    <StatusButton text={craftServiceRequest?.status} context='craftRequest' readOnly={true} className=''  />
                </div>
            </div>
            <h3 className='text-sm'><span className='font-semibold'>Shop:</span> {craftServiceRequest?.mroOrganizationName}</h3>
            {craftServiceRequest?.mroOrganizationPhone && <a href={`tel:${craftServiceRequest?.mroOrganizationPhone}`}><h3 className='text-sm underline ml-10'>{craftServiceRequest?.mroOrganizationPhone}</h3></a>}
          </div>
        </Card>
        <Card title='Estimates'>
          { craftServiceRequest?.estimates?.length > 0 ?  <Table columns={estimateColumns} data={craftServiceRequest?.estimates ?? []} onRowClick={(row) => {
            const token = craftServiceRequest?.estimateTokens?.[row?.id];
            if(row?.status === 'PENDING' && token){
              const encodedToken = encodeURIComponent(token);
              window.open(window.location.origin+`/mro/service-request/${row?.id}/${encodedToken}`, '_blank').focus();  
            }else{
              window.open(row.pdfLink, '_blank').focus();  
            }
          } } /> : <div className='p-4 text-center'>No Estimates Sent</div> }
        </Card>
        <Card title='Invoices'>
          { craftServiceRequest?.invoices?.length > 0 ? <Table columns={invoiceColumns} data={craftServiceRequest?.invoices ?? []} onRowClick={(row) => {
            const token = craftServiceRequest?.invoiceTokens?.[row?.id];
            if(row?.status === 'SENT' && token){
              const encodedToken = encodeURIComponent(token);
              window.open(window.location.origin+`/mro/service-request/invoice/${row?.id}/${encodedToken}`, '_blank').focus();  
            }else{
              window.open(row.pdfLink, '_blank').focus();
            }
          } } /> : <div className='p-4 text-center'>No Invoices Sent</div> }
        </Card>
      </div>
    </>
  );
};

export default View;

import { useState } from 'react';
import { TrashIcon, PencilSquareIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { formatToFixed, formatUSD } from 'utils/formatter';

export const severityOptions = {
  DISCRETIONARY: {
    label: 'Discretionary',
    textColor: 'text-brand-electric',
    bgColor: 'bg-brand-pale',
    drop: 'border-brand-electric/20 group-hover:bg-brand-electric/10',
  },
  RECOMMENDED: {
    label: 'Recommended',
    textColor: 'text-white',
    bgColor: 'bg-amber-500',
    drop: 'border-amber-600 group-hover:bg-amber-600',
  },
  REQUIRED: { label: 'Required', textColor: 'text-white', bgColor: 'bg-red-600', drop: 'border-red-600 group-hover:bg-red-700' },
};

export default function NewEstimateItem({ item, index, handleRemoveItem, handleItemUpdate }) {
  const [contents, setContents] = useState({ ...item });
  const [checked, setChecked] = useState(contents.active);
  const [isEditable, setIsEditable] = useState(contents.temp && !contents.legacy);
  const isWarning = contents.severity === 'RECOMMENDED';
  const isRequired = contents.severity === 'REQUIRED';
  const handleChange = function (e, kind?, ndx = 0) {
    let mutable = { ...item };
    if (kind === 'desc' || kind === 'title' || kind === 'severity') {
      mutable[kind] = e.target.value;
    } else if (kind === 'partPriceChange') {
      mutable.parts[ndx].unitPrice = e.target.value;
      const part = mutable.parts[ndx];
      mutable.parts[ndx].subtotal = part.unitPrice * part.quantity;
    } else if (kind === 'partQuantityChange') {
      mutable.parts[ndx].quantity = e.target.value;
      const part = mutable.parts[ndx];
      mutable.parts[ndx].subtotal = part.unitPrice * part.quantity;
    } else if (kind === 'active') {
      mutable[kind] = !checked;
      setChecked(!checked);
    } else if (kind === 'isFlatRate') {
      mutable[kind] = !mutable[kind];
    } else {
      mutable[kind] = Number(e.target.value);
    }
    mutable.subTotal = mutable.laborCost + mutable.partsCost;
    setContents(mutable);
    handleItemUpdate(index, mutable);
  };
  
  return (
    <div
      key={item.id}
      className={`
        ${isWarning && checked ? 'bg-amber-50 border-amber-400 text-amber-900 font-bold' : 'hover:bg-slate-50/50 font-semibold'} 
        ${isRequired && checked ? 'bg-red-50 border-red-900 text-red-900 font-bold' : 'hover:bg-slate-50/50 font-semibold'} 
        ${!isRequired && !isWarning && checked && 'bg-white'} 
        ${
          !checked && 'bg-white opacity-50 hover:opacity-100'
        } flex flex-col px-2 my-4 first:mt-0 relative border-slate-200 border-dashed border-b last:border-b-0 first:rounded-t last:rounded-b`}>
      <div className="flex justify-between py-2">
        <div>
          {!isEditable && (
            <input
              type="checkbox"
              onClick={(e) => handleChange(e, 'active')}
              className="mt-1 rounded h-3.5 w-3.5 mr-0.5 cursor-pointer"
              onChange={() => {}}
              id={item.id}
              name={item.id}
              checked={checked}
            />
          )}
          <p></p>
        </div>
        
        <div className="flex gap-2">
          <div className="flex items-stretch relative w-36 h-6 group">
            <select
              onChange={(e) => {
                handleChange(e, 'severity');
              }}
              className="absolute w-full h-full z-10 opacity-0 cursor-pointer p-0"
              value={contents.severity}>
              {Object.keys(severityOptions).map((option) => (
                <option key={option} value={option} className="text-black font-normal">
                  {severityOptions[option].label}
                </option>
              ))}
            </select>
            <div
              className={`${severityOptions[contents.severity]?.textColor} ${severityOptions[contents.severity]?.bgColor} text-sm flex font-semibold justify-center items-center rounded w-full relative`}>
              <span className="flex w-full justify-center py-1 px-3 items-center select-none capitalize">
                {severityOptions[contents.severity]?.label}
              </span>
              <ChevronDownIcon className={`${severityOptions[contents.severity]?.drop} border-l h-full w-6 py-[6.075px] p-1 rounded-r`} />
            </div>
          </div>
          {isEditable ? (            
            <PencilSquareIcon
              onClick={(e) => {
                e.preventDefault();
                if (contents.title) {
                  setIsEditable(!isEditable);
                }
              }}
              title="Edit"
              className="bg-brand-electric text-brand-pale w-12 h-6 p-0.5 border border-brand-pale rounded cursor-pointer transition hover:opacity-80"
            />
          ) : (
            <PencilSquareIcon
              onClick={(e) => {
                e.preventDefault();
                setIsEditable(!isEditable);
              }}
              title="Edit"
              className="text-brand-electric bg-white hover:bg-brand-pale w-12 h-6 p-0.5 cursor-pointer border border-brand-pale rounded transition"
            />
          )}
          {contents.temp && (
            <TrashIcon
              onClick={() => {
                handleRemoveItem(index);
              }}
              title="Delete"
              className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
            />
          )}
        </div>
      </div>
      <div
        className="cursor-pointer py-3"
        onClick={(e) => {
          if (isEditable) return;
          handleChange(e, 'active');
        }}>
        <div
          className={
            `${isEditable && 'bg-brand-offwhite'}` +
            'flex flex-col peer-hover:border-l-4 peer-hover:-ml-[calc(2rem+4px)] peer-hover:-m-8 peer-hover:p-8 peer-hover:-mb-4 peer-hover:pb-4 transition'
          }>
          {isEditable && (
            <div className="flex justify-end">
              <label className="flex items-center">
                <span className="mr-2">Bill Flat Rate</span>
                <input
                  type="checkbox"
                  onClick={(e) => handleChange(e, 'isFlatRate')}
                  className="rounded h-3.5 w-3.5 mr-0.5 cursor-pointer"
                  onChange={() => {}}
                  id={item.id}
                  name={item.id}
                  checked={contents.isFlatRate}
                />
              </label>
            </div>
          )}
          <div className="flex justify-between">
            <div className="flex flex-col text-sm">
              <span className="font-semibold">Line Item</span>
              {isEditable ? (
                <input
                  className="border shadow-inner h-8 px-2"
                  defaultValue={contents.title}
                  onChange={(e) => handleChange(e, 'title')}
                />
              ) : (
                <span className="-mt-0.5">{contents.title}</span>
              )}
            </div>
            <div className='flex md:flex-row flex-col justify-center gap-3'>
              {!contents.isFlatRate && (
                <div className="flex flex-col text-sm items-end">
                  <span className="font-semibold">Hours</span>
                  {isEditable ? (
                    <input
                      type="number"
                      className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                      defaultValue={contents.laborHours}
                      onChange={(e) => handleChange(e, 'laborHours')}></input>
                  ) : (
                    <span className="-mt-0.5">{contents.laborHours}</span>
                  )}
                </div>
              )}
              <div className="flex flex-col text-sm items-end">
                <span className="font-semibold">Subtotal</span>
                {isEditable ? (
                  <input
                    type="number"
                    className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                    defaultValue={item.laborCost}
                    onChange={(e) => handleChange(e, 'laborCost')}></input>
                ) : (
                  <span className="-mt-0.5">{formatToFixed(item.laborCost)}</span>
                )}
              </div>
            </div>
          </div>
          <div className={`${!isEditable && !contents.desc ? 'hidden' : 'flex'} flex-col text-sm bg-slate-50 border px-4 py-2 mt-2`}>
            <span className="text-xs text-brand-dark font-semibold uppercase">Description</span>
            {isEditable ? (
              <textarea
                className="border-slate-200 p-1 text-xs focus:ring-0 placeholder:text-brand/50 shadow-inner"
                onChange={(e) => handleChange(e, 'desc')}
                defaultValue={contents.desc}
                placeholder="Begin typing description..."
              />
            ) : (
              <span className="text-xs py-1">{contents.desc}</span>
            )}
          </div>
          <table className={`${contents.parts?.length === 0 ? '-mt-2 invoice-thead' : 'my-4 bg-white'} rounded w-full`}>
            <thead className="border border-b-0">
              <tr className="text-left text-sm border-b">
                <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
                <th className="px-2 font-semibold w-36">Part #</th>
                <th className="px-2 font-semibold">Description</th>
                <th className="px-2 font-semibold text-right">Unit Price</th>
                <th className="px-2 font-semibold text-right">Subtotal</th>
              </tr>
            </thead>
            <tbody className="border">
              {contents.parts.map((part, ndx) => {
                return (
                  <tr className="text-sm" key={ndx}>
                    {isEditable ? (
                      <input
                        type="number"
                        className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                        defaultValue={part.quantity}
                        onChange={(e) => handleChange(e, 'partQuantityChange', ndx)}></input>
                    ) : (
                      <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                    )}
                    <td className="align-top px-2">{part.partNumber}</td>
                    <td className="align-top px-2">{part.description}</td>
                    {isEditable ? (
                      <input
                        type="number"
                        className="border-slate-200 shadow-inner w-5/6 text-right h-8 px-2 no-spinner"
                        defaultValue={part.unitPrice}
                        onChange={(e) => handleChange(e, 'partPriceChange', ndx)}></input>
                    ) : (
                      <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                    )}

                    <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
                  </tr>
                );
              })}
              {!contents.temp && (
                <>
                  <tr className="text-sm border-t bg-slate-50">
                    <td></td>
                    <td></td>
                    <td className="px-2 font-medium">Parts Cost:</td>
                    <td></td>
                    <td className="px-2 text-right">{formatToFixed(contents.partsCost)}</td>
                  </tr>
                  <tr className="text-sm bg-slate-50">
                    <td></td>
                    <td></td>
                    <td className="px-2 font-medium">Labor Cost:</td>
                    <td></td>
                    <td className="px-2 text-right">{formatToFixed(item.laborCost)}</td>
                  </tr>
                </>
              )}
              <tr className="text-sm border-t bg-slate-100">
                <td></td>
                <td></td>
                <td className="px-2 font-bold">Item Subtotal:</td>
                <td></td>
                <td className="px-2 text-right font-bold">{formatUSD(item.subTotal)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import { useContext, useState } from 'react';
import { GearIcon } from 'assets/icons';
import { useSession } from 'contexts';

import Button from 'components/Button/Button';
import ProfileSettings from './Partials/ProfileSettings';
import Tabs from 'components/Tabs/Tabs';
import NotificationsSettings from './Partials/NotificationsSettings';
import AccountSettings from './Partials/AccountSettings';
import WorkOrderSettings from './Partials/WorkOrderSettings/index';
import Roles from './Roles';
import { AbilityContext } from "contexts/AbilityContext";
import PartSettings from './Partials/PartSettings';
import OrganizationSettings from './Partials/OrganizationSettings/index';

const Settings = function () {
  const ability = useContext(AbilityContext);
  const [activeTab, setActiveTab] = useState('Profile');
  const tabs = ['Profile', 'Notifications', 'Account', 
  ...(ability.can('Manage', 'Organization') ? ['Organization'] : []),
  ...(ability.can('Manage', 'Work Orders') ? ['Work Orders'] : []),
  ...(ability.can('Manage', 'Parts') ? ['Parts'] : []),
  ...(ability.can('Manage', 'Roles') ? ['Roles'] : [])];
  
  return (
    <>
      {/* HEADER */}
      <div className="flex flex-col bg-white border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center p-6">
          <GearIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Settings</h1>
        </div>
        <div className="flex items-start bg-slate-50 px-6 rounded-b border-t">
          <Tabs items={tabs} setActiveItem={setActiveTab} activeItem={activeTab} />
        </div>
      </div>
      {/* PROFILE */}
      <div hidden={activeTab !== 'Profile'} className="bg-white border border-slate-300 rounded shadow-blue">
        <ProfileSettings />
      </div>
      {/* NOTIFICATIONS */}
      <div hidden={activeTab !== 'Notifications'} className="bg-white border border-slate-300 rounded">
        <NotificationsSettings />
      </div>
      {/* ACCOUNT */}
      <div hidden={activeTab !== 'Account'} className="bg-white border border-slate-300 rounded">
        <AccountSettings />
      </div>
      {/* ORGANIZATION */}
      <div hidden={activeTab !== 'Organization'}>
        <OrganizationSettings />
      </div>
      {/* WORK ORDER */}
      <div hidden={activeTab !== 'Work Orders'}>
        <WorkOrderSettings />
      </div>
      {/* PARTS */}
      <div hidden={activeTab !== 'Parts'} className="bg-white border border-slate-300 rounded">
        <PartSettings />
      </div>
      {/* Roles */}
      <div hidden={activeTab !== 'Roles'} className="bg-white border border-slate-300 rounded">
        <Roles />
      </div>
    </>
  );
};

export default Settings;

import Button from 'components/Button/Button';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import { Form, Formik } from 'formik';

export function AddTrackingInfo({ trackingInfo, setTrackingInfo }: { trackingInfo: any; setTrackingInfo: any }) {
  const initialValues = {
    trackingNumber: trackingInfo?.trackingNumber ?? '',
    carrier: trackingInfo?.carrier ?? '',
  };

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} onSubmit={setTrackingInfo} enableReinitialize>
        {({ values }) => (
          <Form>
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className="flex gap-1">
                <FullFormikSelect name="carrier" label="Carrier" className="w-2/6">
                  <option value="">N/A</option>
                  <option value="USPS">USPS</option>
                  <option value="FEDEX">Fedex</option>
                  <option value="UPS">UPS</option>
                  <option value="DHL">DHL</option>
                </FullFormikSelect>
                <FullFormikInput name="trackingNumber" label="Tracking #" className="w-4/6" />
              </div>
            </div>
            <div className="flex justify-end items-center mt-4 gap-2">
              <Button text="Mark as Ordered" color="navy" size="xs" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

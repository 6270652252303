import { useState, useEffect } from 'react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

export default function LogEntryRow({ description, index, expandAll, key, itemNumber }) {
  const [isExpanded, setIsExpanded] = useState(expandAll);

  useEffect(() => {
    setIsExpanded(expandAll);
  }, [expandAll]);

  return (
    <div
      key={key}
      className={`border-brand-pale group flex justify-between items-start p-2 -mx-2 px-4 border-b border-dashed last:border-b-0`}>
      <div className="flex flex-col w-full">
        <div onClick={() => setIsExpanded(!isExpanded)} className={`flex justify-between items-center cursor-pointer`}>
          <h2 className={`flex items-center text-sm leading-4 relative py-2 gap-1`}>
            {isExpanded ? (
              <ChevronDownIcon className="h-3 w-3 group-hover:opacity-70 transition" />
            ) : (
              <ChevronRightIcon className="h-3 w-3 group-hover:opacity-70 transition" />
            )}
            Squawk {itemNumber}
          </h2>
        </div>

        {isExpanded && (
          <div className="border-dashed text-sm px-4 pb-2 -mx-4 mt-2">
            <div className={`border-slate-200 border px-4 py-2 rounded`}>
              <span className="text-xs text-brand-dark font-semibold uppercase">Description</span>
              <p className="text-gray-700">{description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
const selectionClass =
  'flex items-center group grow bg-white text-brand-dark cursor-pointer p-2 mb-1 w-full relative rounded border border-dashed border-white border-b-brand-pale last:border-b-white transition-colors hover:border-brand-pale hover:bg-brand-pale/20 hover:border-solid disabled:border-solid disabled:text-brand-electric disabled:bg-brand-pale disabled:border-brand-electric';

// STEP 3
const ContactConfirmation = function ({ mroContacts, setSelectedContact, selectedContact, setStep, closeFlyout }) {
  return (
    <div className="flex flex-col">
      <h2 className="text-xl font-bold text-brand">Select a Contact</h2>
      <span className="text-sm text-slate-500 mb-2">Who should receive notifications for this work order?</span>
      <div className="h-64 overflow-y-auto shadow-inner rounded p-2 border border-slate-200">
        {mroContacts?.map((contact) => (
          <button
            key={contact.id}
            type="button"
            onClick={(e) => {
              setSelectedContact(contact);
            }}
            disabled={selectedContact?.id === contact.id}
            className={selectionClass}>
            <div className="flex">
              <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale group-disabled:bg-brand-electric group-disabled:text-white border-brand-electric w-9 h-9 rounded mr-2">
                {contact.firstName[0]}
              </div>
              <div className="flex flex-col items-start">
                <span className="font-bold truncate">{contact.firstName + ' ' + contact.lastName}</span>
                <span className="truncate text-sm text-slate-500 -mt-1">{contact.email}</span>
              </div>
            </div>
            {selectedContact?.id === contact.id && (
              <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 pointer-events-none" />
            )}
          </button>
        ))}
        <button
          type="button"
          onClick={(e) => {
            setSelectedContact('newContact');
          }}
          disabled={selectedContact === 'newContact'}
          className={selectionClass + ' mt-1'}>
          <div className="flex">
            <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale group-disabled:bg-brand-electric group-disabled:text-white border-brand-electric w-9 h-9 rounded mr-2">
              <PlusIcon className="h-4 w-4" />
            </div>
            <div className="flex items-center">
              <span className="font-bold truncate">Add New Contact</span>
            </div>
          </div>
          {selectedContact === 'newContact' && (
            <CheckIcon className="h-5 w-5 ml-1 text-brand-electric absolute right-3 pointer-events-none" />
          )}
        </button>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => {
            setStep(2);
            closeFlyout();
          }}
          className="underline text-brand font-semibold text-sm">
          Go Back
        </button>
        <button
          onClick={() => {
            if (mroContacts?.find((cntct) => cntct.id === selectedContact.id)) {
              setStep(4);
              closeFlyout();
            } else {
              setStep(98);
            }
          }}
          disabled={!selectedContact}
          className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-2 px-4 disabled:opacity-50">
          Continue
        </button>
      </div>
    </div>
  );
};

export default ContactConfirmation;

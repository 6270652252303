import { useMemo } from 'react';
import { Column } from 'react-table';
import { CogIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Table } from 'components/Table/Table';
import { formatApiDate, formatUSD } from 'utils/formatter';
import NewPartFlyout from '../NewPartFlyout';
import { useQuery, useMutation } from '@apollo/client';
import { GetMroWorkOrderItemDocument, UpdateMroWorkOrderPartDocument } from 'graphql/generated';
import StatusButton from 'components/StatusButton/StatusButton';
import PartDetailFlyout from '../PartDetailFlyout';
export const PartsSection = function ({ updateFlyout, flyoutProps, itemId, context, locked = false }) {
  const { data: { mroWorkOrderItem } = {} } = useQuery(GetMroWorkOrderItemDocument, {
    variables: { id: itemId },
    skip: !itemId,
    returnPartialData: true,
  });
  const [updateOrderPart] = useMutation(UpdateMroWorkOrderPartDocument, {
    refetchQueries: [{ query: GetMroWorkOrderItemDocument, variables: { id: itemId } }],
  });
  /* PART ENTRY TABLE HANDLING SECTION */
  const tableData = useMemo(() => {
    if (!mroWorkOrderItem) return [];
    return mroWorkOrderItem.mroWorkOrderParts;
  }, [mroWorkOrderItem]);

  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Needed By',
        id: 'date',
        accessor: (row) => row.neededDate ?? '', // accessor is the "key" in the data
        className: 'w-2/12 text-xs text-slate-500 border-dashed border-brand-pale',
        Cell: ({ value, row }: any) => {
          return <span className="font-semibold">{formatApiDate(value)}</span>;
        },
      },
      {
        Header: () => <div className="mx-[-10px]">Part #</div>,
        id: 'partNumber',
        accessor: (row) => {
          return {
            partNumber: row.partNumber ?? '',
            partDescription: row.partDescription ?? '',
            mroPart: row?.mroPart,
            id: row.id,
          };
        }, // accessor is the "key" in the data
        className: `${
          context !== 'dashboard' ? 'w-3/12' : 'w-6/12'
        } text-xs text-slate-500 border-dashed border-brand-pale justify-start content-start`,
        Cell: ({ value, row }: any) => {
          if (value) {
            return (
              <div className="mx-[-10px]">
                <div className="text-md font-bold">{value.partNumber}</div>
                <div className="">{value?.mroPart ? value?.mroPart?.description : value?.partDescription ?? '' }</div>
              </div>
            );
          } else {
            return <span className="">-</span>;
          }
        },
      },
      {
        Header: () => <div>Quantity</div>,
        id: 'quantity',
        accessor: (row) => row.quantity ?? '', // accessor is the "key" in the data
        className: 'w-1/12 text-xs text-slate-500 border-dashed border-brand-pale justify-end',
        Cell: ({ value, row }: any) => {
          value === '' && (value = '-');
          return <span className="">{value}</span>;
        },
      },

      ...(context !== 'dashboard'
        ? [
            {
              Header: 'Unit Price',
              id: 'unitPrice',
              accessor: (row) => row.billableRate ?? row.unitPrice, // accessor is the "key" in the data
              className: 'w-1/12 justify-end text-xs text-slate-500 border-dashed border-brand-pale',
              Cell: ({ value, row }: any) => {
                return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
              },
            },
            {
              Header: 'Total Price',
              id: 'totalPrice',
              accessor: (obj) => obj.billableRate * obj.hours || obj.quantity * obj.unitPrice, // accessor is the "key" in the data
              className: 'w-2/12 justify-end text-xs text-slate-500 font-bold border-dashed border-brand-pale',
              Cell: ({ value, row }: any) => {
                return <span className="">{Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? 'N/A'}</span>;
              },
            },
          ]
        : []),
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status', // accessor is the "key" in the data
        className: 'w-3/12',
        Cell: ({ value, row }: any) => {
          return (
            <StatusButton
              readOnly={true}
              context="parts"
              text={value}
              className=""
              onSelect={(status) =>
                updateOrderPart({
                  variables: {
                    input: {
                      id: row.original.id,
                      status,
                    },
                  },
                })
              }
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="flex flex-col justify-between w-full border rounded-b rounded-r shadow-blue relative bg-white border-slate-200">
        <div className="p-4">
          <div className="flex items-center gap-2 w-full bg-slate-50 border rounded border-slate-200 p-1">
            <CogIcon className="h-7 w-7 bg-brand-pale text-brand-electric rounded p-1" />
            <h2 className="text-brand-dark text-left font-bold">Parts</h2>
          </div>
          <div className="">
            {tableData?.length ? (
              <Table
                data={tableData}
                columns={columns}
                onRowClick={(row) => {
                  if(locked) return;
                  updateFlyout({
                    title: 'Part #' + (row.partNumber??''),
                    content: <PartDetailFlyout context={context} partId={row.id} workOrderItemId={itemId} {...flyoutProps} />,
                  });
                }}
              />
            ) : (
              <div className="flex text-sm font-medium bg-brand-offwhite border border-brand-pale rounded p-2 mt-2 text-slate-500 mx-2">
                { !locked && <button
                  className="flex items-center font-semibold text-brand cursor-pointer w-full"
                  onClick={() =>
                    updateFlyout({
                      title: 'Add Part',
                      content: <NewPartFlyout context={context} workOrderItemId={itemId} workOrderId={mroWorkOrderItem?.mroWorkOrder?.id} {...flyoutProps} />,
                    })
                  }>
                  <PlusIcon className="h-2.5 w-2.5 mr-1" />
                  Add a part
                </button> }
              </div>
            )}
          </div>
        </div>
        {context !== 'dashboard' && (
          <div className="flex gap-4 items-center justify-end border-t border-slate-200 rounded-b px-4 py-1.5 text-sm pl-12">
            <div className="flex items-center gap-2">
              <span className="font-bold text-brand">Total Parts Billable:</span>
              <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded">
                {formatUSD(tableData?.reduce((acc, cur) => acc + cur.quantity * cur.unitPrice, 0))}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

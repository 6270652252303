import React from 'react';

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.19999 13.4L4.79999 12L8.79999 8L4.79999 4L6.19999 2.6L11.6 8L6.19999 13.4Z" fill="currentColor" />
    </svg>
  );
};

export default Icon;

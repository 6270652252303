import { CustomerSingleSelect } from './CustomerSingleSelect';

// STEP 2
const SelectUser = function ({ setStep, tailNumber, mroCustomers, selectedCustomer, setSelectedCustomer }) {
  const handleSubmit = () => {
    if (mroCustomers.find((cust) => cust.id === selectedCustomer.id)) {
      setStep(3);
    } else if (selectedCustomer.id) {
      setStep(96);
    } else {
      setStep(97);
    }
  };
  return (
    <form className="flex flex-col" onSubmit={() => handleSubmit()}>
      <h2 className="text-xl font-bold text-brand">Select a Customer</h2>
      <span className="text-sm text-slate-500 mb-2">Whose aircraft is being serviced?</span>
      {/* <div className="h-64 overflow-y-auto shadow-inner rounded p-2 border border-slate-200 mt-4"> */}
      <CustomerSingleSelect
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        tailNumber={tailNumber}
        initialCustomers={mroCustomers}
      />
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => {
            setStep(1);
          }}
          className="underline text-brand font-semibold text-sm">
          Go Back
        </button>
        <div className='flex gap-2'>
          <button
            onClick={() => setStep(99)}
            className="flex items-center text-sm rounded font-semibold py-2 px-4 disabled:opacity-50">
            Skip
          </button>
          <button
            onClick={handleSubmit}
            disabled={!selectedCustomer}
            className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-2 px-4 disabled:opacity-50">
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default SelectUser;

export function isActive(status: string) {
  return ['OPEN', 'IN_PROGRESS', 'HOLD', 'AWAITING_PARTS'].includes(status);
}
export function isClosed(status: string) {
  return ['COMPLETED', 'CLOSED', 'CANCELLED'].includes(status);
}
export function isUpcoming(status: string) {
  return ['DRAFT', 'SCHEDULED'].includes(status);
}
export function webFormat(status?: string) {
  if (!status) return '';
  return status
    .replaceAll('_', ' ')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export const defCase = {
  IN_PROGRESS: {
    textColor: 'text-white/90',
    bgColor: 'bg-brand-electric',
    drop: 'border-blue-800 group-hover:bg-black/10',
  },
  COMPLETED: {
    textColor: 'text-white',
    bgColor: 'bg-emerald-500',
    drop: 'border-emerald-600 group-hover:bg-emerald-600',
  },
};
export const orderCase = {
  DRAFT: { textColor: 'text-slate-500', bgColor: 'bg-white border border-slate-500', drop: 'border-slate-500 group-hover:bg-slate-100' },
  PENDING_SCHEDULE: {
    textColor: 'text-white',
    bgColor: 'bg-orange-500',
    drop: 'bg-orange-500/20 group-hover:bg-orange-500/10',
  },
  SCHEDULED: {
    textColor: 'text-brand-electric',
    bgColor: 'bg-brand-pale',
    drop: 'border-brand-electric/20 group-hover:bg-brand-electric/10',
  },
  HOLD: { textColor: 'text-white', bgColor: 'bg-amber-500', drop: 'border-amber-600 group-hover:bg-amber-600' },
  AWAITING_PARTS: { textColor: 'text-white', bgColor: 'bg-violet-500', drop: 'border-violet-600 group-hover:bg-violet-600' },
  ...defCase,
  CLOSED: { textColor: 'text-slate-500', bgColor: 'bg-slate-100' },
};
export const taskCase = {
  NOT_STARTED: {
    textColor: 'text-slate-500',
    bgColor: 'bg-slate-100',
    drop: 'border-slate-500/20 group-hover:bg-slate-200 ',
  },
  PREPARING: {
    textColor: 'text-brand-electric',
    bgColor: 'bg-brand-pale',
    drop: 'border-brand-electric/20 group-hover:bg-brand-electric/10',
  },
  AWAITING_SIGNOFF: {
    textColor: 'text-white',
    bgColor: 'bg-violet-500',
    drop: 'border-violet-600 group-hover:bg-violet-600',
  },
  STALLED: {
    textColor: 'text-white',
    bgColor: 'bg-amber-500',
    drop: 'border-amber-600 group-hover:bg-amber-600',
  },
  ...defCase,
  CUSTOMER_APPROVED: {
    textColor: 'text-white text-xs',
    bgColor: 'bg-lime-500',
    drop: 'border-lime-600 group-hover:bg-lime-600',
  },
  CUSTOMER_DENIED: {
    textColor: 'text-white',
    bgColor: 'bg-rose-500',
    drop: 'border-rose-500/20 group-hover:bg-rose-400 ',
  },
  ABORT: {
    textColor: 'text-slate-500',
    bgColor: 'bg-slate-100',
    drop: 'border-slate-500/20 group-hover:bg-slate-200 ',
  },
};
export const partsCase = {
  DRAFT: {
    textColor: 'text-slate-500',
    bgColor: 'bg-white border border-slate-500',
    drop: 'border-slate-500 group-hover:bg-slate-100',
  },
  AWAITING_APPROVAL: {
    textColor: 'text-white',
    bgColor: 'bg-orange-500 border border-rose-500/20',
    drop: 'border-slate-500 group-hover:bg-slate-100',
  },
  REQUESTED: {
    textColor: 'text-slate-500',
    bgColor: 'bg-slate-100',
    drop: 'border-slate-500/20',
  },
  PENDING: {
    textColor: 'text-yellow-500',
    bgColor: 'bg-yellow-100',
    drop: 'border-yellow-500/20',
  },
  AWAITING_BACKORDER: {
    textColor: 'text-white',
    bgColor: 'bg-brand-electric',
    drop: 'border-purple-500/20',
  },
  PENDING_BACKORDER: {
    textColor: 'text-brand-electric',
    bgColor: 'bg-brand-pale',
    drop: 'border-purple-500/20',
  },
  BACKORDER_CREATED: {
    textColor: 'text-cyan-500',
    bgColor: 'bg-cyan-100',
    drop: 'border-cyan-500/20',
  },
  READY_FOR_PICKUP: {
    textColor: 'text-white',
    bgColor: 'bg-brand',
    drop: 'border-brand',
  },
  PICKED_UP: {
    textColor: 'text-slate-500',
    bgColor: 'bg-white border border-slate-500',
    drop: 'border-slate-500 group-hover:bg-slate-100',
  },
  INSTALLED: {
    textColor: 'text-white',
    bgColor: 'bg-emerald-500',
    drop: 'bg-emerald-400',
  },
  CANCELLED: {
    textColor: 'text-rose-500',
    bgColor: 'bg-rose-100',
    drop: 'border-rose-500/20',
  },
};

export const signoffCase = {
  NOT_READY: {
    textColor: 'text-slate-500',
    bgColor: 'bg-slate-100',
    drop: 'border-slate-500/20 group-hover:bg-slate-200 ',
  },
  AWAITING_SIGNOFF: {
    textColor: 'text-white',
    bgColor: 'bg-violet-500',
    drop: 'border-violet-600 group-hover:bg-violet-600',
  },
  SIGNED_OFF: {
    textColor: 'text-white',
    bgColor: 'bg-emerald-500',
    drop: 'border-emerald-600 group-hover:bg-emerald-600',
  },
};
export const requestCase = {
  OPEN: { textColor: 'text-slate-500', bgColor: 'bg-white border border-slate-500', drop: 'border-slate-500 group-hover:bg-slate-100' },
  CLOSED: { textColor: 'text-slate-500', bgColor: 'bg-slate-100' },
};
export const estimateCase = {
  UNAPPROVED: { textColor: 'text-slate-400', bgColor: 'bg-slate-100' },
  PENDING: { textColor: 'text-slate-400', bgColor: 'bg-slate-100' },
  APPROVED: { textColor: 'text-white', bgColor: 'bg-emerald-500' },
  DENIED: { textColor: 'text-white', bgColor: 'bg-rose-500' },
  PARTIAL_APPROVED: { textColor: 'text-white', bgColor: 'bg-amber-500' },
  VOID: { textColor: 'text-white', bgColor: 'bg-brand-purple' },
};

export const logEntryCase = {
  INCOMPLETE: { textColor: 'text-slate-400', bgColor: 'bg-slate-100' },
  SIGNED: { textColor: 'text-white', bgColor: 'bg-emerald-500' },
  VOID: { textColor: 'text-white', bgColor: 'bg-brand-purple' },
};

export const invoiceCase = {
  PAID: { textColor: 'text-white', bgColor: 'bg-emerald-500' },
  SENT: { textColor: 'text-brand-electric', bgColor: 'bg-brand-pale' },
  PENDING: { textColor: 'text-rose-500', bgColor: 'bg-rose-100 ' },
  VOID: { textColor: 'text-white', bgColor: 'bg-brand-purple' },
};

export const partOrderCase = {
  DRAFT: {
    textColor: 'text-slate-500',
    bgColor: 'bg-white border border-slate-500',
    drop: 'border-slate-500 group-hover:bg-slate-100',
  },
  PENDING_BACKORDER: {
    textColor: 'text-slate-500',
    bgColor: 'bg-slate-100',
    drop: 'border-slate-500/20',
  },
  ORDErose: {
    textColor: 'text-white',
    bgColor: 'bg-yellow-500',
    drop: 'border-yellow-500/20',
  },
  RECEIVED: {
    textColor: 'text-white',
    bgColor: 'bg-emerald-500',
    drop: 'border-emerald-500/20',
  },
  VOID: {
    textColor: 'text-white',
    bgColor: 'bg-brand-purple',
    drop: 'border-purple-500/20',
  },
};

export const squawkCase = {
  OPEN: { textColor: 'text-slate-500', bgColor: 'bg-white border border-slate-500', drop: 'border-slate-500 group-hover:bg-slate-100' },
  IN_PROGRESS: {
    textColor: 'text-white/90',
    bgColor: 'bg-brand-electric',
    drop: 'border-blue-800 group-hover:bg-black/10',
  },
  PLANNED: { textColor: 'text-white', bgColor: 'bg-yellow-500', drop: 'border-yellow-600 group-hover:bg-yellow-600' },
  RESOLVED: { textColor: 'text-white', bgColor: 'bg-emerald-500', drop: 'border-emerald-600 group-hover:bg-emerald-600' },
  STALLED: { textColor: 'text-white', bgColor: 'bg-rose-500', drop: 'border-rose-600 group-hover:bg-rose-600' },
};

export const severityCase = {
  WATCH: {
    textColor: 'text-white/90',
    bgColor: 'bg-brand-electric',
    drop: 'border-blue-800 group-hover:bg-black/10',
  },
  WARNING: { textColor: 'text-white', bgColor: 'bg-yellow-500', drop: 'border-yellow-600 group-hover:bg-yellow-600' },
  GROUNDED: { textColor: 'text-white', bgColor: 'bg-rose-500', drop: 'border-rose-600 group-hover:bg-rose-600' },
};

export const craftRequestCase = {
  SERVICE_REQUESTED: {
    textColor: 'text-white/90',
    bgColor: 'bg-brand-electric',
    drop: 'border-blue-800 group-hover:bg-black/10',
  },
  PENDING: { textColor: 'text-slate-400', bgColor: 'bg-slate-100' },
  ESTIMATE_PENDING: { textColor: 'text-white', bgColor: 'bg-yellow-500', drop: 'border-yellow-600 group-hover:bg-yellow-600' },
  INVOICE_PENDING: { textColor: 'text-white', bgColor: 'bg-yellow-500', drop: 'border-yellow-600 group-hover:bg-yellow-600' },
  SCHEDULED: {
    textColor: 'text-brand-electric',
    bgColor: 'bg-brand-pale',
    drop: 'border-brand-electric/20 group-hover:bg-brand-electric/10',
  },
  IN_PROGRESS: {
    textColor: 'text-white',
    bgColor: 'bg-brand',
    drop: 'border-brand',
  },
  COMPLETED: { textColor: 'text-white', bgColor: 'bg-emerald-500', drop: 'border-emerald-600 group-hover:bg-emerald-600' },
  CLOSED: { textColor: 'text-slate-500', bgColor: 'bg-slate-100' },
};

export const colors = [
  'rose',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
];

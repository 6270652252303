import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import { FormLabel, FullFormikSelect, FullFormikTextarea, StyledFormikField } from 'components/Form/StandardForm';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  GetMroPartAndInventoriesDocument,
  GetMroWorkOrderItemDocument,
  UpdateMroWorkOrderPartDocument,
  GetPendingPartRequestsDocument,
  GetMroOrgnizationPartPriceDocument
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { PartNumberLookup } from '../../../Parts/Components/PartLookup';
import * as yup from 'yup';
import { useSession } from 'contexts';
import { partMarkup } from 'utils/partMarkup';
import DatePicker from 'components/DatePicker/DatePicker';
import { addDays } from 'date-fns';
import { formatApiDate, formatDateChange } from 'utils/formatter';
import Toast, { useToast } from 'components/Toast/Toast';

interface Values {
  unitPrice: string;
  quantity: string;
  costPrice: string;
  condition: string;
  neededBy: Date;
  shippingType: string;
  notes: string;
}

const NewPartSchema = yup.object().shape({
  unitPrice: yup.number().required('Required'),
  quantity: yup.number().required('Required'),
});

export default function SubmitPartsRequest({
    workOrderItemId,
    workOrderPart,
    setIsOpen,
}: {
    workOrderPart: any;
    workOrderItemId?: string;
    setIsOpen: (isOpen: boolean) => void;
}) {
    const { toastProps, showToast } = useToast();
    const { user } = useSession();
    const [ updateOrderPart ] = useMutation(UpdateMroWorkOrderPartDocument, {
        refetchQueries: [
            ...(workOrderItemId ? [{ query: GetMroWorkOrderItemDocument, variables: { id: workOrderItemId } }] : []),
            { query: GetPendingPartRequestsDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
        ],
    });
    const { data: { mroOrganization: { mroPartPriceMatrix } } = { mroPartPriceMatrix: [] } } = useQuery(GetMroOrgnizationPartPriceDocument, { variables: { id: user.mroOrganizationId } });
    const [selectedPart, setSelectedPart] = useState<any>();
    const [partNumber, setPartNumber] = useState<string>(workOrderPart.partNumber ?? '');
    const [getPart, { data: { mroPart } = { mroPart: undefined } }] = useLazyQuery(GetMroPartAndInventoriesDocument);
    const [initialValues, setInitialValues] = useState<Values>({
        unitPrice: workOrderPart.unitPrice ??'',
        costPrice:  workOrderPart.costPrice ??'0',
        quantity: workOrderPart.quantity ?? '0',
        condition: workOrderPart.condition ?? 'NEW',
        neededBy: workOrderPart.neededBy??addDays(new Date(), 1),
        notes: workOrderPart.notes ?? '',
        shippingType: workOrderPart.shippingType ?? 'GROUND',
    });

    useEffect(() => {
        if (selectedPart === 'newPart') {
            setInitialValues({
                unitPrice: '0',
                costPrice: '0',
                quantity: '',
                condition: '',
                notes: initialValues.notes,
                neededBy: initialValues.neededBy,
                shippingType: initialValues.shippingType,
            });
        } else if (selectedPart) {
            setPartNumber(selectedPart.partNumber);
            getPart({ variables: { id: selectedPart?.id } });
        }
    }, [selectedPart, getPart]);

    useEffect(() => {
        if (mroPart) {
        // Get Price based on Part Inventory
        let price = 0;
        const quantity = Number(initialValues.quantity);
        if (!mroPart.isSerialized && mroPart?.availablePartInventories?.length > 0) {
            const partInventories = mroPart.availablePartInventories;
            var selectedInventory =
            partInventories.find((inv) => quantity >= inv.quantity) ?? partInventories.sort((a, b) => a.unitPrice - b.unitPrice)?.[0];
            price = selectedInventory?.unitPrice ?? 0;
        }
        if (mroPart?.isSerialized && mroPart?.availableSerializedParts?.length > 0) {
            const serializedParts = [...mroPart?.availableSerializedParts]?.sort((a, b) => Number(a.unitPrice) - Number(b.unitPrice));
            price = serializedParts[0]?.unitPrice ?? 0;
        }
        const [markupPrice, markPercent] = partMarkup(Number(price), mroPartPriceMatrix?.partMatrix);
        setInitialValues({
            costPrice: price.toString() ?? '',
            unitPrice: markupPrice.toString() ?? '',
            quantity: mroPart?.isSerialized ? '1' : '',
            condition: '',
            notes: initialValues.notes,
            neededBy: initialValues.neededBy,
            shippingType: initialValues.shippingType,
        });
        }
    }, [mroPart]);


    const handleSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
        updateOrderPart({
            variables: {
              input: {
                id: workOrderPart?.id,
                status: 'REQUESTED',
                partNumber: partNumber,
                ...(selectedPart && { mroPart: { connect: { id: selectedPart?.id } } }),
                neededDate: new Date(values.neededBy),
                shippingType: values.shippingType,
                unitPrice: parseFloat(values.unitPrice),
                notes: values.notes,
                quantity: selectedPart?.isSerialized ? 1 : parseInt(values.quantity),
              },
            },
          })
        .then(() => setIsOpen(false))
        .catch((err) => console.error(err));
    };

    return (
    <>
        <Toast {...toastProps} />
        <div className="p-4">
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={NewPartSchema}>
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="flex flex-col w-full border rounded p-8 shadow bg-white">
                        <div className="p-2">
                            <PartNumberLookup {...{ selectedPart, setSelectedPart, partNumber, setPartNumber }} />
                            {selectedPart?.description && <div className="text-sm mt-[-10px]">Description: {selectedPart?.description}</div>}
                        </div>

                        <dl className="flex flex-col w-full px-2">
                            <div className="flex gap-2">
                                <div className="flex flex-col w-full relative">
                                    <FormLabel>Unit Price</FormLabel>
                                    <StyledFormikField
                                    stripStyle
                                    type="number"
                                    id="unitPrice"
                                    name="unitPrice"
                                    onChange={(e) => {
                                        setFieldValue('unitPrice', e.target.value);
                                        setFieldValue('costPrice', '0');
                                    }}
                                    />
                                    {values.costPrice !== '0' && (
                                    <div className="text-sm text-right mt-[-10px]">Part Cost ${values.costPrice}</div>
                                    )}
                                </div>
                                <div className="flex flex-col w-full relative">
                                    <FormLabel>Quantity</FormLabel>
                                    {selectedPart?.isSerialized ? (
                                        <StyledFormikField name="blank" placeholder="Cannot set QTY for Serialized Part" disabled />
                                    ) : (
                                        <StyledFormikField name="quantity" type="number" />
                                    )}
                                </div>
                            </div>
                            <hr className="my-6" />
                            <div className="flex justify-between">
                                <FormLabel>Total Price</FormLabel>
                                <dd>
                                {Number(+values?.unitPrice * +values.quantity)?.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'USD',
                                }) ?? 'N/A'}
                                </dd>
                            </div>
                        </dl>
                        <hr className="my-6" />
                        <div className="px-2 mt-4">
                            <FormLabel>Need By</FormLabel>
                            <Field
                                as={DatePicker}
                                value={formatApiDate(values.neededBy)}
                                className="w-full bg-state-500" />
                        </div>
                        <div className="px-2 mt-3">
                            <FullFormikSelect name="shippingType" label="Shipping Speed">
                            <option value="GROUND">Ground</option>
                            <option value="2_DAY">2 Day</option>
                            <option value="STANDARD_OVERNIGHT">Standard Overnight</option>
                            <option value="PRIORITY_OVERNIGHT">Priority Overnight</option>
                            </FullFormikSelect>
                        </div>
                        <div className="px-2 mt-3">
                        <FullFormikTextarea name="notes" label="Notes" />
                        </div>
                    </div>
                    <div className="flex justify-end items-center mt-2 gap-4 ">
                        <Button text="Submit Request" color="blue" size="xs" type="submit" />
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    </>
    );
}
